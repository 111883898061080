import React, { useMemo, useEffect, useState } from "react";
import { useMediaById } from "../hooks/useMediaById";
import { useParams } from "react-router";
import { useTranslation } from "@coworker/locales";
import { MediaPlacement } from "../Molecules/MediaPlacement";
import { SupplierNumber } from "../Molecules/SupplierNumber";
import { DateStamp } from "../Molecules/DateStamp";
import { GreyStrip } from "../Atoms/GreyStrip";
import { CreateTasks } from "../Organisms/CreateTasks";
import { useTasksByArticle } from "../hooks/useTasksByArticle";
import { useMediasAndArticleCount } from "../hooks/useMediasAndArticleCount";
import {
  getPrimeTasks,
  getSortedMediasArticleCount,
  reduceMediasArticleCount,
} from "./ArticleView.helper";
import { ArticleViewSkeleton } from "../Skeletons/ArticleViewSkeleton";
import {
  isAlternativeForProduct,
  isMainProduct,
} from "../Molecules/MediaArticles.helper";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ArticleStock } from "../Molecules/ArticleStock";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { HFBLocation } from "../Molecules/HFBLocation";
import { useArticlesByProductArticleId } from "../hooks/useArticlesByProductArticleId";
import { ArticleInGroups } from "../Molecules/ArticleInGroups";
import { AggregatedArticleListItem } from "../Molecules/AggregatedArticleListItem";
import { AggregatedArticle } from "../hooks/useAggregatedArticles";
import { Group } from "../types/groups";
import { ArticleListItemMobile } from "../styles/styles";
import LinkbarLoading from "@coworker/apprestructured/src/shared/components/Linkbar/LinkbarLoading";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import {
  getMainAndAlternativeProducts,
  setMainAndBackupProduct,
} from "../tools";
import { MainAlternativeProducts, Media } from "../types/media";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import { ReplaceProduct } from "../Organisms/ReplaceProduct";
import { useUpdateMedia } from "../hooks/useUpdateMedia";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";
import { FixaWindow } from "@coworker/reusable";
import { MediaTypes } from "../constants";

export function AggregatedArticleView() {
  const { mediaId, productArticleId } = useParams();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { data: articles, isLoading: articlesLoading } =
    useArticlesByProductArticleId(mediaId, productArticleId);
  const { data: media, isLoading: mediaLoading } = useMediaById(mediaId);
  const article = articles && articles.length > 0 ? articles[0] : undefined;
  const mainAndAlternativeProduct = getMainAndAlternativeProducts(
    media as Media
  );
  const currentAlternativeProduct = useMemo(() => {
    if (article?.id === media?.mainProductId1) {
      return media?.alternativeProductId1;
    } else if (article?.id === media?.mainProductId2) {
      return media?.alternativeProductId2;
    }
    return "";
  }, [
    article?.id,
    media?.mainProductId1,
    media?.mainProductId2,
    media?.alternativeProductId1,
    media?.alternativeProductId2,
  ]);
  const enableMedia = (window as FixaWindow).enableMediaTypes;
  const { tasks } = useTasksByArticle(media?.storeId, productArticleId);
  const { data: mediasArticleCount, isLoading: racLoading } =
    useMediasAndArticleCount(productArticleId, media?.storeId);
  const { data: itemInfo } = useItemInfo(currentAlternativeProduct || "");
  const [backupProduct, setBackupProduct] = useState<Item>();

  useEffect(() => {
    if (itemInfo) {
      setBackupProduct(itemInfo);
    }
  }, [itemInfo]);
  const sortedMediasArticleCount = getSortedMediasArticleCount(
    mediasArticleCount || [],
    mediaId
  );

  const primeTasks = getPrimeTasks(mediasArticleCount || [], tasks);

  const updateMedia = useUpdateMedia(mediaId || "").mutate;

  const linkbar = useMemo(() => {
    if (!media?.name || !media?.id) {
      return <LinkbarLoading />;
    }
    return (
      <Linkbar
        items={[
          {
            label: t("mediaMaintenanceString"),
            to: "/mediamaintenance",
          },
          {
            label: media.name,
            to: `/mediamaintenance/tabs/${media.id}?tab=article-list-tab`,
          },
        ]}
        currentLabel={article?.itemName ?? ""}
        currentAsH1
      />
    );
  }, [article?.itemName, media?.id, media?.name, t]);

  const [mainProductChecked, setMainProductChecked] = React.useState(false);

  useEffect(() => {
    if (article) {
      setMainProductChecked(
        isMainProduct(
          article.id,
          mainAndAlternativeProduct.mainProductId1,
          mainAndAlternativeProduct.mainProductId2
        )
      );
    }
  }, [article, mainAndAlternativeProduct]);

  useEffect(() => {
    if (backupProduct) {
      if (article?.id === media?.mainProductId1)
        updateMedia({
          ...media,
          alternativeProductId1: backupProduct.no,
          alternativeProductName1: backupProduct.name,
        });
      else {
        updateMedia({
          ...media,
          alternativeProductId2: backupProduct.no,
          alternativeProductName2: backupProduct.name,
        });
      }
    }
  }, [article?.id, backupProduct, media, updateMedia]);

  if (articlesLoading || mediaLoading || racLoading)
    return (
      <FullScreenPopup noPadding linkbar={linkbar}>
        <ArticleViewSkeleton />
      </FullScreenPopup>
    );

  if (!article) push(`/mediamaintenance/tabs/${mediaId}`);

  async function ToggleMainProduct() {
    if (!media || !article) {
      return;
    }
    let data: MainAlternativeProducts = {
      ...media,
      mainProductId1: "",
      alternativeProductId1: "",
      mainProductName1: "",
      alternativeProductName1: "",
      mainProductId2: "",
      alternativeProductId2: "",
      mainProductName2: "",
      alternativeProductName2: "",
    };
    const isMainProductCheck: boolean = isMainProduct(
      article.id,
      mainAndAlternativeProduct.mainProductId1,
      mainAndAlternativeProduct.mainProductId2
    );
    isMainProductCheck
      ? setBackupProduct(undefined)
      : setBackupProduct(itemInfo || undefined);

    data = setMainAndBackupProduct(data, isMainProductCheck, media, article);

    setMainProductChecked(!mainProductChecked);
    updateMedia(data as Media);
  }

  const articleIds: string[] = articles?.map((article) => article.id) ?? [];
  const showBackupProducts = enableMedia && mainProductChecked;
  return (
    <>
      {article && media && tasks && (
        <FullScreenPopup noPadding linkbar={linkbar}>
          <ArticleListItemMobile>
            <AggregatedArticleListItem
              article={
                {
                  ...article,
                  isFromArticleView: true,
                  aggregatedArticles: articles,
                } as AggregatedArticle
              }
              groups={
                (articles || [])
                  .filter((a) => !!a.groupId)
                  .map((a) => {
                    return { id: a.groupId, name: a.groupName } as Group;
                  }) ?? []
              }
              isMainProduct={() =>
                isMainProduct(
                  article.id,
                  mainAndAlternativeProduct.mainProductId1,
                  mainAndAlternativeProduct.mainProductId2
                )
              }
              numberMain={article.id === media.mainProductId1 ? 1 : 2}
              isAlternativeForProduct={() =>
                isAlternativeForProduct(
                  article.id,
                  mainAndAlternativeProduct.alternativeProductId1 ?? "",
                  mainAndAlternativeProduct.alternativeProductId2 ?? ""
                )
              }
              showActionRow={true}
              hideChevron={true}
            />
          </ArticleListItemMobile>
          <FixaListView id="article-view-list" size={"small"}>
            {(articles?.filter((a) => !!a.groupId).length ?? 0) > 0 && (
              <GreyStrip text={t("inGroupsString")} />
            )}
            <ArticleInGroups articles={articles ?? []} />
            {enableMedia && media.mediaType !== MediaTypes.COMPACT && (
              <FixaListViewItem
                title={t("mainProductBasicString")}
                emphasised={true}
                inset={true}
                control="switch"
                controlProps={{
                  checked: mainProductChecked ?? false,
                  value: "mainArticle",
                }}
                onChange={() => {
                  ToggleMainProduct();
                }}
              />
            )}

            {showBackupProducts && media.mediaType !== MediaTypes.COMPACT && (
              <ReplaceProduct
                oldProductArticleIds={articleIds}
                numberBackup={article.id === media.mainProductId1 ? 1 : 2}
                setNewArticle={setBackupProduct}
                alternativeBackUpProduct={backupProduct}
                isSetAsBackupProduct={true}
              />
            )}

            <GreyStrip text={t("inMediaString")} />

            <MediaPlacement
              mediasArticleCount={
                reduceMediasArticleCount(
                  sortedMediasArticleCount.map((mediaArticle) => {
                    return { ...mediaArticle };
                  })
                ) || []
              }
              currentArticleId={article.id}
            />

            <GreyStrip text={t("aboutArticleString")} />

            <HFBLocation hfb={article.hfb} />

            <ArticleStock
              productArticleId={article.productArticleId}
              productArticleType={article.productArticleType}
            />

            <SupplierNumber
              article={article}
              value={article.supplierNumber ?? ""}
            />

            <DateStamp article={article} value={article.dateStamp ?? ""} />
          </FixaListView>

          <GreyStrip text={t("foundInFollowingMediasString")} />

          <CreateTasks
            mediasArticleCount={sortedMediasArticleCount || []}
            tasks={primeTasks}
          />
        </FullScreenPopup>
      )}
    </>
  );
}
