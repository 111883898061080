import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteShoppingTool } from "../ShoppingToolsAPI";

export const useDeleteShoppingTool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteShoppingTool,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey:["shopping-tools-progress-by-round"]});
      queryClient.invalidateQueries({queryKey:["shopping-tools-chart-data"]});
      queryClient.invalidateQueries({queryKey:["shopping-tools-todays-progress"]});
      queryClient.invalidateQueries({queryKey:["shopping-tools-latest"]});
    },
  });
};
