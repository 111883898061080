import {
  FilterValue,
  SelectCategory,
  SelectItem,
  SelectOption,
  SelectOptionString,
  SelectOptionStringArray,
} from "../../types";
import { isStringArrayFilterValue, stringArrayAreTheSame } from "./filtervalue";

export function isSelectCategory(option: SelectItem): option is SelectCategory {
  return !("value" in option);
}

export function isSelectOption(option: SelectItem): option is SelectOption {
  return "value" in option;
}

export function isSelectOptionString(
  option: SelectItem
): option is SelectOptionString {
  return "value" in option && typeof option.value === "string";
}

export function isSelectOptionStringArray(
  option: SelectItem
): option is SelectOptionStringArray {
  return "value" in option && Array.isArray(option.value);
}

export function getSelectOptionKey(option: SelectItem) {
  if (isSelectOptionStringArray(option)) {
    return option.value.join("-");
  } else if (isSelectOptionString(option)) {
    return option.value;
  } else {
    return option.title;
  }
}

export function isSelectOptionSelected(
  selected: FilterValue,
  option: SelectOption
) {
  if (isStringArrayFilterValue(selected)) {
    if (isSelectOptionStringArray(option)) {
      return stringArrayAreTheSame(selected.value, option.value);
    } else {
      return selected.value.includes(option.value);
    }
  } else {
    return selected.value === option.value;
  }
}
