import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useState } from "react";
import { SizedText } from "../../styles/styles";
import { Articles } from "../../types/article";
import { useUserId } from "../../../../core/auth/useLoggedInUser";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaPrompt } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaPrompt";
import { setOngoingTasksToClosed } from "../../tools";
import { Trans, useTranslation } from "@coworker/locales";
import { ModalTypes } from "../../types/views";
import { useTasksByMedia } from "../../hooks/useTasksByMedia";
import { OngoingTaskLinksResponse } from "../../types/taskLink";

import TrashCanIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TrashCanIconPath"; // NOSONAR
import { logEvent } from "../../../../helpers/tracker";
import { useDeleteArticlesByIds } from "../../hooks/useDeleteArticlesByIds";
import { useToastNotification } from "@coworker/components";

interface DeleteSelectedArticlesModalProps {
  modalVisibility: boolean;
  selectedArticles: Articles;
  mediaId: string;
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export const DeleteSelectedArticlesModal = ({
  modalVisibility,
  selectedArticles,
  mediaId,
  onTriggerNewModal,
}: DeleteSelectedArticlesModalProps) => {
  const { t } = useTranslation();
  const uid = useUserId();
  const mediaTasks = useTasksByMedia(mediaId);
  const deleteArticles = useDeleteArticlesByIds(mediaId);
  const { showToast } = useToastNotification();

  const [deleting, setDeleting] = useState(false);

  const handleDeleteArticles = async () => {
    let articleIdsToDelete = selectedArticles.map((article) => article.id);

    const tasksToBeClosed = mediaTasks.tasks.filter((task) =>
      articleIdsToDelete.includes(task.article_id)
    );
    await setOngoingTasksToClosed(
      mediaId,
      t("taskClosedBecauseRemovedArticleString"),
      {
        count: tasksToBeClosed.length,
        tasks: tasksToBeClosed,
      } as OngoingTaskLinksResponse,
      uid
    );
    try {
      await deleteArticles.mutateAsync(articleIdsToDelete);
    } catch (error) {
      alert(t("searchErrorString"));
    }
    onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
    logEvent("ce:delete_selected_articles");

    showToast(t("articlesDeletedString"));
  };

  return (
    <FixaModal
      visible={modalVisibility}
      handleCloseBtn={() => onTriggerNewModal(ModalTypes.KEEP_SELECTION)}
    >
      <FixaPrompt
        title={""}
        titleId={`delete-${mediaId}`}
        header={<FixaModalHeader title={""} ariaCloseTxt="Close prompt." />}
        footer={
          <FixaModalFooter>
            <FixaButton
              text={t("cancelString")}
              type="secondary"
              onClick={() => onTriggerNewModal(ModalTypes.KEEP_SELECTION)}
            />
            <FixaButton
              text={t("deleteString")}
              ssrIcon={TrashCanIconPath}
              onClick={async () => {
                setDeleting(true);
                await handleDeleteArticles();
              }}
              style={{
                backgroundColor: "var(--pink)",
                color: "var(--white)",
              }}
              loading={deleting}
            />
          </FixaModalFooter>
        }
      >
        <>
          <SizedText $fontSize={16} $bold={true}>
            <Trans values={{ item: mediaId }}>
              sureToDeleteSelectedArticlesString
            </Trans>
          </SizedText>
          <SizedText $fontSize={14} $bold={false}>
            <Trans>allSelectedArticlesWillBeDeletedIncludingGroupsString</Trans>
          </SizedText>
          <SizedText $fontSize={14} $bold={false}>
            <Trans>allOngoingTasksWillBeClosedString</Trans>
          </SizedText>
        </>
      </FixaPrompt>
    </FixaModal>
  );
};
