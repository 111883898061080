import { SVGProps } from "react";
import Button from "@ingka/dual-button";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/dual-button/dist/style.css";

interface FixaDualButtonProps {
  orientation: "horizontal" | "vertical";
  ssrIconFirst: (prefix?: string | undefined) => SVGProps<SVGElement>[];
  ssrIconSecond: (prefix?: string | undefined) => SVGProps<SVGElement>[];
  onClickFirst: () => void;
  onClickSecond: () => void;
  "data-testid"?: string;
  small: boolean;
  type?: "primary" | "secondary";
}

function FixaDualButton(props: FixaDualButtonProps) {
  return <Button {...props} />;
}

export default FixaDualButton;
