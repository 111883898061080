import { UserRoles } from "@coworker/apprestructured/src/shared/enums/user/userRoles";

export const displayRoleTranslationString = (role: UserRoles) => {
  switch (role) {
    case UserRoles.COUNTRY_SUPERUSER:
      return "countrySuperuserString";
    case UserRoles.SUPERUSER:
      return "globalSuperuserString";
    case UserRoles.STOREOWNER:
      return "storeSuperuserString";
    case UserRoles.COWORKER:
    default:
      return "coworkerString";
  }
};
