
import { ListItemContainer } from "../styles/styles";
import {
  SelectableIconPill,
  SelectableIconPillIcons,
} from "../Atoms/SelectableIconPill";
import { useTranslation } from "@coworker/locales";

interface TaskPillRowProps {
  isNewArticleSelected: boolean;
  isPTagSelected: boolean;
  onPTagClick: () => void;
  onNewArticleClick: () => void;
}

export function TaskPillRow({
  isNewArticleSelected,
  isPTagSelected,
  onPTagClick,
  onNewArticleClick,
}: TaskPillRowProps) {
  const { t } = useTranslation();
  return (
    <ListItemContainer>
      <SelectableIconPill
        label={t("getNewArticleString")}
        icon={SelectableIconPillIcons.ARROW_PAIR_SQUARE_PATH}
        selected={isNewArticleSelected}
        onClick={onNewArticleClick}
      />
      <SelectableIconPill
        label={t("printPTagString")}
        icon={SelectableIconPillIcons.PRICE_TAG}
        selected={isPTagSelected}
        onClick={onPTagClick}
      />
    </ListItemContainer>
  );
}
