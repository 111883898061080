import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Close } from "../assets/svg/close-small.svg";

const Container = styled('div')`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: var(--grey150);
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 14px;
`;

const OuterContainer = styled('div')`
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  ${(props) =>
    props.combineWithTop &&
    css`
      padding-top: 0px;
      margin-top: -1px;
      background: white;
    `}
`;
const InfoTitle = styled('div')`
  font-weight: bold;
  line-height: 21px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const CloseIcon = styled(Close)`
  background: var(--grey200);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 20px;
`;

const InfoBox = ({ shown, title, text, onClose, combineWithTop }) => {
  return (
    <OuterContainer combineWithTop={combineWithTop} data-testid="infoBox">
      <Container>
        <InfoTitle>
          <p>{title}</p>
          <CloseIcon onClick={onClose} data-testid="infoBoxClose" />
        </InfoTitle>
        <span>{text}</span>
      </Container>
    </OuterContainer>
  );
};

export default InfoBox;
