import React from "react";
import styled from "styled-components";

const LinesContainer = styled('div')`
  flex-grow: 1;
  margin-bottom: 40px;
`;
const ShortContainer = styled('div')`
  margin-top: 20px;
`;

const WideLinesContainer = styled('div')`
  flex-grow: 1;
  width: 100%;
  padding-left: 29px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const ShortLine = styled('div')`
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
  width: 114px;
  height: 15px;
  border-radius: 8px;
`;

const ShortLineWithMargin = styled(ShortLine)`
  margin-top: 11px;
  flex-grow: 1;
`;

const ShortRoughLine = styled('div')`
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
  width: 105px;
  height: 15px;
  border-radius: 2px;
`;

const ShortRoughThickLine = styled('div')`
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
  width: 105px;
  height: 27px;
  border-radius: 2px;
`;

const Spacer = styled('div')`
  height: ${(props) => props.height}px;
  width: 1px;
`;

const VerticalContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const BoxWithRightMargin = styled('div')`
  margin-right: ${(props) => props.right}px;
`;

const NormalLine = styled('div')`
  margin-top: 11px;
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
  width: 155px;
  height: 15px;
  border-radius: 8px;
`;

const Square = styled('div')`
  width: 200px;
  height: 45px;
  border-radius: 200px;
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
`;

const ShortBoxWithMargin = styled('div')`
  width: 130px;
  height: 60px;
  border-radius: 200px;
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
  margin: ${(props) => props.margin}px;
`;

const Loader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WideSkeleton = styled('div')`
  width: 320px;
  height: 124px;
  border-radius: 7px;
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
`;

const RefillContainer = styled('div')`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    margin-bottom: 17px;
  }
`;

const RefillAmountSkeleton = () => (
  <RefillContainer>
    <NormalLine />
    <Square />
    <ShortLine />
  </RefillContainer>
);

const SmallItemSkeleton = () => {
  return (
    <ShortContainer>
      <ShortLine />
    </ShortContainer>
  );
};

const ItemSkeleton = () => {
  return (
    <LinesContainer>
      <ShortLine />
      <NormalLine />
      <NormalLine />
    </LinesContainer>
  );
};

const InsightsSkeleton = () => {
  return (
    <VerticalContainer data-testid="insights_skeleton_loader">
      <BoxWithRightMargin right={50}>
        <ShortRoughLine />
        <Spacer height={24} />
        <ShortRoughThickLine />
      </BoxWithRightMargin>
      <BoxWithRightMargin right={0}>
        <ShortRoughLine />
        <Spacer height={24} />
        <ShortRoughThickLine />
      </BoxWithRightMargin>
    </VerticalContainer>
  );
};

const MFAQVotesSkeleton = ({ isDays }) => {
  return (
    <Loader data-testid="votes_graph_loader">
      <ShortBoxWithMargin margin={15} />
      <WideLinesContainer>
        {new Array(isDays ? 7 : 4).fill(true).map((elem, index) => {
          return index % 3 ? (
            <ShortLineWithMargin key={index} />
          ) : (
            <NormalLine key={index} />
          );
        })}
      </WideLinesContainer>
    </Loader>
  );
};

const ItemLoader = ({ count = 5 }) => (
  <>
    {new Array(count).fill(true).map((i, index) => (
      <ItemSkeleton key={index}></ItemSkeleton>
    ))}
  </>
);

export {
  ItemSkeleton,
  ItemLoader,
  ShortLine,
  NormalLine,
  Loader,
  RefillAmountSkeleton,
  WideSkeleton,
  SmallItemSkeleton,
  InsightsSkeleton,
  MFAQVotesSkeleton,
};
