import React from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { ReactComponent as DeliveryIcon } from "../assets/svg/DeliveryTruck.svg";
import { StockIndicator } from "../features/stock";
import useFormatter from "../hooks/useFormatter";
import { useExpectedDelivery } from "../hooks/useExpectedDelivery";
import { useItemEdsDateFormatted } from "@coworker/apprestructured/src/shared/hooks/item/useItemEdsDateFormatted";

const Span = styled('span')`
  display: inline-block;
  font-weight: bold;
  span {
    color: var(--bti-red);
  }
`;
const SpanMargin = styled(Span)`
  margin-left: 8px;
`;
const ContainerPill = styled('div')`
  display: flex;
  align-items: center;
`;
const Pill = styled('div')`
  border: ${(props) => (props.noBorder ? "none" : "1px solid grey")};
  border-radius: ${(props) => (props.noBorder ? "0px" : "25px")};
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 10px;
  height: 40px;
  padding: 0px 16px;
`;

export function StockInfoPills({
  type,
  short_id,
  countryId,
  stockInfo,
  noBorder,
}) {
  const { t } = useTranslation();
  const { isLoading, edsDate } = useItemEdsDateFormatted(short_id);
  const { formatNumber } = useFormatter();
  const { nextDelivery, formattedDelivery } = useExpectedDelivery(short_id);
  const { available, elevated, loading, color } = stockInfo ?? {};
  function returnFormattedStockData(quantity) {
    if (!quantity && quantity !== 0) return "";
    return `${formatNumber(quantity)}`;
  }

  const formattedElevated = returnFormattedStockData(elevated); // This is "" initially, then becomes "0" or some other string of a number.

  return (
    <ContainerPill data-testid="stockInfoPills">
      {!loading && (
        <Pill noBorder={noBorder}>
          <StockIndicator color={color} marginRight={8} size={8} />
          <Span>
            {returnFormattedStockData(available)}
            {!!formattedElevated && <> ({formattedElevated})</>}
          </Span>
        </Pill>
      )}
      {nextDelivery && (
        <Pill noBorder={noBorder}>
          <DeliveryIcon />
          <SpanMargin>
            {formattedDelivery}
            {", "}
            {returnFormattedStockData(nextDelivery.quantity)}
            {t("pcsString")}
          </SpanMargin>
        </Pill>
      )}
      {!isLoading && !!edsDate && (
        <Pill noBorder={noBorder}>
          <Span>
            <span>EDS: {edsDate}</span>
          </Span>
        </Pill>
      )}
    </ContainerPill>
  );
}
