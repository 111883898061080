import {Dispatch,SetStateAction} from "react";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import { Trans } from "@coworker/locales";
import { ShoppingToolType } from "../constants";
import styled, { AnyStyledComponent, css } from "styled-components";
interface ShoppingToolsListViewProps {
  id: string;
  itemList: ShoppingToolType[];
  setChosenTool?: Dispatch<SetStateAction<string>>;
  setChecked?: Dispatch<SetStateAction<boolean>>;
  checked?: boolean;
  chosenTool?: string;
  borderColor: string;
}

const StyledListViewItem = styled(FixaListViewItem as AnyStyledComponent)<{
  borderColor: string;
}>`
  padding: 0 1rem;
  border-bottom: none;
  ${(props) =>
    css`
      border-left: 8px solid ${props.borderColor};
    `}
  box-shadow: 0 1px 0 0 #DFDFDF inset;
`;

export const ShoppingToolsListView = ({
  id,
  itemList,
  setChosenTool,
  setChecked,
  checked,
  chosenTool,
  borderColor,
}: ShoppingToolsListViewProps) => {
  return (
    <FixaListView id={id} size="small">
      {itemList.map((item) => {
        return (
          <StyledListViewItem
            key={item.label}
            borderColor={borderColor}
            control={item.control}
            leadingIcon={item.icon}
            title={<Trans>{item.label}</Trans>}
            controlProps={{
              checked: item.name === chosenTool || checked ? true : false,
              value: item.name,
            }}
            onChange={() => {
              setChosenTool && setChosenTool(item.name);
              setChecked && setChecked(!checked);
            }}
          />
        );
      })}
    </FixaListView>
  );
};
