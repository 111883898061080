import {useContext} from "react";
import styles from "./task-card.module.css";
import { getTaskInfo } from "../../helpers/getTaskInfo";
import { useTranslation } from "@coworker/locales";
import useFormatter from "@coworker/app/src/hooks/useFormatter";
import { Type } from "./Type";
import { Priority } from "./Priority";
import { ImagesAndComments } from "./ImagesAndComments";
import { Image } from "./Image";
import { Title } from "./Title";
import { Frequency } from "./Frequency";
import { Assignee } from "./Assignee";
import { Layout } from "./Layout";
import { TaskState } from "../../enums/taskState";
import FixaCheckbox from "../../../shared/wrappers/FixaCheckbox/FixaCheckbox";
import { useMultiSelectForTab } from "@coworker/app/src/hooks/useMultiSelect";
import { Item } from "../../../shared/types/item";
import { SubTitles } from "./SubTitles";
import { InformationBanner } from "./InformationBanner";
import { Timestamp } from "./Timestamp";
import { TaskType } from "../../enums/taskTypes";
import { FullTask } from "../../../activity/types/FullTask";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";

interface TaskCardProps {
  task: FullTask;
  item: Item;
  multiSelect?: boolean;
  isSwiping?: boolean;
  type?: string;
}

export const TaskCard = ({
  task,
  item,
  multiSelect,
  isSwiping,
  type,
}: TaskCardProps) => {
  const { t } = useTranslation();
  const { toggleModal, modalState } = useContext(ModalContext);

  const { productUnit } = useFormatter();
  const productUnitTitle = productUnit(
    task.requested_type,
    task.actual_requested_quantity
  );

  const taskInfo = getTaskInfo(
    task,
    t as (key?: string) => string,
    productUnitTitle,
    item
  );

  const isTaskClosed = task.state === TaskState.CLOSED;
  const isPlannedTask = task.task_type === TaskType.PLANNED && !isTaskClosed;

  const { toggle, selectedTaskIds = [] } = useMultiSelectForTab(type ?? "") as {
    toggle?: (s: string) => void | undefined;
    selectedTaskIds?: string[] | undefined;
    disabled: boolean;
  };

  if (!task) return null;

  return (
    <div
      className={`${styles["task-card"]} ${
        isPlannedTask && styles["planned-task"]
      }
    `}
      role="listitem"
      aria-label="Task card"
      tabIndex={0}
      onKeyDown={(e) => {
        if (!isSwiping) {
          if (
            (!modalState.isTaskDetailSheetOpen &&
              (e.key === "Enter" || e.key === " ")) ||
            (modalState.isTaskDetailSheetOpen && e.key === "Escape")
          ) {
            toggleModal({
              type: ModalType.TaskDetail,
              payload: { taskId: task.id, taskType: task.task_type },
            });
          }
        }
      }}
    >
      {multiSelect && (
        <div className={styles["checkbox-wrapper"]}>
          <FixaCheckbox
            id="select-task"
            value="select-task"
            checked={selectedTaskIds.includes(task.id as string)}
            onChange={() => toggle && toggle(task.id as string)}
          />
        </div>
      )}
      <Layout
        taskType={task.task_type}
        taskId={task.id ?? ""}
        isSwiping={isSwiping!!}
      >
        <div className={styles["top-wrapper"]}>
          <Type task={task} type={taskInfo.type ?? ""} />
          <Priority task={task} state={task.state} />
          <ImagesAndComments
            hasNotes={task?.has_notes as boolean}
            hasImages={task?.has_images as boolean}
          />
        </div>
        <div className={styles["middle-wrapper"]}>
          <Image image={item?.smallImageUrl ?? item?.imageUrl ?? ""} />
          <div
            className={`${styles["title-wrapper"]} ${
              item?.imageUrl && styles["with-image"]
            }`}
          >
            <Title title={taskInfo.title ?? ""} />
            <SubTitles subtitles={taskInfo.subtitles ?? []} />
            {(task.task_type === TaskType.CUSTOM ||
              task.task_type === TaskType.PLANNED) && <Frequency task={task} />}
          </div>
        </div>
        <InformationBanner
          information={taskInfo.bannerInformation}
          isTaskClosed={isTaskClosed}
          type={task.task_type}
        />
        <div className={styles["bottom-wrapper"]}>
          <Assignee
            state={task.state}
            assignee={
              task.state === TaskState.UNASSIGNED
                ? task.assigned_team_id
                : task.state === TaskState.ASSIGNED
                ? task.assigned_user_id
                : task.task_finisher_id ??
                  task.assigned_user_id ??
                  task.assigned_team_id
            }
          />
          <Timestamp
            dateTime={
              task.state === TaskState.UNASSIGNED ||
              task.state === TaskState.ASSIGNED
                ? task.created_at
                : task.finished_at
            }
            type={task.state}
          />
        </div>
      </Layout>
    </div>
  );
};
