import styled from "styled-components";
import { ListItemContainer, SizedText } from "../../styles/styles";
import { useTranslation, Trans } from "@coworker/locales";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaPrompt } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaPrompt";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { Media } from "../../types/media";
import { MediaTypes } from "../../constants";
import { getMediaTypeText } from "../../tools";

interface ChangeMediaTypeConfirmationDialogModalProps {
  media: Media;
  originalMediaType: MediaTypes;
  onConfirm: () => void;
  onCancel: () => void;
}

const ListItemContainerDelete = styled(ListItemContainer)`
  justify-content: flex-start;
  gap: 17px;
`;
export const ChangeMediaTypeConfirmationDialog = ({
  media,
  originalMediaType,
  onConfirm,
  onCancel,
}: ChangeMediaTypeConfirmationDialogModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ListItemContainerDelete onClick={() => onCancel()} role="button" />

      <FixaModal
        data-testid="deleteMediaModal"
        visible={true}
        handleCloseBtn={onCancel}
      >
        <FixaPrompt
          title={""}
          titleId={`delete-${media.id}`}
          header={
            <FixaModalHeader
              title={t("changeMediaTypeString")}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={
            <FixaModalFooter>
              <FixaButton
                data-testid="deleteMediaModal:cancel"
                text={t("cancelString")}
                type="secondary"
                onClick={onCancel}
              />
              <FixaButton
                data-testid="deleteMediaModal:confirm"
                text={t("confirmString")}
                type="primary"
                onClick={onConfirm}
              />
            </FixaModalFooter>
          }
        >
          <>
            <SizedText $fontSize={16} $bold={true}>
              <Trans
                values={{
                  mediaName: media.name,
                  oldMediaType: getMediaTypeText(originalMediaType),
                  newMediaType: getMediaTypeText(media.mediaType as MediaTypes),
                }}
              >
                mediaTypeChangeDialogHeaderString
              </Trans>
            </SizedText>
            <br />
            <SizedText $fontSize={14} $bold={false}>
              <Trans>mediaTypeChangeDialogFieldsString</Trans>
              <br />
              <br />
              <Trans>mediaTypeChangeDialogReassureString</Trans>
            </SizedText>
          </>
        </FixaPrompt>
      </FixaModal>
    </>
  );
};
