import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreServiceBaseUrl } from "@coworker/app/src/core/cloud-services/cloud-services.helper";
import { getEnvironmentId, isChineseEnvironment } from "@coworker/reusable";
import { fetchAPI } from "@coworker/app/src/hooks/API/tasks.service.helper";

const envId = getEnvironmentId();
const CORE_SERVICE_URL = coreServiceBaseUrl(envId, isChineseEnvironment());

async function updateUserLastActiveAtByFixaUid(fixaUid: string) {
  const path = `users/${fixaUid}/last-active-at`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await fetchAPI("PUT", url, {});
}

export const useUpdateUserLastActiveAt = (fixaUid: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => updateUserLastActiveAtByFixaUid(fixaUid),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["user", fixaUid],
      });
    },
  });
};
