import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { ControlledProductDetails, ItemSkeleton } from "@coworker/components";
import { ImageSkeleton } from "@coworker/reusable/components/ImageSkeleton";
import { TwoShortLinesSkeleton } from "@coworker/reusable/Loader";
import { ReactComponent as ErrorIcon } from "../assets/svg/icon-error.svg";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";
import { parseProduct } from "../services/products.service";

export const EmptyProductInfoRow = styled('div')`
  background: var(--white);
  color: var(--grey200);
  padding: 35px 28px;
  border-bottom: 1px solid var(--grey150);
`;

const ErrorMessage = styled('div')`
  color: var(--error);
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ErrorText = styled('span')`
  margin-left: 4px;
`;

const LoaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

export default function ProductInfoRow({
  art,
  onClick,
  imageLoader = false,
  insights,
  showOnlyLocation,
  title,
  subtitle,
  editInsightsEnabled,
  openEditInsights,
}) {
  const [shortId] = parseProduct(art);
  const { data: basicItem, isLoading: loading } = useItemInfo(
    showOnlyLocation ? "" : shortId
  );
  const descriptive = basicItem?.description;
  const productName = title || basicItem?.name;
  const notFound = !productName;

  if (!showOnlyLocation && (loading || notFound)) {
    return (
      <>
        <LoaderContainer>
          <ItemSkeleton />
          {imageLoader && <ImageSkeleton size={66} />}
        </LoaderContainer>
        <TwoShortLinesSkeleton topMargin={-15} />
        {notFound && (
          <ErrorMessage>
            <ErrorIcon />
            <ErrorText>
              <Trans>taskErrorMessage</Trans>
            </ErrorText>
          </ErrorMessage>
        )}
      </>
    );
  }

  if (notFound) return <EmptyProductInfoRow />;

  return (
    <ControlledProductDetails
      showOnlyLocation={showOnlyLocation}
      title={productName}
      subtitle={subtitle}
      descriptive={descriptive}
      onClick={onClick}
      smallImage={basicItem?.smallImageUrl}
      insights={insights}
      formattedNumber={basicItem?.noFormatted}
      editEnabled={editInsightsEnabled}
      openEditInsights={openEditInsights}
    />
  );
}
