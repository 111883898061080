import React from "react";
import styled, { css } from "styled-components";
import { LoaderIcon } from "@coworker/reusable/Loader";

const StyledButton = styled('button')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  ${({ customMargin }) =>
    // This customMargin is used to protect from a styled-components bug (?) that triggers when we do styled(Button)`margin...: ...` and we don't know the source of.
    !customMargin &&
    css`
      margin: 0px 4px;
    `}
  &:focus {
    outline: none;
  }
  ${({ loader }) =>
    loader &&
    css`
      pointer-events: none;
    `}
  ${({ tertiary }) =>
    tertiary &&
    css`
      & > div {
        padding: 0.8125rem 2.312rem;
      }
    `}
  &:focus > div:after {
    content: "";
    position: absolute;
    border-radius: 3.75rem;
    left: -3px;
    top: -3px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 1px solid var(--grey900);
    border-radius: 3.75;
  }
  &:active {
    transform-origin: 50% 50%;
    transform: scale(1.01);
  }
  ${({ primary, dark }) =>
    primary &&
    dark &&
    css`
      color: var(--white);
      background: var(--grey900);
      &:active {
        background-color: var(--grey800);
      }
      &:focus {
        background: var(--grey900);
      }
      &:focus:after {
        border: 1px solid var(--grey900);
      }
    `}

  ${({ primary, light }) =>
    primary &&
    light &&
    css`
      color: var(--grey900);
      background: var(--yellow);
      &:active {
        background-color: #e7bb40;
      }
      &:focus > div:after {
        content: "";
        position: absolute;
        border-radius: 3.75rem;
        padding: 0;
        left: 3px;
        top: 3px;
        width: 97.5%;
        height: 84%;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border: 1px solid var(--grey900);
        border-radius: 3.75;
      }
    `}
  ${({ primary, disabled }) =>
    primary &&
    disabled &&
    css`
      color: var(--grey500);
      background: var(--grey200);
      pointer-events: none;
    `}
  ${({ secondary, dark }) =>
    secondary &&
    dark &&
    css`
      color: var(--grey900);
      border: 1px solid var(--grey500);
      background: transparent;
      &:focus > div:after {
        content: "";
        position: absolute;
        border-radius: 3.75rem;
        left: -5px;
        top: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 6px);
        border: 1px solid var(--grey500);
        border-radius: 3.75;
      }
    `}
  ${({ secondary, light }) =>
    secondary &&
    light &&
    css`
      color: var(--white);
      border: 1px solid var(--white);
      background: transparent;
      &:focus > div:after {
        border: 1px solid var(--grey500);
      }
      &:focus > div:after {
        content: "";
        position: absolute;
        border-radius: 3.75rem;
        left: -5px;
        top: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 6px);
        border: 1px solid var(--grey500);
        border-radius: 3.75;
      }
    `}
  ${({ secondary, light, disabled }) =>
    secondary &&
    light &&
    disabled &&
    css`
      color: var(--grey500);
      border: 1px solid var(--grey200);
      pointer-events: none;
    `}
  ${({ secondary, dark, disabled }) =>
    secondary &&
    dark &&
    disabled &&
    css`
      color: var(--grey500);
      border: 1px solid var(--grey200);
      pointer-events: none;
    `}
  ${({ tertiary, dark }) =>
    tertiary &&
    dark &&
    css`
      max-width: 123px;
      color: var(--white);
      background: var(--grey900);
      &:focus > div:after {
        content: "";
        position: absolute;
        border-radius: 3.75rem;
        left: -5px;
        top: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 6px);
        border: 1px solid #111;
        border-radius: 3.75;
      }
    `}
  ${({ tertiary, light }) =>
    tertiary &&
    light &&
    css`
      max-width: 123px;
      color: #3d4153;
      background: transparent;
      border: 1px solid var(--grey500);
      &:focus > div:after {
        content: "";
        position: absolute;
        border-radius: 3.75rem;
        left: -5px;
        top: -4px;
        width: calc(100% + 8px);
        height: calc(100% + 6px);
        border: 1px solid var(--grey500);
        border-radius: 3.75;
      }
    `}

  ${({ tertiary, light, disabled }) =>
    tertiary &&
    disabled &&
    css`
      pointer-events: none;
      max-width: 123px;
      background: transparent;
      color: var(--grey500);
    `}

    ${({ cardbtn }) =>
    cardbtn &&
    css`
      background: var(--grey100);
      width: auto !important;
      max-width: unset !important;
      border: 1px solid var(--grey100);
      padding: 0 10px;
    `}

  ${({ tertiary, dark, disabled }) =>
    tertiary &&
    dark &&
    disabled &&
    css`
      pointer-events: none;
      max-width: 123px;
      color: var(--grey500);
      background: var(--grey200);
    `}
    ${({ small }) =>
    small &&
    css`
      font-size: 12px;
      max-width: 123px;
      width: 123px;
      border: 0;
      div {
        padding: 0.8125rem 0.7rem;
      }
    `}
    ${({ small, light }) =>
    small &&
    light &&
    css`
      color: ${({ disabled }) =>
        disabled ? "var(--grey500)" : "var(--grey900)"};
      background: var(--white);
    `}
    ${({ small, dark }) =>
    small &&
    dark &&
    css`
      border: 1px solid var(--grey500);
    `}

    ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}

    ${({ blue }) =>
    blue &&
    css`
      background: var(--blue);
      &:active {
        background-color: #101736;
      }
      &:focus {
        background: #101736;
      }
    `}
      ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: calc(100% - 8px);
      max-width: none;
    `}
      ${({ topMargin }) =>
    topMargin &&
    css`
      margin-top: ${topMargin}px;
    `}
      ${({ flexGrow }) =>
    flexGrow &&
    css`
      flex-grow: 1;
    `}
        ${({ flexNoGrow }) =>
    flexNoGrow &&
    css`
      flex-grow: 0;
    `}
`;

const Loader = styled(LoaderIcon)`
  height: 1rem;
  padding: 1.125rem 1rem;
  width: ${(props) => props.width && `${props.width + 0.07}ch`};
`;

const InnerButton = styled('div')`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = ({ onClick, showLoader = false, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const mounted = React.useRef(!process.env.JEST_WORKER_ID);
  React.useEffect(() => {
    return () => {
      mounted.current = false; // Cleanup when the component unmounts
    };
  }, []);
  

  return (
    <StyledButton
      {...props}
      onClick={async (...eventData) => {
        // From COAPP-4394: New prop to handle button state after undo click
        if (mounted.current) setLoading(!(props?.notYetLoading && !showLoader));
        await onClick(...eventData);
        if (mounted.current) setLoading(false);
      }}
      data-testid={props["data-testid"]}
      disabled={props.disabled || showLoader || loading}
    >
      {props.loader || loading ? (
        <Loader width={props.text.length} />
      ) : (
        <InnerButton tabIndex="-1">{props.text}</InnerButton>
      )}
    </StyledButton>
  );
};
export default Button;

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17px 24px;
  ${({ showBorder }) =>
    showBorder &&
    css`
      border-top: 1px solid var(--grey100);
    `}

  & button {
    width: 100%;
  }
`;

export const WideButton = (props) => (
  <ButtonWrapper showBorder={props.showBorder}>
    <Button {...props} />
  </ButtonWrapper>
);
const FullScreenButtonWrapper = styled(ButtonWrapper)`
  background-color: var(--white);
`;

export const FullscreenWideButton = (props) => (
  <FullScreenButtonWrapper showBorder={props.showBorder}>
    <Button {...props} />
  </FullScreenButtonWrapper>
);
