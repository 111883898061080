export enum ModalType {
  MainNavigation,
  Profile,
  TaskDetail,
  TeamSelection,
  SuperUsersList,
  StoreEnvironment,
  SwitchTeam,
  SwitchStore,
  SwitchCountry,
  MultiSelectTasks,
}
