import { MobileOnly } from "../../components/MobileOnly";
import { Filter, FilterValue } from "../types";
import { FilterPanelMobile } from "./FilterPanelMobile";
import { DesktopOnly } from "../../components/DesktopOnly";
import { FilterPanelDesktop } from "./FilterPanelDesktop";

interface FilterPanelProps {
  useFiltersFactory: (
    filterValues: FilterValue[],
    setFilterValues: (values: FilterValue[]) => void
  ) => Filter[];
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

// Main component to view filters.
export const FilterPanel = (props: FilterPanelProps) => {
  return (
    <>
      <MobileOnly>
        <FilterPanelMobile {...props} />
      </MobileOnly>
      <DesktopOnly>
        <FilterPanelDesktop {...props} />
      </DesktopOnly>
    </>
  );
};
