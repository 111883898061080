import { WidgetRow } from "../PaddedRow";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { useInputPopup } from "../../../components/InputPopup";
import { TypeOfArticle } from "@coworker/types";
import { isChineseEnvironment } from "@coworker/reusable";

const MiddleWrapper = styled('div')`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

type ArticleSelectionProps = {
  typeOfArticles: TypeOfArticle;
  onConfirm: (value: TypeOfArticle) => void;
};

export function ArticleSelection({
  typeOfArticles,
  onConfirm,
}: ArticleSelectionProps) {
  const { getInput } = useInputPopup();
  const isChina = isChineseEnvironment();

  const transKey: string =
    isChina && typeOfArticles.type === "ACESANDKING"
      ? "acesAndKingWholeStoreOnlyString"
      : typeOfArticles.transKey;

  const selectTypeOfArticles = async () => {
    await getInput("typeOfArticlesPopup", {
      typeOfArticles,
      onConfirm,
    });
  };

  return (
    <WidgetRow
      className="WidgetView"
      middle={
        <MiddleWrapper>
          <b>
            <Trans>{transKey}</Trans>
          </b>
          <p>
            <Trans>typeOfArticlesString</Trans>
          </p>
        </MiddleWrapper>
      }
      right={<></>}
      onClick={selectTypeOfArticles}
    ></WidgetRow>
  );
}
