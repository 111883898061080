import { useState } from "react";
import { FilterValue, GroupFilter } from "../../types";
import { FixaAccordionItem } from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";
import AccordionGroupCss from "./AccordionGroup.module.css";
import { FilterTabs } from "../FilterTabs";

interface AccordionGroupProps {
  filter: GroupFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const AccordionGroup = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
  open,
  onOpenChange,
}: AccordionGroupProps) => {
  const [listBoxIsOpen, setListBoxIsOpen] = useState(false);
  const { id, title } = filter;
  return (
    <FixaAccordionItem
      id={`filter-accordion-${id}`}
      data-testid={`filter-accordion-${id}`}
      title={title}
      className={AccordionGroupCss["accordion-item-title"] ?? ""}
      open={!!open}
      onTransitionEnd={(_event, isOpen) => {
        onOpenChange(isOpen);
      }}
    >
      <FilterTabs
        filter={filter}
        filterValues={filterValues}
        defaultValues={defaultValues}
        setFilterValues={(filterValues) => {
          setFilterValues(filterValues);
          setListBoxIsOpen(!listBoxIsOpen);
        }}
      />
    </FixaAccordionItem>
  );
};
