import { CreateTaskCard } from "../Molecules/CreateTaskCard";
import { ListWrapper } from "../styles/styles";
import { MediaTask } from "@coworker/types/lib/tasks/media";
import { MediasAndArticleCountResponse } from "../types/media";

interface CreateTasksProps {
  mediasArticleCount: MediasAndArticleCountResponse[];
  tasks: MediaTask[];
  currentArticleId?: string | undefined;
}

export function CreateTasks({
  mediasArticleCount,
  tasks,
  currentArticleId,
}: CreateTasksProps) {
  return (
    <ListWrapper>
      {mediasArticleCount.map((rac) => (
        <CreateTaskCard
          key={rac.articleId}
          task={tasks.find((task) => task.article_id === rac.articleId)}
          mediaName={rac.name}
          pieces={rac.nbrArticles}
          isCurrentArticle={
            !currentArticleId ? false : rac.articleId === currentArticleId
          }
          articleId={rac.articleId}
          mediaId={rac.mediaId}
          groupName={rac.groupName ?? ""}
          groupId={rac.groupId ?? ""}
        />
      ))}
    </ListWrapper>
  );
}
