export const localeIncludes = (str: string, searchStr: string) => {
  const stringLength = str.length;
  const searchStringLength = searchStr.length;
  const lengthDiff = stringLength - searchStringLength;

  if (searchStr.length === 0) {
    return true;
  }
  for (let idx = 0; idx <= lengthDiff; idx++) {
    if (
      str
        .substring(idx, idx + searchStringLength)
        .localeCompare(searchStr, undefined, { sensitivity: "accent" }) === 0
    ) {
      return true;
    }
  }
  return false;
};
