import {useCallback,useState,useMemo,useEffect} from "react";
import styled from "styled-components";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";
import { Trans } from "@coworker/locales";
import { Header, RadioButton, WideButton } from "@coworker/components";
import {
  periods,
  areaTabs,
  initialAreaTab,
  AREA_TAB,
  areaIsWholeStore,
  defaultCustomPeriod,
} from "./filterChoices";
import { useUserFilters } from "./useUserFilters";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/enums/profilePreferences";
import tracker from "../../../helpers/tracker";
import CustomPeriodOptions from "./CustomPeriodOptions";
import { DAY } from "@coworker/enums/insightsGroupTypes";
import { BooleanFlag } from "@coworker/components";
import { DivisionComponent } from "./Divisions/DivisionComponent";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { isEqual } from "../../../helpers/utils";

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background: var(--white);
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--grey900);
`;
const RadioButtonWrapper = styled('div')`
  min-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FiltersWrapper = styled('div')`
  overflow: scroll;
  height: 100%;
`;
const Filter = styled('div')`
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 39px;
`;
const FilterName = styled('div')`
  margin-right: 20px;
  font-size: 14px;
`;
const Title = styled('div')`
  margin: 10px 29px;
  font-weight: bold;
  font-size: 18px;
`;
const TitleToggle = styled(Title)`
  height: 30px;
`;
// WET & DRY comments:
// The above is exact reuse from TaskFilters and is worth generalizing next time it's copied.
// Below follows UserFilter-specifics, but `Tabs` might be next in turn to be standardised.

const HorizontalLine = styled('div')`
  width: 100%;
  margin: 30px 0;
  border-bottom: 1px solid var(--grey200);
`;

const Tab = styled('div')`
  ${({ active }) =>
    active && `color: var(--grey900); border-bottom: 3px solid var(--new);`}
`;
const MarginTop = styled('div')`
  margin-top: 20px;
`;

const Tabs = styled('div')`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey600);
  border-bottom: 1px solid var(--grey200);
  margin-left: 28px;
  margin-right: 20px;
  & > div {
    height: 20px;
    padding: 14px 2px;
    margin: 0 8px;
  }
  & > div:first-child {
    margin-left: 0;
  }
  & > div:last-child {
    margin-right: 0;
  }
`;

const noop = () => null;

export function UserFilterPopup({
  title,
  actionText,
  onSubmit,
  onSetFilters = noop,
  filters,
  hidePeriodFilters = false,
  hideWholeStore = false,
  noPersist = false,
  hideShowTasksToggle = false,
  settingsPrefix = profilePreferences.MFAQ_PREFIX,
}) {
  const [nextFilters, updateNextFilters] = useUserFilters(filters);
  const enableDivisionsArea = false;

  const [activeTab, setActiveTab] = useState(
    areaIsWholeStore(nextFilters) && hideWholeStore
      ? AREA_TAB.DEPTS
      : initialAreaTab(nextFilters)
  );
  const { pathname } = useFixaLocation();

  const [selectedUserFilters, persistFilters] = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS,
    []
  );
  const [showQuestionsWithTask, setShowQuestionsWithTask] = useUserPreference(
    settingsPrefix + profilePreferences.SHOW_QUESTIONS_WITH_TASKS,
    false
  );

  const [showOnlyQuestionsWithData, setShowOnlyQuestionsWithData] =
    useUserPreference(
      settingsPrefix + profilePreferences.SHOW_ONLY_QUESTIONS_WITH_DATA,
      false
    );
  const store_id = useStoreId();
  const displayTabs = areaTabs.filter((tab) => {
    if (hideWholeStore) {
      return tab.key !== AREA_TAB.WHOLE;
    } else if (settingsPrefix === profilePreferences.TESTBUY_PREFIX) {
      return tab.key !== AREA_TAB.OTHER;
    }
    return true;
  });
  // Maps a `tab` to a <Tab/>
  const tabsMaker = ({ key, name }) => (
    <Tab
      key={key}
      data-testid={`filterTab-${key}`}
      active={activeTab === key}
      onClick={() => setActiveTab(key)}
    >
      {name}
    </Tab>
  );
  // Maps a `tab` to a <ChoiceItems/>
  const itemsMaker = useCallback(
    (items) => {
      return (
        <ChoiceItems
          items={items}
          isChecked={({ type }) => nextFilters.includes(type)}
          setChecked={updateNextFilters}
        />
      );
    },
    [nextFilters, updateNextFilters]
  );

  const items = useMemo(() => {
    const currentTab = displayTabs.find((tab) => tab.key === activeTab);
    return currentTab?.items;
  }, [activeTab, displayTabs]);
  const tabItems = itemsMaker(items);
  const [customPeriodPreference, setCustomPeriodPreference] = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS_CUSTOM_PERIOD,
    defaultCustomPeriod()
  );
  const [graphOptionsPreference, setGraphOptionsPreference] = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS_GRAPH_OPTIONS,
    DAY
  );

  const [customStartDate, setCustomStartDate] = useState(
    customPeriodPreference[0]
  );
  const [customEndDate, setCustomEndDate] = useState(
    customPeriodPreference[1]
  );
  const [customGrouping, setCustomGrouping] = useState(
    graphOptionsPreference
  );

  const onCustomDatesChange = (newStartDate, newEndDate) => {
    newStartDate !== customStartDate && setCustomStartDate(newStartDate);
    newEndDate !== customEndDate && setCustomEndDate(newEndDate);
  };

  const onCustomGroupingChange = useCallback(
    (newGrouping) => {
      newGrouping !== customGrouping && setCustomGrouping(newGrouping);
    },
    [customGrouping]
  );

  const isWholeStoreTab = activeTab === AREA_TAB.WHOLE;

  // setShowQuestionsWithTask to false when whole area is selected
  useEffect(() => {
    if (isWholeStoreTab && showQuestionsWithTask) {
      setShowQuestionsWithTask(false);
    }
  }, [isWholeStoreTab, setShowQuestionsWithTask, showQuestionsWithTask]);

  useEffect(() => {
    if (nextFilters.includes("period=custom") && !showOnlyQuestionsWithData) {
      setShowOnlyQuestionsWithData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextFilters]);

  //checks whole store item when tab is clicked
  useEffect(() => {
    if (isWholeStoreTab) {
      tabItems.props.setChecked(tabItems.props.items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWholeStoreTab]);

  const isMfaq =
    pathname === "/customerquestions/new" || pathname === "/customerquestions";

  return (
    <Container>
      <Header title={title} showRightClose border />
      <FiltersWrapper>
        {!hidePeriodFilters && (
          <>
            <Title data-testid="periodFiltersBlock">
              <Trans>periodString</Trans>
            </Title>
            {itemsMaker(periods)}
            {nextFilters.includes("period=custom") && (
              <CustomPeriodOptions
                onDatesChange={onCustomDatesChange}
                onGroupingChange={onCustomGroupingChange}
                settingsPrefix={settingsPrefix}
              />
            )}
            {!isWholeStoreTab && !hideShowTasksToggle && (
              <>
                <TitleToggle data-testid="showQuestionWithTasksTitle">
                  <Trans>TaskString</Trans>
                </TitleToggle>
                <FilterName>
                  <BooleanFlag
                    title={<Trans>onlyQuestionsWithTasksString</Trans>}
                    checked={showQuestionsWithTask}
                    onToggle={() => {
                      setShowQuestionsWithTask(!showQuestionsWithTask);
                    }}
                    titleMargin={"0"}
                    mfaqFilter={true}
                  />
                </FilterName>
                <HorizontalLine />
              </>
            )}
          </>
        )}

        <>
          <MarginTop />
          <Title data-testid="areaFiltersBlock">
            <Trans>areaString</Trans>
          </Title>
          {enableDivisionsArea && !isMfaq ? (
            <DivisionComponent
              storeId={store_id}
              setChecked={updateNextFilters}
            />
          ) : (
            <>
              {" "}
              <Tabs>{displayTabs.map(tabsMaker)}</Tabs>
              {tabItems}
            </>
          )}
        </>
      </FiltersWrapper>
      <WideButton
        primary
        dark
        text={actionText}
        onClick={() => {
          if (!noPersist) {
            if (!isEqual(selectedUserFilters, nextFilters))
              persistFilters(nextFilters);
            if (
              !isEqual(customPeriodPreference, [customStartDate, customEndDate])
            )
              setCustomPeriodPreference([customStartDate, customEndDate]);
            if (!isEqual(graphOptionsPreference, customGrouping))
              setGraphOptionsPreference(customGrouping);
          }
          onSetFilters(nextFilters);
          tracker.UserFilters.trackFilterChange(store_id, filters, nextFilters);
          onSubmit();
        }}
        data-testid={"filterApplyButton"}
      />
    </Container>
  );
}

function ChoiceItems({ items, isChecked, setChecked }) {
  if (!items?.length) return null;
  return items.map((item) => (
    <Filter
      key={item.type}
      data-testid={`filter-${item.type}`}
      onClick={() => setChecked(item)}
    >
      <FilterName>{item.name}</FilterName>
      <RadioButtonWrapper>
        <RadioButton readOnly checked={isChecked(item)} />
      </RadioButtonWrapper>
    </Filter>
  ));
}
