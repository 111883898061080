import { CriteriaState, Criterion as CriterionT } from "@coworker/types";
import {useCallback} from "react";
import styled, { css } from "styled-components";
import { Button } from "@coworker/components";
import { Trans } from "@coworker/locales";
import useCriterionSVG from "./useCriterionSVG";
import { CriteriaReducerAction } from "./updateCriterionReducer";

const Container = styled('li')`
  border-bottom: 1px solid var(--grey200);
  &:last-child {
    border-bottom: none;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 1.5rem 1.5rem 1.5rem;
  ${(p: { isLocked: boolean }) =>
    p.isLocked &&
    css`
      pointer-events: none;
    `}
`;

interface CriteriaButtonProps {
  selected: boolean;
  color: "--pink" | "--green"; // TODO change to type union of all colors
  isLocked: boolean;
}

const CriteriaButton = styled(Button)`
  border: 1px solid var(--grey600);
  border: 1px solid
    var(
      ${(p: CriteriaButtonProps) =>
        p.isLocked ? "--disabled" : p.selected ? p.color : "--grey200"}
    );
  color: var(
    ${(p: CriteriaButtonProps) =>
      p.selected ? "--white" : p.isLocked ? "--disabled" : p.color}
  );
  background-color: var(
    ${(p: CriteriaButtonProps) =>
      p.isLocked && p.selected
        ? "--disabled"
        : p.selected
        ? p.color
        : "--white"}
  );
`;

const QuestionContainer = styled('div')`
  display: flex;
  padding: 1.5rem;
  flex-direction: row;
  align-items: center;
`;

const TextContainer = styled('div')`
  padding: 0 1rem 0 1rem;
  font-size: 14px;
`;
const DescriptionText = styled('div')`
  color: var(--grey600);
`;
const IconContainer = styled('div')`
  width: 20px;
`;

export interface CriterionProps {
  testbuyId: string;
  articleId: string;
  criterion: CriterionT;
  dispatch: Function;
  isLocked: boolean;
  location: string;
  price: number;
}

export const Criterion = ({
  testbuyId,
  articleId,
  criterion,
  dispatch,
  isLocked,
  location,
  price,
}: CriterionProps) => {
  const updateCriterion = useCallback(
    async (newState: CriteriaState) => {
      if (
        newState === "NOT_OK" &&
        criterion.key === "IsItDisplayed" &&
        criterion.state !== "NOT_OK"
      ) {
        const action: CriteriaReducerAction = {
          criterion: criterion,
          type: "updateCriterionIsDisplayedNotOk",
          testBuyId: testbuyId,
          articleId: articleId,
        };
        dispatch(action);
      } else {
        const action: CriteriaReducerAction = {
          criterion: criterion,
          type: "updateCriterion",
          newState: newState,
          testBuyId: testbuyId,
          articleId: articleId,
        };
        dispatch(action);
      }
    },
    [articleId, criterion, dispatch, testbuyId]
  );

  return (
    <Container data-testid={`criterion-${criterion.key}`}>
      <QuestionContainer>
        <IconContainer>{useCriterionSVG(criterion.key)}</IconContainer>
        <TextContainer>
          <strong>
            <Trans>{"tb" + criterion.key}</Trans>
          </strong>
          {criterion.key === "IsItCleanAndPerfect" && (
            <DescriptionText>
              <Trans>{"tb" + criterion.key + "Description"}</Trans>
            </DescriptionText>
          )}
          {criterion.key === "IsItAvailableInIndicatedPosition" && (
            <DescriptionText>
              {location !== "no_location" && (
                <>
                  <Trans>pickupLocation</Trans>: {location}
                </>
              )}
            </DescriptionText>
          )}
          {criterion.key === "DoesItHaveTag" && (
            <DescriptionText>
              <Trans>priceQT</Trans>: {price}
            </DescriptionText>
          )}
        </TextContainer>
      </QuestionContainer>
      <ButtonContainer isLocked={isLocked}>
        <CriteriaButton
          isLocked={isLocked}
          selected={criterion.state === "NOT_OK"}
          color={"--pink"}
          fullWidth
          text={<Trans>tbNotOkay</Trans>}
          data-testid={`criterionStateNotOkay-${criterion.key}`}
          onClick={async () => await updateCriterion("NOT_OK")}
        />
        <CriteriaButton
          isLocked={isLocked}
          selected={criterion.state === "OK"}
          color={"--green"}
          fullWidth
          text={<Trans>tbOkay</Trans>}
          data-testid={`criterionStateOkay-${criterion.key}`}
          onClick={async () => await updateCriterion("OK")}
        />
      </ButtonContainer>
    </Container>
  );
};
