import styled from "styled-components";

export const DetailSection = styled('div')`
  padding: 26px 28px;
  border-bottom: 1px solid var(--grey100);
  display: flex;
  flex-direction: row;
`;

export const SectionTitle = styled('div')`
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey900);
`;

export const SectionText = styled('div')`
  line-height: 21px;
  font-size: 14px;
  line-height: 1.5;
  color: var(--grey700);
  overflow: hidden;
`;

export const DetailSectionLeft = styled('div')`
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;

export const DetailSectionRight = styled('div')`
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 10px;
`;
