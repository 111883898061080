import { useMemo } from "react";
import { AlertResponse, Hfb, Media } from "../types/media";
import { FilterValue } from "@coworker/apprestructured/src/shared/filters/types";
import { OngoingTaskLinksResponse } from "../types/taskLink";

export const useFilteredMedias = (
  medias: (Media & Hfb)[] | undefined,
  alerts: AlertResponse[] | undefined,
  tasks: OngoingTaskLinksResponse,
  selectedMediaTypeTab: string,
  filterValues: FilterValue[]
): Media[] | undefined => {
  return useMemo(() => {
    if (!medias) return [];

    let filteredMedias = medias;

    if (selectedMediaTypeTab !== "allMedia") {
      filteredMedias = filteredMedias.filter(
        (media) => media.mediaType === selectedMediaTypeTab
      );
    }

    filterValues.forEach(({ filterId, value }) => {
      if (!value) return;

      switch (filterId) {
        case "status":
          filteredMedias = filteredMedias.filter(
            (media) => media.active === value
          );
          break;
        case "area":
          filteredMedias = filteredMedias.filter(
            (media) => media.hfb === value
          );
          break;
        case "alerts":
          filteredMedias = filteredMedias.filter((media) => {
            const alert = alerts?.find(
              (a) => String(a.mediaId) === String(media.id)
            );
            const hasOngoingTask = tasks?.tasks?.some(
              (task) => String(task.media_id) === String(media.id)
            );

            if (value === "salesStop") return alert?.salesStop! > 0;
            if (value === "eds") return alert?.eds! > 0;
            if (value === "ongoingTasks") return hasOngoingTask;

            return false;
          });
          break;
        case "sortBy":
          if (value === "newest") {
            filteredMedias = [...filteredMedias].sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );
          } else if (value === "oldest") {
            filteredMedias = [...filteredMedias].sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            );
          } else if (value === "name") {
            filteredMedias = [...filteredMedias].sort((a, b) =>
              a.name.localeCompare(b.name)
            );
          }
          break;
        default:
          break;
      }
    });

    return filteredMedias;
  }, [medias, selectedMediaTypeTab, filterValues, alerts, tasks]);
};
