import { Hfb, Media } from "../../../types/media";
import { useTranslation } from "@coworker/locales";
import {
  CardFrame,
  FullLine,
  PaddedContainer,
  WhiteSpace,
} from "../../../styles/styles";
import { CardHeadLine } from "../../../Molecules/Settings/CardHeadLine";
import { getMediaTypeText } from "../../../tools";
import { MediaFormFieldTypes, MediaTypes } from "../../../constants";
import { SquareMetersInput } from "../../../Molecules/Settings/SquareMetersInput";
import MediaFormField from "../../../Molecules/Settings/MediaFormField";

interface LocationAndSizeSectionProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const LocationAndSizeSection = ({
  media,
  onSave,
}: LocationAndSizeSectionProps) => {
  const { t } = useTranslation();

  const headLineString =
    media.mediaType === MediaTypes.ROOM_SETTINGS
      ? "locationAndSizeOfString"
      : "locationOfString";
  return (
    <CardFrame>
      <CardHeadLine
        character={"3"}
        headline={t(headLineString, {
          name: getMediaTypeText(media.mediaType as MediaTypes),
        })}
      />
      <FullLine />
      <PaddedContainer $py="0px" $px="24px">
        {media.mediaType !== MediaTypes.NONE && (
          <MediaFormField
            type={MediaFormFieldTypes.HFB}
            media={media}
            onSave={onSave}
            valid={(media as Hfb).hfb ? true : false}
          />
        )}
        <WhiteSpace height={"12"} />

        {media.mediaType === MediaTypes.ROOM_SETTINGS && (
          <SquareMetersInput media={media} onSave={onSave} />
        )}
      </PaddedContainer>
      <WhiteSpace />
    </CardFrame>
  );
};
