import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export async function createTask(data: {}) {
  const path = `/v1/task/create`;
  const url = `${TASKS_SERVICE_URL}${path}`;
  const { result } = await fetchAPI("POST", url, data, "json");
  return result;
}

export function useCreateTaskMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { data: {} }) => {
      return await createTask(data.data);
    },
    onSuccess: (data) => {
      const taskId = data?.task_id;
      queryClient.setQueryData(["task", "id", taskId, data.task_type], data);
    },
    onError: (error) => {
      console.log("Error while creating task", error);
    },
  });
}
