import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Checkmark } from "../assets/svg/Checkmark.svg";

const Switch = styled('div')`
  position: relative;
  appearance: none;
  cursor: pointer;
  min-width: 50px;
  height: 24px;
  ${(props) =>
    props.focus &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: -3px;
        width: 54px;
        height: 26px;
        border: ${props.noFocusBorder ? "0" : "1px solid var(--grey500)"};
        border-radius: 30px;
      }
    `}
`;

const Input = styled('input')`
  position: absolute;
  appearance: none;
  cursor: pointer;
  width: 50px;
  height: 24px;
  border-radius: 30px;
  top: 0;
  left: 0;
  background: var(--grey500);
  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 3px;
    left: 3px;
    background: white;
    height: 18px;
    width: 18px;
    border-radius: 50%;
  }
  &:checked {
    background: ${(props) => (props.blue ? "var(--blue)" : "var(--grey900)")};
  }
  &:checked::after {
    content: "";
    position: absolute;
    display: block;
    top: 3px;
    left: 29px;
    background: white;
    height: 18px;
    width: 18px;
    border-radius: 50%;
  }

  &:active {
    background: var(--grey700);
  }
  &:checked:active {
    background: ${(props) => (props.blue ? "var(--blue)" : "var(--grey900)")};
  }
`;

const ConditionalCheckmark = styled(Checkmark)`
  ${({ checked }) => !checked && `display: none;`}
  z-index: 1; /* TODO: rework to remove this as it causes a display bug */
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%) translateX(-50%);
`;

const Toggle = ({ testId, onChange, ...props }) => {
  const [isFocused, setFocus] = React.useState(false);
  const [isPressed, setPressed] = React.useState(false);

  const focus = React.useCallback(() => {
    setFocus(true);
  }, []);

  const blur = React.useCallback(() => {
    setFocus(false);
  }, []);

  const dontFocus = () => {
    setPressed(true);
  };

  const allowFocus = () => {
    setPressed(false);
  };

  return (
    <Switch {...props} focus={!isPressed && isFocused}>
      <ConditionalCheckmark checked={props.checked} />
      <Input
        readOnly
        {...props}
        data-testid={testId}
        type="checkbox"
        onChange={onChange}
        onFocus={focus}
        onBlur={blur}
        onMouseDown={dontFocus}
        onMouseUp={allowFocus}
      />
    </Switch>
  );
};

export default Toggle;
