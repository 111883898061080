import { useTranslation } from "@coworker/locales";
import React from "react";
import styles from "./assign-to-user-or-team.module.css";
import { FixaListView } from "../../wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../wrappers/FixaListView/FixaListViewItem";
import { logEvent } from "@coworker/app/src/helpers/tracker";
import { TeamSuggestions } from "./TeamSuggestions/TeamSuggestions";
import { TeamsAndUsersList } from "./TeamsAndUsersList/TeamsAndUsersList";

interface AssignToUserOrTeamProps {
  itemNo: string;
  showSuggestions?: boolean;
  assignees: {
    uid: string;
    gid: string;
  };
  myUserAndTeamId: {
    userId: string;
    teamId: string;
  };
  onSubmit: (args: { name: string; uid: string; gid: string }) => void;
  onKeepTaskClick?: () => void;
  handoff: boolean;
  spacer?: boolean;
  searchQuery?: string;
  isPQR?: boolean;
  overflow?: boolean;
}

interface SubmitItem {
  name: string;
  uid?: string;
  gid?: string;
  team_id?: string;
}

export function AssignToUserOrTeam({
  itemNo,
  showSuggestions,
  assignees,
  myUserAndTeamId,
  searchQuery = "",
  onSubmit,
  onKeepTaskClick,
  handoff,
  spacer = true,
  overflow = true,
  isPQR = false,
}: AssignToUserOrTeamProps) {
  const { uid, gid } = assignees || {};
  const checkIfSelected = (id: string) => {
    if (uid) return id === uid;
    else if (gid) return id === gid;
    return false;
  };

  const { t } = useTranslation();

  const doSubmit = React.useCallback(
    (item: SubmitItem) => {
      onSubmit({
        name: item.name,
        uid: item.uid || "",
        gid: item.gid || item.team_id || "",
      });
    },
    [onSubmit]
  );

  return (
    <div
      data-testid="assignBackground"
      className={`${styles["container"]}
        ${spacer && styles["container-spacer"]}
        ${overflow && styles["container-overflow"]}
      `}
    >
      {handoff && (
        <>
          <FixaListView id="keep-task">
            <FixaListViewItem
              className={styles["keep-task"] || ""}
              inset
              title={t("keepString")}
              control="radiobutton"
              controlProps={{
                checked: checkIfSelected(myUserAndTeamId.userId) as boolean,
                value: myUserAndTeamId.userId,
              }}
              onChange={() => {
                if (typeof onKeepTaskClick === "function") {
                  onKeepTaskClick();
                }
                doSubmit({
                  name: t("meString"),
                  uid: myUserAndTeamId.userId,
                  gid: myUserAndTeamId.teamId,
                });
                logEvent("ce:assign_to", {
                  type: "coworker",
                  coworker: "me",
                });
              }}
            />
          </FixaListView>
        </>
      )}
      {showSuggestions && itemNo && (
        <TeamSuggestions
          itemNo={itemNo}
          assignedTeamId={gid}
          doSubmit={doSubmit}
        />
      )}
      <TeamsAndUsersList
        isPQR={isPQR}
        searchQuery={searchQuery}
        checkIfSelected={checkIfSelected}
        doSubmit={doSubmit}
        myUserAndTeamId={myUserAndTeamId}
      />
    </div>
  );
}
