import FixaPill from "../../../wrappers/FixaPill/FixaPill";
import { Filter, FilterValue } from "../../types";
import PillCheckboxCss from "./PillCheckbox.module.css";

interface PillCheckboxProps {
  filter: Filter;
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const PillCheckbox = ({
  filter,
  filterValues,
  setFilterValues,
}: PillCheckboxProps) => {
  const checked = filterValues.some((value) => value.filterId === filter.id);

  const handleCheckboxClick = () => {
    const updatedFilters = filterValues.filter(
      (value) => value.filterId !== filter.id
    );

    setFilterValues(
      checked
        ? updatedFilters
        : [...updatedFilters, { filterId: filter.id, value: true }]
    );
  };

  return (
    <FixaPill
      id={filter.id}
      size="small"
      label={
        <div className={PillCheckboxCss["pill-wrapper"]}>
          {filter.title}
          <input
            className={PillCheckboxCss["checkbox"]}
            type="checkbox"
            checked={checked}
          />
        </div>
      }
      onClick={handleCheckboxClick}
    />
  );
};
