import React from "react";
import { CreateTask } from "./CreateTask";
import { useInputPopup } from "./InputPopup";
import { OfflineScreen } from "@coworker/components";
import { Trans } from "@coworker/locales";
import { Heading } from "./Layout/Heading";
import { FlexContainer } from "./Layout/styles";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../hooks/useWorkspaces";
import { useProductQualityTeamId } from "../hooks/useProductQualityTeamId";

const CreateTaskScreen = ({ isOnline }) => {
  const { push, setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const { getInput } = useInputPopup();

  const qualityTeamId = useProductQualityTeamId();
  const onCreateTask = React.useCallback(
    async (type, updateLocationState) => {
      switch (type) {
        case "custom": {
          push(`/task/new/custom`);
          break;
        }
        case "product":
        case "product_quality":
        case "addon": {
          if (updateLocationState) {
            setNavigationState({
              search: true,
              type,
            });
          }
          const product = await getInput("scansearch", { start: new Date() });
          if (product) {
            push(`/task/new/${type}`, { product, qualityTeamId });
          }
          break;
        }
        default:
          if (!["ART", "SPR"].includes(type)) {
            // It's possible to have nagivationState from another screen and end up on this screen, so we don't want to throw in that case.
            throw new Error(`Invalid issue type: "${type}"`);
          }
      }
    },
    [getInput, push, setNavigationState, qualityTeamId]
  );

  React.useEffect(() => {
    if (navigationState && navigationState.search && navigationState.type) {
      onCreateTask(navigationState.type, true);
    }
  }, [navigationState,onCreateTask]);


  return (
    <FlexContainer>
      <Heading
        title={<Trans>create2String</Trans>}
        alwaysSticky
        showBack={false}
      />

      {!isOnline ? (
        <OfflineScreen />
      ) : (
        <CreateTask onCreateTask={onCreateTask} />
      )}
    </FlexContainer>
  );
};

export default CreateTaskScreen;
