import type { StoreTeam } from "@coworker/types/lib/storeteam";
import { useTeamId, useStoreId } from "../auth/useLoggedInUser";
import {
  getUserFixaUidsForTeamId,
  getStoreTeams,
} from "../../hooks/API/core.service";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "../../constants/clientTime";

export function useStoreTeams() {
  const storeId = useStoreId();
  const result = useQuery<StoreTeam[]>({
    queryKey: ["storeTeams", storeId],
    queryFn: async () => await getStoreTeams(storeId),
    staleTime: 2 * INTERVAL.MINUTE,
  });
  return (result?.data as StoreTeam[]) ?? [];
}

export function useTeamsByStore(storeId: string) {
  const result = useQuery<StoreTeam[]>({
    queryKey: ["storeTeams", storeId],
    queryFn: async () => await getStoreTeams(storeId),
    staleTime: 2 * INTERVAL.MINUTE,
  });
  return result;
}

export async function useTeamFixaUids(teamId: string) {
  const result = useQuery<string[]>({
    queryKey: ["storeTeams", teamId],
    queryFn: async () => await getUserFixaUidsForTeamId(teamId),
    staleTime: 5 * INTERVAL.MINUTE,
    enabled: !!teamId,
  });

  return result?.data ?? [];
}

export function useMyTeam() {
  const teamId = useTeamId();
  return useStoreTeams().find((team: StoreTeam) => team.id === teamId);
}
