
import { ListImage } from "../Atoms/ListImage";
import { SimpleProductIdentifier } from "@coworker/apprestructured/src/shared/simple/SimpleProductIdentifier/SimpleProductIdentifier";
import { Text } from "../Atoms/Text";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import {
  CenterContainer,
  LeftContainer,
  RightContainer,
  FlexibleRowContainer,
} from "../styles/styles";
import {
  Article,
  ArticleWithMediaCount,
  isArticleWithMediaCount,
} from "../types/article";
import { formatFullIdWithDots } from "@coworker/reusable";
import { useTranslation } from "@coworker/locales";
interface ArticleListItemDetailsProps {
  article: Article | ArticleWithMediaCount;
  showActionRow: boolean;
  selectArticleIdsMode: boolean;
  onSelectedArticleChange: () => void;
  isSelected: boolean;
  hideChevron: boolean;
}

export function ArticleListItemDetails({
  article,
  selectArticleIdsMode,
  onSelectedArticleChange,
  hideChevron,
  showActionRow,
}: ArticleListItemDetailsProps) {
  const { t } = useTranslation();

  return (
    <FlexibleRowContainer
      onClick={() => {
        if (selectArticleIdsMode) {
          onSelectedArticleChange();
        }
      }}
    >
      <LeftContainer>
        <ListImage src={article.imageSmall} alt={""} />
      </LeftContainer>
      <CenterContainer $centerText={false}>
        <Text text={article.itemName} bold />
        <SimpleProductIdentifier
          articleId={formatFullIdWithDots(article.productArticleId)}
        />
        <Text text={article.itemType} grey />
        {!showActionRow && isArticleWithMediaCount(article) && (
          <Text
            text={t("inXMediaString", {
              count: article.inAmountOfMedias,
            })}
            grey
            bold
          />
        )}
      </CenterContainer>
      <RightContainer>
        {!selectArticleIdsMode && !hideChevron && <RightArrow />}
      </RightContainer>
    </FlexibleRowContainer>
  );
}
