import React from "react";
import { LivingSituation, Media } from "../../types/media";
import {
  FixaOption,
  FixaSelect,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import { useTranslation } from "@coworker/locales";
import FamilyGenderMixedIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FamilyGenderMixedIconPath";
import { getLivingSituationOptions } from "../../../../helpers/livingSituationOptions";

interface LivingSituationSelectProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const LivingSituationSelect = ({
  media,
  onSave,
}: LivingSituationSelectProps) => {
  const { t } = useTranslation();
  return (
    <FixaSelect
      id={"living_situation"}
      label={t("livingSituationString")}
      ssrIcon={FamilyGenderMixedIconPath}
      value={(media as LivingSituation).livingSituation ?? ""}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const newLivingSituation =
          event.target.selectedIndex === 0 ? "" : event.target.value;
        const newMedia = { ...media };
        (newMedia as LivingSituation).livingSituation = newLivingSituation;
        onSave(newMedia);
      }}
    >
      {getLivingSituationOptions().map((livingSituation) => (
        <FixaOption {...livingSituation} key={livingSituation.value} />
      ))}
    </FixaSelect>
  );
};
