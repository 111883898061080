import { ArticleIdNbrArticles } from "../types/article";
import { TaskLinks } from "../types/taskLink";

export const isMainProduct = (
  articleId: string,
  mediasMainProductId: string,
  mediasSecondaryMainProductId: string
): boolean => {
  return (
    articleId === mediasMainProductId ||
    articleId === mediasSecondaryMainProductId
  );
};

export const isAlternativeForProduct = (
  articleId: string,
  mediasAlternativeProductId: string,
  mediasSecondaryAlternativeProductId: string
): boolean => {
  return (
    articleId === mediasAlternativeProductId ||
    articleId === mediasSecondaryAlternativeProductId
  );
};

export const hasOngoingTask = (articleId: string, taskLinks: TaskLinks) =>
  taskLinks.some((task) => task.articleId === articleId);

export const selectArticleIdNbrArticles = (
  articleIdNbrArticles: ArticleIdNbrArticles,
  selectedArticleIdsNbrArticles: ArticleIdNbrArticles[],
  setSelectedArticleIdsNbrArticles: (value: ArticleIdNbrArticles[]) => void
) => {
  const foundArticleIdNbrArticles = selectedArticleIdsNbrArticles.find(
    (item) => item.articleId === articleIdNbrArticles.articleId
  );
  if (
    !!foundArticleIdNbrArticles &&
    foundArticleIdNbrArticles?.nbrArticles !== articleIdNbrArticles.nbrArticles
  ) {
    foundArticleIdNbrArticles.nbrArticles = articleIdNbrArticles.nbrArticles;
    return;
  }

  let newSelectedArticleIds = [];
  if (!!foundArticleIdNbrArticles) {
    newSelectedArticleIds = selectedArticleIdsNbrArticles.filter(
      (item) => item.articleId !== articleIdNbrArticles.articleId
    );
  } else {
    newSelectedArticleIds = [
      ...selectedArticleIdsNbrArticles,
      articleIdNbrArticles,
    ];
  }

  setSelectedArticleIdsNbrArticles(newSelectedArticleIds);
};
