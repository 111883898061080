import {ReactNode} from "react";
import FormField from "@ingka/form-field";
type FixaFormFieldProps = {
  id?: string | undefined;
  children: ReactNode;
  "data-testid"?: string;
  shouldValidate?: boolean;
  valid?: boolean;
  className?: string;
  disabled?: boolean;
  characterLimit?: number;
  fieldHelper?: {
    type?: "";
    msg?: string;
    id?: string;
  };
  validation?: {
    type?: "error" | "";
    msg?: string;
    id?: string;
  };
};

export const FixaFormField = (props: FixaFormFieldProps) => {
  return <FormField {...props} />;
};
