import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type AmountSettings = {
  pieces_per_multi_pack: string | null;
  pieces_per_pallet: string | null;
};

async function fetchItemAmountSettings(itemNo: string) {
  const url = `${TASKS_SERVICE_URL}/v1/shared/amount_settings?short_id=${itemNo}`;
  const { result } = await fetchAPI("GET", url);
  return result;
}

async function updateItemAmountSettings(
  itemNo: string,
  amounts: AmountSettings
) {
  console.log("updateItemAmountSettings", itemNo, amounts);
  const url = `${TASKS_SERVICE_URL}/v1/shared/amount_settings?short_id=${itemNo}`;
  const bodyData = {
    short_id: itemNo,
    // TODO: Will parseInt return NaN here if we have bad data from frontend / from previously stored data?
    pieces_per_multi_pack:
      amounts?.pieces_per_multi_pack !== "" &&
      amounts?.pieces_per_multi_pack !== null
        ? parseInt(amounts?.pieces_per_multi_pack)
        : null,
    pieces_per_pallet:
      amounts?.pieces_per_pallet !== "" && amounts?.pieces_per_pallet !== null
        ? parseInt(amounts?.pieces_per_pallet)
        : null,
  };
  const { result } = await fetchAPI("PUT", url, bodyData);
  return result;
}

export function useItemAmountSettings(itemNo: string) {
  return useQuery({
    queryKey: ["Item", "amountSettings", itemNo],
    queryFn: async () => await fetchItemAmountSettings(itemNo),
    staleTime: 86400000, // 1 day, considered fresh for a day.
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!itemNo,
  });
}

export const useUpdateItemAmountSettings = (itemNo: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (amounts: AmountSettings) =>
      updateItemAmountSettings(itemNo, amounts),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["Item", "amountSettings", itemNo],
      });
    },
  });
};
