import { GroupFilter, FilterValue } from "../../types";
import { PillGroup } from "../PillGroup";

interface InputGroupPillDesktopProps {
  filter: GroupFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const InputGroupPillDesktop = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
}: InputGroupPillDesktopProps) => {
  return (
    <PillGroup
      filter={filter}
      filterValues={filterValues}
      defaultValues={defaultValues}
      setFilterValues={setFilterValues}
    />
  );
};
