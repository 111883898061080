import { useTranslation } from "@coworker/locales";
import { useCustomAllSelectOption } from "../../shared/filters/utils";
import { FilterType, InputType } from "../../shared/filters/types";

const PERIOD_ID = "period";

export function usePeriodFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: PERIOD_ID, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: PERIOD_ID,
      title: t("periodString"),
      options: [
        {
          value: "today",
          title: t("todayString"),
        },
        {
          value: "last7days",
          title: t("last7DaysString"),
        },
        {
          value: "last4weeks",
          title: t("last4WeeksString"),
        },
      ],
      enabled: true,
      defaultValue,
    },
    t("noneString")
  );
}
