
import { Articles } from "../types/article";
import { Group } from "../types/groups";
import { ArticlesImagesOverview } from "./ArticlesImagesOverview";
import {
  ArticleGroupRowContainer,
  CenterContainer,
  FullSpaceAnchor,
  RightContainer,
} from "../styles/styles";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { Text } from "../Atoms/Text";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

interface ArticleGroupItemProps {
  group: Group;
  articles: Articles;
  mediaId: string;
}

export const ArticleGroupItem = ({
  articles,
  group,
  mediaId,
}: ArticleGroupItemProps) => {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const articlesInGroup = articles.filter(
    (article) => article.groupId === group.id
  );
  const articleCount = articlesInGroup.length;
  const pieceCount = articlesInGroup.reduce(
    (accumulator, currentValue) => (accumulator += currentValue.nbrArticles),
    0
  );

  return (
    <FullSpaceAnchor
      href={`/mediamaintenance/groups/${group.id}?mediaId=${mediaId}`}
      onClick={(event) => {
        event.preventDefault();
        push(`/mediamaintenance/groups/${group.id}?mediaId=${mediaId}`);
      }}
    >
      <ArticleGroupRowContainer>
        <CenterContainer $centerText={false}>
          <Text text={group.name} bold />
          <Text
            text={`${t("countArticlesString", {
              count: articleCount,
            })}, ${t("piecesCountString", { count: pieceCount })}`}
          />
          {articleCount > 0 && (
            <ArticlesImagesOverview articles={articlesInGroup} />
          )}
        </CenterContainer>
        <RightContainer>
          <RightArrow />
        </RightContainer>
      </ArticleGroupRowContainer>
    </FullSpaceAnchor>
  );
};
