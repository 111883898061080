import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowForward } from "../assets/svg/arrow-small-forward.svg";

const Wrapper = styled('div')`
  height: ${(props) => (props.small ? "90px" : "110px")};
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  ${(props) => props.disabled && "color: var(--grey700);"};
  ${(props) => props.clickable && !props.disabled && "cursor: pointer;"}
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey100);
  }
`;

const Title = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: ${(props) => props.hfb && "14px"};
`;

const Content = styled('div')`
  color: ${(props) => props.color || "var(--grey700)"};
  font-weight: normal;
  ${({ hasArrow }) => (hasArrow ? `padding-right: 24px;` : "")}
`;

const Options = styled('div')`
  font-size: 14px;
  font-weight: bold;

  ${Wrapper} {
    padding: 0 28px;
  }
`;
const StyledArrowForward = styled(ArrowForward)`
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
`;

const Option = ({
  data: { type, title, content, link },
  onClick,
  isToggle,
  isActive,
  labels,
  bold,
  help,
  hfb,
  disabled,
  loading,
  color,
  hasArrow,
  ...restProps
}) => (
  <Wrapper
    data-testid={`option_${type}`}
    tabIndex="0"
    onClick={!disabled && !!link && !isToggle ? onClick : null}
    small={help || hfb}
    disabled={disabled}
    {...restProps}
  >
    <Title bold={bold || !!content} hfb={hfb}>
      {title}
    </Title>
    {!!link && !isToggle && <StyledArrowForward />}
    <Content hasArrow={hasArrow} color={color}>
      {content}
    </Content>
    {labels}
  </Wrapper>
);

function BasicOption({
  title,
  text,
  onClick,
  testId,
  titleTestId,
  textTestId,
  small,
  titleExtra,
}) {
  return (
    <Wrapper small={small} data-testid={testId} tabIndex="0" onClick={onClick}>
      <Title bold={text} data-testid={titleTestId}>
        {title}
        {titleExtra}
      </Title>
      {onClick && !titleExtra && <StyledArrowForward />}
      <Content data-testid={textTestId}>{text}</Content>
    </Wrapper>
  );
}

export default Option;
export { BasicOption };
export { Options };
