import { useState } from "react";
import styled from "styled-components";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import TrashCanIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TrashCanIconPath";
import {
  LeftContainer,
  ListItemContainer,
  RightContainer,
  SizedText,
  TextStyle,
} from "../../styles/styles";
import { useTranslation, Trans } from "@coworker/locales";
import { useTasksByMedia } from "../../hooks/useTasksByMedia";
import {
  filterOngoingTasksByMediaId,
  setOngoingTasksToClosed,
} from "../../tools";
import { useUserId } from "../../../../core/auth/useLoggedInUser";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaPrompt } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaPrompt";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { deleteMedia } from "../../services/medias.service";
import { useWorkspacesAction } from "../../../../hooks/useWorkspaces";
import { Media } from "../../types/media";
import { useToastNotification } from "@coworker/components";

interface DeleteMediaModalProps {
  media: Media;
}

const ListItemContainerDelete = styled(ListItemContainer)`
  justify-content: flex-start;
  gap: 17px;
`;
export const DeleteMediaModal = ({ media }: DeleteMediaModalProps) => {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { showToast } = useToastNotification();

  const uid = useUserId();
  const tasks = useTasksByMedia(media?.id || "");

  const [modalOpen, setModalOpen] = useState(false);

  const handleDeleteMedia = async () => {
    if (filterOngoingTasksByMediaId(tasks.tasks, media.id).length > 0) {
      await setOngoingTasksToClosed(
        media.id,
        t("taskClosedBecauseRemovedRoomString"),
        tasks,
        uid
      );
    }
    await deleteMedia(media.id);
    setModalOpen(false);
    push("/mediamaintenance");
    showToast(t("mediaDeletedString"));
  };

  if (!media) return <></>;

  return (
    <>
      <ListItemContainerDelete
        onClick={() => setModalOpen(!modalOpen)}
        role="button"
      >
        <LeftContainer>
          <FixaSSRIcon paths={TrashCanIconPath} color="var(--pink)" />
        </LeftContainer>
        <RightContainer>
          <TextStyle
            $bold={false}
            $italic={false}
            $size={"medium"}
            $color="pink"
          >
            <Trans>deleteMediaString</Trans>
          </TextStyle>
        </RightContainer>
      </ListItemContainerDelete>

      <FixaModal
        data-testid="deleteMediaModal"
        visible={modalOpen}
        handleCloseBtn={() => setModalOpen(!modalOpen)}
      >
        <FixaPrompt
          title={""}
          titleId={`delete-${media.id}`}
          header={
            <FixaModalHeader
              title={t("deleteMediaString")}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={
            <FixaModalFooter>
              <FixaButton
                data-testid="deleteMediaModal:cancel"
                text={t("cancelString")}
                type="secondary"
                onClick={() => setModalOpen(!modalOpen)}
              />
              <FixaButton
                data-testid="deleteMediaModal:delete"
                text={t("deleteString")}
                type="danger"
                onClick={handleDeleteMedia}
              />
            </FixaModalFooter>
          }
        >
          <>
            <SizedText $fontSize={16} $bold={true}>
              <Trans values={{ item: media.name }}>
                surelyDeleteRoomSettingString
              </Trans>
            </SizedText>
            <SizedText $fontSize={14} $bold={false}>
              <Trans>deleteRoomSettingTasksClosedString</Trans>{" "}
              <Trans>canNotBeUndoneString</Trans>
            </SizedText>
          </>
        </FixaPrompt>
      </FixaModal>
    </>
  );
};
