import React from "react";
import {
  FirstGrouping,
  Media,
  SecondGrouping,
  ThirdGrouping,
} from "../../types/media";
import { useTranslation } from "@coworker/locales";
import {
  FixaOption,
  FixaSelect,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import StairsIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/StairsIconPath";

interface FirstGroupingSelectProps {
  media: Media;
  onSave: (newMedia: Media) => void;
  availableGroupingOptions: string[];
}

export const FirstGroupingSelect = ({
  media,
  onSave,
  availableGroupingOptions,
}: FirstGroupingSelectProps) => {
  const { t } = useTranslation();
  return (
    <FixaSelect
      id={"firstGrouping"}
      label={t("firstGroupingString")}
      value={(media as FirstGrouping).firstGrouping ?? ""}
      ssrIcon={StairsIconPath}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const newGrouping =
          event.target.selectedIndex === 0 ? "" : event.target.value;
        const newMedia = { ...media };

        const oldGrouping = (newMedia as FirstGrouping).firstGrouping;
        (newMedia as FirstGrouping).firstGrouping = newGrouping;

        if ((newMedia as SecondGrouping).secondGrouping === newGrouping) {
          (newMedia as SecondGrouping).secondGrouping = oldGrouping;
        }
        if ((newMedia as ThirdGrouping).thirdGrouping === newGrouping) {
          (newMedia as ThirdGrouping).thirdGrouping = oldGrouping;
        }
        onSave(newMedia);
      }}
    >
      {availableGroupingOptions.map((grouping) => (
        <FixaOption {...{ value: grouping, name: grouping }} key={grouping} />
      ))}
    </FixaSelect>
  );
};
