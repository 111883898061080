import {Dispatch,SetStateAction} from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import FixaRadioButtonGroup, {
  FixaRadioButtonGroupListItem,
} from "@coworker/apprestructured/src/shared/wrappers/FixaRadioButtonGroup/FixaRadioButtonGroup";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import { HEADER_COLOR_SCHEMA } from "../constants";
import { CustomDate } from "./CustomDate";

interface PeriodProps {
  id: string;
  title: string;
  list: FixaRadioButtonGroupListItem[];
  setState: Dispatch<SetStateAction<string>>;
  showPeriod?: boolean;
}

const RadioButtonWrapper = styled('div')`
  margin: 0px 0px 16px 16px;
`;

export const FiltersOptions = ({
  id,
  title,
  list,
  setState,
  showPeriod = false,
}: PeriodProps) => {
  const activePeriod =
    localStorage.getItem("shoppingToolsPeriodFilter") ?? "today";

  return (
    <>
      <SubHeaderCard
        title={<Trans>{title}</Trans>}
        colorSchema={HEADER_COLOR_SCHEMA.WHITE}
        fontSize={16}
      />
      <RadioButtonWrapper>
        <FixaRadioButtonGroup
          list={list}
          subtle
          onChange={(e) => {
            setState((e.target as HTMLInputElement).id);
            if (id === "period") {
              localStorage.setItem(
                "shoppingToolsPeriodFilter",
                (e.target as HTMLInputElement).id
              );
            } else if (id === "area") {
              localStorage.setItem(
                "shoppingToolsAreaFilter",
                (e.target as HTMLInputElement).id
              );
            } else {
              localStorage.setItem(
                "shoppingToolsRoundFilter",
                (e.target as HTMLInputElement).id
              );
            }
          }}
        />
        {showPeriod && activePeriod === "customTitle" && <CustomDate />}
      </RadioButtonWrapper>
    </>
  );
};
