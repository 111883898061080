import FixaPill from "../../../wrappers/FixaPill/FixaPill";
import {
  FilterValue,
  MultiSelectFilter,
  StringArrayFilterValue,
} from "../../types";
import { filterValueAreTheSame, getSelectedFilterValue } from "../../utils";

interface InputMultiSelectPillMobileProps {
  filter: MultiSelectFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  onTriggerFilter: (filterId: string) => void;
}

export const InputMultiSelectPillMobile = ({
  filter,
  filterValues,
  defaultValues,
  onTriggerFilter,
}: InputMultiSelectPillMobileProps) => {
  const { id, title } = filter;
  const filterValue = getSelectedFilterValue(
    id,
    filterValues,
    defaultValues
  ) as StringArrayFilterValue;

  return (
    <FixaPill
      id={`fixapill-${id}`}
      data-testid={`fixapill-${id}`}
      label={title}
      onClick={() => {
        onTriggerFilter(id);
      }}
      size="small"
      selected={!filterValueAreTheSame(filterValue, filter.defaultValue)}
    />
  );
};
