import { DesktopOnly } from "@coworker/apprestructured/src/shared/components/DesktopOnly";
import { MobileOnly } from "@coworker/apprestructured/src/shared/components/MobileOnly";
import { AddMultipleArticlesMobile } from "./AddMultipleArticlesMobile";
import { AddMultipleArticlesDesktop } from "./AddMultipleArticlesDesktop";

interface AddMultipleArticlesType {
  isOpen: boolean;
  mediaId: string;
  groupId: string;
  countryId: string;
}

export function AddMultipleArticles(props: AddMultipleArticlesType) {
  return (
    <>
      <MobileOnly>
        <AddMultipleArticlesMobile {...props} />
      </MobileOnly>
      <DesktopOnly>
        <AddMultipleArticlesDesktop {...props} />
      </DesktopOnly>
    </>
  );
}
