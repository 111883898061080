import { formatFullIdWithDots } from "@coworker/reusable";
import { FixaProductIdentifier } from "../../wrappers/FixaProductIdentifier/FixaProductIdentifier";

interface SimpleProductIdentifierProps {
  articleId: string;
}

export function SimpleProductIdentifier({
  articleId: productId,
}: SimpleProductIdentifierProps) {
  return <FixaProductIdentifier value={formatFullIdWithDots(productId)} />;
}
