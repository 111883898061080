import styled, { css } from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import BasicWidget from "@coworker/components/src/components/BasicWidget";
import { ReactComponent as TruckIcon } from "../../../assets/svg/icon-delivery-truck.svg";
import { ReactComponent as ExpeditTag } from "../../../assets/svg/EXPEDIT-tag.svg";
import { ReactComponent as SupplierNumberMarker } from "../../../assets/svg/supplier-number-marker.svg";
import Instructions from "../../Instructions";
import profilePreferences from "@coworker/enums/profilePreferences";

const { DISABLE_POPUPS_SUPPLIER_NUMBER } = profilePreferences;

const Overlay = styled('div')`
  position: relative;
  ${({ minHeight }) =>
    css`
      min-height: ${minHeight};
    `}
  max-width: 100%;
  svg {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
  }
`;

export default function TaskFormSupplierNumber({
  value,
  onChange,
  additionalSubTitle,
}) {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();
  const getNumber = async () => {
    const response = await getInput("supplierNumber", {
      value,
      bigTitle: <Trans>supplierNumberString</Trans>,
      instruction: (
        <Instructions
          title={<Trans>whereCanIfindSupplierString</Trans>}
          text={
            <Overlay minHeight="133px">
              <ExpeditTag />
              <SupplierNumberMarker />
            </Overlay>
          }
          type={DISABLE_POPUPS_SUPPLIER_NUMBER}
        />
      ),
    });
    if (response) {
      onChange(response);
    }
  };

  return (
    <BasicWidget
      tabIndex={0}
      onClick={getNumber}
      testId="supplierNumberWidget"
      text={
        value.supplier_number_unknown
          ? t("iDontHaveASuppliedNumber")
          : value.supplier_number
      }
      title={<Trans>supplierNumberString</Trans>}
      icon={<TruckIcon width={22} height={22} />}
      additionalSubTitle={
        !value.supplier_number && !value.supplier_number_unknown ? (
          additionalSubTitle
        ) : (
          <></>
        )
      }
    />
  );
}
