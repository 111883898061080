import { useTranslation } from "@coworker/locales";
import { ArticleListItem } from "../../RoomSettings/Molecules/ArticleListItem";
import { Text } from "../../RoomSettings/Atoms/Text";
import { Reasons } from "../../RoomSettings/constants";
import { mapArticleInfoToArticle } from "../../RoomSettings/types/mappers";
import { Article } from "../../RoomSettings/types/article";
import {
  ArticleListItemMobile,
  SimpleContainer,
} from "../../RoomSettings/styles/styles";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";

interface PreviousProductProps {
  value: {
    new_product_article_id: string;
    state: string;
    reason: string;
  };
}

export default function PreviousProduct({ value }: PreviousProductProps) {
  const { t } = useTranslation();

  const { data: itemInfo } = useItemInfo(value.new_product_article_id);

  if (!itemInfo) return <></>;

  const article: Article = mapArticleInfoToArticle(itemInfo as Item);

  return (
    <>
      {value.reason === Reasons.REPLACE_PRODUCT && (
        <>
          <SimpleContainer $direction="row">
            <Text text={t("newProductString")} bold />
          </SimpleContainer>
          <ArticleListItemMobile>
            <ArticleListItem article={article} hideChevron={true} />
          </ArticleListItemMobile>
        </>
      )}
    </>
  );
}
