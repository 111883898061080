import React from "react";
import {
  IPublicClientApplication,
  AccountInfo,
  InteractionStatus,
  PublicClientApplication,
  InteractionRequiredAuthError,
  BrowserAuthError,
} from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import statusTypes from "@coworker/enums/statusTypes";
import { useLoggedInUser } from "./useLoggedInUser";
import trackerHelper from "../../helpers/tracker";
import { LOGIN_PATH, PRIVACY_PATH, LOGOUT_PATH } from "../../paths";
import { upsertUserByEmail } from "@coworker/apprestructured/src/login/hooks/useUpsertUserByEmail";
import useFixaNavigate from "@coworker/apprestructured/src/shared/wrappers/FixaNavigate/useFixaNavigate";
import {
  msalConfiguration,
  tokenScopes,
} from "@coworker/apprestructured/src/assets/auth/msalConfig";

const AFTER_LOGIN_REDIRECT_URL_KEY = "fixa.afterLoginRedirectUrl";

type UserPurgeStatus = "None" | "InProgress" | "Success";
type MsalSignInStatus = "Initializing" | "Initialized";
type MsalTokenAcquisitionStatus = "None" | "InProgress" | "Done";
type SignInStatus =
  | "None"
  | "Initializing"
  | "Initialized"
  | "InProgress"
  | "Success"
  | "Error";

type AuthState = {
  isAuthenticated: boolean;
  msalAccount: AccountInfo | null;
  msalSignInStatus: MsalSignInStatus;
  azureAccessToken: string | null;
  silentMsalTokenAcquisitionStatus: MsalTokenAcquisitionStatus;
  userPurgeStatus: UserPurgeStatus;
  signInStatus: SignInStatus;
  error: string | null;
};

type AuthReducerAction =
  | {
    type: "handleMsalSignInStateChange";
    payload: {
      msalAccount: AccountInfo | null;
    };
  }
  | {
    type: "handleAzureAccessTokenStateChange";
    payload:
    | {
      status: Extract<MsalTokenAcquisitionStatus, "InProgress">;
    }
    | {
      status: Extract<MsalTokenAcquisitionStatus, "Done">;
      azureAccessToken: string;
    };
  }
  | {
    type: "handleUserPurgeStateChange";
    payload: {
      status: Extract<UserPurgeStatus, "InProgress" | "Success">;
    };
  }
  | {
    type: "handleAuthStateChange";
    payload: {
      isAuthenticated: boolean;
    };
  }
  | {
    type: "handleUserUnavailable";
    payload: {
      status: Extract<SignInStatus, "Error">;
      error: string;
    };
  };
type AuthReducerDispatch = React.Dispatch<AuthReducerAction>;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  msalAccount: null,
  msalSignInStatus: "Initializing",
  azureAccessToken: null,
  silentMsalTokenAcquisitionStatus: "None",
  signInStatus: "None",
  userPurgeStatus: "None",
  error: null,
};

function authReducer(state: AuthState, action: AuthReducerAction): AuthState {
  switch (action.type) {
    case "handleMsalSignInStateChange":
      const msalAccount = action.payload.msalAccount;
      return {
        ...state,
        msalAccount,
        msalSignInStatus: "Initialized",
        userPurgeStatus: msalAccount ? state.userPurgeStatus : "None",
      };
    case "handleAzureAccessTokenStateChange":
      const newAzureAccessTokenState: AuthState = {
        ...state,
        silentMsalTokenAcquisitionStatus: action.payload.status,
        azureAccessToken: null,
      };
      if (action.payload.status === "Done") {
        newAzureAccessTokenState.azureAccessToken =
          action.payload.azureAccessToken;
      }
      return newAzureAccessTokenState;
    case "handleUserPurgeStateChange":
      return {
        ...state,
        userPurgeStatus: action.payload.status,
        isAuthenticated: false,
      };
    case "handleAuthStateChange":
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case "handleUserUnavailable":
      return {
        ...state,
        error: action.payload.error,
        signInStatus: "Error",
      };
    default:
      return state;
  }
}

export const msalPublicClientApplication = (async () => {
  const configWithExplicitCache = {
    ...msalConfiguration,
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  };
  return await PublicClientApplication.createPublicClientApplication(
    configWithExplicitCache
  );
})();

export const msalLogin = async () => {
  const msalInstance = await msalPublicClientApplication;

  return msalInstance.loginRedirect();
};

export const msalLogout = async (msalInstance: any) => {
  if (!msalInstance) {
    return;
  }
  try {
    await msalInstance.logout({
      postLogoutRedirectUri: `${window.location.origin}${LOGIN_PATH}`,
    });
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

export function useAuth() {
  const [authState, authReducerDispatch] = React.useReducer(
    authReducer,
    initialAuthState
  );
  const [isLoginInProgress, setIsLoginInProgress] = React.useState(false);
  const {
    instance: msalInstance,
    accounts: msalAccounts,
    inProgress: msalInteractionStatus,
  } = useMsal();

  const msalAccount = useAccount(msalAccounts[0] ?? {});
  const { setLoggedInUser, resetLoggedInUser } = useLoggedInUser();
  const navigate = useFixaNavigate();
  const [userDataFromService, setUserDataFromService] = React.useState({});

  // Add a ref to ensure loginRedirect is only auto-triggered once
  const hasAttemptedInteractiveLogin = React.useRef(false);

  const handleAuthUserUpdate = React.useCallback(
    (userData: any) => {
      const profile = userData;
      if (profile && profile.status === statusTypes.ACTIVE) {
        setUserDataFromService(userData);
        setLoggedInUser(profile);
      } else {
        throw new Error("Login error. Your Profile is not active.");
      }
    },
    [setLoggedInUser]
  );

  const fetchUserProfile = React.useCallback(async (email: string) => {
    return await upsertUserByEmail(email);
  }, []);

  const userDataRef = React.useRef<any>(null);

  React.useEffect(() => {

    if (authState.msalSignInStatus !== "Initialized") return;

    const { msalAccount, userPurgeStatus, azureAccessToken, signInStatus, isAuthenticated } = authState;
    const currentPath = window.location.pathname;

    if (msalAccount && currentPath.startsWith(LOGOUT_PATH)) return;

    if (!authState.msalAccount && authState.userPurgeStatus === "None") {
      handleUnauthenticatedUserCoreService(
        authReducerDispatch,
        resetLoggedInUser,
        msalInstance,
        msalInteractionStatus // new parameter to inform the function of current interaction status
      );
      return;
    }

    if (!msalAccount && userPurgeStatus === "Success" && ![LOGIN_PATH, PRIVACY_PATH].some(path => currentPath.startsWith(path))) {
      saveAfterLoginRedirectUrl();
      navigate(LOGIN_PATH);
      return;
    }

    if (
      authState.msalAccount &&
      !authState.azureAccessToken &&
      authState.silentMsalTokenAcquisitionStatus === "None"
    ) {
      acquireMsalTokenSilent(
        authReducerDispatch,
        msalInstance,
        authState.msalAccount,
        msalInteractionStatus
      );
      return;
    }
    if (msalAccount && azureAccessToken) {
      if (signInStatus !== "Error" && currentPath.startsWith(LOGIN_PATH)) {
        const afterLoginRedirectUrl = sessionStorage.getItem(AFTER_LOGIN_REDIRECT_URL_KEY) || "/";
        sessionStorage.removeItem(AFTER_LOGIN_REDIRECT_URL_KEY);
        navigate(afterLoginRedirectUrl);
        return;
      }
      let email = msalAccount.idTokenClaims?.preferred_username ?? msalAccount.username;
      if (email.split("@")[1]?.toLowerCase() === "ikea.com") {
        email = email.replace("ikea", "ingka.ikea");
      }
      if (!isAuthenticated && signInStatus !== "Error") {
        fetchUserProfile(email)
          .then((response) => {
            if (response === "User not found") return Promise.reject(new Error("User not found"));
            return handleAuthUserUpdate(response);
          })
          .then(() => {
            trackerHelper.trackLogin();
            authReducerDispatch({
              type: "handleAuthStateChange",
              payload: { isAuthenticated: true },
            });
            userDataRef.current = userDataFromService;
          })
          .catch((error) => {
            console.error("fetch user profile", error?.message);
            //if user is not found in graphAPI 404 NotFoundError
            // if user is not store user, core -service will through 403 forbidden error
            let errorMessage = error?.message;
            if (error.message.includes("User not found")) {
              errorMessage =
                "You do not have your profile created. Please contact your Store Super User.";
            }
            authReducerDispatch({
              type: "handleUserUnavailable",
              payload: { status: "Error", error: errorMessage },
            });
            navigate("/");
          });
      }
    }

    return;

  }, [
    authState,
    msalInstance,
    msalInteractionStatus,
    msalAccount,
    resetLoggedInUser,
    handleAuthUserUpdate,
    navigate,
    fetchUserProfile,
    userDataFromService,
  ]);

  React.useEffect(() => {
    if (msalInteractionStatus === InteractionStatus.None && msalAccount) {
      authReducerDispatch({
        type: "handleMsalSignInStateChange",
        payload: { msalAccount },
      });
    }
    return undefined;
  }, [msalInteractionStatus, msalAccount]);

  React.useEffect(() => {
    if (msalInteractionStatus === InteractionStatus.None && authState.msalSignInStatus === "Initializing") {
      authReducerDispatch({
        type: "handleMsalSignInStateChange",
        payload: { msalAccount: msalAccount || null },
      });
    }
  }, [msalInteractionStatus, msalAccount, authState.msalSignInStatus]);

  // Update loginRedirect: only call if msalInteractionStatus is None, and catch interaction_in_progress errors
  const loginRedirect = React.useCallback(async () => {
    if (msalInteractionStatus !== InteractionStatus.None) {
      console.warn("MSAL interaction ongoing; loginRedirect skipped.");
      return;
    }
    setIsLoginInProgress(true);
    try {
      await msalInstance.loginRedirect({
        scopes: tokenScopes,
        redirectUri: window.location.origin + LOGIN_PATH, // explicit redirectUri
      });
    } catch (error: any) {
      // If the error indicates an ongoing interaction, log and ignore it
      if (error.errorCode === "interaction_in_progress") {
        console.warn("Login interaction already in progress.");
      } else {
        console.error("Login redirect failed:", error);
      }
      setIsLoginInProgress(false);
    }
  }, [msalInstance, msalInteractionStatus]);
  // ( authState.msalSignInStatus !== "Initialized" && authState.msalSignInStatus !== "Initializing")
  // Auto-trigger loginRedirect only once if no MSAL account is detected
  React.useEffect(() => {
    if (
      msalInteractionStatus === InteractionStatus.None &&
      !msalAccount &&
      !isLoginInProgress &&
      !hasAttemptedInteractiveLogin.current
    ) {
      console.log("No MSAL account detected; triggering loginRedirect automatically.");
      hasAttemptedInteractiveLogin.current = true;
      loginRedirect();
    }
  }, [msalInteractionStatus, msalAccount, isLoginInProgress, loginRedirect]);

  React.useEffect(() => {
    if (msalInteractionStatus === InteractionStatus.None && isLoginInProgress) {
      setIsLoginInProgress(false);
    }
  }, [msalInteractionStatus, isLoginInProgress]);

  return {
    isAuthenticated: authState.isAuthenticated,
    isAuthInProgress: isAuthInProgress(authState) || isLoginInProgress,
    authError: authState.error,
    loginRedirect,
    msalLogout,
    isLoginInProgress,
  };
}

function saveAfterLoginRedirectUrl() {
  sessionStorage.removeItem(AFTER_LOGIN_REDIRECT_URL_KEY);
  const afterLoginRedirectUrl = window.location.href.slice(
    window.location.origin.length
  );
  if (
    afterLoginRedirectUrl.length > 1 &&
    !afterLoginRedirectUrl.startsWith(LOGOUT_PATH)
  ) {
    sessionStorage.setItem(AFTER_LOGIN_REDIRECT_URL_KEY, afterLoginRedirectUrl);
  }
}

/*
async function handleUnauthenticatedUser(
  authReducerDispatch: AuthReducerDispatch,
  resetLoggedInUser: () => void
) {
  console.log("handleUnauthenticatedUser");
  authReducerDispatch({
    type: "handleUserPurgeStateChange",
    payload: {status: "InProgress"},
  });
  resetLoggedInUser();
  await logoutUser(false);
  authReducerDispatch({
    type: "handleUserPurgeStateChange",
    payload: {status: "Success"},
  });
}
*/

async function handleUnauthenticatedUserCoreService(
  authReducerDispatch: AuthReducerDispatch,
  resetLoggedInUser: () => void,
  msalInstance?: IPublicClientApplication,
  msalInteractionStatus?: InteractionStatus
) {
  authReducerDispatch({
    type: "handleUserPurgeStateChange",
    payload: { status: "InProgress" },
  });
  resetLoggedInUser();

  // Only call logout if there's at least one account and no interactive operation in progress
  if (
    msalInstance &&
    msalInstance.getAllAccounts().length > 0 &&
    msalInteractionStatus === InteractionStatus.None
  ) {
    await msalInstance.logout({
      postLogoutRedirectUri: window.location.origin + LOGIN_PATH,
    });
  }

  authReducerDispatch({
    type: "handleUserPurgeStateChange",
    payload: { status: "Success" },
  });
}

async function acquireMsalTokenSilent(
  authReducerDispatch: AuthReducerDispatch,
  msalInstance: IPublicClientApplication,
  msalAccount: AccountInfo,
  msalInteractionStatus: InteractionStatus
) {
  try {
    authReducerDispatch({
      type: "handleAzureAccessTokenStateChange",
      payload: { status: "InProgress" },
    });
    const msalResponse = await msalInstance.acquireTokenSilent({
      account: msalAccount,
      scopes: tokenScopes,
    });
    authReducerDispatch({
      type: "handleAzureAccessTokenStateChange",
      payload: { status: "Done", azureAccessToken: msalResponse.accessToken },
    });
  } catch (error: any) {
    console.error("Silent token acquisition failed:", error);
    saveAfterLoginRedirectUrl();
    if (error instanceof InteractionRequiredAuthError) {
      if (msalInteractionStatus === InteractionStatus.None) {
        try {
          await msalInstance.acquireTokenRedirect({
            scopes: tokenScopes,
            redirectUri: window.location.origin + LOGIN_PATH,
          });
        } catch (error) {
          throw new Error(`Error msal token redirect ${error}`);
        }
      } else {
        throw new Error(`Msal- Interaction already in progress ${error}`);
      }
    } else if (error instanceof BrowserAuthError) {
      throw new Error(`Msal browser auth error ${error}`);
    } else {
      throw error;
    }
  }
}

// This function checks if any of the authentication-related states are in progress
// Needs a revisit
function isAuthInProgress(authState: AuthState) {
  return (
    authState.msalSignInStatus === "Initializing" ||
    authState.signInStatus === "Initializing" ||
    authState.signInStatus === "InProgress" ||
    authState.userPurgeStatus === "InProgress" ||
    authState.silentMsalTokenAcquisitionStatus === "InProgress" ||
    authState.msalSignInStatus === "Initialized"
  );
}
