import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { AddonSingleProductInformation } from "../../AddonSingleProductInformation";
import { StockInfoPills } from "../../StockInfoPills";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { useAvailableElevatedStock } from "../../../hooks/useAvailableElevatedStock";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";

const Container = styled('div')`
  padding: 26px 28px;
`;

export function ProductAndStockPillsWidget({
  value: { productId, productType },
}) {
  const { push } = useWorkspacesAction();
  const { data: item, isLoading: loadingItem } = useItemInfo(productId);

  const {
    available,
    elevated,
    loading: loadingStockInfo,
    color,
  } = useAvailableElevatedStock(`${productType}${productId}`);
  const countryId = useCountryId();

  return (
    <Container
      data-testid="productWithPills"
      onClick={() => {
        push(`/product/${item?.type}${item?.no}`);
      }}
    >
      <AddonSingleProductInformation basicItem={item} loadingItem={loadingItem} />
      <StockInfoPills
        stockInfo={{ available, elevated, color, loading: loadingStockInfo }}
        short_id={item?.no}
        type={item?.type}
        countryId={countryId}
      />
    </Container>
  );
  // TODO: Add skeleton either here or only in ProductAndStockPills?
}
