import {
  MultiSelectFilter,
  SelectOption,
  StringArrayFilterValue,
} from "../../types";
import { MultiSelectListView } from "../SelectListView";
import { FixaAccordionItem } from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";
import AccordionSelectlCss from "./AccordionSelect.module.css";

interface AccordionMultiSelectProps {
  filter: MultiSelectFilter;
  selected: StringArrayFilterValue;
  onChange: (option: SelectOption, isSelected: boolean) => void;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const AccordionMultiSelect = ({
  filter,
  selected,
  onChange,
  open,
  onOpenChange,
}: AccordionMultiSelectProps) => {
  const { id, title } = filter;
  return (
    <FixaAccordionItem
      id={`filter-accordion-${id}`}
      data-testid={`filter-accordion-${id}`}
      title={title}
      className={AccordionSelectlCss["accordion-item-title"] ?? ""}
      open={!!open}
      onTransitionEnd={(_event, isOpen) => {
        onOpenChange(isOpen);
      }}
    >
      <MultiSelectListView
        prefix="accordion"
        filter={filter}
        selected={selected}
        onChange={onChange}
      />
    </FixaAccordionItem>
  );
};
