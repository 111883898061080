import React from "react";
import ImageSlider from "../ImageSlider";
import { useGetImagesSignedUrls } from "@coworker/apprestructured/src/tasks/hooks/useGetImageSignedUrl";

const ImageGallery = ({ data, onClose }) => {
  const signedUrlsResult = useGetImagesSignedUrls(data);

  const uploadedTaskImageUrls = signedUrlsResult?.map(
    (query) => query.data?.[0]
  );

  return (
    <ImageSlider
      key="images"
      images={data}
      scrollables={uploadedTaskImageUrls}
      onClose={onClose}
    />
  );
};

export default ImageGallery;
