import Current from "./Current";
import Home from "./Home";
import styles from "./Linkbar.module.css";
import Dots from "./Dots";
import { LinkbarItem } from "./Linkbar";

function LinkbarMinimal({
  currentLabel,
  currentAsH1 = false,
  onDotsClick,
  items = [],
  "data-testid": dataTestId,
}: {
  currentLabel: string;
  currentAsH1?: boolean;
  onDotsClick: () => void;
  items?: LinkbarItem[];
  "data-testid"?: string | undefined;
}) {
  return (
    <div data-testid={dataTestId} className={styles["linkbar-minimal"]}>
      <Home />
      {items.length > 0 && <Dots onClick={onDotsClick} />}
      <Current label={currentLabel} asH1={currentAsH1} truncate />
    </div>
  );
}

export default LinkbarMinimal;
