import { useTranslation } from "@coworker/locales";
import { CardHeadLine } from "../../Molecules/Settings/CardHeadLine";
import {
  CardFrame,
  FullLine,
  PaddedContainer,
  WhiteSpace,
} from "../../styles/styles";
import { Media } from "../../types/media";
import { getMediaTypeText } from "../../tools";
import { MediaTypes } from "../../constants";
import { RangeDescriptionInput } from "../../Molecules/Settings/RangeDescriptionInput";
import { ProductRangeInput } from "../../Molecules/Settings/ProductRangeInput";
import { FirstGroupingSelect } from "../../Molecules/Settings/FirstGroupingSelect";
import { SecondGroupingSelect } from "../../Molecules/Settings/SecondGroupingSelect";
import { ThirdGroupingSelect } from "../../Molecules/Settings/ThirdGroupingSelect";

interface AboutCompactProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const AboutCompact = ({ media, onSave }: AboutCompactProps) => {
  const { t } = useTranslation();

  const groupingOptions = ["Color / Material", "Style", "Family"];

  return (
    <CardFrame>
      <CardHeadLine
        character={"2"}
        headline={t(`aboutString`, {
          name: getMediaTypeText(MediaTypes.COMPACT),
        })}
      />
      <FullLine />
      <PaddedContainer $py="0px" $px="24px">
        <RangeDescriptionInput media={media} onSave={onSave} />
        <WhiteSpace height={"12"} />
        <ProductRangeInput media={media} onSave={onSave} />
        <WhiteSpace height={"12"} />
        <FirstGroupingSelect
          media={media}
          onSave={onSave}
          availableGroupingOptions={groupingOptions}
        />
        <WhiteSpace height={"12"} />
        <SecondGroupingSelect
          media={media}
          onSave={onSave}
          availableGroupingOptions={groupingOptions}
        />
        <WhiteSpace height={"12"} />
        <ThirdGroupingSelect
          media={media}
          onSave={onSave}
          availableGroupingOptions={groupingOptions}
        />
        <WhiteSpace height={"12"} />
      </PaddedContainer>
    </CardFrame>
  );
};
