import React from "react";
import noPreviewImg from "../assets/images/no-preview.png";
import { RotatedImage } from "./RotatedImage";
import { useGetImageSignedUrl } from "@coworker/apprestructured/src/tasks/hooks/useGetImageSignedUrl";
import { SimpleLoadingBall } from "@coworker/apprestructured/src/shared/simple/SimpleLoading/SimpleLoading";
import { useTranslation } from "@coworker/locales";

/**
 * @param {"large" | "medium" | "small" | "original"} size Wanted image size
 * @param {boolean} dark Only used in PreviewImage.
 * @param {boolean} fullscreen Used in PreviewImage and ImageSlider.
 * @param {boolean} taskHeader Only when used in header.
 */
export function StoredImage({
  size = "large",
  image = {},
  fullscreen = false,
  taskHeader = false,
}) {
  const { t } = useTranslation();
  const [src, setSrc] = React.useState(image.src || "");
  React.useEffect(() => {
    if (image.src) setSrc(image.src); // Support for showing a product image, where we don't need to wait for backend response.
  }, [image]);

  const { data: urls, isLoading } = useGetImageSignedUrl(
    image?.file_name,
    size
  );

  React.useEffect(() => {
    if (urls) setSrc(urls[0]);
  }, [urls]);

  const showingOriginal = !!image.src || !(src || "").includes("/resized/");
  return (
    <React.Fragment>
      {isLoading && (
        <SimpleLoadingBall text={t("loadingEllipsisString")} size="medium" />
      )}
      {!isLoading && src && (
        <RotatedImage
          src={src}
          alt={image.file_name}
          orientation={showingOriginal ? image.orientation : 1} // if resized then also the rotation is normalized to 1 on the backend.
          fullScreenTransform={!!(fullscreen && showingOriginal)}
          fullscreen={fullscreen}
          taskHeader={taskHeader}
          onError={() => setSrc(noPreviewImg)}
        />
      )}
    </React.Fragment>
  );
}
