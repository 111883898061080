import Skeleton from "@ingka/skeleton";
import "@ingka/skeleton/dist/style.css";

interface Props {
  height?: string;
  width?: string;
}

export const FixaSkeleton = (props: Props) => {
  return <Skeleton {...props} />;
};
