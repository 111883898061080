import { useEffect } from "react";
import { useAuth } from "@coworker/app/src/core/auth/useAuth";
import Main from "./components/Main";
import { LoginView } from "@coworker/apprestructured/src/login/components/LoginView/LoginView";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { SimpleLoadingBall } from "@coworker/apprestructured/src/shared/simple/SimpleLoading/SimpleLoading";

export function App() {
  const { instance, accounts, inProgress } = useMsal();
  const { isAuthenticated, authError, isAuthInProgress } = useAuth();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length === 0) {
      instance.loginRedirect();
    }
  }, [inProgress, accounts, instance]);

  if (
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect ||
    inProgress === InteractionStatus.Login
  ) {
    return <SimpleLoadingBall />;
  }

  return isAuthenticated ? (
    <Main />
  ) : (
    <LoginView authError={authError} authInProgress={isAuthInProgress} />
  );
}
