
import {
  FixaOption,
  FixaSelect,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import { RoomSettingsMedia } from "../../types/media";
import StarFilledIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/StarFilledIconPath";
import { Articles } from "../../types/article";
import { getFullArticleName } from "../../Organisms/Settings/SettingsForm.helper";

interface MainAlternativeProductSelectProps {
  componentId: string;
  label: string;
  value: string;
  roomSettingsMedia: RoomSettingsMedia;
  onSave: (articleId: string) => void;
  currentProductId: string;
  articles: Articles;
}

export const MainAlternativeProductSelect = ({
  componentId,
  label,
  value,
  roomSettingsMedia,
  onSave,
  currentProductId,
  articles,
}: MainAlternativeProductSelectProps) => {
  const mainAlternativeProductsToRemove = [
    roomSettingsMedia.mainProductId1,
    roomSettingsMedia.mainProductId2,
    roomSettingsMedia.alternativeProductId1,
    roomSettingsMedia.alternativeProductId2,
  ].filter((productId) => productId !== currentProductId);
  return (
    <FixaSelect
      id={componentId}
      label={label}
      value={value}
      ssrIcon={StarFilledIconPath}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const articleId =
          event.target.selectedIndex === 0 ? "" : event.target.value;
        onSave(articleId);
      }}
    >
      {articles
        .filter(
          (article) => !mainAlternativeProductsToRemove.includes(article.id)
        )
        .map((article) => (
          <FixaOption
            key={article.id}
            value={article.id}
            name={getFullArticleName(articles, article.id)}
          />
        ))}
    </FixaSelect>
  );
};
