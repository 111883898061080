import { useTranslation } from "@coworker/locales";
import { FilterType, InputType } from "../types";
import { useCustomAllSelectOption } from "../utils";

const TASKS_ID = "tasks";

export function useTasksSelectFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: TASKS_ID, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: TASKS_ID,
      title: t("ongoingTasksString"),
      options: [
        { value: "ongoingTasks", title: t("articlesWithOngoingTasksString") },
        {
          value: "noOngoingTasks",
          title: t("articlesWithNoOngoingTasksString"),
        },
      ],
      enabled: true,
      defaultValue,
    },
    t("allArticlesString")
  );
}
