import React from "react";
import { useToastNotification } from "@coworker/components";
import { Trans } from "@coworker/locales";
import tracker from "../helpers/tracker";
import { useUpdateTaskMutation } from "@coworker/apprestructured/src/tasks/hooks/useUpdateTaskById";

export default function useNotificationWithUndo() {
  const { showToast } = useToastNotification();
  const updateTask = useUpdateTaskMutation();
  return React.useCallback(
    async (data, taskId) => {
      await updateTask.mutateAsync({ data, taskId });
      // undo_action_id is of format 'actiontype-timestamp',
      // so we remove the timestamp and only carry forward the action type
      const undoneAction =
        data.undo_action_id?.split("-")?.[0] ?? "unknown_action";
      tracker.trackUndo(undoneAction);
      showToast(<Trans>undoneString</Trans>);
    },
    [showToast, updateTask]
  );
}
