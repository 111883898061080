import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useMutation } from "@tanstack/react-query";
import { Image } from "../types/imageUpload";
import { useQueryClient } from "@tanstack/react-query";

export async function addImageToTask(taskId: string, images: Image[]) {
  const path = `/v1/task/${taskId}/images/create`;
  const url = `${TASKS_SERVICE_URL}${path}`;
  const { result } = await fetchAPI("POST", url, { list: images }, "json");
  return result;
}

export function useAttachImageToTask(taskId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { images: Image[] }) => {
      return await addImageToTask(taskId, data.images);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["task", "images", taskId],
      });
    },
    onError: (error) => {
      console.log("Error while updating task", error);
    },
  });
}
