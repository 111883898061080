import { motion } from "framer-motion";
import { type FixaWindow, isRDTDevice } from "@coworker/reusable";
import { ComponentType } from "react";

// Updated safe check for Cypress using optional chaining
const isTestEnv = typeof window !== 'undefined' && (window as FixaWindow).Cypress;
const duration = isRDTDevice() || isTestEnv ? 0 : 300;

const variants = {
  enter: { y: 0, transition: { ease: "easeIn", duration, type: "spring", stiffness: 100 } },
  exit: { y: "100%", transition: { ease: "easeOut", duration, type: "spring", stiffness: 100 } },
};

// Updated the function signature to constrain T to an object
export function revealFromBelow<T extends {}>(Component: ComponentType<T>) {
  return (props: T) => (
    <motion.div
      key={`reveal-${Math.random()}`} // Ensure unique key
      variants={variants}
      initial="exit"
      animate="enter"
      exit="exit"
    >
      <Component {...props} />
    </motion.div>
  );
}

export function animatedCurtain<T extends {}>(Component: ComponentType<T>, height = 60) {
  return (props: T) => (
    <motion.div
      drag="y"
      dragConstraints={{ top: -100, bottom: 0 }}
      initial={{ y: "-100%" }}
      animate={{ y: 0, transition: { ease: "easeIn", duration } }}
      exit={{ y: -height, transition: { ease: "easeIn", duration } }}
    >
      <Component {...props} />
    </motion.div>
  );
}
