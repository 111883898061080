import { useState } from "react";
import { useTranslation } from "@coworker/locales";
import { useQueryClient } from "@tanstack/react-query";
import { AggregatedArticle } from "../../hooks/useAggregatedArticles";
import { Media } from "../../types/media";
import { ArticleListItem } from "../../Molecules/ArticleListItem";
import { Article } from "../../types/article";
import { ArticleListItemMobile } from "../../styles/styles";
import { DeleteArticleRow } from "../../Molecules/DeleteArticleRow";
import { invalidateArticleQueries } from "../../tools";
import { sortArticlesByGroup } from "../../Articles/ArticleView.helper";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalBody } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalBody";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import styled from "styled-components";

const StyledFixaSheets = styled(FixaSheets)`
  borderbottom: 1px solid var(--grey200);
`;

const StyledFixaModalBody = styled(FixaModalBody)`
  padding: 0px;
`;
interface DeleteArticlesModalProps {
  aggregatedArticle: AggregatedArticle;
  media: Media;
  onClose: () => void;
}

export const DeleteArticlesModal = ({
  aggregatedArticle,
  onClose,
  media,
}: DeleteArticlesModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [articles, setArticles] = useState<Article[]>([]);

  const closeDialog = async () => {
    await invalidateArticleQueries(queryClient);
    onClose();
  };

  if (articles.length === 0) {
    setArticles(sortArticlesByGroup(aggregatedArticle.aggregatedArticles));
  }

  const articleDeleted = async (article: Article) => {
    const articlesAfterDelete = articles.filter((a) => a.id !== article.id);
    if (articlesAfterDelete.length === 0) {
      await closeDialog();
    } else {
      setArticles(articlesAfterDelete);
    }
  };

  return (
    <FixaModal
      handleCloseBtn={async () => {
        await closeDialog();
      }}
      visible={true}
    >
      <StyledFixaSheets
        alignment="right"
        aria-label="Accessibility header for a modal"
        footer={null}
        header={
          <FixaModalHeader
            ariaCloseTxt="Close delete article"
            title={t("deleteArticleString")}
            closeBtnClick={async () => {
              await closeDialog();
            }}
          />
        }
        size="small"
      >
        <StyledFixaModalBody>
          <ArticleListItemMobile>
            <ArticleListItem article={aggregatedArticle} hideChevron={true} />
          </ArticleListItemMobile>
          {articles.map((article, index) => (
            <DeleteArticleRow
              key={index}
              index={index}
              media={media}
              article={article}
              articleDeleted={articleDeleted}
            />
          ))}
        </StyledFixaModalBody>
      </StyledFixaSheets>
    </FixaModal>
  );
};
