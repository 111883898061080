import { ArticleError } from "@coworker/apprestructured/src/shared/components/ArticleError/ArticleError";
import {
  Item,
  ItemSalesPrice,
} from "@coworker/apprestructured/src/shared/types/item";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { Button, ErrorMessage, SearchSkeleton } from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as AddonIcon } from "../assets/svg/addon-icon.svg";
import { ReactComponent as ArrowIcon } from "../assets/svg/icon-arrow-small-forward-cropped.svg";
import { ReactComponent as ProductIcon } from "../assets/svg/product-icon.svg";
import { logEvent } from "../helpers/tracker";
import { BlueLinkWithNumber } from "./BlueLinkWithNumber";
import { ScanItemResult } from "./ScanItemResult";
import { ItemError } from "@coworker/apprestructured/src/shared/enums/itemError";

const PopoverContainer = styled("div")`
  background: var(--white);
  width: 100%;
  max-height: 90%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  z-index: var(--z-popover);
`;

const ButtonWithSpacing = styled(Button)`
  margin: 18px 19px 18px 19px;
`;

const ExpandIcon = styled(({ className }) => (
  <ArrowIcon className={className} />
))`
  transform: rotate(90deg);
  ${({ rotated }) =>
    rotated &&
    css`
      transform: rotate(-90deg);
    `}
`;

const ScanPopoverActions = styled("div")`
  min-height: 90px;
  display: flex;
  align-items: center;
  padding-left: 26px;
  padding-right: 38px;
  cursor: pointer;

  & > span {
    flex-grow: 1;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--black);
  }

  border-top: 1px solid var(--grey150);
  border-bottom: 1px solid var(--grey150);
`;

const ActionItem = styled("div")`
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 26px;
  padding-right: 38px;
  cursor: pointer;

  & > span {
    margin-left: 31px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.2px;
    color: var(--black);
  }

  border-bottom: 1px solid var(--grey150);
`;

const BlankDiv = styled("div")`
  border-top: 1px solid var(--grey150);
  height: 90px;
`;

const LoadingContainer = styled("div")`
  padding-top: 19px;
  padding-left: 29px;

  & > * {
    margin-bottom: 15px;
  }
`;

const noop = () => null;

interface ScanPopoverInnerProps {
  basicItem: Item | undefined;
  relatedTasksCount: number | undefined;
  ofWhichClosed?: number | undefined;
  withContinue: boolean | undefined;
  withActions: boolean | undefined;
  notFound: boolean | undefined;
  onContinue: () => void;
  onAction: (url: string, data: { product: string; type: string }) => void;
  onRelated: () => void;
  onItemClick: () => void;
  price: ItemSalesPrice | undefined;
  secondaryPrice: ItemSalesPrice | undefined;
  loading: boolean;
  latestSalesDate: React.ReactNode;
  hasActiveProductQualityTeam: boolean;
  onClose: () => void;
}

function ScanPopoverInner(
  {
    basicItem,
    relatedTasksCount = 0,
    ofWhichClosed = 0,
    withContinue = false,
    onContinue,
    withActions = false,
    onAction = noop,
    onRelated = noop,
    notFound = false,
    onItemClick = noop,
    price,
    secondaryPrice,
    loading,
    latestSalesDate,
    hasActiveProductQualityTeam,
    onClose = noop,
  }: ScanPopoverInnerProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { t } = useTranslation();
  const {
    name = "",
    description = "",
    no: shortId,
    type,
    smallImageUrl = "",
  } = basicItem || {};
  const fullId = `${type}${shortId}`;

  const [actionsOpen, setActionsOpen] = React.useState(false);
  const onActionOverride = React.useCallback(
    (url: string) => {
      if (!type || !shortId) return;
      onAction(url, { product: shortId, type: type });
      logEvent("ce:halfsheet:create", { type, shortId });
    },
    [onAction, shortId, type]
  );

  const [showArticleErrorModal, setShowArticleErrorModal] =
    React.useState(false);
  const onCloseArticleErrorModal = () => {
    setShowArticleErrorModal(!showArticleErrorModal);
  };

  const hasProduct = basicItem && !notFound;

  return (
    <React.Fragment>
      <PopoverContainer ref={ref} data-testid="scanPopover">
        <FixaModalHeader
          title={t("articleString")}
          closeBtnClick={() => {
            onClose?.();
          }}
        />
        {hasProduct && !loading && (
          <ScanItemResult
            title={name}
            price={price}
            secondaryPrice={secondaryPrice}
            image={smallImageUrl}
            onClick={onItemClick}
            description={description}
            fullId={fullId}
            latestSalesDate={latestSalesDate}
          />
        )}
        {(notFound || loading) && (
          <>
            <LoadingContainer>
              <SearchSkeleton />
              {notFound && (
                <ErrorMessage text={<Trans>noResultsString</Trans>} />
              )}
            </LoadingContainer>
            <BlankDiv />
          </>
        )}
        {hasProduct && relatedTasksCount > 0 && (
          <BlueLinkWithNumber
            onClick={onRelated}
            text={<Trans>relatedTasksString</Trans>}
            number={relatedTasksCount}
            ofWhichClosed={ofWhichClosed}
          />
        )}
        {hasProduct && withContinue && (
          <ButtonWithSpacing
            customMargin
            data-testid="continueButton"
            text={<Trans>continue2String</Trans>}
            primary
            dark
            onClick={onContinue}
          />
        )}
        {hasProduct && !loading && withActions && (
          <React.Fragment>
            <ScanPopoverActions
              data-testid="actionButton"
              onClick={() => {
                setActionsOpen(!actionsOpen);
                logEvent("ce:halfsheet:toggle", { state: actionsOpen });
              }}
            >
              <span>
                <Trans>create2String</Trans>
              </span>
              <ExpandIcon rotated={actionsOpen} />
            </ScanPopoverActions>
            {actionsOpen && (
              <div data-testid="actionContainer">
                <ActionItem
                  data-testid="createSearchProduct"
                  onClick={() => onActionOverride("/task/new/product")}
                >
                  <ProductIcon />
                  <span>
                    <Trans>productString</Trans>
                  </span>
                </ActionItem>
                <ActionItem
                  data-testid="createSearchAddon"
                  onClick={() => {
                    const isItemPriceOrCurrencyMissing =
                      !price?.price || !price?.currency;
                    if (isItemPriceOrCurrencyMissing) {
                      setShowArticleErrorModal(true);
                      return;
                    }
                    onActionOverride("/task/new/addon");
                  }}
                >
                  <AddonIcon />
                  <span>
                    <Trans>addonOrderString</Trans>
                  </span>
                </ActionItem>
                {hasActiveProductQualityTeam && (
                  <ActionItem
                    data-testid="createSearchProductQuality"
                    onClick={() =>
                      onActionOverride("/task/new/product_quality")
                    }
                  >
                    <AddonIcon />
                    <span>
                      <Trans>productQualityReportString</Trans>
                    </span>
                  </ActionItem>
                )}
              </div>
            )}
            <ArticleError
              itemErrorType={ItemError.ITEM_PRICE_MISSING}
              openArticleErrorModal={showArticleErrorModal}
              onClose={onCloseArticleErrorModal}
            />
          </React.Fragment>
        )}
      </PopoverContainer>
    </React.Fragment>
  );
}

export const ScanPopover = React.forwardRef(ScanPopoverInner);
