import React from "react";
import { useTeamId } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import FullScreenPopup from "../../../layout/components/FullScreenPopup/FullScreenPopup";
import { useTranslation } from "react-i18next";
import { SimpleLoadingBall } from "../../../shared/simple/SimpleLoading/SimpleLoading";

export function LandingPage() {
  const teamId = useTeamId();
  const { replace } = useWorkspacesAction();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!teamId) {
      replace("/teamselection");
    } else {
      replace("/home");
    }
  }, [teamId, replace]);
  
  return (
    <FullScreenPopup noPadding noAppBar>
      <SimpleLoadingBall text={t("loadingEllipsisString")} />
    </FullScreenPopup>
  );
}
