import React from "react";
import styled from "styled-components";

const Context = React.createContext(null);

const TooltipBackdrop = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--z-tooltip);
`;

const TooltipContainer = styled.div.attrs(({ posX, posY }) => ({
  style: {
    left: posX,
    top: posY,
  },
}))`
  z-index: var(--z-tooltip);
  position: fixed;
  transform: translateX(-50%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px var(--grey200);
  background-color: var(--white);
  padding: 18px;
  &:after {
    position: absolute;
    top: -5px;
    left: 50%;
    content: "";
    width: 8px;
    height: 8px;
    border-left: solid 1px var(--grey200);
    border-top: solid 1px var(--grey200);
    transform: translateX(-50%) rotate(45deg);
    background: var(--white);
  }
`;

function Tooltip() {
  const [tooltip, setTooltip] = React.useContext(Context);

  if (!tooltip) {
    return null;
  }
  return (
    <React.Fragment>
      <TooltipBackdrop onClick={() => setTooltip(null)} />
      <TooltipContainer posX={tooltip.x} posY={tooltip.y}>
        {tooltip.content}
      </TooltipContainer>
    </React.Fragment>
  );
}

export function TooltipProvider({ children }) {
  const state = React.useState(null);
  return (
    <Context.Provider value={state}>
      {children}
      <Tooltip />
    </Context.Provider>
  );
}

export function useTooltip() {
  const [tooltip, setTooltip] = React.useContext(Context);

  const showTooltip = (event, content) => {
    const bounds = event.target.getBoundingClientRect();
    setTooltip({
      x: bounds.x + bounds.width / 2,
      y: bounds.bottom + 8,
      content,
    });
  };

  return {
    tooltip,
    showTooltip,
  };
}
