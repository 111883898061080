import { useParams } from "react-router-dom";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import { useGetArticleById } from "../CommonComponents/Repositories/useArticleService";
import { DisplayArticleDetails } from "./DisplayArticleDetails";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { formatProductIdWithDots } from "@coworker/reusable";
import LinkbarLoading from "@coworker/apprestructured/src/shared/components/Linkbar/LinkbarLoading";

export function ArticleDetailsPage() {
  const { id: testbuyId = "", articleId = "" } = useParams<{
    id: string;
    articleId: string;
  }>();
  const { data: article, loading: articleLoading } = useGetArticleById(
    testbuyId,
    articleId
  );

  const headerTitleText = `${article.name} ${formatProductIdWithDots(
    article.shortId
  )}`;

  if (articleLoading)
    return (
      <FullScreenPopup
        appBarConfig={{
          title: headerTitleText,
        }}
        noPadding
        linkbar={<LinkbarLoading />}
      >
        <PositionedLoaderIcon />
      </FullScreenPopup>
    );
  return (
    <DisplayArticleDetails
      testbuyId={testbuyId}
      article={article}
      showNavigationIcon={false}
      isInteractive={false}
    />
  );
}
