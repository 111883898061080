import React from "react";
import { StoreTeam } from "@coworker/types/lib/storeteam";
import { ModalType } from "../../../../layout/context/modalType";
import { SwitcherSheet } from "./SwitcherSheet";
import { Store } from "../../../../shared/types/store";

interface SwitchStoreSheetProps {
  stores: Store[] | undefined;
  selectedStore: Store | undefined;
  setSelectedStore: React.Dispatch<React.SetStateAction<Store | undefined>>;
  setSelectedTeam: React.Dispatch<React.SetStateAction<StoreTeam | undefined>>;
  loading: boolean;
}

export const SwitchStoreSheet = (props: SwitchStoreSheetProps) => (
  <SwitcherSheet
    modalType={ModalType.SwitchStore}
    titleKey="adminStoreFilter"
    placeholderKey="searchStoreString"
    items={props.stores ?? []}
    selectedItem={props.selectedStore}
    getItemLabel={(store) => `${store?.storeId} - ${store?.name}`}
    getItemKey={(store) => store?.name ?? ""}
    onSelect={(store) => {
      props.setSelectedStore(store);
      props.setSelectedTeam({} as StoreTeam);
    }}
    loading={props.loading}
  />
);
