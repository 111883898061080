import React from "react";
import { Navigate } from "react-router-dom";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";
import { TeamSetter } from "./TeamSetter";
import { useTeamId } from "../core/auth/useLoggedInUser";
import { useStoreTeams } from "../core/hooks/useStoreTeams";
import statusTypes from "@coworker/enums/statusTypes";

export function ChangeTeamWrapper({ children }) {
  const [closedSelectTeam, setClosedSelectTeam] = React.useState();
  const teamId = useTeamId();
  const teams = useStoreTeams();
  const loaded = !!teams.length;
  const myteam = teams.find((team) => team.id === teamId);

  const isDeactivated =
    loaded && !!myteam && myteam.status !== statusTypes.ACTIVE;

  const { pathname } = useFixaLocation();
  const showSelectTeam =
    loaded &&
    pathname !== "/logout" &&
    isDeactivated &&
    !!teamId &&
    (!closedSelectTeam || closedSelectTeam !== teamId);

  // TODO this is the only thing we can not support yet with router 6
  // The functionality will come in a future update so given we navigate out if the team is deactivated
  // i think its ok to not block navigation for now
  // React.useEffect(
  //   () =>
  //     history.block(({ pathname }) => {
  //       if (pathname !== "/logout" && isDeactivated) {
  //         setClosedSelectTeam();
  //         return false; // block router navigation event
  //       } else {
  //         return true; // allow navigation
  //       }
  //     }),
  //   [myteam, history]
  // );

  if (isDeactivated && !["/home", "/logout"].includes(pathname)) {    
    return <Navigate to="/home" />;
  }

  return (
    <React.Fragment>
      {children}
      <TeamSetter
        shown={showSelectTeam}
        onClose={() => teamId && setClosedSelectTeam(teamId)}
      />
    </React.Fragment>
  );
}
