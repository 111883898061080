import React from "react";
import { MainMessage, Media } from "../../types/media";
import { FixaTextArea } from "@coworker/apprestructured/src/shared/wrappers/FixaTextArea/FixaTextArea";
import { useTranslation } from "@coworker/locales";

interface MainMessageInputProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const MainMessageInput = ({ media, onSave }: MainMessageInputProps) => {
  const { t } = useTranslation();

  return (
    <FixaTextArea
      id={"main_message"}
      label={t("mainMessageString")}
      style={{ height: "100px" }}
      value={(media as MainMessage).mainMessage ?? ""}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newMainMessage = event.target.value;
        const newMedia = { ...media };
        (newMedia as MainMessage).mainMessage = newMainMessage;
        onSave(newMedia);
      }}
    />
  );
};
