import React from "react";
import { animated } from "react-spring";
import styled, { css } from "styled-components";

export interface SlidingButtonProps {
  children: React.ReactNode;
  onClick: Function;
  style?: object;
  disabled?: boolean;
  background?: string;
}

// eslint-disable-next-line no-mixed-operators
const Button = animated(styled('div')<SlidingButtonProps>`
  width: 100px;
  height: 100%;
  min-height: 110px;
  color: var(--white);
  font-size: 12px;
  flex-shrink: 0;
  background-color: ${({ background }) => background || "var(--grey900)"};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  flex-direction: column;
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--grey500);
      background: var(--grey200);
      pointer-events: none;
    `}
`);

export const SlidingButtonText = styled('div')`
  width: 80px;
  flex-shrink: 0;
`;

export const SlidingButtonIcon = styled('div')`
  flex-shrink: 0;
`;

export const SlidingButton = ({
  children,
  onClick,
  style,
  disabled = false,
  background,
}: SlidingButtonProps) => {
  return (
    <Button
      style={style}
      background={background}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
