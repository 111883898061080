import React from "react";
import styled from "styled-components";
import { Navigate } from "react-router-dom";
import { announceableFeatures } from "./announcements";
import { useWorkspacesState } from "../../hooks/useWorkspaces";

const AnnouncementHeading = styled('div')`
  display: flex;
  flex-direction: column;
  height: 191px;
  background-color: var(--yellow);
`;

const Title = styled('span')`
  font-size: 64px;
  padding-left: 30px;
`;

export function AnnouncementPage() {
  const { navigationState } = useWorkspacesState();
  const FeatureDetailedAnnouncement =
    announceableFeatures[navigationState.feature]?.detailsView;

  if (!FeatureDetailedAnnouncement) return <Navigate to="/" />;

  return (
    <div>
      <AnnouncementHeading>
        <Title>
          <span role="img" aria-label="Congratulations">
            🎉
          </span>
        </Title>
      </AnnouncementHeading>
      <FeatureDetailedAnnouncement />
    </div>
  );
}
