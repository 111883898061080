import { MouseEvent } from "react";
import { ModalHeader } from "@ingka/modal";

interface ModalHeaderProps {
  title?: string;
  titleId?: string;
  floating?: boolean;
  ariaCloseTxt?: string;
  className?: string;
  closeBtnClick?: (e: MouseEvent) => void;
  backBtnClick?: (e: MouseEvent) => void;
}

export const FixaModalHeader = (props: ModalHeaderProps) => {
  return <ModalHeader {...props} />;
};
