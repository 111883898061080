import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CheckIcon } from "../assets/svg/check.svg";

const StyledCheckbox = styled('div')`
  appearance: none;
  cursor-events: none;
  cursor: none;
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.focus &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: -3px;
        left: -3px;
        width: 28px;
        height: 28px;
        border-radius: 30px;
        border: 1px solid var(--grey500);
        border: ${(props) => props.checked && "1px solid var(--grey900)"};
        border: ${(props) =>
          props.checked && props.blue && "1px solid var(--blue)"};
        border: ${(props) => props.light && "1px solid var(--white)"};
        border: ${(props) => props.error && "1px solid var(--error)"};
        border: ${(props) =>
          props.light && props.error && "1px solid var(--yellow)"};
      }
    `}
`;
const Input = styled('input')`
  position: absolute;
  cursor: pointer;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: transparent;
  border: ${(props) =>
    props.error ? "1px solid var(--error)" : "1px solid var(--grey500)"};
  border: ${(props) => props.light && "1px solid var(--white)"};
  border: ${(props) => props.light && props.error && "1px solid var(--yellow)"};
  border: ${(props) =>
    props.isPressed &&
    !props.error &&
    !props.light &&
    "1px solid var(--grey700)"};
`;
const Check = styled(CheckIcon)`
  cursor: pointer;
  position: absolute;
  background: ${(props) => (props.blue ? "var(--blue)" : "var(--grey900)")};
  background: ${(props) => props.error && "var(--error)"};
  background: ${(props) => props.light && "var(--white)"};
  background: ${(props) => props.light && props.error && "var(--yellow)"};
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  path: last-of-type {
    fill: ${(props) => (props.light ? "var(--blue)" : "var(--white)")};
  }
`;

const Checkbox = ({ blue, error, light, checked, ...props }) => {
  const [isFocused, setFocus] = React.useState(false);
  const [isPressed, setPressed] = React.useState(false);

  const focus = React.useCallback((event) => {
    setFocus(true);
  }, []);

  const blur = React.useCallback((event) => {
    setFocus(false);
  }, []);

  const dontFocus = () => {
    setPressed(true);
  };

  const allowFocus = () => {
    setPressed(false);
  };

  return (
    <StyledCheckbox
      focus={!isPressed && isFocused}
      blue={blue}
      error={error}
      light={light}
      checked={checked}
    >
      <label>
        <Input
          {...props}
          type="checkbox"
          onFocus={focus}
          onBlur={blur}
          onMouseDown={dontFocus}
          onMouseUp={allowFocus}
          blue={blue}
          error={error}
          light={light}
          isPressed={isPressed}
          checked={checked}
        />
        {checked && <Check blue={blue} error={error} light={light} />}
      </label>
    </StyledCheckbox>
  );
};

export default Checkbox;
