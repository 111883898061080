import React from "react";
import styled from "styled-components";
import { ReactComponent as NextIcon } from "../assets/svg/icon-arrow-small-forward-cropped.svg";
import { Trans } from "@coworker/locales";

const Subtext = styled('span')`
  color: var(--grey718);
  font-weight: normal;
`;

const RelatedTasks = styled('div')`
  display: flex;
  align-items: center;
  padding-left: 26px;
  padding-right: 27px;
  min-height: 81px;
  border-top: 1px solid var(--grey150);
  padding-top: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: var(--related-blue);
  & span:first-child {
    flex-grow: 1;
  }
  & span:nth-child(2) {
  }
  cursor: pointer;
  user-select: none;
`;

const Next = styled(NextIcon)`
  color: var(--related-blue);
  margin-left: 9px;
  margin-top: 1px;
`;

/**
 * @param {{ number:number|undefined, ofWhichClosed?:number|undefined,onClick:function, text:any }}
 */
export function BlueLinkWithNumber({ text, number, ofWhichClosed, onClick }) {
  return (
    <RelatedTasks onClick={onClick} data-testid="relatedTasks">
      <span>
        {ofWhichClosed && ofWhichClosed !== number ? (
          <>
            {text}
            <br />
            <Subtext>
              <Trans count={ofWhichClosed}>ofWhichRecentlyClosedString</Trans>
            </Subtext>
          </>
        ) : ofWhichClosed ? (
          <Trans count={ofWhichClosed}>onlyRecentlyClosedString</Trans>
        ) : (
          text
        )}
      </span>
      <span>{number}</span>
      <Next />
    </RelatedTasks>
  );
}
