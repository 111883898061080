import React from "react";
import styles from "./swipeable-task-card.module.css";
import { Trans } from "@coworker/locales";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import CheckmarkIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CheckmarkIconPath";
import PencilIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PencilIconPath";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
// @ts-ignore
import { useCompleteTask } from "@coworker/app/src/hooks/TaskActions/useCompleteTask";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import { FullTask } from "../../../activity/types/FullTask";

interface CompleteButtonsProps {
  swipeDirection: string;
  setSwipeDirection: React.Dispatch<
    React.SetStateAction<"" | "left" | "right">
  >;
  isAddonTask: boolean;
  task: FullTask;
}

export const CompleteButtons = ({
  swipeDirection,
  setSwipeDirection,
  isAddonTask,
  task,
}: CompleteButtonsProps) => {
  const { push } = useWorkspacesAction();
  const { toggleModal } = React.useContext(ModalContext);

  const { call: completeTask } = useCompleteTask(task, task.id, {
    undo: true,
    afterUndo: () =>
      toggleModal({
        type: ModalType.TaskDetail,
        payload: { taskId: task.id, taskType: task?.task_type },
      }),
  });

  return (
    <div
      className={`${styles["complete-buttons"]} ${
        swipeDirection === "right"
          ? styles["complete-buttons-visible"]
          : styles["complete-buttons-hidden"]
      }`}
    >
      <div className={styles["complete-buttons-wrapper"]}>
        <div
          className={styles["button-complete"]}
          onClick={() => {
            completeTask();
            setSwipeDirection("");
          }}
        >
          <FixaSSRIcon paths={CheckmarkIconPath} />
          <span className={styles["button-text"]}>
            <Trans>completeString</Trans>
          </span>
        </div>
        {isAddonTask && (
          <div
            className={styles["button-complete-with-changes"]}
            onClick={() => push(`/task/${task.id}/changes`, { picking: false })}
          >
            <FixaSSRIcon paths={PencilIconPath} />
            <span className={styles["button-text"]}>
              <Trans>completeWithChangesString</Trans>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
