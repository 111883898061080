import React from "react";
import { Media, StyleGroup } from "../../types/media";
import {
  FixaOption,
  FixaSelect,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import PaintbrushIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PaintbrushIconPath";
import { getStyleGroupOptions } from "../../../../helpers/styleGroupOptions";
import { useTranslation } from "@coworker/locales";

interface StyleGroupSelectProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const StyleGroupSelect = ({ media, onSave }: StyleGroupSelectProps) => {
  const { t } = useTranslation();
  return (
    <FixaSelect
      id={"style_group"}
      label={t("styleGroupString")}
      value={(media as StyleGroup).styleGroup ?? ""}
      ssrIcon={PaintbrushIconPath}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const newStyleGroup =
          event.target.selectedIndex === 0 ? "" : event.target.value;
        const newMedia = { ...media };
        (newMedia as StyleGroup).styleGroup = newStyleGroup;
        onSave(newMedia);
      }}
    >
      {getStyleGroupOptions().map((styleGroup) => (
        <FixaOption {...styleGroup} key={styleGroup.value} />
      ))}
    </FixaSelect>
  );
};
