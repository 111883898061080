import styled from "styled-components";
import { Trans } from "@coworker/locales";

const OptionalLabelWrapper = styled('div')`
  opacity: 0.58;
  font-size: 14px;
  line-height: 24px;
  margin-right: 5px;
  flex-grow: 0;
  font-weight: normal;
`;

export function OptionalLabel() {
  return (
    <OptionalLabelWrapper>
      <Trans>optionalString</Trans>
    </OptionalLabelWrapper>
  );
}
