import { FixaListViewItem } from "../../../wrappers/FixaListView/FixaListViewItem";
import { SelectItem } from "../../types";

interface CategoryListItemProps {
  item: SelectItem;
  prefix: string;
}

export const CategoryListItem = ({ item, prefix }: CategoryListItemProps) => {
  return (
    <FixaListViewItem
      inset
      showDivider={false}
      title={<b>{item.title}</b>}
      data-testid={`listview-option-${prefix}-${item.title}`}
    />
  );
};
