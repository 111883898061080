import { actorTypeKeys } from "@coworker/enums/actorTypes";
import { useStoreTeams } from "../hooks/useStoreTeams";
import { useAllStoreUsers } from "@coworker/apprestructured/src/shared/hooks/users/useAllStoreUsers";
import { UserStatuses } from "@coworker/apprestructured/src/shared/enums/user/userStatuses";
import { useTranslation } from "@coworker/locales";

export function UserOrGroupName({ uid, gid }) {
  const { data: users } = useAllStoreUsers();
  const { t } = useTranslation();
  const user = users?.find((user) => user.fixaUid === uid || user.uid === uid);
  const teams = useStoreTeams();
  const team = teams?.find((team) => team.id === gid);

  if (actorTypeKeys[uid]) return <div>t(actorTypeKeys[uid])</div>;

  if (uid) {
    const { firstName, lastName, fullName, status } = user || {};
    if (status === UserStatuses.DELETED)
      return <div>{t("deletedUserString")}</div>;
    return (
      <div>{fullName || firstName || lastName || t("unknownUserString")}</div>
    );
  }

  return <div>{team?.name || ""}</div>;
}
