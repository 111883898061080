import React from "react";

export const SCROLL_DOWN = "SCROLL_DOWN";
export const SCROLL_UP = "SCROLL_UP";
export const NO_SCROLL = "NO_SCROLL";
export const NOT_SCROLLABLE = "NOT_SCROLLABLE";

export type ScrollDirectionType =
  | typeof SCROLL_DOWN
  | typeof SCROLL_UP
  | typeof NO_SCROLL
  | typeof NOT_SCROLLABLE;

export const useScrollDirection = () => {
  const [scrollTarget, setScrollTarget] = React.useState<HTMLElement | null>(
    null
  );
  const [scrollDirection, setScrollDirection] =
    React.useState<ScrollDirectionType>(NO_SCROLL);
  const lastDirection = React.useRef<ScrollDirectionType>(NO_SCROLL);
  const lastPosition = React.useRef(0);
  const lastTimeout = React.useRef(0);
  const lastFrame = React.useRef(0);

  const determineScrollEvent = React.useCallback(() => {
    if (!scrollTarget) return;
    const lastScroll = lastPosition.current;
    const currentScroll = scrollTarget.scrollTop;
    let direction: ScrollDirectionType;

    /* Catch elastic scroll bounce from top on iOS */
    if (currentScroll <= 0) {
      direction = SCROLL_UP;
    } else if (
      /* Catch elastic scroll bounce from bottom on iOS */
      scrollTarget.scrollHeight <=
      scrollTarget.scrollTop + scrollTarget.clientHeight
    ) {
      direction = SCROLL_DOWN;
    } else if (
      // Determining SCROLL_DOWN in the null case assumes
      // that initially the container is scrolled to the top
      lastScroll === null ||
      lastScroll < currentScroll
    ) {
      direction = SCROLL_DOWN;
    } else {
      direction = SCROLL_UP;
    }

    if (direction !== lastDirection.current && lastFrame.current === 0) {
      clearTimeout(lastTimeout.current);
      const currentTimeout = window.setTimeout(() => {
        lastFrame.current = window.requestAnimationFrame(() => {
          setScrollDirection(lastDirection.current);
          lastFrame.current = 0;
        });
        lastTimeout.current = currentTimeout;
      }, 250);
    }

    lastDirection.current = direction;
    lastPosition.current = currentScroll;
  }, [scrollTarget]);

  React.useEffect(() => {
    const copyRef = scrollTarget;
    if (!copyRef) return;

    if (copyRef.clientHeight >= copyRef.scrollHeight) {
      setScrollDirection(NOT_SCROLLABLE);
    } else if (
      scrollDirection !== SCROLL_DOWN &&
      scrollDirection !== SCROLL_UP
    ) {
      setScrollDirection(NO_SCROLL);
    }

    copyRef.addEventListener("scroll", determineScrollEvent);
    return () => {
      if (!copyRef) return;
      copyRef.removeEventListener("scroll", determineScrollEvent);
    };
     // Ignored: scrollDirection
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTarget, determineScrollEvent]);

  return [scrollDirection, setScrollTarget, scrollTarget] as const;
};
