import { useEffect, useState } from "react";
import { callInternalApi } from "../../../hooks/API/useCallInternal";
import { MediaTask } from "@coworker/types/lib/tasks/media";
import { deleteTaskLinks } from "../services/tasks.service";
import { OngoingTaskLinksResponse, TaskLinks } from "../types/taskLink";
import { TaskListingTypes } from "../constants";
import { getTaskFilter } from "../tools";
import dayjs from "dayjs";
import { useStoreId } from "../../../core/auth/useLoggedInUser";

export function useTaskLinks(
  taskLinks: TaskLinks,
  taskListingType: TaskListingTypes
) {
  const [tasks, setTasks] = useState<MediaTask[]>([]);
  const [tasksFetched, setTasksFetched] = useState<boolean>(false);
  const [missingTaskIds, setMissingTaskIds] = useState<string[]>([]);
  const storeId = useStoreId();
  useEffect(() => {
    const taskIds = taskLinks.map((taskLink) => taskLink.taskId);
  
    if (taskIds.length === 0) {
      setTasksFetched(false);
      return;
    }
  
    callInternalApi(`tasks/by_ids?ids=${taskIds.join(",")}`, {
      store_id: storeId,
    })
      .then((response) => {
        const responseTasks = response?.data?.tasks || response?.list || response?.tasks || [];
        setTasks(responseTasks);
        setTasksFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching tasks:', error);
        setTasksFetched(false);
      });
  }, [taskLinks, storeId]);
  

  useEffect(() => {
    const retainTaskLinksDate = dayjs().subtract(12, "hour").toISOString();
    if (tasksFetched) {
      const taskIds: string[] =
        taskLinks
          .filter((tl) => tl.createdAt < retainTaskLinksDate)
          .map((taskLink) => taskLink.taskId) || [];
      const missingTaskIdsComparedToTaskService = taskIds.filter(
        (taskId) => !tasks.find((task) => task.id === taskId)
      );

      if (missingTaskIdsComparedToTaskService.length > 0) {
        setMissingTaskIds(missingTaskIdsComparedToTaskService);
      }
    }
  }, [tasks, taskLinks, tasksFetched]);

  useEffect(() => {
    if (missingTaskIds.length > 0) deleteTaskLinks(missingTaskIds);
  }, [missingTaskIds]);

  const tasksFilterFunction = getTaskFilter(taskListingType);

  const filteredAndSortedTasks = tasks
    .filter(tasksFilterFunction)
    .sort((t1, t2) => (t1.updated_at > t2.updated_at ? 1 : -1));
  const ongoingTasksFirstByDate = filteredAndSortedTasks
    .filter(getTaskFilter(TaskListingTypes.Ongoing))
    .concat(
      filteredAndSortedTasks.filter(getTaskFilter(TaskListingTypes.NotOngoing))
    );

  const ongoingTaskLinks = taskLinks.filter((tl) => {
    return filteredAndSortedTasks.find((t) => t.id === tl.taskId);
  });
  const sortedOngoingTaskLinks = ongoingTaskLinks.sort(
    (tl1, tl2) => (tl1.createdAt > tl2.createdAt ? 1 : -1) // TaskLinks never update. So, there will be only a createdAt date
  );

  return {
    count: ongoingTasksFirstByDate.length,
    tasks: ongoingTasksFirstByDate,
    taskLinks: sortedOngoingTaskLinks,
  } as OngoingTaskLinksResponse;
}
