import { useEffect, useMemo, useState } from "react";
import { Article, Combined } from "../types/article";
import { useEDSData } from "./useEDSData";
import { useSalesStopData } from "./useSalesStopData";
import { joinProductArticleIds } from "../tools";
import { useTaskLinksByMedia } from "./useTaskLinksByMedia";

export function useCombinedArticles(
  countryId: string,
  storeId: string,
  mediaId: string,
  articles: Article[] | undefined
) {
  // The same article objects are referenced in articles, combinedArticles and
  // articleMap.
  const [combinedArticles, setCombinedArticles] = useState<
    Combined<Article>[] | undefined
  >(articles);
  const articleMap: Map<string, Combined<Article>> = useMemo(
    () =>
      new Map(
        articles?.map((article) => {
          if (article.createdAt) {
            article.createdAtDate = new Date(article.createdAt);
          }
          return [article.productArticleId, article];
        }) ?? []
      ),
    [articles]
  );
  const productArticleIdsString = useMemo(
    () => joinProductArticleIds(articles ?? []),
    [articles]
  );

  const { data: edsList } = useEDSData(countryId, productArticleIdsString);
  useEffect(() => {
    for (const eds of edsList ?? []) {
      if (eds.validToDateTime) {
        eds.validToDate = new Date(eds.validToDateTime);
      }
      if (eds.validFromDateTime) {
        eds.validFromDate = new Date(eds.validFromDateTime);
      }
      articleMap.get(eds.productArticleId)!.eds = eds;
    }
    setCombinedArticles(articles ? [...articles] : articles);
  }, [articleMap, articles, edsList]);

  const { data: salesStopList } = useSalesStopData(
    storeId,
    productArticleIdsString
  );
  useEffect(() => {
    for (const salesStop of salesStopList ?? []) {
      articleMap.get(salesStop.productArticleId)!.salesStop = salesStop;
    }
    setCombinedArticles(articles ? [...articles] : articles);
  }, [articleMap, articles, salesStopList]);

  const { data: tasks } = useTaskLinksByMedia(mediaId);
  useEffect(() => {
    for (const task of tasks ?? []) {
      const article = articleMap.get(task.productArticleId)!;
      if (article) {
        if (!article.tasks) {
          article.tasks = [];
        }
        article.tasks.push(task);
      }
    }
    setCombinedArticles(articles ? [...articles] : articles);
  }, [articleMap, articles, tasks]);

  return combinedArticles;
}
