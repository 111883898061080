import React, { Dispatch } from "react";
import { Country } from "@coworker/app/src/core/hooks/useCountries";
import { StoreTeam } from "@coworker/types/lib/storeteam";
import { ModalType } from "../../../../layout/context/modalType";
import { SwitcherSheet } from "./SwitcherSheet";
import { Store } from "../../../../shared/types/store";

interface SwitchCountrySheetProps {
  countries: Country[] | undefined;
  selectedCountry: Country;
  setSelectedCountry: React.Dispatch<React.SetStateAction<Country | undefined>>;
  setSelectedStore: React.Dispatch<React.SetStateAction<Store | undefined>>;
  setSelectedTeam: Dispatch<React.SetStateAction<StoreTeam | undefined>>;
  loading: boolean;
}

export const SwitchCountrySheet = (props: SwitchCountrySheetProps) => (
  <SwitcherSheet
    modalType={ModalType.SwitchCountry}
    titleKey="adminCountryFilter"
    placeholderKey="searchCountryString"
    items={props.countries ?? []}
    selectedItem={props.selectedCountry}
    getItemLabel={(country) => country?.name}
    getItemKey={(country) => country?.name}
    onSelect={(country) => {
      props.setSelectedCountry(country);
      props.setSelectedStore({} as Store);
      props.setSelectedTeam({} as StoreTeam);
    }}
    loading={props.loading}
  />
);
