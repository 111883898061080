import React from "react";
import { Media, SquareMeters } from "../../types/media";
import { useTranslation } from "@coworker/locales";
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";

interface SquareMetersInputProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const SquareMetersInput = ({
  media,
  onSave,
}: SquareMetersInputProps) => {
  const { t } = useTranslation();

  return (
    <FixaInputField
      type="number"
      id={"square_meters"}
      label={t("squareMetersString")}
      value={`${(media as SquareMeters).squareMeters ?? 0}`}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const newSquareMeters = event.target.value;
        const newMedia = { ...media };
        (newMedia as SquareMeters).squareMeters = parseInt(
          newSquareMeters ? newSquareMeters : "0"
        );
        onSave(newMedia);
      }}
    />
  );
};
