import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { StoredImage } from "./StoredImage";
import { Trans } from "@coworker/locales";

const PreviewHeader = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 30px 28px 10px 28px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  flex-shrink: 0px;
`;

const HeaderTitle = styled('div')`
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: white;
`;

const HeaderButton = styled('button')`
  line-height: 21px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
`;

const ImagePreviewContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageHolder = styled('div')`
  margin-right: 9px;
  margin-bottom: 9px;
  overflow: hidden;
  display: inline-flex;
  vertical-align: top;
  width: 100%;
  height: 100%;
`;

function PreviewImage(props) {
  return (
    <AnimatePresence>
      {props.image && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.2 } }}
        >
          <PreviewHeader>
            <HeaderButton onClick={props.onClose}>
              <Trans>cancelString</Trans>
            </HeaderButton>
            <HeaderTitle>
              <Trans>imageString</Trans>
            </HeaderTitle>
            <HeaderButton onClick={props.onDelete}>
              <Trans>deleteString</Trans>
            </HeaderButton>
          </PreviewHeader>
          <ImagePreviewContainer>
            <ImageHolder>
              <StoredImage fullscreen image={props.image} dark />
            </ImageHolder>
          </ImagePreviewContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default PreviewImage;
