import AddOnIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/AddOnIconPath";
import DataChartIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/DataChartIconPath";
import ClipboardCheckmarkIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ClipboardCheckmarkIconPath";
import RoomLayoutIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/RoomLayoutIconPath";
import ShoppingBagIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ShoppingBagIconPath";
import SpeechBubbleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/SpeechBubbleIconPath";
import BoxCancelIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/BoxCancelIconPath";
import ChairIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChairIconPath";
import PencilIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PencilIconPath";
import LinkOutIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/LinkOutIconPath";

import { TaskTypesRoutes } from "../../../../home/constants/taskTypesRoutes";

const NavigationLabelsKeys = {
  ADDON: "addonString",
  INSIGHTS: "addonInsightsString",
  TEST_BUY: "testBuyString",
  ROOM_SETTINGS: "roomSettingsString",
  MEDIA_MAINTENANCE_ROOM_SETTINGS: "mediaMaintenanceString",
  SHOPPING_TOOLS: "shoppingToolsString",
  CUSTOMER_MFAQ: "customerQuestionsString",
  PRODUCT_QUALITY_REPORT: "productQualityReportString",
  PRODUCT_ISSUE: "productString",
  CUSTOM_TASK: "customString",
  DASHBOARD: "dashboardString",
};

export type NavigationItem = {
  id: string;
  orderIndex: number;
  icon: (prefix?: string) => React.SVGProps<SVGElement>[];
  labelTranslationKey: string;
  href?: string;
  taskType?: string;
  link?: string;
  testId: string;
};

export const NavigationItemsConfig: NavigationItem[] = [
  {
    id: "dashboard",
    orderIndex: 10,
    icon: LinkOutIconPath,
    labelTranslationKey: NavigationLabelsKeys.DASHBOARD,
    link: "https://fixadashboard.ingka.com",
    testId: "dashboardNavigationButton",
  },
  {
    id: "add-on",
    orderIndex: 9,
    icon: AddOnIconPath,
    labelTranslationKey: NavigationLabelsKeys.ADDON,
    taskType: TaskTypesRoutes.ADDON,
    testId: "addonNavigationButton",
  },
  {
    id: "insights",
    orderIndex: 8,
    icon: DataChartIconPath,
    labelTranslationKey: NavigationLabelsKeys.INSIGHTS,
    href: "/insights",
    testId: "insideNavigationButton",
  },
  {
    id: "test-buy",
    orderIndex: 7,
    icon: ClipboardCheckmarkIconPath,
    labelTranslationKey: NavigationLabelsKeys.TEST_BUY,
    href: "/testbuy",
    testId: "testBuyNavigationButton",
  },
  {
    id: "room-setting",
    orderIndex: 6,
    icon: RoomLayoutIconPath,
    labelTranslationKey: NavigationLabelsKeys.MEDIA_MAINTENANCE_ROOM_SETTINGS,
    href: "/mediamaintenance",
    testId: "roomSettingsNavigationButton",
  },
  {
    id: "shopping-tools",
    orderIndex: 5,
    icon: ShoppingBagIconPath,
    labelTranslationKey: NavigationLabelsKeys.SHOPPING_TOOLS,
    href: "/shoppingtools",
    testId: "shoppingToolsNavigationButton",
  },
  {
    id: "customer-mfaq",
    orderIndex: 4,
    icon: SpeechBubbleIconPath,
    labelTranslationKey: NavigationLabelsKeys.CUSTOMER_MFAQ,
    href: "/customerquestions",
    testId: "customerQuestionsNavigationButton",
  },
  {
    id: "product-quality-report",
    orderIndex: 3,
    icon: BoxCancelIconPath,
    labelTranslationKey: NavigationLabelsKeys.PRODUCT_QUALITY_REPORT,
    taskType: TaskTypesRoutes.PRODUCT_QUALITY,
    testId: "productQualityReportNavigationButton",
  },
  {
    id: "product-issue",
    orderIndex: 2,
    icon: ChairIconPath,
    labelTranslationKey: NavigationLabelsKeys.PRODUCT_ISSUE,
    taskType: TaskTypesRoutes.PRODUCT_ISSUE,
    testId: "productIssueNavigationButton",
  },
  {
    id: "customer-task",
    orderIndex: 1,
    icon: PencilIconPath,
    labelTranslationKey: NavigationLabelsKeys.CUSTOM_TASK,
    taskType: TaskTypesRoutes.CUSTOM,
    testId: "customNavigationButton",
  },
];
