import React, { FormEvent, useContext, useState } from "react";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { FixaFormField } from "../../../shared/wrappers/FixaFormField/FixaFormField";
import styles from "./team-selection.module.css";
import { Trans } from "@coworker/locales";
import FullScreenPopup from "../../../layout/components/FullScreenPopup/FullScreenPopup";
import { TeamSelectionHeader } from "./Header/TeamSelectionHeader";
import { TeamSelectionMessage } from "./Message/TeamSelectionMessage";
import InformationCircleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/InformationCircleIconPath";
import { TeamsModal } from "./TeamsModal/TeamsModal";
import ChevronDownIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronDownIconPath";
import { useTeamsQuery } from "../../../shared/hooks/useTeamsQuery";
import { Team, TeamStatus } from "../../../shared/types/team";
import { updateUser } from "@coworker/app/src/services/user.service";
import {
  setLoggedInUserTeamId,
  useFixaUID,
  useTeamId,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { useTranslation } from "react-i18next";
import { SuperUsersListModal } from "../../../shared/components/SuperUsersListModal/SuperUsersListModal";
import { SimpleLoadingBall } from "../../../shared/simple/SimpleLoading/SimpleLoading";
import { FixaInputField } from "../../../shared/wrappers/FixaInputField/FixaInputField";
import trackerHelper from "@coworker/app/src/helpers/tracker";

export const TeamSelection: React.FC = () => {
  const { t } = useTranslation();
  const { replace, push } = useWorkspacesAction();
  const { data: teams, isLoading } = useTeamsQuery();
  const currentTeamId = useTeamId();
  if (currentTeamId) {
    replace("/home");
  }
  const activeTeamsInStore =
    teams?.filter((team) => team.status === TeamStatus.ACTIVE) ??
    ([] as Team[]);

  const defaultTeamOption = {
    id: "defaultOption",
    name: "Select team",
    is_product_quality: false,
    status: TeamStatus.ACTIVE,
  } as Team;

  const { toggleModal } = useContext(ModalContext);

  const [selectedTeam, setSelectedTeam] = useState<Team>(defaultTeamOption);
  const [shouldValidate, setShouldValidate] = useState(false);

  const hasTeams = activeTeamsInStore?.length > 0;
  const fixaUid = useFixaUID();
  const handleContinueClick = () => {
    setShouldValidate(selectedTeam.id === defaultTeamOption.id);
    if (selectedTeam.id !== defaultTeamOption.id) {
      updateUser(fixaUid, { team_id: selectedTeam?.id });
      trackerHelper.trackStoreSwitcher("open_app");
      setLoggedInUserTeamId(selectedTeam?.id);
      push("/home");
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleTeamSelection = (team: Team) => {
    setSelectedTeam(team);
    toggleModal({ type: ModalType.TeamSelection });
    setShouldValidate(false);
  };

  return (
    <FullScreenPopup
      actionBarContent={
        !isLoading && (
          <FixaButton
            aria-label={t("submitSelectedTeamString")}
            className={styles["action-bar-continue-button"] || ""}
            disabled={!hasTeams}
            text={<Trans>continue2String</Trans>}
            type="primary"
            onClick={() => handleContinueClick()}
          />
        )
      }
      noPadding
      noAppBar
    >
      {isLoading && (
        <SimpleLoadingBall text={t("loadingEllipsisString")} size={"medium"} />
      )}
      {!isLoading && (
        <React.Fragment>
          <TeamSelectionHeader></TeamSelectionHeader>
          <div className={styles["form-container"]}>
            <form className={styles["form-body"]} onSubmit={handleSubmit}>
              <FixaFormField
                id={styles["team-form-field"]}
                className={styles["team-form-field"] || ""}
                fieldHelper={{
                  id: "helper-msg-change-team-later",
                  msg: t("changeTeamsLaterString"),
                  type: "",
                }}
                valid={false}
                validation={{
                  id: "error-msg-id",
                  msg: t("selectTeamString"),
                  type: "error",
                }}
                disabled={!hasTeams}
                shouldValidate={shouldValidate}
              >
                <FixaInputField
                  id="team-input-field"
                  aria-label={selectedTeam.name}
                  readOnly
                  type="text"
                  value={selectedTeam.name}
                  onClick={() => {
                    toggleModal({ type: ModalType.TeamSelection });
                  }}
                  disabled={!hasTeams}
                  ssrIcon={ChevronDownIconPath}
                  iconPosition="trailing"
                  label={t("joinTeamString")}
                  iconOnClick={() => {
                    toggleModal({ type: ModalType.TeamSelection });
                  }}
                />
              </FixaFormField>
              <TeamsModal
                handleTeamSelection={handleTeamSelection}
                activeTeams={activeTeamsInStore}
                selectedTeamId={selectedTeam?.id}
              />
              <FixaButton
                aria-label={t("submitSelectedTeamString")}
                className={styles["continue-button"] || ""}
                disabled={!hasTeams}
                text={<Trans>continue2String</Trans>}
                type="primary"
                fluid
                onClick={() => handleContinueClick()}
              />

              {!hasTeams && (
                <div className={styles["no-teams-container"]}>
                  <TeamSelectionMessage
                    variant={"cautionary"}
                    title={t("noTeamsString")}
                    message={t("noTeamsMessageString")}
                    icon={InformationCircleIconPath}
                    showAction={false}
                    actionText={t("listOfSuperUsersString")}
                  />
                </div>
              )}

              <div className={styles["super-users-container"]}>
                <TeamSelectionMessage
                  variant={"informative"}
                  title={t("superUsersString")}
                  message={t("contactStoreSuperUserString")}
                  icon={InformationCircleIconPath}
                  showAction={true}
                  actionText={t("listOfSuperUsersString")}
                />
              </div>
              <SuperUsersListModal />
            </form>
          </div>
        </React.Fragment>
      )}
    </FullScreenPopup>
  );
};
