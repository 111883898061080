import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteArticleByIds } from "../services/articles.service";
import { updateMainAndAlternativeProducts } from "./useDeleteArticles.helper";
import { QueryKeys } from "./queryKeys";

export const useDeleteArticlesByIds = (mediaId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (articleIds: string[]) => {
      await updateMainAndAlternativeProducts(mediaId, articleIds);
      while (articleIds.length > 0) {
        await deleteArticleByIds(articleIds.splice(0, 50));
      }
    },
    onSuccess: async () =>
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Media],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ArticlesByMedia],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ArticlesByGroup],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ArticlesByMediaAndProductArticleId],
        }),
      ]),
  });
};
