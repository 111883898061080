import { useContext } from "react";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { useTranslation } from "@coworker/locales";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import styles from "../../../login/components/TeamSelection/TeamsModal/teams-modal.module.css";
import { SuperUsersTitle } from "./components/SuperUsersTitle/SuperUsersTitle";
import { UsersListView } from "./components/UsersListView/UsersListView";
import {
  useCountrySuperUsers,
  useStoreOwners,
} from "../../hooks/users/useStoreSuperUsers";

export const SuperUsersListModal = () => {
  const { modalState, toggleModal } = useContext(ModalContext);
  const currentUserId = useFixaUID();
  const storeOwners = useStoreOwners();
  const countryOwners = useCountrySuperUsers();
  const { t } = useTranslation();
  const storeSuperUsers = storeOwners?.filter(
    ({ fixaUid }) => fixaUid !== currentUserId
  );
  const countrySuperUsers = countryOwners?.filter(
    ({ fixaUid }) => fixaUid !== currentUserId
  );
  return (
    <FixaModal
      escapable={true}
      visible={modalState.isSuperUsersListOpen}
      handleCloseBtn={() => {
        toggleModal({ type: ModalType.SuperUsersList });
      }}
    >
      <FixaSheets
        fullSize={false}
        labelledById={t("joinTeamString")}
        size="small"
        className={styles["teams-sheets"] as string}
        header={
          <FixaModalHeader
            className={styles["teams-header-title"] as string}
            title={`Fixa ${t("superUsersString")}`}
          ></FixaModalHeader>
        }
        footer={null}
      >
        <SuperUsersTitle
          title={t("storeSuperusersString")}
          subTitle={t("storeSuperUsersDescription")}
        />
        <UsersListView users={storeSuperUsers} id={"store_users"} />
        <SuperUsersTitle
          title={t("countrySuperusersString")}
          subTitle={t("countrySuperUsersDescription")}
        />
        <UsersListView users={countrySuperUsers} id={"country_users"} />
      </FixaSheets>
    </FixaModal>
  );
};
