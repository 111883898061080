import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import ChevronRightSmallIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronRightSmallIconPath";

import styles from "./Linkbar.module.css";

function Dots({
  onClick,
  "data-testid": dataTestId,
}: {
  onClick: () => void;
  "data-testid"?: string | undefined;
}) {
  return (
    <button
      data-testid={dataTestId}
      className={`${styles["clickable"]} ${styles["breadcrumb"]}`}
      onClick={onClick}
    >
      ...
      <FixaSSRIcon paths={ChevronRightSmallIconPath} />
    </button>
  );
}

export default Dots;
