import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import useFormatter from "../hooks/useFormatter";
import { useAvailableElevatedStock } from "../hooks/useAvailableElevatedStock";
import { DeliveryContainer } from "@coworker/components";
import TaskCreationRow from "@coworker/components/src/components/TaskCreationRow";
import { TwoShortLinesSkeleton } from "@coworker/reusable/Loader";
import { textForStockItemsAmount, StockIndicator } from "../features/stock";
import { parseProduct } from "../services/products.service";
import { useExpectedDelivery } from "../hooks/useExpectedDelivery";
import { useItemEdsDateFormatted } from "@coworker/apprestructured/src/shared/hooks/item/useItemEdsDateFormatted";

const InformationRow = styled('div')`
  display: flex;
  & *:first-child {
    flex-grow: 1;
  }
`;

const DetailText = styled('div')`
  color: var(--black);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
`;

const GrayText = styled('div')`
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: var(--grey700);
`;

function DisplayStockDetails({
  color,
  formattedDelivery,
  formattedStockAndUnit,
  formattedElevatedAndUnit,
  deliveryAmountAndUnit,
  stockStatusText,
  latestSalesDate,
}) {
  const hasElevatedInfo =
    formattedStockAndUnit !== "" && !!formattedElevatedAndUnit;
  return (
    <TaskCreationRow
      minheight={"30px"}
      left={
        <StockIndicator color={color} marginLeft={6} marginTop={6} size={8} />
      }
    >
      <InformationRow>
        <GrayText>{stockStatusText}</GrayText>
        <DetailText>{formattedStockAndUnit}</DetailText>
      </InformationRow>
      <InformationRow>
        {!!formattedElevatedAndUnit && (
          <GrayText>
            <Trans>elevatedStockString</Trans>
          </GrayText>
        )}
        {hasElevatedInfo && <DetailText>{formattedElevatedAndUnit}</DetailText>}
      </InformationRow>
      <InformationRow>
        {!!latestSalesDate && (
          <>
            <GrayText>
              <Trans>outgoingProductString</Trans>
            </GrayText>
            <DetailText>{latestSalesDate}</DetailText>
          </>
        )}
      </InformationRow>
      <InformationRow>
        {!!formattedDelivery && (
          <>
            <DeliveryContainer padding={0}>
              <Trans>expectedDelivery</Trans>
            </DeliveryContainer>
            <DetailText>{formattedDelivery}</DetailText>
            {!!deliveryAmountAndUnit && (
              <DetailText>{deliveryAmountAndUnit}</DetailText>
            )}
          </>
        )}
      </InformationRow>
    </TaskCreationRow>
  );
}

export function StockDetails({ full_id, unitCode }) {
  const { available, elevated, loading, color } =
    useAvailableElevatedStock(full_id);
  const [short_id] = parseProduct(full_id);
  const { formattedDelivery, nextDelivery } = useExpectedDelivery(short_id);
  const { edsDate } = useItemEdsDateFormatted(short_id);
  const { formatNumber, productUnit } = useFormatter();

  function formatQuantity(quantity) {
    if (!quantity && quantity !== 0) return "";
    return `${formatNumber(quantity)} ${
      quantity >= 0 ? productUnit(unitCode) : ""
    }`;
  }

  return loading ? (
    <TwoShortLinesSkeleton />
  ) : (
    <DisplayStockDetails
      color={color}
      formattedDelivery={formattedDelivery}
      latestSalesDate={edsDate}
      formattedStockAndUnit={formatQuantity(available)}
      formattedElevatedAndUnit={formatQuantity(elevated)}
      deliveryAmountAndUnit={formatQuantity(nextDelivery?.quantity)}
      stockStatusText={textForStockItemsAmount(available)}
    />
  );
}
