import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useQueries, useQuery } from "@tanstack/react-query";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";
import { imageSize } from "../enums/imageSizes";
import { ImageDetails } from "../types/ImageDetailsType";

export async function fetchSignedUrl(
  filename: string,
  size: imageSize = imageSize.LARGE
) {
  const path = `storage/get_signed_urls?size=${size}&filenames=${filename}`;
  const url = `${TASKS_SERVICE_URL}/v1/${path}`;
  const { result } = await fetchAPI("GET", url);
  return result?.urls as string[];
}

export function useGetImageSignedUrl(filename: string, size: imageSize) {
  return useQuery({
    queryKey: ["image", "url", filename, size],
    queryFn: async () => await fetchSignedUrl(filename, size),
    staleTime: INTERVAL.DAY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!filename && !!size,
  });
}

export function useGetImagesSignedUrls(
  taskImagesDetails: ImageDetails[] | undefined
) {
  return useQueries({
    queries:
      taskImagesDetails?.map((taskImage) => {
        return {
          queryKey: ["image", "url", taskImage.file_name, imageSize.LARGE],
          queryFn: () => fetchSignedUrl(taskImage.file_name),
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          enabled: !!taskImage.file_name,
          staleTime: INTERVAL.DAY,
        };
      }) ?? [],
  });
}
