import { Filter, InputType, FilterValue } from "../../types";
import { useTranslation } from "@coworker/locales";
import FixaButton from "../../../wrappers/FixaButton/FixaButton";
import FixaFiltersIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FiltersIconPath";
import { useChangedFiltersCount } from "../../utils";
import { FilterRowScrollPanel } from "../FilterRowScrollPanel";
import { InputSelectPillMobile } from "./InputSelectPillMobile";
import { InputMultiSelectPillMobile } from "./InputMultiSelectPillMobile";
import { PillCheckbox } from "../PillCheckbox/PillCheckbox";
import { PillRadioButton } from "../PillRadioButton/PillRadioButton";

interface FilterRowMobileProps {
  filters: Filter[];
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  onTriggerFilter: (filterId: string) => void;
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const FilterRowMobile = ({
  filters,
  filterValues,
  defaultValues,
  onTriggerFilter,
  setFilterValues,
}: FilterRowMobileProps) => {
  const { t } = useTranslation();
  const changedFilterCount = useChangedFiltersCount(filters, filterValues);

  return (
    <FilterRowScrollPanel>
      <FixaButton
        type="primary"
        ssrIcon={FixaFiltersIconPath}
        text={t("filterString2", { count: changedFilterCount })}
        size="small"
        onClick={() => {
          onTriggerFilter("");
        }}
      />
      {filters
        .filter(({ enabled }) => enabled)
        .map((filter) => {
          switch (filter.inputType) {
            case InputType.Select: {
              return (
                <InputSelectPillMobile
                  key={filter.id}
                  filter={filter}
                  filterValues={filterValues}
                  defaultValues={defaultValues}
                  onTriggerFilter={onTriggerFilter}
                />
              );
            }
            case InputType.MultiSelect: {
              return (
                <InputMultiSelectPillMobile
                  key={filter.id}
                  filter={filter}
                  filterValues={filterValues}
                  defaultValues={defaultValues}
                  onTriggerFilter={onTriggerFilter}
                />
              );
            }
            case InputType.Checkbox: {
              return (
                <PillCheckbox
                  filter={filter}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                />
              );
            }
            case InputType.RadioButton: {
              return (
                <PillRadioButton
                  filter={filter}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                />
              );
            }
            default:
              return null;
          }
        })}
    </FilterRowScrollPanel>
  );
};
