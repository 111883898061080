import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMediaImage } from "../services/images.service";
import { QueryKeys } from "./queryKeys";

export const useDeleteImages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      imageIds,
      mediaId,
    }: {
      imageIds: string[];
      mediaId: string;
    }) => {
      return Promise.all(
        imageIds.map((imageId) => deleteMediaImage(imageId, mediaId))
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ImagesByMedia],
      });
    },
  });
};
