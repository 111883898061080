import {ReactNode} from "react";
import ListBox, { MenuItem } from "@ingka/list-box";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/list-box/dist/style.css";

interface ListBoxProps {
  children: ReactNode | ReactNode[];
  className?: string;
  multiple?: boolean;
  open: boolean;
  position?: "left" | "right";
  value?: string[];
}
interface MenuItemProps {
  key: string;
  onClick: () => void;
  id: string;
  title: string | ReactNode;
}

export const FixaListBox = (props: ListBoxProps) => {
  return <ListBox {...props} />;
};

export const FixaMenuItem = (props: MenuItemProps) => {
  return <MenuItem {...props} />;
};
