import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";
import { ImageDetails } from "../types/ImageDetailsType";

async function fetchTaskImagesDetails(taskId: string) {
  const url = `${TASKS_SERVICE_URL}/v1/task/${taskId}/images`;
  const { result } = await fetchAPI("GET", url);
  return result as ImageDetails[];
}

export function useTaskImagesDetails(taskId: string) {
  return useQuery<ImageDetails[]>({
    queryKey: ["task", "images", taskId],
    queryFn: async () => await fetchTaskImagesDetails(taskId),
    staleTime: INTERVAL.HOUR,
    refetchOnWindowFocus: false,
    enabled: !!taskId,
  });
}
