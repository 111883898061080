// Used for firebase only (and temporary for showing the loading spinner?? maybe not needed there)
export function getCleanFileNameWithTimestamp(originalFileName: string) {
  const label = originalFileName
    .replace(/[^a-z0-9.\s-]/gi, "")
    .trim()
    .replace(/\s+/g, "_");
  const timestamp = new Date().getTime();
  return [`${timestamp}_${label}`, timestamp];
}




