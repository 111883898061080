import React from "react";
import styled from "styled-components";
import { ConfirmationPrompt } from "@coworker/components";
import { motion, AnimatePresence } from 'framer-motion';
import { revealFromBelow } from "../../helpers/animationHelper";
import { isRDTDevice } from "@coworker/reusable";

const Backdrop = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--black);
  opacity: 0.7;
  z-index: 102;
`;

const PosedBackdrop = motion.create(Backdrop);

const PosedConfirmation = revealFromBelow(ConfirmationPrompt);

function Confirmation({
  onSubmit,
  onClose,
  onCancel,
  question,
  description,
  negativeText,
  positiveText,
  swapButtons,
}) {

  const [mounted, setMounted] = React.useState(true);
 // const callbackRef = React.useRef(null);
  const withUnmountDelay = React.useCallback((fn) => {
    if (fn) fn(); // Call the function immediately
    setMounted(false);
  }, []);
  
  const isRDT = isRDTDevice();

  return (
    <AnimatePresence
      animateOnMount={!isRDT}
      // removing since not sure if this is needed, test and evaluate later
     // onRest={() => callbackRef.current && callbackRef.current()}
    >
      {mounted && (
        <PosedBackdrop
          data-testid="backdrop"
          key="backdrop"
          onClick={() => withUnmountDelay(onClose)}
          animateOnMount={!isRDT}
        />
      )}
      {mounted && (
        <PosedConfirmation
          animateOnMount={!isRDT}
          key="confirmation"
          onConfirm={(e) => {
            e.stopPropagation();
            withUnmountDelay(() => onSubmit(true));
          }}
          onDeny={(e) => {
            e.stopPropagation();
            withUnmountDelay(() => onCancel(false));
          }}
          question={question}
          description={description}
          negativeText={negativeText}
          positiveText={positiveText}
          swapButtons={swapButtons}
        />
      )}
    </AnimatePresence>
  );
}

export default Confirmation;
