import { useMemo } from "react";
import { useEdsDatesSelectFilter } from "@coworker/apprestructured/src/shared/filters/filters";
import {
  ApplyFilter,
  FilterValue,
  SelectFilter,
  StringFilterValue,
} from "@coworker/apprestructured/src/shared/filters/types";
import { Article, Combined } from "../types/article";
import { ALERT_ID } from "@coworker/apprestructured/src/shared/filters/filters/useAlertSelectFilter";

function useEdsDateOptions(edsDates: string[]) {
  return useMemo(
    () =>
      edsDates
        .sort()
        .reverse()
        .map((edsDate) => {
          const title = edsDate.split("T")[0] ?? edsDate;
          return { value: edsDate, title, dependsOnFilterId: ALERT_ID };
        }),
    [edsDates]
  );
}

function useEdsDatesSelectArticleFilter(
  filterValues: FilterValue[],
  edsDates: string[]
) {
  const edsDateSelectOptions = useEdsDateOptions(edsDates);
  const alertsValue = filterValues.find(
    ({ filterId }) => filterId === ALERT_ID
  );

  // Check if alerts filter has any of eds selections and adjust eds date
  // options accordingly.
  const enabled =
    !!alertsValue &&
    ["eds", "pastEds"].includes((alertsValue as StringFilterValue).value);
  const options = !enabled
    ? []
    : alertsValue.value === "pastEds"
    ? edsDateSelectOptions.filter(
        ({ value }) => new Date(value).getTime() < new Date().getTime()
      )
    : edsDateSelectOptions.filter(
        ({ value }) => new Date(value).getTime() >= new Date().getTime()
      );
  const filter = useEdsDatesSelectFilter(enabled, options);

  const applyFilter: ApplyFilter<SelectFilter, Article> = {
    filter,
    filterCompare: (item: Combined<Article>, { value }: FilterValue) => {
      if (filter.enabled && value) {
        if (!item.eds) {
          return false;
        }
        return value === item.eds.validToDateTime;
      } else {
        return true;
      }
    },
  };

  return applyFilter;
}

export { useEdsDatesSelectArticleFilter };
