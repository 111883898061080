import React ,{useState,useEffect} from "react";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { sfmHomeLocation } from "./foodMarket.service";
import { parseProduct } from "./products.service";
import { callInternalApi } from "../hooks/API/useCallInternal";
import { makePartialMatcher } from "@coworker/utils/location.helper";
import { INTERVAL } from "../constants/clientTime";
import useFlag, { FLAGS } from "../hooks/useFlag";
import { useQuery } from "@tanstack/react-query";
import { useRecentCustomLocationsForItem } from "@coworker/apprestructured/src/tasks/hooks/useRecentCustomLocationsForItem";
import { useRecentCustomLocationsBySearch } from "@coworker/apprestructured/src/tasks/hooks/useRecentCustomLocationsBySearch";

/**
 * @param {String} code Location code
 * @param {String} name Location name
 * @returns {String} Joined string with <Location code> - <location name>
 */
export const joinLocation = (code, name) => `${code} - ${name || ""}`;

/**
 * @param {string|undefined} location Location code and name formatted with " - " in between.
 * @returns {[string, string]} The location code and the location name
 */
export function splitLocation(location) {
  const matches = (location || "").match(/(.+?) - (.+)/);
  if (!matches) return [location || "", ""];
  return matches.slice(1, 3).map((a) => (a || "").trim());
}

const queryFilter = (query, locations) => {
  const partialMatch = makePartialMatcher(query);
  return locations.filter(partialMatch);
};

async function fetchAllSalesLocations(fullId, storeId) {
  const path = `locations/defined?full_id=${fullId}`;
  const result = await callInternalApi(path, {
    store_id: storeId,
  });
  return result?.data?.list || {};
}

export function useAllSalesLocationsForArticleQuery(fullId) {
  const storeId = useStoreId();
  return useQuery({
    enabled: !!fullId && !!storeId,
    queryKey: fullId && storeId ? ["allSalesLocations", fullId, storeId] : null,
    queryFn: async () => {
      if (!fullId || !storeId) {
        throw new Error("Invalid fullId or storeId");
      }
      return await fetchAllSalesLocations(fullId, storeId);
    },
    staleTime: 1 * INTERVAL.HOUR,
    initialData: !fullId || !storeId ? [] : undefined,
  });
}

export function useRecentCustomLocations(
  query,
  short_id,
  taskType,
  onlyPickups
) {
  const {
    data: itemCustomLocations,
    isLoading: isItemCustomLocationsLoading,
  } = useRecentCustomLocationsForItem(short_id, taskType);

  const {
    data: searchCustomLocations,
    isLoading: isSearchCustomLocationsLoading,
  } = useRecentCustomLocationsBySearch(query, taskType);

  return React.useMemo(() => {
    const key = onlyPickups ? "pickups" : "refills";
    if (isItemCustomLocationsLoading || isSearchCustomLocationsLoading)
      return { loading: true, customLocations: [] };
    const fromQuery = searchCustomLocations?.[key] || [];
    const fromShortId = itemCustomLocations?.[key] || [];
    const set = new Set([...fromQuery, ...fromShortId]);
    return { loading: false, customLocations: queryFilter(query, [...set]) };
  }, [
    itemCustomLocations,
    isItemCustomLocationsLoading,
    searchCustomLocations,
    isSearchCustomLocationsLoading,
    query,
    onlyPickups,
  ]);
}

/**
 * Returns sales AND SGF locations for a single product, including subproducts when `type` == SPR.
 * Uses MFS, and sometimes not all locations are returned.
 * @param {String} short_id
 * @param {String} type
 */
export function useMFSLocations(short_id, type) {
  const storeId = useStoreId();
  const full_id = `${type}${short_id}`;
  const [groupedLocationsFromService, setGroupedLocationsFromService] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    if (full_id && storeId) {
      const fetchGroupedLocations = async () => {
        try {
          const response = await callInternalApi(`grouped_locations?full_id=${full_id}`, {
            store_id: storeId,
          });
          setGroupedLocationsFromService({ data: [response], loading: false });
        } catch (error) {
          console.error('Error fetching grouped locations:', error);
          setGroupedLocationsFromService({ data: [], loading: false });
        }
      };

      fetchGroupedLocations();
    } else {
      setGroupedLocationsFromService({ data: [], loading: false });
    }
  }, [full_id, storeId]);

  return groupedLocationsFromService;
}

export function useLocationCapacity(gridcode, full_id) {
  const [short_id, type] = parseProduct(full_id);
  const { data: locationsData } = useAllSalesLocationsForArticleQuery(
    !!type && !!short_id ? `${type}${short_id}` : ""
  );
  const found = locationsData?.find((l) => gridcode === l.location);
  return found?.quantity || "";
}

const foodHFBs = ["60", "61"];
const isSFM = (panumber) => foodHFBs.includes((panumber || "").slice(0, 2));

function makePickupFields(storeLocation, locationDescription, custom) {
  return {
    pickup_location: custom
      ? ""
      : joinLocation(storeLocation, locationDescription),
    pickup_location_grid_code: storeLocation,
    pickup_location_department: locationDescription,
    pickup_location_custom: custom || "",
  };
}

export function useTaskFormDefaultPickupFields(task) {
  const short_id = task?.product_article_id;
  const type = task?.product_article_type;
  const panumber = task?.panumber;

  const { data: locationsData, isLoading } =
    useAllSalesLocationsForArticleQuery(
      !!short_id && !!type ? `${type}${short_id}` : ""
    );
  const pickupFieldsExperiment = useFlag(FLAGS.BETTER_PICKUP);

  return React.useMemo(() => {

    if (!short_id) return null;

    if (!isLoading && locationsData) {
      if (isSFM(panumber)) {
        return makePickupFields("", "", sfmHomeLocation);
      }

      let homeLoc = null;
      let primaryLoc = null;
      if (locationsData) {
        for (const loc of locationsData) {
          if (loc?.isHome || loc?.isHomeBase) homeLoc = loc;
          if (loc?.isPrimary) primaryLoc = loc;
        }
      }
      const found = (pickupFieldsExperiment && primaryLoc) || homeLoc;
      if (!found) return null;
      return makePickupFields(
        found.storeLocation || found.location,
        found.locationDescription || found.descriptive
      );
    }
  }, [
    locationsData,
    panumber,
    short_id,
    pickupFieldsExperiment,
    isLoading,
  ]);
}

export function useNonSLMFoodLocation(isFoodArticle, hasNoSLMLocations) {
  const sfmLocationList = [];
  if (isFoodArticle && hasNoSLMLocations) {
    const frontendFriendlyObject = {
      location: "SFM",
      locationDescription: "Swedish Food Market",
      department: "",
      isPrimary: true,
      isHomeBase: true,
    };
    sfmLocationList.push(frontendFriendlyObject);
  }
  return { sfmLocationList };
}
export function useProductQualityLocations(isProductQualityTask) {
  const productQualityLocationsList = [];
  if (isProductQualityTask) {
    const productQualityAreaObject = {
      code: "Quality Area/Cage",
      locationDescription: "",
      department: "",
      isPrimary: false,
      isHomeBase: false,
      section: "Quality Area/Cage",
    };
    const productQualityCageObject = {
      code: "Recovery",
      locationDescription: "",
      department: "",
      isPrimary: false,
      isHomeBase: false,
      section: "Recovery",
    };
    productQualityLocationsList.push(
      productQualityAreaObject,
      productQualityCageObject
    );
  }
  return { productQualityLocationsList };
}

export async function getAllLocationsFromSLM(bu) {
  if (bu) {
    const response = await callInternalApi(`locations/store?storeId=${bu}`, {
      store_id: bu,
    });
    return response?.data;
  }
}
