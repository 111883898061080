import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useQuery } from "@tanstack/react-query";
import { TaskType } from "../enums/taskTypes";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";

async function fetchTaskByIdAndType(taskId: string, type: TaskType) {
  const url = `${TASKS_SERVICE_URL}/v1/task/by-id-type?taskId=${taskId}&type=${type}`;
  const { result } = await fetchAPI("GET", url);
  return result;
}

export function useGetTaskByIdAndType(taskId: string, type: TaskType) {
  return useQuery({
    queryKey: ["task", "id", taskId, type],
    queryFn: async () => await fetchTaskByIdAndType(taskId, type),
    staleTime: INTERVAL.MINUTE,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!taskId && !!type,
  });
}
