import { StoreTeam } from "@coworker/types/lib/storeteam";

export type FilterOptionType = {
  id: string;
  transKey?: string;
};
export interface GroupedFilterOptionsType {
  taskType: FilterOptionType[];
  assignedTo: (FilterOptionType | StoreTeam)[];
  createdBy: (FilterOptionType | StoreTeam)[];
  sortedBy: FilterOptionType[];
  locations: FilterOptionType[];
  period: FilterOptionType[];
}

export const getGrouppedFilterOptions = (
  options?: FilterOptionType[]
): GroupedFilterOptionsType => {
  return {
    taskType: [
      {
        id: "pickAndRefill",
        transKey: "addonTitleString",
      },
      {
        id: "refill",
        transKey: "refillString",
      },
      {
        id: "productIssue",
        transKey: "taskTypeTitleProductIssue",
      },
      {
        id: "PQR",
        transKey: "productQualityReportString",
      },
      {
        id: "roomSettings",
        transKey: "mediaMaintenanceString",
      },
      {
        id: "testbuy",
        transKey: "testBuyFollowUpString",
      },
      {
        id: "mfaq",
        transKey: "mfaqFollowUpTitle",
      },
      {
        id: "custom",
        transKey: "customTasksString",
      },
      {
        id: "main",
        transKey: "mainScheduledTasksString",
      },
      {
        id: "planned",
        transKey: "scheduledTasksString",
      },
    ],
    assignedTo: [{ id: "me", transKey: "meString" }, ...(options ?? [])],
    createdBy: [{ id: "me", transKey: "meString" }, ...(options ?? [])],
    sortedBy: [
      {
        id: "createdAtDesc",
        transKey: "newestFirstString",
      },
      {
        id: "createdAtAsc",
        transKey: "oldestFirstString",
      },
    ],
    locations: options ?? [],
    period: [
      {
        id: "today",
        transKey: "todayString",
      },
      {
        id: "last7days",
        transKey: "last7DaysString",
      },
      {
        id: "last4weeks",
        transKey: "last4WeeksString",
      },
    ],
  };
};
