import React from "react";
import { WideButton } from "@coworker/components";
import styled from "styled-components";
import Question from "./Question";
import { useTranslation } from "@coworker/locales";
import taskState from "@coworker/enums/taskState";

const ButtonContainer = styled('div')`
  width: 15rem;
  margin: auto;
`;

const QuestionsContainer = styled('div')`
  width: 100%;
`;

function Questions({
  questions,
  updateFrom,
  shouldShowLoadMore = false,
  canUpvote,
  showUpvote,
  selectedArea,
  dataTestId = "questionsContainer",
}) {
  const { t } = useTranslation();
  if (!questions?.length) return null;
  return (
    <QuestionsContainer data-testid={dataTestId}>
      {Array.isArray(questions) &&
        questions.map((question) => (
          <Question
            key={question?.question_id}
            question_id={question?.question_id}
            questionText={question?.question_text}
            upvotesCount={question?.upvotes}
            lastAskedAt={question?.last_asked_at}
            canUpvote={canUpvote}
            showUpvote={showUpvote}
            selectedArea={selectedArea}
            ongoingTaskCreatedAt={getOngoingTaskCreatedAt(question?.tasks)}
          />
        ))}
      {shouldShowLoadMore && (
        <ButtonContainer>
          <WideButton
            data-testid="loadMoreQuestions"
            secondary
            dark={true}
            disabled={false}
            text={t("loadMore")}
            onClick={() => updateFrom()}
          />
        </ButtonContainer>
      )}
    </QuestionsContainer>
  );
}

function getOngoingTaskCreatedAt(tasks) {
  const hasOngoingTask =
    tasks &&
    Array.isArray(tasks) &&
    tasks.filter(
      (task) =>
        task.state !== taskState.COMPLETED && task.state !== taskState.CLOSED
    );
  return !!hasOngoingTask?.length && hasOngoingTask[0].created_at;
}

export default Questions;
