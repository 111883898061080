import { TaskTabsTypes } from "../../shared/constants/taskTabsTypes";
import { useTranslation } from "@coworker/locales";
import { TasksPreviewSection } from "../types/taskPreviewType";
import { mapTaskToTaskPreview } from "../helpers/taskPreviewHelper";
import { useItemsInfoQueries } from "../../shared/hooks/item/useItemsInfo";
import { Item } from "../../shared/types/item";
import { useActivityData } from "../../activity/hooks/useActivityData";
import React from "react";
import { ActivityFilterType } from "../../activity/types/filterOptionsTypes";
import { DEFAULT_FILTER_VALUES } from "../../activity/constants/defaultFilterValues";
import { ActivityColumns } from "../../activity/constants/activityColumns";
import {
  useFixaUID,
  useTeamId,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { FullTask } from "../../activity/types/FullTask";
import { TaskType } from "../../tasks/enums/taskTypes";

export type TaskInfo = {
  tasksList: FullTask[];
  isLoading: boolean;
  count: number;
};

const getMappedTasksForPreview = (
  itemsInfo: (Item | null | undefined)[],
  taskInfo: TaskInfo,
  limit: number,
  t: any
) => {
  return {
    count: taskInfo?.count,
    isLoading: taskInfo?.isLoading,
    mappedTasksList: taskInfo?.tasksList?.slice(0, limit).map((task: any) => {
      const item = itemsInfo?.find(
        (item) => item?.no === task.product_article_id
      );
      return mapTaskToTaskPreview(item, task, t);
    }),
  } as TasksPreviewSection;
};

export const useGetTasksForPreview = () => {
  const { t } = useTranslation();
  const limit = 3;
  const localStorageFilters = JSON.parse(
    localStorage.getItem("activityFilters") as string
  );
  const fixaUid = useFixaUID();
  const teamId = useTeamId();

  const [filters] = React.useState<ActivityFilterType>(
    localStorageFilters ?? DEFAULT_FILTER_VALUES
  );
  const myTasks =
    useActivityData(
      { ...filters, assignedTo: fixaUid },
      ActivityColumns.IN_PROGRESS
    )?.inProgressTasks ?? [];

  const openTasks =
    useActivityData({ ...filters, assignedTo: teamId }, ActivityColumns.OPEN)
      ?.openTasks ?? [];

  const myTasksCount = myTasks.data?.pages[0]?.filteredCount ?? 0;
  const openTasksCount = openTasks.data?.pages[0]?.filteredCount ?? 0;

  const topMyTasks = myTasks.data?.pages.flatMap((page) => page.list) ?? [];
  const topOpenTasks = openTasks.data?.pages.flatMap((page) => page.list) ?? [];

  const top3MyTasks = topMyTasks
    ?.filter((task) => task.task_type !== TaskType.PLANNED)
    .slice(0, 3);
  const top3OpenTasks = topOpenTasks
    ?.filter((task) => task.task_type !== TaskType.PLANNED)
    .slice(0, 3);

  const articleIdsMissingImages = top3OpenTasks
    .concat(top3MyTasks)
    ?.filter((task: any) => !task?.item?.image_small)
    .map((task: any) => task.product_article_id as string)
    .filter(Boolean);

  const itemsInfoResult = useItemsInfoQueries(articleIdsMissingImages ?? "");

  const itemsInfo = itemsInfoResult?.map((query) => query.data);

  let allTasksPreview: Record<string, TasksPreviewSection> = {};
  allTasksPreview[TaskTabsTypes.OPEN] = getMappedTasksForPreview(
    itemsInfo,
    {
      tasksList: top3OpenTasks,
      count: openTasksCount,
      isLoading: openTasks.isLoading,
    },
    limit,
    t
  );

  allTasksPreview[TaskTabsTypes.MY] = getMappedTasksForPreview(
    itemsInfo,
    {
      tasksList: top3MyTasks,
      count: myTasksCount,
      isLoading: myTasks.isLoading,
    },
    limit,
    t
  );
  return allTasksPreview;
};
