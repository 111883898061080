import {Dispatch,SetStateAction,useEffect} from "react";
import {
  ChecklistItem,
  TestBuyFollowUp,
} from "@coworker/types/lib/tasks/testbuy_followup";
import { WithDoneState } from "./taskValidation";
import { toggleChecklistItem } from "./checklistWidgetHelper";
import { useUpdateTaskMutation } from "@coworker/apprestructured/src/tasks/hooks/useUpdateTaskById";

export const useChecklist = (
  task: WithDoneState<TestBuyFollowUp>,
  checklist: ChecklistItem[],
  setChecklist: Dispatch<SetStateAction<ChecklistItem[]>>,
  taskId: string
) => {
  const updateTask = useUpdateTaskMutation();
  const toggleCriterion = (id: string) => {
    setChecklist((state: ChecklistItem[]) => {
      return state.map((stateItem: ChecklistItem) => {
        if (stateItem.key === id) {
          toggleChecklistItem(
            taskId,
            stateItem.key,
            !stateItem.isDone,
            updateTask
          );
          return { ...stateItem, isDone: !stateItem.isDone };
        } else {
          return stateItem;
        }
      });
    });
  };

  useEffect(() => {
    const allDone = checklist.every((item: ChecklistItem) => item.isDone);
    if (task.isDone !== allDone) {
      task.setIsDone(allDone);
    }
  }, [checklist, task]);
  

  return {
    checklist,
    toggleCriterion,
  };
};
