import React from "react";
import {
  FirstGrouping,
  Media,
  SecondGrouping,
  ThirdGrouping,
} from "../../types/media";
import { useTranslation } from "@coworker/locales";
import {
  FixaOption,
  FixaSelect,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import StairsIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/StairsIconPath";

interface SecondGroupingSelectProps {
  media: Media;
  onSave: (newMedia: Media) => void;
  availableGroupingOptions: string[];
}

export const SecondGroupingSelect = ({
  media,
  onSave,
  availableGroupingOptions,
}: SecondGroupingSelectProps) => {
  const { t } = useTranslation();
  return (
    <FixaSelect
      id={"secondGrouping"}
      label={t("secondGroupingString")}
      value={(media as SecondGrouping).secondGrouping ?? ""}
      ssrIcon={StairsIconPath}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const newGrouping =
          event.target.selectedIndex === 0 ? "" : event.target.value;
        const newMedia = { ...media };

        const oldGrouping = (newMedia as SecondGrouping).secondGrouping;
        (newMedia as SecondGrouping).secondGrouping = newGrouping;

        if ((newMedia as FirstGrouping).firstGrouping === newGrouping) {
          (newMedia as FirstGrouping).firstGrouping = oldGrouping;
        }
        if ((newMedia as ThirdGrouping).thirdGrouping === newGrouping) {
          (newMedia as ThirdGrouping).thirdGrouping = oldGrouping;
        }
        onSave(newMedia);
      }}
    >
      {availableGroupingOptions.map((grouping) => (
        <FixaOption {...{ value: grouping, name: grouping }} key={grouping} />
      ))}
    </FixaSelect>
  );
};
