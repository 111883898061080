export enum Reasons {
  DAILY_MAINTENANCE = "daily_maintenance",
  REPLACE_PRODUCT = "replace_product",
  REMOVE_PRODUCT = "remove_product",
  SALES_STOP = "sales_stop",
}

export enum TaskListingTypes {
  Ongoing = "ongoing",
  NotOngoing = "not_ongoing",
  All = "all",
}

export enum TaskOwner {
  Mine = "mine",
  Team = "team",
  All = "all",
}

export enum MediaFormFieldTypes {
  STYLE_GROUP = "Style group",
  LIVING_SITUATION = "Living situation",
  LONG_TERM_PRIORITY = "Long term priority",
  PRICING = "Pricing",
  HFB = "Hfb",
}

export const roomSettingsRequiredFields: string[] = [
  "livingSituation",
  "hfLtp",
  "styleGroup",
  "pricing",
  "hfb",
];
export const compactRequiredFields: string[] = ["hfb"];
export const coordinationMediaRequiredFields: string[] = [
  "styleGroup",
  "hfLtp",
  "hfb",
];

export const roomSettingsFormFields = [
  MediaFormFieldTypes.STYLE_GROUP,
  MediaFormFieldTypes.LIVING_SITUATION,
  MediaFormFieldTypes.LONG_TERM_PRIORITY,
  MediaFormFieldTypes.PRICING,
];

export const vignetteFormFields = [
  MediaFormFieldTypes.STYLE_GROUP,
  MediaFormFieldTypes.LIVING_SITUATION,
  MediaFormFieldTypes.LONG_TERM_PRIORITY,
  MediaFormFieldTypes.PRICING,
];

export const coordinationFormFields = [
  MediaFormFieldTypes.STYLE_GROUP,
  MediaFormFieldTypes.LONG_TERM_PRIORITY,
];

export enum MediaTypes {
  HOME = "HOME",
  ROOM = "ROOM",
  COMPACT = "COMPACT",
  COORDINATION_MEDIA = "COORDINATION_MEDIA",
  VIGNETTE = "VIGNETTE",
  ROOM_SETTINGS = "ROOM_SETTINGS",
  NONE = "NONE",
}
