import React from "react";
import { ArticleIdNbrArticles, Articles } from "../types/article";
import { Media } from "../types/media";
import { Groups } from "../types/groups";
import {
  AggregatedArticle,
  useAggregatedArticles,
} from "../hooks/useAggregatedArticles";
import { useTasksByMedia } from "../hooks/useTasksByMedia";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { joinProductArticleIds } from "../tools";
import { useEDSData } from "../hooks/useEDSData";
import { useSalesStopData } from "../hooks/useSalesStopData";
import { SearchBar } from "../Molecules/SearchBar";
import { useTranslation } from "@coworker/locales";
import { SelectArticles } from "../Molecules/SelectArticles";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { FilterPanelMobile } from "@coworker/apprestructured/src/shared/filters/components";
import { useMediaArticlesFilters } from "../hooks/useMediaArticlesFilters";
import { AggregatedArticleList } from "./AggregatedArticleList";

const ArticleFiltersContainer = styled("div")`
  border-bottom: 1px solid var(--grey200);
`;

const SearchBarContainer = styled("div")`
  padding: 16px;
`;

const SelectButtonContainer = styled("div")`
  padding: 16px;
  text-align: center;
  position: flex;
  top: 56px;
  background-color: white;
  z-index: 1;
`;

interface AggregatedArticlesTabProps {
  articles: Articles | undefined;
  media: Media;
  groups?: Groups | undefined;
  isSelectArticlesMode: boolean;
  getSelectedArticleIds: (
    fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[]
  ) => void;
  setSelectArticleMode: () => void;
}
export const AggregatedArticlesTab = ({
  articles,
  media,
  groups,
  isSelectArticlesMode,
  getSelectedArticleIds,
  setSelectArticleMode,
}: AggregatedArticlesTabProps) => {
  const { t } = useTranslation();

  const { taskLinks } = useTasksByMedia(media.id);
  const countryId = useCountryId();
  const articleNumbers = joinProductArticleIds(articles);
  const { data: edsData } = useEDSData(countryId, articleNumbers);
  const { data: salesStopData } = useSalesStopData(
    media.storeId,
    articleNumbers
  );
  const uniqueArticles = useAggregatedArticles(articles);

  const [selectedArticleIdsNbrArticles, setSelectedArticleIdsNbrArticles] =
    React.useState<ArticleIdNbrArticles[]>([]);
  const [articleSearchResult, setArticleSearchResult] =
    React.useState(uniqueArticles);
  const [isSearchedResult, setIsSearchedResult] = React.useState(false);

  React.useEffect(() => {
    if (!isSelectArticlesMode) setSelectedArticleIdsNbrArticles([]);
  }, [isSelectArticlesMode]);

  // New filtering. Should do nothing if not enabled.
  const articlesToUse = isSearchedResult ? articleSearchResult : uniqueArticles;
  const {
    useFiltersFactory,
    filterValues,
    setFilterValues,
    useFilteredArticleData,
  } = useMediaArticlesFilters(articlesToUse);
  const articlesToShow = useFilteredArticleData(articlesToUse);

  getSelectedArticleIds(() => {
    const sortedAndFiltered = articlesToShow;
    return selectedArticleIdsNbrArticles.filter((a) =>
      sortedAndFiltered!.some((b) => a.articleId === b.id)
    );
  });

  return (
    <>
      <ArticleFiltersContainer>
        <FilterPanelMobile
          useFiltersFactory={useFiltersFactory}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
      </ArticleFiltersContainer>

      <SearchBarContainer>
        <SearchBar
          articles={uniqueArticles ?? []}
          onSearchResults={(result: AggregatedArticle[]) => {
            setIsSearchedResult(
              !!uniqueArticles && result.length !== uniqueArticles.length
            );
            setArticleSearchResult(result);
          }}
        />
      </SearchBarContainer>
      {!!uniqueArticles && uniqueArticles.length > 0 && (
        <SelectButtonContainer>
          <FixaButton
            type={"secondary"}
            text={
              isSelectArticlesMode
                ? t("cancelString")
                : t("selectArticlesString")
            }
            onClick={setSelectArticleMode}
            fluid
          />
        </SelectButtonContainer>
      )}
      <SelectArticles
        selectedArticles={selectedArticleIdsNbrArticles}
        uniqueArticles={uniqueArticles}
        filteredArticles={articlesToShow}
        isSelected={isSelectArticlesMode}
        onSelectAll={(selectAllChecked: boolean) => {
          if (selectAllChecked) {
            setSelectedArticleIdsNbrArticles(
              uniqueArticles?.map((article) => ({
                articleId: article.id,
                nbrArticles: article.nbrArticles,
              })) ?? []
            );
          } else {
            setSelectedArticleIdsNbrArticles([]);
          }
        }}
      />
      <AggregatedArticleList
        articles={articlesToShow}
        media={media}
        groups={groups}
        isSelectArticlesMode={isSelectArticlesMode}
        taskLinks={taskLinks}
        edsData={edsData ?? []}
        salesStopData={salesStopData ?? []}
        selectedArticleIdsNbrArticles={selectedArticleIdsNbrArticles}
        setSelectedArticleIdsNbrArticles={setSelectedArticleIdsNbrArticles}
      />
    </>
  );
};
