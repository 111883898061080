import { getTokenAsync } from "../../../hooks/API/getAuthToken";
import { makeHeaders } from "../tools";
import {
  Article,
  Articles,
  ArticleWithMediaCount,
  EdsData,
  PartialArticle,
  SalesStopData,
} from "../types/article";
import { MEDIAS_SERVICE_URL } from "./service.helper";
import { Ids } from "../types/images";

const BASE_URL = MEDIAS_SERVICE_URL + "/articles";

export const getArticleById = async (articleId: string) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return;
    }
    const response = await fetch(`${BASE_URL}/${articleId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const article = (await response.json()) as Article;
    return article;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const updateArticle = async (
  articleId: string,
  article: PartialArticle
) => {
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  const url = `${BASE_URL}/${articleId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: makeHeaders(token),
    body: JSON.stringify(article),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};

export const addArticle = async (article: Partial<Article>) => {
  delete article.id;

  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(`${BASE_URL}/single`, {
    method: "POST",
    headers: makeHeaders(token),
    body: JSON.stringify(article),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data = (await response.json()) as Ids;
  return data;
};

export const addArticlesBulk = async (
  articles: Partial<Article>[],
  groupId: string
) => {
  articles.forEach((article) => {
    delete article.id;
  });

  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(
    `${BASE_URL}${!!groupId ? `?groupId=${groupId}` : ""}`,
    {
      method: "POST",
      headers: makeHeaders(token),
      body: JSON.stringify(articles),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data = (await response.json()) as Articles;
  return data;
};

export const deleteArticleById = async (articleId: string) => {
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(`${BASE_URL}/${articleId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};

export const deleteArticleByIds = async (articleIds: string[]) => {
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(`${BASE_URL}?ids=${articleIds.join(",")}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};

export const getEDSData = async (countryId: string, articleNumbers: string) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return;
    }
    const url = `${BASE_URL}/eds/${encodeURIComponent(
      countryId
    )}?articleNumbers=${articleNumbers}`;
    const response = await fetch(url, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }
    const data = (await response.json()) as EdsData[];
    return data || [];
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return null;
};

export const getSalesStopData = async (
  storeId: string,
  articleNumbers: string
) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return;
    }
    const url = `${BASE_URL}/salesstop/${encodeURIComponent(
      storeId
    )}?articleNumbers=${articleNumbers}`;
    const response = await fetch(url, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    return (await response.json()) as SalesStopData[];
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return [];
};

export const searchArticlesInMedias = async (
  storeId: string,
  searchQuery: string
) => {
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return;
    }
    const response = await fetch(
      `${BASE_URL}/search/${encodeURIComponent(
        storeId
      )}?searchQuery=${encodeURIComponent(searchQuery)}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const articles = (await response.json()) as ArticleWithMediaCount[];
    return articles;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};
