const profilePreferences = {
  LANGUAGE: "language",
  INSIGHTS_FILTERS: "insightsFilters",
  ASSIGN_TO_MY_TEAM_OPENED: "assignToMyTeamOpened",
  INSIGHTS_FILTER_WEEKSBACK: "insights.filter.weeksBack",
  INSIGHTS_FILTER_CUSTOMDATE: "insights.filter.customDate",
  INSIGHTS_FILTER_GRAPH_OPTION: "insights.filter.graphOption",
  INSIGHTS_FILTER_ORDER_BY: "insights.filter.order_by",
  INSIGHTS_FILTER_ORDER_BY_ASC: "insights.filter.order_by_asc",
  INSIGHTS_FILTER_HFB: "insights.filter.hfb",
  INSIGHTS_FILTER_SHARE: "insights.filter.share",
  INSIGHTS_HOME_VALUE: "insights.home.value",
  INSIGHTS_PRODUCT_PREVIEW_MOST: "insights.product_preview.most",
  INSIGHTS_PRODUCT_PREVIEW_LEAST: "insights.product_preview.least",
  INSIGHTS_GRAPH_TYPE: "insights.graph.type",
  INSIGHTS_HOME_SORT_VALUE: "insights.home_sort_value",
  INSIGHTS_HOME_SORT: "insights.home_sort",
  SEARCH_HISTORY: "searchHistory",
  DISABLE_POPUPS_DATE_STAMP: "disablePopups.dateStamp",
  DISABLE_POPUPS_SUPPLIER_NUMBER: "disablePopups.supplierNumber",
  DISABLE_POPUPS_RECURRING: "disablePopups.recurring",
  DISABLE_POPUPS_HANDOFF: "disablePopups.handoff",
  DISABLE_POPUPS_ERROR: "disablePopups.error",
  DISABLE_POPUPS_MARK_AS_COMPLETE: "mark_as_complete",
  DISABLE_POPUPS_PICKING: "picking",
  DISABLE_POPUPS_TESTBUY_LOST_SALES_CALC: "disablePopups.lostSalesCalc",
  DISABLE_POPUPS_MFAQ_ADD_QUESTION: "disablePopups.mfaqAddQuestion",
  DISABLE_POPUPS_MFAQ_SELECT_AREA: "disablePopups.mfaqSelectArea",
  UNSUPPORTED_BROWSER_NOTICE_CLOSED: "unsupportedBrowserNotice.closed",
  UNSUPPORTED_BROWSER_NOTICE_APPROVED: "unsupportedBrowserNotice.approved",
  PICKING_DEFAULT: "picking.default",
  NOTIFICATIONS_ANNOUNCEMENT_CLOSED: "notification.announcement.closed",
  SEEN_FEATURE_ANNOUNCEMENTS: "announcement.newFeatures.seen",
  RELEASE_ANNOUNCEMENT: "release.announcement",
  INSTRUCTIONS_ANNOUNCEMENT: "instructions.announcement",
  INSTRUCTIONS_COMPLETED: "instructions.completed",
  TASK_FILTERS: "taskFilters",
  TASK_FILTERS_ALL: "taskFiltersAll",
  TASK_SORT_FILTERS: "taskSortFilters",
  TASK_STATUS_FILTERS: "taskStatusFilters",
  CLOSED_REASON_FILTERS: "closedReasonFilters",
  USER_FILTERS: "Filters",
  USER_FILTERS_CUSTOM_PERIOD: ".filters.customPeriod",
  USER_FILTERS_GRAPH_OPTIONS: ".filters.graphOptions",
  TESTBUY_PREFIX: "testbuy",
  MFAQ_PREFIX: "mfaq",
  INSIGHTS_PREFIX: "insights",
  LOCATION_FILTERS: "locationFilters",
  LOCATION_GROUP: "locationGroup",
  ADDON_OPTION: "addonOption",
  TASK_STATE_FILTER: "stateFilter",
  NOTIFICATION_CHOICES: "notificationFilters",
  FEEDBACK_QUESTION_LIST: "feedbackQuestionList",
  SCANNER_SCALE_FACTOR: "scannerScaleFactor",
  HIDE_TABS_SHARE_OPINION: "hideTabsShareOpinion",
  USE_RDT_SCANNER: "useRDTScanner",
  TASK_GROUP_BY_FILTERS: "groupByTaskOption",
  SHOW_QUESTIONS_WITH_TASKS: ".showQuestionsWithTasks",
  HIDE_REPLACE_ARTICLES_GUIDE: "testbuy.hideReplaceArticlesGuide",
  TEAM_FILTER: "teamFilter",
  SHOW_ONLY_QUESTIONS_WITH_DATA: ".showOnlyQuestionsWithData",
  RS_ARTICLE_FILTER: "rsArticleFilter",
  RS_ARTICLE_SORT_FILTER: "rsArticleSortFilter",
  RS_ARTICLE_HFB_FILTER: "rsArticleHFBFilter",
  MEDIA_FILTERS_ARTICLES: "media.filters.articles",
  ACTIVITY_FILTERS: "activity.filters",
  MEDIA_OVERVIEW_FILTERS: "mediaOverview.filters",
};

export default profilePreferences;
