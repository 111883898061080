import React, { Reducer, createContext } from "react";
import { ModalType } from "./modalType";

export type ModalAction = {
  type: ModalType;
  payload?: any;
  onToggle?: () => any;
};

type ModalInitialState = {
  isMainNavigationOpen: boolean;
  isProfileModalOpen: boolean;
  isTaskDetailSheetOpen: boolean;
  isTeamSelectionOpen: boolean;
  isSuperUsersListOpen: boolean;
  isStoreEnvironmentOpen: boolean;
  isSwitchTeamSheetOpen: boolean;
  isSwitchStoreSheetOpen: boolean;
  isSwitchCountrySheetOpen: boolean;
  isMultiSelectTasksModalOpen: boolean;
  props: any;
};

export const modalStateKeyMap: Record<ModalType, keyof ModalInitialState> = {
  [ModalType.MainNavigation]: "isMainNavigationOpen",
  [ModalType.Profile]: "isProfileModalOpen",
  [ModalType.TaskDetail]: "isTaskDetailSheetOpen",
  [ModalType.TeamSelection]: "isTeamSelectionOpen",
  [ModalType.SuperUsersList]: "isSuperUsersListOpen",
  [ModalType.StoreEnvironment]: "isStoreEnvironmentOpen",
  [ModalType.SwitchTeam]: "isSwitchTeamSheetOpen",
  [ModalType.SwitchStore]: "isSwitchStoreSheetOpen",
  [ModalType.SwitchCountry]: "isSwitchCountrySheetOpen",
  [ModalType.MultiSelectTasks]: "isMultiSelectTasksModalOpen",
};

interface ModalContextProps {
  modalState: ModalInitialState;
  toggleModal: React.Dispatch<ModalAction>;
}

const initialModalState: ModalInitialState = {
  isMainNavigationOpen: false,
  isProfileModalOpen: false,
  isTaskDetailSheetOpen: false,
  isTeamSelectionOpen: false,
  isSuperUsersListOpen: false,
  isStoreEnvironmentOpen: false,
  isSwitchTeamSheetOpen: false,
  isSwitchStoreSheetOpen: false,
  isSwitchCountrySheetOpen: false,
  isMultiSelectTasksModalOpen: false,
  props: {},
};

const modalReducer: Reducer<ModalInitialState, ModalAction> = (
  state,
  action
) => {
  const newState = { ...state };
  switch (action.type) {
    case ModalType.MainNavigation:
      newState.isMainNavigationOpen = !state.isMainNavigationOpen;
      break;
    case ModalType.Profile:
      newState.isProfileModalOpen = !state.isProfileModalOpen;
      break;
    case ModalType.TaskDetail:
      newState.isTaskDetailSheetOpen = !state.isTaskDetailSheetOpen;
      newState.props = Object.assign({}, state.props, {
        taskDetail: action.payload,
      });
      break;
    case ModalType.TeamSelection:
      newState.isTeamSelectionOpen = !state.isTeamSelectionOpen;
      break;
    case ModalType.SuperUsersList:
      newState.isSuperUsersListOpen = !state.isSuperUsersListOpen;
      break;
    case ModalType.StoreEnvironment:
      newState.isStoreEnvironmentOpen = !state.isStoreEnvironmentOpen;
      break;
    case ModalType.SwitchTeam:
      newState.isSwitchTeamSheetOpen = !state.isSwitchTeamSheetOpen;
      break;
    case ModalType.SwitchStore:
      newState.isSwitchStoreSheetOpen = !state.isSwitchStoreSheetOpen;
      break;
    case ModalType.SwitchCountry:
      newState.isSwitchCountrySheetOpen = !state.isSwitchCountrySheetOpen;
      break;
    case ModalType.MultiSelectTasks:
      newState.isMultiSelectTasksModalOpen = !state.isMultiSelectTasksModalOpen;
      newState.props = Object.assign({}, state.props, {
        type: action.payload,
      });
      break;
    default:
      return state;
  }
  if (action.onToggle) {
    action.onToggle();
  }
  return newState;
};

export const ModalContext = createContext<ModalContextProps>({
  modalState: initialModalState,
  toggleModal: () => {},
});

const ModalContextProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [modalState, toggleModal] = React.useReducer(
    modalReducer,
    initialModalState
  );

  return (
    <ModalContext.Provider value={{ modalState, toggleModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
