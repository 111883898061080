import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteGroupById } from "../services/groups.service";
import { QueryKeys } from "./queryKeys";

export const useDeleteGroupById = () => {
  const queryClient = useQueryClient();
  const [storedMediaId, setStoredMediaId] = useState<string>("");

  return useMutation({
    mutationFn: ({
      groupId,
      mediaId,
    }: {
      groupId: string;
      mediaId: string;
    }) => {
      setStoredMediaId(mediaId);
      return deleteGroupById(groupId);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [QueryKeys.GroupsByMedia, storedMediaId],
      });
    },
  });
};
