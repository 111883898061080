import styled from "styled-components";

const Skeleton = styled('div')<{ size: number }>`
  ${({ size }) => `height: ${size}px; min-width: ${size}px;`}
  border-radius: 10px;
  background-color: var(--grey150);
`;

export interface ImageSkeletonProps {
  size?: number | null;
}

export function ImageSkeleton({ size }: ImageSkeletonProps) {
  return <Skeleton size={size ?? 155} />;
}
