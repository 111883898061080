import { FilterOptionsKeys } from "../types/filterOptionsTypes";

type AppliedPillsType = {
  transKey: string;
  id: FilterOptionsKeys;
};

export const appliedPills: AppliedPillsType[] = [
  {
    transKey: "assignedToTaskFilterString",
    id: "assignedTo",
  },
  {
    transKey: "createdByString",
    id: "createdBy",
  },
  {
    transKey: "task_typeString",
    id: "taskType",
  },
  {
    transKey: "sortedByString",
    id: "sortedBy",
  },
  {
    transKey: "location3String",
    id: "locations",
  },
  {
    transKey: "pickupLocation",
    id: "pickupLocations",
  },
  {
    transKey: "refillLocString",
    id: "refillLocations",
  },
  {
    transKey: "priorityTasksOnlyString",
    id: "priority",
  },
  {
    transKey: "periodString",
    id: "period",
  },
];

type DropdownPillsType = {
  transKey: string;
  id: Extract<
    FilterOptionsKeys,
    | "assignedTo"
    | "createdBy"
    | "taskType"
    | "sortedBy"
    | "locations"
    | "period"
  >;
};

export const dropdownPills: DropdownPillsType[] = [
  {
    transKey: "task_typeString",
    id: "taskType",
  },
  {
    transKey: "locationsString",
    id: "locations",
  },
  {
    transKey: "periodString",
    id: "period",
  },
  {
    transKey: "sortedByString",
    id: "sortedBy",
  },
];

type SelectablePillsType = {
  transKey: string;
  id: Partial<FilterOptionsKeys>;
  type: "checkbox" | "radio";
};

export const selectablePills: SelectablePillsType[] = [
  {
    transKey: "myString",
    id: "assignedToMe",
    type: "radio",
  },
  {
    transKey: "teamTasksString",
    id: "assignedToMyTeam",
    type: "radio",
  },
  {
    transKey: "priorityTasksOnlyString",
    id: "priority",
    type: "checkbox",
  },
];
