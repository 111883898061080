import {ReactNode} from "react";
import { ModalFooter } from "@ingka/modal";

interface ModalFooterProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const FixaModalFooter = (props: ModalFooterProps) => {
  return <ModalFooter {...props} />;
};
