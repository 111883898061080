import React, { useContext } from "react";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";
import HomeIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/HomeIconPath";
import CheckmarkCircleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CheckmarkCircleIconPath";
import MenuIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/MenuIconPath";
import { useTranslation } from "@coworker/locales";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import { ModalContext } from "../../context/ModalContextProvider";
import { ModalType } from "../../context/modalType";
import styles from "./bottom-navigation.module.css";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import trackerHelper from "@coworker/app/src/helpers/tracker";

export const BottomNavigation = () => {
  const { push } = useWorkspacesAction();
  const location = useFixaLocation();
  const { toggleModal } = useContext(ModalContext);

  const { t } = useTranslation();

  const navItems = [
    {
      label: t("homeString"),
      href: "/home",
      icon: HomeIconPath,
      testId: "homeBottomMenuButton",
    },
    {
      label: t("TasksString"),
      href: "/activity",
      icon: CheckmarkCircleIconPath,
      testId: "tasksBottomMenuButton",
    },
    {
      label: t("menuString"),
      onClick: () => toggleModal({ type: ModalType.MainNavigation }),
      icon: MenuIconPath,
      testId: "navigationBottomMenuButton",
    },
  ];

  return (
    <div className={styles["bottom-navigation"]}>
      {navItems?.map((navItem) => {
        const isActive =
          navItem.href &&
          (`${location.pathname}${location.search}` === navItem.href ||
            (navItem.href === "/tasks?active=my" &&
              location.search === "?active=open"));
        return (
          <div
            className={`${styles["navigation-item"]} ${
              isActive ? styles["active-item"] : ""
            }`}
            key={navItem.label}
            onClick={() => {
              if (navItem.onClick) {
                navItem.onClick();
                trackerHelper.trackButtomNavigationClick(navItem.label);
                return;
              }
              if (navItem.href) {
                push(navItem.href);
                trackerHelper.trackButtomNavigationClick(navItem.label);
              }
            }}
            data-testid={navItem.testId}
          >
            <div className={styles["top-border"]}></div>
            <FixaSSRIcon paths={navItem.icon} />
            <div className={styles["label"]}>{navItem.label}</div>
          </div>
        );
      })}
    </div>
  );
};
