import { MediasViewMobile } from "./MediasViewMobile";
import { MediasViewDesktop } from "./MediasViewDesktop";
import { DesktopOnly } from "@coworker/apprestructured/src/shared/components/DesktopOnly";
import { MobileOnly } from "@coworker/apprestructured/src/shared/components/MobileOnly";

export function MediasView() {
  return (
    <>
      <MobileOnly>
        <MediasViewMobile />
      </MobileOnly>
      <DesktopOnly>
        <MediasViewDesktop />
      </DesktopOnly>
    </>
  );
}
