import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "../constants/clientTime";
import { fetchAPI, TASKS_SERVICE_URL } from "./API/tasks.service.helper";

type Note = {
  creator_id: string;
  id: string;
  task_id: string;
  text: string;
};

const fetchTaskNotes = async (taskId: string) => {
  const path = `/v1/task/${taskId}/notes`;
  const url = `${TASKS_SERVICE_URL}${path}`;
  const { result } = await fetchAPI("GET", url);
  return result as Note[];
};

export function useTaskNotes(taskId: string) {
  return useQuery({
    queryKey: ["task", "notes", taskId],
    queryFn: async () => fetchTaskNotes(taskId),
    refetchOnWindowFocus: false,
    staleTime: INTERVAL.HOUR,
    enabled: !!taskId,
  });
}
