import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import { fontSizeMap } from "../styles/styles";

interface GroupsTextProps {
  groupsCount: number;
  bold?: boolean | undefined;
  italic?: boolean | undefined;
  grey?: boolean | undefined;
  size?: keyof typeof fontSizeMap;
}

export const GroupsText = ({
  groupsCount,
  bold,
  italic,
  grey,
  size,
}: GroupsTextProps) => {
  const { t } = useTranslation();
  return (
    <Text
      text={t("groupsCountString", { count: groupsCount })}
      bold={bold}
      italic={italic}
      grey={grey}
      size={size || "medium"}
    />
  );
};
