import React from "react";
import { ModalBody } from "@ingka/modal";

interface ModalBodyProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const FixaModalBody = (props: ModalBodyProps) => {
  return <ModalBody {...props} />;
};
