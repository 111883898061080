import { useState } from "react";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import MagnifyingGlassIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/MagnifyingGlassIconPath";
import { useToastNotification } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import { ReplacedArticleListItem } from "../Molecules/ReplacedArticleListItem";
import { LargeSkeletonListItem } from "../Molecules/skeletons/LargeSkeletonListItem";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import { useQueryClient } from "@tanstack/react-query";
import { fetchItemsInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemsInfo";

interface ReplaceProductProps {
  oldProductArticleIds: string[];
  numberBackup: number;
  setNewArticle: (newArticle: Item) => void;
  isSetAsBackupProduct?: boolean;
  alternativeBackUpProduct?: Item | undefined;
}

export const ReplaceProduct = ({
  oldProductArticleIds,
  numberBackup,
  setNewArticle,
  isSetAsBackupProduct = false,
  alternativeBackUpProduct,
}: ReplaceProductProps) => {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();
  const { showToast } = useToastNotification();

  const [loading, setLoading] = useState(false);
  const [newArticleInfo, setNewArticleInfo] = useState<Item | undefined>();
  const queryClient = useQueryClient();

  async function onReplaceArticle() {
    setLoading(true);
    const { product } = await getInput("scansearch", {
      start: new Date(),
      forbiddenArticles: oldProductArticleIds,
      onForbiddenArticle: () => {
        showToast(
          t("productAlreadyExistInRoomString"),
          undefined,
          undefined,
          5
        );
      },
    });
    if (product) {
      const articlesInfo: Item[] = await queryClient.ensureQueryData(
        {
          queryKey: ["item", "info", product],
          queryFn: () => fetchItemsInfo(product)
        }
      );

      if (articlesInfo.length > 0) {
        const articleInfo = articlesInfo[0];
        if (articleInfo) setNewArticle(articleInfo);
        setNewArticleInfo(articleInfo);
      } else {
        setNewArticleInfo(undefined);
      }
      setLoading(false);
    }
  }
  const title = isSetAsBackupProduct
    ? "setBackupArticleString"
    : "newProductString";
  return (
    <div onClick={() => onReplaceArticle()}>
      <FixaListView id="replace-product-list" size={"small"}>
        <FixaListViewItem
          title={t(title)}
          leadingIcon={MagnifyingGlassIconPath}
          control={"navigational"}
          controlIcon={"chevronRightSmall"}
          emphasised
          inset
        />
      </FixaListView>
      {loading && <LargeSkeletonListItem />}

      <ReplacedArticleListItem
        numberBackup={numberBackup}
        articleInfo={newArticleInfo ? newArticleInfo : alternativeBackUpProduct}
        isSetAsBackupProduct={isSetAsBackupProduct}
      />
    </div>
  );
};
