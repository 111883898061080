import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { FixaFormField } from "@coworker/apprestructured/src/shared/wrappers/FixaFormField/FixaFormField";
import { FixaTextArea } from "@coworker/apprestructured/src/shared/wrappers/FixaTextArea/FixaTextArea";
import { FixaText } from "@coworker/apprestructured/src/shared/wrappers/FixaText/FixaText";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";

const Container = styled('div')`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`;
const ScrollContainer = styled('div')`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
`;

const StyledListView = styled(FixaListView)`
  display: flex;
  flex-direction: column;
`;
const StyledListViewItem = styled(FixaListViewItem)`
  display: flex;
  span {
    text-decoration: none !important;
  }
`;
const StyledButton = styled(FixaButton)`
  margin: 0px 24px 8px 24px;
`;
const TextAreaContainer = styled('div')`
  margin: 16px;
`;
const RedText = styled(FixaText)`
  color: red;
`;

const SelectIssue = ({
  description: initialDescription,
  issue,
  content,
  onSubmit,
}) => {
  const [description, setDescription] = React.useState(initialDescription);
  const [selectedType, setSelectedType] = React.useState(issue);
  const { t } = useTranslation();

  return (
    <Container data-testid="selectIssuePopup">
      <ScrollContainer>
        <StyledListView id="listviewid" size="small">
          {content.map(({ name, type }) => (
            <StyledListViewItem
              id={type}
              key={type}
              inset
              onChange={() => setSelectedType(type)}
              control="radiobutton"
              controlProps={{
                value: selectedType === type ? "ON" : "OFF",
                checked: selectedType === type,
              }}
              title={name}
              data-testid={type}
            />
          ))}
        </StyledListView>
        <TextAreaContainer>
          <FixaFormField
            characterLimit={250}
            fieldHelper={{
              msg:
                selectedType === "OTHER" && !description?.length ? (
                  <RedText>{t("requiredString")}</RedText>
                ) : selectedType !== "OTHER" ? (
                  <FixaText>{t("optionalString")}</FixaText>
                ) : (
                  ""
                ),
              id: "some-helper-id",
            }}
          >
            <FixaTextArea
              data-testid="descriptionInput"
              value={description}
              characterLimit={250}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              label={<Trans>addDescriptionPlainString</Trans>}
            />
          </FixaFormField>
        </TextAreaContainer>
      </ScrollContainer>
      <StyledButton
        data-testid={"selectConfirm"}
        text={<Trans>continue2String</Trans>}
        type="primary"
        disabled={
          !selectedType ||
          (selectedType && selectedType === "OTHER" && !description?.length)
        }
        onClick={() => onSubmit({ issue: selectedType, description })}
      />
    </Container>
  );
};

export default SelectIssue;
