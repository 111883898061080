import React from "react";
import { HfLtp, Media } from "../../types/media";
import {
  FixaOption,
  FixaSelect,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import { useTranslation } from "@coworker/locales";
import FlagIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FlagIconPath";
import { getLongTermPriorityOptions } from "../../../../helpers/longTermPriorityOptions";

interface LongTermPrioritySelectProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const LongTermPrioritySelect = ({
  media,
  onSave,
}: LongTermPrioritySelectProps) => {
  const { t } = useTranslation();
  return (
    <FixaSelect
      id={"hf_ltp"}
      label={t("longtermPriorityString")}
      value={(media as HfLtp).hfLtp ?? ""}
      ssrIcon={FlagIconPath}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const newLongTermPriority =
          event.target.selectedIndex === 0 ? "" : event.target.value;
        const newMedia = { ...media };
        (newMedia as HfLtp).hfLtp = newLongTermPriority;
        onSave(newMedia);
      }}
    >
      {getLongTermPriorityOptions().map((hfltp) => (
        <FixaOption {...hfltp} key={hfltp.value} />
      ))}
    </FixaSelect>
  );
};
