import React from "react";
import styled from "styled-components";

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  ${(props) => props.minheight && `min-height: ${props.minheight};`}
  ${(props) =>
    props.topmargin && `margin-top: ${props.topmargin};`}
  cursor: pointer;
  width: 100%;
`;
const Grower = styled('div')`
  flex-grow: 1;
`;
const Left = styled('div')`
  width: 50px;
  min-width: 50px; /* needed for create Add on task flow, where the browser decides to squeeze the left side when the middle has multi-line text */
`;
const Right = styled('div')`
  text-align: right;
`;
function TaskCreationRow({
  left,
  children,
  right,
  minheight,
  topmargin,
  onClick,
  showOnlyLocation,
}) {
  return (
    <Row
      data-testid="productInfo"
      minheight={minheight}
      topmargin={topmargin}
      onClick={onClick}
    >
      {!showOnlyLocation && <Left>{left}</Left>}
      <Grower>
        <Row>
          <Grower>{children}</Grower>
          <Right>{right}</Right>
        </Row>
      </Grower>
    </Row>
  );
}

export default TaskCreationRow;
