import { getStoresByCountryId } from "../../hooks/API/core.service";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "../../constants/clientTime";
import { Store } from "@coworker/apprestructured/src/shared/types/store";

export function useGetStoresByCountry(countryId: string) {
  const result = useQuery<Store[]>({
    queryKey: ["stores", countryId],
    queryFn: async () => (await getStoresByCountryId(countryId)) ?? [],
    staleTime: 2 * INTERVAL.HOUR,
  });
  return result;
}
