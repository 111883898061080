import { Widget } from "@coworker/components/src/components/Widget";
import { useInputPopup } from "../../InputPopup";
import { getRefillTypePopup } from "../common/popup.helpers";
import { SelectRefillType } from "@coworker/components";
import { RefillTypes } from "../../../constants/refillTypes";

interface RefillTypeValue {
  refillType: RefillTypes;
}

interface RefillTypeProps {
  value: RefillTypeValue;
  onChange: () => void;
}

export default function RefillType({ value, onChange }: RefillTypeProps) {
  const { getInput } = useInputPopup();
  return (
    <Widget
      onClick={() => {
        getRefillTypePopup({
          init: false,
          getInput,
          onChange,
          refillType: value?.refillType,
        });
      }}
      data-testid="refillType"
    >
      <SelectRefillType
        selectedRefillType={value?.refillType}
      ></SelectRefillType>
    </Widget>
  );
}
