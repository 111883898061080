import React from "react";
import styled from "styled-components";
import { Input } from "@coworker/components";
import { Trans } from "@coworker/locales";
import { useSearchQuestions } from "./useSearchQuestions";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import Questions from "./Questions";
import { capitalizeFirst } from "../../hooks/useFormatter";
import { SmallX } from "../../features/searching/HeaderSearchInput";

const InputWithSpacing = styled(Input)`
  margin-bottom: 22px;
`;

const InputWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

const InformationText = styled('p')`
  font-size: 12px;
  margin-bottom: 22px;
`;

function AddQuestionInput({
  questionDataRef,
  onInputTextChange,
  areaIsWholeStore,
  selectedArea,
}) {
  const { setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();

  /**
   * @type {React.MutableRefObject<HTMLInputElement>}
   */
  const inputElementRef = React.useRef();
  const [inputText, setInputText] = React.useState(
    navigationState?.newMFAQInputText ?? ""
  );

  const [whereFrom, setWhereFrom] = React.useState(0);

  const updateInputText = (newText) => {
    newText = removeQuestionMarks(newText);
    setNavigationState({ newMFAQInputText: newText });
    setInputText(newText);
    onInputTextChange && onInputTextChange(newText);
  };

  questionDataRef.current = {
    ...questionDataRef.current,
    question_text: getTranslatedQuestionText(inputText),
    subject: inputText,
  };
  const store_id = useStoreId();

  const { similarQuestions, shouldShowLoadMore } = useSearchQuestions(
    inputText,
    setWhereFrom,
    whereFrom,
    questionDataRef,
    store_id
  );
  async function updateFrom() {
    setWhereFrom(whereFrom + similarQuestions.length);
  }

  React.useEffect(() => {
    if (!inputElementRef.current || similarQuestions.length === 0 || whereFrom !== 0) {
      return () => {}; // Return an empty function to avoid "Destroy is not a function" error
    }
  
    inputElementRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  
    return () => {}; 
  }, [similarQuestions.length, whereFrom]);
  
  

  return (
    <>
      <InputWrapper>
        <InputWithSpacing
          testId="questionTextInput"
          ref={inputElementRef}
          autoFocus={!areaIsWholeStore}
          autoComplete={"off"}
          onChange={updateInputText}
          value={inputText}
        />
        {inputText.length > 2 && (
          <SmallX
            data-testid="searchClear"
            onClick={() => updateInputText("")}
          />
        )}
      </InputWrapper>
      {}
      {areaIsWholeStore && (
        <InformationText>
          <Trans>selectAreaBeforeSubmitString</Trans>
        </InformationText>
      )}

      {inputText.length > 2 && (
        <Questions
          questions={similarQuestions}
          canUpvote={!areaIsWholeStore}
          showUpvote={!areaIsWholeStore}
          updateFrom={updateFrom}
          shouldShowLoadMore={shouldShowLoadMore}
          selectedArea={selectedArea}
          dataTestId="similarQuestionsContainer"
        />
      )}
    </>
  );
}

/**
 * Translates the question text template for sinking to the database.
 *
 * Uses a different translation key than the UI translation, so we dont have style info in the DB.
 * @param {string} text
 * @returns {string}
 */
function getTranslatedQuestionText(text = "") {
  return `${capitalizeFirst(removeQuestionMarks(text).trim())}?`;
}

/**
 * Formats question text for the UI, with styles if needed.
 * @param {string} text
 */
export function getFormattedQuestionText(text = "...") {
  return `${capitalizeFirst(removeQuestionMarks(text).trim())}?`;
}

function removeQuestionMarks(string) {
  return string.replace(/\?/g, "");
}

export default AddQuestionInput;
