import { useState } from "react";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { Groups } from "../../types/groups";
import { ModalTypes } from "../../types/views";
import { useTranslation } from "@coworker/locales";
import { FullWidthButton } from "../../../SkapaButton";
import { Articles } from "../../types/article";
import { InputPopupContainer } from "../../styles/styles";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import { addUniqueArticlesToGroup } from "./GroupModal.helper";
import { logEvent } from "../../../../helpers/tracker";
import { useToastNotification } from "@coworker/components";

interface AddToGroupModalProps {
  modalVisibility: boolean;
  groups: Groups;
  selectedArticles: Articles;
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export function AddToGroupModal({
  modalVisibility,
  groups,
  selectedArticles,
  onTriggerNewModal,
}: AddToGroupModalProps) {
  const { showToast } = useToastNotification();
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");

  const { t } = useTranslation();

  return (
    <FixaModal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onTriggerNewModal(ModalTypes.KEEP_SELECTION);
      }}
    >
      <FixaSheets
        labelledById="add-to-group-modal"
        size={"small"}
        header={<FixaModalHeader title={`${t("selectGroupString")}`} />}
        footer={
          <FixaModalFooter>
            <FullWidthButton
              disabled={!selectedGroupId}
              text={t("confirmString")}
              type={"primary"}
              onClick={async () => {
                await addUniqueArticlesToGroup(
                  selectedArticles,
                  selectedGroupId
                );

                setSelectedGroupId("");
                onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
                logEvent("ce:articles_selected_addtogroup");
                showToast(t("articlesAddedString"));
              }}
            />
          </FixaModalFooter>
        }
      >
        <InputPopupContainer>
          <FixaListView id={"group-selections"} size={"small"}>
            {groups.map((group) => (
              <FixaListViewItem
                key={group.id}
                onChange={() => {
                  setSelectedGroupId(group.id);
                }}
                title={group.name}
                control={"radiobutton"}
              />
            ))}
          </FixaListView>
        </InputPopupContainer>
      </FixaSheets>
    </FixaModal>
  );
}
