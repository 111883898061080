import React from "react";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "../assets/svg/icon-arrow-small-back.svg";
import { ReactComponent as CloseIcon } from "../assets/svg/icon-close-large.svg";
import { ReactComponent as SearchIcon } from "../assets/svg/icon-search.svg";
import { ReactComponent as MoreIcon } from "../assets/svg/more.svg";
import { ReactComponent as IssueOther } from "@coworker/reusable/svg/three-dots.svg";

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  padding-top: 20px;
  box-sizing: border-box;
  height: 70px;
  background: var(--white);
  ${(props) => props.containerPadding && `padding: ${props.containerPadding}`}
  ${(props) => props.border && `border-bottom: 1px solid var(--grey150)`}
`;
const Button = styled('button')`
  background: transparent;
  padding-left: 10px;
  cursor: pointer;
`;
const StyledBackIcon = styled(BackIcon)`
  width: 50px;
  height: 50px;
`;

const CloseButton = styled(CloseIcon)`
  padding-left: 10px;
  width: 50px;
  height: 50px;
  color: var(--grey900);
  cursor: pointer;
`;

const MoreButton = styled(MoreIcon)`
  margin-right: 19px;
  color: var(--grey900);
  cursor: pointer;
`;

const StyledSearchIcon = styled(SearchIcon)`
  width: 50px;
  height: 50px;
`;

const Title = styled('div')`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey900);
`;

const TextButton = styled('button')`
  line-height: 50px;
  font-size: 14px;
  background: none;
  cursor: pointer;
  ${({ disabled }) =>
    disabled ? "color: var(--grey500)" : "color: var(--grey900)"};
`;

const IconContainer = styled('div')`
  /* This is the width of "Clear all". This hack allows
  * left/right containers to be equal width, so the central
  * container can be centered in the space
  */
  width: 82px;
  display: flex;
  ${(props) => props.right && "justify-content: flex-end;"}
`;

const MenuButton = styled('button')`
  margin-right: 10px;
  background-color: white;
  border: none;
  width: 42px;
  color: black;
  svg {
    vertical-align: middle;
  }
`;

const OverflowMenuButton = styled(IssueOther)`
  cursor: pointer;
`;
const Header = (props) => {
  const {
    title,
    showBack,
    onClose,
    onBack,
    showMore,
    onMore,
    showSearch,
    onTapSearch,
    showRightClose,
    containerPadding,
    rightComponent,
    border,
    showMenu,
    onMenuClick,
  } = props;

  return (
    <Container
      containerPadding={containerPadding}
      border={border}
      data-testid="header"
    >
      <IconContainer>
        {showBack && (
          <Button tabIndex={0} onClick={onBack} data-testid="backButton">
            <StyledBackIcon />
          </Button>
        )}
      </IconContainer>
      <Title>
        <div>{title}</div>
      </Title>
      <IconContainer right>
        {rightComponent}
        {showSearch && (
          <StyledSearchIcon
            onClick={() => onTapSearch && onTapSearch()}
            data-testid="header-search"
          />
        )}
        {showRightClose && (
          <Button tabIndex={0} onClick={onClose} data-testid="header-right-x">
            <CloseButton />
          </Button>
        )}
        {showMore && (
          <Button tabIndex={0} onClick={onMore}>
            <MoreButton />
          </Button>
        )}
        {showMenu && (
          <MenuButton onClick={onMenuClick} data-testid="dots">
            <OverflowMenuButton />
          </MenuButton>
        )}
      </IconContainer>
    </Container>
  );
};

export default Header;
