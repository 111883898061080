import styles from "./task-card.module.css";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import ImageIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ImageIconPath";
import SpeechBubbleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/SpeechBubbleIconPath";

export const ImagesAndComments = ({
  hasNotes,
  hasImages,
}: {
  hasNotes: boolean;
  hasImages: boolean;
}) => {
  return (
    <div className={styles["icon-wrapper"]}>
      {hasImages && (
        <FixaSSRIcon
          paths={ImageIconPath}
          className={styles["icon"] as string}
        />
      )}
      {hasNotes && (
        <FixaSSRIcon
          paths={SpeechBubbleIconPath}
          className={styles["icon"] as string}
        />
      )}
    </div>
  );
};
