import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import ChevronUpIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronUpIconPath";
import ChevronDownIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronDownIconPath";
import {
  FixaListBox,
  FixaMenuItem,
} from "@coworker/apprestructured/src/shared/wrappers/FixaListBox/FixaListBox";
import { FilterOptionType } from "../../../constants/filterOptions";
import { Trans } from "@coworker/locales";
import { StoreTeam } from "@coworker/types/lib/storeteam";
import {
  ActivityFilterType,
  FilterOptionsKeys,
} from "../../../types/filterOptionsTypes";
import styles from "../activity-filters.module.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";

interface DropdownPillProps {
  id: Extract<
    FilterOptionsKeys,
    | "assignedTo"
    | "createdBy"
    | "taskType"
    | "sortedBy"
    | "locations"
    | "period"
  >;
  label: string;
  isOpen: boolean;
  onClick: () => void;
  filters: ActivityFilterType;
  setFilters: (value: ActivityFilterType) => void;
  setIsFilterPanelOpen: (value: boolean) => void;
  closeDropdown: () => void;
  options: FilterOptionType[] | StoreTeam[];
  additionalPillAttributes?: Record<string, unknown>;
}

export const DropdownPill = ({
  id,
  label,
  isOpen,
  onClick,
  filters,
  setFilters,
  setIsFilterPanelOpen,
  closeDropdown,
  options,
  additionalPillAttributes,
}: DropdownPillProps) => {
  const formattedLabel = (
    <>
      <Trans>{label}</Trans>
      {id === "sortedBy" && filters.sortedBy && (
        <>
          :{" "}
          <Trans>
            {filters.sortedBy === "createdAtDesc"
              ? "newestFirstString"
              : "oldestFirstString"}
          </Trans>
        </>
      )}
    </>
  );

  const scrollToSectionInPanel = () => {
    setIsFilterPanelOpen(true);
    setTimeout(() => {
      document.getElementById(`filter-section-${id}`)?.scrollIntoView({
        behavior: "instant",
      });
    }, 0);
  };

  return (
    <>
      {isOpen && <div className={styles["backdrop"]} onClick={closeDropdown} />}
      <div className={styles["dropdown-pill-mobile"]}>
        <FixaPill
          size="small"
          label={formattedLabel}
          selected={!!filters[id] && (filters[id] as string[]).length > 0}
          onClick={() => {
            trackerHelper.trackActivityDropdownPillClick(id);
            scrollToSectionInPanel();
          }}
          {...additionalPillAttributes}
        />
      </div>
      <div className={styles["dropdown-pill-desktop"]}>
        <FixaPill
          size="small"
          label={formattedLabel}
          ssrIcon={isOpen ? ChevronUpIconPath : ChevronDownIconPath}
          iconPosition="trailing"
          onClick={() => {
            trackerHelper.trackActivityDropdownPillClick(id);
            if (id === "locations") {
              scrollToSectionInPanel();
            } else {
              onClick();
            }
          }}
          {...additionalPillAttributes}
        />

        <FixaListBox open={isOpen} value={[filters[id] as string]}>
          {options.map((item) => (
            <FixaMenuItem
              key={item.id}
              onClick={() => {
                trackerHelper.trackActivityDropdownPillValueClick(id, item.id);
                setFilters({
                  ...filters,
                  [id]: filters[id] === item.id ? !item.id : item.id,
                  ...(id === "taskType" && {
                    locations: [],
                    pickupLocations: [],
                    refillLocations: [],
                  }),
                });
                localStorage.setItem(
                  "activityFilters",
                  JSON.stringify({
                    ...filters,
                    [id]: filters[id] === item.id ? !item.id : item.id,
                    ...(id === "taskType" && {
                      locations: [],
                      pickupLocations: [],
                      refillLocations: [],
                    }),
                  })
                );
              }}
              id={item.id}
              title={
                <Trans>
                  {(item as FilterOptionType).transKey ??
                    (item as StoreTeam).name}
                </Trans>
              }
            />
          ))}
        </FixaListBox>
      </div>
    </>
  );
};
