import preferences from "@coworker/enums/profilePreferences";
import { useUserPreferenceStoreFilterValues } from "@coworker/apprestructured/src/shared/filters/utils";
import { useStatusFilter } from "./useStatusFilter";
import { useAreaFilter } from "./useAreaFilter";
import { useAlertsFilter } from "./useAlertsFilter";
import { useSortByFilter } from "./useSortByFilter";

export function useMediaOverviewFilters(areas: string[]) {
  const { filterValues, setFilterValues } = useUserPreferenceStoreFilterValues(
    preferences.MEDIA_OVERVIEW_FILTERS
  );

  const useActivityDataFilters = () => [
    useStatusFilter(),
    useAreaFilter(areas),
    useAlertsFilter(),
    useSortByFilter(filterValues),
  ];

  return {
    useFiltersFactory: useActivityDataFilters,
    filterValues,
    setFilterValues,
  };
}
