import React from "react";
import styled from "styled-components";

const InputGroup = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputField = styled('input')`
  box-sizing: border-box;
  background: var(--grey150);
  border-radius: 10px;
  caret-color: var(--new);
  color: var(--grey900);
  font-size: 14px;
  height: 60px;
  outline: none;
  line-height: 1.25;
  padding: 1.375rem;
  &::placeholder {
    color: var(--grey500);
  }
`;
const defaultMaxLength = 500;
function InputText({
  id,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  value,
  maxLength = defaultMaxLength,
}) {
  if (typeof maxLength !== "number") maxLength = defaultMaxLength;
  const setValue = React.useCallback(
    (event) => {
      if (event.target.value.length <= maxLength) {
        onChange(event);
      }
    },
    [maxLength, onChange]
  );
  return (
    <InputGroup>
      <InputField
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        onFocus={onFocus}
        id={id}
        placeholder={placeholder}
      />
    </InputGroup>
  );
}

export default InputText;
