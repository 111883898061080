import { isRDTDevice11, RDT_HIDDEN_INPUT_ID } from "@coworker/reusable";
import React from "react";
import styled from "styled-components";

const HiddenInputWrapper = styled('div')`
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
`;

export function HiddenInput({
  setScannerText,
}: {
  setScannerText: React.Dispatch<React.SetStateAction<string>>;
}) {
  const isNewRDT = isRDTDevice11();
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (isNewRDT && divRef.current) {
      divRef.current.focus();
    }
  }, [isNewRDT]);
  

  // const htmlCustomAttrs = { virtualkeyboardpolicy: "manual" };

  return isNewRDT ? (
    <HiddenInputWrapper>
      <div
        ref={divRef}
        id={RDT_HIDDEN_INPUT_ID}
        inputMode="text"
        contentEditable
        onFocus={(e) => {
          e.target.innerHTML = "";
        }}
        onInput={(e) => {
          const target = e.target as HTMLDivElement;
          if (target.innerText.trim().length >= 13) {
            setScannerText(target.innerText.trim().slice(0, 13));
            target.blur();
          }
        }}
        // {...htmlCustomAttrs}
      />
    </HiddenInputWrapper>
  ) : null;
}
