import { useQuery } from "@tanstack/react-query";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { fetchAllProgressByRound } from "../ShoppingToolsAPI";
import { INTERVAL } from "../../../constants/clientTime";
import { getStartOfDay } from "../helper";
import { AllProgressByRound } from "../Types";

export const useFetchAllProgressByRound = () => {
  const storeId = useStoreId();
  const startOfDay = getStartOfDay();
  return useQuery<AllProgressByRound, Error>({
    queryKey: ["shopping-tools-progress-by-round", storeId],
    queryFn: async () => {
      const result = await fetchAllProgressByRound(storeId, startOfDay);
      return result as AllProgressByRound;
    },
    staleTime: INTERVAL.MINUTE * 1,
  });
};
