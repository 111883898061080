import { Hfb, Media } from "../../types/media";
import {
  FixaOption,
  FixaSelect,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import { useTranslation } from "@coworker/locales";
import LocationPinSmallIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/LocationPinSmallIconPath";
import { getDepartmentsOptions } from "../../../../helpers/departmentsOptions";

interface HfbSelectProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const HfbSelect = ({ media, onSave }: HfbSelectProps) => {
  const { t } = useTranslation();
  return (
    <FixaSelect
      id={"hfb"}
      label={t("hfbLocationInStoreString")}
      value={(media as Hfb).hfb ?? ""}
      ssrIcon={LocationPinSmallIconPath}
      onChange={(event) => {
        const newHfb = event.target.value;
        const newMedia = { ...media };
        (newMedia as Hfb).hfb = newHfb;
        onSave(newMedia);
      }}
    >
      {getDepartmentsOptions(t).map((department) => (
        <FixaOption {...department} key={department.value} />
      ))}
    </FixaSelect>
  );
};
