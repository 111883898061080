import {useEffect} from "react";
import { ArticleList, ArticleListProps } from "./ArticleList";
import styled from "styled-components";
import {
  createCounter,
  getRemainingArticlesProps,
} from "./TestBuyDetailsHelper";
import { Trans } from "@coworker/locales";

const RemainingHeader = styled('h3')`
  padding: 1rem 1.5rem 1rem 2rem;
  border-bottom: 1px solid var(--grey150);
`;

export interface RemainingArticlesProps extends ArticleListProps {
  onDone: (testbuyId: string) => Promise<unknown>;
  onChangeArticle(): Promise<void>;
}

export default function RemainingArticleList({
  articles,
  testbuyId,
  onDone,
  onChangeArticle,
}: RemainingArticlesProps) {
  const remainingArticlesProps = getRemainingArticlesProps(articles, testbuyId);
console.log("RemainingArticleList", articles, remainingArticlesProps);

  const isDone =
    remainingArticlesProps.articles.length === 0 && articles.length > 0;
  useEffect(() => {
    if (isDone) onDone(testbuyId);
  }, [isDone, onDone, testbuyId]);

  return (
    //This div exist due to render issues on RDT
    <div data-testid="remaining-article-list">
      <RemainingHeader>
        <Trans>remaining</Trans>{" "}
        <span data-testid="remaining-articles-count">
          {createCounter(
            remainingArticlesProps.articles.length,
            articles.length
          )}
        </span>
      </RemainingHeader>
      <ArticleList
        {...remainingArticlesProps}
        allArticleNumbers={articles.map((article) => article.shortId)}
        onChangeArticle={onChangeArticle}
      />
    </div>
  );
}
