import React, { useState } from "react";
import styles from "./tasks-prompt.module.css";
import { FixaModal } from "../../../../shared/wrappers/FixaModal/FixaModal";
import { FixaPrompt } from "../../../../shared/wrappers/FixaModal/FixaPrompt";
import { FixaModalFooter } from "../../../../shared/wrappers/FixaModal/FixaModalFooter";
import FixaButton from "../../../../shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "@coworker/locales";
import { FixaListView } from "../../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../../shared/wrappers/FixaListView/FixaListViewItem";
import { useStoreSwitcher } from "@coworker/app/src/core/hooks/useStoreSwitcher";
import trackerHelper from "@coworker/app/src/helpers/tracker";

interface TasksPromptProps {
  isVisible: boolean;
  onClose: () => void;
  myTasksCount: number;
  uid: string;
  updateUser: () => void;
}

export const TasksPrompt = ({
  isVisible,
  onClose,
  myTasksCount,
  uid,
  updateUser,
}: TasksPromptProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<"keep" | "reassign">(
    "keep"
  );
  const [isLoading, setIsLoading] = useState(false);
  const storeSwitcherMutation = useStoreSwitcher(uid, updateUser);

  const handleConfirm = () => {
    setIsLoading(true);
    trackerHelper.trackStoreSwitcher("profile");
    if (selectedOption === "reassign") {
      storeSwitcherMutation.mutate();
      trackerHelper.trackStoreSwitcherTasksOption("reassign");
    } else {
      updateUser();
      trackerHelper.trackStoreSwitcherTasksOption("keep");
    }
  };

  return (
    <FixaModal visible={isVisible} handleCloseBtn={onClose}>
      <FixaPrompt
        titleId="tasksPrompt"
        title={
          myTasksCount > 1
            ? t("tasksAssignedToYouString", { count: myTasksCount })
            : t("oneTaskAssignedToYouString")
        }
        className={styles["tasks-prompt"] as string}
        header={null}
        footer={
          <FixaModalFooter className={styles["footer"] as string}>
            <FixaButton type={"secondary"} text={t("cancelString")} />
            <FixaButton
              type={"primary"}
              text={t("confirmString")}
              loading={isLoading}
              onClick={handleConfirm}
            />
          </FixaModalFooter>
        }
      >
        <p className={styles["sub-title"]}>
          {t("pleaseDecideWhatTodoWithTasksString")}
        </p>
        <FixaListView
          id="task-prompt"
          className={styles["task-options"] as string}
        >
          <FixaListViewItem
            id="keep-tasks"
            control="radiobutton"
            title={t("keepTasksAssignedToMeString")}
            onChange={() => setSelectedOption("keep")}
            description={t("notVisibleUntilYouChangeBackString")}
            inset
            emphasised
            controlProps={{
              checked: selectedOption === "keep",
              value: "keep",
            }}
          />
          <FixaListViewItem
            id="reassign-tasks"
            control="radiobutton"
            title={t("reassignAllTasksToMyTeamString")}
            onChange={() => setSelectedOption("reassign")}
            inset
            emphasised
            controlProps={{
              checked: selectedOption === "reassign",
              value: "reassign",
            }}
          />
        </FixaListView>
      </FixaPrompt>
    </FixaModal>
  );
};
