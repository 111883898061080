import Webcam from "react-webcam";
import React,{useRef} from "react";
import styled, { css } from "styled-components";
import { Button, Icon, ScannerZoomSlider } from "@coworker/components";
import { isRDTDevice, isRDTDevice11 } from "@coworker/reusable";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { useInputPopup } from "../InputPopup";
import { Trans, useTranslation } from "@coworker/locales";
import Overlay from "./Overlay";
import {
  useCameraConstraints,
  useScale,
  isTorchOn,
} from "./useCameraCapabilities";
import { useWindowProperties } from "./useWindowProperties";
import useBrowserWorker from "./useBrowserWorker";
import { useVideoCrop } from "./useVideoCrop";
import trackerHelper from "../../helpers/tracker";
import { detectMainCamera } from "./detectMainCamera";
import { scannerLog } from "./log";
import { useItemInfoAndPrice } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfoAndPrice";
import { SimpleLoadingBall } from "@coworker/apprestructured/src/shared/simple/SimpleLoading/SimpleLoading";

const StyledLoaderIcon = styled(LoaderIcon)`
  width: 20px;
  height: 20px;
`;

const LoaderContainer = styled('div')`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const onUserMediaError = (error) => {
  if (!process.env.JEST_WORKER_ID) console.error(error);
};

const {
  barcode: { logScannerType },
} = trackerHelper;

const AppWrapper = styled('div')`
  height: 100%;
  overflow: hidden;
`;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 2px;
`;

const ControlsContainer = styled('div')`
  position: fixed;
  ${({ inPopup }) => css`
    bottom: ${inPopup ? "10px" : "90px"};
  `};
  width: calc(100% - 38px);
  display: flex;
  flex-direction: column;
  margin: 0 19px;
  justify-content: center;
`;

const SliderContainer = styled('div')`
  margin: 30px 19px 0;
  flex-grow: 1;
`;

const noop = () => null;
const SCANNING_INTERVAL = 300; //ms

/**
 * Barcode scanner using browser provided BarcodeDetector API
 * @param {{
 *  onBarcode: (barcode: string, ...TODO) => void
 *  switchToRDTScanner: () => void
 *  videoRef: React.MutableRefObject<HTMLVideoElement>
 *  paused: boolean
 *  }} props
 */
export function BrowserScanner({
  onBarcode = noop,
  switchToRDTScanner = noop,
  loadingItemInfo = noop,
  videoRef,
  paused,
}) {
  /**
   * @type {React.MutableRefObject<{video: HTMLVideoElement, stream: MediaStream}>}
   */
  const webcamRef = React.useRef();
  const isScanningRef = React.useRef(false);
  const cropDebugRef = React.useRef();

  // Context
  const { popupOpen: inPopup } = useInputPopup();
  const isRDT = isRDTDevice() || isRDTDevice11();
  const { size, ratio } = useWindowProperties();

  // State
  const [scanning, setScanning] = React.useState(false);
  const [cameraDetectionFailed, ] =
    React.useState(false);
  const [barcode, setBarcode] = React.useState("");
  const { data: itemInfoAndPrice, isLoading: isLoadingItemInfo } =
    useItemInfoAndPrice(barcode);
  const [supplierNumber, setSupplierNumber] = React.useState("");
  const [constraints, setConstraints] = useCameraConstraints(
    webcamRef.current?.stream
  );
  const [cameraCapabilities, setCameraCapabilities] = React.useState();
  const [zoomLevel, setZoom] = useScale();
  const [deviceId, setChosenDeviceId] = React.useState();
  const hasRun = useRef(false);

  React.useEffect(() => {
    async function detectCamera() {
      if (hasRun.current) return; // Prevents double execution in Strict Mode
      hasRun.current = true;


        try {
          console.log("Camera detection start");
          const c = await detectMainCamera();
          const { deviceId, label } = c?.device || {};

          if (deviceId) {
            scannerLog(`Choosing camera: "${deviceId} - ${label}"`);
            setChosenDeviceId(deviceId);
          } else {
            scannerLog("No main camera detected", c?.device || c);
          }
        } catch ({ code, message }) {
          window.enableScannerLogging = true;
          scannerLog("Could not detect camera", code, message);
        }

    }

    detectCamera();
  }, []);


  React.useEffect(() => {
    setConstraints({ zoom: zoomLevel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomLevel]);
  React.useEffect(() => {
    if (webcamRef.current && webcamRef.current.video) {
      videoRef.current = webcamRef.current.video;
    }
  }, [webcamRef, videoRef]);

  // Web worker handling
  const barcodeWorker = useBrowserWorker(
    isScanningRef,
    setBarcode,
    setSupplierNumber,
    setScanning
  );

  const reticule = useVideoCrop(webcamRef.current?.video);
  // Send scanning result to web worker on interval
  React.useEffect(() => {
    let interval;
    let errorCount = 0;

    // Ensure that barcodeWorker and scanning state are valid
    if (barcodeWorker && !barcode && scanning) {
      function findBarcode() {
        if (isScanningRef.current) return; // Prevent scanning too quickly

        const video = webcamRef.current?.video;
        if (!video || !video.width || !video.videoWidth) return; // Ensure video is available and valid

        // Attempt to create an image from the video feed within the reticule
        createImageBitmap(video, ...reticule)
          .then((image) => {
            if (window.enableScannerLogging && cropDebugRef.current) {
              // Debugging image cropping
              scannerLog("drawing");
              cropDebugRef.current.width = image.width;
              cropDebugRef.current.height = image.height;
              cropDebugRef.current
                .getContext("bitmaprenderer")
                .transferFromImageBitmap(image);
            } else {
              // Flag that we've sent off an image to avoid sending too frequently
              isScanningRef.current = true;
              barcodeWorker.postMessage(image, [image]);
            }
          })
          .catch((error) => {
            errorCount += 1;
            scannerLog(`Scanner.jsx error count ${errorCount}`, video);
            scannerLog(error);
          });
      }

      interval = setInterval(findBarcode, SCANNING_INTERVAL);
    }

    return () => {
      scannerLog("Clearing interval");

      // Clear interval when component unmounts or dependencies change
      if (interval) clearInterval(interval);

      // Terminate the worker if it's still running (cleanup)
      if (barcodeWorker) {
        barcodeWorker.terminate();
        scannerLog("Worker terminated");
      }
    };
  }, [barcode, barcodeWorker, reticule, scanning]);


  // Run `onBarcode` callback when a barcode is detected
  const didRun = useRef(false);

  React.useEffect(() => {
    if (!didRun.current && barcode && !paused) {
      scannerLog("browserScanner: Barcode detected", barcode);
      if (!isLoadingItemInfo) {
        onBarcode(
          barcode,
          undefined,
          itemInfoAndPrice,
          supplierNumber,
          isLoadingItemInfo
        );
        setBarcode(null);
        setSupplierNumber(null);
        logScannerType("BROWSER_SCANNER", barcode);
      } else {
        loadingItemInfo();
      }
    }
  }, [
    barcode,
    onBarcode,
    paused,
    supplierNumber,
    isLoadingItemInfo,
    itemInfoAndPrice,
    loadingItemInfo,
  ]);

  const { t } = useTranslation();
  const videoConstraints = React.useMemo(() => {
    const constraints = { ratio };
    if (deviceId) constraints.deviceId = { exact: deviceId };
    else constraints.facingMode = "environment";
    return constraints;
  }, [deviceId, ratio]);
  const onUserMedia = React.useCallback((stream) => {
    const track = stream.getVideoTracks()[0];
    setCameraCapabilities(track.getCapabilities());
    scannerLog(`track set, deviceId: ${track.label}`);
  }, []);
  return (
    <AppWrapper>
      {isLoadingItemInfo && (
        <LoaderContainer>
          <SimpleLoadingBall
            text={t("fetchingItemInfoString")}
            size={"large"}
          />
        </LoaderContainer>
      )}
      {window.enableScannerLogging && (
        <canvas // Used to debug image cropping
          ref={cropDebugRef}
          style={{
            position: "absolute",
            zIndex: 100,
            transform: "scale(0.2)",
            transformOrigin: "top left",
          }}
        />
      )}
      {!isLoadingItemInfo && (
        <React.Fragment>
          <Overlay />
          {ratio && (deviceId || cameraDetectionFailed) && (
            <Webcam
              style={{ backgroundColor: "var(--black)" }}
              audio={false}
              width={size.width}
              height={size.height}
              imageSmoothing={false}
              videoConstraints={videoConstraints}
              ref={webcamRef}
              onUserMedia={onUserMedia}
              onUserMediaError={onUserMediaError}
            />
          )}
          <ControlsContainer inPopup={inPopup}>
            <ButtonContainer>
              {isRDT && (
                <Button
                  primary
                  dark
                  text={
                    <Icon family="icons" name="rdt_scanner" color="white" />
                  }
                  onClick={switchToRDTScanner}
                  flexNoGrow
                  style={{ marginRight: "9px" }}
                />
              )}
              <Button
                primary
                dark
                data-testid="pushToScan"
                flexGrow
                text={
                  scanning ? <StyledLoaderIcon /> : <Trans>scanString</Trans>
                }
                onClick={() => {
                  setScanning(!scanning);
                }}
              />
              {cameraCapabilities?.torch && (
                <Button
                  primary
                  dark
                  flexNoGrow
                  style={{ marginLeft: "9px" }}
                  text={
                    <Icon
                      family="actions"
                      name={isTorchOn(constraints) ? "torch-off" : "torch-on"}
                      color="white"
                    />
                  }
                  onClick={() =>
                    setConstraints({ torch: !isTorchOn(constraints) })
                  }
                />
              )}
            </ButtonContainer>
            {cameraCapabilities?.zoom && (
              <SliderContainer>
                <ScannerZoomSlider
                  value={zoomLevel}
                  setValue={setZoom}
                  min={cameraCapabilities.zoom.min}
                  max={cameraCapabilities.zoom.max}
                  step={cameraCapabilities.zoom.step}
                />
              </SliderContainer>
            )}
          </ControlsContainer>
        </React.Fragment>
      )}
    </AppWrapper>
  );
}
