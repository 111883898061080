import {useCallback,useState} from "react";
import { isPeriod } from "./filterChoices";
import { allDivisions } from "../../TestBuy/constants";

/**
 * Used by the UserFilter Filter popup.
 * Encapsulates logic to for storing and updating filter popup state.
 * Does not persist to database.
 */
export function useUserFilters(initialFilters = []) {
  const [filters, setFilters] = useState(initialFilters);

  const updateFilters = useCallback(
    (change) => {
      let changedFilters = [];
      const divisions = allDivisions.map(
        (division) => division.divisionShortName
      );
      const { type } = change;
      const isDivision = divisions.includes(type);

      if (isDivision) {
        const removeItem = filters.findIndex((item) =>
          divisions.includes(item)
        );
        changedFilters = filters.filter((_item, index) => index !== removeItem);

        const { item } = change;
        if (item) {
          changedFilters = filters.filter(
            (item) => isPeriod(type) ^ isPeriod(item)
          );
          changedFilters.push(item, type);
        }
        setFilters(changedFilters);
      } else {
        const changedFilters = filters.filter(
          (item) => isPeriod(type) ^ isPeriod(item) // XOR, since we keep area if incoming is period, else we keep period
        );
        changedFilters.push(type);
        setFilters(changedFilters);
      }
    },
    [filters, setFilters]
  );

  return [filters, updateFilters];
}
