import React, { useMemo } from "react";
import {
  TestbuyArticleCardProps,
  TestBuyArticleCard,
} from "../CommonComponents/ArticleDetailsCard/TestBuyArticleCard";
import { useTranslation } from "@coworker/locales";
import { CriteriaList } from "./CriterionComponent/CriteriaList";
import { updateCriterionReducer } from "./CriterionComponent/updateCriterionReducer";
import {
  allCriteriaAnswered,
  allCriteriaOk,
} from "../CommonComponents/article.helpers";
import {
  useTestbuyTasks,
  useTestbuy,
} from "../CommonComponents/Repositories/useTestbuyService";
import { formatProductIdWithDots } from "@coworker/reusable";
import { isDateWithinLastWeek } from "../CommonComponents/helpers";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";
import useFixaNavigate from "@coworker/apprestructured/src/shared/wrappers/FixaNavigate/useFixaNavigate";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { useNavigate } from "react-router-dom";
import useFormatter from "@coworker/app/src/hooks/useFormatter";
import { TaskType } from "@coworker/apprestructured/src/tasks/enums/taskTypes";

export function DisplayArticleDetails(props: TestbuyArticleCardProps) {
  const { article, testbuyId } = props;
  const { t } = useTranslation();
  const navigate = useFixaNavigate();
  const { formatDateISO8601 } = useFormatter();

  const { toggleModal } = React.useContext(ModalContext);
  const [criteriaState, dispatch] = React.useReducer(
    updateCriterionReducer,
    article.criteria
  );
  const [loadButton, setLoadButton] = React.useState<boolean>(false);
  const routerNavigate = useNavigate();
  props.article.criteria = criteriaState;

  const { testbuy, loading } = useTestbuy(testbuyId);
  const headerTitleText = `${article.name} ${formatProductIdWithDots(
    article.shortId
  )}`;
  const isTaskCreated = !!article.followUpTaskId;

  const task = useTestbuyTasks(isTaskCreated ? testbuy?.tasks : [])?.find?.(
    (taskData) => taskData.articleId === article.shortId
  );

  const isTaskResolved =
    task?.state === "COMPLETED" || task?.state === "CLOSED";
  const isTaskFinishedWithinLastWeek =
    isTaskResolved && isDateWithinLastWeek(task?.finishedAt);
  const isTaskViewable =
    !!task &&
    isTaskCreated &&
    (!isTaskResolved || (isTaskResolved && isTaskFinishedWithinLastWeek));

  const parentLabel = formatDateISO8601(testbuy?.createdAt);

  const linkbarItems = useMemo(() => {
    return [
      {
        label: t("testBuysString"),
        to: "/testbuy",
      },
      {
        label: parentLabel || "",
        to: `/testbuy/${testbuyId}`,
      },
    ];
  }, [parentLabel, t, testbuyId]);

  return (
    <FullScreenPopup
      noPadding
      actionBarContent={
        <FixaButton
          data-testid="article-done"
          type="primary"
          text={t(isTaskCreated ? "showTaskString" : "doneString")}
          onClick={async () => {
            setLoadButton(true);
            if (allCriteriaOk(article.criteria)) {
              navigate(`/testbuy/${testbuyId}`);
            } else if (isTaskViewable) {
              toggleModal({
                type: ModalType.TaskDetail,
                payload: {
                  taskId: article.followUpTaskId,
                  taskType: TaskType.TESTBUY_FOLLOW_UP,
                },
                onToggle: () => {
                  routerNavigate(-1); // Navigate back when the modal is closed
                },
              });
              setLoadButton(false);
              navigate(`/testbuy/${testbuyId}/article/${article.id}`);
            } else {
              navigate(
                `/testbuy/${testbuyId}/article/${article.id}/createTask`
              );
            }
          }}
          disabled={
            !allCriteriaAnswered(article.criteria) ||
            (isTaskCreated && !isTaskViewable)
          }
          loading={loadButton}
          fluid={true}
        />
      }
      linkbar={
        <Linkbar
          items={linkbarItems}
          currentLabel={headerTitleText}
          currentAsH1
          loading={!parentLabel}
        />
      }
    >
      <TestBuyArticleCard {...props} />
      <CriteriaList
        testbuyId={testbuyId}
        articleId={article.id || ""}
        criteria={criteriaState}
        dispatch={dispatch}
        isLocked={loading || testbuy?.status !== "IN_PROGRESS" || isTaskCreated}
        location={article.location}
        price={article.price}
      />
    </FullScreenPopup>
  );
}
