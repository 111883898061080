import { FilterValue } from "../../shared/filters/types";

export function mapFilterValuesToObject(
  filterValues: FilterValue[]
): Record<string, string | boolean> {
  const filtersMap = filterValues.reduce(
    (
      accumulatedFilters: Record<string, string | boolean>,
      currentFilter: FilterValue
    ) => {
      let value: string | boolean;

      if (currentFilter.value === null) {
        value = "null";
      } else if (Array.isArray(currentFilter.value)) {
        value = currentFilter.value.join(", ");
      } else if (typeof currentFilter.value === "boolean") {
        value = currentFilter.value;
      } else {
        value = String(currentFilter.value);
      }

      accumulatedFilters[currentFilter.filterId] = value;
      return accumulatedFilters;
    },
    {} as Record<string, string | boolean>
  );

  return filtersMap;
}
