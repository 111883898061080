import React from "react";
import styled from "styled-components";
import { TextArea, Button } from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import { useTextAreaPopup } from "./_common";
import { Heading } from "../Layout/Heading";
import { FULLSCREEN_MAX_TEXT_LENGTH } from "../../constants/textInput";

const ButtonWrapper = styled('div')`
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 106px;
  padding-bottom: 10px;
`;

const Container = styled('div')`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 50px;
  overflow-y: auto;
`;
const Title = styled('h1')`
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--grey900);
  margin: 55px 29px;
  margin-bottom: 0px;
`;
const StyledButton = styled(Button)`
  margin: 21px 19px 19px 30px;
  width: 100%;
`;

const StyledArea = styled(TextArea)`
  flex-grow: 1;
  overflow-y: auto;
`;

const CompleteWithText = ({
  onClose,
  titleTranslationKey,
  actionTranslationKey,
  initialValue,
  placeholderTranslationKey,
  onSubmit,
}) => {
  const [value, setValue] = React.useState(initialValue || "");
  const containerRef = React.useRef(null);
  const { textArea, onFocus, onBlur } = useTextAreaPopup(containerRef, value);
  const { t } = useTranslation();

  return (
    <Container ref={containerRef} data-testid="completeWithTextModal">
      <Heading handleBack={onClose} />
      <Title>
        <Trans>{titleTranslationKey}</Trans>
      </Title>
      <StyledArea
        data-testid="completeWithTextModal"
        maxLength={FULLSCREEN_MAX_TEXT_LENGTH}
        placeholder={t(placeholderTranslationKey)}
        onChange={(e) => setValue(e.target.value)}
        ref={textArea}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <ButtonWrapper>
        <StyledButton
          customMargin
          type="submit"
          text={<Trans>{actionTranslationKey}</Trans>}
          primary
          dark
          disabled={!value}
          onClick={(event) => {
            event.preventDefault();
            onSubmit(value);
          }}
          data-testid="completeWithTextButton"
        />
      </ButtonWrapper>
    </Container>
  );
};

export default CompleteWithText;
