import React from "react";
import styled from "styled-components";
import { Checkbox, Header } from "@coworker/components";
import { ConfirmationPrompt } from "@coworker/components";
import { motion, AnimatePresence } from 'framer-motion';
import { Trans } from "@coworker/locales";
import { updateUser } from "../../services/user.service";
import { useTaskListFilters } from "./TaskListFilters";
import statusTypes from "@coworker/enums/statusTypes";
import trackerHelper from "../../helpers/tracker";
import { revealFromBelow } from "../../helpers/animationHelper";
import { useFixaUID } from "../../core/auth/useLoggedInUser";
import { isRDTDevice } from "@coworker/reusable";
import { setLoggedInUserTeamId } from "../../core/auth/useLoggedInUser";

const Container = styled('div')`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--white);
`;
const HeaderContainer = styled('div')`
  position: fixed;
  background: var(--white);
  width: 100%;
  height: 70px;
  left: 0;
  top: 0;
`;
const Body = styled('div')`
  position: relative;
  overflow: auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--white);
  border-top: 1px solid var(--grey200);
`;
const Team = styled('div')`
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 20px;
  border-bottom: 1px solid var(--grey200);
`;
const CheckboxWrapper = styled('div')`
  margin-bottom: 16px;
  flex: 0 0;
  min-width: 16px;
`;
const Backdrop = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--black);
  opacity: 0.7;
`;

const TeamName = styled('div')`
  flex: 1 1;
  overflow: hidden;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
`;

const PosedBackdrop = motion.create(Backdrop);

const PosedConfirmation = revealFromBelow(ConfirmationPrompt);

const TeamChange = ({ title, teams, teamId, onClose }) => {
  const [currentTeam, setCurrentTeam] = React.useState(teamId);
  const [popup, setPopup] = React.useState(false);
  const [mounted, setMounted] = React.useState(true);
  const [teamToChange, setTeamToChange] = React.useState("");
  const callbackRef = React.useRef(null);
  const withUnmountDelay = React.useCallback((fn) => {
    setMounted(false);
    callbackRef.current = fn;
  }, []);
  React.useEffect(() => {
    if (!mounted && callbackRef.current) {
      callbackRef.current();
    }
  }, [mounted]);

  const renderTeam = (team) =>
    team.name &&
    team.id &&
    team.status === statusTypes.ACTIVE &&
    (!team.is_product_quality || team.id === currentTeam) ? (
      <Team
        key={team.id}
        data-testid={team.name.replace(/\s+/g, "")}
        onClick={() => {
          if (team.id !== currentTeam) {
            setPopup(true);
            setTeamToChange(team.id);
          }
        }}
      >
        <TeamName>{team.name}</TeamName>
        <CheckboxWrapper>
          <Checkbox
            key={team.created_at}
            checked={team.id === currentTeam}
            readOnly
          />
        </CheckboxWrapper>
      </Team>
    ) : null;

  const { resetTaskListFilters } = useTaskListFilters();
  const fixaUid = useFixaUID();
  const onConfirm = React.useCallback(() => {
    trackerHelper.changeTeam.confirm(teamToChange);
    updateUser(fixaUid, { team_id: teamToChange });
    setLoggedInUserTeamId(teamToChange);
    setCurrentTeam(teamToChange);
    resetTaskListFilters();
    withUnmountDelay(onClose);
  }, [fixaUid, onClose, resetTaskListFilters, teamToChange, withUnmountDelay]);
  const isRDT = isRDTDevice();

  teams.sort((a, b) => {
    const aName = a.name.toLocaleUpperCase();
    const bName = b.name.toLocaleUpperCase();
    return aName.localeCompare(bName);
  });

  return (
    <Container data-testid="team-change-popup">
      <HeaderContainer>
        <Header showRightClose onClose={onClose} title={title} />
      </HeaderContainer>
      <Body>{teams.map(renderTeam)}</Body>
      {popup && (
        <AnimatePresence initial={!isRDT}>
          {mounted && (
            <PosedBackdrop
              key="backdrop"
              onClick={() => {
                setPopup(false);
              }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
              exit={{ opacity: 0, y: 20, transition: { duration: 0.2 } }}
            />
          )}
          {mounted && (
            <PosedConfirmation
              key="confirmation"
              onConfirm={onConfirm}
              onDeny={() => {
                setPopup(false);
              }}
              question={<Trans>switchString</Trans>}
              description={<Trans>switchDescriptionString</Trans>}
              negativeText={<Trans>cancelString</Trans>}
              positiveText={<Trans>yesString</Trans>}
            />
          )}
        </AnimatePresence>
      )}
    </Container>
  );
};

export default TeamChange;
