import React from "react";
import styled, { css } from "styled-components";

export const CAPTURE_SIZE = [290, 260];
// Offset that will be scanned against barcodes added to CAPTURE_SIZE for better
// scanning chances
export const OVERSCAN_SIZE = [26, 10];
const OVERSCAN_DRAW_MARGIN = 12;

const Left = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  ${({ captureSize }) =>
    css`
      width: calc((100% - ${captureSize[0]}px) / 2);
    `}
  background-color: rgba(0, 0, 0, 0.5);
`;

const Right = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  ${({ captureSize }) =>
    css`
      width: calc((100% - ${captureSize[0]}px) / 2);
    `}
  background-color: rgba(0, 0, 0, 0.5);
`;

const Top = styled('div')`
  position: absolute;
  top: 0;
  ${({ captureSize }) =>
    css`
      left: calc((100% - ${captureSize[0]}px) / 2);
      height: calc((100% - ${captureSize[1]}px) / 2);
      width: ${captureSize[0]}px;
    `}
  background-color: rgba(0, 0, 0, 0.5);
`;

const Bottom = styled('div')`
  position: absolute;
  bottom: 0;
  ${({ captureSize }) =>
    css`
      left: calc((100% - ${captureSize[0]}px) / 2);
      height: calc((100% - ${captureSize[1]}px) / 2);
      width: ${captureSize[0]}px;
    `}
  background-color: rgba(0, 0, 0, 0.5);
`;

const Corners = styled('div')`
  position: absolute;
  ${({ captureSize, overscanMargin }) => css`
    top: calc((100% - ${captureSize[1]}px) / 2 - ${overscanMargin}px);
    left: calc((100% - ${captureSize[0]}px) / 2 - ${overscanMargin}px);
    width: ${captureSize[0] + 2 * overscanMargin}px;
    height: ${captureSize[1] + 2 * overscanMargin}px;
  `}
`;

const Corner = styled('div')`
  width: 16px;
  height: 16px;
`;

const TopLeftCorner = styled(Corner)`
  position: absolute;
  top: 0;
  left: 0;
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  z-index: 1;
`;

const TopRightCorner = styled(Corner)`
  position: absolute;
  top: 0;
  right: 0;
  border-top: 2px solid var(--white);
  border-right: 2px solid var(--white);
  z-index: 1;
`;

const BottomLeftCorner = styled(Corner)`
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid var(--white);
  border-left: 2px solid var(--white);
  z-index: 1;
`;

const BottomRightCorner = styled(Corner)`
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  z-index: 1;
`;

function Overlay() {
  return (
    <>
      <Corners captureSize={CAPTURE_SIZE} overscanMargin={OVERSCAN_DRAW_MARGIN}>
        <TopLeftCorner />
        <TopRightCorner />
        <BottomLeftCorner />
        <BottomRightCorner />
      </Corners>
      <Left captureSize={CAPTURE_SIZE} />
      <Top captureSize={CAPTURE_SIZE} />
      <Bottom captureSize={CAPTURE_SIZE} />
      <Right captureSize={CAPTURE_SIZE} />
    </>
  );
}

export default React.memo(Overlay);
