import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createShoppingTool } from "../ShoppingToolsAPI";

export const useCreateShoppingTool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createShoppingTool,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey:["shopping-tools-progress-by-round"]});
      queryClient.invalidateQueries({queryKey:["shopping-tools-chart-data"]});
      queryClient.invalidateQueries({queryKey:["shopping-tools-todays-progress"]});
      queryClient.invalidateQueries({queryKey:["shopping-tools-latest"]});
    },
  });
};
