import { Trans } from "@coworker/locales";
import { LocationTag } from "../../LocationTag";
import {
  FlexRow,
  WidgetAreaStatic,
} from "@coworker/reusable/components/Widgets";
import styled from "styled-components";

export const PaddedWidgetAreaStatic = styled(WidgetAreaStatic)`
  padding: 12px 28px;
`;

export function LocationWidget({ location }: { location: string }) {
  return (
    <PaddedWidgetAreaStatic>
      <b>
        <Trans>location3String</Trans>
      </b>
      <FlexRow>
        {location}
        <LocationTag type="home" />
      </FlexRow>
    </PaddedWidgetAreaStatic>
  );
}
