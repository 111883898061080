import React from "react";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import "./TasksTodos.css";
import { TaskTabsTypes } from "../../../shared/constants/taskTabsTypes";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import {
  openTasksPath,
  newTasksPath,
} from "../../../tasks/constants/taskRoutePaths";
import trackerHelper from "../../../../../coworker-app/src/helpers/tracker";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";

const EmptyTasksContainer = styled('div')`
  width: 164px;
  padding-top: 48px;
  padding-bottom: 48px;
`;

interface EmptyTasksProps {
  id: string;
  tasksTabType: TaskTabsTypes;
  hideViewButton: boolean;
}

export const TasksEmpty: React.FC<EmptyTasksProps> = ({
  id,
  tasksTabType,
  hideViewButton,
}) => {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { toggleModal } = React.useContext(ModalContext);
  const handleOnClick = (tasksTabType: TaskTabsTypes) => {
    const path =
      tasksTabType === TaskTabsTypes.OPEN ? newTasksPath : openTasksPath;
    if (tasksTabType === TaskTabsTypes.MY) {
      push(path);
    } else {
      toggleModal({ type: ModalType.MainNavigation });
    }
    if (path === newTasksPath) {
      trackerHelper.trackCreateTaskInEmptyState();
    }
  };

  return (
    <EmptyTasksContainer className="tasks-empty" id={id}>
      <FixaText className="text-no-tasks" tagName="h2" textStyle="Heading.XS">
        {tasksTabType === TaskTabsTypes.MY
          ? t("noTasksAssignedYetString")
          : t("noOpenTasksString")}
      </FixaText>
      {!hideViewButton && (
        <FixaButton
          text={
            tasksTabType === TaskTabsTypes.MY
              ? t("viewOpenString")
              : t("createTaskString")
          }
          onClick={() => handleOnClick(tasksTabType)}
          type="primary"
          size="small"
          fluid={true}
        />
      )}
    </EmptyTasksContainer>
  );
};
