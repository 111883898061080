import FixaTabs, {
  FixaTab,
} from "@coworker/apprestructured/src/shared/wrappers/FixaTabs/FixaTabs";
import { MediaTypes } from "../constants";
import { getMediaTypeText } from "../tools";
import { useTranslation } from "@coworker/locales";
import { Media } from "../types/media";

interface MediasViewTabsProps {
  mediaTypesAndCount: Partial<Record<MediaTypes, number>>;
  totalCount: number;
  onTabChange: (tabId: string) => void;
  activeTab: string;
}

const MEDIA_TYPE_ORDER: MediaTypes[] = [
  MediaTypes.HOME,
  MediaTypes.ROOM_SETTINGS,
  MediaTypes.COORDINATION_MEDIA,
  MediaTypes.VIGNETTE,
  MediaTypes.COMPACT,
];

export const countMediaTypes = (
  medias: Media[]
): Partial<Record<MediaTypes, number>> => {
  return medias.reduce(
    (acc, media) => {
      const mediaType = media.mediaType as MediaTypes;
      acc[mediaType] = (acc[mediaType] ?? 0) + 1;
      return acc;
    },
    {} as Partial<Record<MediaTypes, number>>
  );
};

export const MediasViewTabs = ({
  mediaTypesAndCount,
  totalCount,
  onTabChange,
  activeTab,
}: MediasViewTabsProps) => {
  const { t } = useTranslation();

  const handleTabChange = (tabId: string) => {
    onTabChange(tabId);
  };

  const mediaTabs = [
    <FixaTab
      key={"allMedia"}
      text={`${t("allMediaString")} (${totalCount})`}
      tabPanelId={"allMedia"}
      data-test-id={"media-tab-all"}
    />,
    ...MEDIA_TYPE_ORDER.filter((type) => mediaTypesAndCount[type]).map(
      (mediaType) => (
        <FixaTab
          key={mediaType}
          text={`${getMediaTypeText(mediaType)} (${
            mediaTypesAndCount[mediaType]
          })`}
          tabPanelId={mediaType}
          data-test-id={`media-tab-${mediaType}`}
        />
      )
    ),
  ];

  return (
    <FixaTabs
      tabs={mediaTabs}
      onTabChanged={handleTabChange}
      activeTab={activeTab}
    />
  );
};
