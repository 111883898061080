import React from "react";
import Loading from "@ingka/loading";
import "@ingka/loading/dist/style.css";

type FixaLoadingProps = {
  text: string;
  children?: React.ReactNode;
};

const FixaLoading: React.FC<FixaLoadingProps> = (props: FixaLoadingProps) => {
  return <Loading {...props} />;
};

export default FixaLoading;