import styled, { css } from "styled-components";

export const Container = styled('div')`
  padding: 26px 28px;
  border-bottom: 1px solid var(--grey150);
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      touch-action: none;
    `}
`;
