import { getCountries } from "../../hooks/API/core.service";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "../../constants/clientTime";
import { startSpan } from "@sentry/react";

export type Country = {
  createdAt?: string;
  isoCode: string;
  name: string;
};

export function useCountries() {
  return useQuery<Country[]>({
    queryKey: ["countries"],
    queryFn: async () => {
      const countries = startSpan({ name: "core.getCountries" }, async () => {
        return await getCountries() ?? [];
      });
      return countries;
    },
    staleTime: 2 * INTERVAL.HOUR,
  });
}
