// Function to convert full-width characters to half-width characters
// There are Sentry errors containing full-width numbers (Japanese / Korean / Chinese) as the itemNo,
// which gets caught in the validation check.
export function toHalfWidth(string: string): string {
  return string
    .replace(/[！-～]/g, function (ch) {
      return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
    })
    .replace(/　/g, " ");
}

export function containsFullWidthCharacters(string: string): boolean {
  return /[！-～]/.test(string) || /　/.test(string);
}
