import { useTranslation } from "@coworker/locales";
import { Media } from "../types/media";
import { AggregatedArticlesTab } from "./AggregatedArticlesTab";
import { GroupedArticleListing } from "./GroupedArticleListing";

import { ArticleIdNbrArticles, Articles } from "../types/article";
import { Groups } from "../types/groups";
import { TabIds } from "./MediaTabs.helper";
import "../styles/media-tabs.css";
import styled from "styled-components";
import FixaTabs, {
  FixaTab,
  FixaTabPanel,
} from "@coworker/apprestructured/src/shared/wrappers/FixaTabs/FixaTabs";

interface MediaTabsProps {
  media: Media;
  activeArticles: Articles | undefined;
  isSelectArticlesMode: boolean;
  getSelectedArticleIds: (
    fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[]
  ) => void;
  setSelectArticleMode: () => void;
  allArticlesInMedia: Articles;
  onCurrentTabIdChange: (tab: string) => void;
  groups: Groups | undefined;
  currentTabId: string;
}

const StyledTabs = styled(FixaTabs)`
  .tabs__panel {
    padding: 16px 0;
  }
  .overflow-carousel {
    margin-left: 16px;
    margin-right: 16px;
  }
  .tabs__list::after {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const MediaTabs = ({
  media,
  activeArticles,
  isSelectArticlesMode,
  getSelectedArticleIds,
  setSelectArticleMode,
  onCurrentTabIdChange,
  groups,
  currentTabId,
}: MediaTabsProps) => {
  const { t } = useTranslation();

  return (
    <StyledTabs
      defaultActiveTab={currentTabId}
      className="media-tabs-padding"
      tabs={[
        <FixaTab
          key={TabIds.LIST}
          tabPanelId={TabIds.LIST}
          text={t("articleListString")}
        />,
        <FixaTab
          key={TabIds.GROUP}
          tabPanelId={TabIds.GROUP}
          text={t("groupsString")}
        />,
      ]}
      onTabChanged={(tab: string) => onCurrentTabIdChange(tab)}
      tabPanels={[
        <FixaTabPanel key={"article-list-tab-panel"} tabPanelId={TabIds.LIST}>
          <AggregatedArticlesTab
            articles={activeArticles}
            media={media}
            groups={groups}
            isSelectArticlesMode={isSelectArticlesMode}
            getSelectedArticleIds={getSelectedArticleIds}
            setSelectArticleMode={setSelectArticleMode}
          />
        </FixaTabPanel>,

        <FixaTabPanel key={"groups-tab-panel"} tabPanelId={TabIds.GROUP}>
          <GroupedArticleListing
            articles={activeArticles}
            media={media}
            groups={groups}
          />
        </FixaTabPanel>,
      ]}
    />
  );
};
