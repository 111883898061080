import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { NativeNumberKeyPad } from "./NativeNumberKeyPad";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

const Container = styled('div')`
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 10px;
  overflow-y: auto;
`;
const Title = styled('h1')`
  font-size: 28px;
  font-weight: bold;
  color: var(--grey900);
  margin: 55px 29px;
  margin-bottom: 0px;
`;

const InputWrapper = styled('div')`
  margin: 10px 40px 10px 29px;
`;

const CapInfo = styled('div')`
  font-size: 12px;
  color: var(--grey600);
  margin-bottom: 22px;
`;

const Number = ({ value, bigTitle, instruction, onSubmit, maxLength = 11 }) => {
  const [chosen, setChosen] = React.useState(value || "");
  const containerRef = React.useRef(null);

  const CapText = () => {
    if (chosen.length >= maxLength) {
      return <Trans>noMoreDigitsCanBeEntered</Trans>;
    } else {
      return <wbr></wbr>;
    }
  };

  React.useEffect(() => {
    const sanitizedValue = chosen.replace(/[^0-9]/g, "");
    if (sanitizedValue !== chosen) {
      setChosen(sanitizedValue);
    }
  }, [chosen]);  

  return (
    <Container ref={containerRef} data-testid="dateStampModal">
      <Title>{bigTitle}</Title>
      <InputWrapper>
        <NativeNumberKeyPad
          disabled={false}
          initialValue={chosen}
          inputMode="numeric"
          keypadType="number"
          onUpdate={(value) => setChosen(value)}
          onComplete={() => {
            onSubmit(chosen);
          }}
          maxLength={maxLength}
        />
        <CapInfo>
          <CapText />
        </CapInfo>
        <FixaButton
          onClick={() => {
            onSubmit(chosen);
          }}
          type="primary"
          fluid
        >
          <Trans>submitString</Trans>
        </FixaButton>
      </InputWrapper>
      {instruction}
    </Container>
  );
};

export default Number;
