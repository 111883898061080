import React from "react";
import styled from "styled-components";
import Note from "./shared/NoteInput";

const Optional = styled('div')`
  font-size: 14px;
  color: var(--grey500);
  position: absolute;
  top: 36px;
  right: 29px;
`;

function TextNote({
  onSubmit,
  initialValue,
  optional,
}: {
  onSubmit: (value: string) => void;
  initialValue?: string;
  optional?: string;
}) {
  const [value, setValue] = React.useState(initialValue || "");

  return (
    <Note
      onAddNote={(event) => {
        event.preventDefault();
        onSubmit(value);
      }}
      value={value}
      onValueChanged={(event) => setValue(event.target.value)}
    >
      {optional && !value && <Optional>{optional}</Optional>}
    </Note>
  );
}

export default TextNote;
