import { AlertResponse } from "../types/media";
import { TaskLinks } from "../types/taskLink";

export const getOngoingTaskCount = (tasks: TaskLinks, mediaId: string) => {
  if (tasks) {
    return tasks.filter((task) => task.mediaId === mediaId).length;
  }

  return 0;
};

export const getEDSAlertCount = (alerts: AlertResponse[], mediaId: string) => {
  if (Array.isArray(alerts)) {
    const edsAlertForMedia = alerts.find(
      (alert, _index) => alert.mediaId === mediaId
    );

    if (edsAlertForMedia) {
      return edsAlertForMedia.eds;
    }
  }
  return 0;
};

export const getSalesStopAlertCount = (
  alerts: AlertResponse[],
  mediaId: string
) => {
  if (Array.isArray(alerts)) {
    const salesStopAlertForMedia = alerts.find(
      (alert) => alert.mediaId === mediaId
    );

    if (salesStopAlertForMedia) {
      return salesStopAlertForMedia.salesStop;
    }
  }
  return 0;
};

export const getMediaMetaInfo = (
  ongoingTaskCount: number,
  edsAlertCount: number,
  salesStopAlertCount: number,
  t: (key: string) => string
) => {
  let result = "";
  if (ongoingTaskCount > 0)
    result += `${ongoingTaskCount} ${t("ongoingString").toLowerCase()}`;

  if (edsAlertCount > 0) {
    if (result) result += ", ";
    result += `${edsAlertCount} EDS`;
  }

  if (salesStopAlertCount > 0) {
    if (result) result += ", ";
    result += `${salesStopAlertCount} ${t("salesStopString")}`;
  }
  return result;
};
