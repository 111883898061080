import React from "react";
import FixaInlineMessage from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import FixaLink from "@coworker/apprestructured/src/shared/wrappers/FixaLink/FixaLink";
import styles from "./DesktopInsightsPage.module.css";
import { useTranslation } from "@coworker/locales";

const DesktopInsightsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles["desktop-insights-message"]}>
        <FixaLink
          to="https://fixadashboard.ingka.com"
          className="desktop-insights-message"
        >
          <FixaInlineMessage
            title={t("addonInsightsDesktopTitleString")}
            variant="informative"
            body={t("addonInsightsDesktopBodyString")}
          ></FixaInlineMessage>
        </FixaLink>
      </div>
    </>
  );
};

export default DesktopInsightsPage;
