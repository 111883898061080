import preferences from "@coworker/enums/profilePreferences";
import { useUserPreferenceStoreFilterValues } from "@coworker/apprestructured/src/shared/filters/utils";
import { useTaskTypeFilter } from "./useTaskTypeFilter";
import { useLocationFilter } from "./useLocationFilter";
import { usePeriodFilter } from "./usePeriodFilter";
import { useAssigneeOrCreatorFilter } from "./useAssigneeOrCreatorFilter";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import {
  useMyTeam,
  useStoreTeams,
} from "@coworker/app/src/core/hooks/useStoreTeams";
import { useSortByFilter } from "./useSortByFilter";
import { usePriorityOnlyFilter } from "./usePriorityOnlyFilter";
import { useMyOrTeamTasksFilter } from "./useMyOrTeamTasksFilter";
import { FilterValue } from "../../shared/filters/types";

export function useActivityFilters() {
  const { filterValues, setFilterValues } = useUserPreferenceStoreFilterValues(
    preferences.ACTIVITY_FILTERS
  );

  const fixaUid = useFixaUID();
  const teams = useStoreTeams();
  const myTeam = useMyTeam();

  const useActivityDataFilters = (values: FilterValue[]) => [
    useMyOrTeamTasksFilter("myTasks", fixaUid, "myString", values),
    useMyOrTeamTasksFilter(
      "teamTasks",
      myTeam?.id ?? "",
      "teamTasksString",
      values
    ),
    useAssigneeOrCreatorFilter(
      "assignedTo",
      fixaUid,
      "assignedToTaskFilterString",
      teams
    ),
    useAssigneeOrCreatorFilter("createdBy", fixaUid, "createdByString", teams),
    usePriorityOnlyFilter(values),
    useTaskTypeFilter(),
    useLocationFilter(values),
    usePeriodFilter(),
    useSortByFilter(values),
  ];

  return {
    useFiltersFactory: useActivityDataFilters,
    filterValues,
    setFilterValues,
  };
}
