import { useQuery } from "@tanstack/react-query";
import { getMediaById } from "../services/medias.service";
import { QueryKeys } from "./queryKeys";

export const useMediaById = (mediaId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.Media, mediaId],
    queryFn: () => getMediaById(mediaId || ""),
    enabled: !!mediaId,
  });
};
