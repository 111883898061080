import { Button, Icon } from "@coworker/components";
import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import trackerHelper from "../../helpers/tracker";
import { isChineseEnvironment, isRDTDevice11 } from "@coworker/reusable";
import { HiddenInput } from "./RDTScannerHiddenInput";
import { useItemInfoAndPriceSR } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfoAndPriceSR";
import { SimpleLoadingBall } from "@coworker/apprestructured/src/shared/simple/SimpleLoading/SimpleLoading";
import { reportMessageToSentry } from "../../hooks/useConfigureSentry";

const {
  barcode: { logScannerType },
} = trackerHelper;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
`;

const ReadyToScan = styled('div')`
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
`;

const Text = styled('h2')`
  color: black;
  font-size: 18px;
`;

const Container = styled('div')`
  background: white;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  ${({ bottomToolbar }) => bottomToolbar && `border-bottom: 90px solid black;`}
`;

const Spacer = styled('div')`
  height: 5px;
`;

const LoadingContainer = styled('div')`
  align-content: center;
  align-items: center;
  flex-grow: 1;
`;

export default function RDTScanner({
  switchToCameraScanner,
  onBarcode,
  hasBottomToolbar = false,
}) {
  const { t } = useTranslation();
  const [itemNo, setItemNo] = React.useState("");
  const [supplierNumber, setSupplierNumber] = React.useState("");
  const { data: itemInfo, isLoading } = useItemInfoAndPriceSR(itemNo);
  const isNewRDT = isRDTDevice11();
  const [scannerText, setScannerText] = React.useState("");

  React.useEffect(() => {
    if (isNewRDT) {
      if (itemNo && !isLoading) {
        if (isChineseEnvironment()) {
          reportMessageToSentry(`After fetching item info`, {
            level: "warning",
            extra: {
              itemNo: itemNo,
              itemInfo: itemInfo,
            },
          });
        }
        onBarcode(
          itemNo,
          undefined,
          itemInfo,
          supplierNumber,
          isLoading
        );
        setScannerText("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, itemInfo, itemNo]);

  React.useEffect(() => {
    if (isNewRDT) {
      const shortId = scannerText.substring(0, 8);
      const supplierNumber = scannerText.substring(8);

      if (shortId?.length === 8) {
        trackerHelper.barcode.logRDTCaptured(scannerText, shortId);
        setItemNo(shortId);
        setSupplierNumber(supplierNumber);
        logScannerType("RDT_SCANNER", shortId);
      }
    }
    // Disabling this ONLY for onBarcode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannerText]);

  return (
    <Container bottomToolbar={hasBottomToolbar}>
      <Spacer />
      {isLoading && (
        <LoadingContainer>
          <SimpleLoadingBall
            text={t("fetchingItemInfoString")}
            size={"large"}
          />
        </LoadingContainer>
      )}
      {!isLoading && (
        <React.Fragment>
          <ReadyToScan>
            <Icon family="icons" name="rdt_scanner" color="grey900" size="64" />
            <Text>
              <Trans>readyToScanString</Trans>
            </Text>
          </ReadyToScan>
          <ButtonContainer>
            <Button
              primary
              dark
              text={<Icon family="icons" name="camera" color="white" />}
              onClick={switchToCameraScanner}
              flexNoGrow
            />
            <Button
              primary
              dark
              data-testid="pushToScan"
              flexGrow
              text={
                isRDTDevice11() ? (
                  <Trans>pleaseUseSideButtonScanString</Trans>
                ) : (
                  <Trans>scanString</Trans>
                )
              }
              disabled
            />
            {!isRDTDevice11() && (
              <Button
                primary
                dark
                flexNoGrow
                disabled
                text={
                  <Icon family="actions" name="torch-off" color="grey500" />
                }
              />
            )}
          </ButtonContainer>
          <HiddenInput setScannerText={setScannerText} />
        </React.Fragment>
      )}
    </Container>
  );
}
