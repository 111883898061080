import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";

async function reassignAllTasksToMyTeam(uid: string) {
  const url = `${TASKS_SERVICE_URL}/v1/tasks/store_switcher`;
  return await fetchAPI("PUT", url, { uid });
}

export const useStoreSwitcher = (uid: string, updateUser: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => reassignAllTasksToMyTeam(uid),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["activity-tasks"] });
      updateUser();
    },
  });
};
