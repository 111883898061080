import {
  FilterType,
  SelectFilter,
  SelectOption,
  StringFilterValue,
} from "../../types";
import { SelectListView } from "../SelectListView";
import { FixaAccordionItem } from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";
import AccordionSelectlCss from "./AccordionSelect.module.css";
import { useTranslation } from "@coworker/locales";

interface AccordionSelectProps {
  filter: SelectFilter;
  selected: StringFilterValue;
  onChange: (option: SelectOption) => void;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const AccordionSelect = ({
  filter,
  selected,
  onChange,
  open,
  onOpenChange,
}: AccordionSelectProps) => {
  const { id, title } = filter;
  const { t } = useTranslation();

  return (
    <FixaAccordionItem
      id={`filter-accordion-${id}`}
      data-testid={`filter-accordion-${id}`}
      title={
        filter.filterType === FilterType.Sort ? t("sortByTitleString") : title
      }
      className={AccordionSelectlCss["accordion-item-title"] ?? ""}
      open={!!open}
      onTransitionEnd={(_event, isOpen) => {
        onOpenChange(isOpen);
      }}
    >
      <SelectListView
        prefix="accordion"
        filter={filter}
        selected={selected}
        onChange={onChange}
      />
    </FixaAccordionItem>
  );
};
