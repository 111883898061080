import { sign } from "@coworker/reusable/helpers/math";
import { UserRoles } from "@coworker/apprestructured/src/shared/enums/user/userRoles";

function isNotSuperuser(user) {
  return user.roleId !== UserRoles.SUPERUSER;
}

function isStoreSuperuser(user) {
  return user && user.roleId === UserRoles.STOREOWNER;
}

function isCountrySuperuser(user) {
  return user && user.roleId === UserRoles.COUNTRY_SUPERUSER;
}

function belongsToTeam(user, teamId) {
  return user && user.teamId === teamId;
}

const userSortSequence = {
  [UserRoles.SUPERUSER]: 0,
  [UserRoles.COUNTRY_SUPERUSER]: 1,
  [UserRoles.STOREOWNER]: 2,
  [UserRoles.COWORKER]: 3,
};

function getRoleSequence(role) {
  return userSortSequence[role] || 1000;
}

function sortUsersByRoleAndName(user1, user2) {
  if (user1.roleId === user2.roleId) {
    return sortUsersByName(user1, user2);
  }
  return sign(getRoleSequence(user1.roleId) - getRoleSequence(user2.roleId));
}

function sortUsersByName(user1, user2) {
  return user1.fullName &&
    user2.fullName &&
    user1.fullName[0] < user2.fullName[0]
    ? -1
    : 1;
}

export const filterAndSortTeamMembers = (users, teamId) => {
  return Object.values(users)
    .filter((u) => belongsToTeam(u, teamId) && isNotSuperuser(u))
    .sort(sortUsersByRoleAndName);
};

export const sortStoreSuperUsers = (users) =>
  Object.values(users).filter(isStoreSuperuser).sort(sortUsersByName);

export const sortCountrySuperUsers = (users) =>
  Object.values(users).filter(isCountrySuperuser).sort(sortUsersByName);
