import { forwardRef } from "react";

import { LinkbarItem } from "./Linkbar";
import Home from "./Home";
import Current from "./Current";
import Parent from "./Parent";
import styles from "./Linkbar.module.css";

const LinkbarExpanded = forwardRef<
  HTMLDivElement,
  {
    items?: LinkbarItem[];
    currentLabel: string;
    currentAsH1?: boolean;
    "data-testid"?: string | undefined;
  }
>(
  (
    {
      items = [],
      currentLabel,
      currentAsH1 = false,
      "data-testid": dataTestId,
    },
    ref
  ) => {
    return (
      <div
        data-testid={dataTestId}
        className={styles["linkbar-expanded"]}
        ref={ref}
      >
        <Home />
        <div className={styles["linkbar-expanded-inner"]}>
          {items.map(({ label, to }, i) => {
            return <Parent key={i} label={label} to={to} />;
          })}
          <Current label={currentLabel} asH1={currentAsH1} />
        </div>
      </div>
    );
  }
);

export default LinkbarExpanded;
