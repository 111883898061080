// Vendor
import React from "react";
import { Trans } from "@coworker/locales";
import useFlag, { FLAGS } from "../../../hooks/useFlag";
// Enums
import {
  splitLocation,
  useTaskFormDefaultPickupFields,
} from "../../../services/locations.service";
import {
  BOOLEAN_FLAG,
  DIVIDER,
  LOCATION,
  PRODUCT_WITH_PILLS,
  RELATED_TASKS,
  PHOTOS,
  ASSIGN,
  DESCRIPTION,
  AMOUNT,
  REFILL_TYPE,
} from "../widgets";
import { ReactComponent as CheckmarkCircle } from "../../../assets/svg/CheckmarkCircle.svg";
import { ReactComponent as NoteIcon } from "../../../assets/svg/Note.svg";
import { ReactComponent as Person } from "../../../assets/svg/Person.svg";
import { ReactComponent as Refilling } from "@coworker/reusable/svg/refilling.svg";
import { ReactComponent as PriorityIcon } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { useInputPopup } from "../../InputPopup";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../../hooks/useWorkspaces";
import {
  LOCATION_TYPE_PICKUP,
  LOCATION_TYPE_REFILL,
} from "../../../constants/locationWidgetTypes";
import trackerHelper from "../../../helpers/tracker";
import {
  getLocationPopup,
  getAmountPopup,
  getAssigneesPopup,
} from "../common/popup.helpers";
import {
  locationMapper,
  assigneeMapper,
  refillTypeMapper,
} from "../common/fieldMappers.helpers";
import { useAvailableElevatedStock } from "../../../hooks/useAvailableElevatedStock";
import tracker from "../../../helpers/tracker";
import {
  StyledInformationCircle,
  ProductIssueLinkContainer,
  ProductIssueLink,
} from "../../InputPopup/CommonComponents";
import issueTypeOptions from "@coworker/enums/issueTypes";
import useDuplicateTaskPopup from "../../InputPopup/shared/useDuplicateTaskPopup";
import { useNearbyProducts } from "@coworker/apprestructured/src/shared/hooks/useNearbyProducts";
import { TaskType } from "@coworker/apprestructured/src/tasks/enums/taskTypes";

const addonSingleTaskType = {
  table: "tasks",
  type: "addToOrder",
  enumType: TaskType.ADDON,
  createCurtainMessage: <Trans>taskCreatedString</Trans>,
  validate: (form) =>
    [
      form.product_article_id,
      form.assigned_team_id || form.refilled_by_me,
      form.location || form.location_custom,
      form.pickup_location || form.pickup_location_custom,
      form.requested_quantity,
      !form.images?.some((image) => image.loading),
    ].every((x) => x),
  fieldsFilledCount: (form) => {
    let count = 0;
    if (form.requested_quantity) count++;
    if (form.description) count++;
    if (form.priority_flag) count++;
    if (form.images && form.images.length > 0) count++; // Do not care if the image is loading. User has interacted with the field if length > 0
    return count;
  },

  usePostCreate: function ({ form }) {
    const { push } = useWorkspacesAction();
    const disableNearby = useFlag(FLAGS.DISABLE_NEARBY);
    const { data: nearbyProducts, isLoading: nearbyProductsLoading } =
      useNearbyProducts(!disableNearby ? form.location : "");

    const postCreate = React.useCallback(() => {
      if (
        !nearbyProductsLoading &&
        nearbyProducts?.length > 0 &&
        !disableNearby
      ) {
        push("/task/new/addon/nearby", { form });
        return false;
      }
      return true;
    }, [nearbyProducts, nearbyProductsLoading, push, form, disableNearby]);
    return postCreate;
  },
  useBeforeCreate: function ({ form }) {
    const { getInput } = useInputPopup();
    const { navigationState } = useWorkspacesState();
    const { pop, replace } = useWorkspacesAction();

    return React.useCallback(async () => {
      if (!navigationState.isProductOutOfStock) return true;

      const createNewProductIssue = function () {
        replace("/task/new/product", {
          product: {
            product: form.product_article_id,
            issue_type: issueTypeOptions.OUT_OF_STOCK,
          },
          location: form.location,
          isSelectProductIssueType: true,
        });
        tracker.taskEfficiency.trackCreateOutOfStockProductIssue();
      };

      tracker.taskEfficiency.trackWarnOutOfStock();
      const response = await getInput("confirmation", {
        question: <Trans>outOfStockWarningTitle</Trans>,
        description: (
          <>
            <Trans>outOfStockWarningDescriptionString</Trans>
            <ProductIssueLinkContainer>
              <StyledInformationCircle />
              <ProductIssueLink onClick={createNewProductIssue}>
                <span>
                  <Trans>createProductIsuueString</Trans>
                </span>
              </ProductIssueLink>
            </ProductIssueLinkContainer>
          </>
        ),
        positiveText: <Trans>continue2String</Trans>,
        negativeText: <Trans>cancelString</Trans>,
        swapButtons: true,
      });
      if (response) {
        return true;
      } else if (response === false) {
        pop();
      }
      //Ignore all other dependecies, only navigationState is required.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationState]);
  },
  useReactToChanges: function (taskId, task, onChange) {
    const { navigationState } = useWorkspacesState();
    const { setNavigationState, pop } = useWorkspacesAction();
    const { getInput } = useInputPopup();
    const { available, loading } = useAvailableElevatedStock(
      task?.product_article_type + task?.product_article_id
    );
    const assignedTeamRef = React.useRef(null);
    const requestedQuantityRef = React.useRef(null);
    const { getDuplicateTaskConfirmation } = useDuplicateTaskPopup();

    React.useEffect(() => {
      if (task.assigned_team_id) {
        assignedTeamRef.current = task.assigned_team_id;
      }
      if (task.requested_quantity) {
        requestedQuantityRef.current = task.requested_quantity;
      }
    }, [task.assigned_team_id, task.requested_quantity]);

    const isNewTask = !taskId;
    const hasNoLocation = !task.pickup_location && !task.pickup_location_custom;
    const isNewAddonTask = isNewTask && hasNoLocation;

    const pickupFields = useTaskFormDefaultPickupFields(
      isNewAddonTask ? task : null
    );
    React.useEffect(() => {
      if (pickupFields) {
        onChange(pickupFields); // Set a default pickup location when creating a new task
      }
      // Ignored: onChange
      // eslint-disable-next-line
    }, [pickupFields]);

    const { product_article_id, product_article_type, panumber } = task || {};

    const isProductOutOfStock = !loading && available === 0;
    React.useEffect(() => {
      if (isProductOutOfStock && !navigationState.isProductOutOfStock) {
        setNavigationState({ isProductOutOfStock });
      }
      // Ignored deps: navigationState, isProductOutOfStock, setNavigationState.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const isFoodArticle =
      panumber?.substring(0, 2) === "60" || panumber?.substring(0, 2) === "61";

    React.useEffect(() => {
      async function addonFlow() {
        let maxAmount;
        if (!task.location) {
          const locationResults = await getLocationPopup({
            init: true,
            getInput,
            onChange,
            addon: LOCATION_TYPE_REFILL,
            product_article_id,
            product_article_type,
            isFoodArticle,
          });
          maxAmount = locationResults.locationQuantity;

          if (!locationResults.submitted) return;
          const { relatedOnThisLocation, locationCode } = locationResults;
          if (relatedOnThisLocation > 0) {
            tracker.taskEfficiency.trackWarnDuplicate();
            const warningResponse = await getDuplicateTaskConfirmation(
              relatedOnThisLocation,
              product_article_id,
              locationCode
            );
            if (!warningResponse) {
              if (warningResponse === false) pop();
              return;
            }
          }
        }

        if (!requestedQuantityRef.current) {
          const amount = await getAmountPopup({
            init: true,
            getInput,
            onChange,
            maxAmount,
            available,
          });
          if (!amount) return;
        }

        let wantsToReassign;
        if (assignedTeamRef.current) {
          wantsToReassign = await getInput("confirm_assignment", {
            teamId: assignedTeamRef.current,
          });
        }

        if (!assignedTeamRef.current || wantsToReassign) {
          await getAssigneesPopup({
            init: true,
            getInput,
            onChange,
            shortId: product_article_id,
          });
        }
      }

      if (
        navigationState.showAddOnFlow === false ||
        !product_article_id ||
        !product_article_type
      ) {
        return;
      }

      addonFlow();

      // Ignored deps: getInput, navigationState, onChange, and setNavigationState.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product_article_id, product_article_type, isFoodArticle]);
  },
  header: {
    newTitle: <Trans>addonOrderString</Trans>,
    editTitle: <Trans>editTaskString</Trans>,
    alwaysSticky: true,
    hideTallHeader: true,
  },
  fields: [
    {
      type: PRODUCT_WITH_PILLS,
      values: {
        in: ({
          product_article_id: productId,
          product_article_type: productType,
        }) => {
          return { productId, productType };
        },
        // This out section looks like it's not used?
        out: (
          {
            productId: product_article_id,
            articleType: product_article_type,
            name: item_name,
            type: item_type,
            color: item_color,
            item,
            panumber,
          },
          task
        ) => ({
          product_article_id,
          product_article_type,
          item_name,
          item_type,
          item_color,
          item,
          source: console.warn("This never runs?") || "not-ever",
          panumber:
            panumber ||
            (product_article_id === task?.product_article_id &&
              task?.panumber) ||
            "",
        }),
      },
    },
    { type: RELATED_TASKS },
    { type: DIVIDER, hide: (_, { refilled_by_me }) => refilled_by_me },
    {
      type: LOCATION,
      conf: {
        optional: null,
        locationString: <Trans>refillLocString</Trans>,
        addon: LOCATION_TYPE_REFILL,
        popupName: "addonLocation",
        icon: <Refilling />,
        deactivated: false,
        overridePadding: "0 18px 0 24px;",
        newAddonLocation: true,
      },
      values: {
        in: ({
          location,
          location_custom,
          requested_quantity, // used to check if a user added the amount before the location
          actual_requested_quantity,
          requested_type,
          product_article_id,
          product_article_type,
          home_location,
          primary_location,
          panumber,
        }) => {
          const [code, department] = splitLocation(
            (location || "").replace(/%26/g, "&").replace(/%2F/g, "/")
          );

          return {
            code,
            department,
            custom: location_custom,
            requestedAmount: requested_quantity,
            actualRequestedAmount: actual_requested_quantity,
            requestedAmountType: requested_type,
            articleId: product_article_id,
            articleType: product_article_type,
            homeLocation: home_location,
            primaryLocation: primary_location,
            panumber,
          };
        },
        out: locationMapper,
      },
    },
    {
      type: REFILL_TYPE,
      conf: {
        optional: null,
        popupName: "refillType",
        deactivated: false,
        overridePadding: "24px",
      },
      values: {
        in: ({ refill_type: refillType }) => ({
          refillType,
        }),
        out: refillTypeMapper,
      },
    },
    {
      type: AMOUNT,
      conf: {
        deactivated: false,
        overridePadding: "24px",
      },
      values: {
        in: ({
          requested_quantity: quantity,
          actual_requested_quantity: actualQuantity,
          requested_type: type,
          product_article_id: productId,
          product_article_type: articleType,
          location,
        }) => ({
          quantity,
          actualQuantity,
          type,
          productId,
          location: splitLocation(location)[0],
          articleType,
        }),
        out: ({
          amount: requested_quantity,
          actualAmount: actual_requested_quantity,
          amountType: requested_type,
        }) => ({
          requested_quantity,
          actual_requested_quantity,
          requested_type,
        }),
      },
    },
    {
      type: ASSIGN,
      conf: {
        icon: <Person width={"30px"} />,
        deactivated: false,
        overridePadding: "0 24px",
        testId: "addonSingleAssign",
      },
      values: {
        in: ({
          assigned_team_id: gid,
          assigned_user_id: uid,
          refilled_by_me,
          product_article_id,
        }) => ({
          gid,
          uid,
          refilled_by_me,
          productId: product_article_id,
        }),
        out: assigneeMapper,
      },
    },
    {
      type: BOOLEAN_FLAG,
      conf: {
        title: <Trans>priorityString</Trans>,
        icon: <PriorityIcon />,
        testId: "priorityCheckbox",
        overridePadding: "0 24px",
        titleMargin: "14px",
      },
      values: {
        in: ({ priority_flag }) => priority_flag,
        out: (priority_flag) => ({ priority_flag }),
      },
    },
    {
      type: BOOLEAN_FLAG,
      conf: {
        title: <Trans>markAsCompleteString</Trans>,
        icon: <CheckmarkCircle />,
        testId: "markedAsComplete",
        overridePadding: "0 24px",
        titleMargin: "14px",
      },
      values: {
        in: ({ refilled_by_me }) => refilled_by_me,
        out: (refilled_by_me) => {
          trackerHelper.task.trackMarkAsCompleteToggle(refilled_by_me);
          return !refilled_by_me
            ? { refilled_by_me, refilled_quantity: null, pick_quantity: null }
            : { refilled_by_me };
        },
      },
    },
    {
      type: LOCATION,
      conf: {
        optional: null,
        locationString: <Trans>pickupLocation</Trans>,
        addon: LOCATION_TYPE_PICKUP,
        popupName: "addonLocation",
        overridePadding: "0 18px 0 24px",
        newAddonLocation: true,
      },
      hide: (_, { picked }) => picked,
      values: {
        in: ({
          pickup_location,
          pickup_location_custom,
          product_article_id,
          product_article_type,
          panumber,
          home_location,
          primary_location,
        }) => {
          const [code, department] = splitLocation(pickup_location);

          return {
            code,
            department,
            custom: pickup_location_custom,
            articleId: product_article_id,
            articleType: product_article_type,
            panumber,
            homeLocation: home_location,
            primaryLocation: primary_location,
          };
        },
        out: ({
          code,
          department,
          custom,
          section,
          homeLocation,
          primaryLocation,
        }) => {
          let locationText = code || "";
          if (department || section)
            locationText += ` - ${department || section}`;

          let departmentText = "";
          if (department || section)
            departmentText = `${department || section}`;
          return {
            pickup_location: custom ? "" : locationText,
            pickup_location_grid_code: custom ? "" : code,
            pickup_location_department: custom ? "" : departmentText,
            pickup_location_custom: custom ? custom : "",
            home_location: homeLocation ?? "",
            primary_location: primaryLocation ?? "",
          };
        },
      },
    },
    {
      type: DESCRIPTION,
      conf: {
        icon: <NoteIcon />,
        lblColor: "var(--grey900)",
        overridePadding: "0px 16px 0px 24px",
      },
      values: {
        in: ({ description }) => description,
        out: (description) => ({ description }),
      },
    },
    {
      type: PHOTOS,
      conf: {
        lblColor: "var(--grey900)",
        overridePadding: "32px 16px 32px 24px",
      },
      values: {
        in: ({ images }) => images,
        out: (images) => ({ images }),
      },
    },
  ],
};

export default addonSingleTaskType;
