import { useQuery } from "@tanstack/react-query";
import { getMediasByStoreId } from "../services/medias.service";
import { QueryKeys } from "./queryKeys";

export const useMediasByStoreId = (storeId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.Medias, { id: storeId }],
    queryFn: () => getMediasByStoreId(storeId || ""),
    enabled: !!storeId,
  });
};
