import { useCallback } from "react";
import { useToastNotification } from "@coworker/components";
import useUndoneNotification from "../../components/useUndoneNotification";
import useCloseTaskReasonPopupMenu from "../../components/InputPopup/shared/useCloseTaskReason";
import taskStateOptions from "@coworker/enums/taskState";
import activityTypes from "@coworker/enums/activityTypes";
import taskTypeOptions from "@coworker/enums/taskType";
import trackerHelper from "../../helpers/tracker";
import { Trans } from "@coworker/locales";
import {
  useStoreId,
  useUserId,
  useFixaUID,
} from "../../core/auth/useLoggedInUser";
import { useUpdateTaskMutation } from "@coworker/apprestructured/src/tasks/hooks/useUpdateTaskById";
import { useQueryClient } from "@tanstack/react-query";

export const useUndoCloseTask = ({ task, afterUndo }) => {
  const undoCurtain = useUndoneNotification(task?.task_type);
  const uid = useUserId();

  const undoTask = useCallback(
    async (actionId) => {
      let data = {
        last_editor_id: uid,
        state: task.state,
        close_type: "",
        close_description: "",
        undo_action_id: actionId || "",
        completed_note: "",
        activity_type: activityTypes.UNDO_CLOSE,
      };

      if (task.task_type === taskTypeOptions.ADDON) {
        data = {
          ...data,
          task_type_detail: task.picked ? "REFILL" : "PICK_AND_REFILL",
          searchable_location:
            task.pickup_location_custom ?? task.pickup_location,
        };
      }

      await undoCurtain(data, task.id);
      afterUndo();
    },
    [afterUndo, task, uid, undoCurtain]
  );

  return {
    call: undoTask,
  };
};

export const useCloseTask = (task, taskId, config = {}) => {
  const { undo, afterUndo = () => { } } = config;
  const uid = useUserId();
  const fixaUid = useFixaUID();
  const storeId = useStoreId();
  const { showToastWithUndo } = useToastNotification();
  const { getCloseTaskPopup } = useCloseTaskReasonPopupMenu();
  const { call: undoTask } = useUndoCloseTask({
    task: { ...task, id: taskId },
    afterUndo,
  });

  const queryClient = useQueryClient();
  const updateTask = useUpdateTaskMutation();

  const closeTask = useCallback(async () => {
    const actionId = `close-${new Date().getTime()}`;
    const response = await getCloseTaskPopup();

    if (!response) return;

    let updateData = {
      last_editor_id: uid,
      state: taskStateOptions.CLOSED,
      close_type: response.issue,
      close_description: response.description,
      action_id: actionId,
      activity_type: activityTypes.CLOSE,
      // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
      undo_action_id: "",
      ...(task.task_type === taskTypeOptions.ADDON
        ? { refilled_quantity: 0, refilled_type: task.refilled_type }
        : {}),
      // onUpdate trigger takes care of deleting undo_action_id
    };

    if (task.task_type_detail === "PICK_AND_REFILL") {
      updateData = {
        ...updateData,
        task_type_detail: "REFILL",
        searchable_location: task.location,
      };
    }
    updateData = {
      ...updateData,
      team_id: task.assigned_team_id,
      store_id: storeId,
      assigned_user_id: task.assigned_user_id,
      uid: fixaUid,
    };

    await updateTask.mutateAsync({ data: updateData, taskId });

    await queryClient.invalidateQueries({
      queryKey: ["activityTasks", "inProgressTasks"],
    });
    await queryClient.invalidateQueries({
      queryKey: ["activityTasks", "closedTasks"],
    });

    trackerHelper.trackCloseTask(task, response.issue);

    if (!undo) return;

    showToastWithUndo(<Trans>youClosedString</Trans>, () => undoTask(actionId));

    return true;
  }, [
    queryClient,
    getCloseTaskPopup,
    uid,
    task,
    storeId,
    fixaUid,
    updateTask,
    taskId,
    undo,
    showToastWithUndo,
    undoTask,
  ]);

  return {
    call: closeTask,
  };
};

export const useRejectPQReport = (task, taskId) => {
  const uid = useUserId();
  const updateTask = useUpdateTaskMutation();
  const { showToast } = useToastNotification();
  const queryClient = useQueryClient();
  const closeTask = useCallback(
    async (completed_note) => {
      const updateData = {
        last_editor_id: uid,
        state: taskStateOptions.CLOSED,
        action_id: `close-${new Date().getTime()}`,
        activity_type: activityTypes.CLOSE,
        // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend
        // change has not propagated to the frontend we must make sure to also signal this from
        // the frontend!
        undo_action_id: "",
        ...(task.task_type === taskTypeOptions.ADDON
          ? { refilled_quantity: 0 }
          : {}),
        completed_note,
      };

      const result = await updateTask.mutateAsync({ data: updateData, taskId });

      const resultId = result?.id;
      if (resultId === taskId) {
        showToast(<Trans>youRejectedTheTask</Trans>);
        await queryClient.invalidateQueries({
          queryKey: ["activityTasks", "inProgressTasks"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["activityTasks", "closedTasks"],
        });

        return true;
      } else {
        showToast(<Trans>searchErrorString</Trans>); // "Oops! Something went wrong." -- most likely only showing up if device is offline but didn't yet switch to the offline view in Fixa.
        return false;
      }
    },
    [taskId, uid, task, showToast, updateTask, queryClient]
  );

  return closeTask;
};
