import { useState } from "react";
import { FilterRowMobile } from "./FilterRowMobile";
import { FilterModal } from "./FilterModal";
import { Filter, FilterValue } from "../types";
import { getDefaultFilterValues } from "../utils/filter";

interface FilterPanelMobileProps {
  useFiltersFactory: (
    filterValues: FilterValue[],
    setFilterValues: (values: FilterValue[]) => void
  ) => Filter[];
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

// Main component to view filters.
export const FilterPanelMobile = ({
  useFiltersFactory,
  filterValues,
  setFilterValues,
}: FilterPanelMobileProps) => {
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const [scrollTo, setScrollTo] = useState("");
  const filters = useFiltersFactory(filterValues, setFilterValues);
  const defaultValues = getDefaultFilterValues(filters);

  const onTriggerFilter = (filterId: string) => {
    setFilterModalIsOpen(true);
    setScrollTo(filterId);
  };

  return (
    <>
      <FilterRowMobile
        filters={filters}
        filterValues={filterValues}
        defaultValues={defaultValues}
        onTriggerFilter={onTriggerFilter}
        setFilterValues={setFilterValues}
      />
      <FilterModal
        visible={filterModalIsOpen}
        scrollTo={scrollTo}
        useFiltersFactory={useFiltersFactory}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        defaultValues={defaultValues}
        onClose={() => setFilterModalIsOpen(false)}
      />
    </>
  );
};
