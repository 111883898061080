import styled from "styled-components";
import { RankItemProps } from "./RankItemProps";

const Container = styled('div')`
  position: relative;
  margin-bottom: 3rem;
`;

const RankWrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  border: 2px dotted var(--grey300);
  border-radius: 1.5rem;
  width: 110px;
  height: 110px;
  z-index: 2;
  background: white;
`;

const RankNumber = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: bold;
  text-align: center;
  font-size: 56px;
  color: #004f93;
  padding-left: 10px;
`;

const Dot = styled('div')`
  font-size: 40px;
  margin-bottom: 3px;
`;

const Description = styled('div')`
  font-size: 14px;
  color: #004f93;
  font-weight: bold;
  margin-top: -5px;
`;

const Flag = styled('div')`
  clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
  background-color: #004f93;
  width: 60px;
  height: 30px;
  color: #fff;
  z-index: 1;
  position: absolute;
  top: 100px;
`;
const LeftFlag = styled(Flag)`
  transform: rotate(135deg);
  left: -20px;
`;

const RightFlag = styled(Flag)`
  transform: rotate(45deg);
  right: -20px;
`;

function formatHFB(hfb: number | undefined) {
  return `HFB ${hfb}`;
}

export function DecoratedItem({ rank, hfb, value }: RankItemProps) {
  return (
    <Container>
      <RankWrapper>
        <RankNumber>
          {rank}
          <Dot>.</Dot>
        </RankNumber>
        <Description>{formatHFB(hfb) + " - " + value}</Description>
      </RankWrapper>
      <LeftFlag />
      <RightFlag />
    </Container>
  );
}
