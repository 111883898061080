import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import ChevronRightSmallIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronRightSmallIconPath";

import FixaLink from "../../wrappers/FixaLink/FixaLink";
import styles from "./Linkbar.module.css";
import { LinkbarItem } from "./Linkbar";

export type ParentProps = LinkbarItem;

function Parent({
  label,
  to,
  "data-testid": dataTestId,
}: ParentProps & { "data-testid"?: string | undefined }) {
  return (
    <FixaLink
      data-testid={dataTestId}
      className={`${styles["clickable"]} ${styles["breadcrumb"]}`}
      to={to}
    >
      {label}
      <FixaSSRIcon paths={ChevronRightSmallIconPath} />
    </FixaLink>
  );
}

export default Parent;
