import { useTranslation } from "@coworker/locales";
import { CardHeadLine } from "../../Molecules/Settings/CardHeadLine";
import { CardFrame, FullLine } from "../../styles/styles";
import { getMediaTypeText } from "../../tools";
import { MediaTypes } from "../../constants";

export const AboutNone = () => {
  const { t } = useTranslation();
  return (
    <CardFrame>
      <CardHeadLine
        character={"2"}
        headline={t(`aboutString`, {
          name: getMediaTypeText(MediaTypes.NONE),
        })}
      />
      <FullLine />
    </CardFrame>
  );
};
