import { Trans } from "@coworker/locales";
import dayjs from "dayjs";
import { pascalCase } from "@coworker/components/src/helpers/string.helpers";
import hfbs from "./hfbNumbers.json";

export const AREA_TAB = {
  DEPTS: "depts",
  OTHER: "other",
  WHOLE: "whole",
};

export const periods = [
  {
    type: "period=7days",
    name: <Trans>last7DaysString</Trans>,
    transKey: "last7DaysString",
    getInitialDate: () => dayjs().startOf("day").subtract(6, "days"),
  },
  {
    type: "period=4weeks",
    name: <Trans>last4WeeksString</Trans>,
    transKey: "last4WeeksString",
    getInitialDate: () => dayjs().startOf("week").subtract(3, "weeks"),
  },
  {
    type: "period=custom",
    name: <Trans>customPeriodString</Trans>,
    transKey: "customPeriodString",
  },
];

// Other areas listed alphabetically (more or less - no re-sorting happening as it is tricky to do depending on translations etc.)
// Do not translate Hej desk and Retuna.
export const others =
  `Bar|Bistro|Cashline|Click and Collect|Questions from co-workers|Customer service|Drive thru|First room|Financing area|Full Serve Handouts|Greeting area|Hej desk|Home Delivery|Home Service Point – Planning studio|Ikea for business|Ingka Centers|Launch area|Open The Wallet|Parking zone|Pick-up point/hub|Recovery|Restaurant|Retuna|Småland|Stairs|Swedish Food Market|Self-serve`
    .split("|")
    .map((value) => ({
      type: `${AREA_TAB.OTHER}=${value}`,
      tab: AREA_TAB.OTHER,
      name: ["Hej desk", "Retuna"].includes(value) ? (
        <>{value}</>
      ) : (
        <Trans>{`otherArea${pascalCase(value)}String`}</Trans>
      ),
    }));

export const departments = hfbs.map((hfbCode: string) => ({
  type: `${AREA_TAB.DEPTS}=${hfbCode}`,
  tab: AREA_TAB.DEPTS,
  shortName: `HFB ${hfbCode}`,
  hfbNumber: hfbCode,
  name: (
    <>
      <>{`HFB${hfbCode} - `}</>
      <Trans>{`hfb${hfbCode}String`}</Trans>
    </>
  ),
}));

export const WHOLE_STORE_FILTER = [
  {
    type: `${AREA_TAB.WHOLE}=${AREA_TAB.WHOLE}`,
    tab: AREA_TAB.WHOLE,
    name: <Trans>wholeStoreString</Trans>,
  },
];

export const areaTabs = [
  {
    key: AREA_TAB.DEPTS,
    name: <Trans>departmentsString</Trans>,
    items: departments,
  },
  { key: AREA_TAB.OTHER, name: <Trans>otherString</Trans>, items: others },
  {
    key: AREA_TAB.WHOLE,
    name: <Trans>wholeStoreString</Trans>,
    items: WHOLE_STORE_FILTER,
  },
];
