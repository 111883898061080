import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { StoredImage } from "../../StoredImage";
import {
  UpperRow,
  IconColumn,
  Camera,
  Title,
  ImageHolder,
  Container,
} from "../../AttachPhoto";
import { useInputPopup } from "../../InputPopup";
import { useTaskImagesDetails } from "@coworker/apprestructured/src/tasks/hooks/useTaskImagesDetails";

const ContainerMain = styled(Container)`
  padding: 32px 16px 32px 29px;
`;
const ImageContainer = styled('div')`
  margin-left: 40px;
  margin-top: 24px;
`;

function Images({ taskPath }) {
  const { t } = useTranslation();
  const taskId = taskPath.replace(/\/|tasks/g, "");
  const { data: taskImageDetails, isLoading: isLoadingTaskImageDetails } =
    useTaskImagesDetails(taskId);
  const { getInput } = useInputPopup();

  const openImages = useCallback(() => {
    if(isLoadingTaskImageDetails) return;
    getInput("imageGallery", { data: taskImageDetails });
  }, [taskImageDetails, getInput, isLoadingTaskImageDetails]);

  return (
    !!taskImageDetails?.length && (
      <ContainerMain active={taskImageDetails}>
        <UpperRow>
          <IconColumn>
            <Camera />
          </IconColumn>
          <Title
            lblColor={taskImageDetails}
            data-testid="imagesWidgetAttachedCount"
          >
            {taskImageDetails?.length &&
              t("photoString", {
                count: taskImageDetails.length,
              })}
          </Title>
        </UpperRow>
        <ImageContainer>
          {taskImageDetails?.map((image) => (
            <ImageHolder key={image.file_name} onClick={openImages}>
              <StoredImage image={image} size="small" />
            </ImageHolder>
          ))}
        </ImageContainer>
      </ContainerMain>
    )
  );
}

export default Images;
