
import {
  CenterContainer,
  LeftContainer,
  FlexibleRowContainer,
} from "../../styles/styles";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";

export const MediumSkeletonListItem = () => {
  return (
    <>
      <FlexibleRowContainer style={{ padding: "20px 24px" }}>
        <LeftContainer>
          <FixaSkeleton height={"48px"} width={"48px"} />
        </LeftContainer>
        <CenterContainer $centerText={false} style={{ gap: "5px" }}>
          <FixaSkeleton height={"14px"} width={"100%"} />
          <FixaSkeleton height={"14px"} width={"100%"} />
          <FixaSkeleton height={"14px"} width={"100%"} />
        </CenterContainer>
      </FlexibleRowContainer>
    </>
  );
};
