import React from "react";
import { Trans } from "@coworker/locales";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";

export const QuickActionsTitle = () => {
  return (
    <FixaText tagName="h2" textStyle="Heading.S">
      <Trans>quickActionsString</Trans>
    </FixaText>
  );
};
