import React from "react";
import { MainContentScrollDirectionContext } from "../../context/MainContentScrollDirectionContextProvider";
import { useScrollDirection } from "@coworker/app/src/hooks/useScrollDirection";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";
import styles from "./main-content.module.css";

interface MainContentProps {
  children: React.ReactNode;
  scroll: boolean;
}

const MainContent = ({ scroll, children }: MainContentProps) => {
  const { setScrollDirection } = React.useContext(
    MainContentScrollDirectionContext
  );

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollTarget] = useScrollDirection();
  const location = useFixaLocation();

React.useEffect(() => {
  if (!scrollContainerRef.current) return; 

  setScrollTarget(scrollContainerRef.current);
}, [scrollContainerRef, setScrollTarget]);

// Second useEffect: Setting scroll direction
React.useEffect(() => {
  setScrollDirection(scrollDirection); 
}, [scrollDirection, setScrollDirection]);


  const isHomePage = location.pathname === "/" || location.pathname === "/home";
  const isScroll = scroll ? styles["main-content-scroll"] : styles["main-content-no-scroll"];
  const className = `${styles["main-content"]} ${isScroll}`;

  return (
    <div
      className={`${className} ${isHomePage && styles["grey-background"]}`}
      ref={scrollContainerRef}
      id="main-content"
    >
      {children}
    </div>
  );
};

export default MainContent;
