export enum QueryKeys {
  ImagesByMedia = "imagesByMedia",
  Alerts = "alerts",
  Article = "article",
  ArticlesByGroup = "articlesByGroup",
  ArticlesByMediaAndProductArticleId = "articlesByMediaAndProductArticleId",
  ArticlesByMedia = "articlesByMedia",
  ArticlesInfo = "articlesInfo",
  Medias = "medias",
  Media = "media",
  GroupsByMedia = "groupsByMedia",
  EdsData = "edsData",
  Group = "group",
  SignedUrl = "signedUrl",
  MediasAndArticleCount = "mediasAndArticleCount",
  SalesStopData = "salesStopData",
  SearchArticlesInMedias = "searchArticlesInMedias",
  TaskLinksByArticle = "taskLinksByArticle",
  TaskLinksByMedia = "taskLinksByMedia",
  TaskLinksByStore = "taskLinksByStore",
}
