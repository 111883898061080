import React, { useContext } from "react";
import { ProfileHeader } from "./Header/ProfileHeader";
import { ProfileSections } from "./Sections/ProfileSections";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import styles from "./profile.module.css";
import { useTranslation } from "@coworker/locales";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import { StoreEnvironment } from "./StoreEnvironment/StoreEnvironment";

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { modalState, toggleModal } = useContext(ModalContext);
  return (
    <FixaModal
      escapable={true}
      visible={modalState.isProfileModalOpen}
      handleCloseBtn={() => {
        toggleModal({ type: ModalType.Profile });
      }}
    >
      <FixaSheets
        labelledById="Profile"
        size="small"
        className={styles["profile-sheets"] as string}
        header={
          <FixaModalHeader
            className={styles["profile-header-title"] as string}
            title={t("profileString")}
          />
        }
        footer={null}
      >
        <ProfileHeader />
        <ProfileSections />
        <StoreEnvironment />
      </FixaSheets>
    </FixaModal>
  );
};
