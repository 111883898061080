import { useCallback, useState } from "react";

import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import PlusIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PlusIconPath";
import FixaInlineMessage from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import InformationCircleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/InformationCircleIconPath";
import ArrowRightIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ArrowRightIconPath";
import NamingGuidelinesPopup from "@coworker/apprestructured/src/tasks/components/NamingGuidelinesPopup/NamingGuidelinesPopup";

import styles from "./add-custom-location.module.css";
import trackerHelper from "../../../../helpers/tracker";
import { useTranslation } from "@coworker/locales";
import { PleaseFillIn } from "../../../Reusable/PleaseFillIn";
import styled from "styled-components";
import {
  FixaAccordion,
  FixaAccordionItem,
} from "@coworker/apprestructured/src/shared/wrappers/FixaAccordion/FixaAccordion";

type LocationObject = {
  locationCode: string;
  locationSection: string;
  locationDepartment: string;
  locationCustom?: string;
};

interface AddCustomLocatioProps {
  submitWithConfirmation: (locationObject: LocationObject) => void;
  query: string;
}

// Soft enforcement of the location naming guidelines
const LOCATION_NAME_LENGTH = 6;
const AccordionContainer = styled('div')`
  background-color: var(--white);
`;

const AddCustomLocation = ({
  submitWithConfirmation,
  query,
}: AddCustomLocatioProps) => {
  const { t } = useTranslation();

  const [isNamingConventionsOpen, setIsNamingConventionsOpen] = useState(false);
  const [showLocationNameError, setShowLocationNameError] = useState(false);

  const handleAddCustomLocationClick = useCallback(() => {
    trackerHelper.trackAddCustomLocationButtonClick();
    if (query.length === LOCATION_NAME_LENGTH) {
      submitWithConfirmation({
        locationCode: query,
        locationSection: "",
        locationDepartment: "",
        locationCustom: query,
      });
    } else {
      setShowLocationNameError(true);
    }
  }, [query, submitWithConfirmation]);

  return (
    <div className={styles["add-custom-location"]}>
      <div className={styles["section"]}>
        <FixaButton
          iconPosition="trailing"
          ssrIcon={ArrowRightIconPath}
          text={t("namingGuideLinesString")}
          type="secondary"
          fluid
          onClick={() => {
            setIsNamingConventionsOpen(true);
            trackerHelper.trackNamingConventionPopupButton();
          }}
        />
        {isNamingConventionsOpen && (
          <NamingGuidelinesPopup
            handleClose={() => {
              setIsNamingConventionsOpen(false);
            }}
          />
        )}
      </div>
      <div className={`${styles["food-articles-information"]}`}>
        {t("customLocationsOnlyForFoodString")}
      </div>
      <div className={`${styles["section"]} ${styles["query-preview"]}`}>
        {query}
      </div>
      <AccordionContainer>
        <FixaAccordion padded={true}>
          <FixaAccordionItem
            id={"custom-location-id"}
            title={t("customLocationInformationString")}
            children={
              <div className={styles["information-section"]}>
                <FixaInlineMessage
                  title={t("customLocationsString")}
                  body={t("customLocationsMessageString")}
                  variant={"informative"}
                  ssrIcon={InformationCircleIconPath}
                />
              </div>
            }
          />
        </FixaAccordion>
      </AccordionContainer>

      <div className={styles["footer"]}>
        <PleaseFillIn
          testId="location-name-error"
          show={showLocationNameError && query.length !== LOCATION_NAME_LENGTH}
          alternateWarning={
            query.length < LOCATION_NAME_LENGTH
              ? "locationNameTooShort"
              : "locationNameTooLong"
          }
          centerText={true}
        />
        <FixaButton
          data-testid="add-custom-location-button"
          text={t("addCustomLocationString")}
          type="primary"
          fluid
          ssrIcon={PlusIconPath}
          onClick={handleAddCustomLocationClick}
        />
      </div>
    </div>
  );
};

export default AddCustomLocation;
