import React from "react";
import { useTranslation } from "@coworker/locales";
import userPreferences from "@coworker/enums/profilePreferences";
import { useMyStore } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useUserPreference } from "../hooks/useProfilePreferencesQuery";

export function resolveCountryId(userCountryId, countryId, primaryLocale) {
  const countryCodefromMyStore = (countryId?.length === 2 && countryId) || "";
  return countryCodefromMyStore || primaryLocale?.slice(-2) || userCountryId;
}

export function useSetInitialLanguage() {
  const store = useMyStore();

  const [userLanguage, , isLoading] = useUserPreference(
    userPreferences.LANGUAGE,
    store?.primaryLocale ?? ""
  );

  const { changeLanguage, language: activeLang = "" } = useTranslation();
  // Todo: remove this flag when we have a proper solution, talk to Mange about it
  //const blablaLanguage = useFlag(FLAGS.BLABLA_LANGUAGE);

  React.useEffect(() => {
  if (!isLoading && userLanguage !== activeLang) {
      changeLanguage(userLanguage);
    }
  }, [changeLanguage, activeLang, userLanguage, isLoading]);
}
