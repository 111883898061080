import React from "react";
import styled from "styled-components";

const Container = styled('div')`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 50px;
  overflow-y: auto;
`;

const FullscreenPopup = ({ Component, onSubmit, onCancel, onClose }) => {
  return (
    <Container>
      <Component onSubmit={onSubmit} onCancel={onCancel} onClose={onClose} />
    </Container>
  );
};

export default FullscreenPopup;
