import { useEffect, useState } from "react";
import { Media, Medias, RoomSettingsMedia } from "../../types/media";
import { useMediasByStoreId } from "../../hooks/useMediasByStoreId";
import styled from "styled-components";
import { MediaImagesListItem } from "../../Molecules/MediaImagesListItem";
import { FixaFormField } from "@coworker/apprestructured/src/shared/wrappers/FixaFormField/FixaFormField";
import { useTranslation } from "@coworker/locales";
import {
  checkEmptyMediaName,
  checkMediaName,
  getArticleName,
  validationMessage,
} from "./SettingsForm.helper";
import { LabelText } from "../../Atoms/LabelText";
import { WarningTextWithIcon } from "../../Atoms/WarningTextWithIcon";
import { Articles } from "../../types/article";
import { Image } from "../../types/images";
import { MediaNameInput } from "../../Molecules/Settings/MediaNameInput";
import { MainAlternativeProductSelect } from "../../Molecules/Settings/MainAlternativeProductSelect";
import { StyleGroupSelect } from "../../Molecules/Settings/StyleGroupSelect";
import { LivingSituationSelect } from "../../Molecules/Settings/LivingSituationSelect";
import { LongTermPrioritySelect } from "../../Molecules/Settings/LongTermPrioritySelect";
import { PricingSelect } from "../../Molecules/Settings/PricingSelect";
import { HfbSelect } from "../../Molecules/Settings/HfbSelect";
import { MainMessageInput } from "../../Molecules/Settings/MainMessageInput";
import { ActiveSwitcher } from "../../Molecules/Settings/ActiveSwitcher";

const PaddedHorizontalContainer = styled('div')`
  padding: 0 1rem;
`;

const ModalItemWrapper = styled('div')`
  > * + * {
    margin-top: 20px;
  }
  padding: 20px 0;
`;

const PaddedFixaFormField = styled(FixaFormField)`
  padding-right: 12px;
`;

interface SettingsFormProps {
  media: Media;
  articles: Articles;
  onSave: (newMedia: Media) => void;
  showUpdateFields?: boolean;
  images: Image[];
  onImageAddStart: (fileList: FileList) => void;
  onImageAddEnd: (images: Image[]) => void;
  onImageRemove: (images: Image[]) => void;
  onImageUpdate: (image: Image) => void;
}

export const SettingsForm = function ({
  media,
  articles,
  onSave,
  showUpdateFields = true,
  images,
  onImageAddStart,
  onImageAddEnd,
  onImageRemove,
  onImageUpdate,
}: SettingsFormProps) {
  const { t } = useTranslation();
  const [medias, setMedias] = useState<Medias>([]);
  const { data } = useMediasByStoreId(media.storeId);

  // Only load media-list once
  useEffect(() => {
    if (data) {
      setMedias(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roomSettingsMedia = media as RoomSettingsMedia;

  return (
    <PaddedHorizontalContainer>
      <ModalItemWrapper>
        <PaddedFixaFormField
          shouldValidate={
            !medias ||
            checkEmptyMediaName(media) ||
            checkMediaName(media, medias)
          }
          validation={{
            msg: validationMessage(media, medias ?? [], t),
          }}
        >
          <MediaNameInput media={media} onSave={onSave} withAsterisk={true} />
        </PaddedFixaFormField>

        <LabelText text={t("photosString")} />
        <MediaImagesListItem
          media={media}
          images={images}
          onAddStart={onImageAddStart}
          onAddEnd={onImageAddEnd}
          onRemove={onImageRemove}
          onUpdate={onImageUpdate}
        />

        {showUpdateFields && (
          <>
            <MainAlternativeProductSelect
              componentId={"main_article_id_1"}
              label={`${t("mainProductBasicString")} #1`}
              value={roomSettingsMedia.mainProductId1 ?? ""}
              roomSettingsMedia={roomSettingsMedia}
              onSave={(articleId) => {
                onSave({
                  ...media,
                  mainProductId1: articleId,
                  mainProductName1: getArticleName(articles, articleId),
                } as RoomSettingsMedia);
              }}
              currentProductId={roomSettingsMedia.mainProductId1 ?? ""}
              articles={articles}
            />
            <ActiveSwitcher media={media} onSave={onSave} />
            {!media.name && (
              <WarningTextWithIcon text={t("activateRoomNameRequiredString")} />
            )}
            <MainAlternativeProductSelect
              componentId={"backup_article_id_1"}
              label={`${t("backupForProductString")} #1`}
              value={roomSettingsMedia.alternativeProductId1 ?? ""}
              roomSettingsMedia={roomSettingsMedia}
              onSave={(articleId) => {
                onSave({
                  ...media,
                  alternativeProductId1: articleId,
                  alternativeProductName1: getArticleName(articles, articleId),
                } as RoomSettingsMedia);
              }}
              currentProductId={roomSettingsMedia.alternativeProductId1 ?? ""}
              articles={articles}
            />
            <MainAlternativeProductSelect
              componentId={"main_article_id_2"}
              label={`${t("mainProductBasicString")} #2`}
              value={roomSettingsMedia.mainProductId2 ?? ""}
              roomSettingsMedia={roomSettingsMedia}
              onSave={(articleId) => {
                onSave({
                  ...media,
                  mainProductId2: articleId,
                  mainProductName2: getArticleName(articles, articleId),
                } as RoomSettingsMedia);
              }}
              currentProductId={roomSettingsMedia.mainProductId2 ?? ""}
              articles={articles}
            />
            <MainAlternativeProductSelect
              componentId={"backup_article_id_2"}
              label={`${t("backupForProductString")} #2`}
              value={roomSettingsMedia.alternativeProductId2 ?? ""}
              roomSettingsMedia={roomSettingsMedia}
              onSave={(articleId) => {
                onSave({
                  ...media,
                  alternativeProductId2: articleId,
                  alternativeProductName2: getArticleName(articles, articleId),
                } as RoomSettingsMedia);
              }}
              currentProductId={roomSettingsMedia.alternativeProductId2 ?? ""}
              articles={articles}
            />
          </>
        )}
        <MainMessageInput media={media} onSave={onSave} />
        <StyleGroupSelect media={media} onSave={onSave} />
        <LivingSituationSelect media={media} onSave={onSave} />
        <LongTermPrioritySelect media={media} onSave={onSave} />
        <PricingSelect media={media} onSave={onSave} />
        <HfbSelect media={media} onSave={onSave} />
      </ModalItemWrapper>
    </PaddedHorizontalContainer>
  );
};
