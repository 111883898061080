import { Media } from "../../../types/media";
import { AboutNone } from "../AboutNone";
import { MediaTypes } from "../../../constants";
import { AboutRoomSettings } from "../AboutRoomSettings";
import { AboutVignette } from "../AboutVignette";
import { AboutCompact } from "../AboutCompact";
import { AboutCoordinationMedia } from "../AboutCoordinationMedia";

interface AboutSectionProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const AboutSection = ({ media, onSave }: AboutSectionProps) => {
  return (
    <>
      {media.mediaType === MediaTypes.NONE && <AboutNone />}
      {media.mediaType === MediaTypes.ROOM_SETTINGS && (
        <AboutRoomSettings
          media={media}
          onSave={(newMedia) => onSave(newMedia)}
        />
      )}
      {media.mediaType === MediaTypes.VIGNETTE && (
        <AboutVignette media={media} onSave={(newMedia) => onSave(newMedia)} />
      )}
      {media.mediaType === MediaTypes.COMPACT && (
        <AboutCompact media={media} onSave={(newMedia) => onSave(newMedia)} />
      )}
      {media.mediaType === MediaTypes.COORDINATION_MEDIA && (
        <AboutCoordinationMedia
          media={media}
          onSave={(newMedia) => onSave(newMedia)}
        />
      )}
    </>
  );
};
