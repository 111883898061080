// Skapa
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import PlusIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PlusIconPath";

interface SkapaButtonProps {
  onClick: () => void;
}

export const SkapaPlusButton = ({ onClick }: SkapaButtonProps) => {
  return (
    <FixaButton
      type="primary"
      iconOnly
      ssrIcon={PlusIconPath}
      onClick={onClick}
    />
  );
};
