import { useMemo,useState } from "react";
import { useTasksByStore } from "../hooks/useTasksByStore";
import {
  useStoreId,
  useTeamId,
  useUserId,
} from "../../../core/auth/useLoggedInUser";
import { Trans, useTranslation } from "@coworker/locales";
import { GreyStrip } from "../Atoms/GreyStrip";
import { ListWrapper } from "../styles/styles";
import { TaskListingFilter } from "../Molecules/TaskListingFilter";
import { MediaTask } from "@coworker/types/lib/tasks/media";
import { TaskOwner } from "../constants";
import { filterMediaSettingsListFunction } from "../tools";
import EmptyScreen from "../../EmptyScreen";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useMediasByStoreId } from "../hooks/useMediasByStoreId";
import { TaskCard } from "@coworker/apprestructured/src/tasks/components/TaskCard/TaskCard";
import { useItemsInfoQueries } from "@coworker/apprestructured/src/shared/hooks/item/useItemsInfo";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { FullTask } from "@coworker/apprestructured/src/activity/types/FullTask";

export function OngoingTasksListingView() {
  const { t } = useTranslation();
  const storeId = useStoreId();
  const fixaUid = useUserId();
  const teamId = useTeamId();
  const tasks = useTasksByStore(storeId);
  const { data: medias } = useMediasByStoreId(storeId);

  const [taskOwner, setTaskOwner] = useState<TaskOwner>(TaskOwner.Mine);

  const filteredTaskList = filterMediaSettingsListFunction(
    tasks.tasks,
    taskOwner,
    fixaUid,
    teamId
  );

  const itemsNos = filteredTaskList
    ?.map((task) => task.product_article_id)
    .filter(Boolean);

  const itemsInfoResult = useItemsInfoQueries(itemsNos);
  const itemsInfo = itemsInfoResult?.map((result) => result.data);

  const getMediaName = (mediaId: string) => {
    const media = medias?.find((media) => media.id === mediaId);
    return media?.name || "";
  };
  const mediaIds = [
    ...new Set(
      filteredTaskList
        .sort((t1, t2) =>
          getMediaName(t1.media_id).localeCompare(getMediaName(t2.media_id))
        )
        .map((task) => task.media_id)
    ),
  ];

  const linkbar = useMemo(() => {
    return (
      <Linkbar
        items={[
          {
            label: t("mediaMaintenanceString"),
            to: "/mediamaintenance",
          },
        ]}
        currentLabel={t("ongoingTasksString")}
        currentAsH1
      />
    );
  }, [t]);

  return (
    <FullScreenPopup noPadding linkbar={linkbar}>
      <TaskListingFilter setTaskOwner={setTaskOwner} />
      {mediaIds.map((mediaId: string) => (
        <ListWrapper key={mediaId}>
          <GreyStrip text={getMediaName(mediaId)} />
          {filteredTaskList
            .filter((mediaTask: MediaTask) => {
              return mediaTask.media_id === mediaId;
            })
            .map((mediaTask: MediaTask) => {
              const item = itemsInfo?.find(
                (item) => item?.no === mediaTask.product_article_id
              );
              return (
                <TaskCard
                  item={item as Item}
                  key={mediaTask.id}
                  task={mediaTask as unknown as FullTask}
                />
              );
            })}
        </ListWrapper>
      ))}
      {!filteredTaskList.length && (
        <EmptyScreen
          subTitle={<Trans>nothingString</Trans>}
          title={<Trans>noTasksFiltersString</Trans>}
          hideButton={true}
        />
      )}
    </FullScreenPopup>
  );
}
