import profilePreferences from "@coworker/enums/profilePreferences";
import {useState} from "react";
import styled from "styled-components";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import { defaultCustomPeriod } from "./filterChoices";
import { Trans } from "@coworker/locales";
import { Button } from "@coworker/components";
import { DAY, WEEK } from "@coworker/enums/insightsGroupTypes";
import { formatIsoDate } from "../../Insights/helpers";

const Wrapper = styled('div')`
  margin-top: 20px;
`;

const InputBox = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0px 39px 0px 29px;
  & > input {
    font-size: 16px;
    border-bottom: 1px solid var(--grey500);
    background: var(--white);
    width: 100%;
  }
  &:first-child {
    margin-top: 0;
  }
  & > input:first-of-type {
    margin-bottom: 30px;
  }
`;

const InputLabel = styled('label')`
  font-size: 12px;
  color: var(--grey700);
`;

const GroupChoiceWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 20px 39px 0px 29px;
`;

const SmallButton = styled(Button)`
  height: 40px;
  min-width: 110px;
  padding: 12px, 24px, 12px, 24px;
  font-size: 12px;
`;

const Title = styled('p')`
  font-size: 18px;
  font-weight: bold;
  margin: 20px 39px 0px 29px;
`;

export default function CustomPeriodOptions({
  onDatesChange,
  onGroupingChange,
  settingsPrefix = profilePreferences.MFAQ_PREFIX,
}) {
  const now = formatIsoDate();
  const [customPeriodPreference] = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS_CUSTOM_PERIOD,
    defaultCustomPeriod()
  );
  const [startDate, setStartDate] = useState(customPeriodPreference[0]);
  const [endDate, setEndDate] = useState(customPeriodPreference[1]);

  const [graphOptionsPreference] = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS_GRAPH_OPTIONS,
    DAY
  );

  const showDayWeekGraphOptions =
    settingsPrefix === profilePreferences.INSIGHTS_PREFIX;

  const [grouping, setGrouping] = useState(graphOptionsPreference);
  return (
    <Wrapper>
      <InputBox>
        <InputLabel htmlFor="startDate">
          <Trans>startDateString</Trans>
        </InputLabel>
        <input
          data-testid="input-startDate"
          name="startDate"
          type="date"
          value={startDate}
          max={endDate}
          onChange={(e) => {
            onDatesChange(e.target.value, endDate);
            setStartDate(e.target.value);
          }}
        />
        <InputLabel htmlFor="endDate">
          <Trans>endDateString</Trans>
        </InputLabel>
        <input
          data-testid="input-endDate"
          name="endDate"
          type="date"
          value={endDate}
          min={startDate}
          max={now}
          onChange={(e) => {
            onDatesChange(startDate, e.target.value);
            setEndDate(e.target.value);
          }}
        />
      </InputBox>
      {showDayWeekGraphOptions && (
        <>
          <Title>
            <Trans>graphOptionsString</Trans>
          </Title>
          <GroupChoiceWrapper>
            <SmallButton
              data-testid="button-groupByDay"
              text={<Trans>customInsightsPeriodShowDays</Trans>}
              primary={true}
              dark={grouping === DAY}
              onClick={() => {
                if (grouping !== DAY) {
                  onGroupingChange(DAY);
                  setGrouping(DAY);
                }
              }}
            />
            <SmallButton
              data-testid="button-groupByWeek"
              text={<Trans>customInsightsPeriodShowWeeks</Trans>}
              primary={true}
              dark={grouping === WEEK}
              onClick={() => {
                if (grouping !== WEEK) {
                  onGroupingChange(WEEK);
                  setGrouping(WEEK);
                }
              }}
            />
          </GroupChoiceWrapper>
        </>
      )}
    </Wrapper>
  );
}
