import FixaPill from "../../../wrappers/FixaPill/FixaPill";
import CrossIconPath from "../../../wrappers/FixaSSRIcon/paths/CrossIconPath";
import {
  SelectOption,
  FilterValue,
  SelectFilter,
  StringFilterValue,
} from "../../types";
import {
  filterValueAreTheSame,
  isSelectOption,
  removeFilterValueFromValues,
} from "../../utils";

interface AppliedSelectPillProps {
  filter: SelectFilter;
  filterValue: StringFilterValue | undefined;
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const AppliedSelectPill = ({
  filter,
  filterValue,
  filterValues,
  setFilterValues,
}: AppliedSelectPillProps) => {
  const { id, title } = filter;
  if (filterValue && !filterValueAreTheSame(filterValue, filter.defaultValue)) {
    const option = filter.options
      .filter((option) => isSelectOption(option))
      .find((option) => (option as SelectOption).value === filterValue.value);
    return (
      <FixaPill
        iconPosition="trailing"
        ssrIcon={CrossIconPath}
        selected
        label={title + ": " + (option?.title ?? filterValue.value)}
        onClick={() => {
          setFilterValues(removeFilterValueFromValues(filterValues, id));
        }}
        size="small"
      />
    );
  } else {
    return null;
  }
};
