import { useMemo } from "react";
import { Article, Combined } from "../types/article";

export interface AggregatedArticle extends Combined<Article> {
  aggregatedArticles: Combined<Article>[];
  groupIds: string[];
}

export const useAggregatedArticles = (
  articles: Combined<Article>[] | undefined
) => {
  const uniqueArticles = useMemo(() => {
    if (!articles) {
      return articles;
    }
    const articlesProductIdMap = new Map<string, AggregatedArticle>();
    for (const article of articles) {
      const oldArticle = articlesProductIdMap.get(article.productArticleId);
      if (oldArticle) {
        oldArticle.nbrArticles =
          (oldArticle?.nbrArticles ?? 1) + article.nbrArticles;
        if (
          oldArticle.createdAtDate &&
          oldArticle.createdAt &&
          article.createdAtDate &&
          article.createdAt &&
          oldArticle.createdAtDate.getTime() < article.createdAtDate.getTime()
        ) {
          oldArticle.createdAt = article.createdAt;
          oldArticle.createdAtDate = article.createdAtDate;
        }
        if (article.groupId) {
          oldArticle.groupIds.push(article.groupId);
        }
        if (article.tasks) {
          oldArticle.tasks!.push(...article.tasks);
        }
        oldArticle.aggregatedArticles.push(article);
      } else {
        articlesProductIdMap.set(article.productArticleId, {
          ...article,
          groupIds: article.groupId ? [article.groupId] : [],
          tasks: article.tasks ?? [],
          aggregatedArticles: [article],
        });
      }
    }
    return Array.from(articlesProductIdMap.values());
  }, [articles]);
  return uniqueArticles;
};
