import React, { MouseEvent } from "react";
import { useTranslation } from "@coworker/locales";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

const ButtonContainer = styled('div')`
  position: sticky;
  bottom: 0;
  background: white;
  bordertop: 1px solid var(--grey200);
`;

interface AddCustomLocationCTAButtonProps {
  onClick: (
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | Element>
  ) => void;
}

const AddCustomLocationCTAButton: React.FC<AddCustomLocationCTAButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <ButtonContainer>
      <FixaButton
        type="secondary"
        key="addCustomLocationButton"
        text={t("createCustomLocationString")}
        fluid={true}
        onClick={onClick}
        iconPosition="trailing"
        style={{ padding: "25px 20px" }}
      />
    </ButtonContainer>
  );
};

export default AddCustomLocationCTAButton;
