import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import HomeIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/HomeIconPath";

import FixaLink from "../../wrappers/FixaLink/FixaLink";
import styles from "./Linkbar.module.css";

function Home({
  "data-testid": dataTestId,
}: {
  "data-testid"?: string | undefined;
}) {
  return (
    <FixaLink
      data-testid={dataTestId}
      to="/"
      className={`${styles["clickable"]} ${styles["home"]}`}
      aria-label="Home"
    >
      <FixaSSRIcon paths={HomeIconPath} />
    </FixaLink>
  );
}

export default Home;
