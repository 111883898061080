import React from "react";
import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";
import { Trans, useTranslation } from "@coworker/locales";
import { ActivityFilterType } from "../../../types/filterOptionsTypes";
import { appliedPills } from "../../../constants/filterPills";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import {
  FilterOptionType,
  GroupedFilterOptionsType,
  getGrouppedFilterOptions,
} from "../../../constants/filterOptions";
import { StoreTeam } from "@coworker/types/lib/storeteam";
import styles from "./applied-filters.module.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import { formatLocationName } from "../helper";

interface AppliedFiltersProps {
  filters: ActivityFilterType;
  setFilters: React.Dispatch<React.SetStateAction<ActivityFilterType>>;
}

export const AppliedFilters = ({
  filters,
  setFilters,
}: AppliedFiltersProps) => {
  const teams = useStoreTeams();
  const fixaUid = useFixaUID();
  const { t } = useTranslation();
  const { sortedBy, ...validFilters } = filters;

  const getOptionName = (
    id: string,
    value: string | boolean | string[],
    groupOptions: (FilterOptionType | StoreTeam)[]
  ) => {
    const option = groupOptions?.find((item) => item.id === value);

    const isAssignedToMe = filters.assignedTo === fixaUid;
    const isCreatedByMe = filters.createdBy === fixaUid;

    if (
      (id === "assignedTo" && !isAssignedToMe) ||
      (id === "createdBy" && !isCreatedByMe)
    ) {
      return (option as StoreTeam)?.name;
    } else if (
      (id === "assignedTo" && isAssignedToMe) ||
      (id === "createdBy" && isCreatedByMe)
    )
      return "meString";
    else {
      return (option as FilterOptionType)?.transKey;
    }
  };

  return (
    <div className={styles["applied-filters"]}>
      <span className={styles["title"]}>
        <Trans>appliedFiltersString</Trans>
      </span>
      <div className={styles["pill-wrapper"]}>
        {Object.entries(validFilters).map(([id, value]) => {
          const label = appliedPills.find((pill) => pill.id === id)?.transKey;
          const optionsGroups =
            getGrouppedFilterOptions(teams)[
              id as keyof GroupedFilterOptionsType
            ];
          const optionName = getOptionName(id, value as string, optionsGroups);
          const showColon = id !== "priority";

          if (value && value !== "" && !Array.isArray(value)) {
            return (
              <FixaPill
                key={id}
                size="small"
                selected
                label={
                  <>
                    <Trans>{label}</Trans>
                    {showColon && ": "}
                    <Trans>{optionName}</Trans>
                  </>
                }
                ssrIcon={CrossIconPath}
                iconPosition="trailing"
                onClick={() => {
                  if (id === "taskType") {
                    filters.locations = [];
                    filters.pickupLocations = [];
                    filters.refillLocations = [];
                  }
                  trackerHelper.trackActivityAppliedFilterPillClick();
                  setFilters({ ...filters, [id]: false });
                  localStorage.setItem(
                    "activityFilters",
                    JSON.stringify({ ...filters, [id]: false })
                  );
                }}
              />
            );
          } else if (Array.isArray(value) && value.length > 0) {
            return value.map((item) => (
              <FixaPill
                key={item}
                size="small"
                selected
                label={
                  <>
                    <Trans>{label}</Trans>
                    {item && ": "}
                    <Trans>
                      {formatLocationName(t as (key?: string) => string, item)}
                    </Trans>
                  </>
                }
                ssrIcon={CrossIconPath}
                iconPosition="trailing"
                onClick={() => {
                  trackerHelper.trackActivityAppliedFilterPillClick();
                  const newValue = value.filter((v) => v !== item);
                  setFilters({
                    ...filters,
                    [id]: newValue.length ? newValue : false,
                  });
                  localStorage.setItem(
                    "activityFilters",
                    JSON.stringify({
                      ...filters,
                      [id]: newValue.length ? newValue : false,
                    })
                  );
                }}
              />
            ));
          }
          return null;
        })}
      </div>
    </div>
  );
};
