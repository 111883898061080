import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";
import styled from "styled-components";

const Container = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: auto;
  padding: 0.5rem;
`;

const Wrapper = styled('div')`
  margin: 0.8rem 0;
`;

export const QuantitySteppersSkeleton = () => {
  return (
    <Container>
      <Wrapper>
        <FixaSkeleton width="100%" height="34px" />
      </Wrapper>
      <Wrapper>
        <FixaSkeleton width="100%" height="34px" />
      </Wrapper>
      <Wrapper>
        <FixaSkeleton width="100%" height="34px" />
      </Wrapper>
      <Wrapper>
        <FixaSkeleton width="100%" height="34px" />
      </Wrapper>
      <Wrapper>
        <FixaSkeleton width="100%" height="34px" />
      </Wrapper>
    </Container>
  );
};
