import { useStoreId } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useQuery } from "@tanstack/react-query";
import { User } from "../../types/user";
import { getStoreUsers } from "@coworker/app/src/hooks/API/core.service";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";

export function useAllStoreUsers() {
  const storeId = useStoreId();
  return useQuery<User[]>({
    enabled: !!storeId,
    queryKey: ["store", "users", storeId],
    queryFn: async () => await getStoreUsers(storeId),
    staleTime: 2 * INTERVAL.MINUTE,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
