import productIssueForm from "./product_issue";
import addonEditForm from "./addon_edit";
import addonChangesForm from "./addon_changes";
import addonCreateForm from "./addon_single";
import customForm from "./custom";
import plannedForm from "./planned";
import productQualityForm from "./product_quality";
import mfaqFollowUpForm from "./mfaq_followup";
import roomsettingsForm from "./roomsettings_edit";

import { WidgetType } from "../widgets";

const TaskTypes = {
  product: productIssueForm,
  addon_edit: addonEditForm,
  addon_changes: addonChangesForm,
  addon_create: addonCreateForm,
  custom: customForm,
  planned: plannedForm,
  product_quality: productQualityForm,
  mfaq_followup: mfaqFollowUpForm,
  roomsettings_edit: roomsettingsForm,
} as const;

export default TaskTypes;

export type TaskFormTypeNamesType = keyof typeof TaskTypes;
export type TaskFormTypesFull = (typeof TaskTypes)[TaskFormTypeNamesType];
export type TaskFormTypes = Partial<TaskFormTypesFull> & {
  header: TaskFormTypesFull["header"] & {
    editTitle: any;
    alwaysSticky: boolean;
    hideTallHeader: boolean;
    testId: string;
  };
  fields: (TaskFormTypesFull["fields"] & {
    type: WidgetType;
    disabled: ({ isEdit }: { isEdit: boolean }) => boolean | (() => boolean);
    hide: (arg0: string, arg1: any) => boolean;
    values: {
      in: (arg0: any, arg1: any) => any;
      out: (arg0: any, arg1: any) => any;
    };
    conf: { [key: string]: any };
  })[];
};
