import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useQuery } from "@tanstack/react-query";

async function fetchNearbyProducts(location: string) {
  const url = `${TASKS_SERVICE_URL}/v1/products/nearby_products?location=${location}`;
  const { result } = await fetchAPI("GET", url);
  return result;
}

export function useNearbyProducts(location: string) {
  return useQuery({
    queryKey: ["nearby", "products", location],
    queryFn: async () => await fetchNearbyProducts(location),
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!location,
  });
}
