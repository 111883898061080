import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import TrashCanIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TrashCanIconPath";
import { FixaQuantityStepper } from "@coworker/apprestructured/src/shared/wrappers/FixaQuantityStepper/FixaQuantityStepper";
import { useTranslation } from "@coworker/locales";

const ButtonWrapper = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ActionRowWrapper = styled('div')`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  margin-left: 68px;
`;

interface ArticleListItemActionRowProps {
  articleId: string;
  nbrArticles: number;
  onQuantityChange: (value: number) => void;
  selectArticleIdsMode: boolean;
  onDeleteArticle: () => void;
  doUseQuantityStepper?: boolean;
}

const delayedUpdateTimer: Record<string, NodeJS.Timeout> = {};

export const ArticleListItemActionRow: React.FC<
  ArticleListItemActionRowProps
> = ({
  articleId,
  nbrArticles,
  onQuantityChange,
  selectArticleIdsMode,
  onDeleteArticle,
  doUseQuantityStepper = true,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(nbrArticles);
  const [debounce, setDebounce] = useState(false);

  useEffect(() => {
    return () => {
      if (delayedUpdateTimer[articleId]) {
        clearTimeout(delayedUpdateTimer[articleId]);
        delete delayedUpdateTimer[articleId];
      }
    };
  }, [articleId]);

  // Do not update local quantity stepper for some time after input from the quantity stepper.
  if (debounce) {
    if (delayedUpdateTimer[articleId]) {
      clearTimeout(delayedUpdateTimer[articleId]);
    }
    delayedUpdateTimer[articleId] = setTimeout(() => {
      setDebounce(false);
      setQuantity(nbrArticles);
      delete delayedUpdateTimer[articleId];
    }, 2500);
  } else if (!debounce && quantity !== nbrArticles) {
    setQuantity(nbrArticles);
  }

  if (selectArticleIdsMode) {
    return <></>;
  }

  return (
    <ActionRowWrapper
      onClick={(e) => {
        handleClick(e);
      }}
    >
      {doUseQuantityStepper ? (
        <FixaQuantityStepper
          small={true}
          ariaDescribedBy="articleQuantityStepper"
          ariaDescribedById="articleQuantityStepper"
          minVal={1}
          maxVal={99}
          value={quantity}
          onChange={(event) => {
            const newQuantity = parseInt(event.target.value);
            setDebounce(true);
            setQuantity(newQuantity);
            onQuantityChange(newQuantity);
          }}
          onIncrease={() => {
            const newQuantity = quantity + 1;
            setDebounce(true);
            setQuantity(newQuantity);
            onQuantityChange(newQuantity);
          }}
          onDecrease={() => {
            const newQuantity = quantity - 1;
            setDebounce(true);
            setQuantity(newQuantity);
            onQuantityChange(newQuantity);
          }}
        />
      ) : (
        <FixaButton
          size="small"
          text={t("editQuantityString")}
          onClick={() => onQuantityChange(quantity)}
        />
      )}
      {!selectArticleIdsMode && (
        <>
          <ButtonWrapper>
            <FixaButton
              size="small"
              iconOnly={true}
              ssrIcon={TrashCanIconPath}
              onClick={onDeleteArticle}
            />
          </ButtonWrapper>
        </>
      )}
    </ActionRowWrapper>
  );
};
