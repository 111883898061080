import React from "react";
import styled from "styled-components";
import { ReactComponent as DescriptionIcon } from "../assets/svg/comment.svg";
import { Trans } from "@coworker/locales";

const Container = styled('div')`
  background: white;
  padding: ${(props) => props.overridePadding || "0 16px 0 31px"};
  min-height: 90px;
  display: flex;
  align-items: center;
  border-bottom: ${({ border }) =>
    border ? "1px solid var(--grey150)" : "none"};
  color: ${({ lblColor, deactivated }) =>
    lblColor ? lblColor : deactivated ? "var(--grey600)" : "var(--grey900)"};
`;
const CompactContainer = styled('div')`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;
const CompactTitle = styled('h2')`
  font-size: 14px;
  line-height: 1.5;
  margin-left: 20px;
  padding-bottom: 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 700;
`;
const CompactIconWrapper = styled('div')`
  width: 22px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    margin: 0px auto;
    color: ${(props) =>
      props.deactivated && !props.activeIcon
        ? "var(--disabled)"
        : "var(--grey900)"};
  }
`;
const Optional = styled('div')`
  opacity: 0.58;
  font-size: 14px;
  line-height: 24px;
  margin-right: 14px;
  flex-grow: 0;
  font-weight: normal;
`;
const AdditionalSubTitleWrapper = styled('div')`
  margin-bottom: -6px;
`;

const AddDescription = ({
  deactivated,
  descriptionTitle,
  optional,
  noBottomBorder,
  activeIcon,
  icon = <DescriptionIcon />,
  overridePadding,
  lblColor,
  additionalSubTitle,
  ...props
}) => (
  <Container
    overridePadding={overridePadding}
    border={!noBottomBorder}
    deactivated={deactivated}
    lblColor={lblColor}
  >
    <CompactContainer>
      <CompactIconWrapper deactivated={deactivated} activeIcon={activeIcon}>
        {icon}
      </CompactIconWrapper>
      <CompactTitle>
        {props.value || descriptionTitle}
        {!optional && !props.value && (
          <AdditionalSubTitleWrapper>
            {additionalSubTitle}
          </AdditionalSubTitleWrapper>
        )}
      </CompactTitle>
      {optional && (
        <Optional>
          <Trans>optionalString</Trans>
        </Optional>
      )}
    </CompactContainer>
  </Container>
);

export default AddDescription;
