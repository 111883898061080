import React from "react";
import styled from "styled-components";
import { ProductNumber } from "@coworker/reusable/components/ProductNumber";
import { LoaderIcon } from "@coworker/reusable/Loader";


const ProductInformationContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const ProductTitle = styled('div')`
  font-size: 16px;
  font-weight: bold;
`;

const ProductData = styled('div')`
  cursor: pointer;
  padding-right: 12px;
  margin-bottom: 15px;
`;

const DescriptionText = styled('div')`
  font-size: 12px;
  line-height: 22px;
  color: var(--grey718);
`;

export function AddonSingleProductInformation({ basicItem,loadingItem }) {
  return (
    <ProductInformationContainer>
      {loadingItem ? <LoaderIcon/> : ( <ProductData>
        <ProductNumber fullId={basicItem?.no} />
        <ProductTitle>{basicItem?.name}</ProductTitle>
        <DescriptionText>{basicItem?.description}</DescriptionText>
      </ProductData>)}
     
      <img
        alt=""
        height={70}
        data-testid="productImage"
        src={basicItem?.smallImageUrl}
      />
    </ProductInformationContainer>
  );
}
