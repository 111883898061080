import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TASKS_SERVICE_URL, fetchAPI } from "./API/tasks.service.helper";
import { Image } from "@coworker/apprestructured/src/tasks/types/imageUpload";

type Data = {
  creator_id: string;
  text: string;
};

interface CreateNoteProps {
  data: Data;
  images: Image[];
}

export async function createNote(taskId: string, note: Data, images: Image[]) {
  const path = `/v1/task/${taskId}/create_note`;
  const url = `${TASKS_SERVICE_URL}${path}`;
  const { result } = await fetchAPI("POST", url, { note, images }, "json");
  return result;
}

export const useCreateNote = (taskId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (note: CreateNoteProps) =>
      createNote(taskId, note.data, note.images),
    onSuccess: async (data) => {
      const hasNoteImages = data?.hasImages;
      if (hasNoteImages) {
        await queryClient.invalidateQueries({
          queryKey: ["task", "notes", "images", data?.taskId],
        });
      }
      await queryClient.invalidateQueries({
        queryKey: ["task", "notes", data?.taskId],
      });
    },
  });
};
