import { useTranslation } from "@coworker/locales";
import { useCustomAllSelectOption } from "../../shared/filters/utils";
import { FilterType, InputType } from "../../shared/filters/types";
import { StoreTeam } from "@coworker/types/lib/storeteam";

export function useAssigneeOrCreatorFilter(
  id: "assignedTo" | "createdBy",
  fixaUid: string,
  transKey: string,
  teams: StoreTeam[]
) {
  const { t } = useTranslation();
  const defaultValue = { filterId: id, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: id,
      title: t(transKey),
      options: [
        {
          value: fixaUid,
          title: t("meString"),
        },
        ...teams.map((team) => ({
          value: team.id,
          title: team.name,
        })),
      ],
      enabled: true,
      defaultValue,
    },
    t("allString")
  );
}
