import { useTranslation } from "@coworker/locales";
import {
  InputFilter,
  FilterValue,
  GroupFilter,
  InputType,
} from "../../shared/filters/types";
import { usePickupLocationFilter } from "./usePickupLocationFilter";
import { useRefillLocationFilter } from "./useRefillLocationFilter";
import { useSingleLocationFilter } from "./useSingleLocationFilter";

export const LOCATIONS_ID = "locations";

export function useLocationFilter(filterValues: FilterValue[]): GroupFilter {
  const { t } = useTranslation();
  const filters: InputFilter[] = [
    usePickupLocationFilter(filterValues),
    useRefillLocationFilter(filterValues),
    useSingleLocationFilter(filterValues),
  ];
  const enabled = filters.reduce(
    (accumulator, filter) => (filter.enabled ? true : accumulator),
    false
  );

  return {
    inputType: InputType.Group,
    id: LOCATIONS_ID,
    title: t("location3String"),
    enabled,
    filters,
  };
}
