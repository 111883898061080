import {useState} from "react";
import styles from "./discover-now.module.css";
import {
  FixaAccordion,
  FixaAccordionItem,
} from "../../../shared/wrappers/FixaAccordion/FixaAccordion";
import FixaImage from "@coworker/apprestructured/src/shared/wrappers/FixaImage/FixaImage";
import { Trans } from "@coworker/locales";
import StepOneImage from "@coworker/app/src/assets/images/upptacka-step1.png";
import StepTwoImage from "@coworker/app/src/assets/images/upptacka-step2.png";
import StepThreeImage from "@coworker/app/src/assets/images/upptacka-step3.png";
import trackerHelper from "@coworker/app/src/helpers/tracker";

export const UpptackaSetUpInfo = () => {
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  return (
    <FixaAccordion>
      <FixaAccordionItem
        id="upptacka-setup-info"
        title={<Trans>howToSetUpUpptackaHomescreenString</Trans>}
        subtle={false}
        open={isAccordionOpen}
        onClick={() => {
          setAccordionOpen(!isAccordionOpen);
          trackerHelper.trackUpptackaSetUpHelpClick();
        }}
      >
        <FixaImage src={StepOneImage} />
        <div className={styles["step-title"]}>
          <Trans values={{ number: 1 }}>stepNumberString</Trans>
        </div>
        <div className={styles["step-description"]}>
          <Trans>upptackaSetUpStep1String</Trans>
        </div>
        <div className={styles["divider"]} />
        <FixaImage src={StepTwoImage} />
        <div className={styles["step-title"]}>
          <Trans values={{ number: 2 }}>stepNumberString</Trans>
        </div>
        <div className={styles["step-description"]}>
          <Trans values={{ password: "Upptacka" }}>
            upptackaSetUpStep2String
          </Trans>
        </div>
        <div className={styles["divider"]} />
        <FixaImage src={StepThreeImage} />
        <div className={styles["step-title"]}>
          <Trans values={{ number: 3 }}>stepNumberString</Trans>
        </div>
        <div className={styles["step-description"]}>
          <Trans>upptackaSetUpStep3String</Trans>
        </div>
      </FixaAccordionItem>
    </FixaAccordion>
  );
};
