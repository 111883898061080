import React from "react";
import styled from "styled-components";

const Container = styled('div')`
  background: white;
  padding: 30px 28px;
`;
const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & > * {
    margin: 0.3125rem;
  }
`;
const Short = styled('div')`
  width: 6.875rem;
  height: 1rem;
  background: var(--grey150);
  background: linear-gradient(to top, var(--grey100), var(--grey150));
`;
const Long = styled('div')`
  width: 9.687rem;
  height: 0.625rem;
  background: var(--grey150);
  background: linear-gradient(to top, var(--grey100), var(--grey150));
`;

const TaskCardSkeleton = () => (
  <Container>
    <Content>
      <Short />
      <Long />
      <Long />
    </Content>
  </Container>
);

export default TaskCardSkeleton;
