import React from "react";
import { Trans } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import BasicWidget from "@coworker/components/src/components/BasicWidget";
import { ReactComponent as WrenchIcon } from "../../../assets/svg/wrench.svg";
import taskStateOptions from "@coworker/enums/taskState";
import trackerHelper from "../../../helpers/tracker";
import { useUpdateTaskMutation } from "@coworker/apprestructured/src/tasks/hooks/useUpdateTaskById";

export default function TaskDetailMfaqResolution({
  task,
  taskId,
  initialResolution,
  onChange,
}) {
  const { getInput } = useInputPopup();
  const updateTask = useUpdateTaskMutation();

  const getResolution = async () => {
    trackerHelper.MFAQ.trackTaskResolutionWidget(
      task.question_text,
      task.question_area
    );
    const resolutionText = await getInput("mfaqResolution", {
      initialResolution,
      onChange,
    });
    onChange(resolutionText);
    const data = { finished_summary: resolutionText };
    await updateTask.mutateAsync({ data, taskId });
  };

  const isTaskClosedOrCompleted =
    task.state === taskStateOptions.COMPLETED ||
    task.state === taskStateOptions.CLOSED;
  const isTaskNotPickedUp = task.state === taskStateOptions.UNASSIGNED;
  const showWidgetSubTitle = isTaskNotPickedUp
    ? ""
    : initialResolution || <Trans>mfaqResolutionWidgetSubTitle</Trans>;

  return (
    <BasicWidget
      {...(!isTaskClosedOrCompleted &&
        !isTaskNotPickedUp && { onClick: getResolution })}
      testId="mfaqResolutionWidget"
      text={showWidgetSubTitle}
      title={<Trans>mfaqResolution</Trans>}
      icon={<WrenchIcon width={22} height={22} />}
      deactivated={false}
    />
  );
}
