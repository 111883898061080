import {
  AcceptPQReportComponent,
  CompletePickingComponent,
  CompleteTaskComponent,
  generateWithChanges,
  PickUpTaskComponent,
  RejectPQReportComponent,
} from "./components";
import {
  shouldDisplayCompleteTask,
  shouldDisplayPickupTask,
  shouldDisplayCompletePicking,
  shouldDisplayProductQualityCompleteActions,
} from "./helpers";
import { Trans } from "@coworker/locales";

const PickUpTask = {
  component: PickUpTaskComponent,
  display: ({ task, profile }) => shouldDisplayPickupTask(task, profile),
};

const CompleteTask = {
  component: CompleteTaskComponent,
  display: ({ task, profile, picking }) =>
    shouldDisplayCompleteTask(task, profile, picking),
};

const AcceptPQReport = {
  component: AcceptPQReportComponent,
  display: ({ task, profile }) =>
    shouldDisplayProductQualityCompleteActions(task, profile),
};

const RejectPQReport = {
  component: RejectPQReportComponent,
  display: ({ task, profile }) =>
    shouldDisplayProductQualityCompleteActions(task, profile),
};

const CompletePicking = {
  component: CompletePickingComponent,
  display: ({ task, profile, picking }) =>
    shouldDisplayCompletePicking(task, profile, picking),
};

const CompleteWithChanges = {
  component: generateWithChanges(<Trans>completeWithChangesString</Trans>),
  display: ({ task, profile, online, picking }) =>
    shouldDisplayCompleteTask(task, profile, picking) && online,
};

const CompletePickingWithChanges = {
  component: generateWithChanges(
    <Trans>completePickingChangesButtonString</Trans>,
    true
  ),
  display: ({ task, profile, online, picking }) =>
    shouldDisplayCompletePicking(task, profile, picking) && online,
};

export {
  PickUpTask,
  CompleteTask,
  CompletePicking,
  CompleteWithChanges,
  CompletePickingWithChanges,
  AcceptPQReport,
  RejectPQReport,
};
