import { ChoiceItem } from "@ingka/choice";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/focus/dist/style.css";

interface Props {
  id: string;
  title: string;
  onClick: (value: any) => void;
}
export const FixaChoiceItem = (props: Props) => {
  return <ChoiceItem {...props} />;
};
