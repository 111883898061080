import { useTranslation } from "react-i18next";
import { Trans } from "@coworker/locales";

import FullScreenPopup from "../../../layout/components/FullScreenPopup/FullScreenPopup";
import { AppBarAction } from "../../../layout/components/AppBar/AppBar";
import FullColorSection from "./components/FullColorSection/FullColorSection";

import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";
import ArrowRightIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ArrowRightIconPath";
import CheckmarkIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CheckmarkIconPath";
import styles from "./naming-guidelines-popup.module.css";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";

interface NamingGuidelinesPopupProps {
  handleClose: () => void;
}

const NamingGuidelinesPopup = ({ handleClose }: NamingGuidelinesPopupProps) => {
  const { t } = useTranslation();
  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <FixaSSRIcon paths={CrossIconPath} />,
      onClick: handleClose,
      position: "right",
    },
  ];

  // prettier-ignore
  const SHOWROOM = t("customLocationNamingGuidelinesShowroom");
  const MARKET_HALL = t("customLocationNamingGuidelinesMarketHall");
  const OTHER = t("customLocationNamingGuidelinesOther");
  const NEXT_PART = t("customLocationNamingGuidelinesNextPart");
  const HFB_DIGITS = t("customLocationNamingGuidelinesHfbDigits");
  const OPEN_WALLET = t("customLocationNamingGuidelinesOpenWallet");
  const CASHLINE = t("customLocationNamingGuidelinesCashline");
  const LAST_PART = t("customLocationNamingGuidelinesLastPart");
  const NO_GRID_CODE = t("customLocationNamingGuidelinesNoGridCode");
  const IN_THE_END = t("customLocationNamingGuidelinesInTheEnd");
  const REFILL_MAP = t("customLocationNamingGuidelinesRefillMap");
  // prettier-ignore
  const WHY_DOES_THIS_MATTER = t("customLocationNamingGuidelinesWhyDoesThisMatter");
  // prettier-ignore
  const THATS_WHY_IT_MATTERS = t("customLocationNamingGuidelinesThatsWhyItMatters");
  // prettier-ignore
  const HOW_SHOULD_THE_LOCATION_BE_NAMED = t("customLocationNamingGuidelinesHowShouldTheLocationBeNamed");
  // prettier-ignore
  const THE_NAMING_SHOULD_ADHERE = t("customLocationNamingGuidelinesTheNamingShouldAdhere");

  const ConventionOne = () => (
    <FullColorSection color="blue">
      <div className={styles["convention-number"]}>1</div>
      <FixaText tagName="p">
        <Trans>customLocationNamingGuidelinesLocationNameStart</Trans>
      </FixaText>
      <table className={styles["convention-table"]}>
        <tr>
          <td>
            <div className={`${styles["example-label"]} ${styles["small"]}`}>
              S
            </div>
          </td>
          <td>
            <FixaSSRIcon paths={ArrowRightIconPath} />
          </td>
          <td>{SHOWROOM}</td>
        </tr>
        <tr>
          <td>
            <div className={`${styles["example-label"]} ${styles["small"]}`}>
              M
            </div>
          </td>
          <td>
            <FixaSSRIcon paths={ArrowRightIconPath} />
          </td>
          <td>{MARKET_HALL}</td>
        </tr>
        <tr>
          <td>
            <div className={`${styles["example-label"]} ${styles["small"]}`}>
              OT
            </div>
          </td>
          <td>
            <FixaSSRIcon paths={ArrowRightIconPath} />
          </td>
          <td>{OTHER}</td>
        </tr>
      </table>
    </FullColorSection>
  );

  const ConventionTwo = () => (
    <FullColorSection color="blue">
      <div className={styles["convention-number"]}>2</div>
      <FixaText tagName="p">{NEXT_PART}</FixaText>
      <table className={styles["convention-table"]}>
        <tr>
          <td>
            <div className={`${styles["example-label"]}`}>
              <span>HFB</span>XX
            </div>
          </td>
          <td>
            <FixaSSRIcon paths={ArrowRightIconPath} />
          </td>
          <td>{HFB_DIGITS}</td>
        </tr>
        <tr>
          <td>
            <div className={`${styles["example-label"]}`}>OW</div>
          </td>
          <td>
            <FixaSSRIcon paths={ArrowRightIconPath} />
          </td>
          <td>{OPEN_WALLET}</td>
        </tr>
        <tr>
          <td>
            <div className={`${styles["example-label"]}`}>CL</div>
          </td>
          <td>
            <FixaSSRIcon paths={ArrowRightIconPath} />
          </td>
          <td>{CASHLINE}</td>
        </tr>
        <tr>
          <td>
            <div className={`${styles["example-label"]}`}>AS</div>
          </td>
          <td>
            <FixaSSRIcon paths={ArrowRightIconPath} />
          </td>
          <td>ASIS</td>
        </tr>
      </table>
    </FullColorSection>
  );

  const ConventionThree = () => (
    <FullColorSection color="blue">
      <div className={styles["convention-number"]}>3</div>
      <FixaText tagName="p">{LAST_PART}</FixaText>
      <table className={styles["convention-table"]}>
        <tr>
          <td>
            <div className={`${styles["example-label"]}`}>A01</div>
            <div className={`${styles["example-label"]}`}>F12</div>
            <div className={`${styles["example-label"]}`}>G10</div>
          </td>
        </tr>
      </table>
      <FixaText tagName="p" className={styles["italic"] || ""}>
        {NO_GRID_CODE}
      </FixaText>
    </FullColorSection>
  );

  const ConventionFinal = () => (
    <FullColorSection color="green">
      <div className={styles["convention-number"]}>
        <FixaSSRIcon paths={CheckmarkIconPath} />
      </div>
      <FixaText tagName="p">{IN_THE_END}</FixaText>
      <div className={styles["centered"]}>
        <div className={`${styles["example-label"]} ${styles["large"]}`}>
          S01F12
        </div>
        <FixaText tagName="p">or</FixaText>
        <div className={`${styles["example-label"]} ${styles["large"]}`}>
          MOWA01
        </div>
      </div>
      <FixaText tagName="p">{REFILL_MAP}</FixaText>
      <FixaText tagName="p">
        <b>{SHOWROOM} - HFB01</b> - F12
      </FixaText>
      <FixaText tagName="p">
        <b>
          {MARKET_HALL} - {OPEN_WALLET}
        </b>{" "}
        - A01
      </FixaText>
    </FullColorSection>
  );

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("customLocationNamingGuidelinesTitle"),
        actions: appBarActions,
      }}
    >
      <div className={styles["content"]}>
        <div className={styles["introduction"]}>
          <FixaText tagName="h2" textStyle="Heading.M">
            {WHY_DOES_THIS_MATTER}
          </FixaText>
          <FixaText
            tagName="p"
            dangerouslySetInnerHTML={{ __html: THATS_WHY_IT_MATTERS }}
          />
          <FixaText tagName="h2" textStyle="Heading.M">
            {HOW_SHOULD_THE_LOCATION_BE_NAMED}
          </FixaText>
          <FixaText tagName="p">{THE_NAMING_SHOULD_ADHERE}</FixaText>
        </div>
        <ConventionOne />
        <ConventionTwo />
        <ConventionThree />
        <ConventionFinal />
      </div>
    </FullScreenPopup>
  );
};

export default NamingGuidelinesPopup;
