import React from "react";
import { useTranslation } from "@coworker/locales";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import trackerHelper from "../../helpers/tracker";
import styled from "styled-components";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { useTeamId, useUserId } from "../../core/auth/useLoggedInUser";
import { AssignToUserOrTeam } from "@coworker/apprestructured/src/shared/components/AssignToUserOrTeam/AssignToUserOrTeam";

const Container = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 85%;
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const OverflowBackground = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grey800);
  opacity: 0.8;
`;

const SearchContainer = styled('div')`
  margin: 24px;
`;

function NewAssign(props) {
  const userId = useUserId();
  const teamId = useTeamId();

  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = React.useState("");

  return (
    <React.Fragment>
      <OverflowBackground
        onClick={() => {
          trackerHelper.trackDismissModal(false);
          props?.onClose?.();
        }}
        data-testid="assignToBackdrop"
      />
      <Container>
        <FixaModalHeader
          title={t("assignString")}
          data-testid="heading"
          closeBtnClick={props.onClose}
        />
        <SearchContainer data-testid="searchContainer">
          <FixaSearch
            id="new-assign-search"
            placeholder={t("searchBasicString")}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            data-testid="searchQuery"
            onClear={() => {
              setSearchQuery("");
            }}
          />
        </SearchContainer>
        <AssignToUserOrTeam
          spacer={false}
          showSuggestions={true}
          searchQuery={searchQuery}
          itemNo={props.shortId}
          assignees={props.assignees}
          myUserAndTeamId={{ userId: userId, teamId: teamId }}
          onSubmit={props.onSubmit}
          handoff={props.handoff}
        />
      </Container>
    </React.Fragment>
  );
}

export default NewAssign;
