import { MediaTypes } from "../../constants";
import { MediaNameInput } from "./MediaNameInput";
import { Media } from "../../types/media";
import { getMediaTypeText } from "../../tools";
import {
  FlexContainer,
  LeftContainer,
  RightContainer,
} from "../../styles/styles";
import { Text } from "../../Atoms/Text";
import styled from "styled-components";
import { FixaFormField } from "@coworker/apprestructured/src/shared/wrappers/FixaFormField/FixaFormField";
import {
  checkEmptyMediaName,
  checkMediaName,
  validationMessage,
} from "../../Organisms/Settings/SettingsForm.helper";
import { useTranslation } from "@coworker/locales";

const LimitWidth = styled('div')`
  width: calc(100% - 42px);
`;

const FlexSpaceBetween = styled('div')`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 42px);
`;

const PaddedFixaFormField = styled(FixaFormField)`
  padding-right: 12px;
`;

interface RadioButtonWithInputProps {
  media: Media;
  medias: Media[];
  mediaType: MediaTypes;
  currentCheckedMediaType: MediaTypes;
  onSave: (newMedia: Media) => void;
  onRadioChange: (mediaType: MediaTypes) => void;
}

export const RadioButtonWithInput = ({
  media,
  medias,
  mediaType,
  onSave,
  currentCheckedMediaType,
  onRadioChange,
}: RadioButtonWithInputProps) => {
  const { t } = useTranslation();
  const handleRadioChange = () => {
    onRadioChange(
      currentCheckedMediaType === mediaType ? MediaTypes.NONE : mediaType
    );
  };

  const label = getMediaTypeText(mediaType);

  return (
    <FlexContainer $direction={"column"}>
      <FlexSpaceBetween>
        <LeftContainer>
          <Text size={"medium"} text={label} />
        </LeftContainer>
        <RightContainer>
          <input
            type="radio"
            checked={currentCheckedMediaType === mediaType}
            onChange={handleRadioChange}
            style={{ width: "24px", height: "24px" }}
            name="mediaType"
          />
        </RightContainer>
      </FlexSpaceBetween>
      {currentCheckedMediaType === mediaType && (
        <LimitWidth>
          <PaddedFixaFormField
            shouldValidate={
              !medias ||
              checkEmptyMediaName(media) ||
              checkMediaName(media, medias)
            }
            validation={{
              msg: validationMessage(media, medias ?? [], t),
            }}
          >
            <MediaNameInput
              mediaType={mediaType}
              media={media}
              onSave={(newMedia) => onSave(newMedia)}
              withAsterisk={false}
            />
          </PaddedFixaFormField>
        </LimitWidth>
      )}
    </FlexContainer>
  );
};
