import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";
import {useCallback} from "react";
import { useCreateTask } from "../../../hooks/TaskActions/useCreateTask";
import { useToggle } from "../../../hooks/useToggle";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useAddFollowUpTaskId as addFollowUpTaskIdPG } from "../CommonComponents/Repositories/useArticleService";

export function useCreateTestbuyTask() {
  const { call: createTask } = useCreateTask();
  const [loading, toggleLoading] = useToggle(false);
  const { push } = useWorkspacesAction();

  const onCreateClick = useCallback(
    async (task: TestBuyFollowUp, articleKey: string, testbuyId: string) => {
      toggleLoading();

      try {
        // Create the task and retrieve its ID
        const createdTaskId = await createTask(task, task.images);

        // Ensure all parameters are available before calling addFollowUpTaskIdPG
        if (testbuyId && articleKey && createdTaskId) {
          await addFollowUpTaskIdPG(testbuyId, articleKey, createdTaskId);
        } else {
          console.error("Missing parameters: Cannot call addFollowUpTaskIdPG", {
            testbuyId,
            articleKey,
            createdTaskId,
          });
        }

        // Navigate to the testbuy page
        push(`/testbuy/${testbuyId}`);
      } catch (error) {
        console.error("Error creating or linking the follow-up task:", error);
      } finally {
        // Ensure loading toggle is always called
        toggleLoading();
      }
    },
    [createTask, push, toggleLoading]
  );

  return { loading, onCreateClick };
}
