import Home from "./Home";
import styles from "./Linkbar.module.css";
import { FixaSkeleton } from "../../wrappers/FixaSkeleton/FixaSkeleton";

function LinkbarLoading({
  "data-testid": dataTestId,
}: {
  "data-testid"?: string | undefined;
}) {
  return (
    <div data-testid={dataTestId} className={styles["linkbar-loading"]}>
      <Home />
      <FixaSkeleton />
    </div>
  );
}

export default LinkbarLoading;
