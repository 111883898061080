import React from "react";
import styled, { css } from "styled-components";
import { Trans } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import BasicWidget from "@coworker/components/src/components/BasicWidget";
import { ReactComponent as CalendarIcon } from "../../../assets/svg/calendar-cropped.svg";
import { ReactComponent as ExpeditTag } from "../../../assets/svg/EXPEDIT-tag.svg";
import { ReactComponent as DateStampMarker } from "../../../assets/svg/date-stamp-marker.svg";
import Instructions from "../../Instructions";
import profilePreferences from "@coworker/enums/profilePreferences";

const { DISABLE_POPUPS_DATE_STAMP } = profilePreferences;

const Overlay = styled('div')`
  position: relative;
  ${({ minHeight }) =>
    css`
      min-height: ${minHeight};
    `}
  max-width: 100%;
  svg {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
  }
`;

export default function TaskFormDateStamp({
  value,
  onChange,
  additionalSubTitle = <></>,
}) {
  const { getInput } = useInputPopup();
  const getNumber = async () => {
    const response = await getInput("number", {
      value,
      bigTitle: <Trans>dateStampString</Trans>,
      instruction: (
        <Instructions
          title={<Trans>whereCanIfindString</Trans>}
          text={
            <Overlay minHeight="133px">
              <ExpeditTag />
              <DateStampMarker />
            </Overlay>
          }
          type={DISABLE_POPUPS_DATE_STAMP}
        />
      ),
    });
    if (response) onChange(response);
  };

  return (
    <BasicWidget
      tabIndex={0}
      onClick={getNumber}
      testId="dateStampWidget"
      text={value}
      title={<Trans>dateStampString</Trans>}
      icon={<CalendarIcon width={22} height={22} />}
      additionalSubTitle={!value ? additionalSubTitle : <></>}
    />
  );
}
