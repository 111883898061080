import ProductInfoRow from "../../ProductInfoRow";
import { Container } from "./_common";
import { StockDetails } from "../../StockDetails";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useItemInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfo";

export default function TaskFormProduct({
  value: { productId, productType },
  conf: { withAvailability = false },
  disabled,
}) {
  const { push } = useWorkspacesAction();
  const { itemInfo } = useItemInfo(productId);

  const showProductInfo = async () => {
    push(`/product/${itemInfo.type}${itemInfo.no}`);
  };

  const full_id = `${productType || ""}${productId || ""}`;
  const unitCode = "PIECES";
  return productId ? (
    <Container disabled={disabled} data-testid="product" tabIndex={0}>
      <ProductInfoRow art={productId} onClick={showProductInfo} imageLoader />
      {!!withAvailability && (
        <StockDetails full_id={full_id} unitCode={unitCode} />
      )}
    </Container>
  ) : null;
}
