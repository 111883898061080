import { useMemo, useState, Fragment } from "react";
import { Trans } from "@coworker/locales";
import styled from "styled-components";
import taskStateOptions from "@coworker/enums/taskState";
import AddNote from "../../notes/AddNote";
import { createdAtAscending } from "@coworker/reusable/helpers/taskSorters";
import { DetailSection, DetailSectionLeft, SectionTitle } from "./_common";
import Note from "../../notes/Note";
import { useTaskNotes } from "../../../hooks/useTaskNotes";
import { useTaskNotesImagesDetails } from "@coworker/apprestructured/src/tasks/hooks/useTaskNotesImagesDetails";
import { useTaskActivities } from "@coworker/apprestructured/src/tasks/hooks/useTaskActivities";
import { SimpleLoadingBall } from "@coworker/apprestructured/src/shared/simple/SimpleLoading/SimpleLoading";

const Notes = styled("div")`
  width: 100%;
  margin-top: 20px;
`;
const StyledDetailSection = styled(DetailSection)`
  border: none;
`;

function shouldDisplayAddNotes(task) {
  return [taskStateOptions.ASSIGNED].includes(task.state);
}

const uniqueReducer =
  (base = new Set()) =>
  (acc, task) => {
    if (base.has(task.id)) return acc;
    base.add(task.id);
    return acc.concat(task);
  };

function joinAndSort(notes, activities) {
  const list = [...(notes || []), ...(activities || [])];
  list.sort(createdAtAscending);
  // Reduce to unique to avoid duplicated notes/activities.
  return list.reduce(uniqueReducer(), []);
}

function Activity({ task, taskId }) {
  const { data: notes = [] } = useTaskNotes(taskId);
  const { data: taskNotesImagesDetails } = useTaskNotesImagesDetails(taskId);
  const { data: activities, isLoading } = useTaskActivities(taskId);
  const notesAndActivities = useMemo(() => {
    if (!isLoading) {
      return joinAndSort(notes, activities);
    }
    return [];
  }, [notes, activities, isLoading]);
  const [localImages, setLocalImages] = useState([]);
  const [createdNote, setCreatedNote] = useState(null);

  return (
    <Fragment>
      {isLoading && <SimpleLoadingBall text={""} size={"medium"} />}
      {notesAndActivities?.length > 0 && (
        <StyledDetailSection data-testid="historySection">
          <DetailSectionLeft>
            <SectionTitle data-testid="historyTitle">
              <Trans>historyString</Trans>
            </SectionTitle>
            <Notes>
              {notesAndActivities.map((note) => (
                <Note
                  key={note.id || note.text}
                  noteId={note.id}
                  note={note}
                  taskId={taskId}
                  localImages={localImages}
                  images={taskNotesImagesDetails}
                />
              ))}
              {createdNote &&
                !notesAndActivities.some(
                  (note) =>
                    note.text === createdNote.text &&
                    note.creator_id === createdNote.creator_id
                ) && (
                  <Note
                    key={`created-${createdNote.text}`}
                    noteId={createdNote.id}
                    note={createdNote}
                    localImages={localImages}
                    images={taskNotesImagesDetails}
                  />
                )}
            </Notes>
          </DetailSectionLeft>
        </StyledDetailSection>
      )}
      {shouldDisplayAddNotes(task) && (
        <AddNote
          taskId={taskId}
          setCreatedNote={setCreatedNote}
          setLocalImages={setLocalImages}
          localImages={localImages}
        />
      )}
    </Fragment>
  );
}

export default Activity;
