import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";

async function fetchPIPHome(itemNo: string) {
  const url = `${TASKS_SERVICE_URL}/items/pip-home?itemNo=${itemNo}`;
  const { result } = await fetchAPI("GET", url);
  return result;
}

export function usePIPHome(itemNo: string) {
  return useQuery({
    queryKey: ["PIP", "home", itemNo],
    queryFn: async () => await fetchPIPHome(itemNo),
    staleTime: INTERVAL.DAY,
    refetchOnWindowFocus: false,
    enabled: !!itemNo,
  });
}
