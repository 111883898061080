import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/svg/icon-close-large.svg";
import { ReactComponent as IssueOther } from "@coworker/reusable/svg/three-dots.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/icon-search.svg";
import styled, { css } from "styled-components";
import { useIntersect } from "@coworker/components";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import {
  TopBarBackButton,
  TopBarButton,
  TopBarTitle,
} from "../../core/components/TopBarComponents";

export const headingSize = {
  sticky: 70,
  notSticky: 42,
};

// This is to be moved to AppBar
const DetailMenu = styled("div")`
  height: 42px;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: var(--z-heading-menu);
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  transition-property: margin-top;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  margin-top: 0px;
  ${(props) => {
    switch (props.state) {
      case "notSticky":
        return css`
          background-color: transparent;
          top: 43px;
          height: ${headingSize.notSticky}px;
        `;
      default:
      case "sticky":
        return css`
          height: ${headingSize.sticky}px;
          margin-top: 0px;
          background-color: var(--white);
          align-items: flex-end;
          border-bottom: ${props.border && "1px solid #f2f2f2;"};
        `;
      case "hidden":
        return css`
          height: ${headingSize.sticky}px;
          margin-top: -${headingSize.sticky}px;
          background-color: var(--white);
          align-items: flex-end;
          border-bottom: ${props.border && "1px solid #f2f2f2;"};
        `;
    }
  }}
  ${(props) => props.css}
`;

const TextButton = styled("div")`
  height: 42px;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  align-items: center;
  font-size: 14px;
  background: none;
  cursor: pointer;
  ${({ disabled }) =>
    disabled
      ? "color: var(--grey500); cursor: default;"
      : "color: var(--grey900);"}
`;

const MainContainer = styled("div")`
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
`;

const ActionContainer = styled("div")`
  flex: ${(props) => props.actionContainerFlex || 1};
  display: flex;
  align-items: flex-end;

  &:last-child {
    justify-content: flex-end;
  }
`;

const Spacer = styled("div")`
  height: ${({ height }) => `${height}px`};
  visibility: hidden;
  flex-shrink: 0;
`;

const StyledSearchIcon = styled(SearchIcon)`
  width: 50px;
  height: 50px;
`;

// Adding seSticky prop to the StickyHeader component
// If 'setSticky' changes too often, find the parent component that defines it and
//  wrap that definition in useCallback

function StickyHeader({
  topContent,
  children = null,
  alwaysSticky = false,
  isHidden = false,
  setSticky = () => null,
}) {
  const [intersectRef, entry] = useIntersect({ threshold: 0 });
  const isSticky = (!entry.isIntersecting && !entry.isInitial) || alwaysSticky;
  React.useEffect(() => {
    setSticky(isSticky);
  }, [isSticky, setSticky]);

  return (
    <React.Fragment>
      <div ref={intersectRef} data-testid="heading">
        {topContent}
        <DetailMenu
          state={
            isHidden && isSticky ? "hidden" : isSticky ? "sticky" : "notSticky"
          }
        >
          <MainContainer>{children}</MainContainer>
        </DetailMenu>
      </div>
      <Spacer height={isSticky ? headingSize.sticky : headingSize.notSticky} />
    </React.Fragment>
  );
}

export const Heading = ({
  title,
  subTitle,
  children,
  showMenu,
  showBack = true,
  handleMenu,
  handleBack: customOnBack,
  alwaysSticky = false,
  customLeftAction, // Used by Task Form ("Cancel")
  customRightAction, // Used by Notifications ("Read all") and Task Form ("Save")
  rightComponent, // Used by Insights and MFAQ.
  isHidden,
  border = true,
  css,
  testId,
  actionContainerFlex, // ONLY used for Insights Store
  transparent = false, // ONLY used by Profile.jsx
  showSearch = false,
  onTapSearch,
}) => {
  const { pop } = useWorkspacesAction();
  const [intersectRef, entry] = useIntersect({ threshold: 0 });
  const isSticky = (!entry.isIntersecting && !entry.isInitial) || alwaysSticky;

  function defaultBack(event) {
    pop();
    event.stopPropagation();
  }

  if (customLeftAction && !customLeftAction.onClick) {
    customLeftAction.onClick = defaultBack;
  }

  // TODO: Rework this to use StickyHeader
  return (
    <>
      <div ref={intersectRef} data-testid="heading">
        {children}
        <DetailMenu
          state={
            isHidden && isSticky ? "hidden" : isSticky ? "sticky" : "notSticky"
          }
          border={border}
          css={css}
        >
          <MainContainer>
            <ActionContainer actionContainerFlex={actionContainerFlex}>
              {showBack && (
                <TopBarBackButton
                  isSticky={isSticky}
                  transparent={transparent}
                  onClick={customOnBack}
                />
              )}
              {customLeftAction && (
                <TextButton
                  tabIndex={0}
                  onClick={customLeftAction.onClick}
                  data-testid={customLeftAction.enum}
                >
                  {customLeftAction.text}
                </TextButton>
              )}
            </ActionContainer>

            <TopBarTitle
              isSticky={isSticky}
              testId={testId}
              title={title}
              subTitle={subTitle}
            />
            <ActionContainer
              actionContainerFlex={actionContainerFlex}
              data-testid="actionContainer"
            >
              {showMenu && (
                <TopBarButton
                  isSticky={isSticky}
                  transparent={transparent}
                  onClick={handleMenu}
                  data-testid="dots"
                >
                  <IssueOther />
                </TopBarButton>
              )}
              {showSearch && (
                <StyledSearchIcon
                  onClick={() => {
                    onTapSearch && onTapSearch();
                  }}
                  data-testid="header-search"
                />
              )}
              {customRightAction && (
                <TextButton
                  tabIndex={0}
                  onClick={customRightAction.onClick}
                  disabled={customRightAction.disabled}
                  data-testid={customRightAction.enum}
                >
                  {customRightAction.text}
                </TextButton>
              )}
              {rightComponent}
            </ActionContainer>
          </MainContainer>
        </DetailMenu>
      </div>
    </>
  );
};

export const TopBar = ({ title, subTitle, children, rightAction }) => {
  return (
    <React.Fragment>
      <div data-testid="heading">
        {children}
        <DetailMenu>
          <MainContainer>
            <ActionContainer />
            <TopBarTitle isSticky title={title} subTitle={subTitle} />
            <ActionContainer>{rightAction}</ActionContainer>
          </MainContainer>
        </DetailMenu>
      </div>
      <Spacer height={headingSize.sticky} />
    </React.Fragment>
  );
};

export const TopBarPopup = ({
  title = "",
  testId = "",
  children = null,
  onClose = () => null,
}) => {
  const [isSticky, setSticky] = React.useState(false);
  return (
    <StickyHeader setSticky={setSticky} topContent={children}>
      <ActionContainer />
      <TopBarTitle isSticky={isSticky} title={title} />
      <ActionContainer>
        <TopBarButton onClick={onClose} data-testid={testId}>
          <CloseIcon />
        </TopBarButton>
      </ActionContainer>
    </StickyHeader>
  );
};
