import { useStoreId } from "@coworker/app/src/core/auth/useLoggedInUser";
import { getStoreTeams } from "@coworker/app/src/hooks/API/core.service";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";
import { Team } from "../types/team";

export function useTeamsQuery() {
  const storeId = useStoreId();
  return useQuery<Team[]>({
    queryKey: ["storeTeams", storeId],
    queryFn: async () => await fetchTeamsByStoreId(storeId),
    enabled: !!storeId,
    staleTime: 2 * INTERVAL.MINUTE,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export async function fetchTeamsByStoreId(storeId: string) {
  return await getStoreTeams(storeId);
}
