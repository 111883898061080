import { useEffect, useState } from "react";
import { Filter, InputType, FilterValue, InputFilter } from "../../types";
import { useTranslation } from "@coworker/locales";
import { FixaModal } from "../../../wrappers/FixaModal/FixaModal";
import { FixaSheets } from "../../../wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "../../../wrappers/FixaModal/FixaModalFooter";
import FixaButton from "../../../wrappers/FixaButton/FixaButton";
import { FixaModalHeader } from "../../../wrappers/FixaModal/FixaModalHeader";
import { FixaModalBody } from "../../../wrappers/FixaModal/FixaModalBody";
import { FixaAccordion } from "../../../wrappers/FixaAccordion/FixaAccordion";
import {
  filterValuesAreTheSame,
  updatedFilterValueFromValues,
} from "../../utils/filter";
import FilterModalCss from "./FilterModal.module.css";
import { InputSelectOption } from "./InputSelectOption";
import { InputMultiSelectOption } from "./InputMultiSelectOption";
import { InputGroupOption } from "./InputGroupOption";

// useFiltersFactory is a hook that returns/runs the filter hooks. This makes
// it possible to reuse the same filters with different filterValues/states.
// This is used to let the filter modal have its own state and only apply that
// to the state used when filtering data when a user presses apply button.
interface FilterModalProps {
  visible: boolean;
  scrollTo?: string;
  filterValues: FilterValue[];
  useFiltersFactory: (
    filterValues: FilterValue[],
    setFilterValues: (values: FilterValue[]) => void
  ) => Filter[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
  onClose: () => void;
}

export const FilterModal = ({
  visible,
  scrollTo,
  useFiltersFactory,
  filterValues,
  defaultValues,
  setFilterValues,
  onClose,
}: FilterModalProps) => {
  const { t } = useTranslation();
  const [savedValues, setSavedValues] = useState<FilterValue[]>(filterValues);
  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const filters = useFiltersFactory(savedValues, setSavedValues);

  useEffect(() => {
    setSavedValues(filterValues);
  }, [filterValues, visible]);

  useEffect(() => {
    if (scrollTo && visible) {
      setOpenFilters([scrollTo]);
    } else {
      setOpenFilters([]);
    }
  }, [scrollTo, visible]);

  const scrollIntoView = (scrollTo: string) => {
    const element = document.getElementById(`filter-accordion-${scrollTo}`);
    element?.scrollIntoView({
      behavior: "instant",
    });
  };

  const onModalOpened = () => {
    if (scrollTo) {
      scrollIntoView(scrollTo);
    }
  };

  const handleOnValueChange = (filter: InputFilter, value: FilterValue) => {
    setSavedValues(updatedFilterValueFromValues(savedValues, filter, value));
    const { dependentOfFilterId } = value;
    if (dependentOfFilterId) {
      const filters = openFilters.filter(
        (filterId) => filterId !== dependentOfFilterId
      );
      filters.push(dependentOfFilterId);
      setOpenFilters(filters);
      setTimeout(() => {
        scrollIntoView(dependentOfFilterId);
      }, 0);
    }
  };

  const handleOnOpenChange = (filter: Filter, isOpen: boolean) => {
    const filters = openFilters.filter((filterId) => filterId !== filter.id);
    if (isOpen) {
      filters.push(filter.id);
    }
    setOpenFilters(filters);
  };

  return (
    <FixaModal
      visible={visible}
      handleCloseBtn={onClose}
      className={FilterModalCss["filters-modal"] ?? ""}
      onModalOpened={onModalOpened}
    >
      <FixaSheets
        alignment={"right"}
        preserveAlignment={false}
        size="small"
        fullSize={true}
        header={
          <>
            <FixaModalHeader
              title={t("filtersString")}
              floating={false}
              closeBtnClick={onClose}
            />
            <div className={FilterModalCss["filters-modal-header-border"]} />
          </>
        }
        footer={
          <FixaModalFooter>
            {savedValues.length > 0 && (
              <FixaButton
                text={t("resetString")}
                type="secondary"
                onClick={() => {
                  setOpenFilters([]);
                  setSavedValues([]);
                }}
              />
            )}
            <FixaButton
              text={t("applyString2", { count: savedValues.length })}
              type="primary"
              onClick={() => {
                if (!filterValuesAreTheSame(savedValues, filterValues)) {
                  setFilterValues(savedValues);
                  setOpenFilters([]);
                  setSavedValues([]);
                }
                onClose();
              }}
            />
          </FixaModalFooter>
        }
      >
        <FixaModalBody>
          <FixaAccordion collapsible={false} padded={false}>
            {filters
              .filter(({ enabled }) => enabled)
              // Put a single grouped filter in the main filter list
              .map((filter) => {
                if (filter.inputType === InputType.Group) {
                  const enabledGroupFilters = filter.filters.filter(
                    ({ enabled }) => enabled
                  );
                  if (enabledGroupFilters.length === 1) {
                    return enabledGroupFilters[0]!;
                  }
                }
                return filter;
              })
              .map((filter) => {
                switch (filter.inputType) {
                  case InputType.Select: {
                    return (
                      <InputSelectOption
                        key={filter.id}
                        filter={filter}
                        filterValues={savedValues}
                        defaultValues={defaultValues}
                        openFilters={openFilters}
                        onChange={(value) => handleOnValueChange(filter, value)}
                        onOpenChange={(isOpen) =>
                          handleOnOpenChange(filter, isOpen)
                        }
                      />
                    );
                  }
                  case InputType.MultiSelect: {
                    return (
                      <InputMultiSelectOption
                        key={filter.id}
                        filter={filter}
                        filterValues={savedValues}
                        defaultValues={defaultValues}
                        openFilters={openFilters}
                        onChange={(value) => handleOnValueChange(filter, value)}
                        onOpenChange={(isOpen) =>
                          handleOnOpenChange(filter, isOpen)
                        }
                      />
                    );
                  }
                  case InputType.Group: {
                    return (
                      <InputGroupOption
                        key={filter.id}
                        filter={filter}
                        filterValues={savedValues}
                        defaultValues={defaultValues}
                        openFilters={openFilters}
                        setFilterValues={(values) => setSavedValues(values)}
                        onOpenChange={(isOpen) =>
                          handleOnOpenChange(filter, isOpen)
                        }
                      />
                    );
                  }
                  default:
                    return null;
                }
              })}
          </FixaAccordion>
        </FixaModalBody>
      </FixaSheets>
    </FixaModal>
  );
};
