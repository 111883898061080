import React from "react";
import styled from "styled-components";
import { ImageSkeleton } from "@coworker/reusable/components/ImageSkeleton";
import { ItemSkeleton } from "./ItemSkeleton";

const SkeletonContainer = styled('div')`
  display: flex;
  & :first-child {
    margin-right: 20px;
  }
`;

function SearchItemSkeleton() {
  return (
    <SkeletonContainer>
      <ImageSkeleton size={66} />
      <ItemSkeleton />
    </SkeletonContainer>
  );
}

export default SearchItemSkeleton;
