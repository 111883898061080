import React, { useRef, useState } from "react";
import { Trans } from "@coworker/locales";
import styles from "./task-list.module.css";
import { SwipeableTaskCard } from "../TaskCard/SwipeableTaskCard";
import { GroupedTasksHeader } from "../GroupedTasksHeader/GroupedTasksHeader";
import { LoadMore } from "../../../shared/components/LoadMore/LoadMore";
import { TaskType } from "../../enums/taskTypes";
import { Item } from "../../../shared/types/item";

interface TaskListProps {
  tasks: any[];
  totalTasksCount: number;
  type: string;
  basicItem: Item;
}

const ITEMS_PER_LOAD = 20;

const TaskList = ({
  tasks,
  totalTasksCount,
  type,
  basicItem,
}: TaskListProps) => {
  const [maxNbrOfVisibleItems, setMaxNbrOfVisibleItems] =
    useState(ITEMS_PER_LOAD);

  const tasksListRef = useRef<HTMLDivElement>(null);

  const filteredTasks = tasks.filter(
    (item) => !item.isProductHeader && !item.isGroupHeader
  );
  const tasksLength = filteredTasks.length;

  const visibleTasks = tasks.slice(
    0,
    Math.min(tasks.length, maxNbrOfVisibleItems)
  );

  const handleLoadMoreTasksClick = () => {
    setMaxNbrOfVisibleItems((prev) => prev + ITEMS_PER_LOAD);
  };

  return (
    <div className={styles["task-list"]}>
      <div className={styles["counter"]}>
        <Trans>viewingString</Trans>
        {` ${tasksLength}/${totalTasksCount} `}
        <Trans>TasksString</Trans>
      </div>
      <div className={styles["tasks"]} ref={tasksListRef}>
        {visibleTasks.map((task, i) => {
          if (task.isGroupHeader) {
            return (
              <GroupedTasksHeader
                key={i}
                selectedGroupByOption={task.groupByOption}
                numberOfTasksInGroup={task.numberOfTaskInGroup}
                groupByTitle={task.groupByTitle}
              />
            );
          } else {
            return (
              <SwipeableTaskCard
                key={task.id + task.group}
                task={task.task_type === TaskType.CUSTOM ? { ...task } : task}
                item={basicItem}
                multiSelect={false}
                isMyTasks={false}
                type={type}
              />
            );
          }
        })}
      </div>
      {maxNbrOfVisibleItems < tasksLength && (
        <LoadMore
          handleClick={handleLoadMoreTasksClick}
          loadedItemsCount={maxNbrOfVisibleItems}
          totalCount={tasksLength}
        />
      )}
    </div>
  );
};

export default TaskList;
