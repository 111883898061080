import React from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { policy as DEFAULT } from "./countries/DEFAULT";
import { renderPolicy } from "./countries/template";
import { policyContent } from "./countries/content";
import { useBestEffortCountryCode } from "../../hooks/useBestEffortCountryCode";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";

const Content = styled('div')`
  margin-top: 10px;
  padding: 29px;
  h1 {
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 20px;
  }
  p {
    padding: 10px 0;
  }
  a {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
  ul {
    padding-left: 29px;
    li {
      padding-top: 6px;
    }
  }
`;

const PrivacyStatement = () => {
  const countryCode = useBestEffortCountryCode();
  const loggedIn = !!useStoreId();
  const content = policyContent[countryCode];

  if (countryCode === "BE") {
    return buildPolicyForBelgium(loggedIn);
  }

  return PrivacyStatementInner({ content, loggedIn });
};
//Special case for Belgium
function buildPolicyForBelgium(loggedIn) {
  const content_BE_EN = policyContent["BE_EN"];
  const content_BE_NL = policyContent["BE_NL"];
  const content_BE_FR = policyContent["BE_FR"];

  const policies = (
    <>
      <PrivacyStatementInner content={content_BE_EN} loggedIn={loggedIn} />
      <PrivacyStatementInner content={content_BE_NL} loggedIn={loggedIn} />
      <PrivacyStatementInner content={content_BE_FR} loggedIn={loggedIn} />
    </>
  );
  return policies;
}

export function PrivacyStatementInner({ content, loggedIn }) {
  const { t } = useTranslation();
  const rendered =
    loggedIn && typeof content === "function"
      ? content({ loggedIn })
      : renderPolicy((loggedIn && content) || DEFAULT);

  return (
    <FullScreenPopup
      noPadding
      linkbar={<Linkbar currentLabel={t("privacyString")} currentAsH1 />}
    >
      <Content data-testid="privacyContent">{rendered}</Content>
    </FullScreenPopup>
  );
}

export default PrivacyStatement;
