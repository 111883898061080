import { useInputPopup } from "../index";
import { Trans } from "@coworker/locales";
import { RelatedTasksLink } from "../../RelatedTasksLink";
import { RelatedLinkContainer } from "../CommonComponents";

const useDuplicateTaskPopup = () => {
  const { getInput } = useInputPopup();

  function getDuplicateTaskConfirmation(count, short_id, gridcode) {
    return getInput("confirmation", {
      question: <Trans>refillAlreadyRequestedString</Trans>,
      description: (
        <>
          <Trans>areYouSureCreateRefillString</Trans>
          <RelatedLinkContainer>
            <RelatedTasksLink
              relatedTasksCount={count}
              productId={short_id}
              i18nKey={
                <Trans values={{ count, gridcode }}>
                  relatedRefillsSumSForLocationtring
                </Trans>
              }
            />
          </RelatedLinkContainer>
        </>
      ),
      positiveText: <Trans>continue2String</Trans>,
      negativeText: <Trans>cancelString</Trans>,
      swapButtons: true,
    });
  }
  return { getDuplicateTaskConfirmation };
};

export default useDuplicateTaskPopup;
