
import TrashCanIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TrashCanIconPath";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

interface DeleteImageButtonProps {
  deleteImage: (images: string[]) => Promise<void>;
  selectedImages: string[];
  disabled: boolean;
}

export const DeleteImageButton = ({
  deleteImage,
  selectedImages,
  disabled,
}: DeleteImageButtonProps) => {
  const handleDelete = async () => {
    await deleteImage(selectedImages);
  };

  return (
    <FixaButton
      disabled={disabled}
      ssrIcon={TrashCanIconPath}
      onClick={handleDelete}
      iconOnly
      style={{
        backgroundColor: "var(--pink)",
        color: "var(--white)",
      }}
    />
  );
};
