import { notClosedTooLongAgo } from "../helpers/taskFilters";
import { useRelatedTasksForProduct } from "@coworker/apprestructured/src/tasks/hooks/useRelatedTasksForProduct";

const empty = [];

export function useTasksForProduct(shortId) {
  const { data: relatedTasks, isLoading } = useRelatedTasksForProduct(
    shortId ?? ""
  );
  if (!isLoading) {
    const chosen = [];
    let ofWhichClosed = 0;
    let formattedCount = 0;
    for (const task of relatedTasks?.list || []) {
      if (task.task_type === "PRODUCT_QUALITY") continue;
      if (task.state === "COMPLETED") continue;
      if (task.state === "CLOSED") {
        if (!notClosedTooLongAgo(task)) continue; // Ignore too old closed tasks.
        ofWhichClosed += 1;
      }
      formattedCount += 1; // This is the total count, excluding PQ. And excluding CLOSED unless flag is enabled.
      chosen.push(task);
    }
    return {
      list: chosen.length ? chosen : empty,
      formattedCount,
      ofWhichClosed,
      loading: false,
    };
  } else {
    return {
      list: empty,
      formattedCount: 0,
      ofWhichClosed: 0,
      loading: isLoading,
    };
  }
}
