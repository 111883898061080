import React, { useMemo } from "react";
import UserFiltersRow from "../../InputPopup/UserFiltersRow/UserFiltersRow";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/enums/profilePreferences";
import {
  areaIsWholeStore,
  defaultCustomPeriod,
  defaultFilters,
  FilterKeys,
  getAreaFilter,
  getPeriodFilter,
} from "../../InputPopup/UserFiltersRow/filterChoices";
import { Trans, useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTestbuys } from "../CommonComponents/Repositories/useTestbuyService";
import { useFixaUID, useUID, useMyStore } from "@coworker/app/src/core/auth/useLoggedInUser";
import { TestBuyList } from "./TestBuyList";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import { HfbRanks } from "./Analytics/Ranking/HfbRanks";
import EmptyPage from "../../EmptyStateScreen";
import { COMPLETED_STATUS } from "../constants";
import closeReasonTypeOptions from "@coworker/enums/closeReasonTypes";
import trackerHelper from "../../../helpers/tracker";
import Confirmation from "../../InputPopup/ConfirmationPrompt";
import { useToastNotification } from "@coworker/components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { WARNING_TOAST } from "@coworker/components/src/components/ToastNotification";
import { postToTestbuyService } from "../../../hooks/API/testbuy.service";
import { TestBuyId } from "@coworker/types";
import { ActionBar } from "@coworker/apprestructured/src/layout/components/ActionBar/ActionBar";
import "../CommonComponents/styles.css";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const useTestbuyFilterPreferences = (filters: FilterKeys) => {
  const areaFilter = getAreaFilter(filters);
  const department = !areaIsWholeStore(filters) ? areaFilter : undefined;
  const periodType = getPeriodFilter(filters);

  const [[customStart, customEnd]] = useUserPreference(
    profilePreferences.TESTBUY_PREFIX +
      profilePreferences.USER_FILTERS_CUSTOM_PERIOD,
    defaultCustomPeriod()
  );

  return { department, periodType, customStart, customEnd };
};

const setDayStartTime = (date: dayjs.Dayjs) => {
  return date
    .set("hours", 0)
    .set("minutes", 0)
    .set("seconds", 0)
    .set("millisecond", 0);
};

const setDayEndTime = (date: dayjs.Dayjs) => {
  return date
    .set("hours", 23)
    .set("minutes", 59)
    .set("seconds", 59)
    .set("millisecond", 999);
};

// Process dates in local browser time and in last step convert to store
// timezone, just before creating a iso string.
const useStartEndDates = (
  periodType: string | undefined,
  customStart: string | undefined,
  customEnd: string | undefined,
  timezone: string
) => {
  return useMemo(() => {
    const sevenDaysStart = setDayStartTime(dayjs().subtract(7, "days"))
      .tz(timezone, true)
      .toISOString();
    const endDate = setDayEndTime(dayjs()).tz(timezone, true).toISOString();

    switch (periodType) {
      case "period=4weeks":
        return {
          startDate: setDayStartTime(dayjs().subtract(28, "days"))
            .tz(timezone, true)
            .toISOString(),
          endDate,
        };

      case "period=custom":
        if (customStart && customEnd) {
          return {
            startDate: setDayStartTime(dayjs(customStart))
              .tz(timezone, true)
              .toISOString(),
            endDate: setDayEndTime(dayjs(customEnd))
              .tz(timezone, true)
              .toISOString(),
          };
        } else {
          return { startDate: sevenDaysStart, endDate };
        }

      case "period=7days":
      default:
        return { startDate: sevenDaysStart, endDate };
    }
  }, [customEnd, customStart, periodType, timezone]);
};

export const TestBuyOverview = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const uid = useUID();
  const fixaUid = useFixaUID();
  const { showToast } = useToastNotification();
  const store = useMyStore();
  const tz =
    store?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { push } = useWorkspacesAction();

  const [filters] = useUserPreference(
    profilePreferences.TESTBUY_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );

  const { department, periodType, customStart, customEnd } =
    useTestbuyFilterPreferences(filters);

  const { startDate, endDate } = useStartEndDates(
    periodType,
    customStart,
    customEnd,
    tz
  );

  const { loading, testbuys } = useTestbuys(department, startDate, endDate);
  const { testbuys: allTestbuys } = useTestbuys(undefined, startDate, endDate);

  const [isWholeStoreActive, setIsWholeStoreActive] =
    React.useState<boolean>(false);
  const [totalTestbuysDone, setTotalTestbuysDone] = React.useState<number>();
  const [popup, setPopup] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!department) {
      setIsWholeStoreActive(true);
    } else {
      setIsWholeStoreActive(false);
    }
  }, [department, isWholeStoreActive]);

  React.useEffect(() => {
    if (testbuys && filters) {
      const filterDateThreshold = 1661990400000; // 1st of September 2022
      const completedTestbuys = testbuys.filter(
        (tb) => (tb.finishedAtTs ?? 0) >= filterDateThreshold && tb.status === COMPLETED_STATUS
      );
      
      setTotalTestbuysDone(completedTestbuys.length);
    }
  }, [testbuys, filters]);

  const { t } = useTranslation();

  if (loading) return <PositionedLoaderIcon />;

  const showOnlyClosedTestBuys = !totalTestbuysDone && !!testbuys?.length;

  const testBuyInProgress = testbuys?.find(
    (tb) =>
      (tb.createdBy === uid || tb.createdBy === fixaUid) &&
      tb.status === "IN_PROGRESS"
  );

  const closeTestbuy = async () => {
    if (!testBuyInProgress || !testBuyInProgress.id) return;
    await postToTestbuyService(
      "testbuy/close_testbuy",
      {
        testbuyId: testBuyInProgress.id,
        reasonType: closeReasonTypeOptions.NEW_TESTBUY_CREATED,
      },
      (data: TestBuyId[]) => {
        if (data.length > 0) {
          trackerHelper.trackCreateAdditionalTestBuy(
            "CREATE_NEW",
            testBuyInProgress?.id
          );
          push("/testbuy/new");
        } else {
          showToast(
            <Trans>searchErrorString</Trans>,
            undefined,
            undefined,
            5,
            WARNING_TOAST
          );
        }
      }
    );
  };

  return (
    //This div exist due to render issues on RDT
    <div>
      <div>
        <Linkbar currentLabel={t("testBuysString")} currentAsH1 />
        <UserFiltersRow
          filters={filters.filter((filter) => filter !== "")}
          settingsPrefix={profilePreferences.TESTBUY_PREFIX}
        />
        {!!totalTestbuysDone && (
          <>
            <HfbRanks
              testbuys={testbuys ?? []}
              allTestbuys={allTestbuys ?? []}
              filters={filters}
              isWholeStoreActive={isWholeStoreActive}
              totalTestbuysDone={totalTestbuysDone}
            />
            <TestBuyList testbuys={testbuys ?? []} />
          </>
        )}
        {!totalTestbuysDone && (
          <EmptyPage
            titleKey="noTestbuysYetString"
            subtitleKey="tapOnTheButtonToStartTestbuyString"
          />
        )}
        {showOnlyClosedTestBuys && <TestBuyList testbuys={testbuys} />}
        <ActionBar hidden={false}>
          {
            <FixaButton
              data-testid="button-startNewTestBuy"
              fluid={true}
              type="primary"
              text={<Trans>createTestbuyString</Trans>}
              onClick={() => {
                if (testBuyInProgress) {
                  setPopup(!popup);
                } else {
                  push("/testbuy/new");
                }
              }}
              aria-label={`${(<Trans>createTestbuyString</Trans>)}`}
            />
          }
        </ActionBar>
      </div>
      {popup && (
        <Confirmation
          onSubmit={closeTestbuy}
          onCancel={() => {
            trackerHelper.trackCreateAdditionalTestBuy(
              "SEE_ONGOING",
              testBuyInProgress?.id
            );
            push(`/testbuy/${testBuyInProgress?.id}`);
          }}
          onClose={() => {
            setPopup(false);
            trackerHelper.trackCreateAdditionalTestBuy(
              "CLICKED_OUTSIDE",
              testBuyInProgress?.id
            );
          }}
          swapButtons={false}
          question={<Trans>ongoingTestBuyString</Trans>}
          description={<Trans>seeOngoingOrCloseTestBuyString</Trans>}
          positiveText={<Trans>createNewString</Trans>}
          negativeText={<Trans>seeOngoingString</Trans>}
        />
      )}
    </div>
  );
};
