import styles from "./task-card.module.css";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import CrossCircleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossCircleIconPath";
import { TaskType } from "../../enums/taskTypes";
import { Trans } from "@coworker/locales";
import { BannerInformation } from "../../helpers/getTaskInfo";
import ArrowRightIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ArrowRightIconPath";

interface InformationBannerProps {
  information: BannerInformation;
  isTaskClosed: boolean;
  type: string;
}

export const InformationBanner = ({
  information,
  isTaskClosed,
  type,
}: InformationBannerProps) => {
  if (!information) return null;

  let informationContent;

  if (isTaskClosed) {
    informationContent = (
      <div className={styles["banner"]}>
        <FixaSSRIcon
          className={styles["closed-icon"] as string}
          paths={CrossCircleIconPath}
        />
        <div className={styles["information-bold"]}>
          {information.closeType}
        </div>
      </div>
    );
  } else {
    switch (type) {
      case TaskType.MFAQ_FOLLOW_UP:
      case TaskType.ROOM_SETTINGS:
        informationContent = (
          <>
            {information.location && (
              <div className={styles["banner"]}>
                <div className={styles["information"]}>
                  <div>
                    <Trans>inString</Trans>
                  </div>
                  <div className={styles["information-location"]}>
                    {information.location}
                  </div>
                </div>
              </div>
            )}
          </>
        );
        break;

      case TaskType.TESTBUY_FOLLOW_UP:
        informationContent = (
          <div className={styles["banner"]}>
            <div className={styles["information"]}>
              <div>
                <Trans>atString</Trans>
              </div>
              <div className={styles["information-location"]}>
                {information.location}
              </div>
              <div>{information.failedCriteria}</div>
            </div>
          </div>
        );
        break;

      case TaskType.ADDON:
        informationContent = (
          <div className={styles["banner"]}>
            <div className={styles["information"]}>
              <div className={styles["information-bold"]}>
                {information.requestedQuantity}
              </div>
              {information.pickupLocation && (
                <div className={styles["information-location"]}>
                  {information.pickupLocation}
                </div>
              )}
              <FixaSSRIcon
                paths={ArrowRightIconPath}
                className={styles["arrow-right-icon"] as string}
              />
              <div className={styles["information-location"]}>
                {information.location}
              </div>
            </div>
          </div>
        );
        break;
    }
  }

  return <>{informationContent}</>;
};
