
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import styled from "styled-components";

type ListViewItemControl =
  | "default"
  | "checkbox"
  | "radiobutton"
  | "switch"
  | "navigational"
  | "link";
type ListViewItemControlIcon =
  | "chevronRight"
  | "chevronRightSmall"
  | "arrowRight"
  | "linkOut"
  | "copy"
  | null;

const TaskCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0px;
`;

const ListViewContainer = styled.div`
  flex-grow: 1;
`;
const RightIconContainer = styled.div`
  margin-left: auto;
  margin-right: 22px;
`;

interface Props {
  headLine: string | React.ReactElement;
  leftIcon?: React.ReactNode;
  subText: string | React.ReactElement;
  rightIcon?: ListViewItemControlIcon | React.ReactElement;
  control?: ListViewItemControl;
  showDivider?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
}

export function TaskCard({
  headLine,
  leftIcon,
  subText,
  rightIcon = null,
  control = "default",
  onClick = () => {},
  showDivider = true,
}: Props) {
  const controlType: ListViewItemControl = control;
  const controlIcon: ListViewItemControlIcon | null =
    typeof rightIcon === "string" ? rightIcon : null;

  return (
    <TaskCardWrapper>
      <ListViewContainer>
        <FixaListView id="task-card-list-view" showDivider={showDivider}>
          <FixaListViewItem
            onClick={onClick}
            control={controlType}
            leadingIcon={() =>
              leftIcon ? [leftIcon as React.SVGProps<SVGElement>] : []
            }
            title={headLine}
            description={subText}
            inset={true}
            emphasised={true}
            controlIcon={controlIcon}
          />
        </FixaListView>
      </ListViewContainer>
      {typeof rightIcon !== "string" && (
        <RightIconContainer><>{rightIcon}</></RightIconContainer>
      )}
    </TaskCardWrapper>
  );
}
