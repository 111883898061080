import { useState } from "react";
import {
  FilterValue,
  MultiSelectFilter,
  SelectOption,
  StringArrayFilterValue,
} from "../../types";
import { MultiSelectListView } from "../SelectListView";
import FixaChevronUpIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronUpIconPath";
import FixaChevronDownIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronDownIconPath";
import FixaPill from "../../../wrappers/FixaPill/FixaPill";
import { FixaListBox } from "../../../wrappers/FixaListBox/FixaListBox";
import PillSelectCss from "./PillSelect.module.css";

interface PillMultiSelectProps {
  filter: MultiSelectFilter;
  filterValue: FilterValue;
  selected: StringArrayFilterValue;
  onChange: (option: SelectOption, isSelected: boolean) => void;
}

export const PillMultiSelect = ({
  filter,
  filterValue,
  selected,
  onChange,
}: PillMultiSelectProps) => {
  const [listBoxIsOpen, setListBoxIsOpen] = useState(false);
  const { id, title } = filter;
  return (
    <div className={PillSelectCss["dropdown-pill"]}>
      <FixaPill
        id={`fixapill-${id}`}
        data-testid={`fixapill-${id}`}
        iconPosition="trailing"
        ssrIcon={
          listBoxIsOpen ? FixaChevronUpIconPath : FixaChevronDownIconPath
        }
        selected={filterValue !== filter.defaultValue}
        label={title}
        onClick={() => {
          setListBoxIsOpen(!listBoxIsOpen);
        }}
        size="small"
      />

      {listBoxIsOpen && (
        <div
          data-testid={`fixapill-backdrop-${id}`}
          className={PillSelectCss["dropdown-backdrop"]}
          onClick={() => {
            setListBoxIsOpen(!listBoxIsOpen);
          }}
        />
      )}

      <FixaListBox
        className={PillSelectCss["dropdown-list"] ?? ""}
        multiple
        open={listBoxIsOpen}
        position="left"
      >
        <MultiSelectListView
          prefix="pill"
          filter={filter}
          selected={selected}
          onChange={(option, isSelected) => {
            onChange(option, isSelected);
            //setListBoxIsOpen(!listBoxIsOpen);
          }}
        />
      </FixaListBox>
    </div>
  );
};
