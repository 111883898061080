import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createMedia } from "../services/medias.service";
import { QueryKeys } from "./queryKeys";

export const useCreateMedia = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createMedia,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: [QueryKeys.Medias] });
    },
  });
};
