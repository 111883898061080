import { ArticleListItemMobile, ListWrapper } from "../styles/styles";
import { Groups } from "../types/groups";
import { Media } from "../types/media";
import { AggregatedArticleListItem } from "../Molecules/AggregatedArticleListItem";
import {
  hasOngoingTask,
  isAlternativeForProduct,
  isMainProduct,
  selectArticleIdNbrArticles,
} from "../Molecules/MediaArticles.helper";
import { AggregatedArticle } from "../hooks/useAggregatedArticles";
import { TaskLinks } from "../types/taskLink";
import { ArticleIdNbrArticles, EdsData, SalesStopData } from "../types/article";
import { FixaListView } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListView";
import { MediasViewSearchListItemSkeleton } from "../Molecules/MediasViewSearchListItemSkeleton";
import { getMainAndAlternativeProducts } from "../tools";
interface AggregatedArticleListProps {
  articles: AggregatedArticle[] | undefined;
  media: Media;
  groups?: Groups | undefined;
  isSelectArticlesMode: boolean;
  taskLinks: TaskLinks;
  edsData: EdsData[];
  salesStopData: SalesStopData[];
  selectedArticleIdsNbrArticles: ArticleIdNbrArticles[];
  setSelectedArticleIdsNbrArticles: React.Dispatch<
    React.SetStateAction<ArticleIdNbrArticles[]>
  >;
}
export const AggregatedArticleList = ({
  articles,
  media,
  groups,
  isSelectArticlesMode,
  taskLinks,
  edsData,
  salesStopData,
  selectedArticleIdsNbrArticles,
  setSelectedArticleIdsNbrArticles,
}: AggregatedArticleListProps) => {
  const mainAndAlternativeProduct = getMainAndAlternativeProducts(media);

  if (!articles) {
    return (
      <FixaListView id="articles-list">
        <MediasViewSearchListItemSkeleton key="medias-view-article-0" />
        <MediasViewSearchListItemSkeleton key="medias-view-article-1" />
        <MediasViewSearchListItemSkeleton key="medias-view-article-2" />
        <MediasViewSearchListItemSkeleton key="medias-view-article-3" />
      </FixaListView>
    );
  } else {
    return (
      <ListWrapper>
        {articles.map((uniqueArticle) => (
          <ArticleListItemMobile key={uniqueArticle.id}>
            <AggregatedArticleListItem
              article={uniqueArticle}
              isMainProduct={() =>
                isMainProduct(
                  uniqueArticle.id,
                  mainAndAlternativeProduct.mainProductId1,
                  mainAndAlternativeProduct.mainProductId2
                )
              }
              numberMain={uniqueArticle.id === media.mainProductId1 ? 1 : 2}
              isAlternativeForProduct={() =>
                isAlternativeForProduct(
                  uniqueArticle.id,
                  mainAndAlternativeProduct.alternativeProductId1 ?? "",
                  mainAndAlternativeProduct.alternativeProductId2 ?? ""
                )
              }
              hasOngoingTask={() => hasOngoingTask(uniqueArticle.id, taskLinks)}
              edsData={
                edsData?.find(
                  (eds: { productArticleId: string }) =>
                    eds.productArticleId === uniqueArticle.productArticleId
                ) ?? null
              }
              salesStopData={
                salesStopData?.find(
                  (salesStop) =>
                    salesStop.productArticleId ===
                    uniqueArticle.productArticleId
                ) ?? null
              }
              selectArticleIdsMode={isSelectArticlesMode}
              onSelectArticleIdNbrArticles={(articleIdNbrArticles) =>
                selectArticleIdNbrArticles(
                  articleIdNbrArticles,
                  selectedArticleIdsNbrArticles,
                  setSelectedArticleIdsNbrArticles
                )
              }
              isSelected={
                !!selectedArticleIdsNbrArticles.find(
                  (item) => item.articleId === uniqueArticle.id
                )
              }
              showActionRow={true}
              groups={groups}
            />
          </ArticleListItemMobile>
        ))}
      </ListWrapper>
    );
  }
};
