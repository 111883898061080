import styled from "styled-components";

const StyledArea = styled('textarea')`
  resize: none;
  outline: none;
  height: auto;
  box-sizing: border-box;
  &:focus {
    color: var(--grey900);
  }
  width: 100%;
  overflow: hidden;
  ${({ noPadding }) => !noPadding && `padding: 30px 29px;`}
  font-size: 14px;
  color: var(--grey700);
  ${({ noBorder }) => !noBorder && `border: 1px solid var(--grey150);`}
  display: block;
  &::-webkit-input-placeholder {
    opacity: 1;
    color: var(--grey500);
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    opacity: 1;
    color: var(--grey500);
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 1;
    color: var(--grey500);
  }
  &:-ms-input-placeholder {
    opacity: 1;
    color: var(--grey500);
  }
  &::placeholder {
    opacity: 1;
    color: var(--grey500);
  }
  &:focus::-webkit-input-placeholder {
    opacity: 1;
    color: var(--grey200);
  }
  &:focus:-moz-placeholder {
    /* Firefox 18- */
    opacity: 1;
    color: var(--grey200);
  }
  &:focus::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 1;
    color: var(--grey200);
  }
  &:focus:-ms-input-placeholder {
    opacity: 1;
    color: var(--grey200);
  }
  &:focus::placeholder {
    opacity: 1;
    color: var(--grey200);
  }
`;

export default StyledArea;
