import { ChecklistItem } from "@coworker/types/lib/tasks/testbuy_followup";
import { sortingIndexValue } from "../../TestBuy/ArticleDetailPage/CriterionComponent/CriteriaHelper";

export function sortChecklistCriteriaDisplayOrder(
  checklist: ChecklistItem[]
): ChecklistItem[] {
  return [...checklist].sort(
    (first, second) =>
      sortingIndexValue(first.key) - sortingIndexValue(second.key)
  );
}

export const toggleChecklistItem = (
  taskKey: string,
  checklistKey: string,
  isDone: boolean,
  updateTask: { mutateAsync: (params: { data: any; taskId: string }) => void }
) => {
  const body = {
    markDoneChecklistKey: "",
    markNotDoneChecklistKey: "",
  };
  if (isDone) body.markDoneChecklistKey = checklistKey;
  if (!isDone) body.markNotDoneChecklistKey = checklistKey;
  updateTask.mutateAsync({ data: body, taskId: taskKey });
};
