import {
  SelectFilter,
  FilterValue,
  StringFilterValue,
  SelectOption,
} from "../../types";
import {
  createFilterValueFromSelectOption,
  getSelectedFilterValue,
  updatedFilterValueFromValues,
} from "../../utils";
import { PillSelect } from "../PillSelect";

interface InputSelectPillDesktopProps {
  filter: SelectFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const InputSelectPillDesktop = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
}: InputSelectPillDesktopProps) => {
  const { id } = filter;
  const filterValue = getSelectedFilterValue(
    id,
    filterValues,
    defaultValues
  ) as StringFilterValue;
  const handleOnChange = (option: SelectOption) => {
    const value = createFilterValueFromSelectOption(filter.id, option);
    setFilterValues(updatedFilterValueFromValues(filterValues, filter, value));
  };
  return (
    <PillSelect
      filter={filter}
      filterValue={filterValue}
      selected={filterValue}
      onChange={handleOnChange}
    />
  );
};
