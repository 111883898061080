import { FixaListView } from "../../../wrappers/FixaListView/FixaListView";
import styles from "./team-suggestions.module.css";
import { FixaListViewItem } from "../../../wrappers/FixaListView/FixaListViewItem";
import { logEvent } from "@coworker/app/src/helpers/tracker";
import { useTranslation } from "@coworker/locales";
import { useParsedSuggestions } from "../../../../tasks/hooks/useRecentAssignmentSuggestions";

interface TeamSuggestionProps {
  itemNo: string;
  assignedTeamId: string;
  doSubmit: (args: { name: string; gid: string; uid: string }) => void;
}

export const TeamSuggestions = ({
  itemNo,
  doSubmit,
  assignedTeamId,
}: TeamSuggestionProps) => {
  const suggestions = useParsedSuggestions(itemNo);
  const { t } = useTranslation();
  return (
    <FixaListView
      id={"suggestions"}
      className={styles["suggestions"] || ""}
      size="small"
    >
      <FixaListViewItem title={t("assignedRecentlyString")} emphasised inset />
      {suggestions.map(([teamId, [teamName, shortDate]], i) => (
        <FixaListViewItem
          className={
            (i === suggestions.length - 1 && styles["last-suggestion"]) || ""
          }
          key={teamId}
          id={teamId}
          title={
            <>
              <div>{teamName}</div>
              {t("mostRecentlyString")} {shortDate}
            </>
          }
          inset
          control="radiobutton"
          onChange={() => {
            doSubmit({ gid: teamId, name: teamName, uid: "" });
            logEvent("ce:assign_to", { type: "suggested", team: teamId });
          }}
          controlProps={{
            checked: assignedTeamId === teamId,
            value: teamId,
          }}
        />
      ))}
    </FixaListView>
  );
};
