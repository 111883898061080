import { TestBuy } from "@coworker/types";
import { TaskState } from "@coworker/types/lib/tasks/constants";
import { useStoreId, useUserId } from "../../../../core/auth/useLoggedInUser";
import { useQuery } from "@tanstack/react-query";
import {
  fetchTestbuy,
  fetchTestbuys,
  fetchTestbuyTasks,
  fetchCountriesWith4A1KData,
  ArticleTaskPairs,
} from "./testbuy.api";
interface UseTesbuys {
  loading: boolean;
  testbuys: TestBuy[] | undefined;
}

interface TaskData {
  articleId: string;
  taskId: string;
  state: TaskState;
  finishedAt?: number | undefined;
}

export const useTestbuy = (id: string | undefined) => {
  const {
    data: testbuy,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ["get-testbuy-by-id", id],
    queryFn: async () => {
      return await fetchTestbuy(id);
    },
    staleTime: 0,
  });

  return { testbuy, loading, refetch };
};

export const useTestbuys = (
  area: string | undefined,
  sinceIsoDateString: string,
  untilIsoDateString: string
) => {
  const storeId = useStoreId();
  const uid = useUserId();
    
  const { data: testbuy, isLoading: loading } = useQuery({
    queryKey: ["get-testbuys", uid, area, storeId, sinceIsoDateString, untilIsoDateString],
    queryFn: async () => {
      if (!sinceIsoDateString || !untilIsoDateString) return [];
      
      const response = await fetchTestbuys(uid, area, storeId, sinceIsoDateString, untilIsoDateString, "");
      
      return response;
    },
    enabled: !!sinceIsoDateString && !!untilIsoDateString && !!uid && !!storeId,
    staleTime: 0,
  });
  

  return {
    loading,
    testbuys: testbuy,
  } as UseTesbuys;
};

export const useTestbuyTasks = (testbuyTasks: ArticleTaskPairs[]) => {
  const { data: tasks } = useQuery({
    queryKey: ["get-testbuy-tasks", testbuyTasks],
    queryFn: async () => {
      return await fetchTestbuyTasks(testbuyTasks);
    },
  });
  return tasks as TaskData[];
};

export const use4A1KData = () => {
  const { data: countries } = useQuery({
    queryKey: ["get-countries-with-4A1K-data"],
    queryFn: async () => {
      return await fetchCountriesWith4A1KData();
    },
    staleTime: 0,
  });
  return countries as string[];
};
