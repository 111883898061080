import React ,{ReactNode,MouseEventHandler,Fragment} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "@coworker/reusable/svg/icon-arrow-small-forward-cropped.svg";
import {
  FlexRow,
  WidgetAreaClickable,
  WidgetAreaStatic,
} from "@coworker/reusable/components/Widgets";
import { SmartImage } from "./SmartImage";

interface WidgetRowProps {
  image?: string | ReactNode;
  middle: ReactNode;
  right: ReactNode;
  children?: ReactNode;
  // This component expects either onClick or to
  onClick?: MouseEventHandler<HTMLDivElement>;
  to?: string;
  testId?: string;
  className?: string;
  disableClicks?: boolean;
  disableRightArrow?: boolean;
  tabIndex?: number;
}

interface WidgetWrapperProps extends WidgetRowProps {
  isWidgetRow?: boolean;
}

interface ImageWrapperProps {
  image?: string | ReactNode;
  hideSkeleton?: boolean;
}

const PaddedRowRoot = styled(WidgetAreaClickable)`
  display: flex;
  min-height: 147px;
  width: 100%;
  padding: 19px 24px;
  align-items: flex-start;
  box-sizing: border-box;

  &.WidgetView {
    min-height: 90px;
    align-items: center;
  }
  &.PIPView {
    min-height: 110px;
    align-items: center;
  }
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding-right: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export function IconWrapper({ image, hideSkeleton }: ImageWrapperProps) {
  return (
    <Fragment>
      {!!image && typeof image === "string" && (
        <SmartImage padRight={12} image={image} hideSkeleton={hideSkeleton} />
      )}
      {!!image && React.isValidElement(image) && (
        <IconContainer><>{image}</></IconContainer>
      )}
    </Fragment>
  );
}

const ItemContainer = styled('div')<{ isWidgetRow: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MiddleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: 4px;
  margin-left: 15px;
  justify-content: flex-end;

  & > span {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--black);
  }
`;

const NextIcon = styled(ArrowRight)`
  margin-left: 12px;
`;

export function PaddedRow({
  to,
  image,
  middle,
  right,
  onClick,
  children,
  isWidgetRow = false,
  className,
  testId,
  disableRightArrow = false,
  tabIndex = -1,
}: WidgetWrapperProps) {
  // Display right arrow when either to or onClick is available
  const displayRightArrow = !disableRightArrow && !!(to || onClick);

  return (
    <PaddedRowRoot
      data-testid={testId}
      onClick={onClick}
      className={className}
      tabIndex={tabIndex}
    >
      <IconWrapper image={image} hideSkeleton={isWidgetRow} />
      <ItemContainer isWidgetRow={isWidgetRow}>
        <FlexRow>
          <MiddleWrapper className="middle"><>{middle}</></MiddleWrapper>
          <RightWrapper className="right">
            <>{right}</>
            {displayRightArrow && <NextIcon />}
          </RightWrapper>
        </FlexRow>
        <>{children}</>
      </ItemContainer>
    </PaddedRowRoot>
  );
}

export const WidgetTop = styled(PaddedRow)<WidgetWrapperProps>`
  .right {
    align-items: flex-start;
    svg {
      margin-top: 4px;
    }
  }
`;

export const WidgetCenter = styled(PaddedRow)<WidgetWrapperProps>`
  .middle,
  .right {
    align-items: center;
  }
`;

export function WidgetRow({
  to,
  onClick,
  disableClicks,
  tabIndex = -1,
  ...props
}: WidgetRowProps) {
  const getWidgetRow = (rowProps: WidgetWrapperProps) => (
    <WidgetCenter {...rowProps} isWidgetRow={true} tabIndex={tabIndex} />
  );

  if (to) return <Link to={to}>{getWidgetRow({ ...props, to })}</Link>;
  if (onClick) return getWidgetRow({ ...props, onClick });
  if (disableClicks)
    return <WidgetAreaStatic>{getWidgetRow(props)}</WidgetAreaStatic>;

  return getWidgetRow(props);
}
