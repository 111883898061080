import { useTranslation } from "@coworker/locales";
import { FilterType, InputType } from "../types";
import { useCustomAllSelectOption } from "../utils";

const GROUPED_ID = "grouped";

export function useGroupedArticlesSelectFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: GROUPED_ID, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: GROUPED_ID,
      title: t("groupedArticlesString"),
      options: [
        { value: "onlyInGroups", title: t("articlesOnlyInGroupsString") },
        {
          value: "notInGroups",
          title: t("articlesNotInGroupsString"),
        },
      ],
      enabled: true,
      defaultValue,
    },
    t("allArticlesString")
  );
}
