import {useMemo} from "react";
import { useStoreTeams } from "./useStoreTeams";

export function useAssignableTeams(includeAll = false) {
  const teams = useStoreTeams();
  return useMemo(() => {
    if (!teams?.filter) return [];
    return teams.filter(
      (team) =>
        team.id &&
        team.name &&
        team.status === "ACTIVE" &&
        (!team.is_product_quality || includeAll)
    );
  }, [includeAll, teams]);
}
