import { useMemo } from "react";
import styled from "styled-components";
import { CreateTaskButton } from "./CreateTaskButton";
import ChecklistWidget from "../ArticleDetailPage/CriterionComponent/ChecklistWidget";
import { ProductAndStockPillsWidget } from "../../TaskForm/widgets/ProductAndStockPillsWidget";
import { LocationWidget } from "../../TaskForm/widgets/LocationWidget";
import { useGetArticleById } from "../CommonComponents/Repositories/useArticleService";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import { useParams } from "react-router";
import { Trans, useTranslation } from "@coworker/locales";
import { useStoreId, useUserId } from "../../../core/auth/useLoggedInUser";
import { TestBuyProductWithCriteria } from "@coworker/types";
import TaskUploadPhotosWidget from "../../TaskForm/widgets/UploadPhotosWidget";
import { useTaskStateUpdates } from "./useTaskStateUpdates";
import { testbuyCreateTaskFactory } from "../CommonComponents/ObjectFactories/TaskFactory";
import { Widget } from "@coworker/components/src/components/Widget";
import { WidgetDivider } from "@coworker/reusable/components/Widgets";
import { TextField } from "../../../core/components/widgets/TextField";
import { ToggleField } from "../../../core/components/widgets/ToggleField";
import { ReactComponent as PriorityIcon } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { ReactComponent as PersonIcon } from "../../../assets/svg/Person.svg";
import { AssigneeSelection } from "../../../core/components/widgets/AssigneeSelection";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useTestbuy } from "../CommonComponents/Repositories/useTestbuyService";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { formatProductIdWithDots } from "@coworker/reusable";

const ErrorWidget = styled(Widget)`
  margin: auto;
  color: var(--error);
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  text-align: center;
`;

const ButtonWrapper = styled('div')`
  & > * {
    margin: 0;
  }
`;

interface DisplayTestbuyPageProps {
  article: TestBuyProductWithCriteria;
  testbuyId: string;
  testbuyName: string;
}

function DisplayTestbuyTaskCreationPage({
  testbuyId,
  article,
  testbuyName,
}: DisplayTestbuyPageProps): JSX.Element {
  const store_id = useStoreId();
  const uid = useUserId();
  const { t } = useTranslation();

  const failedCriteria = (article?.criteria || [])
    .filter((criterion) => criterion.state === "NOT_OK")
    .map(({ key }) => key);

  const defaultTask = testbuyCreateTaskFactory(
    uid,
    store_id,
    article.shortId,
    article.name,
    article.location,
    article.smallImage,
    testbuyId,
    failedCriteria
  );

  const {
    task,
    togglePriority,
    updateDescription,
    updateAssignedTo,
    updateImages,
    isTaskValid,
  } = useTaskStateUpdates(defaultTask);

  const redflagged = !task.assigned_team_id;

  const linkbarItems = useMemo(() => {
    return [
      {
        label: t("testBuysString"),
        to: "/testbuy",
      },
      {
        label: testbuyName,
        to: `/testbuy/${testbuyId}`,
      },
      {
        label: `${article.name} ${formatProductIdWithDots(article.shortId)}`,
        to: `/testbuy/${testbuyId}/article/${article.id}`,
      },
    ];
  }, [article, t, testbuyId, testbuyName]);

  return (
    <FullScreenPopup
      noPadding
      actionBarContent={
        <ButtonWrapper>
          <CreateTaskButton
            task={task}
            articleKey={article.id || ""}
            testbuyId={testbuyId}
            isTaskValid={isTaskValid}
          />
        </ButtonWrapper>
      }
      linkbar={
        <Linkbar
          items={linkbarItems}
          currentLabel={t("testBuyFollowUpString")}
          currentAsH1
        />
      }
    >
      <ProductAndStockPillsWidget
        value={{
          productId: task.product_article_id,
          productType: "ART", // Test buy only support ART for now.
        }}
      />
      <LocationWidget location={article.location} />
      <WidgetDivider />
      <AssigneeSelection
        selectedTeamId={task.assigned_team_id}
        selectedUserId={task.assigned_user_id}
        onConfirm={updateAssignedTo}
        image={<PersonIcon />}
      />
      <ToggleField
        title={
          <b>
            <Trans>priorityString</Trans>
          </b>
        }
        image={<PriorityIcon />}
        onChange={togglePriority}
        value={task.priority_flag}
      />
      <TextField
        placeholder={
          <b>
            <Trans>addNotesString</Trans>
          </b>
        }
        value={task.description}
        onConfirm={updateDescription}
        optional={true}
        testId={"testbuy-task-value"}
      />
      <TaskUploadPhotosWidget
        images={task.images}
        updateImages={updateImages}
        taskId={""}
      />

      <ChecklistWidget criterionKeys={task.failedCriteria} />

      {redflagged && (
        <ErrorWidget>
          <Trans>needToAssignToCreateString</Trans>
        </ErrorWidget>
      )}
    </FullScreenPopup>
  );
}

export function TestbuyTaskCreationPage(): JSX.Element {
  const { id: testbuyId = "", articleId = "" } = useParams<{
    id: string;
    articleId: string;
  }>();

  const { data: article, loading: loadingArticle } = useGetArticleById(
    testbuyId,
    articleId
  );
  const { testbuy, loading: loadingTestbuy } = useTestbuy(testbuyId);
  const loading = loadingArticle || loadingTestbuy || !testbuy;

  if (loading) return <PositionedLoaderIcon />;

  const props: DisplayTestbuyPageProps = {
    article: article,
    testbuyId: testbuyId,
    testbuyName: testbuy.createdAt,
  };
  return <DisplayTestbuyTaskCreationPage {...props} />;
}
