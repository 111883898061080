import React from "react";
import { debounce } from "throttle-debounce";

/**
 * Debounces execution of a function and returns a react callback to that function.
 * @param {(...args) => any} func function to debounce
 * @param {Number} debounceDelay delay in ms
 */
export function useDebouncedFunc(func, debounceDelay) {
  const debouncedFunc = debounce(debounceDelay, (...args) => {
    return func(...args);
  });
  // We want this function to never change (break the debounce loop),
  // so we wrap it in a useCallback that has no dependencies, so its only executed once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(debouncedFunc, []);
}

/**
 * A custom hook to debounce values.
 * @param {*} value
 * @param {number} delay Milliseconds to delay before allowing an updated `value` to be passed through.
 * @returns {*}
 */
export function useDebouncedValue(value, delay) {
  // Initialize state with the current value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    // Set up the timeout to update the debounced value
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Clear timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * Initially returns undefined.
 * If `input` changes, timer is restarted.
 * If `input` is stable, returns `input` after `milliseconds`.
 * @param {T} input Any value.
 * @param {number} milliseconds Milliseconds to return undefined whenever a change in `input` is detected.
 * @returns {T?}
 */
export function useDelayedValue(input, milliseconds) {
  const [value, setValue] = React.useState();
  const nextValueRef = React.useRef(input);
  const timeoutRef = React.useRef(null);
  React.useEffect(() => {
    nextValueRef.current = input;

    // Return undefined initially and after any change
    setValue(undefined);

    // remove the timeout if value or delay changes
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setValue(nextValueRef.current);
      timeoutRef.current = null;
    }, milliseconds);

    return () => {
      // cleanup on unmount
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [input, milliseconds]);

  return value;
}
