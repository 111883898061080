import { Trans } from "@coworker/locales";
import { AddLocation } from "@coworker/components";
import { Widget } from "@coworker/components/src/components/Widget";
import { useInputPopup } from "../../InputPopup";

export default function TaskFormOptionalLocation({ value, onChange }) {
  const { getInput } = useInputPopup();

  const getLocation = async () => {
    const data = await getInput("optionalLocation", {
      locationCustom: value,
    });
    if (data) {
      const { locationCustom } = data;
      onChange(locationCustom);
    }
  };

  return (
    <Widget onClick={getLocation} data-testid="optionalLocationWidget">
      <AddLocation
        code={value}
        deactivated={!value}
        locationString={<Trans>locationString</Trans>}
        optional={<Trans>optionalString</Trans>}
      />
    </Widget>
  );
}
