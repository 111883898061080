import { useCallback, useState } from "react";
import { RadioButton } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import styled from "styled-components";
import { departments } from "../UserFiltersRow/FilterChoiceData";
import { defaultFilters, getAreaFilter } from "../UserFiltersRow/filterChoices";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/enums/profilePreferences";

const Wrapper = styled('div')`
  overflow-y: scroll;
  max-height: 392px;
  min-height: 112px;
  padding: 0 48px 0 24px;
`;

interface ListProps {
  filter: string | undefined;
  onSelect: (value: string) => void;
}

export function HFBList({ filter, onSelect }: ListProps) {
  const { t } = useTranslation();
  const [filters] = useUserPreference(
    profilePreferences.TESTBUY_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );
  const selectedArea = getAreaFilter(filters);

  const displayTextIncludesFilter = useCallback(
    ({ hfbNumber }: { hfbNumber: string }) => {
      if (!filter) return true;
      const displayText = `HFB${hfbNumber} - ${t(`hfb${hfbNumber}String`)}`;
      return displayText.toLowerCase().includes(filter.toLowerCase());
    },
    [filter, t]
  );

  const [selectedElement, setSelectedElement] = useState(
    departments.find(({ type }) => type === selectedArea)?.type
  );
  return (
    <Wrapper>
      {departments.filter(displayTextIncludesFilter).map(({ type, name }) => (
        <HFBListElement
          testId={`hfb-list-element-${type}`}
          key={type}
          text={name}
          isSelected={type === selectedElement}
          onClick={() => {
            setSelectedElement(type);
            onSelect(type);
          }}
        />
      ))}
    </Wrapper>
  );
}

const Element = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
`;

interface ElementProps {
  text: JSX.Element;
  isSelected: boolean;
  onClick: () => void;
  testId: string;
}

function HFBListElement({ text, isSelected, onClick, testId }: ElementProps) {
  return (
    <Element data-testid={testId} onClick={onClick}>
      <span>{text}</span>
      <RadioButton readOnly checked={isSelected} />
    </Element>
  );
}
