import styles from "./switcher-sheet.module.css";
import { FixaFormField } from "../../../../shared/wrappers/FixaFormField/FixaFormField";
import { FixaInputField } from "../../../../shared/wrappers/FixaInputField/FixaInputField";
import { useTranslation } from "@coworker/locales";
import { FixaSkeleton } from "../../../../shared/wrappers/FixaSkeleton/FixaSkeleton";
import ChevronDownIconPath from "../../../../shared/wrappers/FixaSSRIcon/paths/ChevronDownIconPath";
import { Country } from "@coworker/app/src/core/hooks/useCountries";
import { Store } from "../../../../shared/types/store";
import { StoreTeam } from "@coworker/types/lib/storeteam";

interface StoreSwitcherSelectorProps {
  id: string;
  value: string;
  disabled?: boolean;
  label: string;
  shouldValidate?: boolean;
  validationMsg?: string;
  selectedItem: Country | Store | StoreTeam | undefined;
  onClick: () => void;
  loading: boolean;
}

export const StoreEnvironmentSelector = ({
  id,
  value,
  disabled = false,
  label,
  shouldValidate = true,
  validationMsg,
  selectedItem,
  onClick,
  loading,
}: StoreSwitcherSelectorProps) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <>
        <div className={styles["select-label"]}>{t(label)}</div>
        <FixaSkeleton width="100%" height="48px" />
      </>
    );
  }

  return (
    <FixaFormField
      className={styles["form-field"] as string}
      id={id}
      valid={shouldValidate && !disabled && !!selectedItem?.name}
      validation={{
        id: "error-msg-id",
        msg: t(validationMsg!!),
        type: "error",
      }}
      shouldValidate={!disabled}
    >
      <FixaInputField
        id={id}
        type="text"
        label={t(label)}
        readOnly
        value={value}
        disabled={disabled}
        onClick={() => !disabled && onClick()}
        iconOnClick={() => !disabled && onClick()}
        ssrIcon={ChevronDownIconPath}
        iconPosition="trailing"
      />
    </FixaFormField>
  );
};
