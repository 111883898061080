import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PartialArticle } from "../types/article";
import { updateArticle } from "../services/articles.service";
import { QueryKeys } from "./queryKeys";

export const useUpdateArticle = (
  articleId: string,
  doInvalidateQueries: boolean = true
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartialArticle) => updateArticle(articleId, data),
    onSuccess: () => {
      if (!doInvalidateQueries) return;
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: [QueryKeys.Article] }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ArticlesByGroup],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ArticlesByMedia],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ArticlesByMediaAndProductArticleId],
          refetchType: "all",
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.MediasAndArticleCount],
        }),
      ]);
    },
  });
};
