import React from "react";
import { useInputPopup } from "../InputPopup";
import { Trans } from "@coworker/locales";
import Toast from "./Toast";
import { RECONNECT_TIMEOUT } from "../../core/hooks/useOnline";

export const OfflineBar = ({ isReconnecting }) => {
  const [reconnectSeconds, setReconnectSeconds] = React.useState(null);
  const timer = React.useRef(null);
  const { getInput } = useInputPopup();
  const getOffline = async () => {
    await getInput("confirmation", {
      title: "",
      question: <Trans>checkString</Trans>,
      description: <Trans>navDescriptionString</Trans>,
      positiveText: <Trans>refreshString</Trans>,
      onConfirm: () => window.location.reload(true),
    });
  };

  React.useEffect(() => {
    if (isReconnecting) {
      setReconnectSeconds(RECONNECT_TIMEOUT / 1000);
  
      timer.current = setInterval(() => {
        setReconnectSeconds((prevSeconds) => Math.max(prevSeconds - 1, 0));
      }, 1000);
    }
  
    return () => {
      clearInterval(timer.current);
    };
  }, [isReconnecting]);
  

  return (
    <Toast onClick={getOffline}>
      <Trans values={{ seconds: reconnectSeconds }}>
        {isReconnecting ? "attemptingToReconnectString" : "offlineModeString"}
      </Trans>
    </Toast>
  );
};

export default OfflineBar;
