import { useState } from "react";
import FixaTabs, {
  FixaTab,
  FixaTabPanel,
} from "../../../wrappers/FixaTabs/FixaTabs";
import { GroupFilter, FilterValue, InputType } from "../../types";
import { FilterTabSelect } from "./FilterTabSelect";
import { FilterTabMultiSelect } from "./FilterTabMultiSelect";
import FilterTabsCss from "./FilterTabs.module.css";

interface FilterTabsProps {
  filter: GroupFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const FilterTabs = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
}: FilterTabsProps) => {
  const [activeTab, setActiveTab] = useState(
    filter.filters.filter(({ enabled }) => enabled)[0]!.id
  );
  return (
    <FixaTabs
      className={FilterTabsCss["filter-tabs"] ?? ""}
      onTabChanged={(tab) => {
        setActiveTab(tab);
      }}
      tabs={filter.filters
        .filter(({ enabled }) => enabled)
        .map((filter) => (
          <FixaTab
            key={filter.id}
            text={filter.title}
            tabPanelId={filter.id}
            data-testid={`pill-group-tab-${filter.id}`}
          />
        ))}
      tabPanels={filter.filters
        .filter(({ enabled }) => enabled)
        .map((filter) => {
          switch (filter.inputType) {
            case InputType.Select: {
              return (
                <FixaTabPanel key={filter.id} tabPanelId={filter.id}>
                  <FilterTabSelect
                    filter={filter}
                    filterValues={filterValues}
                    defaultValues={defaultValues}
                    setFilterValues={setFilterValues}
                  />
                </FixaTabPanel>
              );
            }
            case InputType.MultiSelect: {
              return (
                <FixaTabPanel key={filter.id} tabPanelId={filter.id}>
                  <FilterTabMultiSelect
                    filter={filter}
                    filterValues={filterValues}
                    defaultValues={defaultValues}
                    setFilterValues={setFilterValues}
                  />
                </FixaTabPanel>
              );
            }
            default:
              return <FixaTabPanel key={filter.id} tabPanelId={filter.id} />;
          }
        })}
      activeTab={activeTab}
    />
  );
};
