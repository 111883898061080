import React from "react";
import styled from "styled-components";
import { HeaderSearchButton } from "../features/searching/HeaderSearchButton";
import Scanner from "./Scanner";
import { OfflineScreen } from "@coworker/components";
import { useInputPopup } from "./InputPopup";
import { useScanPopover } from "./ScanResult";
import { Trans } from "@coworker/locales";
import { navHeight } from "./Layout/styles";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../hooks/useWorkspaces";
import useMounted from "../hooks/useMounted";
import profilePreferences from "@coworker/enums/profilePreferences";
import RDTScanner from "./InputPopup/RDTScanner";
import { useUserPreference } from "../hooks/useProfilePreferencesQuery";
import { isAnyRDT } from "@coworker/reusable";
import useBrowserScanner from "./Scanning/useBrowserScanner";
import { BrowserScanner } from "./Scanning/BrowserScanner";
import { Tip } from "./Scanning/ScanTip";
import { parseProduct } from "../services/products.service";

const SearchContainer = styled('div')`
  background: var(--white);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 103;
`;

const HeaderContainer = styled('div')`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const Spacer = styled('div')`
  height: ${navHeight}px;
`;

const NoCamera = styled('div')`
  background: black;
  color: white;
  position: relative;
  height: 100%;
  width: 100%;
  & span {
    position: absolute;
    max-width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }
`;

const Search = ({ isOnline, store_id }) => {
  const { push, setNavigationState, pop } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const { setScannedItem, scanPopoverActive } = useScanPopover();
  const { getInput, popupOpen } = useInputPopup();
  const [popoverActive, setPopoverActive] = React.useState(false);
  const [cameraAvailable, setCameraAvailable] = React.useState(true);
  const mounted = useMounted();
  const videoRef = React.useRef(null);
  const [profileSetToRDTScannerMode, setInRDTScannerMode] = useUserPreference(
    profilePreferences.USE_RDT_SCANNER,
    isAnyRDT()
  );
  const inRDTScannerMode = isAnyRDT() && profileSetToRDTScannerMode;

  const startRef = React.useRef(new Date());
  const canUseBrowserScanner = useBrowserScanner();

  const searchPopup = React.useCallback(
    (withLocationState) => {
      setPopoverActive(true);
      if (withLocationState) {
        setNavigationState({ search: true });
      }
      getInput("search", {
        searchPopup: true,
        displayRelatedTasks: true,
        withHistory: true,
        start: startRef.current,
      }).then(() => {
        if (mounted.current) {
          // Restart video after it was paused when entering search
          videoRef.current && videoRef.current.play();
          setPopoverActive(false);
        }
      });
    },
    [getInput, mounted, setNavigationState]
  );

  const onBarcode = (code) => {
    setNavigationState({ code });
    setScannedItem({
      code,
      store_id,
      withContinue: false,
      withActions: true,
      displayRelatedTasks: true,
      start: startRef.current,
      onAction: (url, product) => {
        setScannedItem(null);
        push(url, {
          product,
        });
      },
      onRelated: () => {
        setScannedItem(null);
        push(`/tasks/related/${code}`);
      },
      close: () => {
        // This is here to force the video stream to start playing again if the user closes the popup when an article is found.
        // The setTimeout(..., 0) is needed so that it reorders execution, and starts playing again after react has rerendered the scanner component.
        setTimeout(() => videoRef.current?.play(), 0);

        setNavigationState({ code: null });
      },
      onItemClick: (full_id) => {
        setScannedItem(null);
        const [, type] = parseProduct(full_id);
        if (type) push(`/product/${full_id}`);
      },
      onSearch: () => {
        setScannedItem(null);
        searchPopup();
      },
    });
  };

  const onScanningError = () => {
    setCameraAvailable(false);
  };

  React.useEffect(() => {
    if (navigationState) {
      if (navigationState.search || navigationState.query) {
        searchPopup(true);
      } else if (navigationState.code) {
        onBarcode(navigationState.code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOnline)
    return (
      <>
        <OfflineScreen />
        <Spacer />
      </>
    );

  const scanningPaused = popoverActive || scanPopoverActive;
  return (
    <SearchContainer>
      {cameraAvailable &&
        !popupOpen &&
        (inRDTScannerMode ? (
          <RDTScanner
            switchToCameraScanner={() => setInRDTScannerMode(false)}
            onBarcode={onBarcode}
            hasBottomToolbar
          />
        ) : (
          (canUseBrowserScanner ? (
            <BrowserScanner
              videoRef={videoRef}
              onBarcode={onBarcode}
              paused={scanningPaused}
              switchToRDTScanner={() => setInRDTScannerMode(true)}
            />
          ) : (
            <Scanner
              videoRef={videoRef}
              onBarcode={onBarcode}
              onError={onScanningError}
              scanningPaused={scanningPaused}
              streamPaused={popoverActive || scanPopoverActive}
              switchToRDTScanner={() => setInRDTScannerMode(true)}
            />
          )) ?? <NoCamera />
        ))}
      {!cameraAvailable && (
        <NoCamera>
          <span>
            <Trans>cameraUnavailableTryToSearch</Trans>
          </span>
        </NoCamera>
      )}
      <HeaderContainer>
        <HeaderSearchButton
          onCancel={() => {
            pop();
          }}
          onClick={() => {
            setPopoverActive(true); // Pauses scanning to avoid crashing
            videoRef.current && videoRef.current.pause();
            searchPopup(true);
          }}
        />
        {cameraAvailable && !popoverActive && !inRDTScannerMode && (
          <Tip scanner={canUseBrowserScanner}>
            <Trans>scanTip</Trans>
          </Tip>
        )}
      </HeaderContainer>
    </SearchContainer>
  );
};

export default Search;
