import { Trans } from "@coworker/locales";
import ShoppingBagIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ShoppingBagIconPath";
import ShoppingBasketIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ShoppingBasketIconPath";
import BabyStrollerIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/BabyStrollerIconPath";
import ShoppingBagTrolleyIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ShoppingBagTrolleyIconPath";
import ShoppingTrolleyIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ShoppingTrolleyIconPath";
import FurnitureTrolleyIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FurnitureTrolleyIconPath";
import DocumentFinancialIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/DocumentFinancialIconPath";
import { SVGProps } from "react";
import { FixaRadioButtonGroupListItem } from "@coworker/apprestructured/src/shared/wrappers/FixaRadioButtonGroup/FixaRadioButtonGroup";

type ListViewItemControl =
  | "default"
  | "checkbox"
  | "radiobutton"
  | "switch"
  | "navigational"
  | "link";
interface SVGElementProps extends SVGProps<SVGElement> {
  prefix?: string;
}

type SVGElementArray = (prefix?: string | undefined) => SVGElementProps[];

export interface ShoppingToolType {
  control: ListViewItemControl;
  label: string;
  name: string;
  icon?: SVGElementArray;
}

export const MAX_CASHLINE_TOOLS = 10;
export const MAX_SHOWROOM_TOOLS = 250;
export const TOTAL_MAX_COUNTED_VALUE = 200;
export const TOTAL_ROUND_VALUE = 600;
export const TOTAL_MAX_VALUE = 1800;
export function typesOfShoppingToolsList(
  control?: ListViewItemControl
): ShoppingToolType[] {
  return [
    {
      control: control ?? "default",
      label: "noToolString",
      name: "no_tool",
    },
    {
      control: control ?? "default",
      label: "yellowBagString",
      name: "yellow_bag",
      icon: ShoppingBagIconPath,
    },
    {
      control: control ?? "default",
      label: "blueBagToolString",
      name: "blue_bag",
      icon: ShoppingBagIconPath,
    },
    {
      control: control ?? "default",
      label: "yellowBasketString",
      name: "yellow_basket",
      icon: ShoppingBasketIconPath,
    },
    {
      control: control ?? "default",
      label: "strollerString",
      name: "stroller",
      icon: BabyStrollerIconPath,
    },
    {
      control: control ?? "default",
      label: "bagTrolleyString",
      name: "bag_trolley",
      icon: ShoppingBagTrolleyIconPath,
    },
    {
      control: control ?? "default",
      label: "trolleyString",
      name: "trolley",
      icon: ShoppingTrolleyIconPath,
    },
    {
      control: control ?? "default",
      label: "furnitureTrolleyString",
      name: "furniture_trolley",
      icon: FurnitureTrolleyIconPath,
    },
  ];
}

export function fullServeOrder(
  control: ListViewItemControl
): ShoppingToolType[] {
  return [
    {
      control: control ?? "default",
      label: "hasFullServerOrderString",
      name: "has_full_serve_order",
      icon: DocumentFinancialIconPath,
    },
  ];
}

export function periodOptionsList(
  activePeriod: string
): FixaRadioButtonGroupListItem[] {
  return [
    {
      id: "today",
      name: "today",
      label: <Trans>todayString</Trans>,
      checked: activePeriod === "today",
      readOnly: true,
    },
    {
      id: "currentTertial",
      name: "currentTertial",
      label: <Trans>currentTertialString</Trans>,
      checked: activePeriod === "currentTertial",
      readOnly: true,
    },
    {
      id: "previousTertial",
      name: "previousTertial",
      label: <Trans>previousTertialString</Trans>,
      checked: activePeriod === "previousTertial",
      readOnly: true,
    },
    {
      id: "customTitle",
      name: "custom",
      label: <Trans>customTitleString</Trans>,
      checked: activePeriod === "customTitle",
      readOnly: true,
    },
  ];
}

export function areaOptionsList(
  activeArea: string
): FixaRadioButtonGroupListItem[] {
  return [
    {
      id: "wholeStore",
      name: "wholeStore",
      label: <Trans>wholeStoreString</Trans>,
      checked: activeArea === "wholeStore",
      readOnly: true,
    },
    {
      id: "showroomOW",
      name: "showroomOW",
      label: <Trans>showroomOWString</Trans>,
      checked: activeArea === "showroomOW",
      readOnly: true,
    },
    {
      id: "markethallOW",
      name: "markethallOW",
      label: <Trans>markethallOWString</Trans>,
      checked: activeArea === "markethallOW",
      readOnly: true,
    },
    {
      id: "otherAreaCashline",
      name: "cashline",
      label: <Trans>otherAreaCashlineString</Trans>,
      checked: activeArea === "otherAreaCashline",
      readOnly: true,
    },
  ];
}
export function roundOptionsList(
  activeRound: string
): FixaRadioButtonGroupListItem[] {
  return [
    {
      id: "allRounds",
      name: "allRounds",
      label: <Trans>allRoundsString</Trans>,
      checked: activeRound === "allRounds",
      readOnly: true,
    },
    {
      id: "morningRound",
      name: "morningRound",
      label: <Trans>morningRoundString</Trans>,
      checked: activeRound === "morningRound",
      readOnly: true,
    },
    {
      id: "middayRound",
      name: "middayRound",
      label: <Trans>middayRoundString</Trans>,
      checked: activeRound === "middayRound",
      readOnly: true,
    },
    {
      id: "afternoonRound",
      name: "afternoonRound",
      label: <Trans>afternoonRoundString</Trans>,
      checked: activeRound === "afternoonRound",
      readOnly: true,
    },
  ];
}

export type Division = { division: Divisions; transKey: string };
type Divisions = "showroom" | "markethall" | "cashline";

export const DIVISIONS: Division[] = [
  { division: "showroom", transKey: "showroomOWString" },
  { division: "markethall", transKey: "markethallOWString" },
  { division: "cashline", transKey: "otherAreaCashlineString" },
];

export const DIVISION_HASH = {
  SHOWROOM: DIVISIONS[0],
  MARKETHALL: DIVISIONS[1],
  CASHLINE: DIVISIONS[2],
};

export type Round = { round: Rounds; transKey: string };
type Rounds = "morning" | "midday" | "afternoon" | "oops";

export const ROUNDS: Round[] = [
  { round: "morning", transKey: "morningRoundString" },
  { round: "midday", transKey: "middayRoundString" },
  { round: "afternoon", transKey: "afternoonRoundString" },
];
export const DEFAULT_ROUND: Round = {
  round: "oops",
  transKey: "oops",
};

export const ROUND_HASH = {
  MORNING: ROUNDS[0] || DEFAULT_ROUND,
  MIDDAY: ROUNDS[1] || DEFAULT_ROUND,
  AFTERNOON: ROUNDS[2] || DEFAULT_ROUND,
};

export interface Tertial {
  tertialNumber: number | undefined;
  startDate: string;
  endDate: string;
}
export interface Timestamps {
  startDate: number | undefined;
  endDate: number | undefined;
}

export const TERTIALS: Tertial[] = [
  {
    tertialNumber: 1,
    startDate: "09-01",
    endDate: "12-31",
  },
  {
    tertialNumber: 2,
    startDate: "01-01",
    endDate: "04-30",
  },
  {
    tertialNumber: 3,
    startDate: "05-01",
    endDate: "08-31",
  },
];

const FILTER_PERIODS: string[] = ["today", "currentTertial", "previousTertial"];

export const FILTER_PERIOD_HASH = {
  TODAY: FILTER_PERIODS[0],
  THIS_TERTIAL: FILTER_PERIODS[1],
  LAST_TERTIAL: FILTER_PERIODS[2],
};
export const NO_TOOL = "no_tool";
export interface ColorSchema {
  background: string;
  color: string;
}

export const HEADER_COLOR_SCHEMA = {
  BLUE: {
    background: "var(--blue-medium)",
    color: "var(--white)",
  },
  WHITE: {
    background: "var(--white)",
    color: "var(--black)",
  },
  GREY: {
    background: "var(--grey200)",
    color: "var(--black)",
  },
};
