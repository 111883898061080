import { Trans } from "@coworker/locales";
import { SmallItemSkeleton } from "@coworker/components";
import styled from "styled-components";
import { useItemEdsDateFormatted } from "@coworker/apprestructured/src/shared/hooks/item/useItemEdsDateFormatted";

const LatestSalesDateContainer = styled('div')`
  padding-top: 7px;
  font-size: 14px;
`;

const LatestSalesDateString = styled('span')`
  margin-left: 2px;
`;

export default function LatestSalesDate({ articleId }: { articleId: string }) {
  const { isLoading, edsDate } = useItemEdsDateFormatted(articleId);
  return !isLoading ? (
    <>
      {!!edsDate && (
        <LatestSalesDateContainer>
          <Trans>outgoingProductString</Trans>:
          <LatestSalesDateString>{edsDate}</LatestSalesDateString>
        </LatestSalesDateContainer>
      )}
    </>
  ) : (
    <SmallItemSkeleton />
  );
}
