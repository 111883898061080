import { useState } from "react";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import { Media } from "../types/media";
import {
  CarouselImage,
  PaddedContainer,
  ZoomImageContainer,
  ZoomStaticButtonContainer,
} from "../styles/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import FixaDualButton from "@coworker/apprestructured/src/shared/wrappers/FixaDualButton/FixaDualButton";
import PlusIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PlusIconPath";
import MinusIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/MinusIconPath";
import { Image } from "../types/images";
import { useImagesByMediaId } from "../hooks/useImagesByMediaId";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaTheatre } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaTheatre";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaOverflowCarousel } from "@coworker/apprestructured/src/shared/wrappers/FixaCarousel/FixaCarousel";

interface MediaImagesCarouselProps {
  media: Media;
}

interface ZoomMediaImagesCarousel {
  signedUrlImages: Image[];
  selectedImageName: string;
}

const ZoomMediaImages = ({
  signedUrlImages,
  selectedImageName,
}: ZoomMediaImagesCarousel) => {
  const [zoomLevels, setZoomLevels] = useState<number[]>([]);
  const sortedImages = [...signedUrlImages].sort((a, b) => {
    if (a.fileName === selectedImageName) return -1;
    if (b.fileName === selectedImageName) return 1;
    return 0;
  });
  const handleZoomChange = (index: number, change: number) => {
    const currentZoom = zoomLevels[index] || 1;
    if (change < 0) {
      if (currentZoom <= 1) return;
    }
    const newZoom = currentZoom + change;

    const updatedZoomLevels = [...zoomLevels];
    updatedZoomLevels[index] = newZoom;
    setZoomLevels(updatedZoomLevels);
  };

  return (
    <PaddedContainer $py={"0px"} $px={"16px"}>
      <FixaOverflowCarousel id="imagesByMedia" controlBtnType="primary">
        {sortedImages.map((image, index) => (
          <ZoomImageContainer key={`image-container-${index}`}>
            <TransformWrapper
              key={`image${index}`}
              doubleClick={{ disabled: true }}
              limitToBounds={false}
              centerZoomedOut={true}
            >
              <TransformComponent>
                <div style={{ transform: `scale(${zoomLevels[index] || 1})` }}>
                  <CarouselImage
                    key={`image${index}`}
                    src={image?.url ?? ""}
                    $rotation={image?.rotation || 0}
                  />
                </div>
              </TransformComponent>
            </TransformWrapper>
            <ZoomStaticButtonContainer>
              <FixaDualButton
                orientation="vertical"
                ssrIconFirst={PlusIconPath}
                ssrIconSecond={MinusIconPath}
                onClickFirst={() => handleZoomChange(index, 0.3)}
                onClickSecond={() => handleZoomChange(index, -0.3)}
                small={true}
                type={"primary"}
              ></FixaDualButton>
            </ZoomStaticButtonContainer>
          </ZoomImageContainer>
        ))}
      </FixaOverflowCarousel>
    </PaddedContainer>
  );
};

export const MediaImagesCarousel = ({ media }: MediaImagesCarouselProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [imageName, setImageName] = useState("");
  const { data: images } = useImagesByMediaId(media.id);
  const signedUrlImages = useImagesBySignedUrl(images ?? []);

  if (signedUrlImages && signedUrlImages.length === 0) return <></>;

  const CarouselImages = () => (
    <PaddedContainer $py={"0px"} $px={"16px"}>
      <FixaOverflowCarousel id="imagesByMedia">
        {signedUrlImages.map((image, index) => (
          <CarouselImage
            key={`image${index}`}
            src={image?.url ?? ""}
            $rotation={image?.rotation || 0}
            onClick={() => {
              setOpenModal(true);
              setImageName(image.fileName);
            }}
          />
        ))}
      </FixaOverflowCarousel>
    </PaddedContainer>
  );

  return (
    <>
      <CarouselImages />

      {openModal && (
        <FixaModal
          handleCloseBtn={() => setOpenModal(false)}
          visible={openModal}
        >
          <FixaTheatre header={<FixaModalHeader />}>
            <ZoomMediaImages
              signedUrlImages={signedUrlImages}
              selectedImageName={imageName}
            />
          </FixaTheatre>
        </FixaModal>
      )}
    </>
  );
};
