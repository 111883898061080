import { useQuery } from "@tanstack/react-query";
import { searchArticlesInMedias } from "../services/articles.service";
import { QueryKeys } from "./queryKeys";

export const useSearchArticlesInMedias = (
  storeId: string,
  searchQuery: string
) => {
  const enabled = searchQuery.length > 1;
  return useQuery({
    queryKey: [QueryKeys.SearchArticlesInMedias, { storeId, searchQuery }],
    queryFn: () => searchArticlesInMedias(storeId, searchQuery),
    enabled,
  });
};
