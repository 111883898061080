import {ReactNode} from "react";
import { WidgetRow } from "../PaddedRow";
import { OptionalLabel } from "./OptionalLabel";
import { ReactComponent as NoteIcon } from "../../../assets/svg/Note.svg";
import { useInputPopup } from "../../../components/InputPopup";

interface TextFieldProps {
  placeholder: ReactNode;
  value?: string | undefined;
  onConfirm: (text: string) => void;
  onCancel?: () => void;
  image?: string | ReactNode;
  optional?: boolean;
  testId?: string;
}

export function TextField({
  placeholder,
  value,
  onConfirm,
  onCancel,
  image = <NoteIcon />,
  optional = false,
  testId,
}: TextFieldProps) {
  const { getInput } = useInputPopup();
  const getText = async () => {
    const enteredText = await getInput("description", {
      initialValue: value,
      title: "",
      descriptionTitle: placeholder,
    });

    if (enteredText) {
      onConfirm(enteredText);
    } else {
      !!onCancel && onCancel();
    }
  };

  return (
    <WidgetRow
      className="WidgetView"
      image={image}
      middle={(!!value && <p>{value}</p>) || <p>{placeholder}</p>}
      right={optional && <OptionalLabel />}
      onClick={getText}
      testId={testId || "text-field-widget"}
      disableRightArrow={true}
    ></WidgetRow>
  );
}
