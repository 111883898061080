import { WideButton } from "@coworker/components";
import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";
import { Trans } from "@coworker/locales";
import { useCreateTestbuyTask } from "./useCreateTestbuyTask";

export interface CreateTaskButtonProps {
  task: TestBuyFollowUp;
  articleKey: string;
  testbuyId: string;
  isTaskValid: () => boolean;
}

export function CreateTaskButton({
  task,
  articleKey,
  testbuyId,
  isTaskValid,
}: CreateTaskButtonProps) {
  const { loading, onCreateClick } = useCreateTestbuyTask();

  return (
    <WideButton
      primary
      dark
      showLoader={loading}
      text={<Trans>createString</Trans>}
      disabled={!isTaskValid()}
      onClick={async () => {
        await onCreateClick(task, articleKey, testbuyId);
      }}
      data-testid={"createTestbuyTaskButton"}
    />
  );
}
