import { useTranslation } from "@coworker/locales";
import { getSelectedFilterValue } from "@coworker/apprestructured/src/shared/filters/utils";
import {
  FilterType,
  FilterValue,
  InputType,
  SelectFilter,
} from "@coworker/apprestructured/src/shared/filters/types";

const SORT_BY_ID = "sortBy";

export function useSortByFilter(filterValues: FilterValue[]): SelectFilter {
  const { t } = useTranslation();
  const defaultValue = { filterId: SORT_BY_ID, value: "name" };
  const selected = getSelectedFilterValue(SORT_BY_ID, filterValues, [
    defaultValue,
  ]);
  const options = [
    { value: "name", title: t("nameString") },
    { value: "newest", title: t("newestFirstString") },
    { value: "oldest", title: t("oldestFirstString") },
  ];
  const selectedOption = options.find(
    (option) => option.value === selected.value
  )!;

  return {
    inputType: InputType.Select,
    filterType: FilterType.Sort,
    id: SORT_BY_ID,
    title: t("sortByFilterTitleString", { option: selectedOption.title }),
    options,
    enabled: true,
    defaultValue,
  };
}
