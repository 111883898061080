import { Article } from "../../types/article";
import { addArticlesBulk } from "../../services/articles.service";

export function reduceToUniqueArticlesBasedOnField(
  articles: Article[],
  fieldExtractor: (article: Article) => string
): Article[] {
  return articles.reduce((acc, cur) => {
    const existingArticle = acc.find(
      (article) => fieldExtractor(article) === fieldExtractor(cur)
    );
    if (!existingArticle) {
      acc.push(cur);
    }
    return acc;
  }, [] as Article[]);
}

export async function addUniqueArticlesToGroup(
  articles: Article[],
  groupId: string
): Promise<void> {
  const remappedArticles = articles.map((article) => ({
    ...article,
    nbrArticles: 1,
    groupId: groupId,
  }));

  await addArticlesBulk(
    reduceToUniqueArticlesBasedOnField(
      remappedArticles,
      (article) => article.productArticleId
    ),
    groupId
  );
}
