import React from "react";
import styled from "styled-components";
import profilePreferences from "@coworker/enums/profilePreferences";
import { RadioButton } from "@coworker/components";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import { useFormatRecurring } from "../../hooks/useFormatRecurring";
import { recurringTaskOptions } from "@coworker/enums/recurringTaskOptions";
import Instructions from "../Instructions";
import { useTranslation } from "@coworker/locales";

const recurrableFrequencies = Object.values(recurringTaskOptions);

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--white);
  overflow: hidden;
`;
const Options = styled('div')`
  margin-top: 70px;
`;
const Option = styled('div')`
  border-top: 1px solid var(--grey150);
  border-bottom: 1px solid var(--grey150);
`;
const OptionContent = styled('div')`
  position: relative;
  cursor: pointer;
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 29px;
`;
const Radio = styled('div')`
  width: 50px;
  margin-right: 19px;
`;
const Title = styled('div')`
  font-size: 14px;
  font-weight: bold;
`;

const Repeatable = ({ onSubmit, initialValue }) => {
  const { formatRecurring } = useFormatRecurring(new Date());
  const { t } = useTranslation();
  const defaultInitial = () => {
    if (initialValue) {
      if (initialValue === recurringTaskOptions.NONE) {
        return t("doesNotRepeatString");
      } else {
        return initialValue;
      }
    } else {
      return t("doesNotRepeatString");
    }
  };
  const [checked, setChecked] = React.useState(defaultInitial());

  const [userPreferenceInstructions] = useUserPreference(
    profilePreferences.DISABLE_POPUPS_RECURRING,
    false
  );
  const [done, setDone] = React.useState(false);

  React.useEffect(() => {
    if (!done) return;
    onSubmit(done);
  }, [onSubmit, done]);

  const durationOptions = React.useMemo(
    () =>
      recurrableFrequencies
        .map(formatRecurring)
        .filter(({ frequency }) => frequency !== recurringTaskOptions.PLANNED),
    [formatRecurring]
  );

  return (
    <Container>
      <Options>
        {durationOptions.map((option) => (
          <Option
            key={option.name}
            data-testid={"repeat" + option.frequency}
            onClick={() => {
              setChecked(option.name);
              setDone(option);
            }}
          >
            <OptionContent>
              <Title>{option.name}</Title>
              <Radio>
                <RadioButton
                  data-testid={"repeat" + option.frequency + "-checkbox"}
                  readOnly
                  checked={checked === option.name}
                />
              </Radio>
            </OptionContent>
          </Option>
        ))}
      </Options>
      {!userPreferenceInstructions && (
        <Instructions
          title={t("aboutRepeatingTasksTitle")}
          text={t("infoString")}
          type={profilePreferences.DISABLE_POPUPS_RECURRING}
        />
      )}
    </Container>
  );
};

export default Repeatable;
