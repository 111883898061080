import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import { StarConfig } from "../helper";

interface ShoppingToolsProgressStarProps {
  starConfig: StarConfig;
}
export const ShoppingToolsProgressStar = ({
  starConfig,
}: ShoppingToolsProgressStarProps) => {
  return <FixaSSRIcon paths={starConfig.icon} color={starConfig.color} />;
};
