import { MediaTask } from "@coworker/types/lib/tasks/media";

export function mediaCreateTaskFactory(
  uid: string,
  media_id: string,
  article_id: string,
  articleName: string,
  supplier_number: string | undefined,
  date_stamp: string | undefined,
  pieces: number,
  is_new_article: boolean,
  is_ptag: boolean,
  is_ptag_handled: boolean,
  is_new_article_handled: boolean,
  media_name: string,
  reason: string,
  product_article_id: string,
  store_id: string,
  location_grid_code: string,
  item_type: string,
  item_color: string,
  description: string,
  articleImageUrl: string,
  proof_of_completion: boolean,
  new_product_article_id?: string
): MediaTask {
  return {
    task_type: "ROOM_SETTINGS",
    media_id,
    article_id,
    store_id,
    location_grid_code,
    supplier_number,
    date_stamp,
    pieces,
    is_ptag,
    is_new_article,
    is_ptag_handled,
    is_new_article_handled,
    media_name,
    reason,
    creator_id: uid,
    last_editor_id: uid,
    priority_flag: false,
    product_article_id,
    product_article_type: "ART",
    assigned_team_id: "",
    assigned_user_id: "",
    state: "UNASSIGNED",
    description,
    item_name: articleName,
    created_at: Date.now(),
    last_assigned_at: 0,
    updated_at: 0,
    images: [],
    product_article_currency: "",
    product_article_price: 0,
    product_article_price_missing: true,
    item_color,
    item_type,
    item: {
      measurements: "",
      image_small: articleImageUrl,
    },
    proof_of_completion,
    new_product_article_id,
  } as MediaTask;
}
