import {ReactNode} from "react";
import { WidgetRow } from "../PaddedRow";
import styled from "styled-components";
import { useInputPopup } from "../../../components/InputPopup";

const MiddleWrapper = styled('div')`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
interface divisionProps {
  type: string;
  item: string;
}
interface openAreaSelectionPopupProps {
  selected?: string | ReactNode | undefined;
  onConfirm: (value: string | divisionProps) => void;
  onClose?: (() => void) | undefined;
}

interface AreaSelectionProps {
  title: ReactNode;
  selected?: string | ReactNode | undefined;
  onConfirm: (value: string | divisionProps) => void;
  onClose?: () => void;
  image?: string | ReactNode;
  testId?: string;
}

export function useAreaSelectionPopup(): {
  openAreaSelectionPopup: ({
    selected,
    onConfirm,
    onClose,
  }: openAreaSelectionPopupProps) => Promise<void>;
} {
  const { getInput } = useInputPopup();

  const openAreaSelectionPopup = async ({
    selected,
    onConfirm,
    onClose,
  }: openAreaSelectionPopupProps) => {
    const selectedArea = await getInput("searchSelectArea", {
      initialArea: selected,
    });

    if (selectedArea) {
      onConfirm(selectedArea);
    } else {
      onClose?.();
    }
  };

  return {
    openAreaSelectionPopup,
  };
}

export function AreaSelection({
  title,
  selected,
  onConfirm,
  onClose,
  image,
  testId,
}: AreaSelectionProps) {
  const { openAreaSelectionPopup } = useAreaSelectionPopup();

  return (
    <WidgetRow
      className="WidgetView"
      image={image}
      middle={
        <MiddleWrapper>
          <b>{selected}</b>
          <p>{title}</p>
        </MiddleWrapper>
      }
      right={<></>}
      onClick={() => openAreaSelectionPopup({ selected, onConfirm, onClose })}
      testId={testId || "area-choices-widget"}
    ></WidgetRow>
  );
}
