import React, { useState, useEffect } from "react";
import styles from "./activity-overview.module.css";
import { ActivityFilters } from "./filters/ActivityFilters";
import { TaskStateTabs } from "./states/TaskStateTabs";
import { ActivityColumn } from "./column/ActvityColumn";
import { ActivityFilterType } from "../types/filterOptionsTypes";
import { DEFAULT_FILTER_VALUES } from "../constants/defaultFilterValues";
import { AppliedFilters } from "./filters/applied/AppliedFilters";
import { useActivityData } from "../hooks/useActivityData";
import { useTranslation } from "@coworker/locales";
import { FullTask } from "../types/FullTask";
import { ActivityColumns } from "../constants/activityColumns";
import { FilterPanel } from "../../shared/filters/components";
import { useActivityFilters } from "../hooks/useActivityFilters";
import { FixaWindow } from "@coworker/reusable";
import { mapFilterValuesToObject } from "../helpers/mapFilterValuesToObject";
import FixaButton from "../../shared/wrappers/FixaButton/FixaButton";
import StackIconPath from "../../shared/wrappers/FixaSSRIcon/paths/StackIconPath";
import { MultiSelectPanel } from "./filters/panels/multi-select/MultiSelectPanel";
import { ModalContext } from "../../layout/context/ModalContextProvider";
import { ModalType } from "../../layout/context/modalType";

export const ActivityOverview = () => {
  const [activeTab, setActiveTab] = React.useState(ActivityColumns.OPEN);
  const enableNewActivityFilters = (window as FixaWindow)
    .enableNewActivityFilters;
  const localStorageFilters =
    JSON.parse(localStorage.getItem("activityFilters") as string) ??
    DEFAULT_FILTER_VALUES;
  const activityFilters = useActivityFilters();
  const [filters, setFilters] = useState<ActivityFilterType>(
    enableNewActivityFilters ? DEFAULT_FILTER_VALUES : localStorageFilters
  );
  const { toggleModal } = React.useContext(ModalContext);

  useEffect(() => {
    if (enableNewActivityFilters) {
      if (activityFilters.filterValues.length > 0) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          ...mapFilterValuesToObject(activityFilters.filterValues),
        }));
      } else {
        setFilters(DEFAULT_FILTER_VALUES);
      }
    }
  }, [activityFilters.filterValues, enableNewActivityFilters]);

  const [appliedFiltersCount, setAppliedFiltersCount] = React.useState(0);

  const { openTasks } = useActivityData(filters, ActivityColumns.OPEN) || [];
  const { inProgressTasks } =
    useActivityData(filters, ActivityColumns.IN_PROGRESS) || [];
  const { completedTasks } =
    useActivityData(filters, ActivityColumns.COMPLETED) || [];
  const { closedTasks } =
    useActivityData(filters, ActivityColumns.CLOSED) || [];
  const { t } = useTranslation();

  const allTasks = [
    ...(openTasks.data?.pages.reduce(
      (acc: FullTask[], page) => acc.concat(page.list),
      []
    ) || []),
    ...(inProgressTasks.data?.pages.reduce(
      (acc: FullTask[], page) => acc.concat(page.list),
      []
    ) || []),
    ...(completedTasks.data?.pages.reduce(
      (acc: FullTask[], page) => acc.concat(page.list),
      []
    ) || []),
    ...(closedTasks.data?.pages.reduce(
      (acc: FullTask[], page) => acc.concat(page.list),
      []
    ) || []),
  ];
  
  useEffect(() => {
    if (!filters || typeof filters !== "object" || filters === null) return;
  
    const count = Object.entries(filters).reduce((acc, [key, value]) => {
      // Skip 'sortedBy' key
      if (key === "sortedBy") {
        return acc;
      }
  
      // Count array values by length
      if (Array.isArray(value)) {
        return acc + value.filter(Boolean).length;  // Filter out falsy values in arrays
      }
      
      // Count non-empty values
      if (value && value !== "") {
        return acc + 1;
      }
  
      return acc;
    }, 0);
  
    setAppliedFiltersCount(count);
  }, [filters]);
  

  const columns = [
    {
      transTitleKey: "teamOpenString",
      tasks: openTasks,
      tab: ActivityColumns.OPEN,
    },
    {
      transTitleKey: "inProgressString",
      tasks: inProgressTasks,
      tab: ActivityColumns.IN_PROGRESS,
    },
    {
      transTitleKey: "completd3String",
      tasks: completedTasks,
      tab: ActivityColumns.COMPLETED,
    },
    {
      transTitleKey: "closedFirstUppercaseString",
      tasks: closedTasks,
      tab: ActivityColumns.CLOSED,
    },
  ];

  return (
    <div className={styles["overview-container"]}>
      <div className={styles["title"]}>{t("TasksString")}</div>
      <FixaButton
        iconOnly
        ssrIcon={StackIconPath}
        size="small"
        type="emphasised"
        className={styles["multi-select-button"] as string}
        onClick={() => toggleModal({ type: ModalType.MultiSelectTasks })}
      />
      {enableNewActivityFilters ? (
        <FilterPanel
          filterValues={activityFilters.filterValues}
          setFilterValues={activityFilters.setFilterValues}
          useFiltersFactory={activityFilters.useFiltersFactory}
        />
      ) : (
        <ActivityFilters
          filters={filters}
          setFilters={setFilters}
          appliedFiltersCount={appliedFiltersCount}
          allTasks={allTasks}
          setActiveTab={setActiveTab}
        />
      )}
      {!enableNewActivityFilters && appliedFiltersCount > 0 && (
        <AppliedFilters filters={filters} setFilters={setFilters} />
      )}
      <TaskStateTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tasksCount={{
          open: openTasks.data?.pages[0]?.filteredCount || 0,
          inProgress: inProgressTasks.data?.pages[0]?.filteredCount || 0,
          completed: completedTasks.data?.pages[0]?.filteredCount || 0,
          closed: closedTasks.data?.pages[0]?.filteredCount || 0,
        }}
      />
      <div className={styles["overview-wrapper"]}>
        {columns.map((column) => {
          return (
            <ActivityColumn
              key={column.tab}
              activeTab={activeTab}
              title={column.transTitleKey}
              tasks={
                column.tasks?.data?.pages.map((page) => page.list).flat() || []
              }
              filteredCount={column.tasks?.data?.pages[0]?.filteredCount || 0}
              totalCount={column.tasks?.data?.pages[0]?.totalCount || 0}
              loadNextPage={column.tasks?.fetchNextPage}
              tab={column.tab}
              appliedFiltersCount={appliedFiltersCount}
              setFilters={setFilters}
              isLoading={column.tasks.isLoading}
              isFetching={column.tasks.isFetching}
            />
          );
        })}
      </div>
      <MultiSelectPanel />
    </div>
  );
};
