import React from "react";
import styled from "styled-components";
import { PositiveNumberKeypad } from "../../PositiveNumberKeypad";
import { OverflowBackground } from "../../InputPopup/CommonComponents";
import { cleanKeyNumberPadValue, getGroupCurrencyBlockAmount } from "../helper";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaModalBody } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalBody";
import { useTranslation } from "@coworker/locales";
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";
import { FixaFormField } from "@coworker/apprestructured/src/shared/wrappers/FixaFormField/FixaFormField";

interface KeyNumberPadProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setShowKeyNumberPad: React.Dispatch<React.SetStateAction<boolean>>;
  showComma?: boolean;
  title: string;
  currency?: string;
  showTotalSumKeyPad: boolean;
  displayWarning: boolean;
  setdisplayWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled('div')`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: var(--white);
  border-top: 2px solid var(--grey200);
  z-index: 100;
  height: 55%;
`;

const StyledOverflowBackground = styled(OverflowBackground)`
  height: 45%;
`;

const InputWrapper = styled('div')`
  width: 80%;
  margin: 0px auto;
`;

const KeyPadWrapper = styled('div')`
  border-top: 1px solid var(--grey200);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
`;
export const KeyNumberPad = ({
  value,
  setValue,
  setShowKeyNumberPad,
  showComma = false,
  title,
  currency = "",
  showTotalSumKeyPad,
  displayWarning,
  setdisplayWarning,
}: KeyNumberPadProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const maxcurrencyAmount = getGroupCurrencyBlockAmount(currency);

  return (
    <>
      <Container>
        <StyledOverflowBackground
          onClick={() => {
            setShowKeyNumberPad(false);
          }}
        />
        <FixaModalHeader
          title={t(title)}
          closeBtnClick={() => setShowKeyNumberPad(false)}
        />
        <InputWrapper>
          <FixaFormField
            fieldHelper={{
              msg: "",
            }}
          >
            <FixaInputField
              id="keyNumberPad"
              type="number"
              value={value}
              readOnly
              suffixLabel={currency}
            />
          </FixaFormField>
        </InputWrapper>
        <KeyPadWrapper>
          <PositiveNumberKeypad
            disabled={!value}
            initialValue={cleanKeyNumberPadValue(value)}
            onUpdate={setValue}
            onComplete={() => {
              if (
                showTotalSumKeyPad &&
                parseInt(value, 10) > maxcurrencyAmount &&
                !displayWarning
              ) {
                setIsModalOpen(true);
              } else setShowKeyNumberPad(false);
            }}
            showComma={showComma}
          />
        </KeyPadWrapper>
        <FixaModal
          visible={isModalOpen}
          handleCloseBtn={() => {
            setIsModalOpen(false);
          }}
        >
          <FixaSheets
            footer={
              <FixaModalFooter>
                <FixaButton
                  text={t("yesString")}
                  type="primary"
                  onClick={() => {
                    setIsModalOpen(false);
                    setdisplayWarning(true);
                  }}
                />
                <FixaButton
                  text={t("noString")}
                  type="secondary"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                />
              </FixaModalFooter>
            }
            header={
              <FixaModalHeader
                title={t("warningString")}
                titleId="modalHeader"
              />
            }
          >
            <FixaModalBody>
              <p>
                {" "}
                {t("unsuallyHighNumber")} {value} {currency}
              </p>
            </FixaModalBody>
          </FixaSheets>
        </FixaModal>
      </Container>
    </>
  );
};
