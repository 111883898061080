import styled from "styled-components";
import React from "react";
import { Trans } from "@coworker/locales";

const SectionBar = styled('div')`
  height: 60px;
  background: var(--grey100);
  font-size: 18px;
  color: var(--grey900);
  line-height: 60px;
  padding: 0px 24px;
  font-weight: 700;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ conf: { titleTranslationKey = "" } }) => {
  return (
    <SectionBar>
      <Trans>{titleTranslationKey}</Trans>
    </SectionBar>
  );
};
