import { useState } from "react";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { useTranslation } from "@coworker/locales";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { Media } from "../types/media";
import { MediaTypes } from "../constants";
import { MediaSettingsForm } from "../Organisms/MediaSettingsForm";
import { FullWidthButton } from "../../SkapaButton";
import { useCreateMedia } from "../hooks/useCreateMedia";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "../hooks/queryKeys";
import { Image } from "../types/images";
import { updateImage } from "../services/images.service";
import { useAddImageToMedia } from "../hooks/useAddImageToMedia";
import { useMediasByStoreId } from "../hooks/useMediasByStoreId";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import {
  checkEmptyMediaName,
  checkMediaName,
} from "../Organisms/Settings/SettingsForm.helper";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { getMediaTypeText, getRequiredFields } from "../tools";

export function CreateMediaView() {
  const createMediaMutation = useCreateMedia();
  const storeId = useStoreId();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const [images, setImages] = useState<Image[]>([]);
  const [currentMedia, setCurrentMedia] = useState<Media>({
    mediaType: MediaTypes.NONE,
    storeId: storeId,
    active: "INACTIVE",
  } as Media);
  const [currentCheckedMediaType, setCurrentCheckedMediaType] =
    useState<MediaTypes>(MediaTypes.NONE);
  const [imagesAreUploading, setImagesAreUploading] = useState(false);
  const [mediaIsUpdated, setMediaIsUpdated] = useState(false);

  const { data: medias } = useMediasByStoreId(storeId);
  const addImageToMedia = useAddImageToMedia(currentMedia.id);

  const linkbar = (
    <Linkbar
      currentLabel={t("addMediaString")}
      items={[
        {
          label: t("mediaMaintenanceString"),
          to: "/mediamaintenance",
        },
      ]}
    />
  );

  const onImageAddStart = () => {
    setImagesAreUploading(true);
  };

  const onImageAddEnd = async (addImages: Image[]) => {
    setImages([...images, ...addImages]);
    setMediaIsUpdated(true);
    setImagesAreUploading(false);
  };

  const onImageRemove = async (removeImages: Image[]) => {
    for (const removeImage of removeImages) {
      const removeIndex = images.indexOf(removeImage);
      images.splice(removeIndex, 1);
    }
    setImages([...images]);
  };

  const onImageUpdate = async (image: Image) => {
    if (image.id) {
      try {
        await updateImage(image.id, image);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ImagesByMedia],
        });
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  const submitDisabled =
    !medias ||
    checkEmptyMediaName(currentMedia) ||
    checkMediaName(currentMedia, medias || []) ||
    !mediaIsUpdated ||
    imagesAreUploading ||
    !validateMediaFields(currentMedia);

  const onSubmit = async () => {
    if (!submitDisabled) {
      try {
        const mediaId = await createMediaMutation.mutateAsync(currentMedia);
        if (!mediaId) {
          alert(t("searchErrorString"));
          return;
        }
        for (const image of images) {
          image.mediaId = mediaId.mediaId;
          try {
            await addImageToMedia.mutateAsync(image);
          } catch (error) {
            alert(t("searchErrorString"));
          }
        }
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ImagesByMedia],
        });
        push(`/mediamaintenance/tabs/${mediaId.mediaId}?tab=article-list-tab`);
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  function validateMediaFields(media: Media): boolean {
    const requiredKeys = getRequiredFields(media);
    return requiredKeys.every((key) => {
      const value = media[key as keyof Media];
      return value;
    });
  }

  return (
    <FullScreenPopup
      noPadding
      linkbar={linkbar}
      fullHeightContent={true}
      fieldsAreEmpty={submitDisabled}
      actionBarContent={
        <FullWidthButton
          type="primary"
          text={t("createNameString", {
            name: getMediaTypeText(currentMedia.mediaType as MediaTypes),
          })}
          onClick={onSubmit}
        />
      }
    >
      <MediaSettingsForm
        currentMedia={currentMedia}
        medias={medias || []}
        setCurrentMedia={(newMedia) => {
          setMediaIsUpdated(true);
          setCurrentMedia(newMedia);
        }}
        currentCheckedMediaType={currentCheckedMediaType}
        setCurrentCheckedMediaType={setCurrentCheckedMediaType}
        images={images}
        onImageAddStart={onImageAddStart}
        onImageAddEnd={onImageAddEnd}
        onImageRemove={onImageRemove}
        onImageUpdate={onImageUpdate}
      />
    </FullScreenPopup>
  );
}
