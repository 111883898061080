import { MediaTask } from "@coworker/types/lib/tasks/media";
import { MediasAndArticleCountResponse } from "../types/media";
import { Articles } from "../types/article";

export function getSortedMediasArticleCount(
  mediasArticleCount: MediasAndArticleCountResponse[],
  mediaId?: string | undefined
): MediasAndArticleCountResponse[] {
  const sortedByMediaAndName = mediasArticleCount.sort((a, b) => {
    if (mediaId) {
      if (a.mediaId === mediaId) {
        return -1;
      } else if (b.mediaId === mediaId) {
        return 1;
      }
    }
    return a.name.localeCompare(b.name);
  });

  // Partition into one array per media. This to be able to sort by group later on
  const individualMediaAndArticleCountPerMedia = new Map<
    string,
    MediasAndArticleCountResponse[]
  >();
  for (const mediaArticleCount of sortedByMediaAndName) {
    const media = individualMediaAndArticleCountPerMedia.get(
      mediaArticleCount.mediaId
    );
    if (media) {
      media.push(mediaArticleCount);
    } else {
      individualMediaAndArticleCountPerMedia.set(mediaArticleCount.mediaId, [
        mediaArticleCount,
      ]);
    }
  }

  // Sort each media array by group and reassemble to one array
  const assembledMediasArticleCount: MediasAndArticleCountResponse[] = [];
  individualMediaAndArticleCountPerMedia.forEach(
    (individualMediaAndArticleCount) => {
      const sortedByGroup = sortMediasAndArticleCountByGroup(
        individualMediaAndArticleCount
      );
      assembledMediasArticleCount.push(...sortedByGroup);
    }
  );

  return assembledMediasArticleCount;
}

function sortMediasAndArticleCountByGroup(
  mediasArticleCount: MediasAndArticleCountResponse[]
): MediasAndArticleCountResponse[] {
  return mediasArticleCount.sort((a, b) => {
    if (!a.groupName) {
      return -1;
    } else if (!b.groupName) {
      return 1;
    }
    return a.groupName.localeCompare(b.groupName);
  });
}

export function sortArticlesByGroup(articles: Articles) {
  return articles.sort((a, b) => {
    if (!a.groupName) {
      return -1;
    } else if (!b.groupName) {
      return 1;
    }
    return a.groupName.localeCompare(b.groupName);
  });
}

export function reduceMediasArticleCount(
  mediasArticleCount: MediasAndArticleCountResponse[]
): MediasAndArticleCountResponse[] {
  return mediasArticleCount.reduce((acc, cur) => {
    const existingMedia = acc.find((media) => media.mediaId === cur.mediaId);
    if (existingMedia) {
      existingMedia.nbrArticles += cur.nbrArticles;
    } else {
      acc.push(cur);
    }
    return acc;
  }, [] as MediasAndArticleCountResponse[]);
}

export function getPrimeTasks(
  mediasArticleCount: MediasAndArticleCountResponse[],
  tasks: MediaTask[]
): MediaTask[] {
  const primeTasks: MediaTask[] = [];
  mediasArticleCount.forEach(({ articleId: article_id }) => {
    const matchedTask = tasks.find((task) => task.article_id === article_id);
    if (matchedTask) {
      primeTasks.push(matchedTask);
    }
  });

  return primeTasks;
}
