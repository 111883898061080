import React from "react";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";
import { useTranslation } from "@coworker/locales";
import styles from "./tab-navigation.module.css";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import trackerHelper from "@coworker/app/src/helpers/tracker";

const TabNavigation = () => {
  const location = useFixaLocation();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const tabs = [
    {
      label: t("homeString"),
      href: "/home",
      testId: "homeTabbedNavigationButton",
    },
    {
      label: t("TasksString"),
      href: "/activity",
      testId: "tasksTabbedNavigationButton",
    },
  ];

  return (
    <div className={styles["tab-container"]}>
      {tabs.map((tab) => {
        const isActive =
          tab.href &&
          (`${location.pathname}${location.search}` === tab.href ||
            (tab.href === "/tasks?active=my" &&
              location.search === "?active=open"));
        return (
          <div
            className={styles["tab-wrapper"]}
            key={tab.label}
            data-testid={tab.testId}
          >
            <div
              onClick={() => {
                push(tab.href);
                trackerHelper.trackMainHeaderNavigationLinksClick(tab.label);
              }}
              className={styles[isActive ? "tab-active" : "tab"]}
            >
              {tab.label}
            </div>
            {isActive && <div className={styles["bottom-border"]} />}
          </div>
        );
      })}
    </div>
  );
};

export default TabNavigation;
