import { useTranslation } from "@coworker/locales";
import { useAllSelectOption } from "@coworker/apprestructured/src/shared/filters/utils";
import {
  FilterType,
  InputType,
} from "@coworker/apprestructured/src/shared/filters/types";

const STATUS_ID = "status";

export function useStatusFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: STATUS_ID, value: "" };

  return useAllSelectOption({
    inputType: InputType.Select,
    filterType: FilterType.Filter,
    id: STATUS_ID,
    title: t("statusString"),
    options: [
      {
        value: "ACTIVE",
        title: t("userStatus_ACTIVE"),
      },
      {
        value: "INACTIVE",
        title: t("userStatus_INACTIVE"),
      },
    ],
    enabled: true,
    defaultValue,
  });
}
