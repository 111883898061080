import React, { useState } from "react";
import {
  MultiSelectFilter,
  SelectOption,
  StringArrayFilterValue,
} from "../../types";
import { FixaListView } from "../../../wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../wrappers/FixaListView/FixaListViewItem";
import FixaSearch from "../../../wrappers/FixaSearch/FixaSearch";
import { useTranslation } from "@coworker/locales";
import SelectListViewCss from "./SelectListView.module.css";
import { localeIncludes } from "./SelectListView.helper";
import {
  getSelectOptionKey,
  isSelectOption,
  isSelectOptionSelected,
} from "../../utils";
import { CategoryListItem } from "./CategoryListItem";

interface MultiSelectListViewProps {
  prefix: string;
  filter: MultiSelectFilter;
  selected: StringArrayFilterValue;
  onChange: (option: SelectOption, isSelected: boolean) => void;
}

export const MultiSelectListView = ({
  prefix,
  filter,
  onChange,
  selected,
}: MultiSelectListViewProps) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const { id, title, options, showSearch } = filter;
  return (
    <div>
      {showSearch && (
        <FixaSearch
          className={SelectListViewCss["search-input"] ?? ""}
          id={`listview-search-${prefix}-${id}`}
          value={searchInput}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchInput(e.target.value);
          }}
          onClear={() => setSearchInput("")}
          placeholder={t("searchFilterOptionsString", { title })}
          data-testid={`listview-search-${prefix}-${id}`}
        />
      )}
      <FixaListView
        id={`listview-${prefix}-${id}`}
        data-testid={`listview-${prefix}-${id}`}
        size="small"
        showDivider={false}
      >
        {options
          .filter(
            (option) =>
              !isSelectOption(option) ||
              isSelectOptionSelected(selected, option) ||
              localeIncludes(option.title, searchInput)
          )
          .map((option) => {
            if (isSelectOption(option)) {
              const { value } = option;
              const isSelected = isSelectOptionSelected(selected, option);
              return (
                <FixaListViewItem
                  key={getSelectOptionKey(option)}
                  inset
                  onChange={() => {
                    onChange(option, isSelected);
                  }}
                  control="checkbox"
                  controlProps={{
                    value: isSelected ? "ON" : "OFF",
                    checked: isSelected,
                  }}
                  showDivider={false}
                  title={option.title}
                  data-testid={`listview-option-${prefix}-${value}`}
                />
              );
            } else {
              return (
                <CategoryListItem
                  key={getSelectOptionKey(option)}
                  item={option}
                  prefix={prefix}
                />
              );
            }
          })}
      </FixaListView>
    </div>
  );
};
