import React from "react";
import styled from "styled-components";
import { ReactComponent as ErrorIcon } from "../assets/svg/icon-error.svg";

const ErrorMessage = styled('div')`
  color: var(--error);
  font-size: 12px;
  display: flex;
  align-items: start;
  svg {
    margin-right: 6px;
  }
`;

const Error = (props) => {
  const { text = "Error" } = props;
  return (
    <>
      {!!text && (
        <ErrorMessage>
          <ErrorIcon /> {text}
        </ErrorMessage>
      )}
    </>
  );
};

export default Error;
