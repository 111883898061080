import { useTranslation } from "@coworker/locales";
import { CardHeadLine } from "../../Molecules/Settings/CardHeadLine";
import {
  CardFrame,
  FullLine,
  PaddedContainer,
  WhiteSpace,
} from "../../styles/styles";
import { Media } from "../../types/media";
import { getMediaTypeText, isValidMedia } from "../../tools";
import { MediaTypes, roomSettingsFormFields } from "../../constants";
import { MainMessageInput } from "../../Molecules/Settings/MainMessageInput";
import MediaFormFieldComponent from "../../Molecules/Settings/MediaFormField";

interface AboutRoomSettingsProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const AboutRoomSettings = ({
  media,
  onSave,
}: AboutRoomSettingsProps) => {
  const { t } = useTranslation();
  return (
    <CardFrame>
      <CardHeadLine
        character={"2"}
        headline={t(`aboutString`, {
          name: getMediaTypeText(MediaTypes.ROOM_SETTINGS),
        })}
      />
      <FullLine />
      <PaddedContainer $py="0px" $px="24px">
        <MainMessageInput media={media} onSave={onSave} />
        <WhiteSpace height={"12"} />
        {roomSettingsFormFields.map((field, key) => (
          <MediaFormFieldComponent
            key={key}
            type={field}
            media={media}
            onSave={onSave}
            valid={isValidMedia(media, field)}
          />
        ))}
        <WhiteSpace height={"12"} />
      </PaddedContainer>
    </CardFrame>
  );
};
