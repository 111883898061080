import { useEffect, useRef, useState } from "react";
import defaultPhotoUrl from "../../assets/svg/generic-profile.svg";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "../../constants/clientTime";
import { fetchUserAvatar } from "./core.service";

const verifiedUrls = new Set();

function tryLoadingImage(url: string, callback: (url: string) => any) {
  if (!url) return;
  if (verifiedUrls.has(url)) return callback(url);
  const img = new Image();

  function handler(event: any) {
    img.removeEventListener("load", handler);
    img.removeEventListener("error", handler);
    if (event.type === "load" && img.width) {
      verifiedUrls.add(url);
      callback(url);
    } else {
      callback("");
    }
  }

  img.addEventListener("load", handler);
  img.addEventListener("error", handler);
  img.src = url;
}

export function useAvatarImage(fixaUid: string) {
  const [url, setUrl] = useState("");
  const mounted = useRef(true);
  const avatarImage = useUserAvatarQuery(fixaUid);

  useEffect(() => {
    if (!(avatarImage instanceof Blob)) {
      return;
    }
    if (avatarImage) {
      tryLoadingImage(URL.createObjectURL(avatarImage), (url: string) => {
        if (mounted.current) setUrl(url);
      });
    }
  }, [fixaUid, avatarImage]);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return url || defaultPhotoUrl;
}

export function useUserAvatarQuery(fixaUid: string) {
  const result = useQuery({
    queryKey: ["userAvatar", fixaUid],
    queryFn: async () => {
      const data = await fetchUserAvatar(fixaUid);
      return data;
    },
    enabled: !!fixaUid,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1 * INTERVAL.WEEK,
  });
  return result?.data;
}