import { Media } from "../../../types/media";
import { useTranslation } from "@coworker/locales";
import { CardFrame, FullLine, PaddedContainer } from "../../../styles/styles";
import { CardHeadLine } from "../../../Molecules/Settings/CardHeadLine";
import { LabelText } from "../../../Atoms/LabelText";
import { MediaImagesListItem } from "../../../Molecules/MediaImagesListItem";
import { Image } from "../../../types/images";
import { MediaTypes } from "../../../constants";

interface ImagesSectionProps {
  media: Media;
  images: Image[];
  onImageAddStart: (fileList: FileList) => void;
  onImageAddEnd: (images: Image[]) => void;
  onImageRemove: (images: Image[]) => void;
  onImageUpdate: (image: Image) => void;
}

export const ImagesSection = ({
  media,
  images,
  onImageAddStart,
  onImageAddEnd,
  onImageRemove,
  onImageUpdate,
}: ImagesSectionProps) => {
  const { t } = useTranslation();

  return (
    <CardFrame>
      <CardHeadLine character={"4"} headline={t("addPhotoString")} />
      <FullLine />
      {media.mediaType !== MediaTypes.NONE && (
        <PaddedContainer $py="0px" $px="24px">
          <LabelText text={t("photosString")} />
          <MediaImagesListItem
            media={media}
            images={images}
            onAddStart={onImageAddStart}
            onAddEnd={onImageAddEnd}
            onRemove={onImageRemove}
            onUpdate={onImageUpdate}
          />
        </PaddedContainer>
      )}
    </CardFrame>
  );
};
