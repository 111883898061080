import { useGroupedArticlesSelectFilter } from "@coworker/apprestructured/src/shared/filters/filters";
import {
  ApplyFilter,
  FilterValue,
  SelectFilter,
} from "@coworker/apprestructured/src/shared/filters/types";
import { Combined } from "../types/article";
import { AggregatedArticle } from "../hooks/useAggregatedArticles";

function useGroupedArticlesSelectArticleFilter() {
  const filter: ApplyFilter<SelectFilter, AggregatedArticle> = {
    filter: useGroupedArticlesSelectFilter(),
    filterCompare: (
      item: Combined<AggregatedArticle>,
      { value }: FilterValue
    ) => {
      switch (value) {
        case "onlyInGroups":
          if (item.aggregatedArticles.length === item.groupIds.length) {
            return true;
          }
          return false;
        case "notInGroups":
          if (!item.groupIds.length) {
            return true;
          }
          return false;
        default:
          return true;
      }
    },
  };

  return filter;
}

export { useGroupedArticlesSelectArticleFilter };
