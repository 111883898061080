
import styled from "styled-components";

import { Media } from "../../types/media";
import { LabelText } from "../../Atoms/LabelText";
import { useTranslation } from "@coworker/locales";
import { FixaSwitch } from "@coworker/apprestructured/src/shared/wrappers/FixaSwitch/FixaSwitch";

const FlexSpaceBetween = styled('div')`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 42px);
  margin: 24px;
`;

interface ActiveSwitcherProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const ActiveSwitcher = ({ media, onSave }: ActiveSwitcherProps) => {
  const { t } = useTranslation();
  return (
    <FlexSpaceBetween>
      <LabelText text={t("activeString")} />
      <FixaSwitch
        id="active"
        checked={media.active === "ACTIVE"}
        value={media.active || ""}
        onChange={() => {
          const newActive = media.active === "ACTIVE" ? "INACTIVE" : "ACTIVE";
          onSave({
            ...media,
            active: newActive,
          });
        }}
        disabled={!media.name}
      />
    </FlexSpaceBetween>
  );
};
