import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { NarrowButton } from "./SkapaButton";
import { useWorkspacesAction } from "../hooks/useWorkspaces";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  overflow-y: auto;
`;

const ImageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 30px;
`;
const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled('div')`
  color: var(--grey718);
  font-weight: bold;
  max-width: 162px;
  text-align: center;
  line-height: 24px;
  letter-spacing: -0.19px;
`;

const SubTitle = styled('div')`
  font-size: 14px;
  color: var(--grey718);
  margin-bottom: 10px;
`;

const EmptyScreen = ({
  action = {},
  subTitle,
  title = <Trans>nothingString</Trans>,
  hideButton = false,
}) => {
  const { push } = useWorkspacesAction();
  const { toggleModal } = React.useContext(ModalContext);
  const location = useFixaLocation();
  return (
    <Container>
      <ImageContainer>
        <TextContainer>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          <Title>{title}</Title>
        </TextContainer>
      </ImageContainer>

      {!hideButton && (
        <NarrowButton
          text={action.text}
          onClick={() => {
            if (action.click) {
              action.click();
            }
            if (action.path) {
              if (location.search === "?active=my") {
                push(action.path);
              } else {
                toggleModal({ type: ModalType.MainNavigation });
              }
            }
          }}
        />
      )}
    </Container>
  );
};

export default EmptyScreen;
