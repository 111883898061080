import FixaPill from "../../../wrappers/FixaPill/FixaPill";
import { Filter, FilterValue } from "../../types";
import CrossIconPath from "../../../wrappers/FixaSSRIcon/paths/CrossIconPath";

interface AppliedCheckboxPillProps {
  filter: Filter;
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
  filterValue: boolean;
}

export const AppliedCheckboxPill = ({
  filter,
  filterValues,
  setFilterValues,
  filterValue,
}: AppliedCheckboxPillProps) => {
  if (!filterValue) return null;

  return (
    <FixaPill
      id={filter.id}
      label={filter.title}
      size="small"
      selected
      iconPosition="trailing"
      ssrIcon={CrossIconPath}
      onClick={() =>
        setFilterValues(
          filterValues.filter((value) => value.filterId !== filter.id)
        )
      }
    />
  );
};
