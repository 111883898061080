import React from "react";
import styled from "styled-components";

export const PillShell = styled('span')`
  border-radius: 999px;
  padding: 12px 24px;
  margin: auto 4px;
  height: 16px;
  line-height: 16px;
  white-space: nowrap;
  background-color: var(--grey100);
  color: var(--grey900);
  font-size: 12px;
  font-weight: bold;
`;

const InactivePill = PillShell;

const ActivePill = styled(InactivePill)`
  border: 1px solid var(--grey900);
`;

const DisabledInactivePill = styled(PillShell)`
  color: var(--grey300);
  pointer-events: none;
`;

const DisabledActivePill = styled(InactivePill)`
  color: var(--grey300);
  pointer-events: none;
  border: 1px solid var(--grey900);
`;

export function Pill({
  text,
  disabled = false,
  active = false,
  onClick = () => null,
}) {
  return active ? (
    disabled ? (
      <DisabledActivePill onClick={onClick}>{text}</DisabledActivePill>
    ) : (
      <ActivePill onClick={onClick}>{text}</ActivePill>
    )
  ) : disabled ? (
    <DisabledInactivePill onClick={onClick}>{text}</DisabledInactivePill>
  ) : (
    <InactivePill onClick={onClick}>{text}</InactivePill>
  );
}
