import React from "react";
import styled, { css } from "styled-components";
import { Header } from "@coworker/components";
import inputTypes from "./types";
import { isRDTDevice } from "@coworker/reusable";

const InputPopupContext = React.createContext(null);

const useInputPopup = () => {
  const inputPopupContext = React.useContext(InputPopupContext);
  const [config, setConfig = () => null, configRef = {}] =
    inputPopupContext || [];

  const getInput = React.useCallback(
    (type, conf = {}) => {
      if (!inputTypes[type]) throw Error(`Missing popup: ${type}`);
      let resolver = null;

      const resolve = (value) => {
        setConfig(null);
        if (resolver) {
          resolver(value);
        }
      };
      const cancel = (value) => {
        setConfig(null);
        if (resolver) {
          if (value?.target) resolver();
          // Existing usage of `cancel` typically call it with a browser click event.
          else resolver(value); // This is the new usage needed for Jira-4130.
        }
      };

      const close = () => {
        setConfig(null);
        if (resolver) {
          resolver();
        }
      };

      // // Avoid unnecessary state updates
      // setConfig((prevConfig) => {
      //   if (
      //     prevConfig?.type === type &&
      //     JSON.stringify(prevConfig) === JSON.stringify({ type, ...conf, resolve, cancel, close, test: "test" })
      //   ) {
      //     return prevConfig;
      //   }
      //   return { type, ...conf, resolve, cancel, close, test: "test" };
      // });
      setConfig({ type, ...conf, resolve, cancel, close, test: "test" });

      return new Promise((resolve) => {
        resolver = resolve;
      });
    },
    [setConfig]
  );

  return {
    getInput,
    popupOpen: !!config,
    currentType: config?.type,
    closePopup: () =>
      configRef.current &&
      !configRef.current.nonClosable &&
      configRef.current.cancel(),
  };
};

const useParentPopup = () => {
  const [, , , [config, setConfig, configRef]] =
    React.useContext(InputPopupContext);

  const getInput = React.useCallback(
    (type, conf = {}) => {
      let resolver = null;

      const resolve = (value) => {
        setConfig(null);
        if (resolver) {
          resolver(value);
        }
      };
      const cancel = () => {
        setConfig(null);
        if (resolver) {
          resolver();
        }
      };

      setConfig({ type, ...conf, resolve, cancel, test: "test" });
      return new Promise((resolve) => {
        resolver = resolve;
      });
    },
    [setConfig]
  );
  return {
    getInput,
    popupOpen: !!config,
    closePopup: () => configRef.current && configRef.current.cancel(),
  };
};

const Container = styled('div')`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  z-index: var(--z-popup);
  ${(props) =>
    props.animation &&
    css`
      transition-property: top;
      transition-duration: 0.25s;
      transition-timing-function: ease-in;
    `}

  ${(props) => {
    switch (props.state) {
      case "enter":
        return css`
          top: 0;
        `;
      case "exit":
        return css`
          top: 100%;
        `;
      default:
        break;
    }
  }}
  height: 100%;
  background-color: transparent;
`;

const Content = styled('div')`
  flex-grow: 1;
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : "var(--white)"};
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const HeaderContainer = styled('div')`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
`;

const WhiteBackground = styled('div')`
  width: 100%;
  height: 100%;
  background: transparent;
`;

const PopupContainer = styled('div')`
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

const InputPopup = () => {
  const [input] = React.useContext(InputPopupContext);
  const { type = "", cancel, resolve, close, ...config } = input || {};
  const popupConf = inputTypes[type] || {};
  const Component = popupConf.component;
  const hideHeader = popupConf.hideHeader;
  const hideOverlay = popupConf.hideOverlay;
  const child = input ? (
    <PopupContainer data-testid="pop-up-container">
      <Content transparent={hideOverlay}>
        <Component
          {...config}
          onSubmit={resolve}
          onClose={close}
          onCancel={cancel}
        />
      </Content>
      {!hideHeader && (
        <HeaderContainer>
          <Header showRightClose onClose={cancel} title={config.title} />
        </HeaderContainer>
      )}
    </PopupContainer>
  ) : (
    <WhiteBackground />
  );

  const isRDT = isRDTDevice();

  return (
    <Container
      animation={!popupConf.noAnimation && !isRDT}
      state={input ? "enter" : "exit"}
    >
      {child}
    </Container>
  );
};

const InputPopupProvider = ({ children }) => {
  const parent = React.useContext(InputPopupContext);
  const [input, setInput] = React.useState(null);
  const inputRef = React.useRef();
  inputRef.current = input;

  return (
    <InputPopupContext.Provider value={[input, setInput, inputRef, parent]}>
      {children}
    </InputPopupContext.Provider>
  );
};

export { useInputPopup, InputPopup, InputPopupProvider, useParentPopup };
