import {
  SelectOption,
  StringArrayFilterValue,
  StringFilterValue,
} from "../../types";
import { isSelectOptionStringArray } from "./selectoption";

export const createFilterValueFromSelectOption = (
  filterId: string,
  option: SelectOption
): StringFilterValue => {
  const { dependsOnFilterId, dependentOfFilterId } = option;
  const value = isSelectOptionStringArray(option)
    ? option.value[0] ?? ""
    : option.value;
  return {
    filterId,
    value,
    dependsOnFilterId,
    dependentOfFilterId,
  };
};

export const createFilterValueFromMultiSelectOption = (
  filterId: string,
  option: SelectOption,
  currentFilterValue: StringArrayFilterValue,
  isSelected: boolean
): StringArrayFilterValue => {
  const { dependsOnFilterId, dependentOfFilterId } = option;
  const currentValueSet = new Set(currentFilterValue.value);
  if (isSelectOptionStringArray(option)) {
    for (const value of option.value) {
      if (isSelected) {
        currentValueSet.delete(value);
      } else {
        currentValueSet.add(value);
      }
    }
  } else {
    if (isSelected) {
      currentValueSet.delete(option.value);
    } else {
      currentValueSet.add(option.value);
    }
  }

  return {
    filterId,
    value: [...currentValueSet],
    dependsOnFilterId,
    dependentOfFilterId,
  };
};
