import { getTokenAsync } from "./getAuthToken";
import { makeServiceHeaders } from "../../core/hooks/fetch.helpers";

export async function callToFetch(method: string, url: string) {
  let headers: HeadersInit;
  try {
    const token = await getTokenAsync();
    if (!token || typeof token !== "string") {
      return;
    }

    try {
      if (typeof makeServiceHeaders !== "function") {
        console.error("makeServiceHeaders is undefined");
        return;
      }

      headers = await makeServiceHeaders();
    } catch (error) {
      console.error("Error creating service headers:", error);
      return;
    }
    const params = {
      method,
      headers,
    };
    const response = await fetch(url, params);
    const contentType = response.headers.get("Content-Type");
    if (!response.ok) {
      let errorDetails;
      if (contentType && contentType.includes("application/json")) {
        errorDetails = await response.json();
      } else {
        errorDetails = await response.text();
      }
      throw new Error(
        `Fetching from core-service failed: ${response.status} ${response.statusText}. Details: ${errorDetails}`
      );
    }

    if (contentType && contentType.includes("application/json")) {
      return response.json();
    } else {
      return response.text();
    }
  } catch (error: any) {
    console.error(`Error in callToFetch: ${error.message}`);
    throw new Error(`Error in callToFetch: ${error.message}`);
  }
}
