import React from "react";
import styled from "styled-components";
import { ElidedText } from "@coworker/reusable/components/ElidedText";
import { StockInfoForHalfsheet } from "./StockInfo";
import { formatFullIdWithDots } from "@coworker/reusable";
import { WidgetTop } from "../core/components/PaddedRow";
import { ItemSalesPrice } from "@coworker/apprestructured/src/shared/types/item";

const NoPrice = styled('div')`
  color: var(--grey700);
  font-weight: bold;
`;

const Prices = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ItemDetails = styled('div')`
  flex-grow: 1;
  width: 100%;
  padding-top: 3px;

  & > h1 {
    font-size: 14px;
    font-weight: bold;
    color: var(--grey800);
  }
  & > h2 {
    font-size: 14px;
    line-height: 1.5;
    color: var(--grey700);
    font-weight: normal;
  }
`;

interface ScanItemResultProps {
  title: string;
  description: string;
  fullId: string;
  price: ItemSalesPrice | undefined;
  secondaryPrice: ItemSalesPrice | undefined;
  image: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  latestSalesDate: React.ReactNode;
}

export function ScanItemResult({
  title,
  description,
  fullId,
  price,
  secondaryPrice,
  image,
  onClick,
  latestSalesDate,
}: ScanItemResultProps) {
  const formattedShortId = formatFullIdWithDots(fullId);

  const priceString = React.useMemo(() => {
    const currency = price?.currency;
    const amount = parseFloat(`${price?.price}`);
    if (!currency || !amount) return "";
    const decimals = amount % 1 ? 2 : 0;
    const value = amount.toFixed(decimals);
    const unit = ` ${price?.unit || ""}`;
    return `${value} ${currency}${unit.includes("m") ? unit : ""}`;
  }, [price]);

  const secondaryPriceString = React.useMemo(() => {
    const currency = secondaryPrice?.currency;
    const amount = parseFloat(`${secondaryPrice?.price}`);
    if (!currency || !amount) return "";
    const decimals = amount % 1 ? 2 : 0;
    const value = amount.toFixed(decimals);
    const unit = ` ${secondaryPrice?.unit || ""}`;
    return `${value} ${currency}${unit.includes("m") ? unit : ""}`;
  }, [secondaryPrice]);

  const middle = (
    <ItemDetails data-testid="halfSheetDetails">
      <h1>{title}</h1>
      <h2>
        <ElidedText>{description}</ElidedText>
      </h2>
      <h2>{formattedShortId}</h2>
    </ItemDetails>
  );

  const right = (
    <Prices>
      <span>{price ? priceString : <NoPrice>—</NoPrice>}</span>
      {secondaryPrice && <span>{secondaryPriceString}</span>}
    </Prices>
  );

  const children = (
    <>
      <StockInfoForHalfsheet full_id={fullId} />
      {latestSalesDate}
    </>
  );
  return (
    <WidgetTop
      image={image}
      middle={middle}
      right={right}
      onClick={onClick}
      children={children}
    />
  );
}
