import { AggregatedArticle } from "./useAggregatedArticles";
import { useMemoryFilteredData } from "@coworker/apprestructured/src/shared/filters/utils/apply/useMemoryFilteredData";
import {
  useHfbSelectArticleFilter,
  useAlertSelectArticleFilter,
  useTasksSelectArticleFilter,
  useSortSelectArticleFilter,
  useEdsDatesSelectArticleFilter,
  useGroupedArticlesSelectArticleFilter,
} from "../filters";
import preferences from "@coworker/enums/profilePreferences";
import { useUserPreferenceStoreFilterValues } from "@coworker/apprestructured/src/shared/filters/utils";
import { useEdsDates } from "./useEdsDates";
import { FilterValue } from "@coworker/apprestructured/src/shared/filters/types";

// This code will probably go into AggregatedArticleListing when old filter solution is removed
export function useMediaArticlesFilters(
  articles: AggregatedArticle[] | undefined
) {
  const { filterValues, setFilterValues } = useUserPreferenceStoreFilterValues(
    preferences.MEDIA_FILTERS_ARTICLES
  );
  const edsDates = useEdsDates(articles ?? []);
  const useMediaDataFilters = (values: FilterValue[]) => [
    useHfbSelectArticleFilter(),
    useAlertSelectArticleFilter(),
    useEdsDatesSelectArticleFilter(values, edsDates),
    useTasksSelectArticleFilter(),
    useGroupedArticlesSelectArticleFilter(),
    useSortSelectArticleFilter(values),
  ];
  const useFiltersFactory = (values: FilterValue[]) => {
    const dataFilters = useMediaDataFilters(values);
    return dataFilters.map(({ filter }) => filter);
  };

  const dataFilters = useMediaDataFilters(filterValues);

  function useFilteredArticleData(articles: AggregatedArticle[] | undefined) {
    return useMemoryFilteredData<AggregatedArticle>(
      articles,
      dataFilters,
      filterValues
    );
  }

  return {
    useFiltersFactory,
    filterValues,
    setFilterValues,
    useFilteredArticleData,
  };
}
