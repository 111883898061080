
import { LeftContainer, FlexibleRowContainer } from "../../styles/styles";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";

export const MediumSkeletonBox = () => {
  return (
    <FlexibleRowContainer style={{ padding: "20px 24px" }}>
      <LeftContainer>
        <FixaSkeleton height={"120px"} width={"144px"} />
      </LeftContainer>
    </FlexibleRowContainer>
  );
};
