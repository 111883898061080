import React from "react";
import styled, { css } from "styled-components";
import { Container, OverflowBackground } from "./CommonComponents";

const RoundedContainer = styled(Container)`
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 24px;
`;

const PillsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px 48px;
  & > div {
    margin: 0 5px;
  }
  & > :first-child {
    margin-left: 0px;
    margin-right: 5px;
  }
  & > :last-child {
    margin-left: 5px;
    margin-right: 0px;
  }
`;

interface PillProps {
  selected: boolean;
}

const Pill = styled.div`
  flex-grow: 1;
  ${({ selected }: PillProps) =>
    selected
      ? css`
          background-color: var(--black);
          color: var(--white);
        `
      : css`
          background-color: var(--grey200);
        `}
  border-radius: 20px;
  height: 40px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface PopupProps<ChoiceT extends React.ReactNode> {
  title: React.ReactNode;
  choices: ChoiceT[];
  selected: ChoiceT;
  onConfirm: (value: ChoiceT) => void;
  onClose?: () => void;
  closePopupWhenSelected: boolean;
}

export function PillSelectionPopup<ChoiceT extends React.ReactNode>({
  title,
  choices,
  selected,
  onConfirm,
  onClose,
  closePopupWhenSelected,
}: PopupProps<ChoiceT>) {
  const [selectedValue, setSelectedValue] = React.useState(selected);
  const formatKey = (choice: ChoiceT, index: number) => {
    if (typeof choice == "string" || typeof choice == "number") {
      return choice;
    }

    return index;
  };

  return (
    <>
      <OverflowBackground data-testid="background" onClick={onClose} />
      <RoundedContainer data-testid="pill-choices-container">
        <Title><>{title}</></Title>
        <PillsContainer>
          {choices.map((choice, index) => (
            <Pill
              key={formatKey(choice, index)}
              selected={choice === selectedValue}
              onClick={() => {
                setSelectedValue(choice);
                onConfirm(choice);
                closePopupWhenSelected && onClose?.();
              }}
              data-testid={`pill-choice-${String(choice)}`}
            >
              <strong>{String(choice)}</strong>
            </Pill>
          ))}
        </PillsContainer>
      </RoundedContainer>
    </>
  );
}
