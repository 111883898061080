import React from "react";
import taskTypeOptions from "@coworker/enums/taskType";
import taskStateOptions from "@coworker/enums/taskState";
import activityTypes from "@coworker/enums/activityTypes";
import trackerHelper from "../../helpers/tracker";
import { createQuestionTask } from "../../components/MFAQ/BackendApiRepository/MfaqRepository";
import { useCreateTaskMutation } from "@coworker/apprestructured/src/tasks/hooks/useCreateTaskMutation";

function replaceInvalidWithEmptyString(task) {
  const arr = Object.entries(task).map(([key, val]) => {
    if (val === undefined) {
      return [key, ""];
    } else {
      return [key, val];
    }
  });

  return arr.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

export function useCreateTask() {
  const createTaskMutation = useCreateTaskMutation();
  const createTask = React.useCallback(
    async (formData, images) => {
      const taskData = {
        created_at: Date.now(),
        ...formData,
        activity_type: formData.assigned_user_id
          ? activityTypes.CREATE_AND_ASSIGN_TO_USER
          : activityTypes.CREATE_AND_ASSIGN_TO_TEAM,
      };
      if (taskData.planned) {
        trackerHelper.plannedTasks.created(taskData.planned);
      }

      const cleanedTask = replaceInvalidWithEmptyString(taskData);
      if (
        cleanedTask.state === taskStateOptions.COMPLETED &&
        cleanedTask.activity_type === activityTypes.CREATE_AND_ASSIGN_TO_USER
      ) {
        cleanedTask.activity_type = activityTypes.CREATE_AND_COMPLETE;
      }
      let id = "";
      const data = { ...cleanedTask, images };
      const createdTask = await createTaskMutation.mutateAsync({ data });

      id = createdTask?.task_id;

      const { task_type, state } = cleanedTask;
      const assigned_status =
        taskStateOptions.ASSIGNED === state
          ? "Assigned to user"
          : "Assigned to team";

      if (
        task_type === taskTypeOptions.ADDON &&
        state === taskStateOptions.COMPLETED
      ) {
        trackerHelper.trackCreateTask({
          ...cleanedTask,
          task_id: id,
          assigned_status,
        });
        trackerHelper.trackRefillAddonTask({
          ...cleanedTask,
          task_id: id,
        });
        trackerHelper.trackCompleteTask(
          { ...cleanedTask, task_id: id },
          { create_and_complete: true }
        );
      } else {
        trackerHelper.trackCreateTask({
          ...cleanedTask,
          task_id: id,
          assigned_status,
        });
      }

      const isMfaqTask = taskData.task_type === taskTypeOptions.MFAQ_FOLLOW_UP;
      // We set finished_at field with valid date while creating a task due to Elasticsearch requiring the field while querying. Refer to aggregateTasksByInterval in es.helpers.
      if (isMfaqTask) {
        await createQuestionTask(taskData.question_id, {
          task_id: id,
          description: taskData.description,
          area: taskData.question_area,
          state: taskData.state,
        });
      }

      return id;
    },
    [createTaskMutation]
  );

  return { call: createTask };
}
