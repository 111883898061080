import React from "react";
import styled from "styled-components";
import { FixaSwitch } from "@coworker/apprestructured/src/shared/wrappers/FixaSwitch/FixaSwitch";

const Container = styled('div')`
  background: white;
  padding: ${(props) => props.overridePadding || "0 29px"};
  min-height: ${(props) =>
    props.deactivated || props.mfaqFilter ? "52px" : "90px"};
  border-bottom: ${(props) =>
    props.deactivated || props.mfaqFilter
      ? "none"
      : "1px solid var(--grey150)"};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) =>
    props.deactivated ? "var(--grey600)" : "var(--grey900)"};
  font-weight: ${(props) =>
    props.deactivated || props.mfaqFilter ? "normal" : "bold"};
`;

const CompactTitle = styled('h2')`
  font-size: 14px;
  line-height: 1.5;
  margin-left: ${(props) => props.titleMargin || "21.5px"};
  padding-bottom: 2px;
  flex-grow: 1;
  font-weight: ${(props) => (props.mfaqFilter ? "normal" : "bold")};
`;

const BooleanFlag = ({
  title,
  onToggle,
  checked = false,
  icon,
  testId,
  overridePadding,
  titleMargin,
  mfaqFilter = false,
}) => {
  return (
    <Container
      data-testid={"booleanFlag"}
      onClick={onToggle}
      overridePadding={overridePadding}
      mfaqFilter={mfaqFilter}
    >
      <div>{icon}</div>
      <CompactTitle titleMargin={titleMargin} mfaqFilter={mfaqFilter}>
        {title}
      </CompactTitle>
      <FixaSwitch data-testid={testId} checked={checked} id="" subtle />
    </Container>
  );
};

export default BooleanFlag;
