import React, { Fragment } from "react";
import styles from "./team-selection-header.module.css";
import { SimpleHeader } from "../../../../layout/components/SimpleHeader/SimpleHeader";
import { Trans, useTranslation } from "@coworker/locales";
import { useMyStore } from "@coworker/app/src/core/auth/useLoggedInUser";
import { SimpleLoadingBall } from "../../../../shared/simple/SimpleLoading/SimpleLoading";

export const TeamSelectionHeader = () => {
  const store = useMyStore();
  const { t } = useTranslation();

  return (
    <Fragment>
        <SimpleLoadingBall text={t("loadingEllipsisString")} size="medium" />
        <div>
          <SimpleHeader />
          <div className={styles["header-wrapper"]}>
            <div className={styles["header-container-text"]}>
              <p className={styles["current-store"]}>
                <Trans>storeString</Trans>
              </p>
              <h2
                className={styles["store-name"]}
              >{`${store?.name} - ${store?.storeId}`}</h2>
              <p className={styles["contact-store-super-user-message"]}>
                <Trans>contactStoreSuperUserString</Trans>
              </p>
            </div>
            <h4>Fixa</h4>
          </div>
        </div>
    </Fragment>
  );
};
