import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Offline } from "../assets/svg/offline.svg";
import { Trans } from "@coworker/locales";

const OfflineContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  flex: 1;
`;
const ImageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const TextContainer = styled('div')`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const OfflineImage = styled(Offline)`
  padding-top: ${(props) => (props.search ? "60px" : "0")};
  width: 38px;
  height: 38px;
  opacity: 0.8;
`;
const GoOnline = styled(Link)`
  color: #666;
  text-decoration: none;
  font-size: 12px;
`;
const Text = styled('div')`
  color: var(--grey500);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  letter-spacing: -0.19px;
`;

const OfflineScreen = ({
  task,
  search,
  offlineString = <Trans>offlineString</Trans>,
  backString = <Trans>backString</Trans>,
  viewString = <Trans>viewString</Trans>,
  continueString = <Trans>continueString</Trans>,
}) => (
  <OfflineContainer>
    <ImageContainer>
      <OfflineImage search={search} />
      <TextContainer>
        <Text>{offlineString}</Text>
      </TextContainer>
    </ImageContainer>
    <GoOnline to="/task/new" search={search}>
      {backString} {task ? viewString : continueString}.
    </GoOnline>
  </OfflineContainer>
);

export default OfflineScreen;
