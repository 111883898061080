// Flags and their explanations
const featureFlags = {
  // In use by stores/users/countries.
  BARCODE_DEBUG: "Advanced logging for barcodes",
  BETTER_PICKUP: "Build better default pickup fields for 308 Crny Most",
  DISABLE_NEARBY: "Test disabling Nearby for US store 165.",
  DISABLE_MFS_SEARCH:
    "Do not use MFS Search on the Search screen, for store 455 (and later globally).",

  // For checking translations.
  BLABLA_LANGUAGE:
    "Replaces translations with 'blabla'. Helps finding what's not translated.",
  ALL_LOCALES:
    "Disables store-related language/country filtering when choosing language.",

  MAINTENANCE:
    "Shows a message and hides Fixa (not Admin). Hopefully won't be needed again. Use if Fixa is broken / needs to wait for DB restore.",

  CALL_TASKS_SERVICE_INSIGHTS: "Call new Tasks Service for Insights",
  GERMANY_ROLLOUT:
    "Germany rollout, hides Test buy, PQR and Shopping tool and poorly assembled type in product issue",
  SPR_TESTBUY: "Allows SPR testbuy to be created in the custom testbuy flow",
  REQUIRE_PROOF_OF_COMPLETION:
    "Require 'Proof of Completion' on Room Settings Tasks before being able to Complete the Task. Only use for those who requests it.",
  ENABLE_DISCOVER_NOW: "Show discover now on homepage",
  ENABLE_MEDIA_TYPES: "Use the ability to create a media with new UI",
  ENABLE_NEW_ACTIVITY_FILTERS: "Use new filters in activity",
  ENABLE_MEDIA_LANDING_FILTERS: "Use new filters media landing page",
};

// Export object with the names of flags. Used by Fixa + Admin.
export const FLAGS = Object.keys(featureFlags).reduce(
  (all, key) => ({ ...all, [key]: key }),
  {}
);

export const flagsWithDescriptions = featureFlags;
