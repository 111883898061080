import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "@coworker/app/src/constants/clientTime";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import useFormatter from "@coworker/app/src/hooks/useFormatter";
import { useTeamId } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useAssignableTeams } from "@coworker/app/src/core/hooks/useAssignableTeams";
import React from "react";
import { AssignmentSuggestionResult } from "../types/assignmentSuggestionResultType";
import { compareDate } from "../../shared/helpers/date.helper";

async function fetchRecentAssignmentSuggestions(itemNo: string) {
  const url = `${TASKS_SERVICE_URL}/v1/tasks/assignee_suggestion?short_id=${itemNo}`;
  const { result } = await fetchAPI("GET", url);
  return result as AssignmentSuggestionResult;
}

export const useRecentAssignmentSuggestions = (itemNo: string) => {
  return useQuery({
    enabled: !!itemNo,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ["assignment", "suggestions", itemNo],
    queryFn: async () => await fetchRecentAssignmentSuggestions(itemNo),
    staleTime: 2 * INTERVAL.MINUTE,
  });
};

export const useParsedSuggestions = (itemNo: string) => {
  const { data: suggestions, isLoading } =
    useRecentAssignmentSuggestions(itemNo);

  const { formatDateShort } = useFormatter();
  const currentTeamId = useTeamId();
  const teams = useAssignableTeams();

  return React.useMemo(() => {
    const found: { [key: string]: [string, [string, string, Date]] } = {};
    if (!isLoading && suggestions) {
      for (const which of [
        "team_assignments_picking",
        "team_assignments_refilling",
      ]) {
        const map =
          (suggestions as AssignmentSuggestionResult)?.[
            which as keyof AssignmentSuggestionResult
          ] || {};
        for (const [teamId, timestamp] of Object.entries(map)) {
          if (teamId === currentTeamId) continue; // No need to suggest user's own team, for now.
          const name = teams.find((team) => team.id === teamId)?.name;
          if (!name) continue; // Skip teams we cannot find (as they're surely disabled or removed)
          found[teamId] = [
            teamId,
            [name, formatDateShort(timestamp), timestamp],
          ];
        }
      }
    }
    return Object.values(found).sort((a, b) =>
      compareDate(new Date(a[1][2]), new Date(b[1][2]))
    );
  }, [suggestions, isLoading, teams, formatDateShort, currentTeamId]);
};
