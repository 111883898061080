import { FilterValue, GroupFilter } from "../../types";
import { AccordionGroup } from "../AccordionGroup";

interface InputGroupOptionProps {
  filter: GroupFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  openFilters: string[];
  setFilterValues: (filterValues: FilterValue[]) => void;
  onOpenChange: (isOpen: boolean) => void;
}

export const InputGroupOption = ({
  filter,
  filterValues,
  defaultValues,
  openFilters,
  setFilterValues,
  onOpenChange,
}: InputGroupOptionProps) => {
  return (
    <AccordionGroup
      filter={filter}
      filterValues={filterValues}
      defaultValues={defaultValues}
      setFilterValues={setFilterValues}
      open={!!openFilters.find((filterId) => filterId === filter.id)}
      onOpenChange={onOpenChange}
    />
  );
};
