
import { ReactComponent as ArrowForward } from "../../../../assets/svg/arrow-small-forward.svg";

interface RightArrowProps {
  onClick?: () => void;
}

export function RightArrow({ onClick }: RightArrowProps) {
  return <ArrowForward onClick={onClick} />;
}
