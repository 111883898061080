import {useState,useEffect,SetStateAction,Dispatch} from "react";
import styled from "styled-components";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import FixaInlineMessage from "@coworker/apprestructured/src/shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import { Trans, useTranslation } from "@coworker/locales";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { ShoppingToolType, MAX_SHOWROOM_TOOLS } from "../constants";
import { useCreateShoppingTool } from "../Hooks/useCreateShoppingTool";
import { useDeleteShoppingTool } from "../Hooks/useDeleteShoppingTool";
import { FixaQuantityStepper } from "@coworker/apprestructured/src/shared/wrappers/FixaQuantityStepper/FixaQuantityStepper";
import styles from "./ShowroomQuantityCard.module.css";

interface ShowroomQuantityCardProps {
  type: ShoppingToolType;
  round: string;
  division: string;
  startValue: number;
  currentTotalValue: number;
  disableList: ShoppingToolType[];
  setCurrentTotalValue: Dispatch<SetStateAction<number>>;
}

export const CardWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 8px solid var(--grey200);
  height: 60px;
  padding-left: 15px;
  &:first-child {
    padding-top: 10px;
  }
  &:last-child {
    padding-bottom: 10px;
  }
`;

export const SubTitle = styled('span')`
  font-size: 14px;
  font-weight: 400;
`;

export const InlineMessageWrapper = styled('div')`
  padding-left: 15px;
  padding-bottom: 15px;
  border-left: 8px solid var(--grey200);
`;

export const ShowroomQuantityCard = ({
  type,
  round,
  division,
  startValue,
  currentTotalValue,
  setCurrentTotalValue,
  disableList,
}: ShowroomQuantityCardProps) => {
  let [currentToolValue, setCurrentToolValue] = useState<number>(0);
  const createShoppingToolMutation = useCreateShoppingTool();
  const deleteShoppingToolMutation = useDeleteShoppingTool();
  const storeId = useStoreId();
  const isDisabled = disableList.some((disable) => disable.name === type.name);
  useEffect(() => {
    setCurrentToolValue(startValue);
  }, [setCurrentToolValue, startValue]);

  const { t } = useTranslation();

  function onIncreaseToolValue() {
    currentToolValue++;
    setCurrentToolValue(currentToolValue);
    currentTotalValue++;
    setCurrentTotalValue(currentTotalValue);
  }

  function onDecreaseToolValue() {
    currentToolValue--;
    setCurrentToolValue(currentToolValue);
    currentTotalValue--;
    setCurrentTotalValue(currentTotalValue);
  }

  const isMaxAmountReached = currentToolValue === MAX_SHOWROOM_TOOLS;

  return (
    <>
      <CardWrapper key={type.label}>
        <FixaQuantityStepper
          disabled={isDisabled}
          value={currentToolValue}
          small
          minVal={0}
          maxVal={MAX_SHOWROOM_TOOLS}
          ariaDescribedById="unique-dom-id"
          ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
          onIncrease={() => {
            if (!isMaxAmountReached) {
              createShoppingToolMutation.mutateAsync({
                roundName: round,
                division: division,
                storeId: storeId,
                initialShoppingTools: { [type.name ?? ""]: 1 },
              });
              onIncreaseToolValue();
            }
          }}
          onDecrease={() => {
            deleteShoppingToolMutation.mutateAsync({
              roundName: round,
              division: division,
              storeId: storeId,
              initialShoppingTools: { [type.name ?? ""]: 1 },
            });
            onDecreaseToolValue();
          }}
        />
        <FixaSSRIcon paths={type.icon!} className={styles["icon"] as string} />
        <SubTitle style={{ margin: !type.icon ? "0px 10px" : 0 }}>
          <Trans>{type.label}</Trans>
        </SubTitle>
      </CardWrapper>
      {isMaxAmountReached && (
        <InlineMessageWrapper>
          <FixaInlineMessage
            variant="cautionary"
            subtle
            body={t("maxToolAmountReachedString")}
          />
        </InlineMessageWrapper>
      )}
    </>
  );
};
