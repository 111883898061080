import {Fragment} from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { taskStatusFilterTypes } from "@coworker/types/lib/taskStatusFilterTypes";
import { FilterItem } from "./FilterItem";

const Title = styled('div')`
  margin: 10px 29px;
  font-weight: bold;
  font-size: 18px;
`;

const filterItems = [
  {
    type: taskStatusFilterTypes.ALL_COMPLETED_TASKS,
    translation: "allCompletedTasksString",
  },
  {
    type: taskStatusFilterTypes.COMPLETED_WITH_CHANGES,
    translation: "completedWithChangesString",
  },
  {
    type: taskStatusFilterTypes.COMPLETED_WITHOUT_CHANGES,
    translation: "completd3String",
  },
];

interface TaskStatusFilterProps {
  filterSetter: (filterType: string) => void;
  selectedFilter: string;
}

export function TaskStatusFilter({
  filterSetter,
  selectedFilter,
}: TaskStatusFilterProps) {
  return (
    <Fragment>
      <Title>
        <Trans>taskStatusString</Trans>
      </Title>
      {filterItems.map((item) => (
        <FilterItem
          key={item.type}
          content={<Trans>{item.translation}</Trans>}
          filterType={item.type}
          filterSetter={filterSetter}
          checked={selectedFilter?.includes(item.type)}
        />
      ))}
    </Fragment>
  );
}
