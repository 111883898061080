import styled from "styled-components";
import { Button } from "@coworker/components";

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #ffffff;
  background: var(--white);
  padding-bottom: 70px;
`;
export const Footer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
`;
export const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 19px;
`;

export const OptionsGroup = styled('div')`
  ${(props) => props.disabled && "color: var(--grey700); pointer-events: none;"}
  & * {
    ${(props) => props.disabled && "opacity: 0.9; pointer-events: none;"}
  }
`;

export const Title = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 18px;
  padding-left: 28px;
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const Option = styled('div')`
  height: 50px;
  ${(props) => props.disabled && "color: var(--grey700); pointer-events: none;"}
`;

export const OptionContent = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  pointer-events: none;
  justify-content: space-between;
  margin: 0 29px;
  width: 90vw;
`;
export const Text = styled('p')`
  font-size: 14px;
`;

export const Radio = styled('div')`
  width: 10vw;
`;
