import { getMediaById, updateMedia } from "../services/medias.service";
import { RoomSettingsMedia } from "../types/media";

export async function updateMainAndAlternativeProducts(
  mediaId: string,
  articleIds: string[]
) {
  const media = (await getMediaById(mediaId)) as RoomSettingsMedia;

  if (media) {
    const updatedMedia = { ...media } as RoomSettingsMedia;
    for (const articleId of articleIds) {
      if (articleId === media.mainProductId1) {
        updatedMedia.mainProductId1 = "";
        updatedMedia.mainProductName1 = "";
      }
      if (articleId === media.mainProductId2) {
        updatedMedia.mainProductId2 = "";
        updatedMedia.mainProductName2 = "";
      }
      if (articleId === media.alternativeProductId1) {
        updatedMedia.alternativeProductId1 = "";
        updatedMedia.alternativeProductName1 = "";
      }
      if (articleId === media.alternativeProductId2) {
        updatedMedia.alternativeProductId2 = "";
        updatedMedia.alternativeProductName2 = "";
      }
    }

    const mediaMainAndBackupArticles = [
      media.mainProductId1,
      media.mainProductId2,
      media.alternativeProductId1,
      media.alternativeProductId2,
    ];
    const hasChanged = mediaMainAndBackupArticles.some(
      (articleId) => articleId && articleIds.includes(articleId)
    );
    if (hasChanged) await updateMedia(media.id, updatedMedia);
  }
}
