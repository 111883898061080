import {Dispatch,SetStateAction} from "react";
import styled from "styled-components";
import { DIVISION_HASH, typesOfShoppingToolsList } from "../constants";
import { findSettingsObject, sortAndDisableSettingsList } from "../helper";
import { useFetchShoppingtoolsSettings } from "../Hooks/useFetchShoppingtoolsSettings";
import { SettingsDefault, SettingsResponse } from "../Types";
import { SettingsKeyType } from "../Components/SettingsListView";
import { ShowroomQuantityCard } from "./ShowroomQuantityCard";
import { QuantitySteppersSkeleton } from "../Components/Skeletons/QuantitySteppersSkeleton";
import { useFetchCountOnDivision } from "../Hooks/useFetchCountOnDivision";

interface ShowroomQuantityStepperProps {
  round: string;
  currentTotalValue: number;
  setCurrentTotalValue: Dispatch<SetStateAction<number>>;
}

export const CardContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const ShowroomQuantitySteppers = ({
  round,
  currentTotalValue,
  setCurrentTotalValue,
}: ShowroomQuantityStepperProps) => {
  const { data: settingsList, isFetching } = useFetchShoppingtoolsSettings();

  const { data: showroomTools } = useFetchCountOnDivision(
    DIVISION_HASH.SHOWROOM?.division ?? "",
    round
  );

  if (isFetching) {
    return <QuantitySteppersSkeleton />;
  }

  if (!settingsList) return null;

  const activeSettings: SettingsDefault | SettingsResponse = findSettingsObject(
    settingsList,
    DIVISION_HASH.SHOWROOM?.division ?? ""
  )!;

  const foundTools = Object.keys(activeSettings ?? []).filter(
    (key) => (activeSettings as SettingsKeyType)[key] === true
  );
  const sortedSettingsList = sortAndDisableSettingsList(foundTools);
  const removedSettingsTools = typesOfShoppingToolsList().filter(
    (setting) => !foundTools.includes(setting.name)
  );
  return (
    <CardContainer>
      {sortedSettingsList.map((type) => {
        return (
          <ShowroomQuantityCard
            disableList={removedSettingsTools}
            key={type.name}
            type={type}
            startValue={showroomTools?.initialShoppingTools[type.name] ?? 0}
            round={round}
            division={DIVISION_HASH.SHOWROOM?.division ?? ""}
            currentTotalValue={currentTotalValue}
            setCurrentTotalValue={setCurrentTotalValue}
          />
        );
      })}
    </CardContainer>
  );
};
