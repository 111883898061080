import React from "react";
import InputField from "@ingka/input-field";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";

interface Props {
  id: string;
  type: "text" | "password" | "number" | "email" | "hidden";
  label?: string | React.ReactNode;
  maxLength?: number;
  disabled?: boolean;
  inputMode?: React.InputHTMLAttributes<HTMLInputElement>["inputMode"];
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  iconPosition?: "leading" | "trailing";
  ssrIcon?: (prefix?: string) => React.SVGProps<SVGElement>[];
  readOnly?: boolean;
  suffixLabel?: string | React.ReactNode;
  iconOnClick?: () => void;
}

export const FixaInputField = (props: Props) => {
  return <InputField {...props} />;
};
