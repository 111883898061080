import React from "react";
import { Media, RangeDescription } from "../../types/media";
import { FixaTextArea } from "@coworker/apprestructured/src/shared/wrappers/FixaTextArea/FixaTextArea";
import { useTranslation } from "@coworker/locales";

interface MainMessageInputProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const RangeDescriptionInput = ({
  media,
  onSave,
}: MainMessageInputProps) => {
  const { t } = useTranslation();

  return (
    <FixaTextArea
      id={"range_description"}
      label={t("rangeDescriptionString")}
      style={{ height: "100px" }}
      value={(media as RangeDescription).rangeDescription ?? ""}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newRangeDescription = event.target.value;
        const newMedia = { ...media };
        (newMedia as RangeDescription).rangeDescription = newRangeDescription;
        onSave(newMedia);
      }}
    />
  );
};
