import { useQuery } from "@tanstack/react-query";
import { getAlerts } from "../services/medias.service";
import { QueryKeys } from "./queryKeys";

export function useAlertsByStore(storeId: string | undefined) {
  return useQuery({
    queryKey: [QueryKeys.Alerts, storeId],
    queryFn: () => getAlerts(storeId || ""),
    enabled: !!storeId,
  });
}
