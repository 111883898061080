import { TaskListingTypes } from "../constants";
import { OngoingTaskLinksResponse } from "../types/taskLink";
import { useTaskLinksByMedia } from "./useTaskLinksByMedia";
import { useTaskLinks } from "./useTasksByTaskLinks";

export function useTasksByMedia(
  mediaId: string | undefined
): OngoingTaskLinksResponse {
  const { data: taskLinks } = useTaskLinksByMedia(mediaId || "");
  const tasksResponse = useTaskLinks(taskLinks || [], TaskListingTypes.Ongoing);
  return tasksResponse;
}
