import { useMemo,useState, useEffect } from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { SettingsCard } from "./SettingsCard";
import { useFetchShoppingtoolsSettings } from "../Hooks/useFetchShoppingtoolsSettings";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { findSettingsObject } from "../helper";
import { DIVISION_HASH } from "../constants";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useCreateOrUpdateShoppingtoolsSettings } from "../Hooks/useCreateOrUpdateShoppingtoolsSettings";
import { SettingsDefault, SettingsResponse } from "../Types";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

export const ShoppingToolsSettings = () => {
  const [settingsList, setSettingsList] = useState<
    SettingsDefault[] | SettingsResponse[]
  >([]);
  const { data: settings, isFetching } = useFetchShoppingtoolsSettings();
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();
  const storeId = useStoreId();

  const createOrUpdateSettingsMutation =
    useCreateOrUpdateShoppingtoolsSettings();

  useEffect(() => {
    setSettingsList(settings ?? []);
  }, [settings]);

  const linkbarItems = useMemo(() => {
    return [
      {
        label: t("shoppingToolsHeaderString"),
        to: "/shoppingtools",
      },
    ];
  }, [t]);

  if (!settingsList) return null;

  const createOrUpdateSettings = async () => {
    const updatedSettingsList: SettingsResponse[] | SettingsDefault[] =
      settingsList?.map((setting) => {
        return {
          ...setting,
          store_id: storeId,
        };
      });

    await createOrUpdateSettingsMutation.mutateAsync(updatedSettingsList);
    push("/shoppingtools");
  };

  return (
    <FullScreenPopup
      actionBarContent={
        <FixaButton
          type="primary"
          text={<Trans>applyString</Trans>}
          disabled={!settingsList.length}
          onClick={createOrUpdateSettings}
        />
      }
      noPadding
      linkbar={
        <Linkbar
          items={linkbarItems}
          currentLabel={t("settingsString")}
          currentAsH1
        />
      }
    >
      <SettingsCard
        title={"availableAtShowroomString"}
        id={DIVISION_HASH.SHOWROOM?.division ?? ""}
        divisionSettings={
          findSettingsObject(
            settingsList,
            DIVISION_HASH.SHOWROOM?.division ?? ""
          )!
        }
        setSettingsList={setSettingsList}
        settingsList={settingsList}
        loading={isFetching}
      />
      <SettingsCard
        title={"availableAtMarkethallOTWString"}
        id={DIVISION_HASH.MARKETHALL?.division ?? ""}
        divisionSettings={
          findSettingsObject(
            settingsList,
            DIVISION_HASH.MARKETHALL?.division ?? ""
          )!
        }
        setSettingsList={setSettingsList}
        settingsList={settingsList}
        loading={isFetching}
      />
      <SettingsCard
        title={"availableAtCashlineString"}
        id={DIVISION_HASH.CASHLINE?.division ?? ""}
        divisionSettings={
          findSettingsObject(
            settingsList,
            DIVISION_HASH.CASHLINE?.division ?? ""
          )!
        }
        setSettingsList={setSettingsList}
        settingsList={settingsList}
        loading={isFetching}
      />
    </FullScreenPopup>
  );
};
