import styled from "styled-components";

const Indicator = styled('div')`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;

  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export default Indicator;
