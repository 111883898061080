import { useAlertSelectFilter } from "@coworker/apprestructured/src/shared/filters/filters";
import {
  ApplyFilter,
  FilterValue,
  SelectFilter,
} from "@coworker/apprestructured/src/shared/filters/types";
import { Article, Combined } from "../types/article";

function useAlertSelectArticleFilter() {
  const filter: ApplyFilter<SelectFilter, Article> = {
    filter: useAlertSelectFilter(),
    filterCompare: (item: Combined<Article>, { value }: FilterValue) => {
      switch (value) {
        case "eds":
          return item.eds && item.eds.validToDate
            ? item.eds.validToDate.getTime() >= new Date().getTime()
              ? true
              : false
            : false;
        case "pastEds":
          return item.eds && item.eds.validToDate
            ? item.eds.validToDate.getTime() < new Date().getTime()
              ? true
              : false
            : false;
        case "salesStop":
          return item.salesStop ? true : false;
        default:
          return true;
      }
    },
  };

  return filter;
}

export { useAlertSelectArticleFilter };
