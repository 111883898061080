import React, { useCallback, useEffect, useState } from "react";

import { AppBar, AppBarProps } from "../AppBar/AppBar";
import {
  useScrollDirection,
  SCROLL_UP,
} from "@coworker/app/src/hooks/useScrollDirection";

import styles from "./full-screen-popup.module.css";
import { ActionBar } from "../ActionBar/ActionBar";
import { useTranslation } from "@coworker/locales";
import styled from "styled-components";

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 10px;
  justify-content: center;
  justify-items: center;
  display: flex;
`;

interface FullScreenPopupProps {
  children: React.ReactNode;
  appBarConfig?: AppBarProps;
  noPadding?: boolean;
  actionBarContent?: React.ReactNode;
  isActionBarHiddenOnScroll?: boolean;
  noAppBar?: boolean;
  fullHeightContent?: boolean;
  linkbar?: React.ReactNode;
  fieldsAreEmpty?: boolean;
}

const FullScreenPopup = ({
  children,
  appBarConfig,
  noPadding,
  actionBarContent,
  isActionBarHiddenOnScroll = true,
  noAppBar,
  fullHeightContent = false,
  linkbar,
  fieldsAreEmpty,
}: FullScreenPopupProps) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollTarget] = useScrollDirection();
  const [actionBarHeight, setActionBarHeight] = useState(0);

  useEffect(() => {
    if (!scrollContainerRef.current) return undefined;
    setScrollTarget(scrollContainerRef.current);
  }, [scrollContainerRef, setScrollTarget]);

  const popupClassNames = [styles["full-screen-popup"]];
  const { t } = useTranslation();
  if (noPadding && !fullHeightContent) {
    popupClassNames.push(styles["no-padding"]);
  }
  const onHeightActionBar = useCallback(
    (height: number) => {
      setActionBarHeight(height);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <div className={popupClassNames.join(" ")} ref={scrollContainerRef}>
      {linkbar}
      {!noAppBar && appBarConfig && <AppBar {...appBarConfig} />}
      <div
        className={styles["content"]}
        style={
          fullHeightContent ? { padding: `0 0 ${actionBarHeight}px 0` } : {}
        }
      >
        {children}
      </div>
      {actionBarContent && (
        <ActionBar
          hidden={isActionBarHiddenOnScroll && scrollDirection === SCROLL_UP}
          fullHeightContent={fullHeightContent}
          onHeight={onHeightActionBar}
        >
          {fieldsAreEmpty && (
            <ErrorMessage>{t("fillInMandatoryFieldsString")}</ErrorMessage>
          )}
          {actionBarContent}
        </ActionBar>
      )}
    </div>
  );
};

export default FullScreenPopup;
