import React from "react";
import styled from "styled-components";
import BasicInfo from "./basicinfo";
import { FixaOverflowCarousel } from "@coworker/apprestructured/src/shared/wrappers/FixaCarousel/FixaCarousel";
import { usePIPHome } from "@coworker/apprestructured/src/shared/hooks/item/usePIPHome";
import FixaImage from "@coworker/apprestructured/src/shared/wrappers/FixaImage/FixaImage";
import { useTaskImagesDetails } from "@coworker/apprestructured/src/tasks/hooks/useTaskImagesDetails";
import { SimpleLoadingBall } from "@coworker/apprestructured/src/shared/simple/SimpleLoading/SimpleLoading";
import { useTranslation } from "@coworker/locales";
import { useGetImagesSignedUrls } from "@coworker/apprestructured/src/tasks/hooks/useGetImageSignedUrl";

export const CarouselImage = styled(FixaImage)`
  height: 250px;
  object-fit: contain;
`;

const ImageWrapper = styled("div")`
  padding: 16px 16px 0px 16px;
`;

export default function TaskHeaderWithImages({ task }) {
  const { data: pipHomeData, isLoading: isLoadingProductImages } = usePIPHome(
    task.product_article_id
  );
  const { t } = useTranslation();
  const { data: taskImagesDetails, isLoading: isLoadingTaskImageDetails } =
    useTaskImagesDetails(task.id);
  const showUploadedImages = !!taskImagesDetails?.length;

  const signedUrlsResult = useGetImagesSignedUrls(taskImagesDetails);

  const isLoadingUploadedTaskImageUrls = signedUrlsResult?.some(
    (query) => query.isLoading
  );

  const uploadedTaskImageUrls = signedUrlsResult?.map(
    (query) => query.data?.[0]
  );

  const { images, bigImage } = pipHomeData?.productImages || {};
  const chosenProductImages = React.useMemo(
    () => (images?.length ? images : !!bigImage ? [bigImage] : []),
    [images, bigImage]
  );

  return (
    <>
      {(isLoadingTaskImageDetails || isLoadingUploadedTaskImageUrls) && (
        <SimpleLoadingBall text={t("loadingEllipsisString")} size="medium" />
      )}
      {showUploadedImages &&
        !isLoadingTaskImageDetails &&
        uploadedTaskImageUrls.length > 0 && (
          <ImageWrapper>
            <FixaOverflowCarousel id="own-images">
              {uploadedTaskImageUrls.map((image, index) => (
                <CarouselImage key={`image${index}`} src={image ?? ""} />
              ))}
            </FixaOverflowCarousel>
          </ImageWrapper>
        )}
      {!showUploadedImages &&
        !isLoadingProductImages &&
        chosenProductImages.length > 0 && (
          <ImageWrapper>
            <FixaOverflowCarousel id="product-images">
              {chosenProductImages.map((image, index) => (
                <CarouselImage key={`image${index}`} src={image ?? ""} />
              ))}
            </FixaOverflowCarousel>
          </ImageWrapper>
        )}

      <BasicInfo task={task} />
    </>
  );
}
