import React from "react";
import styled, { AnyStyledComponent } from "styled-components";
import { ScanPopover } from "./ScanPopover";
import { useTasksForProduct } from "../hooks/useTasksForProduct";
import { parseProduct } from "../services/products.service";
import trackerHelper from "../helpers/tracker";
import LatestSalesDate from "./LatestSalesDate";
import { revealFromBelow } from "../helpers/animationHelper";
import { useProductQualityTeamId } from "../hooks/useProductQualityTeamId";
import { useFastBasicProduct } from "../hooks/useBasicProductsMap";
import { reportMessageToSentry } from "../hooks/useConfigureSentry";
import { RDT_HIDDEN_INPUT_ID } from "@coworker/reusable";
import { Item } from "@coworker/apprestructured/src/shared/types/item";

const PopoverBackdrop = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black);
  opacity: 0.7;
  z-index: var(--z-popover);
`;

const noop = () => null;

interface ScannedItem {
  code: string;
  supplierNumber: string;
  product: string;
  store_id: string;
  displayRelatedTasks: boolean;
  fromSearch: boolean;
  withContinue: boolean;
  withActions: boolean;
  start: Date;
  onSubmit: (data: {
    product: string;
    type: string;
    supplierNumber: string;
  }) => void;
  onRelated: () => void;
  onItemClick: (fullId?: string) => void;
  close: () => void;
  onSearch?: () => void;
  onAction?: (url: string, product: { product: string; type: string }) => void;
  onClose: () => void;
}

type PopoverContext = [
  ScannedItem | undefined,
  React.Dispatch<React.SetStateAction<ScannedItem | undefined>>,
];

const ScanPopoverContext = React.createContext<PopoverContext>([
  undefined,
  () => {
    // This is intentionally empty.
  },
]);

function useScanPopover() {
  const [popover, setPopover] = React.useContext(ScanPopoverContext);
  return {
    scanPopoverActive: !!popover,
    setScannedItem: (item: ScannedItem | undefined) => {
      if (item) {
        const elapsed = Date.now() - item.start.valueOf();
        if (item.fromSearch) {
          trackerHelper.trackSearch(elapsed);
        } else {
          trackerHelper.trackCamera(elapsed, item?.code);
        }
      }
      setPopover(item);
    },
  };
}

interface ScanPopoverProviderProps {
  children: React.ReactNode;
}

const ScanPopoverProvider: React.FC<ScanPopoverProviderProps> = ({
  children,
}) => {
  const state = React.useState<ScannedItem>();
  return (
    <ScanPopoverContext.Provider value={state}>
      {children}
    </ScanPopoverContext.Provider>
  );
};

const StyledScanResultWithRef = styled(
  React.forwardRef(ScanResult) as AnyStyledComponent
)`
  z-index: var(--z-popover);
`;
const PosedResult = revealFromBelow(StyledScanResultWithRef);

function ScanResultIffy() {
  const [popover, setPopover] = React.useContext(ScanPopoverContext);
  const onClose = async () => {
    popover?.close?.();
    document.getElementById(RDT_HIDDEN_INPUT_ID)?.focus(); // Used on new RDT to focus the hidden input used for scanning
    setPopover(undefined);
  };
  if (!popover) return null;
  return (
    <>
      <PopoverBackdrop
        data-testid="backdrop"
        key="backdrop"
        onClick={() => {
          trackerHelper.trackDismissModal(false);
          onClose();
        }}
      />
      <PosedResult key="result" {...popover} onClose={onClose} />
    </>
  );
}

/**
 * This is the "Scan half-sheet"
 * It is shown in the scan/search screens if you:
 *  - scan a product
 *  - or search and tap on a result
 *
 * TODO: This component typically renders twice for each scan, causing messy Analytics events for ce:scan_barcode and ce:barcode:...
 */
function ScanResult(
  {
    code,
    supplierNumber,
    product,
    onSubmit,
    withContinue = true,
    withActions = false,
    onAction = noop,
    onRelated,
    onItemClick,
    onClose,
  }: ScannedItem,
  ref: React.Ref<HTMLDivElement>
) {
  const [short_id, cachedType] = parseProduct(code);
  const relatedTasks = useTasksForProduct(short_id);
  const { loading, basicItem, error } = useFastBasicProduct(code);
  const item = basicItem as Item;

  const type = cachedType || item?.type;
  const loadingState = product ? false : loading;

  const onContinue = React.useCallback(() => {
    if (!type || !short_id) return;
    onSubmit({
      product: short_id,
      type,
      supplierNumber,
    });
  }, [type, short_id, onSubmit, supplierNumber]);

  const onClickHandler = React.useCallback(() => {
    type && short_id && onItemClick(type + short_id);
  }, [type, short_id, onItemClick]);

  React.useEffect(() => {
    if (error && type && short_id) {
      reportMessageToSentry("timeout while fetching product", {
        type,
        short_id,
      });
    }
  }, [error, short_id, type]);

  return (
    <ScanPopover
      ref={ref}
      basicItem={item}
      price={item?.salesPrice}
      secondaryPrice={undefined}
      relatedTasksCount={relatedTasks?.formattedCount}
      ofWhichClosed={relatedTasks?.ofWhichClosed}
      loading={loadingState}
      notFound={error || (!loading && !item)}
      hasActiveProductQualityTeam={!!useProductQualityTeamId()}
      withContinue={withContinue}
      withActions={withActions}
      onContinue={onContinue}
      onAction={onAction}
      onRelated={onRelated}
      onItemClick={onClickHandler}
      latestSalesDate={<LatestSalesDate articleId={code} />}
      onClose={onClose}
    />
  );
}

export default ScanResultIffy;
export { ScanPopoverProvider, useScanPopover };
