import { useTranslation } from "@coworker/locales";
import { SelectFilter, FilterType, InputType, FilterValue } from "../types";
import { getSelectedFilterValue } from "../utils/filter";

const SORT_ID = "sort";

export function useSortSelectFilter(filterValues: FilterValue[]): SelectFilter {
  const { t } = useTranslation();
  const defaultValue = {
    filterId: SORT_ID,
    value: "byName",
  };
  const selected = getSelectedFilterValue(SORT_ID, filterValues, [
    defaultValue,
  ]);
  const options = [
    { value: "byName", title: t("byNameString") },
    { value: "newestFirst", title: t("newestFirstString") },
    { value: "oldestFirst", title: t("oldestFirstString") },
  ];
  const selectedOption = options.find(
    (option) => option.value === selected.value
  )!;

  return {
    inputType: InputType.Select,
    filterType: FilterType.Sort,
    id: SORT_ID,
    title: t("sortByFilterTitleString", { option: selectedOption.title }),
    options,
    enabled: true,
    defaultValue,
  };
}
