import { TasksLocationsResult } from "../../types/tasksLocationsResultType";

const WAREHOUSE_AND_SELFSERVE_REGEX = /^(\d{4})(\d{2}).*/;
const isWarehouseOrSelfServeLocation = (location: string) => {
  const match = location.match(WAREHOUSE_AND_SELFSERVE_REGEX);
  const SLID = match && match[2] ? match[2] : "";
  const num = parseInt(SLID);
  if (num >= 20 && num % 10 === 0) {
    return "warehouse";
  } else if (num < 20) {
    return "selfServeFurnitureArea";
  } else return "";
};

type LocationCategoryKeys =
  | "showroom"
  | "markethall"
  | "markethallOpenTheWallet"
  | "showroomOpenTheWallet"
  | "departments"
  | "activityArea"
  | "selfServeFurnitureArea"
  | "cashline"
  | "asis"
  | "staircase"
  | "warehouse"
  | "other";

export const locationCategories: Array<keyof LocationCategory> = [
  "showroom",
  "markethall",
  "markethallOpenTheWallet",
  "showroomOpenTheWallet",
  "activityArea",
  "selfServeFurnitureArea",
  "cashline",
  "asis",
  "staircase",
  "warehouse",
  "other",
];

export const mfaqLocationCategories: Array<"departments" | "other"> = [
  "departments",
  "other",
];

export type MappedLocationsResult = {
  locationGridCode: string;
  locationDepartment: string;
  location: string;
};

export type LocationCategory = {
  [key in LocationCategoryKeys]: MappedLocationsResult[];
};

const joinLocationGridCodeAndDepartment = (
  locationGridCode: string,
  locationDepartment: string
) => {
  return !!locationDepartment
    ? [locationGridCode, locationDepartment].join(" - ")
    : locationGridCode;
};

export function getLocationsByHfb(locations?: TasksLocationsResult[]) {
  let locationsByHfb: LocationCategory = {
    showroom: [],
    markethall: [],
    departments: [],
    markethallOpenTheWallet: [],
    showroomOpenTheWallet: [],
    warehouse: [],
    selfServeFurnitureArea: [],
    cashline: [],
    asis: [],
    activityArea: [],
    staircase: [],
    other: [],
  };

  if (!!locations?.length) {
    Object.keys(locationsByHfb).forEach((key) => {
      locationsByHfb[key as LocationCategoryKeys] = locations
        .filter((location) => {
          switch (key) {
            case "showroom":
              return location?.location_grid_code.startsWith("S0");
            case "markethall":
              return location?.location_grid_code.startsWith("M1");
            case "departments":
              return location?.location_grid_code.startsWith("depts");
            case "warehouse":
            case "selfServeFurnitureArea":
              return (
                isWarehouseOrSelfServeLocation(location?.location_grid_code) ===
                key
              );
            case "markethallOpenTheWallet":
              return location?.location_grid_code.startsWith("MOW");
            case "showroomOpenTheWallet":
              return location?.location_grid_code.startsWith("SOW");
            case "cashline":
              return location?.location_grid_code.startsWith("OTCL");
            case "asis":
              return location?.location_grid_code.startsWith("AS");
            case "activityArea":
              return location?.location_grid_code.startsWith("AA");
            case "staircase":
              return location?.location_grid_code.startsWith("ST");
            case "other":
              const otherPrefixes = [
                "S0",
                "M1",
                "depts",
                "MOW",
                "SOW",
                "OTCL",
                "AS",
                "AA",
                "ST",
              ];
              return (
                !otherPrefixes.some((prefix) =>
                  location?.location_grid_code.startsWith(prefix)
                ) &&
                !["warehouse", "selfServeFurnitureArea"].includes(
                  isWarehouseOrSelfServeLocation(location?.location_grid_code)
                )
              );
            default:
              return false;
          }
        })
        .map((location) => {
          return {
            locationGridCode: location?.location_grid_code,
            locationDepartment: location?.location_department,
            location: joinLocationGridCodeAndDepartment(
              location?.location_grid_code,
              location?.location_department
            ),
          };
        });
    });
  }

  return locationsByHfb;
}

export const formatLocationName = function (
  t: (key?: string) => string,
  location: string
) {
  if (location.includes("depts")) {
    return `HFB ${location.split("=")[1]}`;
  } else if (location.includes("whole")) {
    return `${t("wholeStoreString")}`;
  } else if (location.includes("other")) {
    return location.split("=")[1] ?? "";
  } else {
    return t(location);
  }
};
