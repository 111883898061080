import styled, { css } from "styled-components";

const ORIENT_TRANSFORMS = {
  1: "",
  2: "rotateY(180deg)",
  3: "rotate(180deg)",
  4: "rotate(180deg) rotateY(180deg)",
  5: "rotate(270deg) rotateY(180deg)",
  6: "rotate(90deg)",
  7: "rotate(90deg) rotateY(180deg)",
  8: "rotate(270deg)",
};

function getCSS(
  fullscreenTransform,
  fullscreen,
  orientation,
  taskHeader = false
) {
  const transformText = ORIENT_TRANSFORMS[orientation];
  if (fullscreenTransform) {
    if (orientation > 4) {
      return css`
        transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%))
          ${transformText};
        width: 100vh;
        height: 100vw;
      `;
    } else {
      return css`
        transform: ${transformText};
      `;
    }
  } else if (fullscreen) {
    return css`
      width: 100vw;
      height: 100vh;
    `;
  } else if (taskHeader) {
    if (orientation > 4) {
      return css`
        transform: translateX(calc(50vw - 50%)) translateY(calc(130px - 50%))
          rotate(90deg);
        width: 260px;
        height: 100vw;
      `;
    } else {
      return css``;
    }
  } else {
    return css`
      transform: ${transformText};
    `;
  }
}

export const RotatedImage = styled('img')`
  height: 100%;
  width: 100%;
  object-fit: ${(props) => (props.fullscreen ? "contain" : "cover")};
  cursor: pointer;
  ${(props) =>
    getCSS(
      props.fullScreenTransform,
      props.fullscreen,
      props.orientation,
      props.taskHeader
    )}
`;
