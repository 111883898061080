import { useQuery } from "@tanstack/react-query";
import { getImagesByMediaId } from "../services/images.service";
import { QueryKeys } from "./queryKeys";

export const useImagesByMediaId = (mediaId: string) => {
  return useQuery({
    queryKey: [QueryKeys.ImagesByMedia, mediaId],
    queryFn: () => getImagesByMediaId(mediaId),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 15,
    enabled: !!mediaId,
  });
};
