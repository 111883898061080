import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import {
  toHalfWidth,
  containsFullWidthCharacters,
} from "../helpers/toHalfWidth";

async function fetchLastAssignedTeamForItem(itemNo: string) {
  const halfWidthItemNo = containsFullWidthCharacters(itemNo)
    ? toHalfWidth(itemNo)
    : itemNo;
  const url = `${TASKS_SERVICE_URL}/v1/tasks/last-assigned-team?itemNo=${halfWidthItemNo}`;
  const { result: latestFinishedTask } = await fetchAPI("GET", url);
  return latestFinishedTask?.assigned_team_id ?? null;
}

export function useLastAssignedTeamForItem(itemNo: string) {
  const { data: lastAssignedTeamId, isLoading } = useQuery({
    enabled: !!itemNo,
    queryKey: ["lastAssignedTeam", "item", itemNo],
    queryFn: async () => await fetchLastAssignedTeamForItem(itemNo),
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const storeTeams = useStoreTeams();
  if (!isLoading && storeTeams.length > 0 && !!lastAssignedTeamId) {
    return {
      data: storeTeams.find((team) => team.id === lastAssignedTeamId),
      isLoading,
    };
  }
  return { data: undefined, isLoading };
}
