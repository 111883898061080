import React from "react";
import { Media, ProductRange } from "../../types/media";
import { useTranslation } from "@coworker/locales";
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";

interface ProductRangeInputProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const ProductRangeInput = ({
  media,
  onSave,
}: ProductRangeInputProps) => {
  const { t } = useTranslation();

  return (
    <FixaInputField
      id={"product_range"}
      label={t("productRangeString")}
      type="text"
      value={(media as ProductRange).productRange ?? ""}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const newProductRange = event.target.value;
        const newMedia = { ...media };
        (newMedia as ProductRange).productRange = newProductRange;
        onSave(newMedia);
      }}
    />
  );
};
