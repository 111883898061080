import {
  MultiSelectFilter,
  FilterValue,
  StringArrayFilterValue,
  SelectOption,
} from "../../types";
import {
  createFilterValueFromMultiSelectOption,
  getSelectedFilterValue,
  updatedFilterValueFromValues,
} from "../../utils";
import { PillMultiSelect } from "../PillSelect";

interface InputMultiSelectPillDesktopProps {
  filter: MultiSelectFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const InputMultiSelectPillDesktop = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
}: InputMultiSelectPillDesktopProps) => {
  const { id } = filter;
  const selected = getSelectedFilterValue(
    id,
    filterValues,
    defaultValues
  ) as StringArrayFilterValue;
  const handleOnChange = (option: SelectOption, isSelected: boolean) => {
    const value = createFilterValueFromMultiSelectOption(
      filter.id,
      option,
      selected,
      isSelected
    );
    setFilterValues(updatedFilterValueFromValues(filterValues, filter, value));
  };
  return (
    <PillMultiSelect
      filter={filter}
      filterValue={selected}
      selected={selected}
      onChange={handleOnChange}
    />
  );
};
