import React from "react";
import dayjs from "dayjs";
import {
  useUserPreferences,
  useUpdateUserPreferences,
} from "../../hooks/useProfilePreferencesQuery";
import useMounted from "../../hooks/useMounted";
import profilePreferences from "@coworker/enums/profilePreferences";
import * as insightsGroupTypes from "@coworker/enums/insightsGroupTypes";
import { INSIGHTS_FILTERS_DEFAULT } from "@coworker/apprestructured/src/insights/constants/insightsFilters";
import { merge, setIn } from "../../helpers/utils";
const isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

export function formatIsoDate(timestamp) {
  return dayjs(timestamp || Date.now()).format("YYYY-MM-DD");
}

export function getLastWeekDates() {
  return [salesWeekStart(1), salesWeekEnd(1)];
}

export function formatEDSDate(timestamp, countryId) {
  const dateObject = new Date(timestamp);

  const fullYear = dateObject.getUTCFullYear();
  const shortYear = String(fullYear).slice(2);
  const month = String(dateObject.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth() returns 0-based month
  const day = String(dateObject.getUTCDate()).padStart(2, "0");

  const currentYear = dayjs().year();
  if (fullYear === currentYear) {
    return countryId === "US" ? `${day}/${month}` : `${month}/${day}`;
  } else {
    return countryId === "US"
      ? `${shortYear}/${day}/${month}`
      : `${shortYear}/${month}/${day}`;
  }
}

export function getSalesWeekDatesFromDate(
  startDate = Date.now(),
  endDate = startDate
) {
  const weekStartDate = dayjs(startDate)
    .add(1, "day")
    .startOf("isoWeek")
    .subtract(1, "day")
    .toDate();

  const weekEndDate = dayjs(endDate)
    .add(1, "day")
    .endOf("isoWeek")
    .subtract(1, "day")
    .toDate();

  return [formatIsoDate(weekStartDate), formatIsoDate(weekEndDate)];
}

export function getSalesWeekFromDate(currentDate) {
  // isoWeek returns the week number from Monday to Sunday. Sales week is shifted
  // and is from Sunday to Saturday. We add one day so that we get the correct week
  // number for Sunday.
  return dayjs(currentDate || Date.now())
    .add(1, "day")
    .isoWeek();
}

export function getCurrentYear(currentDate) {
  return dayjs(currentDate || Date.now()).year();
}

export function getLast8Weeks(weeksBack = 0, date) {
  return dayjs(date || Date.now())
    .subtract(8, "week")
    .add(1, "day")
    .startOf("isoWeek")
    .subtract(1, "day")
    .toDate();
}

function salesWeekStart(weeksBack = 0, date) {
  return dayjs(date || Date.now())
    .subtract(weeksBack, "week")
    .add(1, "day")
    .startOf("isoWeek")
    .subtract(1, "day")
    .toDate();
}

function salesWeekEnd(weeksBack = 0, date) {
  return dayjs(date || Date.now())
    .subtract(weeksBack, "week")
    .add(1, "day")
    .endOf("isoWeek")
    .subtract(1, "day")
    .toDate();
}

function returnGraphOption(weeksBack) {
  return weeksBack ? insightsGroupTypes.WEEK : insightsGroupTypes.DAY;
}

export function useInsightsFetchParams({
  store_id,
  location_id,
  product_id,
  hfb_value,
}) {
  const mounted = useMounted();
  // check weeksBack to check for custom date
  const insightsParams = React.useCallback(
    (params) => {
      const weeksBack = params?.period?.weeksBack;
      const hfb = hfb_value ?? params?.deptFilters?.hfb;
      const result = {
        ...params,
        weeksBack: params?.period?.weeksBack,
        hfb: hfb === "ALL" ? null : hfb,
        store_id,
        start_date: formatIsoDate(
          weeksBack === "custom"
            ? params?.period?.customDate?.startDate
            : salesWeekStart(weeksBack ? weeksBack - 1 : 0)
        ),
        today: formatIsoDate(Date.now()),
        end_date: formatIsoDate(
          weeksBack === "custom"
            ? params?.period?.customDate?.endDate
            : salesWeekEnd()
        ),
        group_by:
          weeksBack === "custom"
            ? params?.graph?.option // TODO: This is probably where the bug we protect from in https://github.com/ingka-group-digital/cmp-fixa/pull/2988 has sneaked through.
            : returnGraphOption(weeksBack),
        hfb_request_type: params?.deptFilters?.salesShare,
      };
      if (location_id) result.location = decodeURIComponent(location_id);
      if (product_id) result.product_id = product_id;
      if (product_id)
        result.product_article_code = removeProductType(product_id); // TODO: make backend accept both with and without initial ART/SPR to get rid of this
      return result;
    },
    [location_id, product_id, store_id, hfb_value]
  );

  const [savedInsightsFilter, ] = React.useState(
    INSIGHTS_FILTERS_DEFAULT
  );

  const { data: userPreferences, isLoading: isPreferencesLoading } =
    useUserPreferences(profilePreferences.INSIGHTS_FILTERS);

  const updatePreferences = useUpdateUserPreferences(
    profilePreferences.INSIGHTS_FILTERS
  );

  const [dataFetchParams, setDataFetchParams] = React.useState(() =>
    insightsParams(
      !!isPreferencesLoading && userPreferences
        ? userPreferences?.[profilePreferences.INSIGHTS_FILTERS]
        : INSIGHTS_FILTERS_DEFAULT
    )
  );

  // React.useEffect(() => {
  //   if (!isPreferencesLoading) {
  //     setSavedInsightsFilters(
  //       userPreferences?.[profilePreferences.INSIGHTS_FILTERS]
  //     );
  //     setDataFetchParams((oldDataFetchParams) => {
  //       return insightsParams(
  //         merge(
  //           oldDataFetchParams,
  //           userPreferences?.[profilePreferences.INSIGHTS_FILTERS]
  //         )
  //       );
  //     });
  //   }
  // }, [userPreferences, isPreferencesLoading, insightsParams]);

  const onChangeInsightsParams = (change, persistPreferences = true) => {
    if (!mounted.current) return;
    const params = merge(dataFetchParams, change);
    if (persistPreferences) {
      let updatedInsightsFilters = {};
      if (savedInsightsFilter?.graph?.type !== params?.graph?.type) {
        setIn(updatedInsightsFilters, ["graph", "type"], params?.graph?.type);
      }
      if (
        savedInsightsFilter?.period?.weeksBack !== params?.period?.weeksBack ||
        (params?.period?.weeksBack === "custom" &&
          (savedInsightsFilter?.period?.customDate?.startDate !==
            params?.period?.customDate?.startDate ||
            savedInsightsFilter?.period?.customDate?.endDate !==
              params?.period?.customDate?.endDate))
      ) {
        setIn(
          updatedInsightsFilters,
          ["period", "weeksBack"],
          params?.period?.weeksBack
        );
        if (params?.period?.weeksBack === "custom") {
          setIn(
            updatedInsightsFilters,
            ["period", "customDate", "startDate"],
            params?.period?.customDate?.startDate || ""
          );
          setIn(
            updatedInsightsFilters,
            ["period", "customDate", "endDate"],
            params?.period?.customDate?.endDate || ""
          );

          setIn(
            updatedInsightsFilters,
            ["graph", "option"],
            params.graph?.option
          );
        } else {
          setIn(
            updatedInsightsFilters,
            ["period", "customDate", "startDate"],
            null
          );
          setIn(
            updatedInsightsFilters,
            ["period", "customDate", "endDate"],
            null
          );
        }
      }

      if (
        savedInsightsFilter?.deptFilters?.orderBy !==
        params?.deptFilters?.orderBy
      ) {
        setIn(
          updatedInsightsFilters,
          ["deptFilters", "orderBy"],
          params?.deptFilters?.orderBy
        );
      }
      if (savedInsightsFilter?.deptFilters?.hfb !== params?.deptFilters?.hfb)
        setIn(
          updatedInsightsFilters,
          ["deptFilters", "hfb"],
          params?.deptFilters?.hfb
        );
      if (
        savedInsightsFilter?.deptFilters?.orderByAsc !==
        params.deptFilters?.orderByAsc
      ) {
        setIn(
          updatedInsightsFilters,
          ["deptFilters", "orderByAsc"],
          params?.deptFilters?.orderByAsc
        );
      }
      if (
        savedInsightsFilter?.deptFilters?.salesShare !==
        params?.deptFilters?.salesShare
      ) {
        setIn(
          updatedInsightsFilters,
          ["deptFilters", "salesShare"],
          params?.deptFilters?.salesShare
        );
      }
      if (
        savedInsightsFilter?.home?.valueToShow !== params?.home?.valueToShow
      ) {
        setIn(
          updatedInsightsFilters,
          ["home", "valueToShow"],
          params?.home?.valueToShow
        );
      }
      if (
        savedInsightsFilter?.home?.sortBy?.type !== params?.home?.sortBy?.type
      )
        setIn(
          updatedInsightsFilters,
          ["home", "sortBy", "type"],
          params?.home?.sortBy?.type
        );
      if (
        savedInsightsFilter?.home?.sortBy?.value !== params?.home?.sortBy?.value
      )
        setIn(
          updatedInsightsFilters,
          ["home", "sortBy", "value"],
          params?.home?.sortBy?.value
        );

      if (
        savedInsightsFilter?.productPreview?.most !==
        params?.productPreview?.most
      ) {
        setIn(
          updatedInsightsFilters,
          ["productPreview", "most"],
          params?.productPreview?.most
        );
      }

      if (
        savedInsightsFilter?.productPreview?.least !==
        params?.productPreview?.least
      ) {
        setIn(
          updatedInsightsFilters,
          ["productPreview", "least"],
          params?.productPreview?.least
        );
      }

      updatePreferences.mutate({
        insightsFilters: merge(savedInsightsFilter, updatedInsightsFilters),
      });
    }
    const settings = insightsParams(params);
    setDataFetchParams({ ...settings });
  };
  return [dataFetchParams, onChangeInsightsParams, isPreferencesLoading];
}

export function removeProductType(product = "") {
  return product.replace(/^ART|^SPR/g, "");
}
