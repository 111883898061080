import {ReactNode} from "react";
import { Link } from "react-router-dom";

type FixaLinkProps = {
  to: string;
  children: ReactNode;
  className?: string | undefined;
  "data-testid"?: string | undefined;
  "aria-label"?: string | undefined;
};

function FixaLink(props: FixaLinkProps) {
  return <Link {...props} />;
}

export default FixaLink;
