import { useContext } from "react";

import { useTranslation } from "@coworker/locales";

import { ModalContext } from "../../context/ModalContextProvider";
import { ModalType } from "../../context/modalType";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { useInputPopup } from "@coworker/app/src/components/InputPopup";
import { useProductQualityTeamId } from "@coworker/app/src/hooks/useProductQualityTeamId";
import { TaskTypesRoutes } from "../../../home/constants/taskTypesRoutes";

import {
  NavigationItemsConfig,
  NavigationItem,
} from "./constants/navigationItemsConfig";

import styles from "./main-navigation.module.css";

import { isRDTDevice, isRDTDevice11 } from "@coworker/reusable";
import { FixaListView } from "../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../shared/wrappers/FixaListView/FixaListViewItem";

const MainNavigation = () => {
  const { push, setNavigationState } = useWorkspacesAction();
  const { t } = useTranslation();
  const { modalState, toggleModal } = useContext(ModalContext);
  const qualityTeamId = useProductQualityTeamId();

  const isRDT11 = isRDTDevice11();
  const isRDT = isRDTDevice();

  const { getInput } = useInputPopup();

  const handleNavigationLinkClick = async (type: string) => {
    if (type !== TaskTypesRoutes.CUSTOM) {
      setNavigationState({
        search: true,
        type,
      });
      const product = await getInput("scansearch", { start: new Date() });
      if (product) {
        push(`/task/new/${type}`, { product, qualityTeamId });
      }
    } else {
      push(`/task/new/${type}`, { qualityTeamId });
    }
  };

  const handleToggleMainNavigation = () => {
    toggleModal({ type: ModalType.MainNavigation });
  };

  const filteredNavigationItemsConfig =
    isRDT || isRDT11
      ? NavigationItemsConfig.filter((item) => item.id !== "dashboard")
      : NavigationItemsConfig;

  const navigationItems = filteredNavigationItemsConfig;

  // Load saved order indexes from localStorage
  const savedOrderIndexes = localStorage.getItem("navigationOrderIndexes");
  if (savedOrderIndexes) {
    const parsedOrderIndexes = JSON.parse(savedOrderIndexes);
    navigationItems.forEach((item) => {
      const savedOrderIndex = parsedOrderIndexes[item.id];
      if (savedOrderIndex !== undefined) {
        item.orderIndex = savedOrderIndex;
      }
    });
  }

  // Increment order index for the clicked element and save it to localStorage
  const updateNavigationLinkOrder = (id: string) => {
    const item = navigationItems.find((item) => item.id === id);
    if (item) {
      item.orderIndex++;
      localStorage.setItem(
        "navigationOrderIndexes",
        JSON.stringify(
          navigationItems.reduce((acc: { [key: string]: number }, item) => {
            acc[item.id] = item.orderIndex;
            return acc;
          }, {})
        )
      );
    }
  };

  const handleItemClick = async (item: NavigationItem) => {
    handleToggleMainNavigation();
    if (item.href) {
      push(item.href);
    }

    if (item.link) {
      window.open(item.link, "_blank", "noopener,noreferrer");
    }

    if (item.taskType) {
      handleNavigationLinkClick(item.taskType);
    }

    updateNavigationLinkOrder(item.id);
  };

  return (
    <FixaModal
      visible={modalState.isMainNavigationOpen}
      keepContentMounted={true}
      handleCloseBtn={handleToggleMainNavigation}
    >
      <FixaSheets
        header={null}
        footer={null}
        preserveAlignment={false}
        size="small"
        alignment="left"
      >
        <FixaModalHeader
          title={t("Menu")}
          closeBtnClick={() => handleToggleMainNavigation()}
        />
        <FixaListView
          id="main-navigation"
          className={styles["list-view"] as string}
        >
          {navigationItems
            .sort((a, b) => b.orderIndex - a.orderIndex)
            .map((item) => (
              <FixaListViewItem
                inset
                onClick={() => handleItemClick(item)}
                key={item.id}
                emphasised
                control="navigational"
                controlIcon={null}
                id={item.id}
                leadingIcon={item.icon}
                title={t(item.labelTranslationKey)}
                size="small"
                data-testid={item.testId}
              />
            ))}
        </FixaListView>
      </FixaSheets>
    </FixaModal>
  );
};

export default MainNavigation;
