import styled, { css } from "styled-components";

interface WidgetAreaProps {
  $disabled?: boolean;
  $noBorder?: boolean;
}

const WidgetArea = styled('div')<WidgetAreaProps>`
  font-size: 14px;
  flex-shrink: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none; /* Non-prefixed version for Chrome and Opera */
  ${({ $noBorder }) =>
    !$noBorder &&
    css`
      border-bottom: 1px solid var(--grey100);
    `}
`;

// This component can be used from TaskForm/TaskDetail (via conf) or directly without wrapping with conf.
export const Spacer = ({ height = 0, conf = { height: 0 } }) => (
  <div style={{ height: height || conf?.height || 20 }} />
);

export const WidgetDivider = styled('div')`
  border-bottom: 7px solid var(--grey100);
`;

export const WidgetAreaClickable = styled(WidgetArea)`
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      touch-action: none;
      opacity: 0.6;
    `}
`;

export const WidgetAreaStatic = styled(WidgetAreaClickable)`
  pointer-events: none;
  touch-action: none;
`;

export const FlexRow = styled('div')`
  display: flex;
  flex-direction: row;
`;
