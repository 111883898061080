import { getArticleCompletionStatus } from "../CommonComponents/article.helpers";
import { TestBuyProductWithCriteria, Criterion } from "@coworker/types";
import { ArticleListProps } from "./ArticleList";

export const getRemainingArticlesProps = (
  articles: TestBuyProductWithCriteria[],
  testBuyId: string
): ArticleListProps => {
  
  const props: ArticleListProps = {
    articles:
      articles.filter(
        (article) => getArticleCompletionStatus(article) !== "Completed"
      ) ?? [],
    testbuyId: testBuyId,
  };
  return props;
};

export const createCounter = (
  numberOfArticles: number,
  totalNumberOfArticles: number
): string => {
  return `${numberOfArticles} / ${totalNumberOfArticles}`;
};

const getNumberOfFailedCriteria = (criteria: Criterion[]): number => {
  return criteria.filter((criterion) => criterion.state === "NOT_OK").length;
};

const sortArticlesByFailedCriteria = (
  articles: TestBuyProductWithCriteria[]
): TestBuyProductWithCriteria[] => {
  return articles.sort(
    (first, second) =>
      getNumberOfFailedCriteria(second.criteria) -
      getNumberOfFailedCriteria(first.criteria)
  );
};

export const getCompletedArticlesProps = (
  articles: TestBuyProductWithCriteria[],
  testBuyId: string
): ArticleListProps => {
  const filteredArticles = articles.filter(
    (article) => getArticleCompletionStatus(article) === "Completed"
  );

  const props: ArticleListProps = {
    articles: sortArticlesByFailedCriteria(filteredArticles),
    testbuyId: testBuyId,
  };
  return props;
};
