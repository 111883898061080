import { useTasksSelectFilter } from "@coworker/apprestructured/src/shared/filters/filters";
import {
  ApplyFilter,
  FilterValue,
  SelectFilter,
} from "@coworker/apprestructured/src/shared/filters/types";
import { Article, Combined } from "../types/article";

function useTasksSelectArticleFilter() {
  const filter: ApplyFilter<SelectFilter, Article> = {
    filter: useTasksSelectFilter(),
    filterCompare: (item: Combined<Article>, { value }: FilterValue) => {
      switch (value) {
        case "ongoingTasks":
          return item.tasks && item.tasks.length ? true : false;
        case "noOngoingTasks":
          return item.tasks && item.tasks.length ? false : true;
        default:
          return true;
      }
    },
  };

  return filter;
}

export { useTasksSelectArticleFilter };
