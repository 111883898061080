import { Text } from "./Text";
import { fullMediaName } from "../tools";
import { fontSizeMap } from "../styles/styles";

interface HeadLineProps {
  main?: string | undefined;
  sub1?: string | undefined;
  sub2?: string | undefined;
  bold?: boolean | undefined;
  size?: keyof typeof fontSizeMap;
}

export function HeadLine({ main, sub1, sub2, bold, size }: HeadLineProps) {
  bold = bold === undefined ? true : bold;
  let headLine = fullMediaName(main, sub1, sub2);

  return <Text text={headLine} bold={bold} size={size || "medium"} />;
}
