import styled from "styled-components";
import { Trans } from "@coworker/locales";
import AvatarImage from "../../AvatarImage";
import { UserOrGroupName } from "../../../core/components/UserOrGroupName";
import {
  DetailSection,
  DetailSectionRight,
  DetailSectionLeft,
  SectionText,
  SectionTitle,
} from "./_common";
import { CompactIconWrapper } from "./description";
import { ReactComponent as Group } from "../../../assets/svg/profile-group.svg";
import { ReactComponent as AssignIcon } from "../../../assets/svg/icon-profile-cropped.svg";

const GroupAvatar = styled(Group)`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

function Assignment({ task, title, conf: { icon = null } }) {
  return (
    <DetailSection>
      {icon && (
        <CompactIconWrapper>
          <AssignIcon />
        </CompactIconWrapper>
      )}
      <DetailSectionLeft>
        <SectionTitle>{title || <Trans>assigned2String</Trans>}</SectionTitle>
        <SectionText data-testid="taskAssignee">
          <UserOrGroupName
            uid={task.assigned_user_id}
            gid={task.assigned_team_id}
          />
        </SectionText>
      </DetailSectionLeft>
      <DetailSectionRight>
        {task.assigned_user_id ? (
          <AvatarImage uid={task.assigned_user_id} />
        ) : (
          <GroupAvatar />
        )}
      </DetailSectionRight>
    </DetailSection>
  );
}

export default Assignment;
