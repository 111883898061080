import {SVGProps} from "react";
import SSRIcon from "@ingka/ssr-icon";
import "@ingka/svg-icon/dist/style.css";

type FixaSSRIconProps = {
  paths: (prefix?: string) => SVGProps<SVGElement>[];
  className?: string;
  color?: string;
};

const FixaSSRIcon = (props: FixaSSRIconProps) => {
  return <SSRIcon {...props} />;
};

export default FixaSSRIcon;
