import {
  sfmArticleNumber,
  extractFromEAN,
} from "../services/foodMarket.service";

/***
 * Extract an IKEA ART article number from a scanned ITF-14 code
 */
function extractI25barcode(code) {
  if (code?.length === 14) {
    if (["2", "9", "0"].indexOf(code[13]) >= 0) {
      return code.substring(0, 8);
    }
  }
  return false;
}

function extractI25barcodeSupplierNumber(code) {
  if (code?.length === 14) {
    if (["2", "9", "0"].indexOf(code[13]) >= 0) {
      return code.substring(8, 13);
    }
  }
  return "";
}

/***
 * Validates EAN code according to industry standards. See https://stackoverflow.com/a/15325499/296639 for more info.
 * This shortened implementation comes from https://stackoverflow.com/a/19629719/296639 and is additionally validated against https://www.gs1us.org/tools/check-digit-calculator
 * Example: EAN-13 1501497770005 SOMMARSKÖRD pickled gherkins sliced
 */
function checkEAN(string) {
  if (string.length !== 13) return false; // We want only EAN-13
  const checksum = string.split("").reduce(function (p, v, i) {
    return i % 2 === 0 ? p + 1 * v : p + 3 * v;
  }, 0);
  return checksum % 10 === 0;
}

/***
 * Extract an IKEA ART article number from a scanned EAN code
 */
function getEANcode(string, alreadyChecked) {
  // NOTE: If we want to allow just any EAN code to be scanned without having to add them to a hardcoded list,
  // then we can replace sfmArticleNumber with `extractFromEAN`.
  return (
    (alreadyChecked || checkEAN(string)) &&
    (sfmArticleNumber(string) || extractFromEAN(string))
  );
}

/***
 * Takes a scanned code (ITF-14 or EAN-13) and tries to find an IKEA ART article number
 */
export function getValidCode(string, fromHoneywell) {
  return extractI25barcode(string) || getEANcode(string, fromHoneywell);
}

export function getSupplierNumber(string) {
  return extractI25barcodeSupplierNumber(string);
}
