import React from "react";
import "./team-members-list-view.css";
import { useTranslation } from "react-i18next";
import { FixaListView } from "../../../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../../../shared/wrappers/FixaListView/FixaListViewItem";
import { User } from "../../../../../shared/types/user";
import { displayRoleTranslationString } from "../../../../../shared/helpers/user.helper";

interface TeamMembersListViewProps {
  id: string;
  teamMembers: User[] | undefined;
  isOpen: boolean;
}

export const TeamMembersListView: React.FC<TeamMembersListViewProps> = ({
  id,
  teamMembers,
  isOpen,
}) => {
  const { t } = useTranslation();
  return isOpen ? (
    <FixaListView id={id}>
      {teamMembers?.map((teamMember: User) => {
        return (
          <FixaListViewItem
            className="teams-members-list-view-item"
            key={teamMember.fixaUid}
            id={teamMember.fixaUid}
            controlIcon={null}
            inset
            title={teamMember.fullName}
            addOn={t(displayRoleTranslationString(teamMember.roleId))}
          />
        );
      })}
    </FixaListView>
  ) : null;
};
