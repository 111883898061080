import styled from "styled-components";
import { CardFrame, FullLine, GreySpace, WhiteSpace } from "../styles/styles";
import { CardHeadLine } from "../Molecules/Settings/CardHeadLine";
import { RadioButtonWithInput } from "../Molecules/Settings/RadioButtonWithInput";
import { useTranslation } from "@coworker/locales";
import { Media } from "../types/media";
import { MediaTypes } from "../constants";
import { getMediaTypeText } from "../tools";
import { ActiveSwitcher } from "../Molecules/Settings/ActiveSwitcher";
import { Image } from "../types/images";
import { AboutSection } from "./Settings/Sections/AboutSection";
import { LocationAndSizeSection } from "./Settings/Sections/LocationAndSizeSection";
import { ImagesSection } from "./Settings/Sections/ImagesSection";

const GreyBackground = styled('div')`
  background-color: var(--grey100);
  with: calc(100%);
  height: calc(100%);
`;

interface MediaSettingsFormProps {
  currentMedia: Media;
  medias: Media[];
  setCurrentMedia: (newMedia: Media) => void;
  currentCheckedMediaType: MediaTypes;
  setCurrentCheckedMediaType: (newMediaType: MediaTypes) => void;
  images: Image[];
  onImageAddStart: (fileList: FileList) => void;
  onImageAddEnd: (images: Image[]) => void;
  onImageRemove: (images: Image[]) => void;
  onImageUpdate: (image: Image) => void;
}

export function MediaSettingsForm({
  currentMedia,
  medias,
  setCurrentMedia,
  currentCheckedMediaType,
  setCurrentCheckedMediaType,
  images,
  onImageAddStart,
  onImageAddEnd,
  onImageRemove,
  onImageUpdate,
}: MediaSettingsFormProps) {
  const { t } = useTranslation();

  const radioButtonWithInput = (mediaType: MediaTypes) => (
    <>
      <FullLine />
      <RadioButtonWithInput
        media={currentMedia}
        medias={medias}
        mediaType={mediaType}
        onSave={(newMedia) => setCurrentMedia(newMedia)}
        currentCheckedMediaType={currentCheckedMediaType}
        onRadioChange={(newMediaType) => {
          setCurrentMedia({ ...currentMedia, mediaType: newMediaType });
          setCurrentCheckedMediaType(newMediaType);
        }}
      />
    </>
  );

  return (
    <GreyBackground>
      <CardFrame>
        <GreySpace />
        <CardHeadLine
          character={"1"}
          headline={t("selectMediaTypeString")}
          subHeadline={t("mandatoryString")}
        />
        {radioButtonWithInput(MediaTypes.ROOM_SETTINGS)}
        {radioButtonWithInput(MediaTypes.VIGNETTE)}
        {radioButtonWithInput(MediaTypes.COMPACT)}
        {radioButtonWithInput(MediaTypes.COORDINATION_MEDIA)}
        <WhiteSpace />
      </CardFrame>
      <GreySpace />
      <CardFrame>
        <CardHeadLine
          character={"#"}
          headline={t(`statusOfString`, {
            name: getMediaTypeText(currentMedia.mediaType as MediaTypes),
          })}
        />
        <FullLine />
        <ActiveSwitcher
          media={currentMedia}
          onSave={(newMedia) => setCurrentMedia(newMedia)}
        />
        <WhiteSpace height={"12"} />
      </CardFrame>
      <GreySpace />
      <AboutSection
        media={currentMedia}
        onSave={(newMedia) => setCurrentMedia(newMedia)}
      />
      <GreySpace />
      <LocationAndSizeSection
        media={currentMedia}
        onSave={(newMedia) => setCurrentMedia(newMedia)}
      />
      <GreySpace />
      <ImagesSection
        media={currentMedia}
        images={images}
        onImageAddStart={onImageAddStart}
        onImageAddEnd={onImageAddEnd}
        onImageRemove={onImageRemove}
        onImageUpdate={onImageUpdate}
      />
      <GreySpace />
    </GreyBackground>
  );
}
