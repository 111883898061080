import { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { resetPrepareToCelebrateWithAnAnimation } from "../../helpers/taskFilters";
import WavingHand from "../../assets/images/waving-hand.png";
import { msalLogout } from "../../core/auth/useAuth";
import { useMsal } from "@azure/msal-react";
import { LOGOUT_PATH } from "../../paths";

const Container = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const TextRow = styled('div')`
  position: absolute;
  top: 75%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span {
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: -0.8px;
    color: var(--white);
  }

  & > span:nth-child(2) {
    color: var(--yellow);
  }
`;

const textVariants = {
  pre: { y: -10, opacity: 0 },
  show: { y: 0, opacity: 1, transition: { duration: 0.3 } },
  post: { y: 10, opacity: 0 },
};

const lowerTextVariants = {
  pre: { y: -10, opacity: 0 },
  show: { y: 0, opacity: 1, transition: { duration: 0.3, delay: 0.1 } },
  post: { y: 10, opacity: 0 },
};

const UpperMotionText = ({ pose, children }) => (
  <motion.span variants={textVariants} initial="pre" animate={pose} exit="post">
    {children}
  </motion.span>
);

const LowerMotionText = ({ pose, children }) => (
  <motion.span variants={lowerTextVariants} initial="pre" animate={pose} exit="post">
    {children}
  </motion.span>
);


const IconContainer = styled('div')`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > svg {
    color: var(--yellow);
    /* transform: scale(2.5); */
  }
`;

const Image = styled('img')`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 72px;
  height: 72px;
  animation: 2.9s wave_animation ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: 50% 75%;
  @keyframes wave_animation {
    0% {
      transform: translate(-50%, -50%) scale(0) rotate(-10deg);
    }
    100% {
      transform: translate(-50%, -50%) scale(0) rotate(10deg);
    }
    10%,
    64% {
      transform: translate(-50%, -50%) scale(1) rotate(10deg);
    }
    36%,
    90% {
      transform: translate(-50%, -50%) scale(1) rotate(-10deg);
    }
  }
`;

export function LogoutView() {
  const { instance, accounts } = useMsal();

  const screens = [
    {
      image: WavingHand,
    },
  ].filter((i) => i);

  const [currentScreen, setCurrentScreen] = useState(0);
  const [pose, setPose] = useState("pre");

  useEffect(() => {
    if (currentScreen >= screens.length) {
      return;
    }
    setPose("show");
    let to = setTimeout(next, 2000);
    function next() {
      setPose("post");
      to = setTimeout(() => {
        setPose("pre");
        resetPrepareToCelebrateWithAnAnimation();
        to = setTimeout(() => setCurrentScreen(currentScreen + 1), 500);
      }, 500);
    }
    return () => clearTimeout(to);
  }, [currentScreen, screens.length]);

  useEffect(() => {
    if (!accounts || accounts.length === 0) return;
    if (window.location.pathname.startsWith(LOGOUT_PATH)) {
      msalLogout(instance);
    }
  }, [accounts, instance]);

  if (!screens[currentScreen]) {
    return null;
  }
  const screen = screens[currentScreen];
  const ScreenIcon = screen.icon || (() => null);

  return (
    <Container>
      {screen.image && <Image src={screen.image} />}
      {screen.icon && (
        <IconContainer>
          <ScreenIcon pose={pose} />
        </IconContainer>
      )}
      {(screen.upperText || screen.lowerText) && (
        <TextRow>
          <UpperMotionText pose={pose}>{screen.upperText}</UpperMotionText>
          <LowerMotionText pose={pose}>{screen.lowerText}</LowerMotionText>
        </TextRow>
      )}
    </Container>
  );
}
