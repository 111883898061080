import {useState} from "react";
import styled from "styled-components";
import { ImageSkeleton } from "@coworker/reusable/components/ImageSkeleton";

const ItemPhoto = styled('img')<{ loadingState: boolean; padRight: number }>`
  min-width: 72px;
  height: 72px;
  ${({ loadingState }) => (loadingState ? "display: none;" : "")}
  ${({ padRight }) => (padRight ? `padding-right: ${padRight}px;` : "")}
`;

export function SmartImage({ image = "", hideSkeleton = false, padRight = 0 }) {
  const [imageError, setImageError] = useState(false);
  const [loading, setLoading] = useState(false); // TODO: Somehow the onLoad below stopped having the normal effect?

  if ((imageError || loading) && !hideSkeleton) {
    return <ImageSkeleton data-testid="imageSkeleton" size={72} />;
  }

  if (!imageError && image)
    return (
      <ItemPhoto
        padRight={padRight}
        data-testid="halfSheetPhoto"
        onError={() => setImageError(true)}
        onLoad={() => setLoading(false)}
        src={image}
        loadingState={loading}
      />
    );

  return null;
}
