import { useState, useEffect } from "react";
import { Combined, Article } from "../types/article";

export function useEdsDates(articles: Combined<Article>[]): string[] {
  const [edsDates, setEdsDates] = useState<string[]>([]);

  useEffect(() => {
    if (articles && articles.length) {
      const edsDatesMap = new Map<string, Combined<Article>[]>();
      for (const article of articles) {
        if (article.eds && article.eds.validToDateTime) {
          const date = article.eds.validToDateTime;
          if (edsDatesMap.has(date)) {
            edsDatesMap.get(date)!.push(article);
          } else {
            edsDatesMap.set(date, [article]);
          }
        }
      }
      setEdsDates([...edsDatesMap.keys()]);
    }
  }, [articles]);

  return edsDates;
}
