import styled from "styled-components";

const Badge = styled('div')`
  text-transform: uppercase;
  border-radius: 2px;
  display: inline-block;
  padding: 2px 7px 1px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: black;
  margin-bottom: 5px;
  width: fit-content;
`;

export default Badge;
