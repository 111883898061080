import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./switcher-sheet.module.css";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaModalBody } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalBody";
import { ModalContext } from "../../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../../layout/context/modalType";
import { useTranslation } from "@coworker/locales";
import FixaButton from "../../../../shared/wrappers/FixaButton/FixaButton";
import { SwitchTeamSheet } from "./SwitchTeamSheet";
import { SwitchStoreSheet } from "./SwitchStoreSheet";
import { SwitchCountrySheet } from "./SwitchCountrySheet";
import {
  useMyTeam,
  useTeamsByStore,
} from "@coworker/app/src/core/hooks/useStoreTeams";
import {
  Country,
  useCountries,
} from "@coworker/app/src/core/hooks/useCountries";
import {
  useCountryId,
  useFixaUID,
  useRoleId,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import FixaInlineMessage from "../../../../shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import WarningTrianglePath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/WarningTrianglePath";
import { useGetStoresByCountry } from "@coworker/app/src/core/hooks/useStoresByCountry";
import { TasksPrompt } from "./TasksPrompt";
import { useActivityData } from "../../../../activity/hooks/useActivityData";
import { DEFAULT_FILTER_VALUES } from "../../../../activity/constants/defaultFilterValues";
import { useMyStore } from "@coworker/app/src/core/auth/useLoggedInUser";
import { Store } from "../../../../shared/types/store";
import { useUpdateUser } from "../../../../shared/hooks/users/useUpdateUser";
import { ActivityColumns } from "../../../../activity/constants/activityColumns";
import { isChineseEnvironment } from "@coworker/reusable";
import { StoreEnvironmentSelector } from "./StoreEnvironmentSelector";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { UserRoles } from "../../../../shared/enums/user/userRoles";

export const StoreEnvironment = () => {
  const { modalState, toggleModal } = useContext(ModalContext);
  const myCountryId = useCountryId();
  const { data: countries, isLoading: isCountriesLoading } = useCountries();
  const isChina = isChineseEnvironment();
  const myCountry = useMemo(() => {
    const china: Country = {
      isoCode: "CN",
      name: "China",
    };
    return isChina
      ? china
      : countries?.find((country) => country.isoCode === myCountryId);
  }, [isChina, countries, myCountryId]);

  const store = useMyStore();
  const myTeam = useMyTeam();
  const userRole = useRoleId();
  const { i18n } = useTranslation();
  const userLocale = i18n.language;
  const [selectedCountry, setSelectedCountry] = useState(myCountry);
  const [selectedStore, setSelectedStore] = useState<Store | undefined>(store);
  const [selectedTeam, setSelectedTeam] = useState(myTeam);
  const [showTasksPrompt, setShowTasksPrompt] = useState(false);
  const [isTeamsDisabled, setIsTeamsDisabled] = useState(false);
  const { data: stores, isLoading: isStoresLoading } = useGetStoresByCountry(
    selectedCountry?.isoCode ?? ""
  );
  const { data: teams, isLoading: isTeamsLoading } = useTeamsByStore(
    selectedStore?.storeId ?? ""
  );
  const { t } = useTranslation();
  const uid = useFixaUID();
  const myTasks =
    useActivityData(
      { ...DEFAULT_FILTER_VALUES, assignedTo: uid },
      ActivityColumns.IN_PROGRESS
    )?.inProgressTasks ?? [];
  const myTasksCount = myTasks.data?.pages[0]?.filteredCount;

  useEffect(() => {
    setSelectedCountry(myCountry);
    setSelectedStore(store);
    setSelectedTeam(myTeam);
  }, [myCountry, store, myTeam]);

  const noValuesChanged =
    (selectedTeam?.id === myTeam?.id &&
      selectedStore?.storeId === store?.storeId &&
      selectedCountry?.isoCode === myCountry?.isoCode) ||
    showTasksPrompt;

  const onlyTeamUpdated =
    selectedTeam?.id !== myTeam?.id &&
    selectedStore?.storeId === store?.storeId &&
    selectedCountry?.isoCode === myCountry?.isoCode;

  const updateUserMutation = useUpdateUser(uid, onlyTeamUpdated);
  const updateUser = () => {
    updateUserMutation.mutateAsync({
      uid,
      countryId: selectedCountry?.isoCode ?? "",
      storeId: selectedStore?.storeId ?? "",
      teamId: selectedTeam?.id ?? "",
    });
  };

  const handleOnConfirm = () => {
    if (noValuesChanged) {
      return toggleModal({ type: ModalType.StoreEnvironment });
    }
    if (selectedStore?.name && selectedTeam?.name) {
      if (myTasksCount === 0 || onlyTeamUpdated) {
        updateUser();
        trackerHelper.trackStoreSwitcher("profile");
      } else {
        setShowTasksPrompt(true);
      }
    }
  };

  const sortedCountries = useMemo(
    () =>
      countries?.sort((a, b) =>
        a.name.localeCompare(b.name, userLocale ?? "en")
      ),
    [countries, userLocale]
  );

  const sortedTeams = useMemo(() => {
    const activeTeams = teams?.filter((team) => team.status === "ACTIVE");
    return activeTeams?.sort((a, b) =>
      a.name.localeCompare(b.name, userLocale ?? "en")
    );
  }, [teams, userLocale]);

  const isGlobalSuperUser = userRole === UserRoles.SUPERUSER;

  const sortedStores = useMemo(() => {
    const filteredStores = isGlobalSuperUser
      ? stores
      : stores?.filter((store) => store.storeId !== "012#fixa-team");
    return filteredStores?.sort((a, b) =>
      a.name.localeCompare(b.name, userLocale ?? "en")
    );
  }, [stores, isGlobalSuperUser, userLocale]);

  return (
    <FixaModal
      visible={modalState.isStoreEnvironmentOpen}
      handleCloseBtn={() => toggleModal({ type: ModalType.StoreEnvironment })}
    >
      <FixaSheets
        alignment="right"
        preserveAlignment={false}
        size="small"
        fullSize
        header={
          <FixaModalHeader
            title={t("storeEnvironmentString")}
            className={styles["header"] as string}
            floating={false}
            backBtnClick={() =>
              toggleModal({ type: ModalType.StoreEnvironment })
            }
          />
        }
        footer={
          <>
            {showTasksPrompt ? null : (
              <div className={styles["footer"] as string}>
                <FixaButton
                  text={t("confirmString")}
                  type="primary"
                  className={styles["confirm-button"] as string}
                  fluid
                  loading={updateUserMutation.status === "pending"}
                  onClick={handleOnConfirm}
                />
              </div>
            )}
          </>
        }
      >
        <FixaModalBody>
          <StoreEnvironmentSelector
            id="country"
            value={selectedCountry?.name ?? ""}
            label="adminCountryFilter"
            loading={isCountriesLoading}
            onClick={() => toggleModal({ type: ModalType.SwitchCountry })}
            selectedItem={selectedCountry}
            shouldValidate={false}
            disabled={isChina}
          />
          <br />
          <StoreEnvironmentSelector
            id="store"
            value={
              !selectedStore?.name
                ? t("chooseAnOptionString")
                : `${selectedStore.storeId} - ${selectedStore.name}`
            }
            label="adminStoreFilter"
            validationMsg="pleaseSelectAStoreString"
            selectedItem={selectedStore}
            onClick={() => toggleModal({ type: ModalType.SwitchStore })}
            loading={isStoresLoading}
          />
          <br />
          <StoreEnvironmentSelector
            id="team"
            value={
              !isTeamsDisabled
                ? selectedTeam?.name!!
                : t("chooseAnOptionString")
            }
            disabled={isTeamsDisabled}
            label="teamString"
            validationMsg="pleaseSelectATeamString"
            selectedItem={selectedTeam}
            onClick={() =>
              !isTeamsDisabled && toggleModal({ type: ModalType.SwitchTeam })
            }
            loading={isTeamsLoading}
          />
          <br />
          {!isTeamsLoading && isTeamsDisabled && selectedStore?.name && (
            <FixaInlineMessage
              title={t("noTeamsInStoreString")}
              body={t("notPossibleWithoutTeamsString")}
              variant={"cautionary"}
              ssrIcon={WarningTrianglePath}
            />
          )}
          <SwitchCountrySheet
            countries={sortedCountries}
            selectedCountry={selectedCountry!!}
            setSelectedCountry={setSelectedCountry}
            setSelectedStore={setSelectedStore}
            setSelectedTeam={setSelectedTeam}
            loading={isCountriesLoading}
          />
          <SwitchStoreSheet
            stores={sortedStores}
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            setSelectedTeam={setSelectedTeam}
            loading={isStoresLoading}
          />
          <SwitchTeamSheet
            teams={sortedTeams}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            setIsTeamsDisabled={setIsTeamsDisabled}
            loading={isTeamsLoading}
          />
          <TasksPrompt
            isVisible={showTasksPrompt}
            onClose={() => setShowTasksPrompt(false)}
            myTasksCount={myTasksCount ?? 0}
            uid={uid}
            updateUser={updateUser}
          />
        </FixaModalBody>
      </FixaSheets>
    </FixaModal>
  );
};
