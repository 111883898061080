import React from "react";
import styled from "styled-components";

// && is used to overwrite links becoming other colors than blue
const BlueLink = styled('a')`
  && {
    color: #0060b6;
    word-break: break-word;
  }
`;

export default BlueLink;

export function FixaEmailLink() {
  return (
    <BlueLink href="mailto:fixa.support@ikea.com">
      fixa.support@ikea.com
    </BlueLink>
  );
}
export function IndividualRightsLink() {
  return (
    <>
      {" "}
      <BlueLink href="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx">
        https://inside.ingka.com/&#8203;supportservices/&#8203;Dataprivacy/&#8203;Pages/&#8203;Individual-rights.aspx
      </BlueLink>{" "}
    </>
  );
}

export function EmailLink({ email }) {
  return (
    <>
      {" "}
      <BlueLink href={"mailto:" + email}>{email}</BlueLink>
    </>
  );
}
