import React from "react";
import styled from "styled-components";
import { ReactComponent as HomeFigure } from "../../assets/svg/insights-explain-home.svg";
import { ReactComponent as AwayFigure } from "../../assets/svg/insights-explain-away.svg";
import { Trans } from "@coworker/locales";

const Container = styled('div')`
  margin-top: 70px;
  height: 100vh;
`;

const HomeWrapper = styled('div')`
  background: var(--grey100);
  @media (max-height: 560px) {
    svg {
      max-height: 60vh;
    }
  }
  border-bottom: 10px solid var(--grey500);
`;

const AwayWrapper = styled('div')`
  background: var(--white);
  @media (max-height: 560px) {
    svg {
      max-height: 65vh;
    }
  }
  border-bottom: 20px solid var(--grey100);
`;

const Illustration = styled('div')`
  @media (max-height: 560px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const TextWrapper = styled('div')`
  padding: 35px;
  max-width: 80vw;
  h2 {
    text-align: center;
    padding-bottom: 1em;
  }
`;

function ShowInstruction({ title, figure, content }) {
  return (
    <div>
      <Illustration>
        <div>{figure}</div>
      </Illustration>
      <TextWrapper>
        <h2>{title}</h2>
        {content}
      </TextWrapper>
      <br />
    </div>
  );
}

export function HomeAndAway() {
  return (
    <Container>
      <HomeWrapper>
        <ShowInstruction
          title={<Trans>homeDepartmentString</Trans>}
          figure={<HomeFigure />}
          content={<Trans>homeExplanationString</Trans>}
        />
      </HomeWrapper>
      <AwayWrapper>
        <br />
        <ShowInstruction
          title={<Trans>awayDepartmentString</Trans>}
          figure={<AwayFigure />}
          content={<Trans>awayExplanationString</Trans>}
        />
      </AwayWrapper>
    </Container>
  );
}
