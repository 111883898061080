import { useEffect, useState, useCallback } from "react";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { useTranslation } from "@coworker/locales";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { Media } from "../types/media";
import { MediaSettingsForm } from "../Organisms/MediaSettingsForm";
import { FullWidthButton } from "../../SkapaButton";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "../hooks/queryKeys";
import { Image } from "../types/images";
import { updateImage } from "../services/images.service";
import { useAddImageToMedia } from "../hooks/useAddImageToMedia";
import { useMediasByStoreId } from "../hooks/useMediasByStoreId";
import { useParams } from "react-router";
import { useMediaById } from "../hooks/useMediaById";
import {
  getRequiredFields,
  getMediaTypeText,
  updateUnknownArticles,
} from "../tools";
import {
  checkEmptyMediaName,
  checkMediaName,
} from "../Organisms/Settings/SettingsForm.helper";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useArticlesByMediaId } from "../hooks/useArticlesByMediaId";
import { useDeleteImages } from "../hooks/useDeleteImages";
import { useImagesByMediaId } from "../hooks/useImagesByMediaId";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import { useUpdateMedia } from "../hooks/useUpdateMedia";
import { DeleteMediaModal } from "../Organisms/Modals/DeleteMediaModal";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import styled from "styled-components";
import { MessageModal } from "../Organisms/Modals/MessageModal";
import { OkCancelModal } from "../Organisms/Modals/OkCancelModal";
import { MediaTypes } from "../constants";
import { CenterContainerWithGap, WhiteSpace } from "../styles/styles";
import { ChangeMediaTypeConfirmationDialog } from "../Organisms/Modals/ChangeMediaTypeConfirmationModal";
import { useToastNotification } from "@coworker/components";

const FixaButtonWithMargin = styled(FixaButton)`
  margin: 0px 24px;
  align-self: center;
`;

export function UpdateMediaView() {
  const { t } = useTranslation();

  const { mediaId } = useParams();
  const { push } = useWorkspacesAction();

  const { data: mediaData } = useMediaById(mediaId);
  const updateMediaMutation = useUpdateMedia(mediaId || "");

  const [showModalMessage, setShowModalMessage] = useState("");
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [showChangeMediaTypeModal, setShowChangeMediaTypeModal] =
    useState(false);
  const [imagesAreUploading, setImagesAreUploading] = useState(false);
  const [currentMedia, setCurrentMedia] = useState({} as Media);
  const [updatingUnknowns, setUpdatingUnknowns] = useState(false);
  const [currentCheckedMediaType, setCurrentCheckedMediaType] =
    useState<MediaTypes>(MediaTypes.NONE);
  const [originalMediaType, setOriginalMediaType] = useState(MediaTypes.NONE);

  const { data: medias } = useMediasByStoreId(currentMedia.storeId);
  const { data: articles } = useArticlesByMediaId(mediaId);
  const addImageToMedia = useAddImageToMedia(currentMedia.id);
  const deleteImageMutation = useDeleteImages();
  const queryClient = useQueryClient();
  const { data: images } = useImagesByMediaId(currentMedia.id);
  const signedImages = useImagesBySignedUrl(images ?? []);
  const { showToast } = useToastNotification();

  const linkbar = (
    <Linkbar
      currentLabel={t("updateMediaString")}
      items={[
        {
          label: t("mediaMaintenanceString"),
          to: "/mediamaintenance",
        },
      ]}
    />
  );

  useEffect(() => {
    if (mediaData) {
      setCurrentMedia(mediaData);
      setCurrentCheckedMediaType(mediaData.mediaType as MediaTypes);
      setOriginalMediaType(mediaData.mediaType as MediaTypes);
    }
  }, [mediaData]);

  const onImageAddStart = () => {
    setImagesAreUploading(true);
  };

  const onImageAddEnd = async (images: Image[]) => {
    for (const image of images) {
      try {
        await addImageToMedia.mutateAsync(image);
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
    setImagesAreUploading(false);
  };

  const onImageRemove = async (images: Image[]) => {
    try {
      await deleteImageMutation.mutateAsync({
        imageIds: images.map((image) => image.id ?? ""),
        mediaId: currentMedia.id,
      });
    } catch (error) {
      alert(t("searchErrorString"));
    }
  };

  const onImageUpdate = async (image: Image) => {
    if (image.id) {
      try {
        await updateImage(image.id, image);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ImagesByMedia],
        });
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  const updateUnknowns = async () => {
    setUpdatingUnknowns(true);
    await updateUnknownArticles(articles || [], queryClient);
    setUpdatingUnknowns(false);
  };

  function validateMediaFields(media: Media): boolean {
    const requiredKeys = getRequiredFields(media);
    return requiredKeys.every((key) => {
      const value = media[key as keyof Media];
      return value;
    });
  }

  const [showErrorMessage, setshowErrorMessage] = useState(true);

  const ValidateMedia = useCallback(() => {
    const isDisabled =
      checkEmptyMediaName(currentMedia) ||
      checkMediaName(currentMedia, medias || []) ||
      !validateMediaFields(currentMedia);

    setshowErrorMessage(isDisabled);
  }, [currentMedia, medias]);

  useEffect(() => {
    ValidateMedia();
  }, [ValidateMedia]);

  const submitDisabled = imagesAreUploading;

  const onSubmit = async () => {
    if (showErrorMessage) return;
    try {
      await updateMediaMutation.mutateAsync(currentMedia);
      showToast(
        t("nameWasUpdatedString", {
          name: getMediaTypeText(currentMedia.mediaType as MediaTypes),
        })
      );
      push(`/mediamaintenance/tabs/${mediaId}`);
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotUpdateRoomTryAgainString"));
    }
  };

  if (!currentMedia) {
    return <></>;
  }

  return (
    <FullScreenPopup
      noPadding
      linkbar={linkbar}
      fullHeightContent={true}
      fieldsAreEmpty={showErrorMessage}
      actionBarContent={
        <CenterContainerWithGap $centerText={true}>
          <FullWidthButton
            type="secondary"
            text={t("cancelString")}
            onClick={() => {
              if (!showErrorMessage) {
                setShowConfirmCloseModal(true);
              } else {
                push(`/mediamaintenance/tabs/${mediaId}`);
              }
            }}
          />
          <FullWidthButton
            type="primary"
            text={t("saveString")}
            onClick={() => {
              if (
                originalMediaType !== currentMedia.mediaType &&
                !showErrorMessage
              ) {
                setShowChangeMediaTypeModal(true);
              } else {
                onSubmit();
              }
            }}
            loading={showChangeMediaTypeModal}
            disabled={submitDisabled}
          />
        </CenterContainerWithGap>
      }
    >
      <MediaSettingsForm
        currentMedia={currentMedia}
        medias={medias || []}
        setCurrentMedia={(newMedia) => {
          setCurrentMedia(newMedia);
        }}
        currentCheckedMediaType={currentCheckedMediaType}
        setCurrentCheckedMediaType={setCurrentCheckedMediaType}
        images={signedImages}
        onImageAddStart={onImageAddStart}
        onImageAddEnd={onImageAddEnd}
        onImageRemove={onImageRemove}
        onImageUpdate={onImageUpdate}
      />

      <WhiteSpace />
      <FixaButtonWithMargin
        type="primary"
        onClick={() => {
          updateUnknowns();
        }}
        aria-label={"Repair unknowns button"}
        loading={updatingUnknowns}
      >
        {"Update Unknown Articles"}
      </FixaButtonWithMargin>
      <DeleteMediaModal media={currentMedia} />
      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      {showConfirmCloseModal && (
        <OkCancelModal
          onOk={() => {
            setShowConfirmCloseModal(false);
            push(`/mediamaintenance/tabs/${mediaId}`);
            setCurrentMedia(mediaData ?? ({} as Media));
          }}
          onCancel={() => {
            setShowConfirmCloseModal(false);
          }}
          title={t("confirmCloseString")}
          text={t("allChangesLostCloseString")}
        />
      )}
      {showChangeMediaTypeModal && (
        <ChangeMediaTypeConfirmationDialog
          onConfirm={async () => {
            await onSubmit();
            setShowChangeMediaTypeModal(false);
          }}
          onCancel={() => {
            setShowChangeMediaTypeModal(false);
          }}
          media={currentMedia}
          originalMediaType={originalMediaType}
        />
      )}
    </FullScreenPopup>
  );
}
