import taskTypeOptions from "@coworker/enums/taskType";
import product from "./product";
import addon from "./addon";
import custom from "./custom";
import planned from "./planned";
import product_quality from "./product_quality";
import mfaq_followup from "./mfaq_followup";
import testbuy_follow_up from "./testbuy_follow_up";
import media from "./media";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [taskTypeOptions.PRODUCT_ISSUE]: product,
  [taskTypeOptions.ADDON]: addon,
  [taskTypeOptions.CUSTOM]: custom,
  [taskTypeOptions.PLANNED]: planned,
  [taskTypeOptions.PRODUCT_QUALITY]: product_quality,
  [taskTypeOptions.MFAQ_FOLLOW_UP]: mfaq_followup,
  [taskTypeOptions.TESTBUY_FOLLOW_UP]: testbuy_follow_up,
  [taskTypeOptions.ROOM_SETTINGS]: media,
};
