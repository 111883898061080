import { useState } from "react";
import { Media } from "../../types/media";
import { useMediasByStoreId } from "../../hooks/useMediasByStoreId";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "@coworker/locales";
import {
  checkEmptyMediaName,
  checkMediaName,
  EmptyRoomSettingsMedia,
} from "../Settings/SettingsForm.helper";
import { MessageModal } from "./MessageModal";
import { OkCancelModal } from "./OkCancelModal";
import { SettingsForm } from "../Settings/SettingsForm";
import { Image } from "../../types/images";
import { useCreateMedia } from "../../hooks/useCreateMedia";
import { useAddImageToMedia } from "../../hooks/useAddImageToMedia";
import { useQueryClient } from "@tanstack/react-query";
import { updateImage } from "../../services/images.service";
import { QueryKeys } from "../../hooks/queryKeys";

interface CreateSettingsModalProps {
  storeId: string;
  isVisible: boolean;
  onClose: (mediaId?: string) => void;
}

export const CreateSettingsModal = function ({
  storeId,
  isVisible,
  onClose,
}: CreateSettingsModalProps) {
  const { t } = useTranslation();
  const [showModalMessage, setShowModalMessage] = useState<string>("");
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [mediaIsUpdated, setMediaIsUpdated] = useState(false);
  const [imagesAreUploading, setImagesAreUploading] = useState(false);
  const [roomSettingsMedia, setRoomSettingsMedia] = useState({
    ...EmptyRoomSettingsMedia,
    storeId: storeId,
  } as Media);
  const [images, setImages] = useState<Image[]>([]);
  const { data: medias } = useMediasByStoreId(storeId);
  const createMediaMutation = useCreateMedia();
  const addImageToMedia = useAddImageToMedia(roomSettingsMedia.id);
  const queryClient = useQueryClient();

  const onSubmit = async () => {
    try {
      const mediaId = await createMediaMutation.mutateAsync(roomSettingsMedia);
      for (const image of images) {
        image.mediaId = mediaId?.mediaId ?? "";
        try {
          await addImageToMedia.mutateAsync(image);
        } catch (error) {
          alert(t("searchErrorString"));
        }
      }
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ImagesByMedia],
      });
      onClose(mediaId?.mediaId);
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotCreateRoomTryAgainString"));
    }
  };

  const onCloseWarning = () => {
    if (mediaIsUpdated) {
      setShowConfirmCloseModal(true);
    } else {
      onClose();
    }
  };

  const saveMediaChanges = (saveMedia: Media) => {
    setMediaIsUpdated(true);
    setRoomSettingsMedia(saveMedia);
  };

  const onImageAddStart = () => {
    setImagesAreUploading(true);
  };

  const onImageAddEnd = async (addImages: Image[]) => {
    setImages([...images, ...addImages]);
    setMediaIsUpdated(true);
    setImagesAreUploading(false);
  };

  const onImageRemove = async (removeImages: Image[]) => {
    for (const removeImage of removeImages) {
      const removeIndex = images.indexOf(removeImage);
      images.splice(removeIndex, 1);
    }
    setImages([...images]);
  };

  const onImageUpdate = async (image: Image) => {
    if (image.id) {
      try {
        await updateImage(image.id, image);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.ImagesByMedia],
        });
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  if (!roomSettingsMedia) {
    return <></>;
  }

  const submitDisabled =
    !medias ||
    checkEmptyMediaName(roomSettingsMedia) ||
    checkMediaName(roomSettingsMedia, medias) ||
    !mediaIsUpdated ||
    imagesAreUploading;

  return (
    <>
      <FixaModal visible={isVisible} handleCloseBtn={onCloseWarning}>
        <FixaSheets
          labelledById="edit-media"
          header={<FixaModalHeader title={t("createRoomSettingsString")} />}
          footer={
            <FixaModalFooter>
              <FixaButton
                type="primary"
                onClick={onSubmit}
                disabled={submitDisabled}
                aria-label={t("create4String")}
                data-testid="createSettingsModal:createRoomSettingsButton"
              >
                {t("create4String")}
              </FixaButton>
            </FixaModalFooter>
          }
        >
          <SettingsForm
            media={roomSettingsMedia}
            articles={[]}
            onSave={saveMediaChanges}
            showUpdateFields={false}
            images={images}
            onImageAddStart={onImageAddStart}
            onImageAddEnd={onImageAddEnd}
            onImageRemove={onImageRemove}
            onImageUpdate={onImageUpdate}
          />
        </FixaSheets>
      </FixaModal>
      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      {showConfirmCloseModal && (
        <OkCancelModal
          onOk={() => {
            setShowConfirmCloseModal(false);
            onClose();
            setRoomSettingsMedia({
              ...EmptyRoomSettingsMedia,
              storeId: storeId,
            });
            setImages([]);
          }}
          onCancel={() => {
            setShowConfirmCloseModal(false);
          }}
          title={t("confirmCloseString")}
          text={t("allChangesLostCloseString")}
        />
      )}
    </>
  );
};
