import { Trans } from "@coworker/locales";
import React from "react";
import styled from "styled-components";
import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";

const Container = styled('div')`
  border-top: solid 8px var(--grey100);
  padding: 1rem 4.25rem 1rem 4.25rem;
  font-size: 14px;
  text-align: center;
`;

export type WithDoneState<T> = T & {
  isDone: boolean;
  setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
};

interface TaskValidationProps {
  task: WithDoneState<TestBuyFollowUp>;
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ task }: TaskValidationProps) => {
  if (task.isDone || task.state === "COMPLETED") return <></>;
  return (
    <Container data-testid="youNeedToCheckAllMessage">
      <Trans>youNeedToCheckAllBoxesString</Trans>
    </Container>
  );
};
