import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useFetchLatestShoppingToolsSessionTime } from "../Hooks/useFetchLatestShoppingToolsSessionTime";
import { useFetchLatestShoppingTools } from "../Hooks/useFetchLatestShoppingtool";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

export const Container = styled('div')`
  width: 100%;
  border: 8px solid var(--grey100);
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
`;

export const LoadingWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const SmallText = styled('span')`
  font-size: 12px;
`;

export const LargeText = styled('span')`
  font-size: 36px;
  font-weight: bold;
`;

export const NewReview = () => {
  const { push } = useWorkspacesAction();

  const { data: lastestShoppingTool, isFetching } =
    useFetchLatestShoppingTools();
  const latestSessionTime = useFetchLatestShoppingToolsSessionTime(
    lastestShoppingTool?.created_at ?? 0
  );

  const { t } = useTranslation();

  if (isFetching) {
    return (
      <Container>
        <FixaSkeleton height="132px" width="300px" />
      </Container>
    );
  }

  return (
    <Container>
      <SmallText>
        <Trans>
          {latestSessionTime === t("ongoingString")
            ? "shoppingToolsSessionIsString"
            : "lastSTwasString"}
        </Trans>
      </SmallText>
      <LargeText>{latestSessionTime}</LargeText>
      <FixaButton
        type="primary"
        style={{ margin: "5px 0px" }}
        text={
          <Trans>
            {latestSessionTime === t("ongoingString")
              ? "continue2String"
              : "startToday"}
          </Trans>
        }
        onClick={() => push("/shoppingtools/progress")}
      />
    </Container>
  );
};
