import styles from "./task-card.module.css";
import { Trans } from "@coworker/locales";
import { isOverdue } from "@coworker/app/src/helpers/taskHelper";
import { FullTask } from "../../../activity/types/FullTask";
import { TaskType } from "../../enums/taskTypes";

interface TypeProps {
  task: FullTask;
  type: string;
}

export const Type = ({ task, type }: TypeProps) => {
  return (
    <div className={styles["type"]}>
      {task.task_type === TaskType.CUSTOM && isOverdue(task) && (
        <span className={styles["overdue"]}>
          [<Trans>overdueString</Trans>]{" "}
        </span>
      )}
      {type}
    </div>
  );
};
