import {
  FilterValue,
  MultiSelectFilter,
  StringArrayFilterValue,
} from "../../types";
import {
  createFilterValueFromMultiSelectOption,
  getSelectedFilterValue,
} from "../../utils";
import { AccordionMultiSelect } from "../AccordionSelect";

interface InputSelectOptionProps {
  filter: MultiSelectFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  openFilters: string[];
  onChange: (value: FilterValue) => void;
  onOpenChange: (isOpen: boolean) => void;
}

export const InputMultiSelectOption = ({
  filter,
  filterValues,
  defaultValues,
  openFilters,
  onChange,
  onOpenChange,
}: InputSelectOptionProps) => {
  const selected = getSelectedFilterValue(
    filter.id,
    filterValues,
    defaultValues
  )! as StringArrayFilterValue;
  return (
    <AccordionMultiSelect
      filter={filter}
      selected={selected}
      onChange={(option, isSelected) => {
        const value = createFilterValueFromMultiSelectOption(
          filter.id,
          option,
          selected,
          isSelected
        );
        onChange(value);
      }}
      open={!!openFilters.find((filterId) => filterId === filter.id)}
      onOpenChange={onOpenChange}
    />
  );
};
