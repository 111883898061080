import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export async function updateTaskById(data: {}, taskId: string) {
  const path = `/v1/task/${taskId}/update`;
  const url = `${TASKS_SERVICE_URL}${path}`;
  const { result } = await fetchAPI("PUT", url, data, "json");
  return result;
}

export function useUpdateTaskMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { data: {}; taskId: string }) => {
      return await updateTaskById(data.data, data.taskId);
    },
    onSuccess: async (data) => {
      if (data) {
        const taskId = data?.id;
        await queryClient.invalidateQueries({
          queryKey: ["task", "id", taskId, data.task_type],
        });
        await queryClient.invalidateQueries({
          queryKey: ["task", "activities", taskId],
        });
      }
    },
    onError: (error) => {
      console.log("Error while updating task", error);
    },
  });
}
