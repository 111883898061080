import { FixaFormField } from "@coworker/apprestructured/src/shared/wrappers/FixaFormField/FixaFormField";
import React from "react";
import { Media } from "../../types/media";
import { useTranslation } from "@coworker/locales";
import { StyleGroupSelect } from "./StyleGroupSelect";
import { LivingSituationSelect } from "./LivingSituationSelect";
import { LongTermPrioritySelect } from "./LongTermPrioritySelect";
import { PricingSelect } from "./PricingSelect";
import { HfbSelect } from "./HfbSelect";
import { MediaFormFieldTypes } from "../../constants";
interface MediaFormFieldComponentProps {
  type: MediaFormFieldTypes;
  media: Media;
  onSave: (newMedia: Media) => void;
  valid: boolean;
}

const MediaFormFieldComponent: React.FC<MediaFormFieldComponentProps> = ({
  type,
  media,
  onSave,
  valid,
}: MediaFormFieldComponentProps) => {
  const { t } = useTranslation();
  return (
    <FixaFormField
      fieldHelper={{
        id: type,
        msg: "",
        type: "",
      }}
      shouldValidate={true}
      valid={valid}
      validation={{
        id: "error-msg-id",
        msg: t("pleaseFillInString"),
        type: "error",
      }}
    >
      {type === MediaFormFieldTypes.STYLE_GROUP && (
        <StyleGroupSelect media={media} onSave={onSave} />
      )}
      {type === MediaFormFieldTypes.LIVING_SITUATION && (
        <LivingSituationSelect media={media} onSave={onSave} />
      )}
      {type === MediaFormFieldTypes.LONG_TERM_PRIORITY && (
        <LongTermPrioritySelect media={media} onSave={onSave} />
      )}
      {type === MediaFormFieldTypes.PRICING && (
        <PricingSelect media={media} onSave={onSave} />
      )}
      {type === "Hfb" && <HfbSelect media={media} onSave={onSave} />}
    </FixaFormField>
  );
};

export default MediaFormFieldComponent;
