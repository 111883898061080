import { fetchAPI } from "../../../../hooks/API/testbuy.service";

export const fetchArticleById = async (
  _testbuyKey: string,
  testbuyArticleKey: string
) => {
  if (typeof testbuyArticleKey !== "undefined" && testbuyArticleKey !== null) {
    return fetchAPI(`article/get_article_with_criteria`, "POST", {
      id: testbuyArticleKey,
    });
  } else {
    console.error("testbuy.article.api,testbuyArticleKey is undefined.");
    return null;
  }
};

export const fetchTestbuyArticles = async (testbuyKey: string | undefined) => {
  return (await fetchAPI(`article/${testbuyKey}`, "GET")) || [];
};

export const addFollowUpTaskId = async (
  testbuyKey: string,
  articleKey: string,
  followUpTaskId: string
) => {
  return await fetchAPI(`article/addFollowUpTaskId`, "POST", {
    testbuyId: testbuyKey,
    articleId: articleKey,
    followUpTaskId,
  });
};

export const fetchPreviousTestbuyNumberOfArticles = async (
  storeId: string,
  area: string
) => {
  return await fetchAPI(`testbuy/get_number_of_articles`, "POST", {
    storeId,
    area,
  });
};

export const fetchReplaceArticle = async (
  testbuyId: string,
  oldArticleId: string,
  shortId: string,
  locale: string,
  storeId: string
) => { 
  return await fetchAPI(`testbuy/basic_article_data`, "POST", {
    testbuyId,
    oldArticleId,
    shortId: [shortId],
    locale,
    storeId,
  });
};
