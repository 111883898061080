import { useQuery } from "@tanstack/react-query";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { CustomLocationsResult } from "../types/customLocationsResultType";
import { TaskType } from "../enums/taskTypes";

async function fetchRecentCustomLocationsForItem(
  itemNo: string,
  taskType: TaskType
) {
  const url = `${TASKS_SERVICE_URL}/v1/locations/custom/item?itemNo=${itemNo}&taskType=${taskType}`;

  const { result: customLocations } = await fetchAPI("GET", url);
  return customLocations as CustomLocationsResult;
}

export function useRecentCustomLocationsForItem(
  itemNo: string,
  taskType: TaskType
) {
  return useQuery({
    enabled: !!itemNo,
    queryKey: ["customlocations", "item", itemNo, taskType],
    queryFn: async () =>
      await fetchRecentCustomLocationsForItem(itemNo, taskType),
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
