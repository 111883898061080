import { useTranslation } from "@coworker/locales";
import { FilterType, FilterValue, InputType } from "../../shared/filters/types";

export function useMyOrTeamTasksFilter(
  id: "myTasks" | "teamTasks",
  valueToSet: string,
  transKey: string,
  filterValues: FilterValue[]
) {
  const { t } = useTranslation();
  const checked =
    filterValues.find((filter) => filter.filterId === "assignedTo")?.value ===
    valueToSet;

  const defaultValue = { filterId: id, value: checked };

  return {
    inputType: InputType.RadioButton,
    filterType: FilterType.Filter,
    id: id,
    title: t(transKey),
    defaultValue,
    enabled: true,
    idToUpdate: "assignedTo",
    valueToSet: valueToSet,
  };
}
