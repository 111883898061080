import { useTranslation } from "@coworker/locales";
import { FilterType, FilterValue, InputType } from "../../shared/filters/types";

const PRIORITY_ONLY_ID = "priority";

export function usePriorityOnlyFilter(filterValues: FilterValue[]) {
  const { t } = useTranslation();
  const checked =
    filterValues.find((filter) => filter.filterId === PRIORITY_ONLY_ID)
      ?.value ?? false;

  const defaultValue = { filterId: PRIORITY_ONLY_ID, value: checked };

  return {
    inputType: InputType.Checkbox,
    filterType: FilterType.Filter,
    id: PRIORITY_ONLY_ID,
    title: t("priorityTasksOnlyString"),
    defaultValue,
    enabled: true,
  };
}
