import { useState, useEffect } from "react";
import { getSignedUrl } from "../services/images.service";
import { useQuery } from "@tanstack/react-query";
import { Image } from "../types/images";
import { QueryKeys } from "./queryKeys";

export const useImagesBySignedUrl = (images: Image[]) => {
  const [signedImageUrls, setSignedImageUrls] = useState<string[]>([]);

  const { data: signedUrlResponse } = useQuery({
    queryKey: [QueryKeys.SignedUrl, images?.map((image) => image.id).join(",")],
    queryFn: () =>
      getSignedUrl(
        (images || []).map((image) => ({
          file_name: image.fileName,
        })),
        "large"
      ),
    gcTime: 1000 * 60 * 15, // 15 minutes, doesn't reload images on every render
    staleTime: 1000 * 60 * 15, // 15 minutes, doesn't refetch images on every render (no new network request)
    enabled: !!images,
  });

  useEffect(() => {
    if (signedUrlResponse && Array.isArray(signedUrlResponse.urls)) {
      setSignedImageUrls(signedUrlResponse.urls);
    }
  }, [signedUrlResponse]);

  return signedImageUrls
    .map((url) => {
      const matchingImage = images?.find((image) =>
        url.includes(image.fileName)
      );
      if (matchingImage) {
        matchingImage.url = url;
        return matchingImage;
      }
      return null;
    })
    .filter((image) => image !== null) as Image[];
};
