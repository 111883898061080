import { useStoreTeams } from "../core/hooks/useStoreTeams";

export function useNameFormatter() {
  const teams = useStoreTeams();

  const teamNameFor = (gid) => {
    const team = teams?.find((team) => team.id === gid);
    return team?.name || "?";
  };
  return { teamNameFor };
}
