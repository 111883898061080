import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
const PopupTitle = styled('h1')`
  font-size: 24px;
  font-weight: bold;
  color: var(--black);
  margin: 80px 0px 16px 28px;
`;
const Information = styled('p')`
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
  margin: 0 28px 30px 28px;
`;
const MultiSelectPopup = () => {
  return (
    <Container>
      <PopupTitle>
        <Trans>multiSeletingPopupTitle</Trans>
      </PopupTitle>
      <Information>
        <Trans>multiSelectPopupInfo1</Trans>
      </Information>
      <Information>
        <Trans>multiSelectPopupInfo2</Trans>
      </Information>
    </Container>
  );
};
export default MultiSelectPopup;
