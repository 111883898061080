import React, { CSSProperties, ReactNode } from "react";
import Pill from "@ingka/pill";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/pill/dist/style.css";
import "@ingka/focus/dist/style.css";

type FixaPillProps = {
  id?: string;
  "data-testid"?: string;
  disabled?: boolean;
  iconPosition?: "leading" | "trailing";
  label?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  size?: "xsmall" | "small" | "medium";
  ssrIcon?: (prefix?: string) => React.SVGProps<SVGElement>[];
  style?: CSSProperties;
  iconOnly?: boolean;
  tabIndex?: number;
};

function FixaPill(props: FixaPillProps) {
  return <Pill {...props} />;
}

export default FixaPill;
