import styled from "styled-components";
import { BallWithCharacter } from "../../Atoms/BallWithCharacter";

const Container = styled('div')`
  display: flex;
  align-items: center;
  padding: 16px 16px 0px 16px;
`;

const HeadlineContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const Headline = styled('h2')`
  font-size: 14px;
  font-weight: bold;
`;

const SubHeadline = styled('h3')`
  font-size: 12px;
  color: gray;
`;

interface CardHeadLineProps {
  character: string;
  headline: string;
  subHeadline?: string;
}

export const CardHeadLine = ({
  character,
  headline,
  subHeadline,
}: CardHeadLineProps) => {
  return (
    <Container>
      <BallWithCharacter character={character} color={"blue-medium"} />
      <HeadlineContainer>
        <Headline>{headline}</Headline>
        {subHeadline && <SubHeadline>{subHeadline}</SubHeadline>}
      </HeadlineContainer>
    </Container>
  );
};
