import { useActiveStoreUsers } from "./useActiveStoreUsers";
import { UserRoles } from "../../enums/user/userRoles";
import { User } from "../../types/user";

function sortUsersByName(user1: User, user2: User) {
  return user1.fullName &&
    user2.fullName &&
    (user1.firstName ?? "") < (user2.firstName ?? "")
    ? -1
    : 1;
}

export const useStoreOwners = () => {
  const { data: activeUsers } = useActiveStoreUsers();
  return activeUsers
    ?.filter((user) => user.roleId === UserRoles.STOREOWNER)
    .sort(sortUsersByName);
};

export const useCountrySuperUsers = () => {
  const { data: activeUsers } = useActiveStoreUsers();
  return activeUsers
    ?.filter((user) => user.roleId === UserRoles.COUNTRY_SUPERUSER)
    .sort(sortUsersByName);
};
