import { useQuery } from "@tanstack/react-query";
import { getGroupsByMedia } from "../services/groups.service";
import { QueryKeys } from "./queryKeys";

export const useGroupsByMediaId = (mediaId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.GroupsByMedia, mediaId],
    queryFn: () => getGroupsByMedia(mediaId || ""),
    enabled: !!mediaId,
  });
};
