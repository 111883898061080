const ports = {
  TASKS_SERVICE: "8081",
  TESTBUY_SERVICE: "8082",
  SHOPPINGTOOLS_SERVICE: "8083",
  MFAQ_SERVICE: "8084",
  CORE_SERVICE: "8085",
  ROOM_SETTINGS_SERVICE: "8086",
};

const servicePaths = {
  TASKS_SERVICE: "tasks-service",
  TESTBUY_SERVICE: "testbuy-service",
  SHOPPINGTOOLS_SERVICE: "shoppingtools-service",
  MFAQ_SERVICE: "mfaq-service",
  CORE_SERVICE: "core-service",
  ROOMSETTINGS_SERVICE: "roomsettings-service",
};

/**
 * @param {string} environmentId GCP project in global, "dev" | "prod" in china
 * @param {keyof typeof servicePaths} service name of the cloud run / ECI container
 * @param {boolean} isChina are we connecting to china or global
 * @returns {string} a base url without ending / so we can append "/v1/..." directly on the returned string.
 */
function baseUrl(environmentId, service, isChina) {
  const isLocal = !!process.env["REACT_APP_LOCAL_" + service];
  if (isLocal) return "http://localhost:" + ports[service];
  if (process.env[service + "_URL"]) return process.env[service + "_URL"];

  // CHINA
  if (isChina) {
    switch (environmentId) {
      case "prod":
        return `https://fixa-api.ingka-internal.cn/cn-s1/${servicePaths[service]}`;
      case "dev":
      default:
        return `https://fixa-api-dev.ingka-dt.cn/cn-s1/${servicePaths[service]}`;
    }
  }

  // GLOBAL
  switch (environmentId) {
    case "ikea-coworker-app-stage":
      return `https://fixa-api-stage.ingka.com/eu-w1/${servicePaths[service]}`;
    case "ikea-coworker-app-prod":
      return `https://fixa-api.ingka.com/eu-w1/${servicePaths[service]}`;
    case "ingka-fixa-as-prod":
      return `https://fixa-api.ingka.com/as-e1/${servicePaths[service]}`;
    case "ingka-fixa-us-prod":
      return `https://fixa-api.ingka.com/us-c1/${servicePaths[service]}`;
    case "ikea-coworker-app-test":
    case "ikea-coworker-app-dev":
    default:
      return `https://fixa-api-dev.ingka.com/eu-w1/${servicePaths[service]}`;
  }
}

/**
 * @param {string} environmentId GCP project in global, "dev" | "prod" in china
 * @param {keyof typeof servicePaths} service name of the cloud run / ECI container
 * @param {boolean} isChina are we connecting to china or global
 * @returns {string} a base url without ending / so we can append "/v1/..." directly on the returned string.
 */
function functionsBaseUrl(environmentId) {
  // https://europe-west1-ikea-coworker-app-stage.cloudfunctions.net/excelExport
  // GLOBAL
  switch (environmentId) {
    case "ikea-coworker-app-stage":
      return `https://europe-west1-ikea-coworker-app-stage.cloudfunctions.net`;
    case "ikea-coworker-app-prod":
      return `https://europe-west1-ikea-coworker-app-prod.cloudfunctions.net`;
    case "ingka-fixa-as-prod":
      return `https://asia-east2-ingka-fixa-as-prod.cloudfunctions.net`;
    case "ingka-fixa-us-prod":
      return `https://us-west3-ingka-fixa-us-prod.cloudfunctions.net`;
    case "ikea-coworker-app-test":
    case "ikea-coworker-app-dev":
    default:
      return `https://europe-west1-ikea-coworker-app-dev.cloudfunctions.net`;
  }
}

/**
 * @param {string} environmentId GCP project in global, "dev" | "prod" in china
 * @param {boolean} isChina are we connecting to china or global
 * @returns {string} a base url without ending /
 */
function shoppingtoolsServiceBaseUrl(environmentId, isChina = false) {
  return baseUrl(environmentId, "SHOPPINGTOOLS_SERVICE", isChina);
}

/**
 * @param {string} environmentId GCP project in global, "dev" | "prod" in china
 * @param {boolean} isChina are we connecting to china or global
 * @returns {string} a base url without ending /
 */
function taskServiceBaseUrl(environmentId, isChina = false) {
  return baseUrl(environmentId, "TASKS_SERVICE", isChina);
}

/**
 * @param {string} environmentId GCP project in global, "dev" | "prod" in china
 * @param {boolean} isChina are we connecting to china or global
 * @returns {string} a base url without ending /
 */
function testbuyServiceBaseUrl(environmentId, isChina = false) {
  return baseUrl(environmentId, "TESTBUY_SERVICE", isChina);
}

/**
 * @param {string} environmentId GCP project in global, "dev" | "prod" in china
 * @param {boolean} isChina are we connecting to china or global
 * @returns {string} a base url without ending /
 */
function mfaqServiceBaseUrl(environmentId, isChina = false) {
  return baseUrl(environmentId, "MFAQ_SERVICE", isChina);
}

/**
 * @param {string} environmentId GCP project in global, "dev" | "prod" in china
 * @param {boolean} isChina are we connecting to china or global
 * @returns {string} a base url without ending /
 */
function coreServiceBaseUrl(environmentId, isChina = false) {
  return baseUrl(environmentId, "CORE_SERVICE", isChina);
}

/**
 * @param {string} environmentId GCP project in global, "dev" | "prod" in china
 * @param {boolean} isChina are we connecting to china or global
 * @returns {string} a base url without ending /
 */
function mediasServiceBaseUrl(environmentId, isChina = false) {
  return baseUrl(environmentId, "ROOMSETTINGS_SERVICE", isChina);
}

module.exports = {
  taskServiceBaseUrl,
  testbuyServiceBaseUrl,
  shoppingtoolsServiceBaseUrl,
  mfaqServiceBaseUrl,
  coreServiceBaseUrl,
  mediasServiceBaseUrl,
  functionsBaseUrl,
};
