import {
  SlidingButton,
  SlidingButtonIcon,
  SlidingButtonText,
} from "../../../SlidingButton";
import { ReactComponent as SearchIcon } from "../../../../assets/svg/icon-search.svg";
import { ReactComponent as ExchangeIcon } from "../../../../assets/svg/exchange-icon.svg";
import { Trans } from "@coworker/locales";
import styled from "styled-components";

const StyledSlidingButtonIcon = styled(SlidingButtonIcon)`
  filter: invert(100%);
  height: 0;
  padding-bottom: 40px;
`;

interface TestBuyArticleCardButtonProps {
  style?: object;
  onClick: Function;
}

export function ChangeWithButton({
  style,
  onClick,
}: TestBuyArticleCardButtonProps) {
  return (
    <SlidingButton
      onClick={onClick}
      style={style || {}}
      background={"var(--black)"}
    >
      <StyledSlidingButtonIcon>
        <SearchIcon />
      </StyledSlidingButtonIcon>
      <SlidingButtonText>
        <Trans>changeWithString</Trans>
      </SlidingButtonText>
    </SlidingButton>
  );
}

export function GetNewButton({
  style,
  onClick,
}: TestBuyArticleCardButtonProps) {
  return (
    <SlidingButton
      onClick={onClick}
      style={{ ...(style || {}), marginLeft: "auto" }}
      background={"var(--blue-medium)"}
    >
      <SlidingButtonIcon>
        <ExchangeIcon />
      </SlidingButtonIcon>
      <SlidingButtonText>
        <Trans>getNewString</Trans>
      </SlidingButtonText>
    </SlidingButton>
  );
}
