import { PropsWithChildren } from "react";
import { GroupFilter } from "../types";
import FixaChevronUpIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronUpIconPath";
import FixaChevronDownIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronDownIconPath";
import FixaPill from "../../wrappers/FixaPill/FixaPill";
import { FixaListBox } from "../../wrappers/FixaListBox/FixaListBox";
import FilterPillCss from "./FilterPill.module.css";

interface PillGroupProps {
  filter: GroupFilter;
  listBoxIsOpen: boolean;
  setListBoxIsOpen: (isOpen: boolean) => void;
}

export const FilterPill = ({
  filter,
  listBoxIsOpen,
  setListBoxIsOpen,
  children,
}: PropsWithChildren<PillGroupProps>) => {
  const { id, title } = filter;
  return (
    <div className={FilterPillCss["dropdown-pill"]}>
      <FixaPill
        id={`fixapill-${id}`}
        data-testid={`fixapill-${id}`}
        iconPosition="trailing"
        ssrIcon={
          listBoxIsOpen ? FixaChevronUpIconPath : FixaChevronDownIconPath
        }
        selected={false}
        label={title}
        onClick={() => {
          setListBoxIsOpen(!listBoxIsOpen);
        }}
        size="small"
      />

      {listBoxIsOpen && (
        <div
          data-testid={`fixapill-backdrop-${id}`}
          className={FilterPillCss["dropdown-backdrop"]}
          onClick={() => {
            setListBoxIsOpen(!listBoxIsOpen);
          }}
        />
      )}

      <FixaListBox
        className={FilterPillCss["dropdown-list"] ?? ""}
        multiple
        open={listBoxIsOpen}
        position="left"
      >
        {children}
      </FixaListBox>
    </div>
  );
};
