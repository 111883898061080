import { useQuery } from "@tanstack/react-query";
import { getMediasAndArticleCount } from "../services/medias.service";
import { QueryKeys } from "./queryKeys";

export const useMediasAndArticleCount = (
  productArticleId: string | undefined,
  storeId: string | undefined
) => {
  return useQuery({
    queryKey: [QueryKeys.MediasAndArticleCount, productArticleId, storeId],
    queryFn: () =>
      getMediasAndArticleCount(productArticleId || "", storeId || ""),
    enabled: !!productArticleId && !!storeId,
  });
};
