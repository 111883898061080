import {ReactNode} from "react";
import Status from "@ingka/status";

import "@ingka/status/dist/style.css";

type FixaStatusProps = {
  statusColor?: "green" | "orange" | "red" | "grey";
  className?: string;
  small?: boolean;
  label?: ReactNode;
  "data-testid"?: string;
};

function FixaStatus(props: FixaStatusProps) {
  return <Status {...props} />;
}

export default FixaStatus;
