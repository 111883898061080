import React from "react";
import { StoreTeam } from "@coworker/types/lib/storeteam";
import { SwitcherSheet } from "./SwitcherSheet";
import { ModalType } from "../../../../layout/context/modalType";

interface SwitchTeamSheetProps {
  teams: StoreTeam[] | undefined;
  selectedTeam: StoreTeam | undefined;
  setSelectedTeam: React.Dispatch<React.SetStateAction<StoreTeam | undefined>>;
  setIsTeamsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}

export const SwitchTeamSheet = (props: SwitchTeamSheetProps) => (
  <SwitcherSheet
    modalType={ModalType.SwitchTeam}
    titleKey="teamString"
    placeholderKey="searchTeamString"
    items={props.teams ?? []}
    selectedItem={props.selectedTeam}
    getItemLabel={(team) => team?.name ?? ""}
    getItemKey={(team) => team?.id ?? ""}
    onSelect={props.setSelectedTeam}
    isDisabled={(teams) => props.setIsTeamsDisabled(teams.length === 0)}
    loading={props.loading}
  />
);
