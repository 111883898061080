import { useQuery } from "@tanstack/react-query";
import { getTaskLinksByMedia } from "../services/tasks.service";
import { QueryKeys } from "./queryKeys";

export const useTaskLinksByMedia = (mediaId: string | undefined) => {
  return useQuery({
    queryKey: [QueryKeys.TaskLinksByMedia, mediaId],
    queryFn: () => getTaskLinksByMedia(mediaId || ""),
    enabled: !!mediaId,
  });
};
