import { PropsWithChildren, useEffect, useRef, useState } from "react";
import FilterRowScrollPanelCss from "./FilterRowScrollPanel.module.css";
import FixaPill from "../../wrappers/FixaPill/FixaPill";
import ChevronLeftIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronLeftIconPath";
import ChevronRightIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronRightIconPath";
import { useTranslation } from "@coworker/locales";

interface FilterRowScrollPanelProps {}

export const FilterRowScrollPanel = ({
  children,
}: PropsWithChildren<FilterRowScrollPanelProps>) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [hasOverflowLeft, setHasOverflowLeft] = useState(false);
  const [hasOverflowRight, setHasOverflowRight] = useState(false);

  const onScroll = () => {
    setHasOverflowLeft(ref && ref.current ? ref.current.scrollLeft > 2 : false);
    setHasOverflowRight(
      ref && ref.current
        ? ref?.current?.scrollWidth -
            ref?.current?.clientWidth -
            ref.current.scrollLeft >
            2
        : false
    );
  };

  useEffect(() => {
    onScroll();
    window.addEventListener("resize", onScroll);
    return () => window.removeEventListener("resize", onScroll);
  }, [ref, children]);

  const scroll = (direction: "left" | "right") => {
    if (
      ref &&
      ref.current &&
      ref.current.scroll &&
      ref.current.scrollLeft !== undefined &&
      ref.current.clientWidth !== undefined
    ) {
      const { scrollLeft, clientWidth } = ref.current;
      ref.current.scroll({
        left:
          scrollLeft + (direction === "right" ? clientWidth : -clientWidth) / 2,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={FilterRowScrollPanelCss["filters-container"]}>
      {hasOverflowLeft && (
        <div
          className={FilterRowScrollPanelCss["filters-row-overlay-arrow-left"]}
        >
          <FixaPill
            ssrIcon={ChevronLeftIconPath}
            iconOnly
            size={"small"}
            onClick={() => {
              scroll("left");
            }}
            aria-label={t("scrollLeftString")}
          />
        </div>
      )}
      {hasOverflowRight && (
        <div
          className={FilterRowScrollPanelCss["filters-row-overlay-arrow-right"]}
        >
          <FixaPill
            ssrIcon={ChevronRightIconPath}
            iconOnly
            size={"small"}
            onClick={() => {
              scroll("right");
            }}
            aria-label={t("scrollRightString")}
          />
        </div>
      )}
      <div
        ref={ref}
        onScroll={onScroll}
        className={FilterRowScrollPanelCss["filters-row-container"]}
      >
        {children}
      </div>
    </div>
  );
};
