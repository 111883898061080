import { Icon, useToastNotification } from "@coworker/components";
import { Trans } from "@coworker/locales";
import React from "react";
import { useInputPopup } from "../../InputPopup";
import { getAssigneesPopup } from "../../TaskForm/common/popup.helpers";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { SUCCESS_TOAST } from "@coworker/components/src/components/ToastNotification";
import { ReactComponent as Quantity } from "../../../assets/svg/quantity.svg";
import { ReactComponent as Location } from "../../../assets/svg/location.svg";
import { ReactComponent as Other } from "../../../assets/svg/issue_other.svg";
import closeReasonTypeOptions from "@coworker/enums/closeReasonTypes";
import { postToTestbuyService } from "../../../hooks/API/testbuy.service";
import { TestBuy } from "@coworker/types";

export const testBuyCloseReasons = [
  {
    icon: Location,
    name: <Trans>tbCloseReasonHFB</Trans>,
    type: closeReasonTypeOptions.HFB,
  },
  {
    icon: Quantity,
    name: <Trans>tbCloseReasonAmount</Trans>,
    type: closeReasonTypeOptions.AMOUNT,
  },
  {
    icon: Other,
    name: <Trans>issueNameOtherString</Trans>,
    type: closeReasonTypeOptions.OTHER,
    descriptionRequired: true,
  },
];

export interface PopupMenuProps {
  id: string | undefined;
}
interface ReassignResult {
  id: string;
}
type InProgressTestbuy = Omit<TestBuy, "articles">;

export function useTestbuyOptionsMenu({ id }: PopupMenuProps): {
  getOptionsMenu: () => void;
  loading: boolean;
} {
  const { getInput } = useInputPopup();
  const { push } = useWorkspacesAction();
  const { showToast } = useToastNotification();
  const [loading, setLoading] = React.useState(false);
  const [assigneePopUpRequested, setAssigneePopUpRequested] =
    React.useState(false);
  const [closeReasonPopUpRequested, setCloseReasonPopUpRequested] =
    React.useState(false);

    const handleAssigneeConfirmation = React.useCallback(async () => {
      const onAssigneeConfirmed = async (assignee: { gid: string; uid: string }) => {
        let userHasTestBuyInProgress = false;
    
        // Get test buy status for assignee
        await postToTestbuyService(
          "testbuy/get_in_progress_testbuy_user",
          { uid: assignee.uid },
          (data: InProgressTestbuy[]) => {
            if (data && data.length > 0) {
              userHasTestBuyInProgress = true;
            }
          }
        );
    
        // If user doesn't have a test buy in progress, assign testbuy
        if (!userHasTestBuyInProgress) {
          setLoading(true);
          await postToTestbuyService(
            "testbuy/update_testbuy_assigned_to",
            { testbuyId: id, assignee: assignee.uid },
            (reassignResult: ReassignResult[]) => {
              if (reassignResult.length > 0) {
                setLoading(false);
                setAssigneePopUpRequested(false);
              }
            }
          );
    
          push("/testbuy");
          showToast(
            <Trans>tbReassigned</Trans>,
            undefined,
            undefined,
            5,
            SUCCESS_TOAST
          );
        } else {
          await getInput("confirmation", {
            question: <Trans>thereWasAnIssueString</Trans>,
            description: <Trans>userAlreadyHasActiveTBString</Trans>,
            positiveText: <Trans>OkString</Trans>,
          });
          setAssigneePopUpRequested(true);
        }
      };
    
      // Triggering assignee popup request if needed
      if (assigneePopUpRequested) {
        setAssigneePopUpRequested(false);
        await getAssigneesPopup({
          getInput,
          onChange: onAssigneeConfirmed,
          lastAssignedTeamId: "",
          disableTeamAssignment: true,
        });
      }
    }, [assigneePopUpRequested, id, getInput, push, showToast]);
    
  React.useEffect(() => {
    handleAssigneeConfirmation();
  }, [assigneePopUpRequested, getInput, id, push, showToast,handleAssigneeConfirmation]);

  // Wrap openCloseReasonPopup in useCallback to avoid excessive re-renders
  const openCloseReasonPopup = React.useCallback(async () => {
    const closeReason = await getInput("selectIssue", {
      title: <Trans>whatString</Trans>,
      content: testBuyCloseReasons,
    });

    if (closeReason && id) {
      setLoading(true);
      postToTestbuyService(
        "testbuy/close_testbuy",
        {
          testbuyId: id,
          reasonType: closeReason.issue,
          ...(closeReason.description && {
            closeReasonDescription: closeReason.description,
          }),
        },
        () => {
          setLoading(false);
        }
      );

      // When transfer is completed, redirect back to TB list.
      push("/testbuy");
      showToast(
        <Trans>tbClosed</Trans>,
        undefined,
        undefined,
        5,
        SUCCESS_TOAST
      );
    }
  }, [getInput, id, push, showToast]);

  React.useEffect(() => {
    if (closeReasonPopUpRequested) {
      setCloseReasonPopUpRequested(false);
      openCloseReasonPopup();
    }
  }, [closeReasonPopUpRequested, openCloseReasonPopup]);

  const actions = [
    {
      onClick: () => setAssigneePopUpRequested(true),
      title: <Trans>reassignString</Trans>,
      enum: "REASSIGN",
      icon: (
        <Icon
          size={16}
          family="actions"
          name="recurring"
          color="grey900"
          className="removeHeight"
        />
      ),
    },
    {
      isHidden: false,
      isDisabled: false,
      onClick: () => setCloseReasonPopUpRequested(true),
      title: <Trans>closeActionString</Trans>,
      enum: "CLOSE",
      icon: (
        <Icon
          size={16}
          family="actions"
          name="close-large"
          color="grey900"
          className="removeHeight"
        />
      ),
    },
  ];
  const getOptionsMenu = () => getInput("popupMenu", { actions });

  return { getOptionsMenu, loading };
}
