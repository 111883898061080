import styled from "styled-components";

const BallWithColor = styled('div')<{ $color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid;
  background-color: var(--${({ $color }) => $color});
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  fontsize: 12px;
  fontweight: 700;
`;

interface BallWithNumberProps {
  character: string;
  color: string;
}

export const BallWithCharacter = ({
  character,
  color,
}: BallWithNumberProps) => {
  return <BallWithColor $color={color}>{character}</BallWithColor>;
};
