import React from "react";
import styled from "styled-components";
import { LocationGroupTitle } from "../LocationGroupTitle";
import { useFetchSlmData } from "../Hooks/useFetchSlmData";
import { filterSlmData } from "./helpers";
import { useTranslation } from "@coworker/locales";
import trackerHelper from "../../../../helpers/tracker";

const SectionName = styled('div')`
  font-weight: bold;
  margin-left: 8px;
  background: var(--grey100);
  padding: 10px 10px;
`;

const NavBarMargin = styled('div')`
  margin-bottom: 120px;
`;

const LocationCard = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 22px;
  background: var(--white);
  border-bottom: 1px solid var(--grey200);
  color: var(--grey718);
  font-size: 14px;
`;
type LocationObject = {
  locationCode: string;
  locationSection: string;
  locationDepartment: string;
  locationCustom?: string;
};
interface SlmSuggestionLocationsProps {
  submitWithConfirmation: (locationObject: LocationObject) => void;
  query: string;
}
const SlmSuggestionLocations = ({
  submitWithConfirmation,
  query,
}: SlmSuggestionLocationsProps) => {
  const { data: allSLMLocations, isFetching } = useFetchSlmData();
  const [filteredSlmData, setfilteredSlmData] = React.useState<string[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isFetching && allSLMLocations) {
      setfilteredSlmData(filterSlmData(allSLMLocations || [], query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, allSLMLocations, isFetching]);

  return (
    <>
      {filteredSlmData.length > 0 && (
        <>
          <SectionName>{t("doYouMeanString")}</SectionName>
          <LocationGroupTitle
            groupTitle={t("suggestedLocationsString")}
            showUnit={false}
          />
        </>
      )}
      {filteredSlmData.map((location: string) => {
        return (
          <LocationCard
            key={location}
            onClick={() => {
              submitWithConfirmation({
                locationCode: location,
                locationSection: "",
                locationDepartment: "",
                locationCustom: location,
              });
              trackerHelper.trackSLMLocationItemClick(location);
            }}
          >
            {location}
          </LocationCard>
        );
      })}
      <NavBarMargin />
    </>
  );
};

export default SlmSuggestionLocations;
