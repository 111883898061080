import { Trans } from "@coworker/locales";
import dayjs from "dayjs";
import {useEffect,useState} from "react";
import styled from "styled-components";

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: auto;
  width: 90%;
`;

const Label = styled('span')`
  font-size: 12px;
  color: var(--grey700);
  margin-right: auto;
  margin-top: 30px;
`;

const StyledInput = styled('input')`
  width: 100%;
  font-size: 16px;
  border-bottom: 1px solid var(--grey500);
  background: var(--white);
`;

export const CustomDate = () => {
  const [startDateState, setStartDateState] = useState(
    localStorage.getItem("shoppingToolsStartDatePeriodFilter") ??
      dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [endDateState, setEndDateState] = useState(
    localStorage.getItem("shoppingToolsEndDatePeriodFilter") ??
      dayjs(new Date()).format("YYYY-MM-DD")
  );

  const startDate = localStorage.getItem("shoppingToolsStartDatePeriodFilter");
  const endDate = localStorage.getItem("shoppingToolsEndDatePeriodFilter");

  useEffect(() => {
    if ((!startDate || !endDate) && startDateState && endDateState) {
      localStorage.setItem(
        "shoppingToolsStartDatePeriodFilter",
        startDateState
      );
      localStorage.setItem("shoppingToolsEndDatePeriodFilter", endDateState);
    }
  }, [startDate, endDate,endDateState,startDateState]);
  

  return (
    <Container>
      <Label>
        <Trans>startDateString</Trans>
      </Label>
      <StyledInput
        type="date"
        value={startDateState}
        max={dayjs(Date.now()).format("YYYY-MM-DD")}
        onChange={(e) => {
          setStartDateState(e.target.value);
          localStorage.setItem(
            "shoppingToolsStartDatePeriodFilter",
            e.target.value
          );
        }}
      />
      <Label>
        <Trans>endDateString</Trans>
      </Label>
      <StyledInput
        type="date"
        value={endDateState}
        min={localStorage.getItem("shoppingToolsStartDatePeriodFilter") ?? ""}
        max={dayjs(Date.now()).format("YYYY-MM-DD")}
        onChange={(e) => {
          setEndDateState(e.target.value);
          localStorage.setItem(
            "shoppingToolsEndDatePeriodFilter",
            e.target.value
          );
        }}
      />
    </Container>
  );
};
