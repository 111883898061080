import {
  FilterValue,
  SelectFilter,
  StringFilterValue,
  SelectOption,
} from "../../types";
import {
  getSelectedFilterValue,
  createFilterValueFromSelectOption,
  updatedFilterValueFromValues,
} from "../../utils";
import { SelectListView } from "../SelectListView";

interface FilterTabSelectProps {
  filter: SelectFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const FilterTabSelect = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
}: FilterTabSelectProps) => {
  const selected = getSelectedFilterValue(
    filter.id,
    filterValues,
    defaultValues
  ) as StringFilterValue;
  const handleOnChange = (option: SelectOption) => {
    const value = createFilterValueFromSelectOption(filter.id, option);
    setFilterValues(updatedFilterValueFromValues(filterValues, filter, value));
  };
  return (
    <SelectListView
      prefix="pill"
      filter={filter}
      selected={selected}
      onChange={handleOnChange}
    />
  );
};
