import React from "react";
import { ActivityFilterType } from "../../../../types/filterOptionsTypes";
import styles from "./activity-filters-panel.module.css";
import { Trans, useTranslation } from "@coworker/locales";
import {
  LocationCategory,
  formatLocationName,
  getLocationsByHfb,
  locationCategories,
  mfaqLocationCategories,
} from "../../helper";
import { FilterListView } from "./FilterListView";
import { useActivityLocations } from "../../../../hooks/useActivityData";
import SkapaTabs from "@coworker/app/src/components/InputPopup/SkapaTabs";
import { getCheckedStatus } from "../../../../helpers/getCheckedStatus";
import { FixaSkeleton } from "../../../../../shared/wrappers/FixaSkeleton/FixaSkeleton";
import { FullTask } from "../../../../types/FullTask";
import FixaSearch from "../../../../../shared/wrappers/FixaSearch/FixaSearch";
import FixaInlineMessage from "../../../../../shared/wrappers/FixaInlineMessage/FixaInlineMessage";
import WarningTrianglePath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/WarningTrianglePath";
import { FixaListView } from "../../../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../../../shared/wrappers/FixaListView/FixaListViewItem";
import { TasksLocationsResult } from "../../../../types/tasksLocationsResultType";

interface LocationFilterProps {
  panelFilters: ActivityFilterType;
  allTasks: FullTask[];
  handleChange: (value: string) => void;
  addonLocationType: string;
  setAddonLocationType: React.Dispatch<React.SetStateAction<string>>;
  noLocationsAvailable: boolean;
}

export const LocationFilter = ({
  panelFilters,
  handleChange,
  addonLocationType,
  setAddonLocationType,
  noLocationsAvailable,
}: LocationFilterProps) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState("");
  const hasTaskType = panelFilters.taskType;
  const isMfaqTask = panelFilters.taskType === "mfaq";
  const isAddonTask =
    panelFilters.taskType === "refill" ||
    panelFilters.taskType === "pickAndRefill";

  const [mappedLocations, setMappedLocations] =
    React.useState<LocationCategory>(getLocationsByHfb([]));

  const { data: taskLocations, isLoading: taskLocationsIsLoading } =
    useActivityLocations(
      panelFilters.taskType,
      isAddonTask ? addonLocationType : ""
    );

    React.useEffect(() => {
      if (!taskLocationsIsLoading && taskLocations) {
        setMappedLocations(getLocationsByHfb(taskLocations as TasksLocationsResult[]));
      }
      return undefined; 
    }, [taskLocationsIsLoading, taskLocations]);
    

  const locationCategoryList = (locationCategory: keyof LocationCategory) => {
    if (taskLocationsIsLoading) {
      return <FixaSkeleton height="100%" />;
    } else {
      if (!mappedLocations) return null;

      const filterListItems = mappedLocations[locationCategory].map(
        (mappedLocation) => {
          return {
            id: formatLocationName(
              t as (key?: string) => string,
              mappedLocation?.location
            ),
            title: formatLocationName(
              t as (key?: string) => string,
              mappedLocation?.location
            ),
            value: mappedLocation?.location,
            control: "checkbox" as const,
            checked: getCheckedStatus(
              mappedLocation?.location,
              isAddonTask,
              addonLocationType,
              panelFilters
            ),
          };
        }
      );

      const normalizeString = (str: string) => {
        return str.toLowerCase().replace(/\s+/g, "").replace(/^0+/, "");
      };

      const filteredListItems = filterListItems.filter((item) =>
        normalizeString(item.id).includes(normalizeString(searchQuery))
      );

      if (!filteredListItems || filteredListItems?.length === 0) {
        return null;
      }

      return (
        <FilterListView
          title={t(`${locationCategory}String`)}
          list={filteredListItems}
          handleChange={handleChange}
          noSeparator
        />
      );
    }
  };

  const tabs = [
    {
      title: (
        <>
          <Trans>pickupLocation</Trans>{" "}
          {panelFilters.pickupLocations.length > 0 &&
            `(${panelFilters.pickupLocations.length})`}
        </>
      ),
      key: "pickup",
    },
    {
      title: (
        <>
          <Trans>refillLocString</Trans>{" "}
          {panelFilters.refillLocations.length > 0 &&
            `(${panelFilters.refillLocations.length})`}
        </>
      ),
      key: "refill",
    },
  ];

  const tabChangedHandler = (tabKey: string) => {
    setAddonLocationType(tabKey);
  };

  const categories = isMfaqTask ? mfaqLocationCategories : locationCategories;

  return (
    <div id="filter-section-locations">
      <div className={styles["separator"]}>
        <FixaListView id="locations" size="small">
          <FixaListViewItem
            key="locations"
            inset
            title={t("locationsString")}
            emphasised
          />
          {noLocationsAvailable && (
            <div className={styles["warning-message"]}>
              <FixaInlineMessage
                title={t("locationsNotAvailableString")}
                variant={"cautionary"}
                ssrIcon={WarningTrianglePath}
              />
            </div>
          )}
          {hasTaskType && !noLocationsAvailable && (
            <div className={styles["search-wrapper"]}>
              <FixaSearch
                id="handoff-search"
                placeholder={t("searchBasicString")}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onClear={() => {
                  setSearchQuery("");
                }}
              />
            </div>
          )}
          {isAddonTask && (
            <div className={styles["location-tabs"]}>
              <SkapaTabs
                tabs={tabs}
                active={addonLocationType}
                onTabChanged={tabChangedHandler}
                noTabPanelPadding
              />
            </div>
          )}
          {hasTaskType ? (
            categories.map((locationCategory, i) => (
              <div key={i}>{locationCategoryList(locationCategory)}</div>
            ))
          ) : (
            <div className={styles["warning-message"]}>
              <FixaInlineMessage
                title={t("selectTaskTypeToFilterByLocationString")}
                variant={"cautionary"}
                ssrIcon={WarningTrianglePath}
              />
            </div>
          )}
        </FixaListView>
      </div>
    </div>
  );
};
