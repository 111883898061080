import styled from "styled-components";
import { Trans } from "@coworker/locales";

export const Tag = styled('span')`
  margin: 0 6px;
  background: var(--grey200);
  border-radius: 2px;
  padding: 0 4px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: var(--grey900);
`;

const Explanation = styled('span')`
  font-size: 14px;
  line-height: 1.29;
  color: var(--grey700);
`;

export function LocationTag({
  showText,
  type,
}: {
  showText?: boolean;
  type: "primary" | "home";
}) {
  return (
    <div>
      <Tag>{type === "home" ? "H" : "P"}</Tag>
      {showText && (
        <Explanation>
          {type === "home" ? (
            <Trans>homeBaseString</Trans>
          ) : (
            <Trans>primaryLocationString</Trans>
          )}
        </Explanation>
      )}
    </div>
  );
}
