import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PartialMedia, PartialMediaWithId } from "../types/media";
import { updateMedia } from "../services/medias.service";
import { QueryKeys } from "./queryKeys";

export const useUpdateMedia = (mediaId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartialMedia) => updateMedia(mediaId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.Media, mediaId],
      });
    },
  });
};

export const useUpdateMediaWithId = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (partialMedia: PartialMediaWithId) =>
      updateMedia(partialMedia.id, partialMedia),
    onSuccess: (_response, partialMedia) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.Media, partialMedia.id],
      });
    },
  });
};
