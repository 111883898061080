import { useMemo } from "react";
import {
  MainAlternativeProducts,
  Media,
} from "../components/RoomSettings/types/media";
import { MediaTypes } from "../components/RoomSettings/constants";
import { removeAccents } from "../helpers/utils";

export const useMatchingMedias = (
  medias: Media[] | undefined,
  query: string | undefined
) => {
  const matchingMedias = useMemo(() => {
    if (medias && medias.length && query) {
      const sanitizedQuery = removeAccents(
        query.toLowerCase().replace(/[.,\s]/g, "")
      );
      return medias.filter((media) => {
        const sanitizedMediaName = removeAccents(media.name.toLowerCase());
        if (media.mediaType !== MediaTypes.COMPACT) {
          const mainAlternativeProducts =
            media as unknown as MainAlternativeProducts;
          const sanitizedMainProductName1 = removeAccents(
            (mainAlternativeProducts?.mainProductName1 ?? "").toLowerCase()
          );
          const sanitizedMainProductName2 = removeAccents(
            (mainAlternativeProducts?.mainProductName2 ?? "").toLowerCase()
          );
          return (
            sanitizedMediaName.includes(sanitizedQuery) ||
            sanitizedMainProductName1.includes(sanitizedQuery) ||
            sanitizedMainProductName2.includes(sanitizedQuery)
          );
        }
        return sanitizedMediaName.includes(sanitizedQuery);
      });
    } else {
      return undefined;
    }
  }, [medias, query]);

  return matchingMedias;
};
