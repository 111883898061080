import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchAPI } from "@coworker/app/src/hooks/API/tasks.service.helper";
import { CORE_SERVICE_URL } from "@coworker/app/src/core/hooks/useAllStores";

type Data = {
  uid: string;
  countryId: string;
  storeId: string;
  teamId: string;
};

async function updateUser(uid: string, data: Data) {
  const path = `users/${uid}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await fetchAPI("PUT", url, {
    fixaUid: uid,
    countryId: data.countryId,
    storeId: data.storeId,
    teamId: data.teamId,
  });
}

export const useUpdateUser = (uid: string, onlyTeamChanged: boolean) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Data) => updateUser(uid, data),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["user", uid],
      });
      if (!onlyTeamChanged) {
        await sessionStorage.clear();
        await localStorage.clear();
      }
      window.location.assign("/home");
    },
  });
};
