import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PartialGroup } from "../types/groups";
import { updateGroup } from "../services/groups.service";
import { QueryKeys } from "./queryKeys";

export const useUpdateGroup = (groupId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartialGroup) => updateGroup(groupId, data),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.Group, groupId],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.GroupsByMedia, groupId],
        }),
      ]);
    },
  });
};
