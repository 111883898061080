import styled from "styled-components";
import { Trans } from "@coworker/locales";

const Warning = styled('div')<{ $centerText: boolean }>`
  font-size: 14px;
  font-weight: normal;
  color: var(--error);
  margin-bottom: 6px;
  text-align: ${({ $centerText }) => ($centerText ? "center" : "left")};
`;

interface PleaseFillInProps {
  show: boolean;
  alternateWarning?: string;
  centerText?: boolean;
  testId?: string;
}

export function PleaseFillIn({
  show,
  alternateWarning = "",
  centerText = false,
  testId,
}: PleaseFillInProps) {
  const WarningText = () =>
    !alternateWarning ? (
      <Trans>pleaseFillInString</Trans>
    ) : (
      <Trans>{alternateWarning}</Trans>
    );

  return show ? (
    <Warning $centerText={!!centerText} data-testid={testId}>
      <WarningText />
    </Warning>
  ) : (
    <></>
  );
}
