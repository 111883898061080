
import { FixaListViewItem } from "@coworker/apprestructured/src/shared/wrappers/FixaListView/FixaListViewItem";
import CalendarIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CalendarIconPath";
import { Trans, useTranslation } from "@coworker/locales";
import profilePreferences from "@coworker/enums/profilePreferences";
import Instructions from "../../Instructions";
import { useInputPopup } from "../../InputPopup";
import { ReactComponent as ExpeditTag } from "../../../assets/svg/EXPEDIT-tag.svg";
import { ReactComponent as DateStampMarker } from "../../../assets/svg/date-stamp-marker.svg";
import { Article } from "../types/article";
import { Text } from "../Atoms/Text";
import { Overlay } from "../styles/styles";
import { useUpdateArticle } from "../hooks/useUpdateArticle";

const { DISABLE_POPUPS_DATE_STAMP } = profilePreferences;

interface DateStampProps {
  article: Article;
  value: string;
}

export const DateStamp = ({ article, value }: DateStampProps) => {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();
  const updateArticle = useUpdateArticle(article.id);

  const getDateStamp = async () => {
    const response = await getInput("number", {
      value,
      bigTitle: <Trans>dateStampString</Trans>,
      instructions: (
        <Instructions
          title={<Trans>whereCanIfindString</Trans>}
          text={
            <Overlay minHeight="133px">
              <ExpeditTag />
              <DateStampMarker />
            </Overlay>
          }
          type={DISABLE_POPUPS_DATE_STAMP}
        />
      ),
    });
    if (response) {
      try {
        await updateArticle.mutateAsync({ dateStamp: response });
      } catch (error) {
        alert(t("searchErrorString"));
      }
    }
  };

  const addOnContent = value ? <Text text={value} grey /> : "";

  return (
    <FixaListViewItem
      title={t("dateStampString")}
      leadingIcon={CalendarIconPath}
      addOn={addOnContent}
      control={"navigational"}
      controlIcon={"chevronRightSmall"}
      onClick={getDateStamp}
      emphasised
      inset
    />
  );
};
