import { Trans } from "@coworker/locales";
import {
  useGivenName,
  useTeamId,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { EnvironmentTag } from "@coworker/app/src/components/EnvironmentTag";
import { QuickActions } from "../QuickActions/QuickActions";
import { TasksPreview } from "../TasksPreview/TasksPreview";
import styles from "./home-page.module.css";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { FixaText } from "../../../shared/wrappers/FixaText/FixaText";
import { DiscoverNow } from "../DiscoverNow/DiscoverNow";
import { FixaWindow } from "@coworker/reusable";
import { useMyStore } from "@coworker/app/src/core/auth/useLoggedInUser";

export const HomePage = () => {
  const teamId = useTeamId();
  const { replace } = useWorkspacesAction();
  if (!teamId) {
    replace("/teamselection");
  }

  const store = useMyStore();
  const firstname = useGivenName();

  return (
    <div className={styles["home-outer-container"]}>
      <div className={styles["home-container"]}>
        <div className={styles["vertical-center"]}>
          <FixaText textStyle="Heading.L" tagName="h2">
            {<Trans>hejString</Trans>} {firstname},
            {store?.storeId && store?.name && (
               <FixaText tagName="p" textStyle="Body.M">
              <div>
                {store.storeId} - {store.name}
              </div>
                </FixaText>
            )}
          </FixaText>
          <EnvironmentTag />
        </div>
        <div className={styles["quick-actions-container"]}>
          <QuickActions id="quickActionsHome" />
        </div>
        {(window as FixaWindow).enableDiscoverNow && <DiscoverNow />}
        <TasksPreview />
      </div>
    </div>
  );
};
