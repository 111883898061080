import styled, { css } from "styled-components";

export const Tip = styled('div')`
  color: var(--white);
  background-color: #03030390;
  border-radius: 5px;
  margin: 15px;
  padding: 5px;
  text-align: center;
  ${({ scanner }) =>
    scanner
      ? css`
          background-color: #33000090;
        `
      : css`
          background-color: #00003390;
        `}
`;
