import { useTranslation } from "@coworker/locales";
import { SelectFilter, SelectItem } from "../types";

// Adds an all option to a SelectFilter.
// This is the same as no option set.
export function useCustomAllSelectOption(
  selectFilter: SelectFilter,
  allLabel: string
) {
  const options: SelectItem[] = [
    { value: "", title: allLabel },
    ...selectFilter.options,
  ];
  return { ...selectFilter, options };
}

export function useAllSelectOption(selectFilter: SelectFilter) {
  const { t } = useTranslation();
  return useCustomAllSelectOption(
    selectFilter,
    t("selectOptionAllString", { title: selectFilter.title })
  );
}
