import FixaPill from "../../../wrappers/FixaPill/FixaPill";
import { Filter, FilterValue } from "../../types";
import PillRadioButtonCss from "./PillRadioButton.module.css";

interface PillRadioButtonProps {
  filter: Filter;
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const PillRadioButton = ({
  filter,
  filterValues,
  setFilterValues,
}: PillRadioButtonProps) => {
  const checked =
    filterValues.find((value) => value.filterId === filter.idToUpdate)
      ?.value === filter.valueToSet;

  const handleRadioButtonClick = () => {
    const updatedFilters = filterValues.filter(
      (value) => value.filterId !== filter.idToUpdate
    );

    setFilterValues(
      checked
        ? updatedFilters
        : [
            ...updatedFilters,
            {
              filterId: filter.idToUpdate ?? "",
              value: filter.valueToSet ?? "",
            },
          ]
    );
  };

  return (
    <FixaPill
      id={filter.id}
      size="small"
      label={
        <div className={PillRadioButtonCss["pill-wrapper"]}>
          {filter.title}
          <input
            className={PillRadioButtonCss["radio-button"]}
            type="radio"
            checked={checked}
          />
        </div>
      }
      onClick={handleRadioButtonClick}
    />
  );
};
