import React from "react";
import styles from "./task-card.module.css";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import { useTranslation } from "@coworker/locales";
import PeopleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PeopleIconPath";
import PersonIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PersonIconPath";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import taskStateOptions from "@coworker/enums/taskState";
import { useAllStoreUsers } from "../../../shared/hooks/users/useAllStoreUsers";
import { SimpleLoadingBall } from "../../../shared/simple/SimpleLoading/SimpleLoading";
import { User } from "../../../shared/types/user";

interface TeamNameProps {
  state: string;
  assignee: string;
}

export const Assignee = ({ state, assignee }: TeamNameProps) => {
  const { t } = useTranslation();

  const isTaskOpen = state === taskStateOptions.UNASSIGNED;

  const teams = useStoreTeams();
  const { data: users, isLoading: isLoadingUsers } = useAllStoreUsers();

  const displayName = React.useMemo(() => {
    if (isTaskOpen) {
      return (
        teams.find((team) => team.id === assignee)?.name ??
        t("unknownTeamString")
      );
    } else {
      const user = users?.find(
        (user: User) => user.fixaUid === assignee || user.uid === assignee
      );
      return user
        ? `${user.firstName} ${user.lastName}`
        : t("unknownUserString");
    }
  }, [isTaskOpen, teams, users, assignee, t]);

  return (
    <React.Fragment>
      {isLoadingUsers && (
        <SimpleLoadingBall text={t("loadingEllipsisString")} size="medium" />
      )}
      {!isLoadingUsers && (
        <div className={styles["assignee-wrapper"]}>
          <FixaSSRIcon
            className={styles["assignee-icon"] as string}
            paths={isTaskOpen ? PeopleIconPath : PersonIconPath}
          />
          <div className={styles["assignee"]}>{displayName}</div>
        </div>
      )}
    </React.Fragment>
  );
};
