import React, { useRef } from "react";
import { useUploadImageToCloud } from "../hooks/useUploadImageToCloud";
import { ImageUploadResponse } from "../types/images";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import PlusIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PlusIconPath";
import * as Sentry from "@sentry/react";
import imageCompression from "browser-image-compression";

interface UploadImageButtonProps {
  text?: string;
  onImageUploadStart: (fileList: FileList) => void | undefined;
  onImageUploadEnd: (fileList: ImageUploadResponse[]) => void;
}

export const UploadImageButton = ({
  text,
  onImageUploadStart,
  onImageUploadEnd,
}: UploadImageButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const uploadImageToCloud = useUploadImageToCloud();
  const fileList: ImageUploadResponse[] = [];

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    onImageUploadStart(event.target.files);

    for (const fileUploaded of event.target.files) {
      if (!fileUploaded) continue;

      // Resize the image to 1000x1000
      const options = {
        maxWidthOrHeight: 1000,
      };
      const resizedImage = await imageCompression(fileUploaded, options);

      const uploadedImage = await Sentry.startSpan(
        { name: "media.uploadImageToCloud" },
        async () => {
          return await uploadImageToCloud.mutateAsync(resizedImage);
        }
      );
      if (uploadedImage) fileList.push(uploadedImage);
    }

    onImageUploadEnd(fileList);
  };

  const handleButtonClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <>
      {text ? (
        <FixaButton
          type={"primary"}
          onClick={handleButtonClick}
          loading={uploadImageToCloud.status === 'pending'}
          text={text ?? ""}
        />
      ) : (
        <FixaButton
          type={"secondary"}
          onClick={handleButtonClick}
          loading={uploadImageToCloud.status === 'pending'}
          ssrIcon={PlusIconPath}
          iconOnly
        />
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        multiple
      />
    </>
  );
};
