import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addImageToMedia } from "../services/images.service";
import { QueryKeys } from "./queryKeys";

export const useAddImageToMedia = (mediaId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addImageToMedia,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [QueryKeys.ImagesByMedia, mediaId],
      });
    },
  });
};
