import { FormEventHandler, ReactNode } from "react";
import RadioButtonGroup from "@ingka/radio-button-group";

import "@ingka/forms/dist/style.css";
import "@ingka/focus/dist/style.css";

export type FixaRadioButtonGroupListItem = {
  checked: boolean;
  id: string;
  label: ReactNode;
  name: string;
  readOnly: boolean;
  "data-testid"?: string;
};

type FixaRadioButtonGroupProps = {
  list: FixaRadioButtonGroupListItem[];
  subtle?: boolean;
  onChange: FormEventHandler<HTMLFieldSetElement>;
  "data-testid"?: string;
};

function FixaRadioButtonGroup(props: FixaRadioButtonGroupProps) {
  return <RadioButtonGroup {...props} />;
}

export default FixaRadioButtonGroup;
