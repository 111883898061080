import { useMemo } from "react";
import { useTranslation } from "@coworker/locales";
import {
  FilterType,
  FilterValue,
  InputType,
  MultiSelectFilter,
  SelectItem,
  StringFilterValue,
} from "../../shared/filters/types";
import { TASK_TYPE_ID } from "./useTaskTypeFilter";
import { useActivityLocations } from "./useActivityData";
import {
  formatLocationName,
  getLocationsByHfb,
  locationCategories,
} from "../components/filters/helper";
import { TasksLocationsResult } from "../types/tasksLocationsResultType";

export const REFILL_LOCATIONS_ID = "refillLocations";

export function useRefillLocationFilter(
  filterValues: FilterValue[]
): MultiSelectFilter {
  const { t } = useTranslation();
  const defaultValue = {
    filterId: REFILL_LOCATIONS_ID,
    value: [],
  };

  const taskTypeValue = filterValues.find(
    ({ filterId }) => filterId === TASK_TYPE_ID
  );
  const taskTypeValueValue = (taskTypeValue as StringFilterValue)?.value ?? "";
  const isRefillLocationsAvailable =
    taskTypeValueValue === "refill" || taskTypeValueValue === "pickAndRefill";

  const enabled = isRefillLocationsAvailable;
  const { data: taskLocations } = useActivityLocations(
    taskTypeValueValue,
    "refill"
  );
  const mappedTaskLocations = useMemo(() => {
    if (taskLocations) {
      return getLocationsByHfb(taskLocations as TasksLocationsResult[]);
    }
    return null;
  }, [taskLocations]);
  const options: SelectItem[] =
    mappedTaskLocations !== null
      ? locationCategories
          .map((key) => {
            const category = mappedTaskLocations[key];
            if (category.length) {
              return [
                { title: t(`${key}String`) },
                category.map((locationOption) => ({
                  title: formatLocationName(
                    t as (key?: string) => string,
                    locationOption.location
                  ),
                  value: locationOption.location,
                  dependsOnFilterId: TASK_TYPE_ID,
                })),
              ];
            } else {
              return [];
            }
          })
          .flat(2)
      : [];

  return {
    inputType: InputType.MultiSelect,
    filterType: FilterType.Filter,
    id: REFILL_LOCATIONS_ID,
    title: t("refillLocString"),
    options,
    enabled,
    defaultValue,
    showSearch: true,
  };
}
