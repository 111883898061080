import tracker from "../../helpers/tracker";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/enums/profilePreferences";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import * as mfaqRepository from "./BackendApiRepository/MfaqRepository";
import { aggregateVotes, getMFAQFollowUpTasks } from "./helper";
import { useQuery } from "@tanstack/react-query";

const { SHOW_QUESTIONS_WITH_TASKS, MFAQ_PREFIX } = profilePreferences;

export function useGetOtherQuestions(since, until, area, from) {
  const [onlyQuestionsWithTasks] = useUserPreference(
    MFAQ_PREFIX + SHOW_QUESTIONS_WITH_TASKS,
    false
  );

  const store_id = useStoreId();

  const {
    data: questions,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [
      "questions",
      store_id,
      area,
      since,
      until,
      from,
      onlyQuestionsWithTasks,
    ],
    queryFn: () =>
      mfaqRepository.getAllQuestions(
        store_id,
        area,
        since,
        until,
        from,
        onlyQuestionsWithTasks
      ),
    staleTime: 0, // No stale time here since newly added questions should be shown immediately
    retry: 1, // retry once
  });

  return { data: questions, isLoading, isError, error };
}

export function useGetTopQuestions(since, until, area) {
  const [onlyQuestionsWithTasks] = useUserPreference(
    MFAQ_PREFIX + SHOW_QUESTIONS_WITH_TASKS,
    false
  );
  const store_id = useStoreId();

  const {
    data: questions,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [
      "questions",
      store_id,
      area,
      since,
      until,
      onlyQuestionsWithTasks,
    ],
    queryFn: () =>
      mfaqRepository.getAllQuestions(
        store_id,
        area,
        since,
        until,
        0,
        onlyQuestionsWithTasks
      ),

    staleTime: 0,
    retry: 1,
  });

  return { data: questions, isLoading, isError, error };
}

export function useMFAQ(question_id, since, until) {
  const { data: question } = useQuery({
    queryKey: ["question", question_id, since, until],
    queryFn: () => {
      if (!question_id) return Promise.resolve(undefined);
      return mfaqRepository.getQuestionById(question_id, since, until);
    },
    enabled: !!question_id,
    staleTime: 0,
    retry: 1,
  });
  return question;
}

/**
 * @param {string} searchTerm
 * @param {string?} questionType
 * @param {string?} area
 * @returns {Promise<any[]>}
 */
export async function searchMFAQ(
  searchTerm,
  questionType,
  area,
  store_id,
  from
) {
  return await mfaqRepository.searchQuestions(searchTerm, store_id, area, from);
}

export async function searchMFAQWithSearchTerm(
  searchTerm,
  questionType,
  area,
  store_id,
  from
) {
  const matchingQuestions = await searchMFAQ(
    searchTerm,
    questionType,
    area,
    store_id,
    from
  );
  return { matchingQuestions, searchTermSearched: searchTerm };
}

export async function createVote(question_id, value, vote_location, store_id) {
  const result = mfaqRepository.createVote(
    question_id,
    store_id,
    vote_location,
    value
  );
  tracker.MFAQ.trackVote(question_id, store_id, value, vote_location);
  return result.data;
}

export async function updateQuestionRequest(questionId, questionText, subject) {
  await mfaqRepository.updateQuestion(questionId, questionText);
}

export async function mergeQuestionRequest(questionId, secondQuestionId) {
  const newQuestionId = await mfaqRepository.mergeQuestions(
    questionId,
    secondQuestionId
  );
  return newQuestionId;
}

export async function createQuestion(storeId, area, questionText) {
  await mfaqRepository.createQuestion(storeId, area, questionText);
}

export function useGetAggregatedVotes(
  question_id,
  interval,
  startDate,
  endDate,
  area,
  isWholeStore
) {
  const fetchVotes = () =>
    mfaqRepository.getVotesByQuestionById(
      question_id,
      area,
      startDate,
      endDate,
      isWholeStore
    );

  const { data: votes, isLoading } = useQuery({
    queryKey: ["votes", question_id, area, startDate, endDate, isWholeStore],
    queryFn: fetchVotes,
    staleTime: 0,
  });

  const data = votes ? aggregateVotes(votes, interval) : [];

  return { loading: isLoading, data };
}

export function useGetTasksOnQuestion(
  question_id,
  interval,
  startDate,
  endDate
) {
  const fetchQuestion = () =>
    mfaqRepository.getQuestionById(question_id, startDate, endDate);

  const { data: question, isLoading } = useQuery({
    queryKey: ["question", question_id, startDate, endDate],
    queryFn: fetchQuestion,
    enabled: !!question_id && !!startDate && !!endDate,
  });

  const data = question ? getMFAQFollowUpTasks(question.tasks) : [];

  return { loading: isLoading, data };
}
