import React from "react";
import { Trans } from "@coworker/locales";
import AvatarImage from "../../AvatarImage";
import {
  DetailSection,
  DetailSectionRight,
  DetailSectionLeft,
  SectionText,
  SectionTitle,
} from "./_common";
import { useAllStoreUsers } from "@coworker/apprestructured/src/shared/hooks/users/useAllStoreUsers";
import { SimpleLoadingBall } from "@coworker/apprestructured/src/shared/simple/SimpleLoading/SimpleLoading";
import { useTranslation } from "@coworker/locales";

function Creator({ task, title }) {
  const { t } = useTranslation();
  const { data: users, isLoading: isLoadingUsers } = useAllStoreUsers();
  const creator_name = React.useMemo(() => {
    const user = users?.find(
      (user) => user.fixaUid === task.creator_id || user.uid === task.creator_id
    );
    return user ? (
      `${user.firstName} ${user.lastName}`
    ) : (
      <Trans>unknownUserString</Trans>
    );
  }, [users, task.creator_id]);
  return (
    <React.Fragment>
      {isLoadingUsers && (
        <SimpleLoadingBall text={t("loadingEllipsisString")} size="medium" />
      )}
      {!isLoadingUsers && (
        <DetailSection>
          <DetailSectionLeft>
            <SectionTitle>
              {title || <Trans>createdByString</Trans>}
            </SectionTitle>
            <SectionText data-testid="taskCreator">{creator_name}</SectionText>
          </DetailSectionLeft>
          <DetailSectionRight>
            <AvatarImage uid={task.creator_id} />
          </DetailSectionRight>
        </DetailSection>
      )}
    </React.Fragment>
  );
}

export default Creator;
