import styled, { css } from "styled-components";

export const Widget = styled('div')`
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version for Chrome and Opera */
  pointer-events: ${(props) => props.uneditable && "none"};
  & * {
    ${(props) =>
      props.uneditable &&
      `
      color: var(--disabled);
      font-weight: 700;`}
  }
  ${(props) =>
    props.uneditable &&
    `
        .optional {
          display: none;
        }
      `}
  ${({ last }) =>
    last &&
    css`
      border-bottom: 1px solid var(--grey150);
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      touch-action: none;
      opacity: 0.6;
    `}
`;
