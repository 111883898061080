import styled from "styled-components";
import { Trans } from "@coworker/locales";

const SectionBar = styled('div')`
  min-height: 60px;
  background: var(--grey100);
  font-size: 18px;
  color: var(--grey900);
  line-height: 60px;
  padding: ${(props) => props.overridePadding || "0 24px"};
  font-weight: 700;
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ conf: { titleTranslationKey = "", overridePadding } }) => {
  return (
    <SectionBar overridePadding={overridePadding}>
      <Trans>{titleTranslationKey}</Trans>
    </SectionBar>
  );
};
