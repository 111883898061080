import { callToFetch } from "./shared.service";
import { perform, getQueryIdentifierValue } from "./tasks.service.helper";
import { coreServiceBaseUrl } from "@coworker/app/src/core/cloud-services/cloud-services.helper";
import { isChineseEnvironment, getEnvironmentId } from "@coworker/reusable";
import { makeServiceHeaders } from "../../core/hooks/fetch.helpers";
import { deepCopyAndConvertToSnakeCase } from "@coworker/reusable/helpers/objectAccess";

const isChina = isChineseEnvironment();

const CORE_SERVICE_URL = coreServiceBaseUrl(getEnvironmentId(), isChina);

/***
 * @returns A promise which resolves when the request is done.
 * If you care about results then use the callback.
 */

export async function fetchUserAvatar(fixaUid) {
  const params = {
    method: "GET",
    headers: await makeServiceHeaders(),
  };
  const response = await fetch(
    `${CORE_SERVICE_URL}/users/avatar/${fixaUid}`,
    params
  );
  if (!response.ok) {
    throw new Error("Bad network response when fetching user avatar!");
  }
  const contentType = response.headers.get("Content-Type");
  if (contentType && contentType.includes("application/json")) {
    throw new Error("Expected a Blob but received JSON");
  }
  const blob = await response.blob();
  return blob;
}

export async function getStoreUsers(storeId) {
  const path = `users/store/${encodeURIComponent(storeId)}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getActiveStoreUsers(storeId) {
  const path = `users/active/store/${encodeURIComponent(storeId)}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getStoreTeams(storeId) {
  const path = `teams/store/${encodeURIComponent(storeId)}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getStoresByCountryId(countryId) {
  const path = `stores/country/${encodeURIComponent(countryId)}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getCountries() {
  const path = "countries/";
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getDeployedVersion() {
  const path = `metadata/deployed-version`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getFocusTile(userLanguage) {
  const path = `upptacka-internal/focus-tiles/${userLanguage}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getUserFixaUidsForTeamId(teamId) {
  const path = `users/teamid/${encodeURIComponent(teamId)}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getStoreContacts(storeId) {
  const path = `stores/store-contacts/store/${encodeURIComponent(storeId)}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  const result = await callToFetch("GET", url);
  return deepCopyAndConvertToSnakeCase(result);
}

export async function getUserPermissions(fixaUid) {
  const path = `users/${fixaUid}/permissions`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

export async function getStoreById(storeId) {
  const path = `stores/${encodeURIComponent(storeId)}`;
  const url = `${CORE_SERVICE_URL}/${path}`;
  return await callToFetch("GET", url);
}

function findCoreServiceEndpoint(method) {
  let [which, query] = method.split("?");
  let storeId = getQueryIdentifierValue(query, "storeId");
  let fixaUid = getQueryIdentifierValue(query, "fixa_uid");
  switch (which) {
    case "teams":
      return [`teams/store/${encodeURIComponent(storeId)}`, "GET"];
    case "my/store":
      return [`stores/${encodeURIComponent(storeId)}`, "GET"];
    case "metadata/global_config":
      return [`globalConfig`, "GET"];
    case "update/user":
      return [`users/${fixaUid}`, "PUT"];
    case "users":
      return [`users/store/${encodeURIComponent(storeId)}`, "GET"];
    case "contacts":
      return [`store-contacts/store/${encodeURIComponent(storeId)}`, "GET"];
    default:
      return [method, "GET"];
  }
}

export async function fetchFromCoreService(method, bodyData) {
  const [path, httpVerb, adjustedBody] = findCoreServiceEndpoint(
    method,
    bodyData
  );
  return callCoreService(path, adjustedBody || bodyData, httpVerb || "GET");
}

export async function callCoreService(path, bodyData = {}, httpVerb = "GET") {
  const url = `${CORE_SERVICE_URL}/${path}`;
  switch (path) {
    case "globalConfig":
      return { data: { data: { flags: ["global_flag"] } } };
    default:
      return new Promise((resolve) =>
        perform(httpVerb, url, bodyData, resolve, "")
      );
  }
}
