import { FilterValue } from "../../types";
import { useUserPreference } from "@coworker/app/src/hooks/useProfilePreferencesQuery";
import { filterValuesAreTheSame } from "../filter";

export function useUserPreferenceStoreFilterValues(featureName: string) {
  const [filterValues, setFilterValues] = useUserPreference<FilterValue[]>(
    featureName,
    []
  );

  const storeFilterValues = (values: FilterValue[]) => {
    if (!filterValuesAreTheSame(filterValues, values)) {
      setFilterValues(values);
    }
  };

  return { filterValues, setFilterValues: storeFilterValues };
}
