import {useMemo} from "react";
import { Trans } from "@coworker/locales";
import styled, { css } from "styled-components";
import { RankItemProps } from "./RankItemProps";
import { usePeriodTimestamps } from "../../../InputPopup/UserFiltersRow/usePeriodTimestamps";
import { useUserPreference } from "../../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/enums/profilePreferences";
import useFormatter from "../../../../hooks/useFormatter";
import { defaultFilters } from "../../../InputPopup/UserFiltersRow/filterChoices";
import { DecoratedItem } from "./DecoratedItem";
import { LoaderIcon } from "@coworker/reusable/Loader";

const Container = styled('div')`
  padding-top: 16px;
`;

const GrayText = styled('div')`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--grey718);
`;

const Rankings = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95%;
  margin: 10px auto 0px auto;
  height: 180px;
`;

const Rank = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2.5rem;
  width: 60px;
  ${(p: { value: number | undefined }) =>
    !p.value &&
    css`
      opacity: 0;
    `}
`;

const RankNumber = styled('div')`
  font-weight: bold;
  font-size: 28px;
  display: flex;
  align-items: flex-end;
`;

const Dot = styled('div')`
  font-size: 26px;
`;

const Description = styled('div')`
  font-size: 12px;
  width: 90px;
  text-align: center;
`;

function formatHFB(hfb: number | undefined) {
  return `HFB ${hfb}`;
}

function RankItem({ hfb, value, rank, decorated }: RankItemProps) {
  return (
    <>
      {decorated ? (
        <DecoratedItem rank={rank} hfb={hfb} value={value} />
      ) : (
        <Rank value={value}>
          <RankNumber>
            {rank}
            <Dot>.</Dot>
          </RankNumber>
          <Description>{formatHFB(hfb) + " - " + value}</Description>
        </Rank>
      )}
    </>
  );
}

interface RankingShowcaseProps {
  ahead: RankItemProps;
  active: RankItemProps;
  behind: RankItemProps;
  isWholeStoreActive: boolean;
  offsetIntoCounts: number;
  totalTestbuysDone: number;
}

export function RankingShowcase({
  ahead,
  active,
  behind,
  isWholeStoreActive,
  offsetIntoCounts,
  totalTestbuysDone,
}: RankingShowcaseProps) {
  const { formatDateRange } = useFormatter();

  const [filters] = useUserPreference(
    profilePreferences.TESTBUY_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );

  const [sinceTs, untilTs] = usePeriodTimestamps(
    filters,
    profilePreferences.TESTBUY_PREFIX
  );

  const customDate = formatDateRange(sinceTs || 0, untilTs || 0);

  const period = useMemo(() => {
    if (filters[0] === "period=7days") {
      return "last7DaysString";
    } else if (filters[0] === "period=4weeks") {
      return "last4WeeksString";
    } else {
      return customDate;
    }
  }, [filters, customDate]);

  const isRankingAvailable =
    !!totalTestbuysDone && !isWholeStoreActive && offsetIntoCounts !== -1;

  return (
    <Container>
      {isRankingAvailable && (
        <>
          <GrayText>
            <Trans>testBuysDoneString</Trans>
          </GrayText>
          <GrayText>
            <Trans>{period}</Trans>
          </GrayText>
        </>
      )}
      <Rankings>
        {isRankingAvailable ? (
          <>
            <RankItem {...ahead} />
            <RankItem decorated {...active} />
            <RankItem {...behind} />
          </>
        ) : (
          <LoaderIcon />
        )}
      </Rankings>
    </Container>
  );
}
