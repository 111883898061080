import taskStateOptions from "@coworker/enums/taskState";
import taskTypeOptions from "@coworker/enums/taskType";
import {
  RELATED_TASKS_MAX_CLOSED,
  RELATED_TASKS_MAX_FINISHED,
  AVeryLargeDate,
} from "@coworker/consts/time";
import { taskStatusFilterTypes } from "@coworker/types/lib/taskStatusFilterTypes";
import { closedReasonFilterTypes } from "@coworker/types/lib/closedReasonFilterTypes";
import { hasChangedQuantity } from "./taskHelper";
import { taskFilters } from "../constants/filters";
import { nowAsISOWithOffsetInDays } from "./dates";

const DAY = 24 * 3600 * 1000;
const WEEK = 7 * DAY;

function areaFromGridcode(location_grid_code) {
  // TODO: This should probably be improved.
  return location_grid_code?.slice?.(0, 3);
}

function isMyTeam(item, { team_id }) {
  return item.assigned_team_id && item.assigned_team_id === team_id;
}

function isNotAssignedToAUser(item) {
  return (
    item.state === taskStateOptions.UNASSIGNED &&
    item.assigned_team_id &&
    !item.assigned_user_id
  );
}

function isOpenOrAssigned(item) {
  return [taskStateOptions.UNASSIGNED, taskStateOptions.ASSIGNED].includes(
    item.state
  );
}

function finishedMaxAWeekAgo(item) {
  // TODO: When Tasks Service is live only do string comparison
  if (typeof item.finished_at === "string") {
    return (
      item.finished_at &&
      item.finished_at < nowAsISOWithOffsetInDays(WEEK / DAY)
    );
  }

  return item.finished_at && item.finished_at + WEEK > new Date().getTime();
}

function isActive(item) {
  return (
    (finishedMaxAWeekAgo(item) &&
      [taskStateOptions.CLOSED, taskStateOptions.COMPLETED].includes(
        item.state
      )) ||
    isOpenOrAssigned(item)
  );
}

function createdByMe(item, { uid, fixa_uid }) {
  return (
    isActive(item) &&
    item.creator_id &&
    (item.creator_id === uid || item.creator_id === fixa_uid)
  );
}

function isInMyTasks(item, { uid, team_id, fixa_uid }) {
  return (
    item.assigned_user_id &&
    (item.assigned_user_id === uid || item.assigned_user_id === fixa_uid) &&
    isMyTeam(item, { uid, team_id }) &&
    item.state === taskStateOptions.ASSIGNED
  );
}

function isInOpenTasks(item, { uid, team_id }) {
  return isNotAssignedToAUser(item) && isMyTeam(item, { uid, team_id });
}

function inProgress(item, { uid, team_id }) {
  return (
    isMyTeam(item, { uid, team_id }) &&
    item.assigned_user_id &&
    item.state === taskStateOptions.ASSIGNED
  );
}

function createdByMeInProgress(item, { uid, team_id, fixa_uid }) {
  return (
    createdByMe(item, { uid, team_id, fixa_uid }) &&
    item.assigned_user_id &&
    item.state === taskStateOptions.ASSIGNED
  );
}

function planned(item, { uid, team_id }) {
  return (
    isMyTeam(item, { uid, team_id }) &&
    item.task_type === taskStateOptions.PLANNED &&
    isOpenOrAssigned(item)
  );
}

function createdByMePlanned(item, { uid, team_id, fixa_uid }) {
  return (
    createdByMe(item, { uid, team_id, fixa_uid }) &&
    item.task_type === taskStateOptions.PLANNED &&
    isOpenOrAssigned(item)
  );
}

function isCompleted(item) {
  return item.state === taskStateOptions.COMPLETED;
}

function isClosed(item) {
  return item.state === taskStateOptions.CLOSED;
}

function createdByMeCompleted(item, { uid, team_id, fixa_uid }) {
  return (
    createdByMe(item, { uid, team_id, fixa_uid }) &&
    isCompleted(item) &&
    finishedMaxAWeekAgo(item)
  );
}

function createdByMeClosed(item, { uid, team_id, fixa_uid }) {
  return (
    createdByMe(item, { uid, team_id, fixa_uid }) &&
    isClosed(item) &&
    finishedMaxAWeekAgo(item)
  );
}

function createdByMyTeamCompleted(item, { uid, team_id }) {
  return (
    isMyTeam(item, { uid, team_id }) &&
    isCompleted(item) &&
    finishedMaxAWeekAgo(item)
  );
}

function createdByMyTeamClosed(item, { uid, team_id }) {
  return (
    isMyTeam(item, { uid, team_id }) &&
    isClosed(item) &&
    finishedMaxAWeekAgo(item)
  );
}

function createdByMeOpen(item, { uid, team_id, fixa_uid }) {
  return (
    createdByMe(item, { uid, team_id, fixa_uid }) && isNotAssignedToAUser(item)
  );
}

let completedByMeCount = 0;
export function getCompletedByMeCount() {
  return completedByMeCount;
}
export function prepareToCelebrateWithAnAnimation() {
  completedByMeCount += 1;
}
export function resetPrepareToCelebrateWithAnAnimation() {
  completedByMeCount = 0;
}

function allOngoing(item) {
  return ["ASSIGNED", "UNASSIGNED"].includes(item.state);
}

const now = Date.now();
function recentlyFinished(item) {
  // TODO: When Tasks Service is live only do string comparison
  if (typeof item.finished_at === "string") {
    return (
      item.finished_at >
      nowAsISOWithOffsetInDays(-RELATED_TASKS_MAX_FINISHED / DAY)
    );
  }

  return item.finished_at > now - RELATED_TASKS_MAX_FINISHED;
}

function allClosed(item) {
  return taskStateOptions.CLOSED === item.state && recentlyFinished(item);
}

function allCompleted(item) {
  return taskStateOptions.COMPLETED === item.state && recentlyFinished(item);
}

export function getFilterFunction(type) {
  switch (type) {
    case "my":
      return isInMyTasks;
    case "open":
      return isInOpenTasks;
    case "created_by_me":
      return createdByMe;
    case "in_progress":
      return inProgress;
    case "planned":
      return planned;
    case "completed":
      return createdByMyTeamCompleted;
    case "closed":
      return createdByMyTeamClosed;

    case "all_ongoing":
      return allOngoing;
    case "all_closed":
      return allClosed;
    case "all_completed":
      return allCompleted;

    case "created_by_me_open":
      return createdByMeOpen;
    case "created_by_me_planned":
      return createdByMePlanned;
    case "created_by_me_in_progress":
      return createdByMeInProgress;
    case "created_by_me_completed":
      return createdByMeCompleted;
    case "created_by_me_closed":
      return createdByMeClosed;
    case "related_product_tasks":
      return relatedTasks;
    default:
  }
}

// Directly exported special-purpose sort functions

export function notClosedTooLongAgo(task) {
  // TODO: When Tasks Service is live only do string comparison
  if (typeof task.finished_at === "string") {
    return (
      !task.finished_at ||
      task.finished_at === AVeryLargeDate ||
      task.finished_at >
        nowAsISOWithOffsetInDays(-RELATED_TASKS_MAX_CLOSED / DAY)
    );
  }

  return (
    !task.finished_at ||
    task.finished_at === Number.MAX_SAFE_INTEGER ||
    task.finished_at > Date.now() - RELATED_TASKS_MAX_CLOSED
  );
}

/***
 * @param {String} shortId An 8 digit product id.
 */
export function relatedTasks(task, _user, shortId) {
  return (
    shortId &&
    task.task_type !== taskTypeOptions.PRODUCT_QUALITY &&
    task.state !== taskStateOptions.COMPLETED &&
    notClosedTooLongAgo(task) &&
    task.product_article_id === shortId
  );
}

export function userFilters(task, filter) {
  if (!Array.isArray(filter)) {
    return true;
  }
  if (filter.includes(taskFilters.REFILL)) {
    return task.task_type === taskTypeOptions.ADDON && !!task.picked;
  } else if (filter.includes(taskFilters.PICK_AND_REFILL)) {
    return task.task_type === taskTypeOptions.ADDON && !task.picked;
  } else if (filter.includes(taskFilters.PRODUCT_ISSUE)) {
    return task.task_type === taskTypeOptions.PRODUCT_ISSUE;
  } else if (filter.includes(taskFilters.TESTBUY_FOLLOW_UP)) {
    return task.task_type === taskTypeOptions.TESTBUY_FOLLOW_UP;
  } else if (filter.includes(taskFilters.MFAQ_FOLLOW_UP)) {
    return task.task_type === taskTypeOptions.MFAQ_FOLLOW_UP;
  } else if (filter.includes(taskFilters.CUSTOM)) {
    return task.task_type === taskTypeOptions.CUSTOM;
  } else if (filter.includes(taskFilters.RECURRING)) {
    return task.task_type === taskTypeOptions.PLANNED;
  } else if (filter.includes(taskFilters.PRODUCT_QUALITY)) {
    return task.task_type === taskTypeOptions.PRODUCT_QUALITY;
  } else if (filter.includes(taskFilters.OTHER)) {
    return (
      task.task_type !== taskTypeOptions.PRODUCT_ISSUE &&
      task.task_type !== taskTypeOptions.PRODUCT_QUALITY &&
      task.task_type !== taskTypeOptions.ADDON
    );
  } else if (filter.includes(taskFilters.ROOM_SETTINGS)) {
    return task.task_type === taskTypeOptions.ROOM_SETTINGS;
  } else {
    // Let all through for taskFilters.ALL | undefined
    return true;
  }
}

export function isRefillOrPickAndRefill(filters) {
  if (!Array.isArray(filters)) {
    return false;
  }

  return (
    filters.includes(taskFilters.PICK_AND_REFILL) ||
    filters.includes(taskFilters.REFILL)
  );
}

export function taskStatusFilter(task, filter) {
  const refill = hasChangedQuantity(task);

  if (filter === taskStatusFilterTypes.COMPLETED_WITH_CHANGES) {
    return refill;
  } else if (filter === taskStatusFilterTypes.COMPLETED_WITHOUT_CHANGES) {
    return !refill;
  } else return true;
}

export function taskStateFilter(task, filter) {
  switch (filter) {
    case "ongoing":
      return ["ASSIGNED", "UNASSIGNED"].includes(task.state);

    case "recent":
      return finishedMaxAWeekAgo(task);

    case "all":
    default:
      return true;
  }
}

export function closedReasonFilter(task, filter) {
  if (!filter || filter === closedReasonFilterTypes.ALL_CLOSED_REASONS) {
    return true;
  }
  return task.close_type === filter;
}

/**
 * @param {Task} task
 * @param {teamId} teamId
 * @returns {Boolean} true if task is to be shown, false if not.
 */
export function teamFilter(task, teamId) {
  return !teamId || task.assigned_team_id === teamId;
}

export const markethallRegEx = new RegExp(/^M\d{2}/i);
export const showroomRegEx = new RegExp(/^S\d{2}/i);
export const warehouseRegEx = new RegExp(/^\d{6}/i);

/**
 * @param {Task} task
 * @param {string[]} filter Filtering chosen by user for a specific location
 * @param {string[]} locationGroup Showroom,markethall,warehouse,other
 * @param {string[]} addonOption Which location field to use for location filtering
 * @param {string[]} taskFilters  Used to decide if location filtering should be done
 * @returns {Boolean} true if task is to be shown
 */
export function userLocationFilters(
  task,
  filter,
  locationGroup,
  addonOption,
  taskFilters
) {
  if (!filter?.length && !locationGroup?.length) {
    return true;
  }
  if (filter.includes("allLocationsString")) return true;

  const fieldsToCompare = getRelevantLocationFields(addonOption, taskFilters);
  const [comparatorExpressions, comparatorNegatorExpressions] =
    getComparatorExpressions(filter, locationGroup);

  return filterLocations(
    task,
    fieldsToCompare,
    comparatorExpressions,
    comparatorNegatorExpressions
  );
}

/**
 * Get list of fields that should match location
 * @param {string[]} addonOption Which location field to use for location filtering
 * @param {string[]} taskFilters  Used to decide if location filtering should be done
 * @returns {string[]}
 */
export function getRelevantLocationFields(addonOption, taskFilters) {
  if (
    taskFilters.includes("product") ||
    addonOption.includes("refillFilter") ||
    taskFilters.includes("refill")
  ) {
    return ["location", "location_custom"];
  } else if (addonOption.includes("pickingFilter")) {
    return ["pickup_location", "pickup_location_custom"];
  } else {
    return [
      "location",
      "location_custom",
      "pickup_location",
      "pickup_location_custom",
    ];
  }
}

/**
 * Build up comparator expressions
 * @param {string[]} filter Filtering chosen by user for a specific location
 * @param {string[]} locationGroup Showroom,markethall,warehouse,other
 * @returns {[RegExp[],RegExp[]]}
 */
function getComparatorExpressions(filter, locationGroup) {
  if (locationGroup.includes("other")) {
    return [[], [markethallRegEx, showroomRegEx, warehouseRegEx]];
  }

  if (filter.includes("allMarkethallString")) {
    return [[markethallRegEx], []];
  } else if (filter.includes("allShowroomString")) {
    return [[showroomRegEx], []];
  } else if (locationGroup.includes("warehouse")) {
    return [[warehouseRegEx], []];
  } else {
    return [
      filter.map((code) => new RegExp("^" + areaFromGridcode(code), "g")),
      [],
    ];
  }
}

/**
 * Helper for userLocationFilters
 * @param {Task} task
 * @param {string[]} fieldsToCompare
 * @param {RegExp[]} comparatorExpressions
 * @param {RegExp[]} comparatorNegatorExpressions
 * @returns {Boolean} true if task is to be shown
 */
function filterLocations(
  task,
  fieldsToCompare,
  comparatorExpressions,
  comparatorNegatorExpressions
) {
  // Filter out any forbidden any tasks using negator expressions
  if (comparatorNegatorExpressions.length > 0) {
    return fieldsToCompare.every((field) => {
      return comparatorNegatorExpressions.every((comparator) => {
        // Check that either field is empty or field does not match negation comparator
        return !task[field] || !task[field].match(comparator);
      });
    });
  }

  if (!comparatorExpressions.length) return true;

  // Verify that some field is defined and matches
  return fieldsToCompare.some((field) =>
    comparatorExpressions.some(
      (comparator) => task[field] && task[field].match(comparator)
    )
  );
}

export function onlyTasksFilter(tasks) {
  return tasks?.filter((t) => !!t.task_type);
}
