import { MediaTypes } from "../../constants";
import { Articles } from "../../types/article";
import { Media, Medias, RoomSettingsMedia } from "../../types/media";

export const EmptyRoomSettingsMedia: RoomSettingsMedia = {
  mediaType: MediaTypes.ROOM_SETTINGS,
  createdAt: "",
  updatedAt: "",
  id: "",
  storeId: "",
  name: "",
  styleGroup: "",
  hfLtp: "",
  pricing: "",
  active: "ACTIVE",
  nbrArticles: 0,
  mainProductId1: "",
  mainProductId2: "",
  mainProductName1: "",
  mainProductName2: "",
  livingSituation: "",
  laha: "",
  livingConditions: "",
  hfb: "",
  alternativeProductId1: "",
  alternativeProductId2: "",
  alternativeProductName1: "",
  alternativeProductName2: "",
  mainMessage: "",
};

export const checkMediaName = (media: Media, medias: Medias) => {
  const found = medias.find(
    (mediaFromList) =>
      media.name === mediaFromList.name && media.id !== mediaFromList.id
  );

  if (found) return true;

  return false;
};

export const checkEmptyMediaName = (media: Media) => {
  const isMediaNameEmpty = !media.name;
  if (isMediaNameEmpty) return true;

  return false;
};

export const validationMessage = (
  media: Media,
  medias: Medias,
  t: (key: string, options?: any) => string
) => {
  if (checkMediaName(media, medias)) {
    return t(`nameAlreadyExistsString`, {
      name: media.name,
    });
  } else if (checkEmptyMediaName(media)) {
    return t("nameCannotBeEmptyString");
  }
  return "";
};

export const getArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article ? article.itemName : "";
};

export const getFullArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article
    ? `${article.productArticleId}: ${article.itemName}, ${article.itemType}`
    : "";
};
