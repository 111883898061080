
import styled from "styled-components";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import NoticeRedSmallIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/NoticeRedSmallIconPath";

const WarningTextWithIconContainer = styled('div')`
  display: flex;
  align-items: center;
  color: #e00751;
  gap: 5px;
  font-size: 14px;
`;

interface WarningTextWithIconProps {
  text: string;
}

export const WarningTextWithIcon = ({ text }: WarningTextWithIconProps) => {
  return (
    <WarningTextWithIconContainer>
      <FixaSSRIcon paths={NoticeRedSmallIconPath} />
      <p>{text}</p>
    </WarningTextWithIconContainer>
  );
};
