// Vendor
import React from "react";
import { Trans } from "@coworker/locales";
// Enums
import {
  MFAQ_QUESTION_WIDGET,
  PRIORITY_WIDGET,
  ASSIGN_WIDGET,
  DESCRIPTION_WIDGET,
  PHOTOS_WIDGET,
  MFAQ_GRAPH_CARD_WIDGET,
} from "./_common";
import { useWorkspacesState } from "../../../hooks/useWorkspaces";
import { TaskType } from "@coworker/apprestructured/src/tasks/enums/taskTypes";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  table: "tasks",
  type: "mfaq_followup",
  enumType: TaskType.MFAQ_FOLLOW_UP,
  validate: (form) => {
    const potentialInvalids = [
      !form.assigned_team_id,
      !form.question_id,
      !form.description,
      form.images?.some((image) => image.loading),
    ];
    return !potentialInvalids.some((invalid) => invalid);
  },
  fieldsFilledCount: (form) => {
    let count = 0;
    if (form.assigned_team_id) count++;
    if (form.description) count++;
    return count;
  },
  header: {
    newTitle: <Trans>mfaqFollowUpTitle</Trans>,
    editTitle: "",
    testId: "mfaqFollowUpHeaderTitle",
  },
  fields: [
    MFAQ_QUESTION_WIDGET,
    DESCRIPTION_WIDGET(),
    ASSIGN_WIDGET,
    PRIORITY_WIDGET,
    PHOTOS_WIDGET,
    MFAQ_GRAPH_CARD_WIDGET,
  ],
  useReactToChanges: function (taskId, task, onChange) {
    const { navigationState } = useWorkspacesState() || {};
    const filters = navigationState?.question?.question_current_filters;
    if (!filters?.[0].startsWith("period=")) filters?.reverse();
    React.useEffect(() => {
      if (navigationState.question) {
        onChange({
          question_text: navigationState?.question?.question_text,
          question_id: navigationState?.question?.question_id,
          question_area: filters[1],
        });
      }
      //  eslint-disable-next-line
    }, []);
  },
};
