import React from "react";
import { Media, Pricing } from "../../types/media";
import { useTranslation } from "@coworker/locales";
import {
  FixaOption,
  FixaSelect,
} from "@coworker/apprestructured/src/shared/wrappers/FixaSelect/FixaSelect";
import StairsIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/StairsIconPath";
import { getPricingDataOptions } from "../../../../helpers/pricingDataOptions";

interface PricingSelectProps {
  media: Media;
  onSave: (newMedia: Media) => void;
}

export const PricingSelect = ({ media, onSave }: PricingSelectProps) => {
  const { t } = useTranslation();
  return (
    <FixaSelect
      id={"pricing"}
      label={t("priceLevelString")}
      value={(media as Pricing).pricing ?? ""}
      ssrIcon={StairsIconPath}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const newPricing =
          event.target.selectedIndex === 0 ? "" : event.target.value;
        const newMedia = { ...media };
        (newMedia as Pricing).pricing = newPricing;
        onSave(newMedia);
      }}
    >
      {getPricingDataOptions().map((pricing) => (
        <FixaOption {...pricing} key={pricing.value} />
      ))}
    </FixaSelect>
  );
};
