import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import Instructions from "../Instructions";
import profilePreferences from "@coworker/enums/profilePreferences";
import FixaSearch from "@coworker/apprestructured/src/shared/wrappers/FixaSearch/FixaSearch";
import { useTeamId, useUserId } from "../../core/auth/useLoggedInUser";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";
import { AssignToUserOrTeam } from "@coworker/apprestructured/src/shared/components/AssignToUserOrTeam/AssignToUserOrTeam";

const SearchContainer = styled('div')`
  margin: 24px;
`;

const Handoff = ({ onSubmit, assignees, onClose }) => {
  const userId = useUserId();
  const teamId = useTeamId();
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = React.useState("");

  const appBarActions = [
    {
      name: "close",
      icon: <FixaSSRIcon paths={CrossIconPath} />,
      onClick: () => onClose(),
      position: "right",
    },
  ];

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("handoffRefillString"),
        actions: appBarActions,
      }}
      noPadding
    >
      <Instructions
        title={<Trans>handoffString</Trans>}
        text={<Trans>handoffTextString</Trans>}
        type={profilePreferences.DISABLE_POPUPS_HANDOFF}
      />
      <SearchContainer>
        <FixaSearch
          id="handoff-search"
          placeholder={t("searchBasicString")}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onClear={() => {
            setSearchQuery("");
          }}
        />
      </SearchContainer>
      <AssignToUserOrTeam
        searchQuery={searchQuery}
        showSuggestions={false}
        itemNo={""}
        assignees={assignees}
        myUserAndTeamId={{ userId: userId, teamId: teamId }}
        onSubmit={onSubmit}
        handoff
      />
    </FullScreenPopup>
  );
};

export default Handoff;
