import React from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "@coworker/locales";
import { useInputPopup } from "../InputPopup";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { updateUser } from "../../services/user.service";
import { usePhone, useFixaUID } from "../../core/auth/useLoggedInUser";
import { PositiveNumberKeypad } from "../PositiveNumberKeypad";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";

const Container = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 80vh;
`;
const InputWrapper = styled("div")`
  margin: 33px 29px 16px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95px;
`;
const Text = styled("div")`
  font-size: 12px;
  color: var(--grey700);
  margin: 6px 0;
`;
const Remove = styled("div")`
  font-size: 14px;
  color: var(--grey900);
  margin: 8px 29px;
  text-decoration: underline;
  cursor: pointer;
`;
const StyledInputField = styled(FixaInputField)`
  width: 100%;
`;

const PhoneNumbers = () => {
  const fixaUid = useFixaUID();
  const phone = usePhone() || "";
  const [number, setNumber] = React.useState(phone);
  const { pop } = useWorkspacesAction();
  const { t } = useTranslation();

  const { getInput } = useInputPopup();
  const deletePhone = async () => {
    const userConfirmation = await getInput("confirmation", {
      question: t("removeThisNumberQuestion"),
      description: t("surelyRemoveNumber"),
      positiveText: t("removeString"),
      negativeText: t("cancelString"),
      onClose: () => null,
    });
    if (userConfirmation) {
      await updateUser(fixaUid, { phone: "" });
      pop();
    } else {
      setNumber(phone);
    }
  };

  return (
    <FullScreenPopup
      noPadding
      linkbar={<Linkbar currentLabel={t("ikeaPhoneNumber")} currentAsH1 />}
    >
      <Container>
        <InputWrapper>
          <StyledInputField
            id={"phoneInput"}
            type="text"
            label={
              phone ? (
                <Trans>ikeaPhoneNumber</Trans>
              ) : (
                <Trans>addPhoneString</Trans>
              )
            }
            required
            value={number}
            disabled
            onChange={() => null}
            maxLength={15}
          />
          <Text>
            <Trans>enterYourPhoneForTodayString</Trans>
          </Text>
        </InputWrapper>
        <PositiveNumberKeypad
          disabled={
            !number ||
            number.trim().length === 0 ||
            number === phone ||
            number.split("").some((s) => s.match(/[a-z]/i))
          }
          initialValue={number}
          onUpdate={(value) => setNumber(value.trim().slice(0, 15))}
          onComplete={() => {
            updateUser(fixaUid, { phone: number });
            pop();
          }}
          completeTestid="addPhoneButton"
        />
        {phone && (
          <Remove data-testid="removeNumber" onClick={() => deletePhone()}>
            <Trans>removeThisNumberString</Trans>
          </Remove>
        )}
      </Container>
    </FullScreenPopup>
  );
};

export default PhoneNumbers;
