import React, { createRef, useContext, useEffect, useState } from "react";
import styles from "./switcher-sheet.module.css";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaModalBody } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalBody";
import {
  ModalContext,
  modalStateKeyMap,
} from "../../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../../layout/context/modalType";
import { useTranslation } from "@coworker/locales";
import FixaSearch from "../../../../shared/wrappers/FixaSearch/FixaSearch";
import { FixaListView } from "../../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../../shared/wrappers/FixaListView/FixaListViewItem";
import { FixaSkeleton } from "../../../../shared/wrappers/FixaSkeleton/FixaSkeleton";

interface SwitcherSheetProps<T> {
  modalType: ModalType;
  titleKey: string;
  placeholderKey: string;
  items: T[];
  selectedItem?: T;
  getItemLabel: (item: T) => string;
  getItemKey: (item: T) => string;
  onSelect: (item: T) => void;
  isDisabled?: (items: T[]) => void;
  loading: boolean;
}

export function SwitcherSheet<T>({
  modalType,
  titleKey,
  placeholderKey,
  items,
  selectedItem,
  getItemLabel,
  getItemKey,
  onSelect,
  isDisabled,
  loading,
}: SwitcherSheetProps<T>) {
  const { modalState, toggleModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const searchInputRef = createRef<HTMLInputElement>();
  const [query, setQuery] = useState("");

  useEffect(() => {
    const key = modalStateKeyMap[modalType];
    if (modalState[key]) setQuery("");
  }, [modalState, modalType]);

  useEffect(() => {
    if (isDisabled) isDisabled(items);
  }, [items, isDisabled]);

  const filteredItems = items.filter((item) =>
    getItemLabel(item).toLowerCase().includes(query.toLowerCase())
  );

  return (
    <FixaModal
      visible={modalState[modalStateKeyMap[modalType]]}
      handleCloseBtn={() => toggleModal({ type: modalType })}
    >
      <FixaSheets
        alignment="right"
        preserveAlignment={false}
        size="small"
        fullSize
        header={
          <>
            <FixaModalHeader
              title={t(titleKey)}
              className={styles["header"] as string}
              floating={false}
              closeBtnClick={() => toggleModal({ type: modalType })}
            />
            {loading ? (
              <div className={styles["search-wrapper"]}>
                <FixaSkeleton width="100%" height="50px" />
              </div>
            ) : (
              <div className={styles["search-wrapper"]}>
                <FixaSearch
                  id={`search-${modalType}`}
                  ref={searchInputRef}
                  placeholder={t(placeholderKey)}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onClear={() => setQuery("")}
                />
              </div>
            )}
          </>
        }
        footer={null}
      >
        <FixaModalBody className={styles["modal-body"] as string}>
          {loading ? (
            <div className={styles["skeleton-wrapper"]}>
              <FixaSkeleton width="100%" height="24px" />
              <FixaSkeleton width="100%" height="24px" />
              <FixaSkeleton width="100%" height="24px" />
              <FixaSkeleton width="100%" height="24px" />
              <FixaSkeleton width="100%" height="24px" />
            </div>
          ) : (
            <FixaListView
              id={modalType.toString()}
              size="small"
              showDivider={false}
            >
              {filteredItems.map((item) => (
                <FixaListViewItem
                  key={getItemKey(item)}
                  id={getItemKey(item)}
                  control="radiobutton"
                  onChange={() => {
                    onSelect(item);
                    toggleModal({ type: modalType });
                  }}
                  inset
                  title={getItemLabel(item)}
                  size="small"
                  controlProps={{
                    checked: getItemKey(item) === getItemKey(selectedItem!),
                    value: getItemLabel(item),
                  }}
                />
              ))}
            </FixaListView>
          )}
        </FixaModalBody>
      </FixaSheets>
    </FixaModal>
  );
}
