import { getDeployedVersion } from "@coworker/app/src/hooks/API/core.service";
import { useQuery } from "@tanstack/react-query";

export function useDeployedVersion() {
  return useQuery({
    queryKey: ["metadata", "deployedVersion"],
    queryFn: async () => await fetchDeployedVersion(),
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export async function fetchDeployedVersion() {
  const { deployed_version } = await getDeployedVersion();
  return deployed_version ?? "";
}
