import React from "react";
import styled from "styled-components";
import { useInputPopup } from "../InputPopup";
import Avatar from "../Avatar/Avatar";
import { Trans, useTranslation } from "@coworker/locales";
import { useUserId } from "../../core/auth/useLoggedInUser";
import { useMyTeam } from "../../core/hooks/useStoreTeams";
import { FixaSwitch } from "@coworker/apprestructured/src/shared/wrappers/FixaSwitch/FixaSwitch";
import { BasicOption } from "@coworker/components/src/components/Option";
import {
  iOSSafari,
  blockBrowserScannerKey,
  getLocalDeviceConfig,
  setLocalDeviceConfig,
} from "@coworker/common/helpers/devices";
import { isRDTDevice } from "@coworker/reusable";
import { blocksBrowserScanner } from "../Scanning/useBrowserScanner";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import {
  useCountrySuperUsers,
  useStoreOwners,
} from "@coworker/apprestructured/src/shared/hooks/users/useStoreSuperUsers";

const Empty = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 125px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey500);
  text-align: center;
  width: 100%;

  & > p {
    max-width: 130px;
  }
`;
const Message = styled('div')`
  margin: 28px;
`;
const MessageTitle = styled('div')`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;
const MessageBody = styled('div')`
  font-size: 14px;
  margin-bottom: 18px;
`;
const GraySpacer = styled('div')`
  background: var(--grey100);
  font-size: 14px;
  font-weight: bold;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 28px;
`;
const Well = styled('div')`
  padding: 0 29px;
`;
const TeamMate = styled('div')`
  box-sizing: border-box;
  height: 150px;
  padding: 0 29px;
  color: var(--grey900);
  background: var(--white);
  text-decoration: none;
  border-bottom: 1px solid var(--grey200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 35px 23px 25px 23px;
`;
const Info = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  width: 100%;
  height: 100%;
`;
const AvatarWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 122px;
  height: 40px;
`;
const Name = styled('p')`
  font-weight: bold;
`;
const Email = styled('p')`
  color: var(--grey700);
`;

const Support = () => {
  const currentUserId = useUserId();
  const myteam = useMyTeam();
  const { getInput } = useInputPopup();
  const { t } = useTranslation();
  const [disableBrowserScanner, setDisableBrowserScanner] = React.useState(
    getLocalDeviceConfig(blockBrowserScannerKey)
  );

  const getSuperUser = async (user) =>
    await getInput("user", {
      title: "",
      user: user,
      content: [],
      teamName: myteam?.name,
    });

  const storeOwners = useStoreOwners();
  const storeSuperUsers = storeOwners?.filter(
    ({ uid, fixaUid }) => uid !== currentUserId || fixaUid !== currentUserId
  );
  const countryOwners = useCountrySuperUsers();
  const countrySuperUsers = countryOwners?.filter(
    ({ uid, fixaUid }) => uid !== currentUserId || fixaUid !== currentUserId
  );

  const categories = [
    { nameTranslationKey: "storeSuperusersString", users: storeSuperUsers },
    { nameTranslationKey: "countrySuperusersString", users: countrySuperUsers },
  ];

  return (
    <FullScreenPopup
      noPadding
      linkbar={<Linkbar currentLabel={t("appSupportString")} currentAsH1 />}
    >
      <Message>
        <MessageTitle>
          <Trans>needString</Trans>
        </MessageTitle>
        <MessageBody data-testid="supportmessage">
          <p>
            <Trans>support2021MessageString</Trans>
          </p>
          <br />
          <p>
            <Trans>supportMessageString</Trans>
          </p>
        </MessageBody>
      </Message>
      {categories.map((category) => (
        <React.Fragment key={category.nameTranslationKey}>
          <GraySpacer>
            <Trans>{category.nameTranslationKey}</Trans>
          </GraySpacer>
          {category.users?.length > 0 ? (
            category.users.map((user) => {
              return (
                <TeamMate
                  data-testid={`${user.uid ?? user.fixaUid}`}
                  key={user.uid ?? user.fixaUid}
                  onClick={() => getSuperUser(user)}
                >
                  <Info>
                    <Name>
                      {user.firstName} {user.lastName}
                    </Name>
                    <Email>{user.email}</Email>
                  </Info>
                  <AvatarWrapper>
                    <Avatar uid={user.fixaUid} />
                  </AvatarWrapper>
                </TeamMate>
              );
            })
          ) : (
            <Empty>
              <p>
                <Trans>membersString</Trans>
              </p>
            </Empty>
          )}
        </React.Fragment>
      ))}
      {!iOSSafari() && (
        <React.Fragment>
          <GraySpacer>
            <Trans>supportSettingsString</Trans>
          </GraySpacer>
          {!iOSSafari() && !isRDTDevice() && !blocksBrowserScanner() && (
            <Well>
              <BasicOption
                title={<Trans>enableBrowserScannerString</Trans>}
                onClick={() => {
                  setLocalDeviceConfig(
                    blockBrowserScannerKey,
                    !disableBrowserScanner
                  );
                  setDisableBrowserScanner(!disableBrowserScanner);
                }}
                titleExtra={
                  <FixaSwitch checked={!disableBrowserScanner} subtle />
                }
              />
            </Well>
          )}
        </React.Fragment>
      )}
    </FullScreenPopup>
  );
};

export default Support;
