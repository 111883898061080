import React from "react";
import styled from "styled-components";
import { RadioButton, Button } from "@coworker/components";
import { Trans } from "@coworker/locales";
import { Title, Option, OptionContent, Text, Radio } from "./CommonComponents";
import useFormatter from "../../../hooks/useFormatter";
import {
  formatIsoDate,
  getLast8Weeks,
  getLastWeekDates,
} from "../../Insights/helpers";
import { insightsGroupTypes } from "@coworker/enums/insightsGroupTypes";

const OptionWrapper = styled(Option)`
  height: 100%;
  min-height: 50px;
`;

const SubTitle = styled(Title)`
  font-size: 12px;
  padding-top: 0px;
  margin-top: 30px;
`;

const GraphOptionsWrapper = styled('div')`
  padding-left: 28px;
  display: flex;
`;

const ButtonWrapper = styled(Button)`
  height: 40px;
  min-width: 110px;
  padding: 12px, 24px, 12px, 24px;
  font-size: 12px;
`;

const InputBox = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0px 16px 0px 29px;
  & > input {
    font-size: 16px;
    border-bottom: 1px solid var(--grey500);
    background: var(--white);
    width: 100%;
  }
  &:first-child {
    margin-top: 0;
  }
  & > input:first-of-type {
    margin-bottom: 30px;
  }
`;

const InputLabel = styled('label')`
  font-size: 12px;
  color: var(--grey700);
`;

const CustomPerionWrapper = styled('div')`
  margin-bottom: 26px;
`;

function CustomTimePeriodOption({ selectedPeriod, onSelectedPeriodChange }) {
  const { formatDateRange } = useFormatter();
  const isoNow = formatIsoDate(new Date());
  const last8Weeks = formatIsoDate(getLast8Weeks());
  return (
    <>
      <OptionWrapper
        onClick={() => {
          const [lastWeekStartDate, lastWeekEndDate] = getLastWeekDates();
          onSelectedPeriodChange({
            data: "custom",
            name: formatDateRange(
              new Date(lastWeekStartDate),
              new Date(lastWeekEndDate)
            ),
            graphOption: insightsGroupTypes.DAY,
            startDate:
              selectedPeriod.startDate || formatIsoDate(lastWeekStartDate),
            endDate: selectedPeriod.endDate || formatIsoDate(lastWeekEndDate),
          });
        }}
      >
        <OptionContent>
          <Text>
            <Trans>customInsightsPeriod</Trans>
          </Text>
          <Radio>
            <RadioButton readOnly checked={selectedPeriod?.data === "custom"} />
          </Radio>
        </OptionContent>
      </OptionWrapper>
      {selectedPeriod?.data === "custom" && (
        <CustomPerionWrapper>
          <InputBox>
            <InputLabel htmlFor="startDate">
              <Trans>startDateString</Trans>
            </InputLabel>
            <input
              name="startDate"
              type="date"
              value={selectedPeriod.startDate || isoNow}
              min={last8Weeks}
              max={selectedPeriod.endDate || isoNow}
              onChange={(e) =>
                onSelectedPeriodChange({
                  ...selectedPeriod,
                  startDate: e.target.value,
                  name: formatDateRange(
                    new Date(e.target.value),
                    new Date(selectedPeriod.endDate)
                  ),
                })
              }
            />
            <InputLabel htmlFor="endDate">
              <Trans>endDateString</Trans>
            </InputLabel>
            <input
              name="endDate"
              type="date"
              value={selectedPeriod.endDate || isoNow}
              min={selectedPeriod.startDate || isoNow}
              max={isoNow}
              onChange={(e) =>
                onSelectedPeriodChange({
                  ...selectedPeriod,
                  endDate: e.target.value,
                  name: formatDateRange(
                    new Date(selectedPeriod.startDate),
                    new Date(e.target.value)
                  ),
                })
              }
            />
          </InputBox>
          <SubTitle>
            <Trans>customInsightsPeriodGraphOptions</Trans>
          </SubTitle>
          <GraphOptionsWrapper>
            <ButtonWrapper
              text={<Trans>customInsightsPeriodShowDays</Trans>}
              primary={true}
              dark={selectedPeriod.graphOption === insightsGroupTypes.DAY}
              onClick={() => {
                onSelectedPeriodChange({
                  ...selectedPeriod,
                  graphOption: insightsGroupTypes.DAY,
                });
              }}
            />
            <ButtonWrapper
              text={<Trans>customInsightsPeriodShowWeeks</Trans>}
              primary={true}
              dark={selectedPeriod.graphOption === insightsGroupTypes.WEEK}
              onClick={() => {
                onSelectedPeriodChange({
                  ...selectedPeriod,
                  graphOption: insightsGroupTypes.WEEK,
                });
              }}
            />
          </GraphOptionsWrapper>
        </CustomPerionWrapper>
      )}
    </>
  );
}

export default CustomTimePeriodOption;
