import { useTranslation } from "@coworker/locales";
import { useCustomAllSelectOption } from "@coworker/apprestructured/src/shared/filters/utils";
import {
  FilterType,
  InputType,
} from "@coworker/apprestructured/src/shared/filters/types";

const ALERTS_ID = "alerts";

export function useAlertsFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: ALERTS_ID, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: ALERTS_ID,
      title: t("alertsFilterTitleString"),
      options: [
        {
          value: "eds",
          title: t("edsString"),
        },
        {
          value: "salesStop",
          title: t("salesStopString"),
        },
        {
          value: "ongoingTasks",
          title: t("ongoingTasksString"),
        },
      ],
      enabled: true,
      defaultValue,
    },
    t("allAlertsString")
  );
}
