import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

export const DARK_TOAST = "grey900";
export const LIGHT_TOAST = "grey300";
export const WARNING_TOAST = "warning";
export const SUCCESS_TOAST = "green";

const defaultVisibilityInSeconds = 3;
const undoDelayInSeconds = 1.5;

type ToastContextState = {
  msg: React.ReactNode;
  actionText?: React.ReactNode;
  actionHandler?: (() => void) | undefined;
  start: number;
  duration: number | undefined;
  color: string | undefined;
  onDestroy?: () => void;
};

type ToastContextType = [
  ToastContextState | null,
  React.Dispatch<React.SetStateAction<ToastContextState | null>>,
];

const ToastContext = React.createContext<ToastContextType>([null, () => {}]);

function useToastNotification() {
  const [state, setState] = React.useContext(ToastContext);

  const showToast = (
    msg: React.ReactNode,
    actionText?: React.ReactNode,
    actionHandler?: () => void,
    duration: number = defaultVisibilityInSeconds,
    color?: string,
    onDestroy: () => void = () => {}
  ) => {
    setState({
      msg,
      actionText,
      actionHandler,
      start: Date.now(),
      duration,
      color,
      onDestroy,
    });
  };

  const showToastWithUndo = (
    msg: React.ReactNode,
    undoHandler: () => void,
    duration?: number
  ) => {
    setTimeout(
      () =>
        setState({
          msg,
          actionText: <Trans>undoString</Trans>,
          actionHandler: () => {
            undoHandler();
            setState(null);
          },
          start: Date.now() + undoDelayInSeconds * 1000,
          duration,
          color: DARK_TOAST,
          onDestroy: () => {
            setTimeout(() => {
              setState(null);
            }, 500);
          },
        }),
      250
    );
  };

  return { noToastShown: !state?.msg, showToast, showToastWithUndo };
}

type ToastNotificationProviderProps = {
  children: React.ReactNode;
};

function ToastNotificationProvider({
  children,
}: ToastNotificationProviderProps) {
  const [state, setState] = React.useState<ToastContextState | null>(null);
  return (
    <ToastContext.Provider value={[state, setState]}>
      {children}
    </ToastContext.Provider>
  );
}

const Toast = styled(motion.div)<{ color: string }>`
  color: var(--white);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: var(--${(props) => props.color});
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(17, 17, 17, 0.1);
  min-height: 42px;
  padding: 10px 0;
  bottom: 90px;
  left: 24px;
  right: 24px;
  z-index: var(--z-toast);
  cursor: pointer;
`;

const Action = styled.div`
  font-weight: bold;
  margin: auto 0;
  padding: 8px 16px;
`;

const Text = styled.div`
  margin: auto 16px;
  padding: 8px 0;
`;

const VCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

function ToastNotification() {
  const [state, setState] = React.useContext(ToastContext);
  const { msg, actionText, actionHandler, onDestroy, start, duration, color } =
    state || {};
  const backgroundColor = color || DARK_TOAST;
  React.useEffect(() => {
    if (!start) return;

    const now = Date.now();
    const durationInSeconds = duration || defaultVisibilityInSeconds;
    const end = start + durationInSeconds * 1000;

    const timeout = setTimeout(() => {
      if (onDestroy) onDestroy();
      setState(null);
    }, end - now);

    return () => clearTimeout(timeout);
  }, [start, duration, onDestroy, setState]);

  const onClick = () => {
    setState(null);
    actionHandler && actionHandler();
  };

  return msg ? (
    <Toast
      color={backgroundColor}
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 100 }}
      onDragEnd={(_: any, info: any) => {
        if (info.offset.y > 20) setState(null);
      }}
      data-testid="curtainNotification"
    >
      <VCenter>
        <Text>
          <>{msg}</>
        </Text>
        {actionHandler && (
          <Action onClick={onClick}>
            <>{actionText}</>
          </Action>
        )}
      </VCenter>
    </Toast>
  ) : null;
}

export { ToastNotification, ToastNotificationProvider, useToastNotification };
