
import { CenterContainer, FlexibleRowContainer } from "../../styles/styles";
import { FixaSkeleton } from "@coworker/apprestructured/src/shared/wrappers/FixaSkeleton/FixaSkeleton";

export const LargeSkeletonBox = () => {
  return (
    <FlexibleRowContainer style={{ padding: "20px 24px" }}>
      <CenterContainer $centerText={false}>
        <FixaSkeleton height={"362px"} width={"314px"} />
      </CenterContainer>
    </FlexibleRowContainer>
  );
};
