import React from "react";
import { Team } from "../../../../../shared/types/team";
import { TeamsListViewItem } from "../TeamsListViewItem/TeamsListViewItem";
import styles from "./teams-list-view.module.css";
import { FixaListView } from "../../../../../shared/wrappers/FixaListView/FixaListView";
import { useActiveStoreUsers } from "../../../../../shared/hooks/users/useActiveStoreUsers";
import { SimpleLoadingBall } from "../../../../../shared/simple/SimpleLoading/SimpleLoading";
import { useTranslation } from "@coworker/locales";

interface TeamsListViewProps {
  activeTeams: Team[];
  handleTeamSelection: (team: Team) => void;
  selectedTeamId: string;
}

export const TeamsListView: React.FC<TeamsListViewProps> = ({
  activeTeams,
  handleTeamSelection,
  selectedTeamId,
}) => {
  const { data: activeStoreUsers, isLoading } = useActiveStoreUsers();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {isLoading && (
        <SimpleLoadingBall text={t("loadingEllipsisString")} size={"medium"} />
      )}
      {!isLoading && (
        <FixaListView
          id={"teams-list-view"}
          className={styles["teams-list-view"] ?? ""}
        >
          {activeTeams.map((team: Team, index: number) => {
            const teamMembers = activeStoreUsers?.filter(
              (user: any) => user.teamId === team.id
            );
            return (
              <TeamsListViewItem
                team={team}
                index={index}
                teamMembers={teamMembers}
                handleTeamSelection={handleTeamSelection}
                selectedTeamId={selectedTeamId}
              ></TeamsListViewItem>
            );
          })}
        </FixaListView>
      )}
    </React.Fragment>
  );
};
