import React from "react";
import { create } from "zustand";
import { extendWithGetters } from "@coworker/reusable/helpers/zustandHelpers";
import { camelProxy } from "@coworker/reusable/helpers/objectAccess";
import { UserProfile } from "@coworker/types";

interface LoggedInUserState {
  countryId: string;
  employeeId: string;
  givenName: string;
  phone: string;
  storeId: string;
  teamId: string;
  updatedAt: number;
  uid: string;
  startedAtVersion: string;
  email: string;
  surname: string;
  roleId: string;
  fixaUid: string;
  userId: string;
  hashedUid: string;
  store: any | undefined;
  lastActiveAt: string;
}

const initialLoggedInUserState: LoggedInUserState = {
  countryId: "",
  employeeId: "",
  givenName: "",
  phone: "",
  storeId: "",
  teamId: "",
  updatedAt: 0,
  lastActiveAt: "",
  uid: "",
  startedAtVersion: "",
  email: "",
  surname: "",
  roleId: "",
  fixaUid: "",
  userId: "",
  hashedUid: "",
  store: undefined,
};

export const useLoggedInUserStore = extendWithGetters(
  create<LoggedInUserState>()(() => initialLoggedInUserState)
);

export function useLoggedInUser() {
  const setLoggedInUser = React.useCallback((user: UserProfile) => {
    // Protect against too old data from localStorage overwrites and may cause user to
    // get into a loop where they can never log in and refresh data to stay logged in.
    if (user.updated_at > useLoggedInUserStore.getState().updatedAt) {
      const {
        countryId,
        employeeId,
        givenName,
        phone,
        storeId,
        teamId,
        updatedAt,
        uid,
        startedAtVersion,
        email,
        surname,
        roleId,
        fixaUid,
        hashedUid,
        store,
        lastActiveAt,
      } = camelProxy(user);
      useLoggedInUserStore.setState({
        countryId,
        employeeId,
        givenName,
        phone,
        storeId,
        teamId,
        updatedAt,
        uid,
        startedAtVersion,
        email,
        surname,
        roleId,
        fixaUid,
        userId: fixaUid,
        hashedUid,        
        store,
        lastActiveAt,
      });
    }
  }, []);

  const resetLoggedInUser = React.useCallback(() => {
    useLoggedInUserStore.setState(initialLoggedInUserState);
  }, []);

  // Access full user data, gradually replace with getters
  // We can replace this with getters as we go
  const loggedInUser = useLoggedInUserStore((state) => state);

  return {
    ...useLoggedInUserStore.getters,
    loggedInUser,
    setLoggedInUser,
    resetLoggedInUser,
  };
}

export const useCountryId = () => useLoggedInUser().getCountryId();
export const useEmployeeId = () => useLoggedInUser().getEmployeeId();
export const useGivenName = () => useLoggedInUser().getGivenName();
export const usePhone = () => useLoggedInUser().getPhone();
export const useStoreId = () => useLoggedInUser().getStoreId();
export const useTeamId = () => useLoggedInUser().getTeamId();
export const useUID = () => useLoggedInUser().getUid();
export const useStartedAtVersion = () =>
  useLoggedInUser().getStartedAtVersion();
export const useEmail = () => useLoggedInUser().getEmail();
export const useSurname = () => useLoggedInUser().getSurname();
export const useRoleId = () => useLoggedInUser().getRoleId();
export const useFixaUID = () => useLoggedInUser().getFixaUid();
export const useUserId = () => useLoggedInUser().getUserId();
export const useHashedUid = () => useLoggedInUser().getHashedUid();
export const setLoggedInUserTeamId = (teamId: string) =>
  useLoggedInUserStore.setState({ teamId: teamId });
export const useLoggedInUserState = () => useLoggedInUserStore();
export const useMyStore = () => useLoggedInUser().getStore();
