import { useQuery } from "@tanstack/react-query";
import { getArticlesByMediaId } from "../services/medias.service";
import { QueryKeys } from "./queryKeys";
import { useMemo } from "react";

export const useArticlesByMediaId = (
  mediaId?: string | undefined,
  active?: "ACTIVE" | "INACTIVE" | undefined
) => {
  const {
    data: articles,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKeys.ArticlesByMedia, { id: mediaId }],
    queryFn: () => getArticlesByMediaId(mediaId),
    enabled: !!mediaId,
  });
  const data = useMemo(
    () => articles?.filter((a) => !active || a.active === active) ?? articles,
    [articles, active]
  );
  return { data, isLoading, isError };
};
