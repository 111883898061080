import { ReactNode } from "react";
import Text, { TextStyles } from "@ingka/text";

import "@ingka/text/dist/style.css";

interface TextProps {
  textStyle?: TextStyles;
  className?: string;
  children?: ReactNode;
  tagName?: "h1" | "h2" | "h3" | "h4" | "p";
  dangerouslySetInnerHTML?: { __html: string };
}

export const FixaText = (props: TextProps) => {
  return <Text {...props}>{props.children}</Text>;
};
