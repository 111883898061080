import { useMemo,useState,useEffect } from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { RoundCard } from "../Rounds/RoundCard";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useParams } from "react-router-dom";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { Tools } from "./Tools";
import { DIVISION_HASH, HEADER_COLOR_SCHEMA } from "../constants";
import { scrollToTop } from "../helper";
import { useCreateShoppingTool } from "../Hooks/useCreateShoppingTool";
import { useFetchDivisionsCountByRound } from "../Hooks/useFetchDivisionsCountByRound";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const MarkethallOW = () => {
  const [cameWithThisTool, setCameWithThisTool] = useState("");
  const [leftWithThisTool, setLeftWithThisTool] = useState("");
  let [currentValue, setCurrentValue] = useState<number>(0);
  const { t } = useTranslation();

  const { push } = useWorkspacesAction();
  const { id } = useParams();
  const createShoppingToolMutation = useCreateShoppingTool();
  const storeId = useStoreId();

  const { data: startValue, isFetching } = useFetchDivisionsCountByRound(
    DIVISION_HASH.MARKETHALL?.division ?? "",
    id ?? ""
  );

  useEffect(() => {
    setCurrentValue((prev) => (prev !== startValue ? startValue : prev));
  }, [startValue]);
  
  useEffect(() => {
    setLeftWithThisTool((prev) => (cameWithThisTool && !prev ? cameWithThisTool : prev));
  }, [cameWithThisTool]);
  

  const handleSubmit = async () => {
    await createShoppingToolMutation.mutateAsync({
      storeId: storeId,
      division: DIVISION_HASH.MARKETHALL?.division ?? "",
      roundName: id ?? "",
      initialShoppingTools: { [cameWithThisTool]: 1 },
      lastShoppingTools: { [leftWithThisTool]: 1 },
    });
    setCameWithThisTool("");
    setLeftWithThisTool("");
    currentValue++;
    setCurrentValue(currentValue);
    scrollToTop();
  };

  const linkbarItems = useMemo(() => {
    return [
      {
        label: t("shoppingToolsHeaderString"),
        to: "/shoppingtools",
      },
      {
        label: t("shoppingToolsProgress"),
        to: "/shoppingtools/progress",
      },
    ];
  }, [t]);

  return (
    <FullScreenPopup
      noPadding
      linkbar={
        <Linkbar
          items={linkbarItems}
          currentLabel={`${t("markethallOWString")} - ${t(`${id}String`)}`}
          currentAsH1
        />
      }
    >
      <Tools
        id={"came"}
        title={"customerCameToolString"}
        division={DIVISION_HASH.SHOWROOM?.division ?? ""}
        setChosenTool={setCameWithThisTool}
        chosenTool={cameWithThisTool}
        colorSchema={HEADER_COLOR_SCHEMA.GREY}
      />
      <Tools
        id={"left"}
        title={"customerLeftToolString"}
        division={DIVISION_HASH.MARKETHALL?.division ?? ""}
        setChosenTool={setLeftWithThisTool}
        chosenTool={leftWithThisTool}
        colorSchema={HEADER_COLOR_SCHEMA.BLUE}
      />
      <RoundCard
        title={DIVISION_HASH.MARKETHALL?.transKey ?? ""}
        currentValue={currentValue}
        showNav={false}
        background="secondary"
        whiteBarBackground
        loading={isFetching && !startValue}
      />
      <ButtonWrapper>
        <FixaButton
          type="primary"
          style={{ marginTop: "15px", width: "80%" }}
          text={<Trans>submitNewString</Trans>}
          disabled={!cameWithThisTool.length || !leftWithThisTool.length}
          onClick={handleSubmit}
        />
        <FixaButton
          aria-label={t("goToCashlineAriaString")}
          disabled={currentValue < 50}
          type="secondary"
          style={{ marginTop: "15px", width: "80%" }}
          text={<Trans>goToCashlineString</Trans>}
          onClick={() => push(`/shoppingtools/progress/cashline/${id}`)}
        />
      </ButtonWrapper>
    </FullScreenPopup>
  );
};
