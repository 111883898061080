import styled, { css } from "styled-components";
import { Trans } from "@coworker/locales";
import { LargeText, SmallText } from "../Overview/NewReview";
import { TodaysProgressSkeleton } from "../Components/Skeletons/TodaysProgressSkeleton";
import { useFetchTodaysProgress } from "../Hooks/useFetchTodaysProgress";
import {
  getFormatedRound,
  getFormattedArea,
  sanitizeProgressValue,
} from "../helper";

export const Container = styled('div')`
  height: 150px;
  padding: 0 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: auto;
  border-top: 1px solid var(--grey150);
`;

export const TextWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ProgressBarContainer = styled('div')`
  width: 100%;
  margin: 10px auto;
`;

const Bar = styled('div')`
  position: relative;
  height: 14px;
  background-color: var(--grey100);
  border-radius: 5px;
  overflow: hidden;
`;

const Progress = styled('div')<{
  progress: number;
}>`
  ${(props) => css`
    width: ${props.progress}%;
  `};
  height: 100%;
  background-color: var(--blue-medium);
  border-radius: 5px 0 0 5px;
`;

const Checkpoint = styled('div')<{
  position: number;
}>`
  ${(props) => css`
    right: ${props.position}%;
  `};
  position: absolute;
  top: 50%;
  height: 8px;
  width: 8px;
  background: var(--grey200);
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

export const TodaysProgress = () => {
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";
  const title = activeArea !== "wholeStore" ? activeArea : "";
  const activeRound =
    localStorage.getItem("shoppingToolsRoundFilter") ?? "allRounds";
  const area = getFormattedArea(activeArea);
  const round = getFormatedRound(activeRound);
  const { data: todaysProgress, isFetching } = useFetchTodaysProgress(
    area,
    round
  );
  const progressValue = sanitizeProgressValue(todaysProgress);

  if (isFetching) {
    return <TodaysProgressSkeleton />;
  }
  return (
    <Container>
      <TextWrapper>
        <SmallText>
          <Trans>todaysTargetString</Trans>
          {title && (
            <>
              {" - "}
              <Trans>{title}String</Trans>
            </>
          )}
        </SmallText>
        <LargeText>{`${
          todaysProgress === 100
            ? `${todaysProgress}% 🎉`
            : `${todaysProgress}%`
        }`}</LargeText>
      </TextWrapper>
      <ProgressBarContainer>
        <Bar>
          <Progress progress={progressValue} />
          {progressValue < 33 && <Checkpoint position={66} />}
          {progressValue < 66 && <Checkpoint position={33} />}
          {progressValue <= 99 && <Checkpoint position={0} />}
        </Bar>
      </ProgressBarContainer>
    </Container>
  );
};
