import React from "react";
import styled from "styled-components";
import profilePreferences from "@coworker/enums/profilePreferences";
import { Trans } from "@coworker/locales";
import { HeaderSearchButton } from "../../features/searching/HeaderSearchButton";
import Scanner from "../Scanner";
import { useScanPopover } from "../ScanResult";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { parseProduct } from "../../services/products.service";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import RDTScanner from "./RDTScanner";
import {
  isAnyRDT,
  isChineseEnvironment,
} from "@coworker/reusable/helpers/devices";
import { BrowserScanner } from "../Scanning/BrowserScanner";
import useBrowserScanner from "../Scanning/useBrowserScanner";
import { Tip } from "../Scanning/ScanTip";
import { SimpleToast } from "@coworker/apprestructured/src/shared/simple/SimpleToast/SimpleToast";
import { ArticleError } from "@coworker/apprestructured/src/shared/components/ArticleError/ArticleError";
import { reportMessageToSentry } from "../../hooks/useConfigureSentry";
import { SearchInput } from "./SearchInput";
import { ItemError } from "@coworker/apprestructured/src/shared/enums/itemError";
import { useProductQualityTeamId } from "../../hooks/useProductQualityTeamId";

const SearchContainer = styled.div`
  margin-top: 24px;
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

const ScanContainer = styled.div`
  position: fixed;
  background: var(--white);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

function Scan({
  onScannerError,
  startSearch,
  onCancel,
  paused,
  onBarcode,
  videoRef,
}) {
  const [pause, setPause] = React.useState(false);
  const [profileSetToRDTScannerMode, setInRDTScannerMode] = useUserPreference(
    profilePreferences.USE_RDT_SCANNER,
    isAnyRDT()
  );
  const inRDTScannerMode = isAnyRDT() && profileSetToRDTScannerMode;
  const canUseBrowserScanner = useBrowserScanner();

  const [isLoadingItemInfo, setIsLoadingItemInfo] = React.useState(false);

  return (
    <ScanContainer>
      {inRDTScannerMode ? (
        <RDTScanner
          switchToCameraScanner={() => setInRDTScannerMode(false)}
          onBarcode={onBarcode}
        />
      ) : canUseBrowserScanner ? (
        <BrowserScanner
          videoRef={videoRef}
          onBarcode={onBarcode}
          switchToRDTScanner={() => setInRDTScannerMode(true)}
          loadingItemInfo={() => setIsLoadingItemInfo(true)}
        />
      ) : (
        <Scanner
          videoRef={videoRef}
          onBarcode={onBarcode}
          onError={onScannerError}
          scanningPaused={paused || pause}
          streamPaused={pause || paused}
          loadingItemInfo={() => setIsLoadingItemInfo(true)}
          switchToRDTScanner={() => setInRDTScannerMode(true)}
        />
      )}

      <HeaderContainer>
        <HeaderSearchButton
          onClick={() => {
            setPause(true); // Pause scanning to avoid crashing
            startSearch();
          }}
          onCancel={() => {
            setPause(true);
            onCancel();
          }}
        />
        {!inRDTScannerMode && !isLoadingItemInfo && (
          <Tip scanner={canUseBrowserScanner}>
            <Trans>scanTip</Trans>
          </Tip>
        )}
      </HeaderContainer>
    </ScanContainer>
  );
}

function ScanAndSearchWithoutRouter({
  onSubmit,
  onClose,
  withHistory = true,
  start,
  hideSPR = false,
  forbiddenArticles = [], // Articles that should not be returned
  onForbiddenArticle = () => null, // Called when a forbidden article is scanned
}) {
  const activeRef = React.useRef(true);
  const { push, setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const { setScannedItem, scanPopoverActive } = useScanPopover();
  const [inSearch, setInSearch] = React.useState(false);
  const [toastVisible, setToastVisible] = React.useState(false);
  const store_id = useStoreId();
  const videoRef = React.useRef(null);
  const [showItemErrorModal, setShowItemErrorModal] = React.useState({
    openItemErrorModal: false,
    itemErrorType: null,
  });
  const onCloseArticleErrorModal = (closeArticleErrorModal) => {
    setShowItemErrorModal({
      openItemErrorModal: closeArticleErrorModal,
    });
    setScannedItem(null);
    setInSearch(false);
    setNavigationState({
      code: null,
      supplierNumber: null,
      search: true,
      type: "addon",
    });
    onClose();
  };

  const onBarcode = (
    code,
    fromSearch,
    product,
    supplierNumber,
    isLoadingProductInfo = true
  ) => {
    if (forbiddenArticles.includes(code)) {
      if (window.location.pathname === "/testbuy/custom") {
        return setToastVisible(true);
      } else {
        return onForbiddenArticle(code);
      }
    }
    if (withHistory) {
      setNavigationState({
        code,
        product,
        fromSearch,
        supplierNumber,
      });
    }

    if (navigationState.type === "addon") {
      if (isChineseEnvironment()) {
        reportMessageToSentry(`Scan addon`, {
          level: "warning",
          extra: {
            navigationState: navigationState,
            code: code,
            product: JSON.stringify(product),
            isLoadingProductInfo: isLoadingProductInfo,
          },
        });
      }

      let isItemPriceMissing = false;
      let isItemNotFound = false;

      const params = {
        supplierNumber,
        product: product?.no?.replace(/\./g, ""),
        type: product?.type || "",
      };
      if (!isLoadingProductInfo && product === null) {
        isItemNotFound = true;
      } else if (!isLoadingProductInfo && product !== null) {
        isItemPriceMissing =
          !product?.salesPrice?.currency || !product?.salesPrice?.price;
      }
      if (isItemPriceMissing || isItemNotFound) {
        setShowItemErrorModal({
          openItemErrorModal: true,
          itemErrorType: isItemPriceMissing
            ? ItemError.ITEM_PRICE_MISSING
            : ItemError.ITEM_NOT_FOUND,
        });
      } else if (!isItemPriceMissing && !isItemNotFound) {
        activeRef.current = true;
        onSubmit(params);
        setScannedItem(null);
        setTimeout(() => videoRef.current?.play(), 0);
        if (withHistory && activeRef.current) {
          setNavigationState({ code: null, supplierNumber: null });
        }
      }
    } else {
      setScannedItem({
        code,
        supplierNumber,
        product,
        store_id,
        withContinue: true,
        displayRelatedTasks: true,
        start: start,
        fromSearch: false,
        onSubmit: (...params) => {
          activeRef.current = false;
          onSubmit(...params);
          setScannedItem(null);
        },
        onRelated: () => {
          activeRef.current = false;
          onClose();
          setScannedItem(null);
          push(`/tasks/related/${code}`);
        },
        close: () => {
          // This is here to force the video stream to start playing again if the user closes the popup when an article is found.
          // The setTimeout(..., 0) is needed so that it reorders execution, and starts playing again after react has rerendered the scanner component.
          setTimeout(() => videoRef.current?.play(), 0);

          if (withHistory && activeRef.current) {
            setNavigationState({ code: null, supplierNumber: null });
          }
        },
        onItemClick: (code) => {
          activeRef.current = false;
          onClose();
          setScannedItem(null);
          const [short_id, type] = parseProduct(code);
          if (type) push(`/product/${type + short_id}`);
        },
        onSearch: () => {
          setScannedItem(null);
          setInSearch(true);
        },
      });
    }
  };
  const [inputValue, setInputValue] = React.useState("");
  const [focusedElement, setFocusedElement] = React.useState("input");
  const [pauseScanner, setPauseScanner] = React.useState(false);
  return (
    <React.Fragment>
      {inSearch && (
        <SearchContainer>
          <SearchInput
            placeHolderString={"searchArticleString"}
            hideSPR={hideSPR}
            withHistory={false}
            inputValue={inputValue}
            setInputValue={setInputValue}
            focusedElement={focusedElement}
            updateFocus={(isFocused) =>
              setFocusedElement(isFocused ? "Input" : "none")
            }
            focusWithClearButton={true}
            focusSearchInput={true}
            searchResultItemSelection={(itemNo, itemType, item) => {
              onBarcode(itemNo, true, item, "", false);
            }}
            className={""}
          />
        </SearchContainer>
      )}
      {!inSearch && (
        <Scan
          videoRef={videoRef}
          onBarcode={onBarcode}
          startSearch={() => {
            videoRef.current && videoRef.current.pause();
            setPauseScanner(true);
            setInSearch(true);
          }}
          onScannerError={() => {
            setInSearch(true);
          }}
          onCancel={() => {
            setNavigationState({
              query: null,
              code: null,
              inSearch: null,
              search: null,
              supplierNumber: null,
            });
            onClose();
          }}
          paused={pauseScanner || scanPopoverActive}
        />
      )}
      {toastVisible && (
        <SimpleToast
          text={"articleAlreadyInTestbuyString"}
          isOpen={toastVisible}
          onCloseRequest={() => {
            setToastVisible(false);
          }}
        />
      )}
      <ArticleError
        itemErrorType={showItemErrorModal.itemErrorType}
        query={navigationState?.code}
        openArticleErrorModal={showItemErrorModal.openItemErrorModal}
        onClose={onCloseArticleErrorModal}
      />
    </React.Fragment>
  );
}

function SearchOnlyWithoutRouter({
  onClose,
  onSubmit,
  withHistory,
  displayRelatedTasks,
  start,
}) {
  const qualityTeamId = useProductQualityTeamId();
  const { setScannedItem } = useScanPopover();
  const { setNavigationState, push } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const store_id = useStoreId();
  const [inputValue, setInputValue] = React.useState("");
  const [focusedElement, setFocusedElement] = React.useState("input");
  const onBarcode = (code, type, product, supplierNumber) => {
    const [short_id] = parseProduct(code); // Defend against ARTART
    setNavigationState({ code, type, product, supplierNumber });
    setScannedItem({
      code,
      supplierNumber,
      product,
      store_id,
      displayRelatedTasks,
      withContinue: false,
      withActions: true,
      onSubmit,
      start: start,
      fromSearch: true,
      onAction: (url) => {
        setScannedItem(null);
        onClose();
        push(url, { product: { product: code, type }, qualityTeamId });
      },
      onRelated: () => {
        onClose();
        setScannedItem(null);
        push(`/tasks/related/${code}`);
      },
      onItemClick: () => {
        onClose();
        setScannedItem(null);
        push(`/product/${type + short_id}`);
      },
      close: () => {
        if (withHistory) {
          setNavigationState({ code: null, supplierNumber: null });
        }
      },
    });
  };

  React.useEffect(() => {
    if (navigationState && navigationState.code) {
      onBarcode(
        navigationState.code,
        navigationState.type,
        navigationState.product,
        navigationState.supplierNumber
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <SearchContainer>
        <SearchInput
          placeHolderString={"searchArticleString"}
          hideSPR={false}
          withHistory={false}
          inputValue={inputValue}
          setInputValue={setInputValue}
          focusedElement={focusedElement}
          updateFocus={(isFocused) =>
            setFocusedElement(isFocused ? "Input" : "none")
          }
          focusWithClearButton={true}
          focusSearchInput={true}
          searchResultItemSelection={(itemNo, itemType, item) => {
            onBarcode(itemNo, itemType, item, "", false);
          }}
          className={""}
        />
      </SearchContainer>
    </React.Fragment>
  );
}

export const SearchOnly = SearchOnlyWithoutRouter;

export default ScanAndSearchWithoutRouter;
