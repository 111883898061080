import {
  FilterValue,
  MultiSelectFilter,
  StringArrayFilterValue,
  SelectOption,
} from "../../types";
import {
  getSelectedFilterValue,
  updatedFilterValueFromValues,
  createFilterValueFromMultiSelectOption,
} from "../../utils";
import { MultiSelectListView } from "../SelectListView";

interface FilterTabMultiSelectProps {
  filter: MultiSelectFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const FilterTabMultiSelect = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
}: FilterTabMultiSelectProps) => {
  const selected = getSelectedFilterValue(
    filter.id,
    filterValues,
    defaultValues
  ) as StringArrayFilterValue;
  const handleOnChange = (option: SelectOption, isSelected: boolean) => {
    const value = createFilterValueFromMultiSelectOption(
      filter.id,
      option,
      selected,
      isSelected
    );
    setFilterValues(updatedFilterValueFromValues(filterValues, filter, value));
  };
  return (
    <MultiSelectListView
      prefix="pill"
      filter={filter}
      selected={selected}
      onChange={handleOnChange}
    />
  );
};
