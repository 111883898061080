import {useMemo} from "react";
import useCallUrl from "./useCallUrl";
import { pickSIKlocale } from "@coworker/utils/sikLocales";
import { SIK_API_VERSION, SIK_CLIENT_NAME } from "@coworker/consts/SIK";
import { useMyStore } from "@coworker/app/src/core/auth/useLoggedInUser";

const SIK_EUROPE = "https://sik.search.blue.cdtapps.com";
const SIK_CHINA = "https://search.ikea.cn"; // TODO: Find new SIK URL or replacement.
const baseURL = window.location.host.includes(".cn") ? SIK_CHINA : SIK_EUROPE;
const SIK_AUTOCOMPLETE = "search-box";
const SIK_SEARCH = "search-result-page";
const SIK_SEARCH_MORE = "search-result-page/more";

const empty = {};

/**
 * @param {String} functionName
 * @param {Object} query
 * @param {Object} options
 */
function useCallSIK(endpoint, query, options = {}) {
  const store = useMyStore();
  const primaryLocale = store?.primaryLocale;

  const blockRequest =
    options.blockRequest || !primaryLocale;

  const url = useMemo(() => {
    const sikLocale = pickSIKlocale(primaryLocale);
    const url = new URL(`${baseURL}/${sikLocale}/${endpoint}`);
    Object.keys(query).forEach((key) => {
      if (query[key]) url.searchParams.append(key, query[key]);
    });
    url.searchParams.append("v", SIK_API_VERSION);
    url.searchParams.append("c", SIK_CLIENT_NAME);

    return url;
    // Ignored: query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, JSON.stringify(query), primaryLocale]);

  const { data, error, loading, isBlocked, dataIsFromAPI } = useCallUrl(
    url,
    empty,
    { ...options, blockRequest }
  );

  // if the reponse has field 'autocorrect' it means that it did not find any
  // results for the current query and predicted what the user wanted to write.
  if (data?.searchResultPage?.autocorrect) {
    return { data: null, error, loading };
  }

  return { data, error, loading, isBlocked, dataIsFromAPI };
}

export default useCallSIK;

export { SIK_AUTOCOMPLETE, SIK_SEARCH, SIK_SEARCH_MORE };
