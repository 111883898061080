import FixaPill from "../../../wrappers/FixaPill/FixaPill";
import CrossIconPath from "../../../wrappers/FixaSSRIcon/paths/CrossIconPath";
import {
  FilterValue,
  MultiSelectFilter,
  SelectOption,
  StringArrayFilterValue,
} from "../../types";
import {
  filterValueAreTheSame,
  isSelectOption,
  removeFilterValueFromValues,
} from "../../utils";

interface AppliedSelectPillProps {
  filter: MultiSelectFilter;
  filterValue: StringArrayFilterValue | undefined;
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const AppliedMultiSelectPill = ({
  filter,
  filterValue,
  filterValues,
  setFilterValues,
}: AppliedSelectPillProps) => {
  const { id, title } = filter;

  if (filterValue && !filterValueAreTheSame(filterValue, filter.defaultValue)) {
    const handleOnClick = (value: string) => {
      const newFilterValues = removeFilterValueFromValues(filterValues, id);
      const newFilterValueValue = filterValue.value.filter(
        (filterValue) => filterValue !== value
      );
      if (newFilterValueValue.length) {
        newFilterValues.push({ ...filterValue, value: newFilterValueValue });
      }
      setFilterValues(newFilterValues);
    };

    return (
      <>
        {filterValue.value.map((value) => {
          const option = filter.options
            .filter((option) => isSelectOption(option))
            .find((option) => (option as SelectOption).value === value);
          return (
            <FixaPill
              key={`${filter.id}-${value}`}
              iconPosition="trailing"
              ssrIcon={CrossIconPath}
              selected
              label={title + ": " + (option?.title ?? filterValue.value)}
              onClick={() => {
                handleOnClick(value);
              }}
              size="small"
            />
          );
        })}
      </>
    );
  } else {
    return null;
  }
};
