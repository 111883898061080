import { groupByTypes } from "../constants/groupBy";
import { taskFilters } from "../constants/filters";

export const showGroupTaskFilter = (filters) => {
  return filters.includes(taskFilters.ALL);
};

export const showGroupPackageFilter = (filters) => {
  return filters.some((e) =>
    [taskFilters.ALL, taskFilters.PICK_AND_REFILL, taskFilters.REFILL].includes(
      e
    )
  );
};

export const showGroupGridCodeFilter = (filters) => {
  return !filters.includes(taskFilters.PRODUCT_QUALITY);
};

export const showGroupByFilters = (filters) => {
  return (
    showGroupTaskFilter(filters) ||
    showGroupPackageFilter(filters) ||
    showGroupGridCodeFilter(filters)
  );
};

export const createTaskGroupList = (tasks, groupByOption, addonFilter) => {
  if (
    !groupByOption ||
    groupByOption === groupByTypes.NO_GROUPING ||
    window.location.pathname.includes("/tasks/related")
  ) {
    return tasks;
  }
};
