import styled from "styled-components";
import { Button } from "@coworker/components";
import { ReactComponent as InformationCircle } from "../../assets/svg/information-circle.svg";

export const BottomSpacer = styled('div')`
  padding-bottom: 16px;
`;

export const Title = styled('div')`
  margin-left: 24px;
  margin-top: 30px;
  white-space: normal;
  font-weight: 700;
`;

export const Container = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background: var(--white);
  overflow-y: auto;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 30px;
`;

export const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid var(--grey300);
  line-height: 10px;
  height: 40px;
`;

export const OverflowBackground = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grey800);
  opacity: 0.8;
`;

export const Stack = styled('div')`
  flex-grow: 1;
  margin-right: 9px;
`;
export const StackVertical = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--black);
`;

export const ContentContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

export const StyledInformationCircle = styled(InformationCircle)`
  color: var(--blue-medium);
  margin-right: 10px;
  height: 16px;
  width: 16px;
`;
export const ProductIssueLinkContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
export const ProductIssueLink = styled('a')`
  color: var(--blue-medium);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  display: flex;
  align-items: center;
`;
export const RelatedLinkContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 40px;
  > a {
    font-size: 12px;
    margin-left: 8px;
    width: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  > svg {
    height: 14px;
    width: 14px;
  }
`;
