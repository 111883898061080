import styled from "styled-components";
import React from "react";
import { Trans } from "@coworker/locales";

const SpacedUl = styled('ul')`
  margin-top: 20px;
  margin-bottom: 20px;
  list-style-position: inside;
`;

export default (
  <SpacedUl>
    <Trans>safetyAlarmCheckboxHeaderString</Trans>
    <li>
      <Trans>safetyAlarmCheckboxLine1String</Trans>
    </li>
    <li>
      <Trans>safetyAlarmCheckboxLine2String</Trans>
    </li>
    <li>
      <Trans>safetyAlarmCheckboxLine3String</Trans>
    </li>
    <li>
      <Trans>safetyAlarmCheckboxLine4String</Trans>
    </li>
  </SpacedUl>
);
