import { PropsWithChildren } from "react";
import { useViewportSize } from "../hooks/useViewportSize";
import { ViewportSize } from "../constants/viewport";

interface MobileOnlyProps {
  breakpoint?: ViewportSize;
}
export function MobileOnly({
  breakpoint = ViewportSize.EXTRA_LARGE_1200,
  children,
}: PropsWithChildren<MobileOnlyProps>) {
  const viewportSize = useViewportSize();

  return viewportSize < breakpoint ? <>{children}</> : null;
}
