import { Criterion as CriterionT } from "@coworker/types";
import { Criterion } from "./Criterion";
import { sortCriteriaDisplayOrder } from "./CriteriaHelper";

export interface CriteriaListProps {
  testbuyId: string;
  articleId: string;
  dispatch: Function;
  criteria: CriterionT[];
  isLocked: boolean;
  location: string;
  price: number;
}
export const CriteriaList = ({
  testbuyId,
  articleId,
  criteria,
  dispatch,
  isLocked,
  location,
  price,
}: CriteriaListProps) => {
  return (
    <ol data-testid="criterionList">
      {sortCriteriaDisplayOrder(criteria).map((criterion) => {
        return (
          <Criterion
            key={criterion.key}
            testbuyId={testbuyId}
            articleId={articleId}
            criterion={criterion}
            dispatch={dispatch}
            isLocked={isLocked}
            location={location}
            price={price}
          />
        );
      })}
    </ol>
  );
};
