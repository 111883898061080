import { PropsWithChildren } from "react";

import "../styles/RoomSettings.css";
import {
  Article,
  ArticleIdNbrArticles,
  EdsData,
  SalesStopData,
} from "../types/article";
import { MediumSkeletonListItem } from "./skeletons/MediumSkeletonListItem";
import { ArticleListItemBanner } from "./ArticleListItemBanner";
import { ArticleListItemDetails } from "./ArticleListItemDetails";
import { ArticleListItemWrapper, Row } from "../styles/styles";
import FixaCheckbox from "@coworker/apprestructured/src/shared/wrappers/FixaCheckbox/FixaCheckbox";
import styled from "styled-components";
import { ConditionalAnchor } from "./ConditionalAnchor";

const StyledFixaCheckbox = styled(FixaCheckbox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px;
`;

interface ArticleListItemInnerProps {
  article: Article;
  isMainProduct: boolean;
  numberMain?: number;
  isAlternativeForProduct: boolean;
  hasOngoingTask: boolean;
  showActionRow: boolean;
  hideChevron: boolean;
  edsData?: EdsData | null | undefined;
  salesStopData?: SalesStopData | null | undefined;
  selectArticleIdsMode: boolean;
  isSelected: boolean;
  onSelectArticleIdNbrArticles?:
    | ((articleIdNbrArticles: ArticleIdNbrArticles) => void)
    | undefined;
  onArticleUpdate?:
    | ((article: Article, isSkeleton: boolean) => void)
    | undefined;
  currentNbrArticles: number;
  onClick: () => void;
  isAnchor: boolean;
}

export function ArticleListItemInner({
  article,
  isMainProduct,
  numberMain,
  isAlternativeForProduct,
  hasOngoingTask,
  showActionRow,
  hideChevron,
  edsData,
  salesStopData,
  selectArticleIdsMode,
  isSelected,
  onSelectArticleIdNbrArticles,
  currentNbrArticles,
  onClick,
  isAnchor,
  children,
}: PropsWithChildren<ArticleListItemInnerProps>) {
  const hasBanner = () => {
    if (!article) return false;
    if (isMainProduct) return true;
    if (isAlternativeForProduct) return true;
    if (hasOngoingTask) return true;
    if (edsData) return true;
    if (salesStopData) return true;
    return false;
  };

  if (!article) return <></>;

  const onSelectedArticleChange = () => {
    if (onSelectArticleIdNbrArticles) {
      onSelectArticleIdNbrArticles({
        articleId: article.id,
        nbrArticles: currentNbrArticles,
      });
    }
  };

  const innerComponent = (
    <>
      <ArticleListItemBanner
        isMainProduct={isMainProduct}
        numberMain={numberMain || 0}
        isAlternativeForProduct={isAlternativeForProduct}
        hasOngoingTask={hasOngoingTask}
        edsData={edsData}
        salesStopData={salesStopData}
      />
      <Row>
        <ConditionalAnchor
          isAnchor={isAnchor}
          href={`/mediamaintenance/medias/${article.mediaId}/articles/product_article_id/${article.productArticleId}`}
          onClick={onClick}
        >
          <ArticleListItemDetails
            article={article}
            showActionRow={showActionRow}
            selectArticleIdsMode={selectArticleIdsMode}
            onSelectedArticleChange={onSelectedArticleChange}
            isSelected={!!isSelected}
            hideChevron={!!hideChevron}
          />
        </ConditionalAnchor>
        {selectArticleIdsMode && (
          <StyledFixaCheckbox
            id={article.id}
            value={article.id}
            checked={isSelected}
            onChange={onSelectedArticleChange}
          />
        )}
      </Row>

      {children}
    </>
  );

  return (
    <ArticleListItemWrapper $hasBanner={hasBanner()}>
      {article.isSkeleton && <MediumSkeletonListItem />}
      {!article.isSkeleton && innerComponent}
    </ArticleListItemWrapper>
  );
}
