import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "@coworker/locales";
import styled from "styled-components";
import { getMediaTypeText } from "../tools";
import { MediaTypes } from "../constants";

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 48px 24px;
  @media screen and (min-width: 1200px) {
    margin: 64px 24px;
  }
`;

const NoResultsTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const NoResultsSubtitle = styled.div`
  font-size: 14px;
  color: var(--grey718);
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
  @media screen and (min-width: 1200px) {
    width: 350px;
  }
`;

interface NoResultsProps {
  isSearch: boolean;
  isFilters: boolean;
  mediasCount: number;
  matchingMediasCount: number;
  articlesCount: number;
  mediaType: string;
  query: string;
  clearFilters: () => void;
  clearSearch: () => void;
  setTabToAllMedias: () => void;
}

export const NoResults = ({
  isSearch,
  isFilters,
  mediasCount,
  matchingMediasCount,
  articlesCount,
  mediaType,
  query,
  clearFilters,
  clearSearch,
  setTabToAllMedias,
}: NoResultsProps) => {
  const { t } = useTranslation();

  const isAllMedia = mediaType === "allMedia";
  const filterOnly = mediasCount === 0 && isFilters && !isSearch;
  const searchOnly =
    matchingMediasCount === 0 && articlesCount === 0 && isSearch && !isFilters;
  const filterAndSearch =
    matchingMediasCount === 0 && articlesCount === 0 && isSearch && isFilters;
  return (
    <>
      {filterOnly && (
        <NoResultsWrapper>
          <NoResultsTitle>{t("noTaskResultsString")}</NoResultsTitle>
          <NoResultsSubtitle>
            {isAllMedia ? (
              <>{t("noResultsWithFiltersString")}</>
            ) : (
              <>
                {t("noResultsForMediaWithFiltersString", {
                  name: getMediaTypeText(mediaType as MediaTypes),
                })}
              </>
            )}
          </NoResultsSubtitle>
          <FixaButton
            style={{ width: "290px" }}
            onClick={clearFilters}
            text={t("clearFiltersString")}
            size="medium"
            type="secondary"
          />
        </NoResultsWrapper>
      )}

      {searchOnly && (
        <NoResultsWrapper>
          <NoResultsTitle>{t("noTaskResultsString")}</NoResultsTitle>
          <NoResultsSubtitle>
            {isAllMedia ? (
              <>
                {t("noSearchResultsForQueryString", {
                  query: query,
                })}
              </>
            ) : (
              <>
                {t("noSearchResultsInMediaString", {
                  query: query,
                  name: getMediaTypeText(mediaType as MediaTypes),
                })}
              </>
            )}
          </NoResultsSubtitle>
          <FixaButton
            style={{ width: "290px" }}
            onClick={() => (isAllMedia ? clearSearch() : setTabToAllMedias())}
            text={
              isAllMedia ? t("clearSearchString") : t("searchInAllMediaString")
            }
            size="medium"
            type="secondary"
          />
        </NoResultsWrapper>
      )}

      {filterAndSearch && (
        <NoResultsWrapper>
          <NoResultsTitle>{t("noTaskResultsString")}</NoResultsTitle>
          <NoResultsSubtitle>
            {isAllMedia ? (
              <>
                {t("noSearchResultsWithFiltersString", {
                  query: query,
                })}
              </>
            ) : (
              <>
                {t("noSearchResultsInMediaWithFiltersString", {
                  query: query,
                  name: getMediaTypeText(mediaType as MediaTypes),
                })}
              </>
            )}
          </NoResultsSubtitle>
          <FixaButton
            style={{ width: "290px", marginBottom: "8px" }}
            onClick={clearFilters}
            text={t("clearFiltersString")}
            size="medium"
            type="primary"
          />
          <FixaButton
            style={{ width: "290px" }}
            onClick={() => (isAllMedia ? clearSearch() : setTabToAllMedias())}
            text={
              isAllMedia ? t("clearSearchString") : t("searchInAllMediaString")
            }
            size="medium"
            type="secondary"
          />
        </NoResultsWrapper>
      )}
    </>
  );
};
