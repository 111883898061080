import React from "react";
import styled, { css } from "styled-components";
import { inverseLerp } from "../helpers/math.helpers";

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;

  // Reset styles:
  input[type="range"] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type="range"]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  // Style the input
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    ${({ darkMode }) =>
      darkMode
        ? css`
            background: var(--grey900);
          `
        : css`
            background: var(--white);
          `};
    border-radius: 50%;
    transform: translateY(-10px);
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;

    background: var(--grey200);
    border-radius: 20px;
  }
`;

const IndicatorLine = styled('div')`
  height: 4px;
  border-radius: 20px;
  background: var(--new);
  transform: translateY(100%);
  pointer-events: none;
`;

const TextIndicator = styled('div')`
  margin-bottom: 13px;
  & > span {
    font-size: 14px;
    line-height: 22px;
    color: var(--white);
    letter-spacing: -0.8px;
    display: inline-block;
    padding: 4px 4px 0px 4px;
    text-align: center;

    width: 25px;
    height: 25px;

    /* Background / Inverse */

    background: var(--grey900);
    border-radius: 5px;
  }
`;

const Labels = styled('div')`
  margin-top: 9px;
  font-size: 11px;
  color: var(--grey900);
  line-height: 22px;
  height: 25px;
  display: flex;
  justify-content: space-between;
`;

const LeftLabel = styled('div')`
  text-align: left;
  width: 40%;
`;

const RightLabel = styled('div')`
  text-align: right;
  width: 40%;
`;

const LabelSpan = styled.span.attrs(({ position, darkMode, size }) => ({
  style: {
    transform: `translateX(${position})`,
    fontSize: size === "big" && `16px`,
    color: darkMode ? `var(--grey900)` : `var(--white)`,
  },
}))``;

const Tick = styled.div.attrs(({ value, size, ticks, offset }) => ({
  style: {
    transform: `translate(${(size / ticks) * value + offset}px, 46px)`,
  },
}))`
  position: absolute;
  height: 4px;
  width: 2px;
  background: var(--black);
  opacity: 0.15;
`;

function Ticks({ min, max, value, size, offset }) {
  const relativeValue = value - min; // takes into account the minimum
  return (
    <>
      {min !== Number(value) &&
        new Array(relativeValue)
          .fill(null)
          .map((_, i) => (
            <Tick
              data-testid={`tick-${i}`}
              key={`${i + 1}`}
              ticks={max - min}
              size={size || 0}
              value={i}
              offset={offset}
            />
          ))}
      {max !== Number(value) &&
        new Array(max - min - relativeValue).fill(null).map((_, i) => (
          // This is to make ticks from knob to right excluding the last one
          <Tick
            data-testid={`tick-${i}`}
            key={i + value + 1}
            ticks={max - min}
            size={size || 0}
            value={i + relativeValue + 1}
            offset={offset}
          />
        ))}
    </>
  );
}

function NpsSlider({
  value,
  setValue,
  leftLabel,
  rightLabel,
  min = 0,
  max = 10,
  step = 1,
  showTicks = true,
  showValue = true,
  textSize = "normal",
  darkMode = true,
}) {
  const inputRef = React.useRef();
  const [size, setSize] = React.useState(false);
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (inputRef.current) {
        const { width } = inputRef.current.getBoundingClientRect();
        if (width !== size) {
          setSize(width);
        }
      }
    }
    // Immediatelly call function to simulate sizing on init
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);

  const offset = 28; // paddings and text indicator width is hidden in this number

  function position(value) {
    return `calc(0% + ${((value - min) * (size - offset)) / (max - min)}px)`;
  }
  return (
    <Container darkMode={darkMode}>
      {showValue && size && (
        <TextIndicator>
          <span style={{ transform: `translateX(${position(value)})` }}>
            {value}
          </span>
        </TextIndicator>
      )}
      <IndicatorLine
        style={{ width: `${inverseLerp(min, max, value) * 100}%` }}
      />
      <input
        data-testid="nps-slider"
        ref={inputRef}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {showTicks && (
        <Ticks min={min} max={max} value={value} size={size - 24} offset={12} />
      )}
      {size && (
        <Labels>
          <LeftLabel>
            <LabelSpan
              position={position(min)}
              size={textSize}
              darkMode={darkMode}
            >
              {leftLabel || min}
            </LabelSpan>
          </LeftLabel>
          <RightLabel>
            <LabelSpan
              position={position(max)}
              size={textSize}
              darkMode={darkMode}
            >
              {rightLabel || max}
            </LabelSpan>
          </RightLabel>
        </Labels>
      )}
    </Container>
  );
}
export default NpsSlider;

export function NpsSliderPresentation() {
  const [value, setValue] = React.useState(6);
  return (
    <NpsSlider
      value={value}
      setValue={setValue}
      leftLabel="0 = Highly unlikely"
      rightLabel="10 = Very unlikely"
    />
  );
}

export function ScannerZoomSlider({ value, setValue, min, max, step }) {
  return (
    <NpsSlider
      value={value}
      setValue={setValue}
      min={min}
      max={max}
      step={step}
      showTicks={false}
      showValue={false}
      darkMode={false}
      textSize="big"
      leftLabel="-"
      rightLabel="+"
    />
  );
}
