import {
  Ids,
  Image,
  ImageUploadResponse,
  Images,
  ImageFileType,
} from "../types/images";
import { getTokenAsync } from "../../../hooks/API/getAuthToken";
import { makeHeaders } from "../tools";

import { MEDIAS_SERVICE_URL } from "./service.helper";

const BASE_URL = MEDIAS_SERVICE_URL + "/images";

export const getImagesByMediaId = async (mediaId: string) => {
  try {
    const token = await getTokenAsync();
    if (!mediaId || !token || typeof token !== "string") {
      return;
    }
    const response = await fetch(`${BASE_URL}/medias/${mediaId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (response.status === 404) return [];

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const images = (await response.json()).images as Images;

    // Do not use stored signed images
    for (const image of images) {
      image.url = "";
    }
    return images;
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return null;
};

export const getSignedUrl = async (images: ImageFileType[], size: "large") => {
  const filenames = images.map((item) => item?.file_name).join(",");
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  try {
    const response = await fetch(
      `${BASE_URL}/get_signed_urls?filenames=${filenames}&size=${size}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: ImageFileType = (await response.json()) as ImageFileType;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const uploadImageToCloud = async (imageFile: File) => {
  try {
    const token = await getTokenAsync();
    const formData = new FormData();

    formData.append("image", imageFile);
    const response = await fetch(`${BASE_URL}/image-upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const image = (await response.json()) as ImageUploadResponse;
    return image;
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return null;
};

export const addImageToMedia = async (image: Image) => {
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(
    `${BASE_URL}/medias/${image.mediaId}/image-upload`,
    {
      method: "POST",
      headers: makeHeaders(token),
      body: JSON.stringify([image]),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const ids = (await response.json()) as Ids;
  return ids;
};

export const deleteMediaImage = async (imageId: string, mediaId: string) => {
  const token = await getTokenAsync();
  const response = await fetch(
    `${BASE_URL}/medias/${mediaId}/images/${imageId}`,
    {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return response;
};

export const updateImage = async (imageId: string, image: Image) => {
  const token = await getTokenAsync();
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(`${BASE_URL}/` + imageId, {
    method: "PUT",
    headers: makeHeaders(token),
    body: JSON.stringify(image),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return await response.text();
};
