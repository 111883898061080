import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getUserPermissions } from "./API/core.service";
import { useFixaUID } from "../core/auth/useLoggedInUser";
import { INTERVAL } from "../constants/clientTime";
const { FLAGS } = require("@coworker/enums/featureFlags");

async function fetchUserPermissions(fixaUid: string) {
  const result = await getUserPermissions(fixaUid);
  return result;
}

export function useUserPermissionsQuery() {
  const fixaUid = useFixaUID();
  const result = useQuery({
    queryKey: ["userPermissions", fixaUid],
    queryFn: async () => await fetchUserPermissions(fixaUid),
    staleTime: INTERVAL.WEEK, // Stale for a week
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return result;
}
// Previous implementation was redundant since setOfFlags would be a new object on each render
// useMemo ensures setOfFlags only changes when data changes
export function useFlagsState() {
  const { data: userPermissions } = useUserPermissionsQuery();
  const setOfFlags = React.useMemo(
    () => new Set(userPermissions),
    [userPermissions]
  );
  return { setOfFlags };
}

export function useHasFlag() {
  const { setOfFlags } = useFlagsState();
  return React.useCallback(
    (flag: string) => setOfFlags.has(flag),
    [setOfFlags]
  );
}

function useFlag(flag: string) {
  const { setOfFlags } = useFlagsState();
  const hasFlag = React.useMemo(() => {
    return setOfFlags.has(flag);
  }, [setOfFlags, flag]);
  return hasFlag;
}

export default useFlag;
export { FLAGS };
