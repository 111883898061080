import React, { useState } from "react";
import {
  FixaAccordion,
  FixaAccordionItem,
} from "../../../wrappers/FixaAccordion/FixaAccordion";
import styles from "./team-and-users-list.module.css";
import { FixaListView } from "../../../wrappers/FixaListView/FixaListView";
import { logEvent } from "@coworker/app/src/helpers/tracker";
import { useAssignableTeams } from "@coworker/app/src/core/hooks/useAssignableTeams";
import { useActiveStoreUsers } from "../../../hooks/users/useActiveStoreUsers";
import {
  makeMatcher,
  clean,
} from "@coworker/components/src/helpers/string.helpers";
import { User } from "../../../types/user";
import { useTranslation } from "@coworker/locales";
import { TeamAndUsersListViewItem } from "./TeamsAndUsersListViewItem/TeamsAndUsersListViewItem";
import { SimpleLoadingBall } from "../../../simple/SimpleLoading/SimpleLoading";

const sortByName = (oneName: string = "", anotherName: string = "") => {
  return (
    oneName
      ?.toLocaleLowerCase()
      ?.localeCompare(anotherName?.toLocaleLowerCase()) || 0
  );
};

const membersForTeam = (
  users: User[],
  gid: string,
  matcher: (query: string) => void
) => {
  const chosen = users?.filter(
    (user) => user.teamId === gid && matcher(user.fullName)
  );
  return chosen?.sort((userA, userB) => {
    return sortByName(userA.fullName, userB.fullName);
  });
};

interface TeamsAndUsersListProps {
  isPQR: boolean;
  searchQuery: string;
  checkIfSelected: (id: string) => boolean;
  doSubmit: (args: { name: string; gid: string; uid: string }) => void;
  myUserAndTeamId: { userId: string; teamId: string };
}

export const TeamsAndUsersList = ({
  isPQR,
  searchQuery,
  checkIfSelected,
  doSubmit,
  myUserAndTeamId,
}: TeamsAndUsersListProps) => {
  let assignableTeams = useAssignableTeams(true);
  const { data: activeUsers, isLoading } = useActiveStoreUsers();

  if (isPQR) {
    assignableTeams = assignableTeams.filter((team) => team.is_product_quality);
  }
  const teams = assignableTeams.sort((teamA, teamB) => {
    if (teamA.id === myUserAndTeamId.teamId) return -1;
    if (teamB.id === myUserAndTeamId.teamId) return 1;

    return sortByName(teamA.name, teamB.name);
  });

  const matchAll = makeMatcher(searchQuery, true);
  const matcher = (query: string) => !matchAll || matchAll.test(clean(query));

  const [openedTeamIds, setOpenedTeamIds] = useState(() => {
    const teamId = myUserAndTeamId.teamId;
    return teamId ? [teamId] : [];
  });

  function handleAccordionClick(id: string) {
    const isOpen = openedTeamIds.includes(id);
    if (isOpen) {
      setOpenedTeamIds(openedTeamIds.filter((item) => item !== id));
    } else {
      setOpenedTeamIds([...openedTeamIds, id]);
    }
  }

  const { t } = useTranslation();
  const myTeam = assignableTeams?.find(
    (team) => team.id === myUserAndTeamId.teamId
  );
  const myTeamName = myTeam?.name || t("myTeamString");

  return (
    <React.Fragment>
      {isLoading && (
        <SimpleLoadingBall text={t("loadingEllipsisString")} size="medium" />
      )}
      {!isLoading &&
        teams.map((team) => {
          const teamMembers = membersForTeam(
            activeUsers as User[],
            team.id,
            matcher
          );
          const matchTeamName = matcher(team.name);
          const isMyTeam = team.name === myTeamName;
          return (
            (teamMembers?.length > 0 || matchTeamName) && (
              <FixaAccordion
                className={styles["accordion"] || ""}
                size="medium"
                key={team.id}
              >
                <FixaAccordionItem
                  className={styles["accordion-item"] || ""}
                  id={team.id}
                  title={team.name}
                  open={
                    openedTeamIds.includes(team.id) ||
                    (searchQuery !== "" && matcher(searchQuery))
                  }
                  onClick={() => handleAccordionClick(team.id)}
                  caption={isMyTeam ? t("myCurrentTeamString") : ""}
                >
                  <FixaListView id={team.id} size="small">
                    {matchTeamName && (
                      <TeamAndUsersListViewItem
                        isTeamName={true}
                        className={"team"}
                        id={team.id}
                        title={team.name}
                        teamName={team.name}
                        teamId={team.id}
                        userId={""}
                        isMyTeam={isMyTeam}
                        checkIfSelected={checkIfSelected}
                        doSubmit={doSubmit}
                        logEvent={logEvent}
                      />
                    )}
                    {teamMembers.map(
                      (user: User) =>
                        matcher(user.fullName) && (
                          <TeamAndUsersListViewItem
                            key={user.fixaUid}
                            className={"member"}
                            isTeamName={false}
                            id={user.fixaUid}
                            title={user.fullName}
                            teamName={team.name}
                            userName={user.fullName}
                            teamId={team.id}
                            userId={user.fixaUid}
                            isMyTeam={isMyTeam}
                            checkIfSelected={checkIfSelected}
                            doSubmit={doSubmit}
                            logEvent={logEvent}
                          />
                        )
                    )}
                  </FixaListView>
                </FixaAccordionItem>
              </FixaAccordion>
            )
          );
        })}
    </React.Fragment>
  );
};
