import {ReactElement} from "react";
import Modal from "@ingka/modal";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/focus/dist/style.css";
interface ModalProps {
  children: ReactElement[] | ReactElement;
  handleCloseBtn: () => void;
  visible?: boolean;
  escapable?: boolean;
  className?: string;
  keepContentMounted?: boolean;
  focusLockProps?: { locked: boolean };
  onModalOpened?: (e: AnimationEvent) => void;
}

export const FixaModal = (props: ModalProps) => {
  return <Modal {...props} />;
};
