import { useTranslation } from "@coworker/locales";
import { FilterType, InputType, SelectOption } from "../types";
import { useCustomAllSelectOption } from "../utils";

export const EDS_DATES_ID = "edsDates";

export function useEdsDatesSelectFilter(
  enabled: boolean,
  options: SelectOption[]
) {
  const { t } = useTranslation();
  const defaultValue = { filterId: EDS_DATES_ID, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: EDS_DATES_ID,
      title: t("edsDatesString"),
      options,
      enabled,
      defaultValue,
    },
    t("allEdsDatesString")
  );
}
