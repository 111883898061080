import React from "react";
import { SwipeableTaskCard } from "../../../tasks/components/TaskCard/SwipeableTaskCard";
import styles from "./activity-column.module.css";
import { ActivityFilterType } from "../../types/filterOptionsTypes";
import { DEFAULT_FILTER_VALUES } from "../../constants/defaultFilterValues";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { Trans } from "@coworker/locales";
import { LoadMore } from "../../../shared/components/LoadMore/LoadMore";
import { useItemsInfoQueries } from "../../../shared/hooks/item/useItemsInfo";
import { Item } from "../../../shared/types/item";
import { FixaSkeleton } from "../../../shared/wrappers/FixaSkeleton/FixaSkeleton";
import { FullTask } from "../../types/FullTask";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import StackIconPath from "../../../shared/wrappers/FixaSSRIcon/paths/StackIconPath";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";

interface ActivityColumnProps {
  title: string;
  tasks: FullTask[];
  filteredCount: number;
  totalCount: number;
  loadNextPage: () => void;
  tab: string;
  activeTab: string;
  appliedFiltersCount: number;
  setFilters: React.Dispatch<React.SetStateAction<ActivityFilterType>>;
  isLoading: boolean;
  isFetching: boolean;
}

export const ActivityColumn = ({
  title,
  tasks,
  filteredCount,
  totalCount,
  loadNextPage,
  tab,
  activeTab,
  appliedFiltersCount,
  setFilters,
  isLoading,
  isFetching,
}: ActivityColumnProps) => {
  const itemNos = tasks
    ?.map((task) => task?.product_article_id)
    .filter(Boolean);

  const itemsInfoResult = useItemsInfoQueries(itemNos);

  const itemsInfo = itemsInfoResult
    .map((result) => result.data)
    .filter(Boolean);

  const uid = useFixaUID();
  const { toggleModal } = React.useContext(ModalContext);

  return (
    <div
      className={`${styles["column"]} ${activeTab === tab && styles["active"]}`}
    >
      <div className={styles["title-wrapper"]}>
        {(tab === "OPEN" || tab === "IN_PROGRESS") && (
          <FixaButton
            iconOnly
            ssrIcon={StackIconPath}
            size="xsmall"
            type="tertiary"
            className={styles["multi-select-button"] as string}
            onClick={() =>
              toggleModal({
                type: ModalType.MultiSelectTasks,
                payload: { type: tab === "OPEN" ? "open" : "my" },
              })
            }
          />
        )}
        <div className={styles["title"]}>
          <Trans>{title}</Trans>{" "}
          {!isLoading && filteredCount < totalCount && (
            <span className={styles["count"]}>
              ({filteredCount}/{totalCount}
              {totalCount >= 1000 && "+"})
            </span>
          )}
          {!isLoading && filteredCount === totalCount && (
            <span className={styles["count"]}>
              {totalCount}
              {totalCount >= 1000 && "+"}
            </span>
          )}
        </div>
      </div>
      <div className={styles["wrapper"]}>
        {tasks.map((task) => {
          return (
            <SwipeableTaskCard
              task={task}
              key={task.id}
              item={
                itemsInfo?.find(
                  (item) => item?.no === task.product_article_id
                ) as Item
              }
              multiSelect={false}
              isMyTasks={task.assigned_user_id === uid}
              type={tab}
              disableSwipe={
                task.state === "COMPLETED" || task.state === "CLOSED"
              }
              disablePickup={
                task.state === "ASSIGNED" && task.assigned_user_id !== uid
              }
            />
          );
        })}
        {!isLoading && !tasks.length && (
          <div className={styles["no-results"]}>
            <div className={styles["no-results-title"]}>
              <Trans>noTaskResultsString</Trans>
            </div>
            {!tasks.length && appliedFiltersCount > 0 ? (
              <>
                <div className={styles["no-results-subtitle"]}>
                  <Trans>noTasksWithAppliedFiltersString</Trans>
                </div>
                <FixaButton
                  onClick={() => {
                    setFilters(DEFAULT_FILTER_VALUES);
                    localStorage.setItem(
                      "activityFilters",
                      JSON.stringify(DEFAULT_FILTER_VALUES)
                    );
                  }}
                  text={<Trans>clearFiltersString</Trans>}
                  size="small"
                  type="secondary"
                />
              </>
            ) : (
              <div className={styles["no-results-subtitle"]}>
                <Trans>noTasksFoundString</Trans>
              </div>
            )}
          </div>
        )}
        {isLoading && (
          <div className={styles["loading"]}>
            <FixaSkeleton height="100%" />
          </div>
        )}
        {tasks.length < filteredCount && !isLoading && (
          <LoadMore
            handleClick={loadNextPage}
            loadedItemsCount={tasks.length}
            totalCount={filteredCount}
            isFetching={isFetching}
          />
        )}
      </div>
    </div>
  );
};
