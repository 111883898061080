import {
  postFileType,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { useMutation } from "@tanstack/react-query";

export async function uploadImageToStorage(file: File) {
  const formData = new FormData();
  formData.append("image", file);
  const path = `/v1/storage/image-upload`;
  const url = `${TASKS_SERVICE_URL}${path}`;
  return await postFileType(url, formData);
}

export function useUploadImageToStorage() {
  return useMutation({
    mutationFn: async (data: { file: File }) => {
      return await uploadImageToStorage(data.file);
    },
    onError: (error) => {
      console.log("Error while uploading image", error);
    },
  });
}
