import { Trans } from "@coworker/locales";
import {useState} from "react";
import styled from "styled-components";
import { ReactComponent as Wrench } from "../../../assets/svg/wrench.svg";
import { useChecklist } from "./checklistHook";
import {
  ChecklistItem,
  TestBuyFollowUp,
} from "@coworker/types/lib/tasks/testbuy_followup";
import { WithDoneState } from "./taskValidation";
import { sortChecklistCriteriaDisplayOrder } from "./checklistWidgetHelper";

const DoneInput = styled('input')`
  min-height: 24px;
  min-width: 24px;
  border-color: 1px solid var(--disabled);
`;
const CriterionLabel = styled('label')`
  font-size: 14px;
  padding-left: 1rem;
  font-weight: bold;
`;
const Container = styled('div')`
  padding: 0 1.625rem 0 1.625rem;
  background-color: var(--grey100);
`;

const CriterionContainer = styled('div')`
  padding: 1.625rem 0 1.625rem 0;
  display: flex;
  align-items: center;
`;
const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding: 1.2rem 0 1.2rem 0;
`;

const WrenchIcon = styled(Wrench)`
  padding-right: 1rem;
`;

interface CheckListProps {
  task: WithDoneState<TestBuyFollowUp>;
  taskId: string;
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ task, taskId }: CheckListProps) => {
  const [checklist, setChecklist] = useState(task.checklist);
  const { toggleCriterion } = useChecklist(
    task,
    checklist,
    setChecklist,
    taskId
  );
  return (
    <Container>
      <TitleContainer>
        <WrenchIcon /> <Trans>checklistToFixString</Trans>
      </TitleContainer>
      {sortChecklistCriteriaDisplayOrder(checklist).map(
        (checklistItem: ChecklistItem) => {
          return (
            <CriterionContainer key={checklistItem.key}>
              <DoneInput
                type="checkbox"
                data-testid="doneInputChecklist"
                id={checklistItem.key}
                name={checklistItem.key}
                checked={checklistItem.isDone}
                disabled={task.state === "COMPLETED" || !task.assigned_user_id}
                onChange={() => {
                  toggleCriterion(checklistItem.key);
                }}
              />
              <CriterionLabel>
                <Trans>tb{checklistItem.key}</Trans>
              </CriterionLabel>
            </CriterionContainer>
          );
        }
      )}
    </Container>
  );
};
