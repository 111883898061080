import { Filter, InputType, FilterValue } from "../../types";
import { useTranslation } from "@coworker/locales";
import FixaButton from "../../../wrappers/FixaButton/FixaButton";
import FixaFiltersIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FiltersIconPath";
import { useChangedFiltersCount } from "../../utils";
import { FilterRowScrollPanel } from "../FilterRowScrollPanel";
import { InputSelectPillDesktop } from "./InputSelectPillDesktop";
import { InputMultiSelectPillDesktop } from "./InputMultiSelectPillDesktop";
import { PillCheckbox } from "../PillCheckbox/PillCheckbox";
import { InputGroupPillDesktop } from "./InputGroupPillDesktop";
import { PillRadioButton } from "../PillRadioButton/PillRadioButton";

interface FilterRowDesktopProps {
  filters: Filter[];
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
  setFilterModalIsOpen: (isOpen: boolean) => void;
}

export const FilterRowDesktop = ({
  filters,
  filterValues,
  defaultValues,
  setFilterValues,
  setFilterModalIsOpen,
}: FilterRowDesktopProps) => {
  const { t } = useTranslation();
  const changedFilterCount = useChangedFiltersCount(filters, filterValues);

  return (
    <FilterRowScrollPanel>
      <FixaButton
        type="primary"
        ssrIcon={FixaFiltersIconPath}
        text={t("filterString2", { count: changedFilterCount })}
        size="small"
        onClick={() => {
          setFilterModalIsOpen(true);
        }}
      />
      {filters
        .filter(({ enabled }) => enabled)
        // Put a single grouped filter in the main filter list
        .map((filter) => {
          if (filter.inputType === InputType.Group) {
            const enabledGroupFilters = filter.filters.filter(
              ({ enabled }) => enabled
            );
            if (enabledGroupFilters.length === 1) {
              return enabledGroupFilters[0]!;
            }
          }
          return filter;
        })
        .map((filter) => {
          switch (filter.inputType) {
            case InputType.Select: {
              return (
                <InputSelectPillDesktop
                  key={filter.id}
                  filter={filter}
                  filterValues={filterValues}
                  defaultValues={defaultValues}
                  setFilterValues={setFilterValues}
                />
              );
            }
            case InputType.MultiSelect: {
              return (
                <InputMultiSelectPillDesktop
                  key={filter.id}
                  filter={filter}
                  filterValues={filterValues}
                  defaultValues={defaultValues}
                  setFilterValues={setFilterValues}
                />
              );
            }
            case InputType.Checkbox: {
              return (
                <PillCheckbox
                  key={filter.id}
                  filter={filter}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                />
              );
            }
            case InputType.Group:
              return (
                <InputGroupPillDesktop
                  key={filter.id}
                  filter={filter}
                  filterValues={filterValues}
                  defaultValues={defaultValues}
                  setFilterValues={setFilterValues}
                />
              );
            case InputType.RadioButton: {
              return (
                <PillRadioButton
                  filter={filter}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                />
              );
            }
            default:
              return null;
          }
        })}
    </FilterRowScrollPanel>
  );
};
