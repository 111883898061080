import React from "react";
import { Button } from "@coworker/components";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

const Container = styled('div')`
  padding: 26px 28px;
  height: 100vh;
  display: flex;
  background-color: var(--white);
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
`;

const Title = styled('h2')`
  line-height: 36px;
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.8px;
  color: var(--grey900);
`;

const ErrorMessage = styled('div')`
  line-height: 21px;
  font-size: 14px;
  color: #111111;
  margin-bottom: 20px;
`;

const Main = styled('div')`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: scroll;
`;

const ButtonContainer = styled('div')`
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ErrorDetails = styled('div')`
  font-size: 14px;
  color: var(--error);
  white-space: pre-wrap;
`;

export function OopsScreen({ error, componentStack }) {
  const [count, setCount] = React.useState(window.Cypress ? 5 : 0);
  return (
    <Container>
      <Main>
        <Title>
          Oo
          <span data-testid="p" onClick={() => setCount((n) => ++n)}>
            p
          </span>
          s
        </Title>
        <ErrorMessage>
          <Trans>errorBoundaryString</Trans>
        </ErrorMessage>
        {count > 4 && (
          <ErrorDetails>
            <h3>{error?.toString()}</h3>
            {componentStack}
          </ErrorDetails>
        )}
      </Main>
      <ButtonContainer>
        <StyledButton
          primary
          dark
          text={"Return home"}
          onClick={() => {
            try {
              window.history.pushState(undefined, undefined, "/");
              window.location.reload(true);
            } catch (error) {
              console.log("OopsScreen", error);
            }
          }}
        />
      </ButtonContainer>
    </Container>
  );
}
