import { useTranslation } from "@coworker/locales";
import { FilterType, InputType } from "../types";
import { useCustomAllSelectOption } from "../utils";
import { EDS_DATES_ID } from "./useEdsDatesSelectFilter";

export const ALERT_ID = "alert";

export function useAlertSelectFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: ALERT_ID, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: ALERT_ID,
      title: t("alertsFilterTitleString"),
      options: [
        {
          value: "eds",
          title: t("edsString"),
          dependentOfFilterId: EDS_DATES_ID,
        },
        {
          value: "pastEds",
          title: t("pastEdsString"),
          dependentOfFilterId: EDS_DATES_ID,
        },
        { value: "salesStop", title: t("salesStopString") },
      ],
      enabled: true,
      defaultValue,
    },
    t("allArticlesString")
  );
}
