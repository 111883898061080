import { PackageType } from "@coworker/types";
import { TaskType } from "@coworker/types/lib/tasks/constants";

export const groupByTypes = {
  NO_GROUPING: "NO_GROUPING", // Uppercase as it gets compared to taskGroupTypes.NO_GROUPING
  BY_TASK_TYPE: "task_type",
  BY_PACKAGE_TYPE: "requested_type",
  BY_GRID_CODE: "location_grid_code",
  BY_PRODUCT_ID: "productid",
};

// Type to ensure groupByTypes and groupByTypesTranslations always have the same keys
type GroupByKeys = keyof typeof groupByTypes;

export const groupByTypesTranslations: Record<GroupByKeys, string> = {
  NO_GROUPING: "no_groupingString",
  BY_TASK_TYPE: "task_typeString",
  BY_PACKAGE_TYPE: "requested_typeString",
  BY_GRID_CODE: "location_grid_codeString",
  BY_PRODUCT_ID: "product2String",
};

// Type to ensure all task types and package types can be grouped by
type TaskGrouping =
  | Exclude<TaskType, "PLANNED">
  | PackageType
  | "DEFAULT"
  | "REFILL"
  | "NO_GROUPING";

export const taskGroupTypes: Record<TaskGrouping, string> = {
  //TASK TYPES
  ADDON: "ADDON",
  REFILL: "REFILL", // Semi special as its tasks that have been picked but not refilled
  PRODUCT_ISSUE: "PRODUCT_ISSUE",
  CUSTOM: "CUSTOM",
  PRODUCT_QUALITY: "PRODUCT_QUALITY",
  MFAQ_FOLLOW_UP: "MFAQ_FOLLOW_UP",
  TESTBUY_FOLLOW_UP: "TESTBUY_FOLLOW_UP",
  ROOM_SETTINGS: "ROOM_SETTINGS",
  //PACKAGE TYPES
  PIECES: "PIECES",
  PALLET: "PALLET",
  MULTI_PACK: "MULTI_PACK",
  // SPECIAL TYPES
  NO_GROUPING: "NO_GROUPING",
  DEFAULT: "default",
};

// Type to ensure all keys from taskGroupTypes get translated
type TranslationGroupingKeys = Exclude<keyof typeof taskGroupTypes, "DEFAULT">;

export const translationSwitchEnum: Record<TranslationGroupingKeys, string> = {
  PIECES: "piecesUnit",
  PALLET: "palletsUnit",
  MULTI_PACK: "multiPackUnit",
  ADDON: "addonTitleString",
  REFILL: "refillString",
  PRODUCT_ISSUE: "issueTitleOtherString",
  CUSTOM: "taskTypeTitleCustom",
  PRODUCT_QUALITY: "productQualityReportString",
  NO_GROUPING: "noGroupingString",
  MFAQ_FOLLOW_UP: "mfaqFollowUpTitle",
  TESTBUY_FOLLOW_UP: "testBuyFollowUpString",
  ROOM_SETTINGS: "mediaMaintenanceString",
};
