import { TestBuy } from "@coworker/types";
import styled from "styled-components";
import { useUserId } from "../../../core/auth/useLoggedInUser";
import { allCriteriaOk } from "../CommonComponents/article.helpers";
import { TestBuyItem } from "./TestBuyListItem";

const ListWrapper = styled('div')`
  width: 100%;
  flex-grow: 1;
`;

interface TestBuyListProps {
  testbuys: TestBuy[] | undefined;
}

export function TestBuyList({ testbuys }: TestBuyListProps) {
  const uid = useUserId();
  return (
    <ListWrapper>
      {testbuys?.map((testbuy) => (
        <TestBuyItem
          key={testbuy.id}
          id={testbuy.id}
          area={testbuy.area}
          division={testbuy.divisionId}
          startDate={testbuy.createdAt}
          numberOfArticles={testbuy.numberOfArticles}
          customName={testbuy.customName}
          isMine={
            testbuy.assignedTo === uid && testbuy.status === "IN_PROGRESS"
          }
          isClosed={testbuy.status === "CLOSED"}
          reasonForClosing={testbuy.closeReason || ""}
          numberOfSucceededArticles={
            testbuy.articles?.filter((art) => allCriteriaOk(art.criteria))
              .length ?? 0
          }
          testbuyTasks={testbuy.tasks}
        />
      ))}
    </ListWrapper>
  );
}
