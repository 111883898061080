import { useTranslation } from "@coworker/locales";
import { useCustomAllSelectOption } from "@coworker/apprestructured/src/shared/filters/utils";
import {
  FilterType,
  InputType,
} from "@coworker/apprestructured/src/shared/filters/types";

const AREA_ID = "area";

export function useAreaFilter(areas: string[]) {
  const { t } = useTranslation();
  const defaultValue = { filterId: AREA_ID, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: AREA_ID,
      title: t("areaString"),
      options: areas.map((area) => {
        return {
          value: area,
          title: `${area} - ${t(`hfb${area.split(" ")[1]}String`)}`,
        };
      }),
      enabled: true,
      defaultValue,
    },
    t("allAreasString")
  );
}
