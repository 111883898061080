import React from "react";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
  DetailSectionRight,
} from "./_common";
import { Trans } from "@coworker/locales";
import tracker from "../../../helpers/tracker";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import ChevronRightIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ChevronRightIconPath";

const Container = styled('div')`
  cursor: pointer;
`;

const LinkText = styled('div')`
  color: var(--new);
`;

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default function RepeatableChild({ task }) {
  const { push } = useWorkspacesAction();
  const { modalState, toggleModal } = React.useContext(ModalContext);

  if (!task.repeatable_task_id) return null;
  return (
    <Container>
      <DetailSection
        onClick={() => {
          tracker.plannedTasks.linkToParent();
          if (modalState.isTaskDetailSheetOpen) {
            toggleModal({ type: ModalType.TaskDetail });
            toggleModal({
              type: ModalType.TaskDetail,
              payload: {
                taskId: task.repeatable_task_id,
                taskType: task?.task_type,
              },
            });
          } else {
            push(`/task/${task.repeatable_task_id}`);
          }
        }}
      >
        <DetailSectionLeft>
          <SectionTitle>
            <Trans>wasScheduledString</Trans>
          </SectionTitle>
          <SectionText>
            <LinkText>
              <Trans>viewMainTaskForDetailsString</Trans>
            </LinkText>
          </SectionText>
        </DetailSectionLeft>
        <DetailSectionRight>
          <IconWrapper>
            <FixaSSRIcon paths={ChevronRightIconPath} />
          </IconWrapper>
        </DetailSectionRight>
      </DetailSection>
    </Container>
  );
}
