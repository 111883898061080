// Vendor
import React from "react";
import { Trans } from "@coworker/locales";
// Enums
import { getProductWidget } from "./_common";
import { AMOUNT } from "../widgets";
import { SUBTITLE, SIMPLE_PRODUCT, TEXT_NOTE, LOCATION } from "../widgets";
import { LOCATION_TYPE_PICKUP } from "../../../constants/locationWidgetTypes";
import { useWorkspacesState } from "../../../hooks/useWorkspaces";
import { splitLocation } from "../../../services/locations.service";
import { TaskType } from "@coworker/apprestructured/src/tasks/enums/taskTypes";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  table: "tasks",
  type: "addon",
  enumType: TaskType.ADDON,
  createCurtainMessage: <Trans>curtainRefilledString</Trans>,
  validate: (form, task) => {
    return true;
  },
  fieldsFilledCount: () => 0,
  useReactToChanges(taskId, form, editFields) {
    const { navigationState } = useWorkspacesState() || {};
    React.useEffect(() => {
      if (navigationState.picking) {
        editFields({
          _picking: true,
        });
    
        if ([undefined, null, "", NaN].includes(form.pick_quantity)) {
          editFields({
            pick_quantity: form.requested_quantity,
            actual_pick_quantity: form.actual_requested_quantity,
          });
        } else if ([undefined, null, ""].includes(form.pick_type)) {
          editFields({
            pick_type: form.requested_type,
          });
        }
      } else {
        if ([undefined, null, "", NaN].includes(form.refilled_quantity)) {
          editFields({
            _picking: false,
          });
    
          if (form.picked) {
            editFields({
              refilled_quantity: form.pick_quantity,
              refilled_type: form.pick_type,
              actual_refilled_quantity: form.actual_pick_quantity,
              pick_type: form.pick_type || form.requested_type,
            });
          } else {
            editFields({
              refilled_quantity: form.requested_quantity,
              refilled_type: form.requested_type,
              actual_refilled_quantity: form.actual_requested_quantity,
              pick_type: form.requested_type || form.pick_type,
            });
          }
        }
      }
    }, [navigationState, form, editFields]);
    
  },
  header: {
    editTitle: ({ _picking, picked }) => {
      if (_picking) {
        return <Trans>completePickingWithChangesString</Trans>;
      } else if (picked) {
        return <Trans>completeRefillWithChangesString</Trans>;
      } else {
        return <Trans>completeWithChangesString</Trans>;
      }
    },
    newTitle: "",
  },
  fields: [
    getProductWidget({}, SIMPLE_PRODUCT),
    {
      type: SUBTITLE,
      hide: (_, { _picking, picked }) => _picking === true || !picked,
      values: { in: () => <Trans>refillingDetailString</Trans> },
    },
    {
      type: SUBTITLE,
      hide: (_, { _picking, picked }) => _picking === true || picked,
      values: { in: () => <Trans>confirmPickingRefillingString</Trans> },
    },
    {
      type: SUBTITLE,
      hide: (_, { _picking }) => _picking !== true,
      values: { in: () => <Trans>confirmPickingChangesString</Trans> },
    },
    {
      type: LOCATION,
      conf: {
        noArrow: true,
        isConfirm: true,
        optional: null,
        locationString: <Trans>pickupLocationString</Trans>,
        addon: LOCATION_TYPE_PICKUP,
        popupName: "addonLocation",
      },
      hide: (_, { picked }) => picked,
      values: {
        in: (
          {
            pickup_location,
            pickup_location_custom,
            product_article_id,
            product_article_type,
          },
          task
        ) => {
          const [code, department] = splitLocation(pickup_location);

          return {
            code,
            department,
            custom: pickup_location_custom,
            articleId: product_article_id,
            articleType: product_article_type,
            didntChange:
              task &&
              task?.pickup_location === pickup_location &&
              task?.pickup_location_custom === pickup_location_custom,
          };
        },
        out: ({ code, department, custom, section }) => {
          let locationText = code || "";
          if (department || section)
            locationText += ` - ${department || section}`;

          let departmentText = "";
          if (department || section)
            departmentText = `${department || section}`;

          return {
            pickup_location: custom ? "" : locationText,
            pickup_location_grid_code: custom ? "" : code,
            pickup_location_department: custom ? "" : departmentText,
            pickup_location_custom: custom ? custom : "",
          };
        },
      },
    },
    // Complete refilling only
    {
      type: AMOUNT,
      conf: {
        changeAmount: true,
        changeName: <Trans>picked2String</Trans>,
        ctaTitle: <Trans>confirmString</Trans>,
        changeTitle: <Trans>partialAmountString</Trans>,
      },
      hide: (_, { _picking, picked }) => _picking === true || !picked,
      values: {
        in: ({
          refilled_quantity,
          actual_refilled_quantity,
          actual_pick_quantity,
          refilled_type,
          product_article_id,
          product_article_type,
          location,
          pick_quantity,
          pick_type,
          pieces_per_multi_pack,
          pieces_per_pallet,
        }) => {
          const quantity = refilled_type ? refilled_quantity : pick_quantity;
          const type = refilled_type ? refilled_type : pick_type;
          return {
            quantity,
            actualQuantity: actual_refilled_quantity || actual_pick_quantity,
            type,
            productId: product_article_id,
            location: splitLocation(location)[0],
            articleType: product_article_type,
            requestedQuantity: pick_quantity,
            piecesPerMultiPack: pieces_per_multi_pack,
            piecesPerPallet: pieces_per_pallet,
          };
        },
        out: ({ amount, actualAmount, amountType }) => ({
          refilled_quantity: amount,
          actual_refilled_quantity: actualAmount,
          refilled_type: amountType,
        }),
      },
    },
    // Complete picking and refilling with changes
    {
      type: AMOUNT,
      conf: {
        changeAmount: true,
        changeName: <Trans>requested2String</Trans>,
        ctaTitle: <Trans>confirmString</Trans>,
        changeTitle: <Trans>partialAmountString</Trans>,
      },
      hide: (_, { _picking, picked }) => _picking === true || picked,
      values: {
        in: ({
          refilled_quantity,
          actual_refilled_quantity,
          refilled_type,
          product_article_id,
          product_article_type,
          location,
          requested_quantity,
          pieces_per_multi_pack,
          pieces_per_pallet,
        }) => {
          return {
            quantity: refilled_quantity,
            actualQuantity: actual_refilled_quantity,
            type: refilled_type,
            productId: product_article_id,
            location: splitLocation(location)[0],
            articleType: product_article_type,
            requestedQuantity: requested_quantity,
            piecesPerMultiPack: pieces_per_multi_pack,
            piecesPerPallet: pieces_per_pallet,
          };
        },
        out: ({ amount, actualAmount, amountType }) => ({
          refilled_quantity: amount,
          actual_refilled_quantity: actualAmount,
          pick_quantity: amount,
          actual_pick_quantity: actualAmount,
          refilled_type: amountType,
        }),
      },
    },
    // Refill only
    {
      type: AMOUNT,
      conf: {
        changeAmount: true,
        changeName: <Trans>requested2String</Trans>,
        ctaTitle: <Trans>confirmString</Trans>,
        changeTitle: <Trans>partialPickedAmountString</Trans>,
      },
      hide: (_, { _picking }) => _picking !== true,
      values: {
        in: ({
          pick_quantity,
          actual_pick_quantity,
          pick_type,
          product_article_id,
          product_article_type,
          location,
          requested_quantity,
          pieces_per_multi_pack,
          pieces_per_pallet,
        }) => {
          return {
            quantity: pick_quantity,
            actualQuantity: actual_pick_quantity,
            type: pick_type,
            productId: product_article_id,
            location: splitLocation(location)[0],
            articleType: product_article_type,
            requestedQuantity: requested_quantity,
            piecesPerMultiPack: pieces_per_multi_pack,
            piecesPerPallet: pieces_per_pallet,
          };
        },
        out: ({ amount, actualAmount, amountType }) => ({
          pick_quantity: amount,
          actual_pick_quantity: actualAmount,
          pick_type: amountType,
        }),
      },
    },
    {
      type: TEXT_NOTE,
      conf: {
        optional: <Trans>optionalString</Trans>,
        title: <Trans>leaveNoteString</Trans>,
      },
      values: {
        in: ({ note }) => note,
        out: (note) => ({ note }),
      },
    },
  ],
};
