
import {
  CenterContainer,
  SizedText,
  PaddedContainer,
  SimpleContainer,
} from "../styles/styles";
import { Trans } from "@coworker/locales";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

interface MessageAndButtonProps {
  keyForMessageText: string;
  keyForButtonText: string;
  buttonAction: () => Promise<void>;
}

export function MessageAndButton({
  keyForMessageText,
  keyForButtonText,
  buttonAction,
}: MessageAndButtonProps) {
  return (
    <PaddedContainer $px={"0px"} $py={"48px"}>
      <CenterContainer $centerText={true}>
        <SimpleContainer $direction={"column"}>
          <SizedText $fontSize={20} $bold={true}>
            <Trans>{keyForMessageText}</Trans>
          </SizedText>
          <FixaButton type="primary" onClick={async () => await buttonAction()}>
            <Trans>{keyForButtonText}</Trans>
          </FixaButton>
        </SimpleContainer>
      </CenterContainer>
    </PaddedContainer>
  );
}
