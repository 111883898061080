
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaPrompt } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaPrompt";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { useTranslation } from "@coworker/locales";

interface CreateTaskModalProps {
  modalVisibility: boolean;
  onModalChange: (visibility: boolean) => void;
  onCancel: () => void;
}

export const CreateTaskModal = ({
  modalVisibility,
  onModalChange,
  onCancel,
}: CreateTaskModalProps) => {
  const { t } = useTranslation();
  return (
    <FixaModal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onModalChange(!modalVisibility);
      }}
    >
      <FixaPrompt
        titleId="mainOrBackupProductWarning"
        title=""
        header={
          <FixaModalHeader ariaCloseTxt="Close" title={t("warningString")} />
        }
        footer={
          <FixaModalFooter>
            <FixaButton onClick={() => onCancel()}>{t("noString")}</FixaButton>
            <FixaButton type={"primary"}>{t("yesString")}</FixaButton>
          </FixaModalFooter>
        }
      >
        <p>{t("isProductMainOrBackupWarningString")}</p>
        <p style={{ marginTop: "10px" }}>{t("sureToContinueString")}</p>
      </FixaPrompt>
    </FixaModal>
  );
};
