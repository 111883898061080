import {useMemo} from "react";
import { ViewportSize } from "../constants/viewport";
import { useResponsiveWidth } from "./useResponsiveWidth";

export function useViewportSize(): ViewportSize {
  const width = useResponsiveWidth();
  return useMemo(() => {
    if (
      ViewportSize.EXTRA_LARGE_1200 <= width &&
      width < ViewportSize.EXTRA_LARGE_1440
    ) {
      return ViewportSize.EXTRA_LARGE_1200;
    } else if (
      ViewportSize.EXTRA_LARGE_1440 <= width &&
      width < ViewportSize.EXTRA_EXTRA_LARGE_1600
    ) {
      return ViewportSize.EXTRA_LARGE_1440;
    } else if (ViewportSize.EXTRA_EXTRA_LARGE_1600 <= width) {
      return ViewportSize.EXTRA_EXTRA_LARGE_1600;
    } else {
      return ViewportSize.SMALL_0;
    }
  }, [width]);
}
