import { FixaListViewItem } from "../../../../wrappers/FixaListView/FixaListViewItem";
import styles from "./team-and-users-list-view-item.module.css";
import { useUserAvatarQuery } from "@coworker/app/src/hooks/API/avatar.service";

interface TeamAndUsersListViewItemProps {
  isTeamName: boolean;
  id: string;
  className: string;
  title: string;
  avatarUrl?: string;
  teamName: string;
  userName?: string;
  teamId: string;
  userId: string;
  isMyTeam: boolean;
  checkIfSelected: (id: string) => boolean;
  doSubmit: (args: { name: string; gid: string; uid: string }) => void;
  logEvent: (name: string, args: {}) => void;
}

export const TeamAndUsersListViewItem = ({
  isTeamName,
  id,
  className,
  title,
  userId,
  teamName,
  userName,
  teamId,
  checkIfSelected,
  doSubmit,
  logEvent,
  isMyTeam,
}: TeamAndUsersListViewItemProps) => {
  const avatarImage = useUserAvatarQuery(isTeamName ? "" : userId);
  const avatarUrl =
    avatarImage &&
    (avatarImage instanceof Blob
      ? URL.createObjectURL(avatarImage)
      : avatarImage);
  return (
    <FixaListViewItem
      key={id + teamName}
      className={styles[className] || ""}
      id={id}
      title={title}
      image={avatarUrl || ""}
      control="radiobutton"
      inset
      controlProps={{
        checked: checkIfSelected(id),
        value: id,
      }}
      onChange={() => {
        doSubmit({
          name: isTeamName ? teamName : (userName as string),
          gid: teamId,
          uid: isTeamName ? "" : userId,
        });
        if (isTeamName) {
          logEvent("ce:assign_to", {
            type: "team",
            team: isMyTeam ? "mine" : teamId,
          });
        } else {
          logEvent("ce:assign_to", {
            type: "coworker",
            coworker: userId,
          });
        }
      }}
    />
  );
};
