import React, { useContext } from "react";
import { Trans } from "@coworker/locales";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { ProfileSectionItem } from "../../../types/profileSectionType";
import styles from "./profile-section.module.css";
import { ModalContext } from "../../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../../layout/context/modalType";
import { FixaListView } from "../../../../shared/wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../../shared/wrappers/FixaListView/FixaListViewItem";
import { FixaSkeleton } from "../../../../shared/wrappers/FixaSkeleton/FixaSkeleton";

interface ProfileSectionListViewProps {
  id: string;
  sectionsList: ProfileSectionItem[];
}

export const ProfileSectionListView: React.FC<ProfileSectionListViewProps> = ({
  id,
  sectionsList,
}) => {
  const { push } = useWorkspacesAction();
  const { toggleModal } = useContext(ModalContext);

  function handleSectionClick(section: ProfileSectionItem) {
    if (section.link) {
      window.open(section.link, "_blank", "noopener,noreferrer");
      return;
    }
    if (section.isStoreEnvironment) {
      toggleModal({ type: ModalType.StoreEnvironment });
    } else {
      toggleModal({ type: ModalType.Profile });
      push(section.navigationUrl);
    }
  }

  return (
    <FixaListView id={id} className={styles["hidden-overflow-x"] as string}>
      {sectionsList.map((section: ProfileSectionItem) => {
        if (section.loading) {
          return <FixaSkeleton width="100%" height="73px" />;
        }
        return (
          <FixaListViewItem
            className={styles["profile-list-item"] || ""}
            key={section.id}
            id={section.id}
            control={section.isNavigational ? "navigational" : "default"}
            onClick={() => handleSectionClick(section)}
            inset
            controlIcon={section.isNavigational ? "chevronRight" : null}
            title={<Trans>{section.title}</Trans>}
            description={section.description ?? ""}
            size="small"
            emphasised={section.isTitleBold}
            leadingIcon={section.ssrIcon ? section.ssrIcon : () => []}
          />
        );
      })}
    </FixaListView>
  );
};
