
import { FixaInputField } from "@coworker/apprestructured/src/shared/wrappers/FixaInputField/FixaInputField";
import { LabelText } from "../../Atoms/LabelText";
import TagIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TagIconPath";
import { Media } from "../../types/media";
import { useTranslation } from "@coworker/locales";
import { getMediaTypeText } from "../../tools";
import { MediaTypes } from "../../constants";

interface MediaNameInputProps {
  media: Media;
  onSave: (newRoomSettingsMedia: Media) => void;
  mediaType?: MediaTypes;
  withAsterisk: boolean;
}

export const MediaNameInput = ({
  media,
  onSave,
  mediaType,
  withAsterisk,
}: MediaNameInputProps) => {
  const { t } = useTranslation();

  const labelText = mediaType
    ? t(`nameOfString`, { name: getMediaTypeText(mediaType) })
    : t("mediaNameString");
  return (
    <FixaInputField
      id={"media_name"}
      data-testid={"settingsFormInputField:mediaName"}
      type={"text"}
      label={<LabelText text={labelText} withAsterisk={withAsterisk} />}
      value={media.name || ""}
      ssrIcon={TagIconPath}
      iconPosition={"leading"}
      onChange={(event) => {
        const newMediaName = event.target.value;
        onSave({
          ...media,
          name: newMediaName,
        });
      }}
    />
  );
};
