import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import RefillTypes from "@coworker/enums/refillTypes";
import { ReactComponent as RefillTypeIcon } from "../assets/svg/addon-icon.svg";

const Container = styled('div')`
  background: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
  height: 100%;
  color: var(--grey900);
  font-weight: bold;
`;
const ContentContainer = styled('div')`
  height: 90px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: 29px;
`;

const Stack = styled('div')`
  display: flex;
  flex-direction: column;
`;
const Title = styled('div')`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  margin-left: 14px;
  padding-bottom: 2px;
`;

const SubTitle = styled('div')`
  font-size: 14px;
  line-height: 1.5;
  margin-left: 14px;
  padding-bottom: 2px;
  font-weight: normal;
  color: #484848;
`;

interface SelectRefillTypeProps {
  selectedRefillType: string;
}

export default function SelectRefillType({
  selectedRefillType,
}: SelectRefillTypeProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <ContentContainer>
        <div>
          <RefillTypeIcon />
        </div>
        <Stack>
          <Title>{t("refillTypeString")}</Title>
          <SubTitle>
            {selectedRefillType === RefillTypes.REGULAR
              ? t("refillTypeRegularString")
              : t("refillTypeFirstFillString")}
          </SubTitle>
        </Stack>
      </ContentContainer>
    </Container>
  );
}
