export const baseLanguage = "en-GB";
export const ikeaLocales = {
  cs_CZ: "cs-CZ",
  da_DK: "da-DK",
  de_DE: "de-DE",
  en_GB: baseLanguage,
  en_US: "en-US",
  es_ES: "es-ES",
  eu_ES: "eu-ES",
  fi_FI: "fi-FI",
  fr_FR: "fr-FR",
  hr_HR: "hr-HR",
  hu_HU: "hu-HU",
  it_IT: "it-IT",
  ja_JP: "ja-JP",
  ko_KR: "ko-KR",
  nb_NO: "nb-NO",
  nl_NL: "nl-NL",
  pl_PL: "pl-PL",
  pt_PT: "pt-PT",
  ro_RO: "ro-RO",
  ru_RU: "ru-RU",
  sk_SK: "sk-SK",
  sl_SI: "sl-SI",
  sr_RS: "sr-RS",
  sv_SE: "sv-SE",
  en_PT: baseLanguage,
  en_CA: "en-US",
  fr_CA: "fr-CA",
  en_AU: "en-US",
  no_NO: "nb-NO",
  en_SE: baseLanguage,
  en_FR: baseLanguage,
  en_FI: baseLanguage,
  en_HR: baseLanguage,
  es_US: "es-ES",
  en_IT: baseLanguage,
  gl_ES: "es-ES",
  ca_ES: "es-ES",
  en_ES: baseLanguage,
  en_IE: baseLanguage,
  en_RS: baseLanguage,
  en_RO: baseLanguage,
  de_CH: "de-DE",
  en_CH: baseLanguage,
  fr_CH: "fr-FR",
  it_CH: "it-IT",
  de_AT: "de-AT",
  en_AT: baseLanguage,
  it_AT: "it-IT",
  sl_AT: "sl-SI",
  de_NL: "de-DE",
  en_NL: baseLanguage,
  fr_BE: "fr-BE",
  nl_BE: "nl-BE",
  en_PL: baseLanguage,
  ru_PL: "ru-RU",
  en_JP: "en-US",
  en_RU: baseLanguage,
  en_KR: "en-US",
  fi_SE: "fi-FI",
  en_IN: "en-US",
  zh_CN: "zh-CN",
  en_CN: baseLanguage,
  en_DE: baseLanguage,
};

export const lazyTranslations = {
  "cs-CZ": () =>
    import(/* webpackChunkName: "cs" */ "./cs-CZ/translations.json"),
  "da-DK": () =>
    import(/* webpackChunkName: "da" */ "./da-DK/translations.json"),
  "de-DE": () =>
    import(/* webpackChunkName: "de" */ "./de-DE/translations.json"),
  "de-AT": () =>
    import(/* webpackChunkName: "de" */ "./de-AT/translations.json"),
  "en-GB": () =>
    import(/* webpackChunkName: "en" */ "./en-GB/translations.json"),
  "en-US": () =>
    import(/* webpackChunkName: "en" */ "./en-US/translations.json"),
  "es-ES": () =>
    import(/* webpackChunkName: "es" */ "./es-ES/translations.json"),
  "eu-ES": () =>
    import(/* webpackChunkName: "eu" */ "./eu-ES/translations.json"),
  "fi-FI": () =>
    import(/* webpackChunkName: "fi" */ "./fi-FI/translations.json"),
  "fr-CA": () =>
    import(/* webpackChunkName: "fr" */ "./fr-CA/translations.json"),
  "fr-FR": () =>
    import(/* webpackChunkName: "fr" */ "./fr-FR/translations.json"),
  "fr-BE": () =>
    import(/* webpackChunkName: "fr" */ "./fr-BE/translations.json"),
  "hr-HR": () =>
    import(/* webpackChunkName: "hr" */ "./hr-HR/translations.json"),
  "hu-HU": () =>
    import(/* webpackChunkName: "hu" */ "./hu-HU/translations.json"),
  "it-IT": () =>
    import(/* webpackChunkName: "it" */ "./it-IT/translations.json"),
  "ja-JP": () =>
    import(/* webpackChunkName: "ja" */ "./ja-JP/translations.json"),
  "ko-KR": () =>
    import(/* webpackChunkName: "ko" */ "./ko-KR/translations.json"),
  "nb-NO": () =>
    import(/* webpackChunkName: "nb" */ "./nb-NO/translations.json"),
  "nl-NL": () =>
    import(/* webpackChunkName: "nl" */ "./nl-NL/translations.json"),
  "nl-BE": () =>
    import(/* webpackChunkName: "nl" */ "./nl-BE/translations.json"),
  "pl-PL": () =>
    import(/* webpackChunkName: "pl" */ "./pl-PL/translations.json"),
  "pt-PT": () =>
    import(/* webpackChunkName: "pt" */ "./pt-PT/translations.json"),
  "ro-RO": () =>
    import(/* webpackChunkName: "ro" */ "./ro-RO/translations.json"),
  "ru-RU": () =>
    import(/* webpackChunkName: "ru" */ "./ru-RU/translations.json"),
  "sk-SK": () =>
    import(/* webpackChunkName: "sk" */ "./sk-SK/translations.json"),
  "sl-SI": () =>
    import(/* webpackChunkName: "sl" */ "./sl-SI/translations.json"),
  "sr-RS": () =>
    import(/* webpackChunkName: "sr" */ "./sr-Latn/translations.json"),
  "sv-SE": () =>
    import(/* webpackChunkName: "sv" */ "./sv-SE/translations.json"),
  "zh-CN": () =>
    import(/* webpackChunkName: "zh" */ "./zh-CN/translations.json"),
};

/**
 *
 * @param {string} ikeaLocale
 * @returns {string} i18n locale code (and Phrase uses this code too)
 */
export function getI18nLocaleCode(ikeaLocale) {
  const locale = ikeaLocale?.replace("-", "_");
  return ikeaLocales[locale];
}
