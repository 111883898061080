import {
  compactRequiredFields,
  coordinationMediaRequiredFields,
  MediaFormFieldTypes,
  MediaTypes,
  Reasons,
  roomSettingsRequiredFields,
  TaskListingTypes,
  TaskOwner,
} from "./constants";
import taskStateOptions from "@coworker/enums/taskState";
import dayjs from "dayjs";
import {
  Article,
  ArticleIdNbrArticles,
  Articles,
  EdsData,
  SalesStopData,
} from "./types/article";
import { OngoingTaskLinksResponse } from "./types/taskLink";
import {
  HfLtp,
  LivingSituation,
  MainAlternativeProducts,
  Media,
  Pricing,
  StyleGroup,
} from "./types/media";
import { QueryClient } from "@tanstack/react-query";
import { QueryKeys } from "./hooks/queryKeys";
import { MediaTask } from "@coworker/types/lib/tasks/media";
import { fetchItemsInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemsInfo";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import { updateArticle } from "./services/articles.service";
import { mapArticleInfoToArticle } from "./types/mappers";
import { createNote } from "../../hooks/useCreateNote";
import { updateTaskById } from "@coworker/apprestructured/src/tasks/hooks/useUpdateTaskById";

export function getTranslationKeyFromFlags(task: MediaTask) {
  if (task.is_ptag) {
    return "printPtagInRoomString";
  }
  if (task.is_new_article) {
    return "replaceArticleInRoomString";
  }
  if (task.reason === Reasons.REMOVE_PRODUCT) {
    return "removeProductInString";
  }
  if (task.reason === Reasons.REPLACE_PRODUCT) {
    return "changeProductInString";
  }
  return "replaceArticleInRoomString";
}

export function getTranslationKeyFromReason(reason: string) {
  switch (reason) {
    case Reasons.DAILY_MAINTENANCE:
      return "dailyMaintenanceString";
    case Reasons.REPLACE_PRODUCT:
      return "changeProductString";
    case Reasons.SALES_STOP:
      return "replaceRoomInArticleString";
    case Reasons.REMOVE_PRODUCT:
      return "removeProductString";
    default:
      return "replaceArticleInRoomString";
  }
}

export function getRequiredFields(media: Media) {
  let requiredFields: string[] = [];

  switch (media.mediaType) {
    case MediaTypes.ROOM_SETTINGS:
    case MediaTypes.VIGNETTE:
      requiredFields = roomSettingsRequiredFields;
      return requiredFields;
    case MediaTypes.COMPACT:
      requiredFields = compactRequiredFields;
      return requiredFields;
    case MediaTypes.COORDINATION_MEDIA:
      requiredFields = coordinationMediaRequiredFields;
      return requiredFields;
    default:
      return requiredFields;
  }
}

export function getPiecesTranslation(count: number) {
  return count > 1 ? "piecesUnit" : "pieceUnit";
}

export function getMediasCountTranslation(count: number) {
  return count === 1 ? "mediaString" : "mediasString";
}

export function fullMediaName(
  main: string | undefined,
  sub1: string | undefined,
  sub2: string | undefined
) {
  let headLine = main || "";

  if (headLine && (sub1 || sub2)) {
    headLine += ": ";
  }

  let subDivider = " / ";
  if (sub1) {
    headLine += sub1;
  } else {
    subDivider = "";
  }
  if (sub2) {
    headLine += subDivider + sub2;
  }
  return headLine;
}

export const isValidMedia = (media: Media, field: string) => {
  if (field === MediaFormFieldTypes.STYLE_GROUP) {
    return (media as StyleGroup).styleGroup ? true : false;
  }
  if (field === MediaFormFieldTypes.LIVING_SITUATION) {
    return (media as LivingSituation).livingSituation ? true : false;
  }
  if (field === MediaFormFieldTypes.LONG_TERM_PRIORITY) {
    return (media as HfLtp).hfLtp ? true : false;
  }
  if (field === MediaFormFieldTypes.PRICING) {
    return (media as Pricing).pricing ? true : false;
  } else {
    return false;
  }
};

export const isOngoing = (t: MediaTask) => {
  const ongoingStates = [
    taskStateOptions.ASSIGNED,
    taskStateOptions.UNASSIGNED,
  ];
  return ongoingStates.includes(t.state);
};

export function getTaskFilter(taskListingType: TaskListingTypes) {
  switch (taskListingType) {
    case TaskListingTypes.Ongoing:
      return (t: MediaTask) => isOngoing(t);
    case TaskListingTypes.NotOngoing:
      return (t: MediaTask) => !isOngoing(t);
    case TaskListingTypes.All:
    default:
      return (_: MediaTask) => true;
  }
}

export function getPassedTimeAndTranslationKey(created_at: string) {
  const now = dayjs();
  const taskDate = dayjs(created_at);

  const monthsPassed = now.diff(taskDate, "months");
  if (monthsPassed > 1) {
    return {
      passedTime: monthsPassed,
      translationKey: "monthsAgoString",
    };
  }
  const weeksPassed = now.diff(taskDate, "weeks");
  if (weeksPassed > 1) {
    return {
      passedTime: weeksPassed,
      translationKey: "weeksAgoString",
    };
  }
  const daysPassed = now.diff(taskDate, "days");
  if (daysPassed > 1) {
    return {
      passedTime: daysPassed,
      translationKey: "daysAgoString",
    };
  }
  const hoursPassed = now.diff(taskDate, "hours");
  if (hoursPassed > 24) {
    return {
      passedTime: hoursPassed,
      translationKey: "hoursAgoString",
    };
  }
  return { passedTime: 0, translationKey: "lessThanDayString" };
}

export const makeHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
});

export const filterMediaSettingsListFunction = (
  tasks: MediaTask[],
  taskOwner: string,
  fixaUid: string,
  teamId: string
) => {
  const filteredTasks = (() => {
    switch (taskOwner) {
      case TaskOwner.Mine:
        return tasks.filter(
          (task: MediaTask) => task.assigned_user_id === fixaUid
        );
      case TaskOwner.Team:
        return tasks.filter(
          (task: MediaTask) => task.assigned_team_id === teamId
        );
      default:
        return tasks;
    }
  })();

  return filteredTasks.sort((a: MediaTask, b: MediaTask) => {
    if (b.priority_flag === a.priority_flag) return 0;
    return a.priority_flag ? 1 : 1;
  });
};

export const filterOngoingTasksByMediaId = (
  tasks: MediaTask[],
  mediaId: string
) => {
  return tasks
    .filter((task) => isOngoing(task))
    .filter((task) => task.media_id === mediaId);
};

export const hasOutgoingEDS = (eds: EdsData[], article: Article) => {
  return eds.some((ed) => ed.productArticleId === article.productArticleId);
};

export const hasSalesStop = (salesStop: SalesStopData[], article: Article) => {
  return salesStop.some(
    (ss) => ss.productArticleId === article.productArticleId
  );
};

export const joinProductArticleIds = (articles: Articles | undefined) => {
  return (
    articles
      ?.map((article) => article.productArticleId)
      .sort((a, b) => a.localeCompare(b))
      .join(",") ?? ""
  );
};

export const formatWithCommasAndFilterEmpty = (items: string[] | undefined) =>
  items ? items.filter((i) => !!i.trim()).join(", ") : "";

export const setOngoingTasksToClosed = async (
  mediaId: string,
  noteText: string,
  tasks: OngoingTaskLinksResponse,
  userId: string
): Promise<void> => {
  if (tasks.count > 0) {
    for (const task of tasks.tasks) {
      if (isOngoing(task) && task.media_id === mediaId) {
        await createNote(
          task.id as string,
          {
            text: noteText,
            creator_id: userId,
          },
          []
        );

        await updateTaskById(
          { state: taskStateOptions.CLOSED },
          task?.id as string
        );
      }
    }
  }
};

export function setMainAndBackupProduct(
  data: MainAlternativeProducts,
  isMain: boolean,
  media: Media,
  article: Article
) {
  if (!media || !article) return data;
  isMain
    ? (data = {
        ...media,
        mainProductId1:
          article.id === media.mainProductId1 ? "" : media.mainProductId1,
        alternativeProductId1:
          article.id === media.mainProductId1
            ? ""
            : media.alternativeProductId1,
        mainProductName1:
          article.itemName === media.mainProductName1
            ? ""
            : media.mainProductName1,
        alternativeProductName1:
          article.itemName === media.mainProductName1
            ? ""
            : media.alternativeProductName1,
        mainProductId2:
          article.id === media.mainProductId2 ? "" : media.mainProductId2,
        alternativeProductId2:
          article.id === media.mainProductId2
            ? ""
            : media.alternativeProductId2,
        mainProductName2:
          article.itemName === media.mainProductName2
            ? ""
            : media.mainProductName2,
        alternativeProductName2:
          article.itemName === media.mainProductName2
            ? ""
            : media.alternativeProductName2,
      })
    : (data = {
        ...media,
        mainProductId1: article.id,
        alternativeProductId1: "",
        mainProductName1: article.itemName,
        alternativeProductName1: "",
        mainProductId2: media.mainProductId1
          ? media.mainProductId1
          : media.mainProductId2,
        alternativeProductId2: media.alternativeProductId1
          ? media.alternativeProductId1
          : media.alternativeProductId2,
        mainProductName2: media.mainProductName1
          ? media.mainProductName1
          : media.mainProductName2,
        alternativeProductName2: media.alternativeProductName1
          ? media.alternativeProductName1
          : media.alternativeProductName2,
      });

  return data;
}

export const getArticlesCountText = (
  t: (key: string, options?: any) => string, // NOSONAR
  selectedArticles: ArticleIdNbrArticles[],
  uniqueArticles: Articles,
  filteredArticles: Articles,
  isSelected: boolean
): string => {
  let articlesCountText = "";

  if (isSelected) {
    const selectedArticlesAfterFilter = selectedArticles.filter((a) =>
      filteredArticles.some((fa) => a.articleId === fa.id)
    );
    articlesCountText = `${selectedArticlesAfterFilter.length}/${
      filteredArticles.length
    } ${t("articlesSelectedString")}`;
  } else if (filteredArticles.length !== uniqueArticles.length) {
    articlesCountText = `${t("viewingString")} ${filteredArticles.length}/${
      uniqueArticles.length
    } ${t("articlesString")}`;
  } else {
    const totalPiecesCount = uniqueArticles.reduce(
      (accumulator, currentValue) => (accumulator += currentValue.nbrArticles),
      0
    );
    articlesCountText = `${t("countArticlesString_plural", {
      count: uniqueArticles.length,
    })}, ${totalPiecesCount} ${t("piecesUnit")}`;
  }
  return articlesCountText;
};

export const invalidateArticleQueries = async (queryClient: QueryClient) => {
  await Promise.all([
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ArticlesByGroup],
      refetchType: "all",
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ArticlesByMedia],
      refetchType: "all",
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ArticlesByMediaAndProductArticleId],
      refetchType: "all",
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.MediasAndArticleCount],
    }),
  ]);
};

export const getMainAndAlternativeProducts = (media: Media) => {
  const mainAlternativeProducts =
    media?.mediaType !== MediaTypes.COMPACT
      ? (media as MainAlternativeProducts)
      : undefined;
  return {
    mainProductId1: mainAlternativeProducts?.mainProductId1 ?? "",
    mainProductId2: mainAlternativeProducts?.mainProductId2 ?? "",
    mainProductName1: mainAlternativeProducts?.mainProductName1 ?? "",
    mainProductName2: mainAlternativeProducts?.mainProductName2 ?? "",
    alternativeProductId1: mainAlternativeProducts?.alternativeProductId1 ?? "",
    alternativeProductId2: mainAlternativeProducts?.alternativeProductId2 ?? "",
    alternativeProductName1:
      mainAlternativeProducts?.alternativeProductName1 ?? "",
    alternativeProductName2:
      mainAlternativeProducts?.alternativeProductName2 ?? "",
  };
};

export const getMediaTypeText = (mediaType: MediaTypes) => {
  switch (mediaType) {
    case MediaTypes.ROOM_SETTINGS:
      return "Room setting";
    case MediaTypes.COMPACT:
      return "Compact";
    case MediaTypes.COORDINATION_MEDIA:
      return "Coordination media";
    case MediaTypes.HOME:
      return "Home";
    case MediaTypes.VIGNETTE:
      return "Vignette";
    case MediaTypes.ROOM:
      return "Room";
    default:
      return "";
  }
};

export const updateUnknownArticles = async (
  articles: Article[],
  queryClient: QueryClient
) => {
  const articlesThatAreUnknown = articles?.filter(
    (article) => article.itemName === "Unknown"
  );
  if (!!articlesThatAreUnknown) {
    const uniqueProductArticlesIds = articlesThatAreUnknown
      .filter(
        (obj, index, self) =>
          index ===
          self.findIndex(
            (article) => article.productArticleId === obj.productArticleId
          )
      )
      .map((article) => article.productArticleId)
      .join(",");

    const articlesInfo = await fetchItemsInfo(uniqueProductArticlesIds);

    if (articlesInfo.length > 0) {
      for (const articleInfo of articlesInfo as Item[]) {
        const articlesBasedOnProductArticleId = articlesThatAreUnknown.filter(
          (article) => article.productArticleId === articleInfo.no
        );
        for (const article of articlesBasedOnProductArticleId) {
          await updateArticle(article.id, {
            ...article,
            ...mapArticleInfoToArticle(articleInfo),
          });
        }
      }
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ArticlesByMedia],
      });
    }
  }
};
