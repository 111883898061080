import { Hfb, Media } from "../types/media";

export const mapUniqueMediaAreas = (medias: (Media & Hfb)[]): string[] =>
  [
    ...new Set(
      medias
        ?.map((media) => media.hfb?.trim())
        .filter((hfb): hfb is string => !!hfb && /^HFB \d{2}$/.test(hfb))
    ),
  ].sort();
