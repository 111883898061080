import { Trans } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { AddDescription } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";

export default function TaskFormTextNote({
  value,
  onChange,
  conf: { optional, title },
}) {
  const { getInput } = useInputPopup();
  const getNote = async () => {
    const note = await getInput("textNote", {
      initialValue: value,
      title: title,
      optional,
    });
    if (typeof note === "string") {
      onChange(note);
    }
  };

  return (
    <Widget onClick={getNote} last data-testid="addNoteWidget">
      <AddDescription
        value={value}
        deactivated={!value}
        descriptionTitle={<Trans>addNoteString</Trans>}
        optional={optional}
        noBottomBorder={true}
        activeIcon={!!value}
      />
    </Widget>
  );
}
