import { useHfbSelectFilter } from "@coworker/apprestructured/src/shared/filters/filters";
import {
  ApplyFilter,
  FilterValue,
  SelectFilter,
} from "@coworker/apprestructured/src/shared/filters/types";
import { Article } from "../types/article";

function useHfbSelectArticleFilter() {
  const filter: ApplyFilter<SelectFilter, Article> = {
    filter: useHfbSelectFilter(),
    filterCompare: (item: Article, { value }: FilterValue) => {
      if (value === undefined || value === "" || value === item.hfb) {
        return true;
      }
      return false;
    },
  };

  return filter;
}

export { useHfbSelectArticleFilter };
