import React, { useState } from "react";
import { SelectFilter, SelectOption, StringFilterValue } from "../../types";
import { FixaListView } from "../../../wrappers/FixaListView/FixaListView";
import { FixaListViewItem } from "../../../wrappers/FixaListView/FixaListViewItem";
import FixaSearch from "../../../wrappers/FixaSearch/FixaSearch";
import { useTranslation } from "@coworker/locales";
import SelectListViewCss from "./SelectListView.module.css";
import { localeIncludes } from "./SelectListView.helper";
import { getSelectOptionKey, isSelectOption } from "../../utils";
import { CategoryListItem } from "./CategoryListItem";

interface SelectListViewProps {
  prefix: string;
  filter: SelectFilter;
  selected: StringFilterValue;
  onChange: (option: SelectOption) => void;
}

export const SelectListView = ({
  prefix,
  filter,
  onChange,
  selected,
}: SelectListViewProps) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const { id, title, options, showSearch } = filter;
  return (
    <div>
      {showSearch && (
        <FixaSearch
          className={SelectListViewCss["search-input"] ?? ""}
          id={`listview-search-${prefix}-${id}`}
          value={searchInput}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchInput(e.target.value);
          }}
          onClear={() => setSearchInput("")}
          placeholder={t("searchFilterOptionsString", { title })}
          data-testid={`listview-search-${prefix}-${id}`}
        />
      )}
      <FixaListView
        id={`listview-${prefix}-${id}`}
        data-testid={`listview-${prefix}-${id}`}
        size="small"
        showDivider={false}
      >
        {options
          .filter(
            (option) =>
              !isSelectOption(option) ||
              selected.value === option.value ||
              localeIncludes(option.title, searchInput)
          )
          .map((option) => {
            if (isSelectOption(option)) {
              const { value } = option;
              const isSelected = selected.value === value;
              return (
                <FixaListViewItem
                  key={getSelectOptionKey(option)}
                  inset
                  onChange={() => {
                    onChange(option);
                  }}
                  control="radiobutton"
                  controlProps={{
                    value: isSelected ? "ON" : "OFF",
                    checked: isSelected,
                  }}
                  showDivider={false}
                  title={option.title}
                  data-testid={`listview-option-${prefix}-${value}`}
                />
              );
            } else {
              return (
                <CategoryListItem
                  key={getSelectOptionKey(option)}
                  item={option}
                  prefix={prefix}
                />
              );
            }
          })}
      </FixaListView>
    </div>
  );
};
