import { useState } from "react";
import { FilterValue, GroupFilter } from "../../types";
import { FilterPill } from "../FilterPill";
import { FilterTabs } from "../FilterTabs";

interface PillGroupProps {
  filter: GroupFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const PillGroup = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
}: PillGroupProps) => {
  const [listBoxIsOpen, setListBoxIsOpen] = useState(false);
  return (
    <FilterPill
      filter={filter}
      listBoxIsOpen={listBoxIsOpen}
      setListBoxIsOpen={setListBoxIsOpen}
    >
      <FilterTabs
        filter={filter}
        filterValues={filterValues}
        defaultValues={defaultValues}
        setFilterValues={(filterValues) => {
          setFilterValues(filterValues);
          setListBoxIsOpen(!listBoxIsOpen);
        }}
      />
    </FilterPill>
  );
};
