import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { isRDTDevice } from "@coworker/reusable";
import image from "../../assets/images/announcement-Dashboard.png";

const Container = styled('div')`
  margin-top: 60px;
  margin-left: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: left;

  & > div {
    margin-top: 30px;
    flex-grow: 1;
  }
`;

const Image = styled('img')`
  margin: 0 auto;
`;

const Title = styled('strong')`
  font-size: 25px;
`;

// We need a custom component to wrap the <a> </a> or else Trans fails to include the inner part and the </a>
const URL = "https://jira.digital.ingka.com/servicedesk/";
const Linked = () => <a href={URL}>{URL}</a>;

export function DashboardAnnouncement() {
  const link = isRDTDevice() ? URL : <Linked />;
  return (
    <Container>
      <Title>
        <Trans>announcementDashboardTitle</Trans>
        <br />
      </Title>
      <div>
        <div>
          <Trans>announcementDashboardPara1</Trans>
          <br />
          <br />
        </div>
      </div>
      <div>
        <strong>
          <Trans>announcementDashboardSubHeader</Trans>
        </strong>
        <div>
          <Trans components={{ link }} i18nKey="announcementDashboardPara2">
            Request access to our dashboard by visiting <link /> and search for{" "}
            <b>‘Fixa’</b>. Select the first option and fill it in. Remember to
            use your IKEA email!\n\nIf you already had access to our dashboard
            since before, you don’t need to do anything. Simply visit the
            dashboard and you will see the new release.\n\nIf you have any
            questions, provide feedback, or need help with access - please reach
            out to:\nfixa.support@ingka.ikea.com
          </Trans>
          <br />
          <br />
        </div>
      </div>
      <div>
        <Image src={image} />
      </div>
    </Container>
  );
}
