import {Dispatch,SetStateAction} from "react";
import styles from "./swipeable-task-card.module.css";
import { Trans } from "@coworker/locales";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import PlusIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PlusIconPath";
// @ts-ignore
import { usePickUpTask } from "@coworker/app/src/hooks/TaskActions/usePickUpTask";
import { FullTask } from "../../../activity/types/FullTask";

interface PickupTaskButtonProps {
  swipeDirection: string;
  setSwipeDirection: Dispatch<
    SetStateAction<"" | "left" | "right">
  >;
  task: FullTask;
}

export const PickupTaskButton = ({
  swipeDirection,
  setSwipeDirection,
  task,
}: PickupTaskButtonProps) => {
  const { call: pickUpTask } = usePickUpTask(task, task.id);

  return (
    <div
      className={`${styles["button-pickup-task"]} ${
        swipeDirection === "right"
          ? styles["button-hidden"]
          : styles["button-puckup-task-visible"]
      }`}
      onClick={() => {
        pickUpTask();
        setSwipeDirection("");
      }}
    >
      <FixaSSRIcon paths={PlusIconPath} />
      <span className={styles["button-text"]}>
        <Trans>pickupTaskButton</Trans>
      </span>
    </div>
  );
};
