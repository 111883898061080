import { FilterValue, SelectFilter, StringFilterValue } from "../../types";
import {
  createFilterValueFromSelectOption,
  getSelectedFilterValue,
} from "../../utils";
import { AccordionSelect } from "../AccordionSelect";

interface InputSelectOptionProps {
  filter: SelectFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  openFilters: string[];
  onChange: (value: FilterValue) => void;
  onOpenChange: (isOpen: boolean) => void;
}

export const InputSelectOption = ({
  filter,
  filterValues,
  defaultValues,
  openFilters,
  onChange,
  onOpenChange,
}: InputSelectOptionProps) => {
  const selected = getSelectedFilterValue(
    filter.id,
    filterValues,
    defaultValues
  )! as StringFilterValue;
  return (
    <AccordionSelect
      filter={filter}
      selected={selected}
      onChange={(option) => {
        const value = createFilterValueFromSelectOption(filter.id, option);
        onChange(value);
      }}
      open={!!openFilters.find((filterId) => filterId === filter.id)}
      onOpenChange={onOpenChange}
    />
  );
};
