import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import { FixaModalHeader } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalHeader";
import { FixaPrompt } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaPrompt";

interface MessageModalProps {
  message: string;
  closeModal: () => void;
}

export const MessageModal = ({ message, closeModal }: MessageModalProps) => {
  return (
    <FixaModal visible={!!message} handleCloseBtn={() => closeModal()}>
      <FixaPrompt
        title={"Message"}
        titleId={"message-modal"}
        header={<FixaModalHeader ariaCloseTxt={"Close modal"} />}
        footer={
          <FixaModalFooter>
            <FixaButton
              text={"Close"}
              onClick={() => {
                closeModal();
              }}
            />
          </FixaModalFooter>
        }
      >
        <p>{message}</p>
      </FixaPrompt>
    </FixaModal>
  );
};
