import { useQuery } from "@tanstack/react-query";
import { Item } from "../../types/item";
import {
  fetchAPI,
  TASKS_SERVICE_URL,
} from "@coworker/app/src/hooks/API/tasks.service.helper";
import { formatProductIdWithDots } from "@coworker/reusable";
import { isEmpty } from "../../helpers/string.helper";

export async function fetchItemInfo(itemNo: string) {
  const url = `${TASKS_SERVICE_URL}/items/item-info?itemNo=${itemNo}`;

  const { result } = await fetchAPI("GET", url);

  return result && !isEmpty(result)
    ? ({
        ...result,
        noFormatted: formatProductIdWithDots(result?.no),
      } as Item)
    : null;
}

// Fetches Items info from Items table if found or SR
// Doesn't contain price and currency info
export const useItemInfo = (itemNo: string) => {
  return useQuery({
    queryKey: ["item", "info", itemNo],
    queryFn: async () => await fetchItemInfo(itemNo),
    enabled: !!itemNo,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 86400000, // cache 24 hours
  });
};
